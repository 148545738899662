<template>
    <div class="background-white">
        <div class="card-body">
            <div class="font-size-xl font-weight-xbold mb-2">{{ $t('Abilitazioni')}}</div>
            <div class="d-flex certificate-preview-container align-items-center" v-if="$auth.user && $auth.user.id === user.user.id && !user.user.diploma">
                <a href="#" class="certificate-image d-flex justify-content-center align-items-center mr-3" @click.prevent="openCertificateModal({})">
                    <img alt="certificates-circle" src="~/assets/images/icons/certificates-circle.svg" width="40">
                </a>
                <div class="certificate-text w-100">
                    <a href="#" @click.prevent="openCertificateModal({})">{{ $t('Aggiungi qualifica di estetista ')}} <u class="font-weight-bold">{{ $t('II anno')}}</u></a>
                </div>
            </div>
            <div class="certificate-preview-container d-flex align-items-center show-on-hover" v-else-if="user.user.diploma">
                <div class="certificate-image d-flex justify-content-center mr-2 cursor-pointer" v-if="!modal">
                    <img alt="education-icon-grey" src="~/assets/images/icons/education-icon-grey.svg" width="20">
                </div>
                <div class="certificate-image overflow-hidden mr-2 d-flex justify-content-center align-items-center" v-else>
                    <img :src="user.user.diploma.image_res.small" width="35px" v-if="user.user.diploma.image_res">
                    <img alt="diploma" src="~/assets/images/icons/diploma.svg" width="35px" v-else>
                </div>
                <div class="certificate-text">
                    <i class="fa fa-exclamation-triangle text-danger" v-if="user.user.diploma.status === 'rejected'"></i>
                    {{ $t('Diplomata ') }} <span v-if="user.user.diploma.account"> {{ $t('presso la scuola ')}} <strong @click.prevent="$router.push(user.user.diploma.account.permalink)">{{ user.user.diploma.account.name }}</strong></span> <span v-if="user.user.diploma.year"> {{ $t(' nel ')}}<span v-if="user.user.diploma.day">{{ user.user.diploma.day | pad(2) }}/</span><span v-if="user.user.diploma.month">{{ user.user.diploma.month | pad(2) }}/</span>{{ user.user.diploma.year }}</span>
                    <div class="text-grey font-size-sm mt-1">
                        <span v-if="!user.user.diploma.visibility">
                            <i class="fa fa-globe"></i> {{ $t("Tutti")}}
                        </span>
                        <span v-else-if="user.user.diploma.visibility === 'private'">
                            <i class="fa fa-lock"></i> {{ $t("Visibile solo a me")}}
                        </span>
                    </div>
                </div>
                <div class="dropdown text-right align-self-start ml-auto" v-if="isEditable($auth.user)">
                    <dropup-menu :hide-bottom-bar="true" :show-overlay="true">
                        <template slot="menu-icon">
                            <i class="cf-more btn-link"></i>
                        </template>
                        <template slot="menu-options">
                            <ul class="menu-options">
                                <li class="pt-2 pb-1 text-grey" ><small>{{ $t('Diploma')}}</small></li>
                                <hr class="m-0">
                                <li @click.prevent="openCertificateModal(user.user.diploma)"> {{ $t('Modifica')}}</li>
                                <li class="text-danger" @click.prevent="removeCertificate(user.user.diploma)" v-if="user.user.diploma.status === 'pending'"> {{ $t('Elimina')}}</li>
                            </ul>
                        </template>
                    </dropup-menu>
                </div>
            </div>

            <div class="d-flex certificate-preview-container align-items-center" v-if="$auth.user && $auth.user.id === user.user.id && !user.user.specialisation_diploma">
                <a href="#" class="certificate-image d-flex justify-content-center align-items-center mr-3" @click.prevent="openCertificateModal({})">
                    <img alt="certificates-circle" src="~/assets/images/icons/certificates-circle.svg" width="40">
                </a>
                <div class="certificate-text w-100">
                    <a href="#" @click.prevent="openCertificateModal({}, 'specialisation')">{{ $t('Aggiungi specializzazione ')}} <u class="font-weight-bold">{{ $t('III anno')}}</u></a>
                </div>
            </div>
            <div class="certificate-preview-container d-flex align-items-center show-on-hover" v-else-if="user.user.specialisation_diploma">
                <div class="certificate-image d-flex justify-content-center mr-2 cursor-pointer" v-if="!modal">
                    <img alt="education-icon-grey" src="~/assets/images/icons/education-icon-grey.svg" width="20">
                </div>
                <div class="certificate-image overflow-hidden mr-2 d-flex justify-content-center align-items-center" v-else>
                    <img :src="user.user.specialisation_diploma.image_res.small" width="35px" v-if="user.user.specialisation_diploma.image_res">
                    <img alt="diploma" src="~/assets/images/icons/diploma.svg" width="35px" v-else>
                </div>
                <div class="certificate-text">
                    <i class="fa fa-exclamation-triangle text-danger" v-if="user.user.specialisation_diploma.status === 'rejected'"></i>
                    {{ $t('Specializzata ') }} <span v-if="user.user.specialisation_diploma.account"> {{ $t('presso la scuola ')}} <strong @click.prevent="$router.push(user.user.specialisation_diploma.account.permalink)">{{ user.user.specialisation_diploma.account.name }}</strong></span> <span v-if="user.user.specialisation_diploma.year"> {{ $t(' nel ')}}<span v-if="user.user.specialisation_diploma.day">{{ user.user.specialisation_diploma.day | pad(2) }}/</span><span v-if="user.user.specialisation_diploma.month">{{ user.user.specialisation_diploma.month | pad(2) }}/</span>{{ user.user.specialisation_diploma.year }}</span>
                    <div class="text-grey font-size-sm mt-1">
                        <span v-if="!user.user.specialisation_diploma.visibility">
                            <i class="fa fa-globe"></i> {{ $t("Tutti")}}
                        </span>
                        <span v-else-if="user.user.specialisation_diploma.visibility === 'private'">
                            <i class="fa fa-lock"></i> {{ $t("Visibile solo a me")}}
                        </span>
                    </div>
                </div>
                <div class="dropdown text-right align-self-start ml-auto" v-if="isEditable($auth.user)">
                    <dropup-menu :hide-bottom-bar="true" :show-overlay="true">
                        <template slot="menu-icon">
                            <i class="cf-more btn-link"></i>
                        </template>
                        <template slot="menu-options">
                            <ul class="menu-options">
                                <li class="pt-2 pb-1 text-grey" ><small>{{ $t('Diploma')}}</small></li>
                                <hr class="m-0">
                                <li @click.prevent="openCertificateModal(user.user.specialisation_diploma)"> {{ $t('Modifica')}}</li>
                                <li class="text-danger" @click.prevent="removeCertificate(user.user.specialisation_diploma)" v-if="user.user.specialisation_diploma.status === 'pending'"> {{ $t('Elimina')}}</li>
                            </ul>
                        </template>
                    </dropup-menu>
                </div>
            </div>
        </div>
        <div class="background-white"><hr class="m-0 mr-3 ml-3"></div>
        <div class="card-body">
            <div class="font-size-xl font-weight-xbold mb-2">{{ $t('Istruzione')}}</div>

            <!--<div class="d-flex certificate-preview-container align-items-center" v-if="$auth.user && $auth.user.id === user.user.id && !user.user.beautician_school">-->
                <!--<a href="#" class="certificate-image d-flex justify-content-center align-items-center mr-3">-->
                    <!--<img alt="school-circle" src="~/assets/images/icons/school-circle.svg" width="40">-->
                <!--</a>-->
                <!--<div class="certificate-text w-100">-->
                    <!--<a href="#" @click.prevent="openCertificateModal({})">{{ $t('Aggiungi scuola di estetica ')}}</a>-->
                <!--</div>-->
            <!--</div>-->
            <div class="d-flex certificate-preview-container align-items-center" v-if="$auth.user && $auth.user.id === user.user.id && !user.user.attending_place">
                <a href="#" class="certificate-image d-flex justify-content-center align-items-center mr-3">
                    <img alt="school-circle" src="~/assets/images/icons/school-circle.svg" width="40">
                </a>
                <div class="certificate-text w-100">
                    <a href="#" @click.prevent="openExperienceModal()">{{ $t('Aggiungi scuola di estetica ')}}</a>
                </div>
            </div>
            <div class="certificate-preview-container d-flex align-items-center" v-else-if="user.user.attending_place">
                <div class="certificate-image d-flex justify-content-center mr-2 cursor-pointer">
                    <img alt="education-icon-grey" src="~/assets/images/icons/education-icon-grey.svg" width="20">
                </div>
                <!--<account-image class="mr-3" :account="myWorkExperience.account" size="30"></account-image>-->
                <div class="work-experience-text">
                    {{ user.user.attending_place.current ? $t('Studia presso') : $t('Ha studiato presso ')}} <span class="font-weight-bold">{{ user.user.attending_place.account.name }}</span>
                </div>
                <!--<div class="edit-work-experience flex-grow-2 text-right align-self-start"><a href="#" class="d-hover-inline" @click.prevent="showWorkExperience(myWorkExperience)"><i class="cf-pencil"></i> {{ $t('Edit') }}</a></div>-->
                <div class="dropdown text-right align-self-start ml-auto" v-if="isEditable($auth.user)">
                    <dropup-menu :hide-bottom-bar="true" :show-overlay="true">
                        <template slot="menu-icon">
                            <i class="cf-more btn-link"></i>
                        </template>
                        <template slot="menu-options">
                            <ul class="menu-options">
                                <li class="pt-2 pb-1 text-grey" ><small>{{ $t('Scuola')}}</small></li>
                                <hr class="m-0">
                                <li @click.prevent="openExperienceModal()"> {{ $t('Modifica')}}</li>
                                <hr class="m-0">
                                <li class="text-danger" @click.prevent="removeExperience()"> {{ $t('Elimina')}}</li>
                            </ul>
                        </template>
                    </dropup-menu>
                </div>
            </div>
        </div>
        <certificate-modal :diploma="true" ref="certificateModal"></certificate-modal>
        <experience-modal class="steps" ref="accountModal" type="school"></experience-modal>
    </div>
</template>

<script>

    import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
    import CertificateModal from '~/components/CertificateModal'
    import ExperienceModal from '~/components/UpdateProfile/Mobile/Steps/WorkingPlace'

    export default {

        components: {
            CertificateModal,
            ExperienceModal
        },

        props: ['modal'],

        data: function () {
            return {

            }
        },

        computed: {
            ...mapState([
                'user'
            ]),
            ...mapGetters({
                'isEditable': 'user/IS_EDITABLE_BY_USER'
            }),
        },

        methods: {
            ...mapActions({
                'refetchUser': 'user/refetch',
                'deleteCertificate': 'certificates/delete',
                'deleteWorkExperience': 'work-experience/delete'
            }),
            ...mapMutations({
                setUserTab: 'user/SET_USER_TAB',
                resetCertificate: 'certificate/RESET_STATE',
                setCertificate: 'certificate/SET_CERTIFICATE',
                setCertificateType: 'certificate/SET_CERTIFICATE_TYPE',
                setCertificateDiploma: 'certificate/SET_CERTIFICATE_DIPLOMA',
                setCertificateEntityId: 'certificate/SET_CERTIFICATE_ENTITY_ID',
                setCertificateEntityType: 'certificate/SET_CERTIFICATE_ENTITY_TYPE',
                resetAccount: 'account/RESET_ACCOUNT',
                resetWorkExperience: 'work-experience/RESET_STATE',
                setWorkExperience: 'work-experience/SET_WORK_EXPERIENCE',
                setWorkExperienceStep: 'work-experience/SET_STEP',
                setWorkExperienceCurrent: 'work-experience/SET_WORK_EXPERIENCE_CURRENT',
            }),
            openExperienceModal() {

                this.resetWorkExperience();

                if (this.$store.state.user.user.attending_place) {
                    this.setWorkExperience(this.$store.state.user.user.attending_place)
                } else {
                    this.setWorkExperienceCurrent(true)
                }

                this.$refs.accountModal.openModal()
            },
            openCertificateModal(diploma, type = null) {

                if (diploma.id) {
                    this.setCertificate(diploma)
                } else {
                    this.resetCertificate(diploma)
                    this.setCertificateDiploma(true);
                    this.setCertificateType(type);
                    this.setCertificateEntityId(this.$store.state.user.user.id)
                    this.setCertificateEntityType('user')
                }
                this.$refs.certificateModal.showModal();
            },
            removeCertificate(certificate) {

                this.$nuxt.$loading.start()

                this.deleteCertificate(certificate).then(() => {

                    this.refetchUser().then(() => {

                        this.$nuxt.$loading.finish()

                        this.$notify({
                            group: 'messages',
                            type: 'success',
                            title: this.$t('Attestato eliminato correttamente')
                        });
                    }).catch(() => {
                        this.$nuxt.$loading.finish()
                    })
                })
            },
            removeExperience() {

                this.$nuxt.$loading.start()

                this.deleteWorkExperience(this.user.user.attending_place).then(() => {

                    this.refetchUser().then(() => {

                        this.$nuxt.$loading.finish()

                        this.$notify({
                            group: 'messages',
                            type: 'success',
                            title: this.$t('Esperienza lavorativa eliminato correttamente')
                        });
                    }).catch(() => {
                        this.$nuxt.$loading.finish()
                    })
                })
            },
        },
        mounted () {
        }
    }
</script>

<style lang="scss" scoped>

    a {
        text-decoration: none;
    }

    .certificate-preview-container {

        padding: 10px 0;

        .certificate-image {

            min-width: 30px;
            height: 30px;
            margin-bottom: 0!important;

            &.image-placeholder {
                background-color: #772A79;
            }
        }

        .certificate-text {

            .certificate-title {
                color: #403D40;
                font-weight: bold;
                line-height: 20px;
            }
            .certificate-description {
                color: #8C8B8C;
                line-height: 18px;
            }
        }
        .edit-certificate {
            margin-right: -10px
        }
    }

    .certificate-preview {
        width: 100%;
        border: 2px solid #DFDFDF;
        border-radius: 6px;
        background-color: #F9F8F9;

        .certificate-image-container {
            width: 100%;
            overflow: hidden;
        }

        .certificate-image {
            width: 100%;
        }
        .certificate-footer {
            color: #8C8B8C;
            font-size: 14px;
            line-height: 22px;
            height: 50px;
        }
    }
</style>