import Vue from 'vue'
import { getField, updateField } from 'vuex-map-fields';
import _ from 'lodash'

export const state = () => ({
    filters: {
        post_id: null
    },
    sort: {
        column: 'created_at',
        order: 'desc'
    },
    pagination: {
        page: 0,
        per_page: 20,
    },
    postImages: [],
    totalCount: 0,
    processing: false
})

export const getters = {
    getField,
    GET_POST_IMAGES_BY_POST_ID: (state) => (postId) => {

        if(!postId) return [];

        return _.orderBy(_.filter(state.postImages, function (postImage) {
            return postImage.post_id === postId && !postImage.from_notifications
        }), 'created_at', 'asc')
    },
}

export const mutations = {
    updateField,
    SET_FILTERS (state, filters) {
        state.filters = filters
    },
    APPEND_POST_IMAGE (state, postImage) {

        if(!_.includes(_.map(state.postImages, 'id'), postImage.id)) {

            state.postImages.push(postImage);
        } else {

            let myPostImageIndex = _.findIndex(state.postImages, {id: postImage.id});

            if(myPostImageIndex > -1) {
                Vue.set(state.postImages, myPostImageIndex, postImage);
            }
        }
    },
    LIKE_POST_IMAGE_BY_ID (state, postImageId) {

        let myPostImage = _.find(state.postImages, {id: postImageId});

        if(myPostImage) {
            Vue.set(myPostImage, 'liked', !myPostImage.liked)
            Vue.set(myPostImage, 'likes_count', myPostImage.liked ? myPostImage.likes_count + 1 : myPostImage.likes_count - 1)
        }
    },
    SET_PROCESSING (state, processing) {
        state.processing = processing
    },
    SET_POST_IMAGES (state, posts) {
        state.posts = posts
    },
    SET_TOTAL_COUNT (state, totalCount) {
        state.totalCount = totalCount
    }
}

export const actions = {

    fetchById ({ dispatch, commit, state}, data) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .get('/api/post-images/' + data.id, {
                params: {
                    notifications: data.notifications
                }
            })
            .then(r => r.data)
            .then(postImage => {
                commit('APPEND_POST_IMAGE', postImage);

                if(postImage.comments && postImage.comments.length) {
                    dispatch('comments/appendComments', postImage.comments, {root: true});
                }

                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },

    likeById ({ commit, state}, postImageId) {

        commit('SET_PROCESSING', true)
        commit('LIKE_POST_IMAGE_BY_ID', postImageId);

        return this.$axios
            .post('/api/post-images/' + postImageId + '/like')
            .then(r => r.data)
            .then(postImage => {
                commit('SET_PROCESSING', false)
                commit('APPEND_POST_IMAGE', postImage);
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },

    appendPostImages ({commit, dispatch}, postImageImages) {

        if(!postImageImages || !postImageImages.length) return;

        _.each(postImageImages, (postImage) => {

            commit('APPEND_POST_IMAGE', postImage);

            if(postImage.comments && postImage.comments.length) {
                dispatch('comments/appendComments', postImage.comments, {root: true});
            }
        });
    },
}