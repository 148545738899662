
import { getField, updateField } from 'vuex-map-fields';

export const state = () => ({
    data: {
        first_name: '',
        last_name: '',
        region_name: '',
        state_name: '',
        city: null,
        city_id: '',
        city_name: '',
        address: '',
        phone: '',
        mobile_phone: '',
        other_contacts: '',
        filtered_links: [],
        links: [
            {
                url: ''
            }
        ],
        notes: '',
        images: [],
        reporter_first_name: '',
        reporter_last_name: '',
        reporter_region_name: '',
        reporter_state_name: '',
        reporter_city_name: '',
        reporter_mobile_phone: '',
    },
    step: 'login',
    processing: false
})

export const getters = {
    getField
}

export const mutations = {
    updateField,
    SET_PROCESSING (state, processing) {
        state.processing = processing
    },

}

export const actions = {
    setStep ({ commit }, step) {
        commit('SET_STEP', step)
    },

    submitReport ({ commit, state}) {

        return this.$axios.post('/api/illicit-work-reports', {...state.data })
    }
}