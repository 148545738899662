<template>
    <div class="info-card">
        <!--<certificate-card v-if="!user.user.certificates.length" :user="user.user"></certificate-card>-->
        <!--<certificates-card></certificates-card>-->
            <component :modal="modal" :is="DiplomaSpecialisationComponent"></component>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        props: ['limit', 'modal'],
        computed: {
            ...mapState([
                'mobileMode'
            ]),
            DiplomaSpecialisationComponent() {
                return () => import('~/components/UserProfile/ProfileInfo/Mobile/SpecialisationDiplomaCard')
                // return () => import('~/components/UserProfile/ProfileInfo/' + ( this.mobileMode ? 'Mobile' : 'Desktop' ) +'/CertificatesCard')
            }
        }
    }
</script>
