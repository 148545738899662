<template>
    <div class="grid-view" v-if="view === 'grid'">
        <nuxt-link :to="{ path: account.permalink }" class="no-decoration">
            <div class="mb-3 d-flex flex-column align-items-center">
                <account-image :account="account" size="100"></account-image>
                <div class="font-weight-bold text-center mt-3 d-flex flex-column align-items-center">
                    {{ account.name }}
                    <!--<account-rank class="profile-rank mr-2 ml-2" :account="account" :hide-text="true"></account-rank>-->
                </div>
                <span class="text-grey mt-1" v-if="account.specialisation1">{{ account.specialisation1.name }}</span>
                <review-rating class="mt-1" :rating="account.avg_rating" :size="14"></review-rating>
            </div>
        </nuxt-link>
    </div>
    <div class="list-view" v-else>
        <nuxt-link :to="{ path: account.permalink }" class="no-decoration">
            <div class="d-flex">
                <div class="d-flex">
                    <div class="image-container d-flex mr-2">
                        <account-image :account="account" size="60"></account-image>
                        <!--<account-rank class="profile-rank" :account="account" :hide-text="true"></account-rank>-->
                    </div>
                    <div class="ml-2">
                        <div class="account-name">
                            <span class="font-weight-bold mr-3">{{ account.name }}</span>
                        </div>
                        <div class="sign-up-date"></div>
                        <div>
                            <div class="subtitle text-grey" v-if="account.specialisation1 || account.specialisation2 || account.specialisation3">
                                {{ $t('Specializzato in ') }}
                                {{ account.specialisation1 ? account.specialisation1.name : '' }}
                                <!--{{ account.specialisation2 ? account.specialisation2.name : '' }}-->
                                <!--{{ account.specialisation3 ? account.specialisation3.name : '' }}-->
                            </div>
                            <!--<div class="text-grey" v-else>-->
                                <!--{{ $t('Centro estetico') }}-->
                            <!--</div>-->
                            <div class="subtitle text-grey" v-if="account.city">
                                {{ account.city.name }}
                            </div>
                            <div class="subtitle text-grey" v-else-if="account.address">
                                {{ account.address }}
                            </div>
                            <div class="d-flex align-items-center" v-if="account.avg_rating">
                                <span class="mr-2 vanitas text-brand review-score">{{ account.avg_rating ? account.avg_rating.toFixed(1) : '-' }}</span>
                                <review-rating :rating="account.avg_rating" :size="14"></review-rating>
                            </div>
                        </div>
                    </div>
                </div>
                <!--<div class="ml-auto mb-auto text-right" v-if="$auth.user && $auth.user.is_admin">-->
                    <!--<div class="mb-3">-->
                        <!--<b-dropdown right no-caret variant="link">-->
                            <!--<template slot="button-content">-->
                                <!--<i class="cf-more"></i>-->
                            <!--</template>-->
                            <!--<b-dropdown-item>{{ $t('Contatta in privato') }}</b-dropdown-item>-->
                            <!--<b-dropdown-item>{{ $t('Rifiuta richiesta') }}</b-dropdown-item>-->
                        <!--</b-dropdown>-->
                    <!--</div>-->
                <!--</div>-->
            </div>
        </nuxt-link>
    </div>
</template>

<script>
    import AccountRank from '~/components/AccountRank'
    import ReviewRating from '~/components/ReviewRating'

    export default {

        components: {
            AccountRank,
            ReviewRating
        },

        props: ['account', 'view'],

        mounted () {
        }
    }
</script>

<style lang="scss" scoped>

    .image-container {
        height: 75px;
        position: relative;

        .profile-rank {
            position:absolute;
            right: -10px;
            bottom: -10px;
        }
    }

    .review-score {
        font-size: 18px;
    }

    .account-info {
        font-size: 15px;
        margin-bottom: 5px;
        display: flex;
        color: #8C8B8C;
        .account-icon {
            width: 20px;
            text-align: center;
            margin-right: 10px;
            color: #DBDBDB;
        }
        .value {
            color: #403D40;
        }
    }
</style>
