<template>
    <div class="nuxt-error d-flex flex-column align-items-center justify-content-center h-100 w-100 content pb-5" style="background: #F9F8F9">
        <component :is="errorPage" :error="error" />
    </div>
</template>
<script>
    import error404 from '~/components/Errors/404.vue';
    import error500 from '~/components/Errors/500.vue';

    export default {
        name: 'nuxt-error',
        auth: false,
        layout: 'default', // optional
        props: {
            error: {
                type: Object,
                default: () => {
                },
            },
        },
        computed: {
            errorPage() {
                if (this.error.statusCode === 404) {
                    return error404;
                }
                // catch everything else
                return error500;
            },
        }
    }
</script>