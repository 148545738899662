import Vue from 'vue'

export default ({ app, $axios } , inject) => {
    window.$app_log = function (data) {
        $axios.post('/api/app-log', {data: JSON.stringify(data)})
    };

    Vue.prototype.smartGoBack = function () {
        window.smartGoBack()
    }

    window.getAccessToken = function(eventName = false) {

        let event = {
            event: eventName ? eventName : 'access-token',
            body: app.$auth.loggedIn ? app.$auth.getToken('local') : ''
        }

        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify(event));
        } else {
            window.postMessage(JSON.stringify(event), '*');
        }
    }

    window.sendPushToken = function(tokenData) {
        app.store.dispatch('user/sendPushToken', tokenData)
    }

    window.loginWithNativeFacebook = function(token) {
        window.$nuxt.$root.$emit('login-with-facebook-token', token)
    }

    window.smartGoBack = function() {
        if (app.store.state.prevRoute !== '/') {
            app.router.back()
        } else {
            app.router.push('/')
        }
    }

    window.onKeyboardOpened = function(data) {

        // app.store.dispatch('setBottomOffset', data.height)
        app.store.dispatch('setKeyboardOpened', data.height > 0)

        if(!data.height) {
            window.dispatchEvent(new Event('resize'));
        }
    }
    window.finishIAPTransaction = function () {
        window.$nuxt.$root.$emit('finish-iap-transaction')
    }

    window.setIAPProducts = function (data) {

        let products = data.products;

        if(app.$auth.loggedIn && app.$auth.user.account.subscription_id) {
            _.map(products, function (product) {
                Vue.set(product, 'selected', product.productId === app.$auth.user.account.subscription_id);
            });
        }

        app.store.dispatch('plans/setPlans', products)
    }

    window.onOrientationUpdate = function (data) {

        if(data.platform === 'ios') {
            data.x = data.x * -10;
            data.y = data.y * -10;
            data.z = data.z * -10;
        }

        // app.store.dispatch('setOrientation', data)

        if(Math.abs(data.y) < 7 && Math.abs(data.z) < 7) {
            app.store.dispatch('setOrientation', data.x > 5 ? 'landscape right' : 'landscape left')
        } else if(Math.abs(data.y) > 5 && Math.abs(data.x) < 5){
            app.store.dispatch('setOrientation', 'portrait')
        }
    }

    window.setAppMode = function (data) {

        // window.$app_log(data)
        
        document.body.classList.add('app');

        app.store.dispatch('setAppMode', true)

        document.documentElement.style.removeProperty('--vh');

        app.store.commit('SET_PLATFORM', data.platform)

        let envVersion = ''

        if(data.platform === 'android') {
            envVersion = process.env.ANDROID_BREAKING_VERSION
        } else if(data.platform === 'ios') {
            envVersion = process.env.IOS_BREAKING_VERSION
        }

        if(data.version && envVersion) {
            let splittedAppVersion = data.version.split('.')
            let splittedEnvVersion = envVersion.split('.')
            _.each(splittedAppVersion, (value, key) => {
                if(splittedEnvVersion[key] && parseInt(value) < parseInt(splittedEnvVersion[key])) {
                    // window.$nuxt.$root.$emit('update-app')
                    // return false
                }
            })
        } else {
            // OLD VERSION
            // window.$nuxt.$root.$emit('update-app')
            console.log('no version provided!')
        }

        // let fontScale = data.font_scale;
        let insets = JSON.parse(data.insets);

        app.store.dispatch('setInsets', insets);

        let menuStyle = {
            marginTop: 50 + (insets ? insets.top : 0),
            marginBottom: 50 + (insets ? insets.bottom : 0),
            touchable: {
                style: {
                    alignItems: 'stretch',
                    height: 68,
                    padding: 10,
                    borderBottomWidth: 1,
                    borderColor: '#f4f2f3'
                }
            },
            dropdown_touchable: {
                style: {
                    alignItems: 'stretch',
                    height: 58,
                    padding: 10,
                    paddingLeft: 20,
                    borderBottomWidth: 1,
                    borderColor: '#f4f2f3',
                    backgroundColor: '#fafafa',
                }
            },
            category: {
                style: {
                    paddingVertical: 10,
                    paddingHorizontal: 10,
                    backgroundColor: '#f4f2f3',

                },
                title: {
                    fontSize: 12,
                    color: '#404040',
                    fontWeight: 'bold',
                    textTransform: 'uppercase'
                }
            },
            news: {
                style: {
                    width: 4,
                    height: 4,
                    borderRadius: 2,
                    backgroundColor: '#9B187E',
                }
            },
            item: {
                style: {},
                title: {
                    width: '90%',
                    fontSize: 21,
                    color: '#404040'
                },
                subtitle: {
                    width: '90%',
                    marginLeft: 5,
                    fontSize: 16,
                    color: '#757575'
                },
                image: {
                    marginRight: 15,
                    width: 34,
                    height: 34
                }
            },
            dropdown_item: {
                style: {},
                title: {
                    width: '90%',
                    marginLeft: 15,
                    fontSize: 19,
                    color: '#404040'
                },
                subtitle: {
                    width: '90%',
                    marginLeft: 20,
                    fontSize: 14,
                    color: '#757575'
                },
                image: {
                    width: 30,
                    height: 30
                }
            },
        }

        let events = [
            {
                event: 'access-token',
                body: app.$auth.getToken('local')
            },
            {
                event: 'menu-style',
                body: menuStyle,
            }
        ]
        _.each(events, event => {
            if(window.ReactNativeWebView) {

                window.ReactNativeWebView.postMessage(JSON.stringify(event));
            } else {
                window.postMessage(JSON.stringify(event), '*');
            }
        })
    }

    window.setMenuOpen = function (data) {
        app.store.dispatch('setMenuOpen', data === 'open')
    }

    window.routeTo = function (path) {
        app.router.push(path)
    }

    window.logout = function () {

        app.store.dispatch('setMenuOpen', false)

        app.$auth
            .logout()
            .then(() => {
                app.router.push('/')
            });
    }

    window.getValidationErrorMessage = function(responseData) {

        if (responseData.errors) {

            let message = '';

            _.each(responseData.errors, (errorArray) => {

                _.each(errorArray, (errorMessage) => {

                    if (message != '') {
                        message += '\n';
                    }

                    message += errorMessage;
                })
            });

            return message;
        }

        return '';
    }
}