<template>
    <div class="d-flex">
        <div class="mr-2">
            <nuxt-link :to="'/estetiste/' + comment.user.id + '-' + comment.user.slug" v-if="comment.user && ['student', 'professional'].indexOf(comment.user.profile_type) > -1">
                <user-image class="relative" :user="comment.user" size="32"></user-image>
            </nuxt-link>
            <user-image class="relative" :user="comment.user" size="32" v-else></user-image>
        </div>
        <div class="w-100">
            <div>
                <div class="d-flex pr-lg-5" v-if="!edit">
                    <div class="d-flex comment w-100 mb-1 position-relative" v-longpress="openDropupMenu">
                        <span style="word-break: break-word">
                            <strong class="mr-1 no-wrap comment-user" @click.prevent="openPostPage">{{ comment.user ? comment.user.name : $t('Utente Cancellato') }}</strong>
                            <br />
                            <div class="pre-wrap post-text">
                                <template v-for="piece in textPieces">
                                    <span v-if="piece.type === 'plain'" @click.prevent="openPostPage">{{ piece.value }}</span><span v-if="piece.type === 'html'" v-html="piece.value"></span>
                                </template>
                            </div>
                        </span>
                        <div v-if="comment.likes_count && modal" class="position-absolute comment-reactions-container d-flex justify-content-center align-items-center text-dark-grey">
                            <img alt="like-circle" src="~/assets/images/icons/like-circle.svg" class="mr-1" width="16px" height="16px">
                            {{ comment.likes_count }}
                        </div>
                    </div>
                    <b-dropdown right no-caret variant="link" class="align-self-center d-none d-lg-block absolute" style="right: 30px" v-if="$auth.loggedIn">
                        <template slot="button-content">
                            <i class="cf-more"></i>
                        </template>
                        <b-dropdown-item @@click.prevent="toggleEditComment" v-if="comment.user_id === $auth.user.id || group.admin">{{ $t('Modifica commento') }}</b-dropdown-item>
                        <b-dropdown-item class="text-danger" @click.prevent="destroyComment" v-if="comment.user_id === $auth.user.id || group.admin">{{ $t('Elimina commento') }}</b-dropdown-item>
                    </b-dropdown>
                    <div class="dropdown d-block d-lg-none align-self-center absolute" style="right: 30px" v-if="$auth.loggedIn">
                        <dropup-menu ref="dropupMenu" :bottom="50" :hide-bottom-bar="true" :show-overlay="true">
                            <!--<template slot="menu-icon">-->
                                <!--<i class="cf-more btn-link"></i>-->
                            <!--</template>-->
                            <template slot="menu-options">
                                <ul class="menu-options">
                                    <li v-if="$auth.user.is_admin || comment.user_id === $auth.user.id || group.admin" @click="toggleEditComment">
                                        <i class="fa cf-pencil mr-2"></i> {{ $t('Modifica commento')}}
                                    </li>
                                    <!--<li>-->
                                        <!--<i class="fa fa-bookmark-o mr-2"></i> {{ $t('Salva commento')}}-->
                                    <!--</li>-->
                                    <!--<li>-->
                                        <!--<i class="fa fa-flag-o mr-2"></i> {{ $t('Segnala commento')}}-->
                                    <!--</li>-->
                                    <li class="text-danger" @click.prevent="destroyComment" v-if="$auth.user.is_admin || comment.user_id === $auth.user.id || group.admin">
                                        <i class="fa fa-trash mr-2"></i> {{ $t('Elimina commento')}}
                                    </li>
                                </ul>
                            </template>
                        </dropup-menu>
                    </div>
                </div>
                <div v-else class="d-flex flex-column w-100 relative align-items-center">
                    <textarea class="d-flex comment new-comment w-100"
                              :class="{edit: edit}"
                              v-focus="focused"
                              :id="'editCommentId' + comment.id"
                              @keypress.enter.prevent="saveComment(editComment)"
                              v-model="editComment.text"
                    >
                    </textarea>
                    <div class="comment-actions d-flex mt-2 d-block d-lg-none ml-3 align-self-end">
                        <a class="btn btn-post btn-xs btn-brand btn-transparent mr-1" @click.prevent="toggleEditComment">{{ $t('Annulla')}}</a>
                        <a class="btn btn-post btn-xs btn-brand ml-1" :disabled="editComment.text.length == 0" @click.prevent="saveComment(editComment)">{{ $t('Aggiorna')}}</a>
                    </div>
                </div>
                <div class="comment-reactions pb-lg-2" v-if="(postDetail || !mobileMode) && !edit">
                    <span class="text-grey">{{ comment.created_at | fromNow }} </span><label class="m-0 text-grey font-weight-bold ml-2" :class="{liked: comment.liked}" @click.prevent="likeComment">{{ $t('Mi piace') }}</label> <span v-if="!comment.comment_id" class="text-grey"></span> <label v-if="!comment.comment_id" :for="'newCommentPostId'" class="m-0 text-grey font-weight-bold ml-2" @click="showAnswerForm">{{ $t('Rispondi') }}</label>
                </div>
                <div v-if="comment.comments_count > getCommentsByCommentId(comment.id).length">
                    <a href="#" class="text-grey" v-if="modal" @click.prevent="loadMore"><i class="fa fa-rotate-180 fa-reply mr-1"></i>{{ $t('Visualizza altre') }} {{ comment.comments_count - getCommentsByCommentId(comment.id).length }} {{ comment.comments_count - getCommentsByCommentId(comment.id).length > 1 ? $t('risposte') : $t('risposta') }}</a>
                </div>
            </div>
            <div v-for="childComment in getCommentsByCommentId(comment.id)" :key="childComment.id" class="d-flex comment-container" v-if="showingReplies">
                <comment :modal="modal" class="w-100" :comment="childComment"></comment>
            </div>
            <div class="d-lg-flex d-none mb-3" v-if="showingAnswerForm">
                <user-image class="relative mr-2" :user="$auth.loggedIn ? $auth.user : {}" size="24"></user-image>
                <div class="d-flex w-100 relative">
                    <textarea class="d-flex comment new-comment w-100"
                              :id="'newCommentCommentId' + comment.id"
                              :placeholder="$t('Scrivi un commento...')"
                              @keypress.enter.prevent="saveComment(newComment)"
                              v-model="newComment.text"
                    >
                    </textarea>
                    <div class="comment-actions d-block d-lg-none">
                        <i class="fa cf-message-f" :class="{'text-grey': newComment.text.length == 0, 'text-brand': newComment.text.length > 0 }" @click.prevent="saveComment(newComment)"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import { mapState, mapActions, mapGetters } from 'vuex'
    import { focus } from 'vue-focus';
    import autosize from 'autosize'
    import _ from 'lodash'

    export default {

        name: 'comment',

        props: {
            comment: {
                type: Object,
                required: true
            },
            modal: {
                type: Boolean,
                default: function() {
                    return false
                }
            },
            postDetail: {
                type: Boolean,
                default: function() {
                    return false
                }
            }
        },

        data: function () {
            return {
                edit: false,
                editComment: _.cloneDeep(this.comment),
                newComment: {
                    text: '',
                    post_id: this.comment.post_id,
                    comment_id: this.comment.id
                },
                showingReplies: true,
                showingAnswerForm: false,
                page: 0,
                focused: true,
            }
        },

        directives: {
            focus: focus,
        },

        computed: {
            ...mapState([
                'group',
                'mobileMode'
            ]),
            ...mapGetters({
                'getCommentsByCommentId': 'comments/GET_COMMENTS_BY_COMMENT_ID'
            }),

            textPieces () {

                return this.postTextPieces(this.comment.text, false)
            },
        },

        methods: {
            ...mapActions({
                'fetch': 'comments/fetch',
                'refetchPost': 'posts/fetchById',
                'refetchComment': 'comments/fetchById',
                'createComment': 'comment/create',
                'updateComment': 'comment/update',
                'deleteComment': 'comments/delete',
                'like': 'comments/likeCommentById'
            }),
            openDropupMenu() {

                if(this.$auth.loggedIn && (this.$auth.user.is_admin || this.post.user_id === this.$auth.user.id || this.group.admin)) {
                    if (this.$refs && this.$refs.dropupMenu) {
                        this.$refs.dropupMenu.toggleShowDropUp()
                    }
                }
            },
            loadMore() {

                this.page++;

                this.fetch({ page: this.page, per_page: 10, comment_id: this.comment.id }).then(() => {

                    this.showAnswerForm();
                })
            },
            destroyComment() {
                this.$nuxt.$loading.start()

                this.deleteComment(this.comment).then(() => {
                    this.refetchPost({ id: this.comment.post_id}).then(() => {
                        this.$nuxt.$loading.finish()
                    })
                })
                    .catch(() => {
                        this.$nuxt.$loading.finish()
                    });
            },
            toggleEditComment() {
                if (!this.edit) {
                    this.edit = true;

                    setTimeout(() => {
                        autosize(document.querySelectorAll('textarea'))
                    })

                    let el = document.getElementById('editCommentId' + this.comment.id)

                    if (el) {
                        setTimeout(() => {
                            el.focus();
                        }, 50)
                    }
                } else {
                    this.edit = false;
                    this.editComment.text = this.comment.text
                }

            },
            saveComment (comment) {

                if (comment.text.length === 0) return;

                this.$nuxt.$loading.start()

                if (comment.id) {
                    this.updateComment(comment)
                        .then((response) => {

                            this.edit = false;

                            this.$nuxt.$loading.finish()
                        }).catch(() => {
                        this.$nuxt.$loading.finish()
                    });
                } else {
                    this.createComment(comment)
                        .then(() => {
                            this.newComment.text = '';

                            setTimeout(function () {
                                autosize.update(document.querySelectorAll('textarea'))
                            })

                            this.refetchPost({id: this.comment.post_id})

                            this.$nuxt.$loading.finish()
                        }).catch(() => {
                        this.$nuxt.$loading.finish()
                    });
                }
            },
            likeComment() {
                this.like(this.comment.id)
                    .then((r) => {
                        // this.refetchComment(this.comment.id)
                    })
            },
            showAnswerForm () {
                this.showingAnswerForm = true;
                setTimeout(() => {

                    autosize(document.querySelectorAll('textarea'))
                    document.getElementById('newCommentCommentId' + this.comment.id).focus();
                }, 20)

                this.$nuxt.$emit('post-' + this.comment.post_id + '-answer-to-comment-id', {commentId: this.comment.id, user: this.comment.user });
            },
            openPostPage() {
                if (this.mobileMode && !this.modal) {
                    this.$nuxt.$emit('open-post-modal', this.comment.post_id)
                }
            }
        },

        mounted () {
            autosize(document.querySelectorAll('textarea'))
        }
    }
</script>

<style lang="scss" scoped>
    a {
        text-decoration: none;
    }
</style>