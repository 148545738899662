import Vue from 'vue'
import moment from 'moment-timezone'

moment.locale('it');
// moment.updateLocale('it', { months : 'Gennaio_Febbraio_Marzo_Aprile_Maggio_Giugno_Luglio_Agosto_Settembre_Ottobre_Novembre_Dicembre'.split('_') });

Vue.prototype.$moment = moment;

moment.fn.fromNowOrNow = function (a) {
    if (Math.abs(moment().diff(this)) < 15000) { // 15 seconds
        return 'adesso';
    }
    return this.fromNow(a);
}