<template>
    <div class="card h-100 text-center">
        <div class="card-header card-header-sm  d-flex justify-content-center align-items-center">
            <i class="cf-back absolute p-3" style="left: 0;" @click.prevent="previousStep()"></i>
            <span v-if="type === 'school'">{{ $t('Aggiungi una scuola di estetica') }}</span>
            <span v-else>{{ $t('Collegati ad un luogo di lavoro') }}</span>
        </div>
        <div class="card-body">
            <div class="mt-lg-5 mb-2 p-4 d-flex align-items-center justify-content-between">
                <user-image :user="$auth.user" size="100"></user-image>
                <i class="fa fa-link fa-2x"></i>
                <account-image :account="account.account" size="100"></account-image>
            </div>
            <div class="title p-3">
                {{ $t('Collegamento avvenuto con successo!')}}
            </div>

            <div class="d-flex mt-5" v-if="workExperience.workExperience.account.id">
                <user-image :user="$auth.user" size="60"></user-image>
                <div class="ml-3 text-left ellipsis">
                    <strong>{{ $auth.user.name }}</strong>
                    <profile-rank :user="$auth.user" :hideBadge="true"></profile-rank>
                    <span class="text-grey">{{ workExperience.workExperience.type == 'owner' ? $t('Titolare') : $t('Dipendente') }}</span>
                </div>
            </div>
            <div class="m-3">
                <i class="fa fa-link fa-lg mr-1"></i>
                {{ $t('E\' collegato a')}}
            </div>
            <div class="d-flex" >
                <account-image class="align-self-start" :account="workExperience.workExperience.account" size="60"></account-image>
                <div class="ml-3 text-left d-flex flex-column">
                    <strong>{{ workExperience.workExperience.account.name }}</strong>
                    <span class="text-grey">{{ workExperience.workExperience.account.address }}</span>
                </div>
            </div>

        </div>
        <div class="card-footer">
            <nuxt-link :to="account.account.permalink" class="btn btn-brand w-100" v-if="workExperience.workExperience.type === 'owner' && $auth.user.wizard_completed">
                {{ $t('Visualizza il tuo centro') }}
            </nuxt-link>
            <div v-else>
                <button class="btn btn-brand w-100" @click.prevent="nextStep()">
                    {{ $t('Chiudi') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapMutations } from 'vuex'

    export default {

        props: ['modal', 'type'],

        computed: {
            ...mapState({
                'account': 'account',
                'workExperience': 'work-experience'
            })
        },

        methods: {
            ...mapMutations({
                'setWorkExperienceType': 'work-experience/SET_TYPE',
                'setWorkExperienceStep': 'work-experience/SET_STEP',
                'setStep': 'profile-wizard/SET_STEP'
            }),
            previousStep() {
                this.setWorkExperienceStep('work-experience')
            },
            nextStep() {
                if (this.modal) {
                    this.$nuxt.$emit('close-experience-modal')
                } else {
                    this.setWorkExperienceStep('profile-image')
                }
            }
        },

        mounted () {
        },
    }
</script>