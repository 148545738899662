<template>
    <div class="bg-white">
        <div>
            <div class="p-3 d-flex flex-column">
                <div class="d-flex w-100">
                    <user-image v-if="diploma.entity_type === 'App\\User'" class="mr-3 align-self-start" :user="diploma.entity" size="50"></user-image>
                    <account-image v-else-if="diploma.entity_type === 'App\\Account'" class="mr-3 align-self-start" :user="diploma.entity" size="50"></account-image>
                    <div class="d-flex flex-column w-100" style="min-width: 0">
                        <div class="d-flex">
                            <div class="user-name d-flex" style="max-width: 85%">
                                <span v-if="diploma.entity_type === 'App\\User'" class="font-weight-bold" style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden">{{ diploma.entity.first_name }} {{ diploma.entity.last_name }}</span>
                                <span v-if="diploma.entity_type === 'App\\Account'" class="font-weight-bold" style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden">{{ diploma.entity.name }}</span>
                            </div>
                        </div>
                        <profile-rank v-if="diploma.entity_type === 'App\\User'" class="profile-rank" size="small" :user="diploma.entity">
                            <template v-slot:time>
                                <!--<div class="ml-1">-->
                                    <!--<img alt="pending-verification" class="verified-icon" src="~/assets/images/icons/pending-verification.svg">-->
                                <!--</div>-->
                                <div class="text-grey ml-1" v-if="diploma.created_at"> {{ diploma.created_at | fromNow }}</div>
                            </template>
                        </profile-rank>
                        <div>
                            <u>{{ diploma.institute }}</u>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="mt-2 mb-2">
                <img :src="diploma.image_res.medium" class="w-100" v-if="diploma.image_res" @click="openImagesGalleryModal">
                <img alt="diploma-grey" src="~/assets/images/icons/diploma-grey.svg" class="w-100" v-else>
            </div>
        </div>
        <div class="pb-3 pl-3 pr-3" v-if="diploma.status === 'rejected'">
            <hr />
            <i class="cf-like fa fa-rotate-180 liked text-grey"></i>
            <span v-if="diploma.rejected_at">{{ diploma.rejected_at | fromNow }}</span>
            <span class="ml-1" v-if="diploma.rejected_by_id">
            da <a href="#" @click.prevent="$router.push('/estetiste/' + diploma.rejected_by_id)">{{ diploma.rejected_by.first_name + ' ' + diploma.rejected_by.last_name }}</a>
        </span>
        </div>

        <images-gallery ref="postImagesGallery" :images="images" url="/api">
            <template slot="dropdown-menu">
                <b-dropdown right no-caret variant="link" class="d-none d-lg-block">
                    <template slot="button-content">
                        <i class="cf-more"></i>
                    </template>
                    <!--<b-dropdown-item @click.prevent>{{ $t('Salva immagine') }}</b-dropdown-item>-->
                    <!--<b-dropdown-item @click.prevent>{{ $t('Segnala immagine') }}</b-dropdown-item>-->
                    <!--<b-dropdown-item class="text-danger" @click.prevent="deleteImage" v-if="$auth.user.is_admin || post.user_id === $auth.user.id || group.admin">{{ $t('Elimina immagine') }}</b-dropdown-item>-->
                </b-dropdown>
                <div class="dropdown d-block d-lg-none">
                    <dropup-menu :bottom="50" :hide-bottom-bar="true" :show-overlay="true">
                        <template slot="menu-icon">
                            <i class="cf-more btn-link text-white"></i>
                        </template>
                        <template slot="menu-options">
                            <ul class="menu-options">
                                <!--<li >-->
                                    <!--<i class="fa fa-bookmark-o mr-2"></i> {{ $t('Salva immagine')}}-->
                                <!--</li>-->
                                <!--<li>-->
                                    <!--<i class="fa fa-flag-o mr-2"></i> {{ $t('Segnala immagine')}}-->
                                <!--</li>-->
                                <!--<li class="text-danger" @click.prevent="deleteImage" v-if="$auth.user.is_admin || post.user_id === $auth.user.id || group.admin">-->
                                    <!--<i class="fa fa-trash mr-2"></i> {{ $t('Elimina immagine')}}-->
                                <!--</li>-->
                            </ul>
                        </template>
                    </dropup-menu>
                </div>
            </template>

            <template slot="resource-name">
                <nuxt-link class="title font-weight-bold text-white" :to="'/estetiste/' + diploma.entity.id + '-' + diploma.entity.slug">{{ diploma.entity.first_name }} {{ diploma.entity.last_name }}</nuxt-link>
            </template>
            <template slot="dropup-menu">
                <dropup-menu :hide-bottom-bar="true">
                    <template slot="menu-options">
                        <ul>
                            <li @click.prevent="openInput">
                                <i class="fa fa-photo mr-2"></i>
                                {{ $t('Foto/video')}}
                            </li>
                            <li>
                                <i class="cf-camera mr-2"></i>
                                {{ $t('Fotocamera')}}</li>
                            <li>
                                <i class="cf-friends mr-2"></i>
                                {{ $t('Tagga persone')}}
                            </li>
                        </ul>
                    </template>
                </dropup-menu>
            </template>
            <template slot="actions">
                <div class="pb-3 text-white">
                    <admin-actions :modal="modal" :user="diploma.entity" :certificate="diploma" default-button-classes="text-white btn-default" brand-button-classes="text-white btn-default"></admin-actions>
                </div>
            </template>
        </images-gallery>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'

    export default {


        props: {
            diploma: {
                type: Object,
                required: true
            },
            modal: {
                type: Boolean,
                default: function() {
                    return false;
                }
            }
        },

        data() {
            return {
                showingDiploma: false
            }
        },

        computed: {
            checkExpirationDate() {

                return this.$moment(this.user.account.subscription_expiration_date).format() > this.$moment().format();
            },
            images() {
                return [this.diploma];
            }
        },

        methods: {
            ...mapActions({

            }),

            openImagesGalleryModal() {
                this.$refs.postImagesGallery.showImagesGalleryModal(0)
            },
        },

        mounted () {
        }
    }
</script>

<style lang="scss" scoped>

    .verified-icon {
        width: 20px;
    }
</style>