
import { getField, updateField } from 'vuex-map-fields';
import _ from 'lodash'
import Vue from "vue";

export const state = () => ({
    filters: {
      name: ''
    },
    regions: [],
    processing: false
})

export const getters = {
    getField,
    getRegionsByFilters: (state) => (filters) => {

        return _.orderBy(_.filter(state.regions, function (region) {

            let passes = true;

            if (filters.hasOwnProperty('name')) {
                passes = passes && region.name.match(new RegExp(filters.name, 'i'))
            }

            return passes
        }), 'created_at', 'desc')
    }
}

export const mutations = {
    updateField,
    SET_NAME_FILTER (state, text) {
        state.filters.name = text
    },
    SET_FILTERS (state, filters) {
        state.filters = filters
    },
    SET_PROCESSING (state, processing) {
        state.processing = processing
    },
    SET_REGIONS (state, regions) {
        state.regions = regions
    },
    SET_INFINITE_SCROLL_ENABLED (state, infiniteScrollEnabled) {
        state.infiniteScrollEnabled = infiniteScrollEnabled
    },
    SET_TOTAL_COUNT (state, totalCount) {
        state.totalCount = totalCount
    },
    SET_LAST_PAGE (state, lastPage) {
        state.pagination.last_page = lastPage
    }
}

export const actions = {

    setTextFilter ({ commit, dispatch }, text) {
        commit('SET_NAME_FILTER', text)
    },

    fetchAll ({ commit, state}, data) {

        commit('SET_PROCESSING', true);

        let filters = [];

        if(data && data.hasOwnProperty('filters')) {
            filters = data.filters;
        }

        return this.$axios
            .get('/api/regions', {
                params: {
                    ...state.filters,
                    ...filters
                }
            })
            .then(response => {
                commit('SET_REGIONS', response.data)
                commit('SET_TOTAL_COUNT', response.data.length)
                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    }
}