<template>
    <div>
        <div>
            <div class="mb-2" v-if="resource.root_comments_count > comments.length">
                <a href="#" class="text-grey" v-if="groupBuyDetail || !mobileMode" @click.prevent="loadMore">{{ $t('Visualizza altri') }} {{ resource.root_comments_count - comments.length }} {{ (resource.root_comments_count - comments.length) > 1 ? $t('commenti') : $t('commento') }}</a>
            </div>
            <div class="comment-container" :class="{'not-focused': focusCommentId && resourceComment.id !== focusCommentId}" v-for="resourceComment in filteredComments" :key="resourceComment.id" >
                <comment :group-buy-detail="groupBuyDetail" :modal="modal" :comment="resourceComment"></comment>
            </div>
        </div>
        <div class="d-lg-flex d-none">
            <user-image  class="relative mr-3" :user="$auth.loggedIn ? $auth.user : {}" size="40"></user-image>
            <div class="d-flex w-100 relative">
                <textarea class="d-flex comment new-comment w-100"
                          :id="'newCommentPostId' + resource.id"
                          :placeholder="$t('Scrivi un commento...')"
                          @keypress.enter="enterPressed"
                          v-model="comment.text"
                >
                </textarea>
                <div class="absolute comment-actions d-block d-lg-none">
                    <i class="fa cf-message-f" :class="{'text-grey': comment.text.length == 0, 'text-brand': comment.text.length > 0 }" @click.prevent="saveComment"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import { mapState, mapActions } from 'vuex'
    import UserImage from "~/components/UserImage";
    import Comment from "~/components/GroupBuys/GroupBuy/Comments/Comment";
    import autosize from 'autosize';
    import _ from 'lodash';

    export default {

        props: {

            limit: {
                type: Number,
                default: function () {
                    return 0
                }
            },
            modal: {
                type: Boolean,
                default: function () {
                    return false
                }
            },
            resource: {
                type: Object,
                default: function () {
                    return {}
                }
            },
            comments: {
                type: Array,
                default: function () {
                    return []
                }
            },
            focusCommentId: {
                type: Number,
                default: function () {
                    return null;
                }
            },
            groupBuyDetail: {
              type: Boolean,
              default: function() {
                return false
              }
            }
        },

        components: {
            UserImage,
            Comment,
        },

        data: function () {

            return {
                comment: {
                    text: '',
                    // group_buy_id: this.resource.post_id ? this.resource.id : null,
                    group_buy_product_id: this.resource.id,
                    comment_id: null
                },
                page: 0
            }
        },

        computed: {
            ...mapState([
                'group',
                'mobileMode'
            ]),
            filteredComments() {
                if (!this.limit || this.page > 0) {
                  return this.comments;
                }
                return _.take(this.comments, this.limit)
            }
        },

        methods: {

            ...mapActions({
                'fetch': 'group-buy-comments/fetch',
                'refetchGroupBuy': 'group-buys/fetchById',
                'createComment': 'comment/create'
            }),

            enterPressed(event) {
                if (!event.shiftKey) {
                    event.preventDefault();
                    this.saveComment()
                }
            },

            loadMore() {

                this.page++;

                this.fetch({
                    page: this.page,
                    per_page: 50,
                    // group_buy_id: this.resource.post_id ? this.resource.id : null,
                    filters: {
                        group_buy_product_id: this.resource.id
                    }
                })
            },
            saveComment () {

                if (this.comment.text.length === 0) return;

                this.$nuxt.$loading.start()

                this.createComment(this.comment)
                    .then((comment) => {
                        this.showingOverlay = false;
                        this.comment.text = '';

                        setTimeout(function () {
                            autosize.update(document.querySelectorAll('textarea'))
                        }, 20)

                        this.refetchGroupBuy(this.resource)

                        this.$nuxt.$loading.finish()

                    }).catch(() => {
                        this.$nuxt.$loading.finish()
                    });
            }
        },

        created () {

            this.$nuxt.$on('focus-new-comment-' + this.resource.id, data => {

                document.getElementById('newCommentPostId' + this.resource.id).focus();
                // this.$refs['newCommentPostId' + this.post.id].focus()
            });
        },

        mounted () {
            autosize(document.querySelectorAll('textarea'))
            setTimeout(() => {
                autosize.update(document.querySelectorAll('textarea'))
            })
        }
    }
</script>

<style lang="scss" scoped>
    a {
        text-decoration: none;
    }
</style>