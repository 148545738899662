import Vue from "vue";
import { getField, updateField } from 'vuex-map-fields';
import _ from 'lodash'

export const state = () => ({
    transactions: [],
    filters: {
        date_from: '',
        date_to: ''
    },
    sort: {
        column: 'created_at',
        order: 'desc'
    },
    pagination: {
        page: 0,
        per_page: 10
    },
    totalCount: 0,
    processing: false
})

export const getters = {
    getField,
    getTransactions (state) {
        return _.orderBy(state.transactions, 'created_at', 'desc')
    }
}

export const mutations = {
    updateField,
    SET_PROCESSING (state, processing) {
        state.processing = processing
    },
    APPEND_TRANSACTION (state, transaction) {
        if(!_.includes(_.map(state.transactions, 'id'), transaction.id)) {

            state.transactions.push(transaction);
        } else {
            let index = _.findIndex(state.transactions, {id: transaction.id});

            Vue.set(state.transactions, index, transaction);
        }
    },
    REMOVE_TRANSACTION (state, transaction) {
        let commentIndex = _.findIndex(state.transactions, {id: transaction.id});

        if (commentIndex > -1) {
            state.transactions.splice(commentIndex, 1);
        }
    },
    SET_INFINITE_SCROLL_ENABLED (state, infiniteScrollEnabled) {
        state.infiniteScrollEnabled = infiniteScrollEnabled
    },
    SET_TRANSACTIONS (state, transactions) {
        state.transactions = transactions;
    },
    SET_TOTAL_COUNT (state, totalCount) {
        state.totalCount = totalCount
    },
}

export const actions = {

    fetch ({ commit, dispatch, state}, data) {

        commit('SET_PROCESSING', true)

        let filters = data.filters ?  data.filters : state.filters;
        let sort = data.sort ?  data.sort : state.filters;

        return this.$axios
            .get('/api/wallet', {
                params: {
                    ...sort,
                    ...filters,
                    page: data && data.page ? data.page : state.pagination.page,
                    per_page: data && data.per_page ? data.per_page : state.pagination.per_page
                }
            })
            .then(r => r.data)
            .then(response => {
                if(state.transactions.length) {
                    dispatch('appendTransactions', response.data)
                } else {
                    commit('SET_TRANSACTIONS', response.data)
                }

                commit('SET_TOTAL_COUNT', response.totalCount)
                commit('SET_INFINITE_SCROLL_ENABLED', response.data.length >= ( data && data.per_page ? data.per_page : state.pagination.per_page));
                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },

    appendTransactions ({commit, state}, transactions) {

        if(!transactions || !transactions.length) return;

        _.each(transactions, (transaction) => {

            commit('APPEND_TRANSACTION', transaction);
        });
    },
}