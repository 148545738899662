<template>
    <div class="border-bottom">
        <div class="payment-method-input">
            <div class="d-flex align-items-center">
                <div class="mr-3 d-flex align-items-center">
                    <i class="fa fa-check-circle text-primary" v-if="paymentMethod.id === $auth.user.default_payment_method.id"></i>
                    <i class="fa fa-circle-o text-grey" v-else @click.prevent="setDefaultSource"></i>
                </div>
                <div class="d-flex justify-content-between w-100">
                    <div class="font-size-sm">
                        <img class="mr-2" :src="require('~/assets/images/cards/' + getCardName(paymentMethod.card.brand) + '.png')" height="24px">
                        <strong>{{  paymentMethod.card.brand }}</strong> {{ $t('termina con ')}} {{ paymentMethod.card.last4 }}
                    </div>
                    <div class="dropdown d-block d-lg-none align-self-center">
                        <dropup-menu ref="dropupMenu" :hide-bottom-bar="true" :show-overlay="true">
                            <template slot="menu-icon">
                                <i class="cf-more btn-link" style="font-size: 5px"></i>
                            </template>
                            <template slot="menu-options">
                                <ul class="menu-options">
                                    <li @click.prevent="editSource"> {{ $t('Modifica')}}</li>
                                    <li class="text-danger" @click.prevent="deleteSource"> {{ $t('Elimina')}}</li>
                                </ul>
                            </template>
                        </dropup-menu>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="edit" class="d-flex justify-content-between align-items-center">
            <div class="ml-5 flex-basis-0 flex-grow-2 font-size-sm"> {{ $t('Modifica data di scadenza') }}</div>
            <card-expiry    class='stripe-element card-expiry flex-basis-0 flex-grow-1 border-bottom-0'
                            ref='cardExpiry'
                            v-model="expirationDate"
                            :stripe="stripeApiKey"
                            :options='stripeOptions'
                            @change='expiry = $event.complete'
            />
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
    import { Card, CardExpiry } from 'vue-stripe-elements-plus'

    export default {

        auth: false,

        components: {
            CardExpiry
        },

        props: ['paymentMethod'],

        data() {

            let elementStyles = {
                base: {
                    color: '#403D40',
                    fontFamily: 'Acumin Pro',
                    fontSize: '20px',

                    '::placeholder': {
                        color: '#A6ACAF'
                    }
                },
                invalid: {
                    color: '#FA755A',
                    ':focus': {
                        color: '#FA755A',
                    },
                    '::placeholder': {
                        color: '#FFCCA5',
                    },
                },
            };

            let elementClasses = {
                focus: 'focus',
                empty: 'empty',
                invalid: 'invalid',
            };

            return {
                stripeApiKey: process.env.STRIPE_PUBLISHABLE_KEY || 'pk_test_nH1hcGkzXexiZBYsKY72hUXA',
                edit: false,
                expirationDate: 'ciao',
                stripeOptions: {
                    style: elementStyles,
                    classes: elementClasses
                },
            }
        },

        watch: {
            'account': function() {
                if (this.account) {
                    this.first_name = this.$auth.user.first_name;
                    this.last_name = this.$auth.user.last_name;
                }
            },
            'number': function () {
                this.update()
            },
            'expiry': function () {
                this.update()
            },
            'cvc': function () {
                this.update() }
        },

        computed: {
            ...mapGetters([
                'account'
            ]),
            ...mapState([
                'user',
                'checkout'
            ])
        },

        methods: {
            ...mapActions({
                'setDefault': 'payment-methods/setDefault',
                'update': 'payment-methods/update',
                'delete': 'payment-methods/delete'
            }),
            ...mapMutations({
                setStep: 'subscription/SET_STEP'
            }),
            editSource() {
                this.$nuxt.$emit('update-edit');
                setTimeout(() => {
                    this.edit = true;
                }, 50)
                this.$refs.dropupMenu.toggleShowDropUp();
            },
            deleteSource() {
                this.$refs.dropupMenu.toggleShowDropUp();

                this.$nuxt.$loading.start();

                this.delete(this.paymentMethod.id).then(() => {
                    this.$auth.fetchUser().then(() => {
                        this.$nuxt.$loading.finish();
                    });
                }).catch(() => {
                        this.$nuxt.$loading.finish();
                })
            },
            setDefaultSource() {

                this.$nuxt.$loading.start();

                this.setDefault(this.paymentMethod.id)
                    .then(() => {
                        this.$auth.fetchUser().then(() => {
                            this.$nuxt.$loading.finish();
                        });
                    }).catch(() => {
                    this.$nuxt.$loading.finish();
                })
            },
            getCardName(name) {

                return name.replace(' ', '');
            },
            saveCard() {

                // this.$nuxt.$loading.start()

                let el = document.querySelector('.stripe-element.card-expiry .InputContainer input');

                let expirationDate = '';

                if (el) {
                    expirationDate = el.value
                }

                console.log(el);

                // if (expirationDate) {
                //     this.update({expiry: expirationDate}).then(() => {
                //         this.$nuxt.$loading.finish()
                //
                //         this.$notify({
                //             group: 'messages',
                //             type: 'success',
                //             duration: 10000,
                //             title: this.$t('Carta modificata correttamente.')
                //         });
                //     })
                // }
            },
            update () {
                this.complete = this.number && this.expiry && this.cvc

                // field completed, find field to focus next
                if (this.number) {
                    if (!this.expiry) {
                        this.$refs.cardExpiry.focus()
                    } else if (!this.cvc) {
                        this.$refs.cardCvc.focus()
                    }
                } else if (this.expiry) {
                    if (!this.cvc) {
                        this.$refs.cardCvc.focus()
                    } else if (!this.number) {
                        this.$refs.cardNumber.focus()
                    }
                }
                // no focus magic for the CVC field as it gets complete with three
                // numbers, but can also have four
            }
        },

        mounted () {
            this.$nuxt.$on('update-edit', () => {
                this.edit = false;
            })

            this.$nuxt.$on('update-card', () => {
                if (this.edit) {
                    this.saveCard();
                }
            })
        }
    }
</script>

<style lang="scss" scoped>

    .subscription.card {
        .card-body {
            max-height: inherit!important;
            .card  {
                height: inherit!important;
                .card-body {
                    padding-bottom: 20px !important;
                    max-height: inherit!important;
                }
            }
        }
    }

    .middle {
        flex-basis: 0;
        width: inherit !important;
    }

    .sidebar-right {
        flex-basis: 0;
        max-width: inherit !important;
        width: inherit !important;
    }

    a {
        text-decoration: underline;
        color: inherit;
    }

    hr {
        margin: 0;
    }

    .form-group {
        margin-bottom: 0;
    }

    .payment-method-input {
        padding: 20px 15px;
        i {
            font-size: 24px;
        }
    }

    .payment-method-logo {
        height: 18px;
    }

    .card.cursor-pointer:hover {
        background-color: #f4f4f4;
    }

    .cf-logo-container {
        width: 60px;
        height: 60px;
        border-radius: 6px;
        background-color: #772A79;
    }

    .book-image-container {
        width: 100%;
        height: 120px;
    }

    .total {
        font-size: 20px;
        font-weight: bold;
    }

    .form-group {
        margin: 0;
    }

    .stripe-element {
        padding: 20px 15px;
        border: 1px solid #F4F2F3;
    }
</style>
