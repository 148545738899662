
import { getField, updateField } from 'vuex-map-fields';

export const state = () => ({
    certificate: {
        entity_id: null,
        entity_type: '',
        title: '',
        city_id: '',
        institute: '',
        account_id: '',
        account: {},
        duration_in_hours: '',
        diploma: false,
        type: '',
        day: '',
        month: '',
        year: '',
        date: '',
        status: 'pending',
        visibility: null
    },
    saving: false
})

export const getters = {
    getField,
    IS_EDITABLE_BY_USER: state => (user) => {

        if(!user) return false;

        return (state.certificate.user_id === user.id || user.is_admin);
    }
}

export const mutations = {
    updateField,
    SET_CERTIFICATE_ENTITY_ID (state, entityId) {
        state.certificate.entity_id = entityId
    },
    SET_CERTIFICATE_ENTITY_TYPE (state, entityType) {
        state.certificate.entity_type = entityType
    },
    SET_CERTIFICATE_TYPE (state, type) {
        state.certificate.type = type
    },
    SET_CERTIFICATE_ACCOUNT (state, account) {
        state.certificate.account = account
    },
    SET_CERTIFICATE_ACCOUNT_ID (state, accountId) {
        state.certificate.account_id = accountId
    },
    SET_CERTIFICATE_DIPLOMA (state, diploma) {
        state.certificate.diploma = diploma
    },
    SET_CERTIFICATE_VISIBILITY (state, visibility) {
        state.certificate.visibility = visibility
    },
    SET_CERTIFICATE (state, certificate) {
        state.certificate = certificate
    },
    RESET_STATE (state) {
      state.certificate = {
          entity_id: null,
          entity_type: '',
          title: '',
          city_id: '',
          institute: '',
          account_id: '',
          account: {},
          duration_in_hours: '',
          diploma: false,
          type: '',
          day: '',
          month: '',
          year: '',
          date: '',
          status: 'pending',
          visibility: null
      }
    },
    SET_LOADING (state, loading) {
        state.loading = loading
    },
    SET_SAVING (state, saving) {
        state.saving = saving
    },
}

export const actions = {

    async refetch({ state, dispatch }) {
        await dispatch('fetchById', state.certificate.id)
    },

    fetchById ({ commit, state}, id) {

        commit('SET_LOADING', true)

        return this.$axios
            .get('/api/certificates/' + id)
            .then(r => r.data)
            .then(certificate => {
                commit('SET_CERTIFICATE', certificate)
                commit('SET_LOADING', false)
            })
            .catch(error => {
                commit('SET_LOADING', false)
                throw error;
            })
    },

    create ({ commit, state }, image) {

        commit('SET_SAVING', true)

        let data = new FormData();

        data.append('entity_id', state.certificate.entity_id);
        data.append('entity_type', state.certificate.entity_type);
        data.append('title', state.certificate.title ? state.certificate.title : '');
        data.append('account_id', state.certificate.account_id ? state.certificate.account_id : '');
        data.append('duration_in_hours', state.certificate.duration_in_hours ? state.certificate.duration_in_hours : '');
        data.append('diploma', state.certificate.diploma ? 1 : 0);
        data.append('type', state.certificate.type ? state.certificate.type : '');
        data.append('day', state.certificate.day ? state.certificate.day : '');
        data.append('month', state.certificate.month ? state.certificate.month : '');
        data.append('year', state.certificate.year ? state.certificate.year : '');
        data.append('visibility', state.certificate.visibility ? state.certificate.visibility : '');

        if(state.certificate.city_id) {
            data.append('city_id', state.certificate.city_id);
        }

        if(image) {
            data.append('image', image);
        }

        return this.$axios
            .post('/api/certificates', data)
            .then(r => r.data)
            .then(certificate => {
                commit('SET_SAVING', false);
                commit('SET_CERTIFICATE', certificate)
            })
            .catch(error => {
                commit('SET_SAVING', false);
                throw error;
            })
    },

    update ({ commit, state }, image) {

        commit('SET_SAVING', true)

        let data = new FormData();

        data.append('entity_id', state.certificate.entity_id);
        data.append('entity_type', state.certificate.entity_type);
        data.append('title', state.certificate.title ? state.certificate.title : '');
        data.append('account_id', state.certificate.account_id ? state.certificate.account_id : '');
        data.append('duration_in_hours', state.certificate.duration_in_hours ? state.certificate.duration_in_hours : '');
        data.append('diploma', state.certificate.diploma ? 1 : 0);
        data.append('type', state.certificate.type ? state.certificate.type : '');
        data.append('day', state.certificate.day ? state.certificate.day : '');
        data.append('month', state.certificate.month ? state.certificate.month : '');
        data.append('year', state.certificate.year ? state.certificate.year : '');
        data.append('visibility', state.certificate.visibility ? state.certificate.visibility : '');

        if(state.certificate.city_id) {
            data.append('city_id', state.certificate.city_id);
        }

        if (image) {
            data.append('image', image);
        }

        return this.$axios
            .post('/api/certificates/' + state.certificate.id, data)
            .then(r => r.data)
            .then(certificate => {
                commit('SET_SAVING', false);
                commit('SET_CERTIFICATE', certificate)
            })
            .catch(error => {
                commit('SET_SAVING', false);
                throw error;
            })
    },

    requestVerification ({ commit, state}) {

        commit('SET_SAVING', true)

        return this.$axios
            .post('/api/certificates/' + state.certificate.id + '/request-verification')
            .then(r => r.data)
            .then(user => {
                commit('SET_SAVING', false)
            })
            .catch(error => {
                commit('SET_SAVING', false)
                throw error;
            })
    },

    removeImage ({commit, state}) {

        commit('SET_SAVING', true)

        return this.$axios.post('/api/certificates/' + state.certificate.id + '/remove-image')
            .then(r => r.data)
            .then(certificate => {
                commit('SET_SAVING', false)
            })
            .catch(error => {
                commit('SET_SAVING', false)
                throw error;
            })
    }
}