<template>
    <div class="card card-modal border-0">
        <div class="card-header card-header-sm d-flex justify-content-center align-items-center">
            <i class="cf-delete absolute p-3" @click.prevent="closeUploadCertificate()" style="left: 0" v-if="!edit || !certificate.certificate.id"></i>
            <u class="font-size-sm absolute p-3" style="left: 0" @click.prevent="toggleEditMode" v-else >{{ $t('Annulla')}}</u>
            <div v-if="certificate.certificate.diploma && (user.profile_type === 'student' || user.profile_type === 'professional')">
                <span v-if="type === 'specialisation'">{{ $t('Specializzazione di estetista') }}</span>
                <span v-else>{{ $t('Qualifica di estetista') }}</span>
            </div>
            <div v-else-if="certificate.certificate.diploma && user.profile_type === 'not_beautician'">{{ $t('Verifica della licenza') }}</div>
            <div v-else>
                <span v-if="type === 'update'">{{ $t('Corso di aggiornamento') }}</span>
                <span v-if="type === 'specialisation'">{{ $t('Corso di specializzazione') }}</span>
            </div>
            <!--<span class="font-size-base absolute p-3" style="right: 0" v-if="!editable && ($auth.user && $auth.user.id === user.id) && (certificate.certificate.image_res || blob) && (!certificate.certificate.diploma || (certificate.certificate.diploma && (certificate.certificate.status === 'pending' || certificate.certificate.status === 'rejected')))">-->
                <!--<u @click.prevent="edit = true" v-if="!edit">{{ $t('Modifica' )}}</u>-->
                <!--<u @click.prevent="saveCertificate" v-else>{{ $t('Salva' )}}</u>-->
            <!--</span>-->
            <div class="absolute" style="right: 0" v-if="isEditable($auth.user)">
                <a href="#" class="font-size-sm p-3" v-if="!certificate.certificate.id || this.edit" @click.prevent="saveCertificate(true, true)"><u>{{ $t('Salva')}}</u></a>
                <div class="dropdown d-block" v-else>
                    <dropup-menu :bottom="50" :hide-bottom-bar="true" :show-overlay="true">
                        <template slot="menu-icon">
                            <i class="cf-more btn-link p-3"></i>
                        </template>
                        <template slot="menu-options">
                            <ul class="menu-options">
                                <li v-if="$auth.user && $auth.user.id === user.id" @click.prevent="editCertificate">
                                    <i class="fa fa-bookmark-o mr-2"></i> {{ $t('Modifica')}}
                                </li>
                                <li class="text-danger" @click.prevent="deleteCertificate" v-if="$auth.user && $auth.user.id === user.id">
                                    <i class="fa fa-trash mr-2"></i> {{ $t('Elimina')}}
                                </li>
                            </ul>
                        </template>
                    </dropup-menu>
                </div>
            </div>
        </div>
        <div class="card-body p-0 pb-5 background-dark-gray overflow-auto scrollable-content">
            <input type="file" name="image" :id="'image-input-' + _uid" accept="image/*" @change="setImage" class="d-none"/>

            <div @click.prevent="openAccountModal()" class="background-white border-bottom" :class="{'border-grey': !diploma}">
                <div class="font-weight-xbold font-size-lg mb-1 text-left mx-3 pt-2">{{ $t('Scuola di conseguimento') }}</div>
                <div class="d-flex align-items-center p-2 pl-3 pr-3" v-if="certificate.certificate.account_id">
                    <div>
                        <account-image class="mr-2 rounded-circle overflow-hidden" :account="certificate.certificate.account" size="50" v-if="certificate.certificate.account"></account-image>
                        <account-image class="mr-2 rounded-circle overflow-hidden" size="50" v-else></account-image>
                    </div>
                    <div class="ml-2 text-left ellipsis">
                        <strong>{{ certificate.certificate.account.name }}</strong>
                        <div class="text-grey no-wrap">{{ certificate.certificate.account.address }}</div>
                    </div>
                </div>
                <div v-else class="d-flex align-items-center p-2 pl-3 pr-3">
                    <div>
                        <account-image class="mr-2 rounded-circle overflow-hidden" :account="certificate.certificate.account" size="50" v-if="certificate.certificate.account"></account-image>
                        <account-image class="mr-2 rounded-circle overflow-hidden" size="50" v-else></account-image>
                    </div>
                    <span class="text-light-grey no-wrap ellipsis font-size-xl">{{ $t('Nome della tua scuola di estetica')}}</span>
                </div>
            </div>
            <div class="background-white border-bottom border-grey p-3" v-if="!certificate.certificate.diploma">
                <input :disabled="!editable" type="text" class="w-100 font-size-lg placeholder-light p-0" :placeholder="$t('Titolo del corso')" autocomplete="off" v-model="title"/>
            </div>
            <div class="background-white border-bottom p-3" v-if="!certificate.certificate.diploma">
                <select :disabled="!editable" class="form-control form-control-md acumin has-label d-none d-lg-block" autocomplete="off" v-model="duration_in_hours" >
                    <option class="text-grey" value="" disabled selected style="display: none">{{ $t('Durata in ore') }}</option>
                    <option v-for="option in durationOptions" :value="option.value"> {{ option.name }}</option>
                </select>
                <div class="d-lg-none d-block">
                    <dropup-menu :hide-bottom-bar="true" :show-overlay="true" :disabled="!editable">
                        <template slot="menu-icon">
                            <span v-if="!duration_in_hours" class="text-light-grey font-size-lg">{{ $t('Durata in ore') }}</span>
                            <span v-else class="font-size-lg">
                                {{ durationOptionName }}
                            </span>
                        </template>
                        <template slot="menu-options">
                            <ul class="menu-options scrollable-options scrollable-content">
                                <li v-for="option in durationOptions" @click.prevent="duration_in_hours = option.value"> {{ option.name }}</li>
                            </ul>
                        </template>
                    </dropup-menu>
                </div>
            </div>
            <div class="card mt-2">
                <!--<div class="card-header pl-3 d-flex card-header-sm align-items-center text-left">-->
                    <!--<img alt="photo-icon" class="ml-0 mr-2" src="~/assets/images/icons/photo-icon.svg" width="20px" height="20px">-->
                    <!--<span v-if="certificate.certificate.diploma">-->
                        <!--<span v-if="type === 'specialisation'">{{ $t('Attestato di specializzazione') }}</span>-->
                        <!--<span v-else>{{ $t('Foto del diploma')}}</span>-->
                    <!--</span>-->
                    <!--<span v-else>{{ $t('Attestato del corso')}}</span>-->
                <!--</div>-->
                <div class="card-body p-3 text-left">
                    <div class="mb-2">
                        <div class="font-weight-xbold font-size-lg mb-1" v-if="certificate.certificate.diploma" >
                            <span v-if="type === 'specialisation'">{{ $t('Attestato di specializzazione') }}</span>
                            <span v-else>{{ $t('Foto del diploma')}}</span>
                        </div>
                        <div v-else class="font-weight-xbold font-size-lg">{{ $t('Attestato del corso')}}</div>
                        <!-- Certificate image description -->
                        <div class="text-dark-grey font-size-sm">
                            <span v-if="type === 'update'">{{ $t('Il tuo valore professionale si misura anche dal tuo aggiornamento continuo. Inserendo la foto del tuo attestato comporrai il tuo archivio personale con tutti gli attestati, visibili da tutti o solo da te.')}}</span>
                            <span v-if="type === 'specialisation'">{{ $t('Il tuo valore professionale si misura anche dalle tue specializzazioni. Inserendo la foto del tuo attestato comporrai il tuo archivio personale con tutti gli attestati, visibili da tutti o solo da te.')}}</span>
                        </div>
                    </div>
                    <div class="relative w-100 d-flex mt-1">
                        <div v-if="blob" class="certificate-image-container">
                            <img :src="stringBlob">
                        </div>
                        <div v-else-if="certificate.certificate.image_res" style="max-height: 200px;overflow: hidden" @click.prevent="openGalleryModal">
                            <img alt="certificate" :src="certificate.certificate.image_res.medium" width="100%">
                        </div>
                        <div v-else class="image-placeholder d-flex justify-content-center align-items-center background-gray" @click.prevent="openInput">
                            <img alt="certificate" src="~/assets/images/icons/certificate-placeholder.svg" width="120px">
                        </div>
                        <div class="absolute p-1 pr-2 pl-2 font-size-sm edit-button bottom right d-flex align-items-center justify-content-center" @click.prevent="openInput" v-if="editable">
                            <i class="cf-camera-f text-white mr-1"></i>
                            <span v-if="certificate.certificate.image_res || blob">{{ $t('Modifica') }}</span>
                            <span v-else>{{ $t('Aggiungi') }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card mt-2">
                <div class="card-body">
                    <div class="text-left">
                        <div class="font-weight-xbold font-size-lg mb-2">
                            <span v-if="certificate.certificate.diploma">{{ $t('Data della qualifica di estetista') }}</span>
                            <span v-else>{{ $t('Data di conseguimento dell\'attestato') }}</span>
                        </div>
                        <date-picker :editable="editable" :day.sync="day" :month.sync="month" :year.sync="year">
                            <template slot="placeholder">
                               <span class="text-light-grey font-size-xl">
                                   <img class="rounded-circle mr-2" src="~assets/images/calendar-placeholder.svg" width="50px" height="50px">
                                    {{ $t('Data di conseguimento') }}
                                </span>
                            </template>
                        </date-picker>
                    </div>
                </div>
            </div>
            <div class="pl-3 pt-3 pb-2" v-if="isEditable($auth.user)">
                <dropup-menu :hide-bottom-bar="true" :show-overlay="true">
                    <template slot="menu-icon">
                        <div class="no-wrap form-control d-flex align-items-center w-auto background-white border form-control-xs font-weight-medium">
                            <i class="mr-1" :class="visibilityOption.class"></i> {{ visibilityOption.name }} <i class="fa fa-angle-down ml-2"></i>
                        </div>
                    </template>
                    <template slot="menu-options">
                        <ul class="menu-options">
                            <li class="show-icon d-flex align-items-center" @click.prevent="setCertificateVisibility(option.value)" v-for="option in visibilityOptions">
                                <i class="mr-2 font-size-xxl" :class="option.class"></i>
                                <div class="text-left">
                                    {{ option.name }}
                                    <div class="font-size-sm text-grey">{{ option.description}}</div>
                                </div>
                            </li>
                        </ul>
                    </template>
                </dropup-menu>
            </div>
            <hr>
        </div>
        <div class="card-footer d-flex" v-if="($auth.user && $auth.user.id !== user.id) && $auth.user.is_admin">
            <div class="d-flex w-100" v-if="certificate.certificate.status === 'pending'">
                <admin-actions class="w-100" :user="user" :certificate="certificate.certificate"></admin-actions>
            </div>
            <button class="btn btn-brand w-100" v-else @click.prevent="closeUploadCertificate">
                {{ $t('Chiudi') }}
            </button>
        </div>
        <!--<div class="card-footer d-flex" v-else>-->
            <!--<div v-if="certificate.certificate.diploma" class="w-100">-->
                <!--<div v-if="editable">-->
                    <!--<button class="btn btn-brand w-100" @click.prevent="saveCertificate" :disabled="(!certificate.certificate.image_res && !blob) || !account_id || (!day && !month && !year)">-->
                        <!--<span v-if="!group">{{ $t('Salva') }}</span>-->
                        <!--<span v-else>{{ $t('Salva e invia richiesta agli amministratori')}}</span>-->
                    <!--</button>-->
                <!--</div>-->
                <!--<div v-else>-->
                    <!--<div v-if="certificate.certificate.id">-->
                        <!--<button class="btn btn-danger btn-transparent text-danger w-100" v-if="user.status === 'rejected'" style="pointer-events: none">-->
                            <!--<i class="cf-delete text-danger mr-2 font-size-xs"></i>-->
                            <!--{{ $t('Qualifica rifiutata') }}-->
                        <!--</button>-->
                        <!--<button class="btn btn-danger btn-transparent text-danger w-100" v-else-if="user.status === 'pending'" style="pointer-events: none">-->
                            <!--<img alt="pending-verification-f" src="~/assets/images/icons/pending-verification-f.svg" class="m-0 mr-2" width="20px">-->
                            <!--{{ $t('Verifica in corso...') }}-->
                        <!--</button>-->
                    <!--</div>-->
                    <!--<div v-else>-->
                        <!--<button class="btn btn-danger text-danger btn-transparent w-100" v-if="!certificate.certificate.image_res || !city_id || !date || !institute" @click.prevent="toggleEditMode">{{ $t('Completa i dati mancanti')}}</button>-->
                    <!--</div>-->
                <!--</div>-->
            <!--</div>-->
        <!--</div>-->
        <account-modal ref="accountModal" type="school" :confirm-callback="setAccount"></account-modal>
        <images-gallery v-if="certificate.certificate"
                        id="gallery-images-modal"
                        ref="galleryImagesModal"
                        :images="[certificate.certificate]"
        >
            <template slot="gallery-title">
                {{ $t('Attestato di qualifica')}}
            </template>
            <template slot="dropdown-menu">
                <b-dropdown right no-caret variant="link" class="align-self-center d-none d-lg-block">
                    <template slot="button-content">
                        <i class="cf-more"></i>
                    </template>
                    <!--<b-dropdown-item>{{ $t('Invia email')}}</b-dropdown-item>-->
                    <!--<b-dropdown-item @click.prevent="">{{ $t('Invia un messaggio')}}</b-dropdown-item>-->
                    <!--<b-dropdown-item @click.prevent="">{{ $t('Blocca membro')}}</b-dropdown-item>-->
                    <b-dropdown-item @click.prevent="$router.push('/estetiste/' + user.id)" v-if="$auth.loggedIn && $auth.user.id !== certificate.certificate.user_id">{{ $t('Visualizza profilo')}}</b-dropdown-item>
                </b-dropdown>
                <div class="dropdown d-block d-lg-none align-self-center">
                    <dropup-menu :hide-bottom-bar="true" :show-overlay="true">
                        <template slot="menu-icon">
                            <i class="cf-more btn-link"></i>
                        </template>
                        <template slot="menu-options">
                            <ul class="menu-options">
                                <!--<li @click.prevent=""> {{ $t('Invia email')}}</li>-->
                                <!--<li @click.prevent=""> {{ $t('Invia un messaggio')}}</li>-->
                                <!--<li @click.prevent=""> {{ $t('Blocca membro')}}</li>-->
                                <li @click.prevent="$router.push('/estetiste/' + user.id)" v-if="$auth.loggedIn && $auth.user.id !== certificate.certificate.user_id"> {{ $t('Visualizza profilo')}}</li>
                            </ul>
                        </template>
                    </dropup-menu>
                </div>
            </template>
            <template slot="resource-image">
                <user-image class="relative mr-3" :user="user" size="50">
                    <!--<template slot="profile-rank">-->
                        <!--<profile-rank class="absolute bottom right" :hide-text="true" :user="user" size="small"></profile-rank>-->
                    <!--</template>-->
                </user-image>
            </template>
            <template slot="resource-name">
                <nuxt-link class="font-size-xl text-white" :to="'/estetiste/' + user.id">{{ user.name }}</nuxt-link>
            </template>
            <template slot="actions">
                <div class="pb-3 text-white" v-if="($auth.user && $auth.user.id !== user.id) && $auth.user.is_admin">
                    <admin-actions class="w-100" :user="user" :certificate="certificate.certificate" default-button-classes="text-white btn-default" brand-button-classes="text-white btn-default"></admin-actions>
                </div>
            </template>
        </images-gallery>
    </div>
</template>

<script>
    import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
    import { mapFields } from 'vuex-map-fields'
    import DatePicker from "~/components/DatePicker";
    import AccountModal from "~/components/AccountModal";
    import AdminActions from '~/components/PendingUsers/PendingUser/AdminActions';
    import _ from 'lodash'

    export default {

        components: {
            DatePicker,
            AccountModal,
            AdminActions
        },

        props: ['blob', 'modal', 'imgSrc', 'group'],

        data () {
            return {
                base64img: '',
                edit: false,
                cities: [],
                visibilityOptions: [
                    {
                        class: 'fa fa-globe',
                        value: null,
                        name: 'Tutti',
                        description: 'Visibile da chiunque'
                    },
                    {
                        class: 'fa fa-lock',
                        value: 'private',
                        name: 'Solo io',
                        description: 'Visibile solo a me e agli amministratori'
                    },
                ],
                durationOptions: [
                    {
                        value: 4,
                        name: '4 ore: mezza giornata'
                    },
                    {
                        value: 8,
                        name: '8 ore: una giornata'
                    },
                    {
                        value: 16,
                        name: '16 ore: due giornate'
                    }
                ]
            }
        },

        watch: {
            'visibility': function () {
                if (!this.edit && this.user.id === this.$auth.user.id && this.certificate.certificate.id) {
                    this.saveCertificate(false);
                }
            }
        },

        computed: {
            ...mapState({
                certificate: 'certificate',
            }),
            ...mapGetters({
                isEditable: 'user/IS_EDITABLE_BY_USER'
            }),
            ...mapFields('certificate', [
                'certificate.title',
                'certificate.diploma',
                'certificate.account_id',
                'certificate.institute',
                'certificate.duration_in_hours',
                'certificate.day',
                'certificate.month',
                'certificate.year',
                'certificate.type',
                'certificate.status',
                'certificate.visibility'
            ]),
            user () {
                return this.$store.state.certificate.certificate.entity ? this.$store.state.certificate.certificate.entity : this.$auth.user;
            },
            editable () {
                return (this.edit || !this.certificate.certificate.id) && this.isEditable(this.$auth.user)
            },
            stringBlob () {

                if (!this.blob) return '';

                let reader = new FileReader();
                reader.readAsDataURL(this.blob);
                reader.onload = () => {
                    this.base64img = reader.result;
                }
                return this.base64img
            },
            visibilityOption () {

                return _.find(this.visibilityOptions, { value: this.visibility})
            },
            durationOptionName () {

                if (!this.duration_in_hours) return ''

                let durationOption =  _.find(this.durationOptions, { value: this.duration_in_hours})
                if (durationOption) {
                    return durationOption.name
                }
            }
        },

        methods: {
            ...mapMutations({
                setStep: 'profile-wizard/SET_STEP',
                setCertificateImgSrc: 'certificate/SET_IMG_SRC',
                setCertificateAccount: 'certificate/SET_CERTIFICATE_ACCOUNT',
                setCertificateAccountId: 'certificate/SET_CERTIFICATE_ACCOUNT_ID',
                setCertificateVisibility: 'certificate/SET_CERTIFICATE_VISIBILITY'
            }),
            ...mapActions({
                'refetchUser': 'user/refetch',
                'verifyUser': 'users/verify',
                'refetchCertificate': 'certificate/refetch',
                'requestVerification': 'certificate/requestVerification',
                'delete': 'certificates/delete'

            }),
            openAccountModal () {
                if (this.editable) {

                    this.$refs.accountModal.showModal()
                }
            },
            toggleEditMode () {
              this.edit = !this.edit
            },
            searchCities: _.debounce((loading, search, vm) => {
                if (search.length > 1) {
                    loading(true);
                    vm.$axios.get('/api/cities?name=' + search)
                        .then(res => {

                            vm.cities = res.data;

                            loading(false);
                        });
                }
            }, 350),
            onSearchCities (search, loading) {
                this.searchCities(loading, search, this);
            },
            openGalleryModal () {
                this.$refs.galleryImagesModal.showImagesGalleryModal(0);
            },
            closeUploadCertificate () {
                if (this.modal) {
                    this.$nuxt.$emit('close-certificate-modal');
                }
                else if (this.$route.path === '/aggiorna-profilo') {
                    this.setStep('education')
                } else {
                    this.smartGoBack();
                }
            },
            editCertificate () {
                if(this.certificate.certificate.diploma && this.certificate.certificate.status == 'verified') {
                    this.$swal({
                        text: this.$t('Se modifichi questo diploma perderai lo stato di utente verificato. Potrai richiedere nuovamente la verifica dopo aver caricato il nuovo diploma.'),
                        type: 'warning',
                        showCancelButton: true,
                        reverseButtons: true,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        animation: false,
                        cancelButtonText: this.$t('Annulla'),
                        confirmButtonText: this.$t('Conferma'),
                    }).then((result) => {
                        if (result.value) {
                            this.toggleEditMode()
                        }
                    })
                } else {
                    this.toggleEditMode()
                }
            },
            deleteCertificate () {
                this.$swal({
                    text: this.$t('Se elimini questo diploma perderai lo stato di utente verificato. Potrai richiedere nuovamente la verifica dopo aver caricato il nuovo diploma.'),
                    type: 'warning',
                    showCancelButton: true,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    reverseButtons: true,
                    cancelButtonText: this.$t('Annulla'),
                    confirmButtonText: this.$t('Elimina'),
                }).then((result) => {
                    if (result.value) {
                        this.$nuxt.$loading.start();
                        this.delete(this.certificate.certificate).then(() => {
                            this.refetchUser(() => {
                                this.$nuxt.$loading.finish();
                            })
                        }).finally(() => {
                            this.closeUploadCertificate()
                            this.$nuxt.$loading.finish();
                        })
                    }
                })
            },
            saveCertificate (close = true, checkFields = false) {
              if ((!this.certificate.certificate.image_res && !this.blob) || !this.certificate.certificate.account_id || (!this.certificate.certificate.day && !this.certificate.certificate.month && !this.certificate.certificate.year) && checkFields) {
                this.$notify({
                  group: 'messages',
                  type: 'error',
                  title: 'controllare che i campi siano correttamente compilati'
                })
                return
              }

                let action = this.certificate.certificate.id ? 'update' : 'create';

                this.$nuxt.$loading.start();

                this.$store
                    .dispatch('certificate/' + action, this.blob)
                    .then(r => {
                        return this.refetchUser().then(() => {
                            this.toggleEditMode();
                            if (close) {
                                this.closeUploadCertificate()
                            }
                            this.$nuxt.$loading.finish()
                        })

                    }).finally((error) => {
                        this.$nuxt.$loading.finish();
                    })
            },
            publishUser () {
                this.verifyUser({user: this.user}).then(() => {
                    this.refetchUser();
                })
            },
            setImage (e) {

                if (e.target.files.length === 0) return;

                const file = e.target.files[0];

                if (!file.type.includes('image/')) {
                    alert('Please select an image file');
                    return;
                }

                if (typeof FileReader === 'function') {
                    const reader = new FileReader();

                    reader.onload = (event) => {

                        this.$emit('update:imgSrc', event.target.result)
                    };

                    reader.readAsDataURL(file);
                } else {
                    alert('Sorry, FileReader API not supported');
                }
            },
            setAccount (account) {
                this.setCertificateAccount(account)
                this.setCertificateAccountId(account.id)
            },
            openInput (isVisible) {

                if (isVisible && this.editable) {
                    let image = document.getElementById('image-input-' + this._uid);

                    image.click()
                }
            }
        },

        mounted () {
        },
    }
</script>
<style lang="scss" scoped>

    hr {
        margin: 0;
    }

    .image-placeholder {
        height: 200px;
        width: 100%;
    }
    .certificate-image-container {
        width: auto;
        height: 200px;
        > img {
            height: 100%;
            width: 100%;
            object-fit: cover
        }
    }

    .diploma-recap {
        font-size: 16px;
    }

    .edit-button {
        background-color: #a1a1a1;
        color: white;
        border: 1px solid white;
        border-radius: 2px;

        &.bottom {
            bottom: 5px;
        }
        &.right {
            right:5px;
        }
    }
    .check-circle {
        font-size: 28px;
        line-height: 28px;
        width: 25px;
        height: 25px;
    }
</style>
