import { getField, updateField } from 'vuex-map-fields';

export const state = () => ({
    data: {
        account: {
            name: '',
            address: '',
            type: 'beauty-centre',
            vat_id: '',
            postal_code_name: '',
            city_id: '',
            state_name: '',
            route: '',
            latitude: '',
            longitude: '',
        }
    },
    step: 'intro'
})


export const getters = {
    getField
}

export const mutations = {
    updateField,
    SET_STEP (state, step) {
        state.step = step
    },
    SET_ACCOUNT (state, account) {
        state.data.account = account
    },
    SET_ACCOUNT_VAT_ID (state, vatId) {
        state.data.account.vat_id = vatId
    },
}

export const actions = {
    setStep ({ commit }, step) {
        commit('SET_STEP', step)
    }
}