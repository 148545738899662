<template>
    <div class="review-stars d-flex">
        <div class="star-container position-relative d-flex align-items-center justify-content-center">
            <i class="cf-half-star text-brand absolute" v-if="rating >= 0.5 && rating < 1"></i>
            <i class="cf-star" :class="rating > 0 ? 'text-brand' : 'text-light-grey'"></i>
        </div>
        <div class="star-container position-relative d-flex align-items-center justify-content-center">
            <i class="cf-half-star text-brand absolute" v-if="rating >= 1.5 && rating < 2"></i>
            <i class="cf-star" :class="rating >= 2 ? 'text-brand' : 'text-light-grey'"></i>
        </div>
        <div class="star-container position-relative d-flex align-items-center justify-content-center">
            <i class="cf-half-star text-brand absolute" v-if="rating >= 2.5 && rating < 3"></i>
            <i class="cf-star" :class="rating >= 3 ? 'text-brand' : 'text-light-grey'"></i>
        </div>
        <div class="star-container position-relative d-flex align-items-center justify-content-center">
            <i class="cf-half-star text-brand absolute" v-if="rating >= 3.5 && rating < 4"></i>
            <i class="cf-star" :class="rating >= 4 ? 'text-brand' : 'text-light-grey'"></i>
        </div>
        <div class="star-container position-relative d-flex align-items-center justify-content-center">
            <i class="cf-half-star text-brand absolute" v-if="rating >= 4.5 && rating < 5"></i>
            <i class="cf-star" :class="rating >= 5? 'text-brand' : 'text-light-grey'"></i>
        </div>
    </div>
</template>

<script>

    export default {

        props: ['rating', 'hide-score', 'size'],

        mounted () {

        }
    }
</script>

<style lang="scss" scoped>
    .review-stars > div.star-container {
       margin-right: 2%;
    }
</style>