<template>
    <div class="text-center d-flex flex-column h-100">
        <slot name="card-header">
            <div class="card-header card-header-sm  d-flex justify-content-center align-items-center">
                <i class="cf-back absolute p-3" style="left: 0;" @click.prevent="previousStep()"></i>
                <span>{{ $t('Aggiungi l\'indirizzo') }}</span>
            </div>
        </slot>
        <div class="card-body p-0" v-if="step === 'address-data'">
            <div class="font-size-lg font-weight-xbold text-left pt-2 px-3">
                {{ $t('Inserisci l\'indirizzo completo') }}
                <span v-if="type === 'beauty-centre'">{{ $t('del tuo centro estetico') }}</span>
                <span v-else-if="type === 'school'">{{ $t('della tua scuola di estetica') }}</span>
                <span v-else>{{ $t('del tuo luogo di lavoro') }}</span>
            </div>
            <div class="address-item font-size-xl">
                <input v-model="myAddress.route" class="w-100" :placeholder="$t('Indirizzo completo')">
            </div>
            <div class="d-flex">
                <div class="address-item font-size-xl mr-3">
                    <input v-model="myAddress.street_number" class="w-100" :placeholder="$t('Numero civico')">
                </div>
                <div class="address-item font-size-xl ml-3">
                    <input v-model="myAddress.postal_code_name" class="w-100" :placeholder="$t('CAP')">
                </div>
            </div>

            <div class="address-item font-size-xl">
                <div class="w-100 text-left" @click.prevent="showingSearch = 'city'">
                    <span class="text-light-grey" style="height: 34px" v-if="!myAddress.city">
                        {{ $t('Città') }}
                    </span>
                    <span v-else style="height: 34px">{{ myAddress.city.name }}</span>
                </div>
                <select-picker
                        url="/api/cities"
                        v-if="showingSearch === 'city'"
                        v-model="myAddress.city_id"
                        :cancel-callback="hideSearch"
                        :save-callback="updateAccount"
                        :initial-object="myAddress.city"
                >
                    <template slot="picker-title">
                        {{ $t('Seleziona città') }}
                    </template>
                    <template slot="options-title">
                        {{ $t('Lista città') }}
                    </template>
                </select-picker>
            </div>
        </div>
        <div class="card-body p-0" v-if="step === 'confirm-address'">
            <div class="px-3 py-4 border-bottom text-left" v-if="!suggestedAddress || (suggestedAddress && (originalAddress.formatted_address.toLowerCase() !== suggestedAddress.formatted_address.toLowerCase()))">
                <div class="d-flex" @click.prevent="selectedAddress = 'original'">
                    <i class="fa fa-check-circle fa-lg text-primary" v-if="selectedAddress === 'original'"></i>
                    <i class="fa fa-circle-thin fa-lg" v-else></i>
                    <div class="d-flex flex-column ml-3">
                        <strong class="mb-1">{{ $t('Indirizzo originale:') }}</strong>
                        <span v-if="originalAddress" class="address-string">
                            <span v-if="originalAddress.route">{{ originalAddress.route }}</span>
                            <span v-if="originalAddress.street_number">{{ originalAddress.street_number }}</span><span v-if="originalAddress.street_number && originalAddress.locality">,</span>
                            <span v-if="originalAddress.locality">{{ originalAddress.locality }}</span><span v-if="originalAddress.locality && originalAddress.postal_code_name">,</span>
                            <span v-if="originalAddress.postal_code_name">{{ originalAddress.postal_code_name }}</span><span v-if="originalAddress.postal_code_name && originalAddress.state">,</span>
                            <span v-if="originalAddress.state">{{ originalAddress.state }}</span><span v-if="originalAddress.state && originalAddress.country">,</span>
                            <span v-if="originalAddress.country">{{ originalAddress.country }}</span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="px-3 py-4 border-bottom text-left" v-if="suggestedAddress">
                <div @click.prevent="selectedAddress = 'suggested'">
                    <div class="d-flex mb-2">
                        <i class="fa fa-check-circle fa-lg text-primary" v-if="selectedAddress === 'suggested'"></i>
                        <i class="fa fa-circle-thin fa-lg" v-else></i>
                        <div class="d-flex flex-column ml-3">
                            <strong class="mb-1">{{ $t('Indirizzo suggerito:') }}</strong>
                            <div v-if="suggestedAddress" class="address-string">
                                <span v-if="suggestedAddress.route"
                                      :class="{'error': !checkAddressComponent('route') }"
                                >{{ suggestedAddress.route }}</span>
                                <span v-if="suggestedAddress.street_number"
                                      :class="{'error': !checkAddressComponent('street_number') }"
                                >{{ suggestedAddress.street_number }}</span><span v-if="suggestedAddress.street_number && suggestedAddress.locality">,</span>
                                <span v-if="suggestedAddress.locality"
                                      :class="{'error': !checkAddressComponent('locality') }"
                                >{{ suggestedAddress.locality }}</span><span v-if="suggestedAddress.locality && suggestedAddress.postal_code_name">,</span>
                                <span v-if="suggestedAddress.postal_code_name"
                                      :class="{'error': !checkAddressComponent('postal_code_name') }"
                                >{{ suggestedAddress.postal_code_name }}</span><span v-if="suggestedAddress.postal_code_name && suggestedAddress.state">,</span>
                                <span v-if="suggestedAddress.state"
                                      :class="{'error': !checkAddressComponent('state') }"
                                >{{ suggestedAddress.state }}</span><span v-if="suggestedAddress.state && suggestedAddress.country">,</span>
                                <span v-if="suggestedAddress.country"
                                      :class="{'error': !checkAddressComponent('country') }"
                                >{{ suggestedAddress.country }}</span>
                            </div>
                        </div>
                    </div>
                    <GmapMap
                            v-if="(suggestedAddress.latitude && suggestedAddress.longitude)"
                            :center="{'lat': suggestedAddress.latitude, 'lng': suggestedAddress.longitude}"
                            :zoom="12"
                            class="w-100"
                            style="height: 40vw; max-height: 200px"
                            :options="{
                                   zoomControl: false,
                                   mapTypeControl: false,
                                   scaleControl: false,
                                   streetViewControl: false,
                                   rotateControl: false,
                                   fullscreenControl: false,
                                   disableDefaultUi: false,
                                   draggable: false
                                 }"
                    >
                        <GmapMarker
                                :icon="require('assets/images/icons/map-marker.svg')"
                                :position="{'lat': suggestedAddress.latitude, 'lng': suggestedAddress.longitude}"
                                :clickable="true"
                        />
                    </GmapMap>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <button class="btn btn-brand w-100" @click.prevent="confirmData()" :disabled="!(myAddress.route && myAddress.city_id && myAddress.postal_code_name && myAddress.street_number)" v-if="step === 'address-data'">
                {{ $t('Avanti') }}
            </button>
            <button class="btn btn-brand w-100" @click.prevent="confirmAddress()" v-if="step === 'confirm-address'">
                {{ $t('Avanti') }}
            </button>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState, mapMutations } from 'vuex'
    import { mapFields } from 'vuex-map-fields'
    import SelectPicker from "~/components/SelectPicker";

    export default {

        components: {
            SelectPicker
        },

        props: [
            'type',
            'account',
            'set-step',
            'owner'
        ],

        data() {
            return {
                step: 'address-data',
                showingSearch: '',
                myAddress: {
                    route: this.account.route,
                    postal_code_name: this.account.postal_code_name,
                    street_number: this.account.street_number,
                    city_id: this.account.city_id,
                    city: this.account.city,
                },
                googleResponse: null,
                selectedAddress: 'suggested'
            }
        },

        computed: {
            ...mapState({
                'user': 'user',
                'insets': 'insets',
            }),
            originalAddress() {
                return this.googleResponse ? this.googleResponse.original_address : null;
            },
            suggestedAddress() {
                return this.googleResponse ? this.googleResponse.suggested_address : null;
            }
        },

        methods: {
            ...mapActions({
                'refetchUser': 'user/refetch'
            }),
            previousStep() {
                if(this.step === 'confirm-address') {
                    this.step = 'address-data'
                } else {
                    this.setStep('search-accounts')
                }
            },
            updateAccount(city) {
                this.myAddress.city = city
                this.hideSearch()
            },
            hideSearch() {
                this.showingSearch = ''
            },
            checkAddressComponent(param) {
                return this.suggestedAddress[param] && this.originalAddress[param] && (this.suggestedAddress[param].toLowerCase() === this.originalAddress[param].toLowerCase())
            },
            confirmData() {

                this.$nuxt.$loading.start()

                this.$axios.get('/api/geolocate-address', {
                    params: {
                        'route': this.myAddress.route,
                        'street_number': this.myAddress.street_number,
                        'city_id': this.myAddress.city_id,
                        'postal_code_name': this.myAddress.postal_code_name,
                    }
                })
                    .then(r => r.data)
                    .then((response) => {
                        this.$nuxt.$loading.finish()
                        this.googleResponse = response;
                        this.step = 'confirm-address'
                    }).catch((error) => {
                        this.$nuxt.$loading.finish()

                        this.$notify({
                            group: 'messages',
                            type: 'error',
                            text: this.$t('Errore, riprova più tardi.')
                        })
                    })
            },
            confirmAddress() {

                let address = this.selectedAddress === 'original' ? this.originalAddress : this.suggestedAddress;

                this.$emit('update:address', address.formatted_address);
                this.$emit('update:route', address.route);
                this.$emit('update:street_number', address.street_number);
                this.$emit('update:postal_code_name ', address.postal_code_name);
                this.$emit('update:city_id', this.myAddress.city_id);
                this.$emit('update:state_name', address.state);
                this.$emit('update:latitude', address.latitude);
                this.$emit('update:longitude', address.longitude);

                this.setStep('confirm-account-data')
            },
        },

        mounted () {
        },
    }
</script>

<style lang="scss" scoped>
    .error {
        border: 1px solid red;
        color: red;
        padding: 0 2px;
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;
    }
    .address-string {
        line-height: 1.5;
    }
    .address-item {
        border-bottom: 1px solid #DBDBDB;
        padding: 12px;

        input {
            &::placeholder {
                color: #DBDBDB;
            }
        }
    }
</style>