import Pusher from 'pusher-js'
import Echo from "laravel-echo"

export default ({ app }, inject) => {

    inject('echo', new Echo({
        broadcaster: 'pusher',
        key: process.env.PUSHER_KEY,
        cluster: 'eu',
        encrypted: true,
        authEndpoint: process.env.SERVER_BASE_URL + '/api/echo/auth',
        auth: { headers: {Authorization: localStorage.getItem('auth._token.local') ? localStorage.getItem('auth._token.local') : '' } },
        namespace: ''
    }))
}