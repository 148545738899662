<template>
    <div class="info-card">
        <component :limit="limit" :is="workExperienceComponent"></component>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {

        props: ['limit'],

        computed: {
            ...mapState([
                'mobileMode'
            ]),
            workExperienceComponent() {
                return () => import('~/components/UserProfile/ProfileInfo/Mobile/WorkExperiencesCard')
                // return () => import('~/components/UserProfile/ProfileInfo/' + ( this.mobileMode ? 'Mobile' : 'Desktop' ) +'/WorkExperiencesCard')
            }
        }
    }
</script>
