<template>
    <div class="login-box">
        <form @submit.prevent="submit" class="d-none d-lg-block">

            <input type="hidden" name="_token" :value="auth.accessToken" />

            <div class="row row-eq-height mt-3">
                <div class="col-auto">
                    <label class="sr-only">E-mail</label>
                    <input type="text" name="email" class="form-control from-control-flat" placeholder="E-mail" v-model="email" autocapitalize="none" />
                </div>
                <div class="col-auto">
                    <label class="sr-only">Password</label>
                    <input type="password" name="password" class="form-control from-control-flat" placeholder="Password" v-model="password" />
                    <small>
                        <nuxt-link to="/password-reset" class="text-white">{{ $t('Password dimenticata?')}}</nuxt-link>
                    </small>
                </div>
                <div class="col-auto">
                    <button type="submit" @click.prevent="submit" class="btn btn-brand" :disabled="processing">
                        <span v-if="!processing">{{ $t('Accedi') }}</span>
                        <span v-else><i class="fa fa-spinner fa-spin"></i> Accesso in corso...</span>
                    </button>
                </div>
            </div>
        </form>

        <!--<nuxt-link to="/login" class="btn btn-brand d-block d-lg-none">-->
            <!--{{ $t('Accedi') }}-->
        <!--</nuxt-link>-->
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'

    export default {

        data() {
            return {
                email: '',
                password: '',
                processing: false
            }
        },

        computed: {
            ...mapState([
                'auth'
            ])
        },

        methods: {
            ...mapActions({
                'login': 'user/login'
            }),
            submit() {

                this.processing = true;

                this.login({
                        username: this.email,
                        password: this.password
                    })
                    .then(() => {

                        this.$router.push('/dashboard')
                    })
                    .catch((response) => {

                        this.$notify({
                            group: 'messages',
                            type: 'error',
                            title: 'Le credenziali inserite non risultano essere corrette. Si prega di riprovare.'
                        });

                        this.processing = false;
                    })
            }
        }
    }
</script>