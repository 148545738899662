<template>
    <div class="error-500 text-center">
        <i class="la la-times-circle-o text-danger"></i>
        <div class="font-size-2x my-5 text-center text-lg-left px-4 px-lg-0">{{ $t('Oops! Qualcosa è andato storto.')}}</div>
        <button class="btn btn-brand m-auto" @click.prevent="$router.push('/')">{{ $t('Torna alla dashboard')}}</button>
    </div>
</template>
<script>
    export default {
        name: 'error-500',
        props: {
            error: {
                type: Object,
                default: () => {},
            },
        },
    };
</script>