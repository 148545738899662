
import Vue from 'vue'
import axios from 'axios'

export default function ({ app, $axios, redirect }) {

    $axios.onRequest(config => {
        console.log('Making request to ' + config.url)
    })

    $axios.onError(error => {

        const code = parseInt(error.response && error.response.status)

        if (process.client) {
          
            if (axios.isCancel(error)) {

            } else if (code === 422) {

                let message = error.response.data.errors[Object.keys(error.response.data.errors)[0]][0];

                Vue.notify({
                    group: 'validation-messages',
                    type: 'error',
                    text: message
                })
            }
        }
    })
}