import Vue from 'vue'
import { getField, updateField } from 'vuex-map-fields';
import _ from 'lodash'

export const state = () => ({
    pagination: {
        page: 0,
        per_page: 20,
    },
    notifications: [],
    unreadNotificationsCount: 0,
    totalCount: 0,
    infiniteScrollEnabled: true,
    processing: false
})

export const getters = {
    getField,
    getOrderedNotifications (state) {
      return _.orderBy(state.notifications, 'created_at', 'desc')
    },
    getLatestNotifications (state) {
        return _.orderBy(state.notifications, 'created_at' , 'desc').slice(0, 5);
    },
    getUnreadNotifications (state) {
        return state.unreadNotificationsCount;
    }
}

export const mutations = {
    updateField,
    SET_PROCESSING (state, processing) {
        state.processing = processing
    },
    APPEND_NOTIFICATION (state, notification) {

        if(!_.includes(_.map(state.notifications, 'id'), notification.id)  || !notification.id) {

            state.notifications.push(notification);
        } else {

            let notificationIndex = _.findIndex(state.notifications, {id: notification.id});

            Vue.set(state.notifications, notificationIndex, notification);
        }
    },
    SET_INFINITE_SCROLL_ENABLED (state, infiniteScrollEnabled) {
        state.infiniteScrollEnabled = infiniteScrollEnabled
    },
    SET_UNREAD_NOTIFICATIONS_COUNT (state, unreadNotificationsCount) {
        state.unreadNotificationsCount = unreadNotificationsCount
    },
    SET_TOTAL_COUNT (state, totalCount) {
        state.totalCount = totalCount
    }
}

export const actions = {

    fetch ({ commit, state, dispatch }, data) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .get('/api/notifications', {
                params: {
                    page: data && data.page ? data.page : state.pagination.page,
                    per_page: data && data.per_page ? data.per_page : state.pagination.per_page
                }
            })
            .then(r => r.data)
            .then(response => {

                _.each(response.data, (notification) => {

                    commit('APPEND_NOTIFICATION', notification);
                });

                commit('SET_UNREAD_NOTIFICATIONS_COUNT', response.unread_notifications_count);
                commit('SET_TOTAL_COUNT', response.total);
                commit('SET_INFINITE_SCROLL_ENABLED', response.data.length >= ( data && data.per_page ? data.per_page : state.pagination.per_page));
                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },

    clear () {
        return this.$axios.post('/api/notifications/clear')

    },

    read ({ state }, notification) {
        return this.$axios.post('/api/notifications/' +  notification.id + '/read')

    },

    readAll ({ state }) {
        return this.$axios.post('/api/notifications/read-all')

    }
}