<template>
    <div class="logo">
        <div class="d-flex" @click.prevent="">
            <nuxt-link to="/dashboard">
                <img src="~assets/images/ico-w.png" class="icon" />
            </nuxt-link>
            <nuxt-link to="/dashboard" v-if="!$auth.loggedIn" class="d-none d-lg-block">
                <img src="~assets/images/logo-w.png" class="logo ml-2" />
            </nuxt-link>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import UserImage from '~/components/UserImage'

    export default {

        components: {
            UserImage
        },

        computed: {
            ...mapState([
                'menuOpen'
            ])
        },

        methods: {
            closeMenu() {

                if (this.menuOpen) {
                    let event = {
                        event: 'close-menu'
                    }

                    if (window.ReactNativeWebView) {
                        window.ReactNativeWebView.postMessage(JSON.stringify(event));
                    } else {
                        window.postMessage(JSON.stringify(event), '*');
                    }
                }
            }
        }
    }
</script>