import Vue from 'vue'
import { getField, updateField } from 'vuex-map-fields';
import _ from 'lodash'
import moment from "moment-timezone";

export const state = () => ({
    filters: {
        text: null,
        group_id: null,
        type: 'group-post'
    },
    sort: {
        column: 'created_at',
        order: 'desc'
    },
    pagination: {
        page: 0,
        per_page: 5,
        last_page: 0,
    },
    posts: [],
    topPosts: [],
    latestNews: [],
    totalCount: 0,
    infiniteScrollEnabled: true,
    processing: false
})

export const getters = {
    getField,
    getTopArticles: (state) => (count) => {
        return _.orderBy(_.take(_.filter(state.topPosts, function (post) {
            return post.is_article
        }), count), 'user_score', 'desc');
    },
    getLatestNews: (state) => (count) => {
        return _.orderBy(_.take(_.filter(state.latestNews, function (post) {
            return post.is_communication
        }), count), 'published_at', 'desc');
    },
    getPostsForDashboard: (state) => (groupId) => {

        if(!groupId) return [];

        return _.orderBy(_.filter(state.posts, function (post) {
            return ((post.group_id === groupId && post.type === 'group-post') || post.type !== 'group-post') && !post.from_notifications && post.from_dashboard
        }), 'last_comment_date', 'desc')
    },
    getPostByPermalink: (state) => (permalink) => {

        let posts = state.posts.filter((post) => {
            return post.permalink === permalink
        })

        return posts.length ? posts[0] : null;
    },

    getFakeLikesCount: (state) => (post) => {

        if (post && (post.layout === 'article' || post.layout === 'communication')) {

            let elapsedMonths = moment.duration(moment().diff(moment(post.created_at))).as('months')
            const likes = Math.floor(1521.28 * Math.log(24.7885 * elapsedMonths) * (post.second_year_likes / 10000))
            return likes > 0 ? likes : 0;
        }

        return 0;
    },
    getFilteredPosts: (state) => (filters) => {

        let sort = state.sort

        if(filters.hasOwnProperty('sort')) {
            sort = filters.sort;
        }

        // Recursive get Children
        let getChildren = (post) => {
            let children = _.clone(post.all_children);

            let found = [];

            _.each(children, (child) => {

                let deepChildren = getChildren(child);

                _.each(deepChildren, myChild => found.push(myChild))
            })

            _.each(found, child => children.push(child));

            return children
        }

        let filteredPosts = _.filter(state.posts, function (post) {

            let passes = true;

            if(filters.hasOwnProperty('group_id')) {
                passes = passes && (post.group_id == filters.group_id)
            }

            if(filters.hasOwnProperty('user_id') && filters.user_id) {
                passes = passes && (post.user_id === filters.user_id)
            }

            if(filters.hasOwnProperty('type') && filters.type) {
                passes = passes && (post.type === filters.type)
            }

            if(filters.hasOwnProperty('layout')) {
                passes = passes && (post.layout === filters.layout)
            }

            if(filters.hasOwnProperty('menu')) {
                passes = passes && (post.menu === filters.menu)
            }

            if(filters.hasOwnProperty('permalink')) {
                passes = passes && post.permalink.match(new RegExp(filters.permalink, 'i'))
            }

            if(filters.hasOwnProperty('parent_id')) {
                passes = passes && (post.parent_id === filters.parent_id)
            }

            if(filters.hasOwnProperty('category')) {
                if(filters.category) {
                    passes = passes && (post.layout === 'category')
                } else {
                    passes = passes && (post.layout !== 'category')

                }
            }

            if(filters.hasOwnProperty('from_notifications')) {
                passes = passes && (post.from_notifications === filters.from_notifications)
            } else {
                passes = passes && (!post.from_notifications)
            }

            if(filters.hasOwnProperty('text') && filters.text && filters.text.length) {

                let children = _.clone(post.all_children);
                _.each(post.all_children, myChild => {

                    let deepChildren = getChildren(myChild);
                    _.each(deepChildren, myChild => children.push(myChild))
                });

                passes = passes && (
                    (post.user ? post.user.name.match(new RegExp(filters.text, 'i')) : false) ||
                    (post.title ? post.title.match(new RegExp(filters.text, 'i')) : false) ||
                    (post.abstract ? post.abstract.match(new RegExp(filters.text, 'i')) : false) ||
                    (post.text ? post.text.match(new RegExp(filters.text, 'i')) : false) ||
                    _.findIndex(children, child => {
                        return (child.title ? child.title.match(new RegExp(filters.text, 'i')) : false) ||
                        (child.abstract ? child.abstract.match(new RegExp(filters.text, 'i')) : false) ||
                        (child.text ? child.text.match(new RegExp(filters.text, 'i')) : false)
                    }) !== -1
                )
            }

            if(filters.hasOwnProperty('new')) {
                passes = passes && (post.new == filters.new)
            } else {
                passes = passes && !post.new
            }

            return passes
        })

        return _.orderBy(filteredPosts, sort.column ? sort.column : 'created_at', sort.order ? sort.order : 'desc')
    }
}

export const mutations = {
    updateField,
    SET_GROUP_ID_FILTER (state, groupId) {
        state.filters.group_id = groupId
    },
    SET_USER_ID_FILTER (state, userId) {
        state.filters.user_id = userId
    },
    SET_FILTERS (state, filters) {
        state.filters = filters
    },
    SET_PROCESSING (state, processing) {
        state.processing = processing
    },
    SET_POSTS (state, posts) {
        state.posts = posts
    },
    APPEND_POST (state, post) {

        let index = state.posts.findIndex(myPost => {
            return myPost.id === post.id
        })

        if(index < 0 || !post.id) {

            state.posts.push(post);
        } else {

            post.from_notifications = post.hasOwnProperty('from_notifications') ? post.from_notifications : state.posts[index].from_notifications;
            post.from_dashboard = post.hasOwnProperty('from_dashboard') ? post.from_dashboard : state.posts[index].from_dashboard;
            post.new = post.hasOwnProperty('new') ? post.new : state.posts[index].new;

            post.children = post.hasOwnProperty('children') ? post.children : state.posts[index].children;
            post.menu = post.hasOwnProperty('menu') ? post.menu : state.posts[index].menu;

            Vue.set(state.posts, index, post)
        }
    },
    LIKE_POST_BY_ID (state, postId) {

        let myPost = _.find(state.posts, {id: postId});

        if(myPost) {
            Vue.set(myPost, 'liked', !myPost.liked)
            Vue.set(myPost, 'likes_count', myPost.liked ? myPost.likes_count + 1 : myPost.likes_count - 1)
        }
    },
    UPDATE_POST_BY_NAME (state, post) {

        let myPostIndex = _.findIndex(state.posts, {name: post.name});

        if(myPostIndex > -1) {
            post.from_notifications = state.posts[myPostIndex].from_notifications;
            post.new = state.posts[myPostIndex].new;

            Vue.set(state.posts, myPostIndex, post)
        }
    },
    INCREMENT_LIKES_COUNT (state, post) {
        let myPost = _.find(state.posts, {id: post.id});

        if(myPost) {
            Vue.set(myPost, 'likes_count', myPost.likes_count + 1);
        }

    },
    REMOVE_POST (state, post) {
        let postIndex = _.findIndex(state.posts, {id: post.id});

        if (postIndex > -1) {
            state.posts.splice(postIndex, 1);
        }
    },
    APPEND_POSTS (state, posts) {

        _.each(posts, (post) => {
            state.posts.push(post);
        });
    },
    SET_INFINITE_SCROLL_ENABLED (state, infiniteScrollEnabled) {
        state.infiniteScrollEnabled = infiniteScrollEnabled
    },
    SET_TOTAL_COUNT (state, totalCount) {
        state.totalCount = totalCount
    },
    SET_LAST_PAGE (state, lastPage) {
        state.pagination.last_page = lastPage
    },
    SET_TOP_POSTS (state, posts) {
        state.topPosts = posts
    },
    SET_LATEST_NEWS (state, posts) {
        state.latestNews = posts
    }
}

export const actions = {

    fetchById ({ dispatch, commit, state}, data) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .get('/api/posts/' + data.id, {
                params: {
                    notifications: data.notifications
                }
            })
            .then(r => r.data)
            .then(post => {
                commit('APPEND_POST', post)
                if(post.user) {
                    commit('users/APPEND_USER', post.user, {root: true})
                }
                commit('post/SET_POST', post, {root: true});

                if(post.comments && post.comments.length) {
                    dispatch('comments/appendComments', post.comments, {root: true});
                }

                if(post.images && post.images.length) {
                    dispatch('post-images/appendPostImages', post.images, {root: true});

                }

                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                // throw error;
                console.log('fetch by id error: ' + error)

            })
    },

    fetchByPermalink ({ dispatch, commit, state}, data) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .get('/api/posts/permalink', {
                params: {
                    permalink: data.permalink,
                    notifications: data.notifications
                }
            })
            .then(r => r.data)
            .then(post => {
                commit('APPEND_POST', post)
                if(post.user) {
                    commit('users/APPEND_USER', post.user, {root: true})
                }
                commit('post/SET_POST', post, {root: true});

                if(post.comments && post.comments.length) {
                    dispatch('comments/appendComments', post.comments, {root: true});
                }

                if(post.images && post.images.length) {
                    dispatch('post-images/appendPostImages', post.images, {root: true});
                }

                commit('SET_PROCESSING', false)

                return post
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },

    fetch ({ commit, state, dispatch }, data) {

        commit('SET_PROCESSING', true);

        commit('SET_GROUP_ID_FILTER', data.group_id ? data.group_id : null);
        commit('SET_USER_ID_FILTER', data.user_id ? data.user_id : null);

        let filters = data.filters ?  data.filters : state.filters;
        let sort = data.sort ?  data.sort : state.filters;

        return this.$axios
            .get('/api/posts', {
                params: {
                    ...sort,
                    ...filters,
                    page: data && data.page ? data.page : state.pagination.page,
                    per_page: data && data.per_page ? data.per_page : state.pagination.per_page
                }
            })
            .then(r => r.data)
            .then(response => {

                if(state.posts.length) {

                    _.each(response.data, (post) => {

                        commit('APPEND_POST', post);
                        if(post.user) {
                            commit('users/APPEND_USER', post.user, {root: true})
                        }
                        dispatch('comments/appendComments', post.comments, { root: true });
                        dispatch('post-images/appendPostImages', post.images, {root: true});
                    });
                } else {
                    commit('SET_POSTS', response.data);
                }

                commit('SET_TOTAL_COUNT', response.total);
                commit('SET_LAST_PAGE', response.last_page);
                commit('SET_INFINITE_SCROLL_ENABLED', response.data.length >= ( data && data.per_page ? data.per_page : state.pagination.per_page));
                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
                // console.log('fetch error: ' + error)
            })
    },

    fetchLatestNews ({ dispatch, commit, state}, data) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .get('/api/latest-news')
            .then(r => r.data)
            .then(posts => {

                commit('SET_LATEST_NEWS', posts)

                _.each(posts, function (post) {
                    if(post.user) {
                        commit('users/APPEND_USER', post.user, {root: true})
                    }

                    if(post.comments && post.comments.length) {
                        dispatch('comments/appendComments', post.comments, {root: true});
                    }

                    if(post.images && post.images.length) {
                        dispatch('post-images/appendPostImages', post.images, {root: true});
                    }
                })

                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },

    fetchTopPosts ({ dispatch, commit, state}, data) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .get('/api/top-posts', {
                params: {
                    permalink: data.permalink,
                }
            })
            .then(r => r.data)
            .then(posts => {

                commit('SET_TOP_POSTS', posts)

                _.each(posts, function (post) {
                    if(post.user) {
                        commit('users/APPEND_USER', post.user, {root: true})
                    }

                    if(post.comments && post.comments.length) {
                        dispatch('comments/appendComments', post.comments, {root: true});
                    }

                    if(post.images && post.images.length) {
                        dispatch('post-images/appendPostImages', post.images, {root: true});
                    }
                })

                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },

    fetchMenuItems ({ commit, state, dispatch }, data) {

        commit('SET_PROCESSING', true);

        return this.$axios
            .get('/api/posts/menu-items', {
                params: {
                    permalink: data.permalink
                }
            })
            .then(r => r.data)
            .then(response => {

                if(state.posts.length) {

                    _.each(response.data, (post) => {

                        commit('APPEND_POST', post);
                    });
                } else {
                    commit('SET_POSTS', response.data);
                }

                commit('SET_TOTAL_COUNT', response.total);
                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },

    toggleMenuById ({ commit, state}, postId) {

        return this.$axios
            .post('/api/posts/' + postId + '/toggle-menu')
            .then(r => r.data)
            .then(post => {
                commit('APPEND_POST', post);
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },

    likePostById ({ commit, state}, postId) {

        commit('SET_PROCESSING', true)
        commit('LIKE_POST_BY_ID', postId);

        return this.$axios
            .post('/api/posts/' + postId + '/like')
            .then(r => r.data)
            .then(post => {
                commit('SET_PROCESSING', false)
                commit('APPEND_POST', post);
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },

    followById ({ commit, state}, postId) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .post('/api/posts/' + postId + '/follow')
            .then(r => r.data)
            .then(post => {
                commit('SET_PROCESSING', false)
                commit('APPEND_POST', post);

                return post;
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },

    unfollowById ({ commit, state}, postId) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .post('/api/posts/' + postId + '/unfollow')
            .then(r => r.data)
            .then(post => {
                commit('SET_PROCESSING', false)
                commit('APPEND_POST', post);

                return post;
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },

    delete ({ commit, state}, post) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .delete('/api/posts/' + post.id)
            .then(r => r.data)
            .then(res => {
                commit('REMOVE_POST', post)
                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },

    appendPosts ({commit, dispatch}, posts) {

        if(!posts || !_.size(posts)) return;

        _.each(posts, (post) => {

            commit('APPEND_POST', post);

            if(post.user) {
                commit('users/APPEND_USER', post.user, {root: true})
            }

            if(post.comments && post.comments.length) {
                dispatch('comments/appendComments', post.comments, {root: true});
            }

            if(post.images && post.images.length) {
                dispatch('post-images/appendPostImages', post.images, {root: true});
            }
        });
    },
}