<template>
    <div class="card h-100 text-center">
        <div class="card-header card-header-sm  d-flex justify-content-center align-items-center">
            <i class="cf-back absolute p-3" style="left: 0;" @click.prevent="setWorkExperienceStep('search-accounts')"></i>
            <span v-if="type === 'school'">{{ $t('Aggiungi una scuola di estetica') }}</span>
            <span v-else>{{ $t('Collegati ad un luogo di lavoro') }}</span>
        </div>
        <div class="card-body p-3">
            <div class="image-container mb-2">
                <img alt="step-07" src="~/assets/images/steps/step-07.png">
            </div>
            <strong class="title p-3">
                <span v-if="workExperience.workExperience.account.id && workExperience.workExperience.type === 'owner'">{{ $t('Dove si trova il tuo centro estetico') }}</span>
                <span v-else>
                    <span v-if="type !== 'school'">{{ $t('Aggiungi l\'indirizzo del centro estetico:')}}</span>
                    <span v-else>{{ $t('Aggiungi l\'indirizzo della scuola:')}}</span>
                    <br>
                    «{{ workExperience.workExperience.account.name }}»
                </span>
            </strong>
            <div class="description p-4"> {{ $t('Per individuare meglio la struttura è importante inserire l\'indirizzo corretto')}}</div>

            <div @click.prevent="$refs.accountAddress.openModal()" class="font-size-extra mt-3 border-bottom d-lg-none d-block pb-2">
                <span v-if="workExperience.workExperience.account.address">{{ workExperience.workExperience.account.address }}</span>
                <span class="text-light-grey font-weight-light font-size-2x" v-else>{{ $t('Clicca per aggiungerlo') }}</span>
            </div>
            <account-address-modal v-bind.sync="workExperience.workExperience.account" ref="accountAddress" :account="workExperience.workExperience.account" :type="type"></account-address-modal>

            <div class="d-lg-block d-none">
                <vue-google-autocomplete id="map"
                                         classname="form-control form-control-sm mb-2"
                                         v-on:placechanged="getAddressData"
                                         :enable-geolocation="true"
                                         :placeholder="$t('Inserisci un indirizzo')"
                                         :country="['it']"
                                         :value="address"
                                         :disabled="workExperience.workExperience.account.id"
                >
                </vue-google-autocomplete>
                <v-select index="id"
                          label="name"
                          class="select-sm mb-2"
                          :options="cities"
                          @search="onSearchCities"
                          :filterable="false"
                          :placeholder="$t('Cerca la tua città')"
                          v-model="city_id"
                          :disabled="workExperience.workExperience.account.id"
                >
                    <template slot="no-options">
                        {{ $t('Inserisci almeno due caratteri')}}
                    </template>
                    <template slot="option" slot-scope="option">
                        <div class="d-center">
                            {{ option.name }}
                        </div>
                    </template>
                    <template slot="selected-option" slot-scope="option" v-if="workExperience.workExperience.account.city && (city_id === workExperience.workExperience.account.city.id)">
                        <div class="d-center">
                            {{ workExperience.workExperience.account.city.name }}
                        </div>
                    </template>
                </v-select>

                <v-select index="id"
                          label="code"
                          class="select-sm"
                          :options="postalCodes"
                          :filterable="false"
                          @search="onSearchPostalCodes"
                          :placeholder="$t('Cerca il tuo CAP')"
                          v-model="postal_code_id"
                          :disabled="workExperience.workExperience.account.id"
                >
                    <template slot="no-options">
                    <span v-if="!workExperience.workExperience.account.city_id">
                        {{ $t('Inserisci prima la città')}}
                    </span>
                        <span v-else>
                        {{ $t('Nessuna opzione disponibile') }}
                    </span>
                    </template>
                    <template slot="option" slot-scope="option">
                        <div class="d-center">
                            {{ option.code }}
                        </div>
                    </template>
                    <template slot="selected-option" slot-scope="option" v-if="workExperience.workExperience.account.postal_code && (postal_code_id == workExperience.workExperience.account.postal_code.id)">
                        <div class="selected d-center">
                            {{ workExperience.workExperience.account.postal_code.code }}
                        </div>
                    </template>
                </v-select>
            </div>
            <div v-if="workExperience.workExperience.type === 'owner'">
                <h4 class="p-3"><strong> {{ $t('Questo è il mio centro estetico.')}}</strong></h4>
            </div>
        </div>
        <div class="card-footer" v-if="workExperience.workExperience.account.address">
            <button class="btn btn-brand w-100" @click.prevent="confirmData()" :disabled="!workExperience.workExperience.account.address">
                <span v-if="workExperience.workExperience.type === 'owner'">{{ $t('Conferma') }}</span>
                <span v-else>{{ $t('Avanti') }}</span>
            </button>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState, mapMutations } from 'vuex'
    import { mapFields } from 'vuex-map-fields'
    import AccountAddressModal from '~/components/WorkingPlace/Steps/AccountAddressModal'
    import _ from 'lodash'

    export default {

        components: {
            AccountAddressModal
        },

        props: ['type'],

        data() {
            return {
                cities: [],
                postalCodes: [],
            }
        },

        computed: {
            ...mapState({
                'user': 'user',
                'account': 'account',
                'workExperience': 'work-experience'
            }),
            ...mapFields('work-experience', [
                'workExperience.account.address',
                'workExperience.account.postal_code_id',
                'workExperience.account.city_id',
                'workExperience.account.latitude',
                'workExperience.account.longitude'
            ])
        },

        methods: {
            ...mapActions({
                'refetchUser': 'user/refetch'
            }),
            ...mapMutations({
                'setWorkExperienceAccount': 'work-experience/SET_WORK_EXPERIENCE_ACCOUNT',
                'setWorkExperienceAccountId': 'work-experience/SET_WORK_EXPERIENCE_ACCOUNT_ID',
                'setWorkExperienceType': 'work-experience/SET_TYPE',
                'setWorkExperienceStep': 'work-experience/SET_STEP'
            }),
            nextStep() {
                this.setWorkExperienceStep('work-experience')
            },
            confirmData() {

                if (!this.workExperience.account.id) {

                    this.$nuxt.$loading.start()

                    this.$store.dispatch('account/create', this.workExperience.account)
                        .then((account) => {

                            this.refetchUser();

                            this.setWorkExperienceAccount(account);
                            this.setWorkExperienceAccountId(account.id);

                            this.$nuxt.$loading.finish()
                            this.nextStep()
                        }).catch((error) => {

                            this.$nuxt.$loading.finish()
                        })
                } else {
                    this.nextStep()
                }
            },
            getAddressData(addressData, placeResultData, id) {

                this.address = addressData.route + (addressData.street_number ? ', ' + addressData.street_number : '');
                this.latitude = addressData.latitude;
                this.longitude = addressData.longitude;
            },
            onSearchCities(search, loading) {
                this.searchCities(loading, search, this);
            },
            onSearchPostalCodes(search, loading) {
                this.searchPostalCodes(loading, search, this);
            },
            searchCities: _.debounce((loading, search, vm) => {

                if (search.length > 1) {
                    loading(true);
                    vm.$axios.get('/api/cities?name=' + search)
                        .then(res => {

                            vm.cities = res.data;
                            loading(false);
                        });
                }
            }, 350),
            searchPostalCodes: _.debounce((loading, search, vm) => {

                if (vm.workExperience.workExperience.account.city_id) {
                    loading(true);
                    vm.$axios.get('/api/postal-codes?code=' + search + '&city_id=' + vm.workExperience.workExperience.account.city_id)
                        .then(res => {

                            vm.postalCodes = res.data;
                            loading(false);
                        });
                }
            }, 350),
        },

        mounted () {
        },
    }
</script>