import Vue from 'vue'
import infiniteScroll from 'vue-infinite-scroll'
import InfiniteLoading from 'vue-infinite-loading'

Vue.use(infiniteScroll)
// Vue.component('infinite-loading', infiniteLoading)
Vue.use(InfiniteLoading, {
    system: {
        throttleLimit: 100
    }
})

export default ({ app }, inject) => {

}