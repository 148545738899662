import Vue from "vue";
import { getField, updateField } from 'vuex-map-fields';
import _ from 'lodash'

export const state = () => ({
    groupBuyCategories: [],
    processing: false
})

export const getters = {
    getField,
    getGroupBuyCategories (state) {
        return state.groupBuyCategories
    }
}

export const mutations = {
    updateField,
    SET_PROCESSING (state, processing) {
        state.processing = processing
    },
    APPEND_GROUP_BUY_CATEGORY (state, groupBuyCategory) {
        if(!_.includes(_.map(state.groupBuyCategories, 'id'), groupBuyCategory.id)) {

            state.groupBuyCategories.push(groupBuyCategory);
        } else {
            let commentIndex = _.findIndex(state.groupBuyCategories, {id: groupBuyCategory.id});

            Vue.set(state.groupBuyCategories, commentIndex, groupBuyCategory);
        }
    },
    REMOVE_GROUP_BUY_CATEGORY (state, groupBuyCategory) {
        let commentIndex = _.findIndex(state.groupBuyCategories, {id: groupBuyCategory.id});

        if (commentIndex > -1) {
            state.groupBuyCategories.splice(commentIndex, 1);
        }
    },
    SET_GROUP_BUY_CATEGORIES (state, groupBuyCategories) {
        state.groupBuyCategories = groupBuyCategories;
    }
}

export const actions = {

    fetch ({ commit, dispatch, state}, data) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .get('/api/group-buy-categories')
            .then(response => {
                if(state.groupBuyCategories.length) {
                    dispatch('appendCategories', response.data)
                } else {
                    commit('SET_GROUP_BUY_CATEGORIES', response.data)
                }
                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },

    appendCategories ({commit, state}, groupBuyCategories) {

        if(!groupBuyCategories || !groupBuyCategories.length) return;

        _.each(groupBuyCategories, (groupBuyCategory) => {

            commit('APPEND_GROUP_BUY_CATEGORY', groupBuyCategory);
        });
    },
}