<template>
  <div class="d-flex flex-column position-fixed w-100" :style="'height: 100vh; height: calc(var(--vh, 1vh) * 100);'" :class="{'guest': !auth.loggedIn}">
      <header-bar class="header-fixed background-brand" :style="'padding-top:' + insets.top + 'px'"  />
      <div class="page boxed flex-grow-1" :class="{'user-select-none': menuOpen, 'dropup-open': dropupOpen}">

          <client-only>
              <notifications class="notification-messages" group="messages" position="top center">
                  <template slot="body" slot-scope="props">
                      <div class="notification-message" :class="props.item.type">
                          <div class="notification-title" v-if="props.item.title">{{ props.item.title }}</div>
                          <div class="notification-text" v-if="props.item.text">{{ props.item.text }}</div>
                      </div>
                  </template>
              </notifications>

              <notifications class="validation-messages" group="validation-messages" position="top center" :style="'top: ' + ((insets ? insets.top : 0) + 20) + 'px'">
                  <template slot="body" slot-scope="props">
                      <div class="validation-message" :class="props.item.type">
                          {{ props.item.text }}
                      </div>
                  </template>
              </notifications>

              <notifications group="communications" :position="mobileMode ? 'top center' : 'bottom left'">
                  <template slot="body" slot-scope="props">
                      <notification-card :notification="props.item"></notification-card>
                  </template>
              </notifications>
          </client-only>
          <nuxt class="w-100" keep-alive/>
      </div>
      <update-app-modal></update-app-modal>
      <footer-bar v-if="!$auth.loggedIn"></footer-bar>

      <bottom-bar class="bottom-fixed position-fixed" :style="'padding-bottom:' + insets.bottom + 'px'"/>
  </div>
</template>

<script>
    import { mapState } from 'vuex'
    import HeaderBar from '~/components/HeaderBar'
    import BottomBar from '~/components/BottomBar'
    import FooterBar from '~/components/Footer'
    import NotificationCard from '~/components/NotificationCard';
    import UpdateAppModal from '~/components/UpdateAppModal';

    import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

    export default {

        components: {
            // DropupMenu,
            HeaderBar,
            BottomBar,
            FooterBar,
            NotificationCard,
            UpdateAppModal
        },

        watch: {
          'auth.loggedIn': function(val) {
              if(val) {

                  this.$store.dispatch('authenticateEchoChannels', this);
              } else {
              }
          }
        },

        computed: {
            ...mapState([
                'auth',
                'mobileMode',
                'menuOpen',
                'dropupOpen',
                'insets'
            ])
        },

        destroyed() {
            clearAllBodyScrollLocks();
        },

        mounted() {

            let page = document.querySelector('body .page')

            if(page) {
                disableBodyScroll(page)
            }

            if (this.$auth && this.$auth.loggedIn) {

                this.$store.dispatch('authenticateEchoChannels', this);
            }
        }
    }
</script>

<style>

    html {
      font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
      font-size: 16px;
      word-spacing: 1px;
      -ms-text-size-adjust: 100%;
      -webkit-text-size-adjust: 100%;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      box-sizing: border-box;
    }

    *, *:before, *:after {
      box-sizing: border-box;
      margin: 0;
    }

</style>

