<template>
    <b-modal ref="walletTransactionsModal" modal-class="slide-up" body-class="p-0" @show="show" @hidden="hide" hide-header hide-footer no-fade class="card card-modal border-0">
        <div class="w-100"
             style="height: 100vh!important"
             :style="'padding-top:' + insets.top + 'px!important; padding-bottom:' + insets.bottom + 'px'"
        >
            <div class="card card-modal h-100 border-0 rounded-0 relative">
                <div class="card-header card-header-sm d-flex justify-content-center align-items-center">
                    <i class="cf-back absolute p-3" style="left: 0" @click.prevent="$refs.walletTransactionsModal.hide()"></i>
                    <span>{{ $t('Ricarica borsellino')}}</span>
                </div>
                <div class="card-body scrollable-content p-0">
                    <div class="d-flex p-3">
                        <div class="mr-3">
                            <img src="~/assets/images/icons/menu/borsellino.svg" width="44px">
                        </div>
                        <div>{{ $t('Ricarica il tuo borsellino per poter fare acquisti sulla piattaforma di Confestetica') }}</div>
                    </div>
                    <div class="background-gray text-uppercase font-weight-bold pl-3 p-2">{{ $t('Importo')}}</div>
                    <div class="font-size-extra d-flex p-3 justify-content-between align-items-center">
                        €
                        <input class="w-100 text-right" maxlength="7" v-model="amount" v-money="{}">
                    </div>
                    <div class="background-gray text-uppercase font-weight-bold pl-3 p-2">{{ $t('Metodo di pagamento')}}</div>
                    <payment-methods :hide-stripe-fields="!value" :success-callback="processPayment" ref="paymentMethods"></payment-methods>
                </div>
                <div class="card-footer">
                    <button @click.prevent="getPaymentMethod" class="btn btn-brand w-100"> {{ $t('Effettua ricarica')}}</button>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>

    import { mapState, mapActions } from 'vuex';
    import paymentMethods from "~/components/PaymentMethods";

    export default {

        components: {
            paymentMethods
        },

        props: ['value', 'returnUrl'],

        data () {
            return {
                stripeApiKey: process.env.STRIPE_PUBLISHABLE_KEY || 'pk_test_nH1hcGkzXexiZBYsKY72hUXA',
                amount: 0
            }
        },

        computed: {
            ...mapState({
                app: 'app',
                insets: 'insets',
                platform: 'platform'
            })
        },

        methods: {
            ...mapActions({
                'refetchUser': 'user/refetch'
            }),
            show() {
                this.value = true;
            },
            hide() {
                this.value = false;
                this.$emit('input', this.value)
            },
            openModal() {
                this.$refs.walletTransactionsModal.show();
            },
            closeModal() {
                this.$refs.walletTransactionsModal.hide();
            },
            async paymentSucceeded () {
                await this.refetchUser()
                    .then(() => {
                        this.$notify({
                            group: 'messages',
                            type: 'success',
                            title: this.$t('Ricarica avvenuta con successo'),
                        })
                        this.closeModal();
                })
            },
            processPayment ({paymentMethod, source}) {
                this.$nuxt.$loading.start();
                this.$axios.post('/api/wallet/add-funds', { amount: this.amount, payment_method: paymentMethod, stripe_payment_method: source })
                    .then((response) =>{
                        if(paymentMethod === 'stripe') {

                            let stripe = Stripe(this.stripeApiKey);

                            if (response.requiresSCA) {
                                stripe.handleCardPayment(response.payment_intent_secret).then(result => {

                                    this.paymentSucceeded().then(() => {
                                    }).finally(() => {
                                        this.$nuxt.$loading.finish();
                                    })
                                });
                            } else {
                                this.paymentSucceeded().then(() => {
                                }).finally(() => {
                                    this.$nuxt.$loading.finish();
                                })
                            }
                        } else if(paymentMethod === 'bank_transfer') {

                            let stripe = Stripe(this.stripeApiKey);

                            stripe.createSource({
                                type: 'sofort',
                                amount: parseInt((this.amount * 100).toFixed()),
                                statement_descriptor: 'Ricarica borsellino Confestetica',
                                currency: 'eur',
                                redirect: {
                                    return_url: process.env.BASE_URL + this.returnUrl + (this.app ? '?platform=' + this.platform : ''),
                                },
                                metadata: {
                                    wallet_transaction: true,
                                    user_id: this.$auth.user.id.toString()
                                },
                                owner: {
                                    // name: this.$auth.user.name,
                                    name: 'succeeding_charge',
                                    email: this.$auth.user.email
                                },
                                sofort: {
                                    country: 'IT',
                                },
                            }).then((result) => {
                                if (result.source) {
                                    window.location = result.source.redirect.url;
                                } else if(result.error) {
                                    console.error(result.error)
                                }
                            }).finally(() => {
                              this.$nuxt.$loading.finish();
                            })
                        }
                    })
                    .catch(() => {
                      this.$nuxt.$loading.finish()
                    })

            },
            getPaymentMethod () {
                this.$refs.paymentMethods.getPaymentMethod();
            }
        },

        created () {

            this.$nuxt.$on('close-wallet-transactions-modal', () => {
                if (this.$refs.walletTransactionsModal) {
                    this.$refs.walletTransactionsModal.hide();
                }
            })
        }
    }
</script>
