<template>
    <div class="card">
        <slot name="card-header">
            <div class="card-header card-header-sm  d-flex justify-content-center align-items-center">
                <i class="cf-back absolute p-3" style="left: 0;" @click.prevent="previousStep()"></i>
                {{ $t('Logo del tuo centro')}}
            </div>
        </slot>
        <div class="card-body d-flex flex-column p-4">
            <cropper type="Profile" :img-src.sync="imgSrc" ref="editImageCropper"></cropper>
            <!--</template>-->
            <!--</edit-image>-->
        </div>
        <div class="card-footer d-flex">
            <button class="btn btn-brand btn-transparent mr-1 w-100" @click.prevent="setStep('header-image')">
                {{ $t('Salta') }}
            </button>
            <button class="btn btn-brand ml-1 w-100" @click.prevent="updateImage" :disabled="!imgSrc && !account.account.profile_image">
                {{ $t('Ok') }}
            </button>
        </div>
    </div>
</template>

<script>

    import { mapState, mapMutations } from 'vuex';
    import Cropper from "~/components/WorkingPlace/Steps/Cropper";

    export default {
        components: {
            Cropper
        },

        props: ['type'],

        data () {
            return {
                imgSrc: ''
            }
        },

        computed: {
            ...mapState({
                'account': 'account',
                'workExperience': 'work-experience'
            }),
            showing() {
                return (!this.$refs.editImageCropper);
            }
        },
        methods: {
            ...mapMutations({
                'setStep': 'work-experience/SET_STEP'
            }),

            updateImage() {

                if (this.imgSrc) {
                    this.$refs.editImageCropper.saveImage()
                }
                else {
                    this.setStep('header-image')
                }
            },

            previousStep() {
                if (this.workExperience.workExperience.type == 'owner') {
                    this.setStep('location-photo')
                } else {
                    this.setStep('account-whatsapp-number')
                }
            }
        }
    }
</script>