<<template>
    <b-modal id="image-comments-modal" modal-class="scale-up-center" body-class="p-0" @shown="init" no-fade hide-header hide-footer ref="commentsModal" static lazy>
        <div class="d-flex news w-100" style="height: 100vh">
            <div class="card post w-100"
                 :style="'min-height:' + windowHeight + ';max-height:' + windowHeight + ';padding-top:' + insets.top + 'px!important; padding-bottom:' + (bottomOffset ? 0 : insets.bottom) + 'px'"
            >
                <div class="card-header card-header-sm d-flex justify-content-center align-items-center">
                    <u class="position-absolute p-3 font-size-base" @click.prevent="$refs.commentsModal.hide()" style="right: 0px">{{ $t('Fine') }}</u>
                    {{ $t('Commenti') }}
                </div>
                <div class="card-body scrollable-content comments">
                    <comments :modal="true" :resource="resource" :comments="getCommentsFromState"></comments>
                </div>
                <div v-if="comment.user" class="card-footer d-lg-none d-flex justify-content-between text-grey font-size-sm align-items-center p-2">
                    <span>{{ $t('Risposta a ') }} {{ comment.user.name }}</span>
                    <i class="cf-delete" @click.prevent="clearAnswerComment"></i>
                </div>
                <div class="card-footer comments d-lg-none">
                    <div class="d-flex">
                        <!--<div class="align-self-end comment-actions d-block d-lg-none">-->
                            <!--<i class="fa cf-camera text-grey mr-2" @click.prevent="saveComment"></i>-->
                        <!--</div>-->
                        <div class="d-flex w-100 relative">
                            <!--@keypress.enter.prevent="saveComment"-->
                            <textarea class="d-flex comment new-comment w-100"
                                      :id="'newCommentPostId'"
                                      :placeholder="$t('Scrivi un commento...')"
                                      v-model="comment.text"
                            >
                            </textarea>
                            <!--<div class="absolute align-self-end comment-actions d-block d-lg-none">-->
                                <!--<i class="fa fa-smile-o text-grey" @click.prevent="saveComment"></i>-->
                            <!--</div>-->
                        </div>
                        <div class="d-block comment-actions d-lg-none align-self-end">
                            <i class="fa cf-message-f text-brand ml-2" v-if="comment.text.length" @click.prevent="saveComment"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>

    import Vue from 'vue'

    import { mapGetters, mapState, mapActions } from 'vuex'
    import Comments from "~/components/Group/Wall/Posts/Comments";
    import autosize from "autosize"

    export default {

        props: {
            resource: {
                type: Object,
                default: function () {
                    return {}
                }
            },
            resourceType: {
                type: String,
                default: function () {
                    return 'posts'
                }
            }
        },

        components: {
            Comments
        },

        data: function () {

            return {
                height: 0,
                comment: {
                    text: '',
                    post_id: this.resource.post_id ? this.resource.post_id : this.resource.id,
                    post_image_id: this.resource.post_id ? this.resource.id : null,
                    comment_id: null
                }
            }
        },

        watch: {
            resource: {
                deep: true,
                handler: function () {
                    this.comment.text = '';
                    this.comment.comment_id = null;
                    this.comment.post_id = this.resource.post_id ? this.resource.post_id : this.resource.id;
                    this.comment.post_image_id = this.resource.post_id ? this.resource.id : null;
                }
            },
            bottomOffset: function () {
                document.querySelector('body').scrollTo(0,0)
            }
        },

        computed: {

            getCommentsFromState() {
                if (this.resourceType === 'post-images') {
                    return this.getCommentsByPostImage(this.resource);
                } else if (this.resourceType === 'posts') {
                    return this.getCommentsByPostId(this.resource.id);
                } else {
                    return []
                }
            },

            windowHeight() {
                return this.bottomOffset ? ((this.height * (1 - this.bottomOffset / 100)) + 'px !important') : '100vh';
            },
            ...mapGetters({
                'getCommentsByPostId': 'comments/GET_COMMENTS_BY_POST_ID',
                'getCommentsByPostImage': 'comments/GET_COMMENTS_BY_POST_IMAGE'
            }),
            ...mapState([
                'bottomOffset',
                'insets'
            ])
        },

        methods: {

            ...mapActions({
                'fetch': 'comments/fetch',
                'refetch': 'group/fetchById',
                'refetchPost': 'posts/fetchById',
                'refetchPostImage': 'post-images/fetchById',
                'createComment': 'comment/create'
            }),

            show() {
                this.$refs.commentsModal.show();
            },

            init() {
                this.loadMore();
                setTimeout(() => {
                    autosize.update(document.querySelectorAll('textarea'))
                })
            },

            hide() {
                this.clearAnswerComment()
            },

            clearAnswerComment() {
                this.comment.user = null;
                this.comment.comment_id = null;
            },

            loadMore() {

                this.page++;

                this.fetch({
                    page: this.page,
                    per_page: 50,
                    post_id: this.resource.post_id ? this.resource.post_id : this.resource.id,
                    post_image_id: this.resource.post_id ? this.resource.id : null,
                })
            },

            refetchResource() {
                if (this.resourceType === 'post-images') {
                    this.refetchPostImage(this.resource);
                } else if (this.resourceType === 'posts') {
                    this.refetchPost(this.resource);
                }
            },

            saveComment () {

                if (this.comment.text.length === 0) return;

                this.$nuxt.$loading.start()

                this.createComment(this.comment)
                    .then((comment) => {
                        this.showingOverlay = false;
                        this.comment.text = '';

                        setTimeout(function () {
                            autosize.update(document.querySelectorAll('textarea'))
                        }, 20)

                        this.refetchResource()

                        this.$nuxt.$loading.finish()

                    }).catch(() => {
                    this.$nuxt.$loading.finish()
                });
            }
        },

        created () {
            this.$nuxt.$on('answer-to-comment-id', (data) => {

                Vue.set(this.comment, 'comment_id', data.commentId)
                Vue.set(this.comment, 'user', data.user)
                Vue.set(this.comment, 'text', data.user ? '@' + data.user.name + ' ' + this.comment.text : this.comment.text)

                setTimeout(() => {
                    autosize.update(document.querySelectorAll('textarea'))
                })
            });
        },

        mounted () {

            this.height = window.innerHeight;
        },
    }
</script>