<template>
    <div class="card border-0 rounded-0 text-center">
        <div class="card-header card-header-sm d-flex justify-content-center align-items-center">
            <i class="cf-back absolute p-3" style="left: 0;" @click.prevent="previousStep()"></i>
            <span v-if="type === 'school'">{{ $t('Aggiungi una scuola di estetica') }}</span>
            <span v-else>{{ $t('Collegati ad un luogo di lavoro') }}</span>
        </div>
        <div class="card-body p-0 background-gray">
            <div class="search-bar d-flex background-white">
                <i class="cf-search text-grey "></i>
                <input class="w-100 ellipsis font-size-xl" :placeholder="type !== 'school' ? $t('Nome del posto di lavoro') : $t('Nome della tua scuola di estetica')" v-model="filters.text">
                <i class="fa fa-times-circle align-self-center absolute text-grey p-3" style="right: 0" v-if="filters.text" @click.prevent="filters.text = ''"></i>
            </div>
            <div class="background-white" v-if="filters.text.length >= 2">
                <div class="d-flex align-items-center p-2" @click.prevent="nextStep('')">
                    <account-image class="p-2" size="50"></account-image>
                    <div class="ml-2 text-left">
                        <div>{{ $t('Crea nuovo centro estetico:')}}</div>
                        <span class="text-brand">« <u class="font-weight-bold">{{ filters.text }}</u> »</span>
                    </div>
                </div>
                <hr>
                <div v-if="!loading">
                    <div class="d-flex flex-column" v-for="account in accounts.accounts" @click.prevent="nextStep(account)">
                        <div class="d-flex align-items-center p-2">
                            <account-image class="p-2" :account="account" size="50"></account-image>
                            <div class="ml-2 text-left ellipsis">
                                <strong class="no-wrap"> {{ account.name }}</strong>
                                <div class="text-grey"> {{ account.address }}</div>
                            </div>
                        </div>
                        <hr v-if="lastAccount && (account.id !== lastAccount.id)">
                    </div>
                </div>
            </div>
            <div v-if="loading">
                <i class="fa fa-spin">
                    <img alt="loading" src="~/assets/images/icons/loading.svg" width="36px">
                </i>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState, mapMutations } from 'vuex'
    import { mapFields } from 'vuex-map-fields'
    import AccountResults from '~/components/SearchAccounts/Results'
    import _ from 'lodash'

    export default {

        components: {
            AccountResults
        },

        props: ['type'],

        data() {
            return {
                filters: {
                    text: '',
                    type: this.type
                },
                loading: false
            }
        },

        watch: {
            'filters.text': _.debounce(function (val) {
               if (val.length >= 2) {
                   this.loading = true;
                   this.fetchAccounts({filters: this.filters}).then(() => {
                       this.loading = false;
                   }).catch(() => {
                       this.loading = false;
                   })
               }
            }, 500)
        },

        computed: {
            ...mapState({
                'accounts': 'accounts',
                'workExperience': 'work-experience'
            }),
            lastAccount() {
                return _.last(this.accounts.accounts);
            }
        },

        methods: {
            ...mapActions({
                'refetchUser': 'user/refetch',
                'fetchAccounts': 'accounts/fetchAll',
                'loadMoreAccounts': 'accounts/fetchMore'
            }),
            ...mapMutations({
                'resetAccount': 'work-experience/RESET_ACCOUNT',
                'setAccountName': 'work-experience/SET_ACCOUNT_NAME',
                'setAccountType': 'work-experience/SET_ACCOUNT_TYPE',
                'setWorkExperienceType': 'work-experience/SET_TYPE',
                'setWorkExperienceStep': 'work-experience/SET_STEP',
                'setWorkExperienceAccount': 'work-experience/SET_WORK_EXPERIENCE_ACCOUNT',
            }),
            previousStep() {
                this.setWorkExperienceStep('work-experience')
                // if (this.modal) {
                //     this.setStep()
                // } else {
                //     this.smartGoBack()
                // }
            },
            nextStep(account) {

                if (!account) {
                    this.resetAccount()
                    this.setAccountName(this.filters.text);
                    this.setAccountType(this.type);

                    this.setWorkExperienceStep('account-address')

                } else {
                    this.setWorkExperienceAccount(account)
                    this.setWorkExperienceStep('work-experience')
                }
            }
        },
        mounted () {
            if (this.workExperience.workExperience && this.workExperience.workExperience.account.name) {
                this.text = this.workExperience.workExperience.account.name
            }
        }
    }
</script>

<style lang="scss" scoped>
    .account-card {
        box-shadow: 0 3px 7px rgba(0,0,0,0.16), 0 3px 7px rgba(0,0,0,0.1);
    }
    .search-bar {
        border-bottom: 1px solid #DBDBDB;

        i.cf-search {
            padding: 18px;
            font-size: 26px;
        }

        input {
            &::placeholder {
                color: #DBDBDB;
            }
        }
    }

    hr {
        margin: 0!important;
    }
</style>