<template>
    <div v-if="urlList.length">
        <div class="meta-tags d-flex" v-if="metas && !processing">
            <img class="image-tag mr-2" v-if="metas.image" :src="metas.image">
            <div class="data-tags">
                <div class="title-tag">
                    <strong>{{ metas.title }}</strong>
                </div>
                <div class="description-tag" v-html="description">
                </div>
            </div>
        </div>
        <div v-if="processing">
            <div class="meta-tags processing d-flex justify-content-center align-items-center">
                <div class="text-grey fa fa-spinner fa-spin fa-2x"></div>
            </div>
        </div>
    </div>
</template>

<script>

    import _ from 'lodash'

    export default {
        props: {
            'text': {
                type: String,
                default () {
                    return '';
                }
            }
        },

        data () {
            return {
                processing: false,
                metas: null,
                urlList: [],
            }
        },

        watch: {
            'text': {
                // immediate: true,
                deep: true,
                handler() {

                    if (this.text.length) {

                        this.urlList = this.text.match(/((http(s)?:\/\/.)|(www\.))[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g) || []

                        if (this.urlList.length) {
                            this.retrieveTagsFromUrl(this.urlList[0]);
                        }
                    }
                }
            }
        },

        computed: {
            description () {
                return _.truncate(this.metas.description, {
                    length: 160,
                    separator: /,? +/
                })
            }
        },

        methods: {
            retrieveTagsFromUrl: _.debounce(function (url) {

                if (this.processing) return;
                this.processing = true;

                this.$axios.get('/api/retrieve-tags-from-url', { params: { url: url } })
                    .then((metas) => {
                        this.metas = metas.data;
                        this.processing = false;
                    })
            }, 500)
        },

        mounted () {
          if (this.text.length) {

            this.urlList = this.text.match(/((http(s)?:\/\/.)|(www\.))[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g) || []

            if (this.urlList.length) {
              this.processing = true;

              this.$axios.get('/api/retrieve-tags-from-url', { params: { url: this.urlList[0] } })
                .then((metas) => {
                  this.metas = metas.data;
                  this.processing = false;
                })
            }
          }
        }
    }
</script>