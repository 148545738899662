<template>
    <div class="no-wrap d-flex align-items-center" :class="{'justify-content-center': position === 'center', 'justify-content-start': position === 'left'}">
        <div class="d-flex justify-content-center align-items-center position-relative">
            <img src="~/assets/images/rank-diamond@2x.png" :class="'badge-' + (size ? size : 'large')"/>
            <div class="badge-content text-dark-grey">
                {{ account.profile_points }}
            </div>
        </div>
        <span v-if="!hideText" class="text-grey ml-1">
            <span v-if="account.profile_points >= 22">{{ $t('Top Level')}}</span>
            <span v-else-if="account.profile_points >= 7">{{ $t('Medium Level')}}</span>
            <span v-else>{{ $t('Entry Level')}}</span>
        </span>
    </div>
</template>

<script>

    export default {

        props: ['account', 'position', 'hideText', 'size']
    }
</script>
