<template>
    <div :id="'search-accounts-' + _uid" class="text-center d-flex flex-column h-100">
        <slot name="card-header">
            <div class="card-header card-header-sm d-flex justify-content-center align-items-center">
                <i class="cf-back absolute p-3" style="left: 0;" @click.prevent="previousStep()"></i>
                <span v-if="type === 'school'">{{ $t('Aggiungi una scuola di estetica') }}</span>
                <span v-else>{{ $t('Collegati ad un luogo di lavoro') }}</span>
            </div>
        </slot>
        <div class="card-body p-0 background-gray overflow-auto">
            <div class="account-search-bar d-flex background-white position-sticky w-100 pr-3">
                <i class="cf-search text-grey font-size-xxl"></i>
                <input v-focus="focused" class="w-100 ellipsis font-size-xl" :placeholder="placeholder" v-model="filters.text">
                <i class="fa fa-times-circle align-self-center absolute text-grey p-3" style="right: 0" v-if="filters.text" @click.prevent="filters.text = ''"></i>
            </div>
            <div class="background-white" v-if="filters.text.length >= 2">
                <div class="d-flex align-items-center p-2" @click.prevent="nextStep('')">
                    <account-image class="p-2" size="50" :account="account"></account-image>
                    <div class="ml-2 text-left">
                        <div>
                            <span v-if="type === 'work' || type === 'beauty-centre'">{{ $t('Crea nuovo centro estetico:')}}</span>
                            <span v-else-if="type === 'school'">{{ $t('Crea nuova scuola di estetica:')}}</span>
                            <span v-else-if="type === 'brand'">{{ $t('Crea nuovo brand:')}}</span>
                        </div>
                        <span class="text-brand">« <u class="font-weight-bold">{{ filters.text }}</u> »</span>
                    </div>
                </div>
                <hr>
                <div v-if="!loading">
                    <div class="d-flex flex-column" v-for="item in accounts.accounts" @click.prevent="nextStep(item)">
                        <div class="d-flex align-items-center p-2">
                            <account-image class="p-2" :account="item" size="50"></account-image>
                            <div class="ml-2 text-left ellipsis">
                                <strong class="no-wrap"> {{ item.name }}</strong>
                                <div class="text-grey"> {{ item.address }}</div>
                            </div>
                        </div>
                        <hr v-if="lastAccount && (item.id !== lastAccount.id)">
                    </div>
                </div>
            </div>
            <div v-if="loading">
                <i class="fa fa-spin">
                    <img alt="loading" src="~/assets/images/icons/loading.svg" width="36px">
                </i>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState, mapMutations } from 'vuex'
    import { mapFields } from 'vuex-map-fields'
    import AccountResults from '~/components/SearchAccounts/Results'
    import { focus } from 'vue-focus';
    import _ from 'lodash'

    const bodyScrollLock = require('body-scroll-lock')

    export default {

        components: {
            AccountResults
        },

        props: ['account', 'type', 'confirmCallback', 'setStep'],

        directives: {
            focus: focus,
        },

        data() {
            return {
                filters: {
                    text: '',
                    type: this.account.type
                },
                focused: true,
                loading: false
            }
        },

        watch: {
            'account.type': function (val) {
                this.filters.type = val;
            },
            'filters.text': _.debounce(function (val) {
                if (val.length >= 2) {
                    this.loading = true;
                    this.fetchAccounts({filters: this.filters}).then(() => {
                        this.loading = false;
                    }).catch(() => {
                        this.loading = false;
                    })
                }
            }, 500)
        },

        computed: {
            ...mapState({
                'user': 'user',
                'accounts': 'accounts',
                'workExperience': 'work-experience'
            }),
            placeholder() {
                switch(this.type) {
                    case 'brand':
                        return this.$t('Nome del brand')
                    case 'beauty-centre':
                        return this.$t('Nome del centro estetico')
                    case 'school':
                        return this.$t('Nome della scuola di estetica')
                    default:
                        return this.$t('Nome del posto di lavoro')
                }
            },
            lastAccount() {
                return _.last(this.accounts.accounts);
            }
        },

        methods: {
            ...mapActions({
                'refetchUser': 'user/refetch',
                'fetchAccounts': 'accounts/fetchAll',
                'loadMoreAccounts': 'accounts/fetchMore'
            }),
            previousStep() {

                // this.backCallBack();
                // this.setWorkExperienceStep('work-experience')
            },
            nextStep(account) {

                if (!account) {

                    this.$emit('update:name', this.filters.text)

                    if(this.owner || this.type === 'brand') {
                        this.setStep('account-image')
                    } else {
                        this.setStep('account-address')
                    }
                } else {
                    this.$emit('update:account', account)
                    this.setStep('confirm-account-data')
                }
            }
        },

        beforeDestroy() {
            let page = document.querySelector('#search-accounts-' + this._uid + ' .card-body.overflow-auto')
            if(page) {
                bodyScrollLock.disableBodyScroll(page);
            }
        },

        mounted () {
            if (this.account && this.account.name) {
                this.text = this.account.name
            }

            let page = document.querySelector('#search-accounts-' + this._uid + ' .card-body.overflow-auto')
            console.log(page);
            if(page) {
                bodyScrollLock.disableBodyScroll(page)
            }
        }
    }
</script>

<style lang="scss" scoped>

    .cf-search {
        padding: 0.9em;
    }

    .account-card {
        box-shadow: 0 3px 7px rgba(0,0,0,0.16), 0 3px 7px rgba(0,0,0,0.1);
    }
    .account-search-bar {
        z-index: 1;
        top: 0;
        border-bottom: 1px solid #DBDBDB;

        input {
            &::placeholder {
                color: #DBDBDB;
            }
        }
    }

    hr {
        margin: 0!important;
    }
</style>