<template>
    <div class="card text-center">
        <div class="card-header card-header-sm  d-flex justify-content-center align-items-center">
            <i class="cf-back absolute p-3" style="left: 0;" @click.prevent="previousStep()"></i>
            <span v-if="workExperience.workExperience.type == 'owner'">
                {{ $t('Il tuo centro estetico')}}
            </span>
            <span v-else>
                {{ $t('Cerca centro estetico') }}
            </span>
        </div>
        <div class="card-body">
            <div class="mt-lg-5 mb-2">
                <img alt="step-02" class="w-100 icon" src="~/assets/images/steps/step-02.png">
            </div>
            <div class="description">
                {{ $t('Operazione completata')}}
            </div>
            <div class="mt-3">
                <div class="title">
                    {{ account.account.name }}
                </div>
                <div class="description">
                    {{ account.account.address }}
                </div>
            </div>
            <div class="p-4">
                <div v-if="workExperience.workExperience.type === 'owner'">
                    {{ $t('E\' in fase di valutazione ...')}}
                </div>
                <div class="background-gray description p-3" v-else>
                    {{ $t('Siamo in attesa che la titolare di') }} {{ account.account.name }} {{ $t('confermi la tua posizione lavorativa') }}
                </div>
            </div>
            <strong class="form-error text-danger mt-2" v-if="errors.has('email')">{{ errors.first('email') }}</strong>
        </div>
        <div class="card-footer">
            <nuxt-link :to="account.account.permalink" class="btn btn-brand w-100" v-if="workExperience.workExperience.type === 'owner' && $auth.user.wizard_completed">
                {{ $t('Visualizza il tuo centro') }}
            </nuxt-link>
            <div v-else>
                <button class="btn btn-brand w-100" disabled>
                    {{ $t('Annulla ') }}
                </button>
                <button class="btn btn-brand w-100" @click.prevent="nextStep()">
                    {{ $t('Esci') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState, mapMutations } from 'vuex'
    import _ from 'lodash'

    export default {

        props: ['modal'],

        computed: {
            ...mapState({
                'account': 'account',
                'workExperience': 'work-experience'
            })
        },

        methods: {
            ...mapMutations({
                'setWorkExperienceType': 'work-experience/SET_TYPE',
                'setWorkExperienceStep': 'work-experience/SET_STEP',
                'setStep': 'profile-wizard/SET_STEP'
            }),
            previousStep() {
                this.setWorkExperienceStep('staff-invite')
            },
            nextStep() {
                if (this.modal) {
                    this.$emit('close-modal')
                } else {
                    this.setWorkExperienceStep('profile-image')
                }
            }
        },

        mounted () {
        },
    }
</script>