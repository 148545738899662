
import { getField, updateField } from 'vuex-map-fields';

export const state = () => ({
    data: {
        text: '',
        conversation_id: null
    },
    loading: false,
    saving: false,
})

export const getters = {
    getField
}

export const mutations = {
    updateField,
    SET_DATA (state, message) {
        state.message = message
    },
    SET_LOADING (state, loading) {
        state.loading = loading
    },
    SET_SAVING (state, saving) {
        state.saving = saving
    },
}

export const actions = {

    create ({ commit, state}, data) {

        commit('SET_SAVING', true)

        return this.$axios
            .post('/api/messages', data)
            .then(r => r.data)
            .then(record => {
                commit('SET_SAVING', false)

                commit('messages/APPEND', record, { root: true })
            })
            .catch(error => {
                commit('SET_SAVING', false)
                throw error;
            })
    }
}