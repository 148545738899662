<template>
    <div class="user-menu">
        <div class="user-nav">
            <!--<a href="#" class="no-decoration" @click.prevent="toggleMenu" :class="{'menu-open': menuOpen}">-->
                <!--<slot name="menu-button">-->
                    <!--<i class="cf-chat"></i>-->
                <!--</slot>-->
            <!--</a>-->
            <!--<div class="list card" v-if="menuOpen" v-click-outside="outside" @click="outside">-->
                <!--<ul>-->
                    <!--<li class="p-2">-->
                        <!--<nuxt-link to="#" class="text-center">-->
                            <!--{{ $t('In lavorazione')}}-->
                        <!--</nuxt-link>-->
                    <!--</li>-->
                <!--</ul>-->
            <!--</div>-->

            <nuxt-link to="/chat" class="no-decoration text-center">
                <slot name="menu-button">
                    <i class="cf-chat"></i>
                </slot>
            </nuxt-link>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters } from 'vuex'
    import UserImage from "~/components/UserImage"

    export default {
        data () {
            return {
                menuOpen: false
            }
        },

        components: {
            UserImage
        },

        computed: {
            ...mapGetters([
                'account'
            ]),
            ...mapState([
                'auth'
            ])
        },

        directives: {
            'click-outside': {
                bind: function (el, binding, vNode) {
                    // Provided expression must evaluate to a function.
                    if (typeof binding.value !== 'function') {
                        const compName = vNode.context.name
                        let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`
                        if (compName) {
                            warn += `Found in component '${compName}'`
                        }

                        console.warn(warn)
                    }
                    // Define Handler and cache it on the element
                    const bubble = binding.modifiers.bubble
                    const handler = (e) => {
                        if (bubble || (!el.contains(e.target) && el !== e.target)) {
                            binding.value(e)
                        }
                    }
                    el.__vueClickOutside__ = handler

                    // add Event Listeners
                    document.addEventListener('click', handler)
                },

                unbind: function (el, binding) {
                    // Remove Event Listeners
                    document.removeEventListener('click', el.__vueClickOutside__)
                    el.__vueClickOutside__ = null

                }
            }
        },

        methods: {
            outside: function(e) {
                this.menuOpen = false;
            },

            toggleMenu () {
                this.menuOpen = !this.menuOpen
            }
        },

        mounted () {

        }
    }
</script>