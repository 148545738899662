<template>
    <div class="subscription card">
        <slot name="card-header">
            <div class="card-header card-header-sm d-flex justify-content-center align-items-center">
                <i class="cf-back absolute p-3" style="left: 0" @click.prevent="setStep('payment-method')"></i>
                <span>{{ $t('Le tue carte')}}</span>
            </div>
        </slot>
        <div class="card-body p-0">
            <div class="card pb-4">
                <div class="background-gray pl-3 p-2 d-flex justify-content-between">
                    <strong class="text-uppercase">{{ $t('Le tue carte di credito')}}</strong>
                    <img alt="payment-method-logo" src="~/assets/images/cards/payment-method-logo.svg" height="18px">
                </div>
                <div class="card-body pt-0 p-0">
                    <card ref="card" v-for="(paymentMethod, index) in getPaymentMethodsByCustomerId($auth.user.customer_id)" :payment-method="paymentMethod" v-if="paymentMethod.card" :key="paymentMethod.id"></card>
                    <div v-if="!newCard" class="payment-method-input d-flex align-items-center border-bottom">
                        <i class="fa fa-circle mr-3 text-light-grey"></i>
                        <div>
                            <img alt="unknown" class="mr-2" src="~/assets/images/cards/unknown.png" height="24px">
                            <u class="text-primary" @click.prevent="openNewCardForm"> {{ $t('Aggiungi nuova carta') }}</u>
                        </div>
                    </div>
                    <div v-if="newCard">
                        <div class="background-gray pl-3 p-2 d-flex justify-content-between">
                            <strong class="text-uppercase">{{ $t('Nuova carta di credito')}}</strong>
                        </div>
                        <div class="form-group w-100" style="max-width: inherit">
                            <card-number class='has-label stripe-element card-number'
                                         ref='cardNumber'
                                         :placeholder="$t('Numero carta di credito')"
                                         :stripe="stripeApiKey"
                                         :options='stripeOptions'
                                         @change='number = $event.complete'
                            />
                        </div>
                        <div class="d-flex">
                            <div class="form-group flex-grow-1 flex-basis-0">
                                <card-expiry class='stripe-element card-expiry'
                                             ref='cardExpiry'
                                             methjods         :stripe="stripeApiKey"
                                             :options='stripeOptions'
                                             @change='expiry = $event.complete'
                                />
                            </div>
                            <div class="form-group flex-grow-1 flex-basis-0">
                                <card-cvc class='stripe-element card-cvc'
                                          ref='cardCvc'
                                          :stripe="stripeApiKey"
                                          :options='stripeOptions'
                                          @change='cvc = $event.complete'
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="card-footer text-center" v-if="editing || newCard">
            <button class="btn btn-brand w-100" @click.prevent="saveCard">{{ $t('Salva') }}</button>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
    import { CardNumber, CardExpiry, CardCvc, createPaymentMethod } from 'vue-stripe-elements-plus'

    import Card from '~/components/Subscription/User/Steps/Source'

    export default {

        components: { Card, CardNumber, CardExpiry, CardCvc, createPaymentMethod},

        data() {

            let elementStyles = {
                base: {
                    color: '#403D40',
                    fontFamily: 'Acumin Pro',
                    fontSize: '20px',

                    '::placeholder': {
                        color: '#A6ACAF'
                    }
                },
                invalid: {
                    color: '#FA755A',
                    ':focus': {
                        color: '#FA755A',
                    },
                    '::placeholder': {
                        color: '#FFCCA5',
                    },
                },
            };

            let elementClasses = {
                focus: 'focus',
                empty: 'empty',
                invalid: 'invalid',
            };

            return {
                stripeApiKey: process.env.STRIPE_PUBLISHABLE_KEY || 'pk_test_nH1hcGkzXexiZBYsKY72hUXA',
                editing: false,
                newCard: false,
                stripeOptions: {
                    style: elementStyles,
                    classes: elementClasses
                },
                number: false,
                expiry: false,
                cvc: false
            }
        },

        watch: {
            'account': function() {
                if (this.account) {
                    this.first_name = this.$auth.user.first_name;
                    this.last_name = this.$auth.user.last_name;
                }
            },
            'number': function () {
                this.update()
            },
            'expiry': function () {
                this.update()
            },
            'cvc': function () {
                this.update() }
        },

        computed: {
            ...mapGetters({
                'getPaymentMethodsByCustomerId': 'payment-methods/getPaymentMethodsByCustomerId'
            }),
            ...mapState([
                'user',
                'checkout'
            ]),
        },

        methods: {
            ...mapActions({
                'processCheckout': 'checkout/processCheckout',
                'login': 'user/login',
                'savePaymentMethod': 'payment-methods/create'
            }),
            ...mapMutations({
                setStep: 'subscription/SET_STEP'
            }),

            saveCard() {
                if (this.editing) {
                    this.$nuxt.$emit('update-card')
                } else {

                    this.$validator.validate().then(result => {

                        if (!result) return;

                        let _self = this;

                        this.$nuxt.$loading.start()

                        createPaymentMethod('card')
                            .then((data) => {

                                if (!data || !data.paymentMethod) {
                                    this.processing = false;
                                    this.$nuxt.$loading.finish()

                                    this.$notify({
                                        group: 'messages',
                                        type: 'error',
                                        duration: 3000,
                                        title: _self.$t('Errore durante il pagamento.')
                                    });

                                }

                                if (data && data.paymentMethod) {

                                    this.savePaymentMethod(data.paymentMethod).then(() => {
                                        this.$auth.fetchUser().then(() => {
                                            this.newCard = false;

                                            this.$nuxt.$loading.finish()

                                            this.$notify({
                                                group: 'messages',
                                                type: 'success',
                                                duration: 10000,
                                                title: _self.$t('Carta aggiunta correttamente.')
                                            });
                                        });
                                    }).catch(() => {
                                        this.$nuxt.$loading.finish()
                                    })
                                }
                            })
                            .catch(() => {
                                this.$nuxt.$loading.finish()
                            });
                    });
                }
            },
            updateEditing() {

                if (this.$refs.card) {

                    let paymentMethods = this.$refs.card

                    this.editing =  _.filter(paymentMethods, {edit: true}).length > 0
                }
            },
            openNewCardForm() {

                this.$nuxt.$emit('update-edit');

                setTimeout(() => {
                    this.newCard = true;
                }, 50)
            },
            getCardName(name) {

                return name.replace(' ', '');
            },
            update () {
                this.complete = this.number && this.expiry && this.cvc

                // field completed, find field to focus next
                if (this.number) {
                    if (!this.expiry) {
                        this.$refs.cardExpiry.focus()
                    } else if (!this.cvc) {
                        this.$refs.cardCvc.focus()
                    }
                } else if (this.expiry) {
                    if (!this.cvc) {
                        this.$refs.cardCvc.focus()
                    } else if (!this.number) {
                        this.$refs.cardNumber.focus()
                    }
                }
                // no focus magic for the CVC field as it gets complete with three
                // numbers, but can also have four
            }
        },

        mounted () {
            this.$nuxt.$on('update-edit', () => {
                this.newCard = false;
                setTimeout(() => {

                    this.updateEditing()
                }, 100)
            })
        }
    }
</script>

<style lang="scss" scoped>

    .subscription.card {
        .card-body {
            max-height: inherit!important;
            .card  {
                height: inherit!important;
                .card-body {
                    padding-bottom: 20px !important;
                    max-height: inherit!important;
                }
            }
        }
    }

    .middle {
        flex-basis: 0;
        width: inherit !important;
    }

    .sidebar-right {
        flex-basis: 0;
        max-width: inherit !important;
        width: inherit !important;
    }

    a {
        text-decoration: underline;
        color: inherit;
    }

    hr {
        margin: 0;
    }

    .form-group {
        margin-bottom: 0;
    }

    .payment-method-input {
        padding: 20px 15px;
        i {
            font-size: 24px;
        }
    }

    .payment-method-logo {
        height: 18px;
    }

    .card.cursor-pointer:hover {
        background-color: #f4f4f4;
    }

    .cf-logo-container {
        width: 60px;
        height: 60px;
        border-radius: 6px;
        background-color: #772A79;
    }

    .book-image-container {
        width: 100%;
        height: 120px;
    }

    .total {
        font-size: 20px;
        font-weight: bold;
    }

    .form-group {
        margin: 0;
    }

    .stripe-element {
        padding: 20px 15px;
        border: 1px solid #F4F2F3;
    }
</style>
