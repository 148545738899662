<template>
    <b-modal id="billing-details-modal" modal-class="slide-left" ref="billingDetailsModal" no-fade hide-header hide-footer>
        <div class="card" :style="'height:100vh!important;height: calc(var(--vh, 1vh) * 100)!important;padding-top:' + insets.top + 'px!important; padding-bottom:' + insets.bottom + 'px'">
            <div class="card-header card-header-sm d-flex justify-content-center align-items-center">
                <i class="position-absolute cf-back p-3" style="left: 0" @click.prevent="$refs.billingDetailsModal.hide()"></i>
                {{ $t('Fatturazione')}}
            </div>
            <div class="card-body scrollable-content" v-show="step === 'billing-details'">
                <div class="font-weight-xbold font-size-lg mt-3">{{ $t('Dati fatturazione') }}</div>
                <div class="d-flex mt-2 flex-column">
                    <input class="form-control has-label" name="billing_name" @focus="clearErrorList" type="text" :placeholder="$t('Ragione Sociale')" autocomplete="off" v-model="billingDetails.billing_name" />
                    <strong class="form-error text-danger mt-1" v-if="errorList.billing_name">{{ errorList.billing_name[0] }}</strong>
                </div>
                <div class="d-flex mt-2 flex-column">
                    <input class="form-control has-label" name="billing_phone_number" @focus="clearErrorList" type="text" :placeholder="$t('Numero di telefono')" autocomplete="off" v-model="billingDetails.billing_phone_number" />
                    <strong class="form-error text-danger mt-1" v-if="errorList.billing_phone_number">{{ errorList.billing_phone_number[0] }}</strong>
                </div>
                <div class="d-flex mt-2 flex-column">
                    <input class="form-control has-label" name="billing_vat_id" @focus="clearErrorList" type="text" :placeholder="$t('Partita IVA')" autocomplete="off" v-model="billingDetails.billing_vat_id" />
                    <strong class="form-error text-danger mt-1" v-if="errorList.billing_vat_id">{{ errorList.billing_vat_id[0] }}</strong>
                </div>
                <div class="d-flex mt-2 flex-column">
                    <input class="form-control has-label" name="billing_sdi" @focus="clearErrorList" type="text" :placeholder="$t('SDI')" autocomplete="off" v-model="billingDetails.billing_sdi" />
                    <strong class="form-error text-danger mt-1" v-if="errorList.billing_sdi">{{ errorList.billing_sdi[0] }}</strong>
                </div>
                <div class="d-flex mt-2 flex-column">
                    <input class="form-control has-label" name="billing_pec" @focus="clearErrorList" type="text" :placeholder="$t('Pec')" autocomplete="off" v-model="billingDetails.billing_pec" />
                    <strong class="form-error text-danger mt-1" v-if="errorList.billing_pec">{{ errorList.billing_pec[0] }}</strong>
                </div>
                <div class="d-flex mt-2 flex-column" @click.prevent="showAddressModal('billing')">
                    <input class="form-control has-label" name="billing_formatted_address" @focus="clearErrorList" type="text" :placeholder="$t('Indirizzo fatturazione')" autocomplete="off" v-model="billingDetails.billing_formatted_address" />
                    <strong class="form-error text-danger mt-1" v-if="errorList.billing_formatted_address">{{ errorList.billing_formatted_address[0] }}</strong>
                    <strong class="form-error text-danger mt-1" v-else-if="errorList.billing_street_number">{{ errorList.billing_street_number[0] }}</strong>
                    <strong class="form-error text-danger mt-1" v-else-if="errorList.billing_route">{{ errorList.billing_route[0] }}</strong>
                    <strong class="form-error text-danger mt-1" v-else-if="errorList.billing_postal_code">{{ errorList.billing_postal_code[0] }}</strong>
                    <strong class="form-error text-danger mt-1" v-else-if="errorList.billing_city_name">{{ errorList.billing_city_name[0] }}</strong>
                    <strong class="form-error text-danger mt-1" v-else-if="errorList.billing_city_id">{{ errorList.billing_city_id[0] }}</strong>
                </div>
                <div class="d-flex justify-content-between align-items-center font-weight-medium mt-3">
                    {{ $t('Spedisci qui?')}}
                    <div class="switch">
                        <input :id="'switch' + _uid" type="checkbox" v-model="sameAddress" class="switch-sm"/>
                        <label :for="'switch' + _uid" class="m-0" style="font-size: 15px"></label>
                    </div>
                </div>
                <div v-if="!sameAddress">
                    <div class="font-weight-xbold font-size-lg mt-2">{{ $t('Dati spedizione') }}</div>
                    <div class="d-flex mt-2 flex-column">
                        <input class="form-control has-label" name="shipping_name" @focus="clearErrorList" type="text" :placeholder="$t('Destinatario')" autocomplete="off" v-model="billingDetails.shipping_name" />
                        <strong class="form-error text-danger mt-1" v-if="errorList.shipping_name">{{ errorList.shipping_name[0] }}</strong>
                    </div>
                    <div class="d-flex mt-2 flex-column" @click.prevent="showAddressModal('shipping')">
                        <input class="form-control has-label" name="billing_name" @focus="clearErrorList" type="text" :placeholder="$t('Indirizzo spedizione')" autocomplete="off" v-model="billingDetails.shipping_formatted_address" />
                        <strong class="form-error text-danger mt-1" v-if="errorList.shipping_formatted_address">{{ errorList.shipping_formatted_address[0] }}</strong>
                        <strong class="form-error text-danger mt-1" v-else-if="errorList.shipping_street_number">{{ errorList.shipping_street_number[0] }}</strong>
                        <strong class="form-error text-danger mt-1" v-else-if="errorList.shipping_route">{{ errorList.shipping_route[0] }}</strong>
                        <strong class="form-error text-danger mt-1" v-else-if="errorList.shipping_postal_code">{{ errorList.shipping_postal_code[0] }}</strong>
                        <strong class="form-error text-danger mt-1" v-else-if="errorList.shipping_city_name">{{ errorList.shipping_city_name[0] }}</strong>
                        <strong class="form-error text-danger mt-1" v-else-if="errorList.shipping_city_id">{{ errorList.shipping_city_id[0] }}</strong>
                    </div>
                </div>
            </div>
            <div class="card-body p-0 scrollable-content" v-show="step === 'payment-method'">
                <div class="background-gray text-uppercase font-weight-bold pl-3 p-2">{{ $t('Riepilogo ordine')}}</div>
                <div class="d-flex p-3">
                    <div>
                        <div class="group-buy-image-container">
                            <img v-if="product.thumbnail_res" :src="product.thumbnail_res.small">
                        </div>
                    </div>
                    <div class="ml-2">
                        <span class="text-dark-grey font-weight-bold">{{ $t('Prezzo:')}} <span style="color: #B12704!important">{{ quantity.unit_price.toFixed(2) }} € ({{ quantity.vat_pct === 0 ? $t('esente IVA') : $t('IVA esclusa') }})</span></span>
                        <div class="text-dark-grey font-weight-medium font-size-sm mt-1">{{ boxesForQuantity(quantity) }} {{ $t('conf. da') }} {{ quantity.unit_per_box }}pz.</div>
                        <div class="text-dark-grey font-weight-medium font-size-sm mt-1">{{ $t('Costo di spedizione ')}} {{ quantity.shipping_price }} €</div>
                        <span class="text-dark-grey font-weight-bold font-size-sm mt-1" style="color: #B12704!important">{{ amountForQuantity(quantity) }} € ({{ quantity.vat_pct === 0 ? $t('esente IVA') : $t('IVA inclusa') }})</span>
                        <div class="font-weight-medium font-size-sm mt-1">{{ $t('Consegna in 5/6 giorni l. dalla chiusura del gruppo')}}</div>
                    </div>
                </div>
                <div class="background-gray text-uppercase font-weight-bold pl-3 p-2">{{ $t('Borsellino')}}</div>

                <div class="p-3 d-flex">
                    <div class="mr-2">
                        <img src="~/assets/images/icons/menu/borsellino.svg" width="44px" height="44px">
                    </div>
                    <div class="w-100">
                        <span class="font-weight-bold">{{ $t('Borsellino')}}</span>
                        <div class="text-dark-grey mt-2">{{ $t('Credito accumulato: ')}} € {{ $auth.user.wallet_balance}}</div>
                        <div class="d-flex align-items-center justify-content-between" v-if="$auth.user.wallet_balance > 0">
                            <span class="text-grey">{{ $t('Utilizza per pagamento') }}</span>
                            <div class="switch switch-sm mt-2">
                                <input id="switch" type="checkbox" class="switch-sm" v-model="useWalletBalance">
                                <label for="switch" style="font-size: 15px"></label>
                            </div>
                        </div>
                        <div class="text-brand font-size-sm" @click.prevent="showWalletTransactionsModal"><u>{{ $t('Effettua ricarica')}}</u></div>
                    </div>
                </div>
                <div v-if="!useWalletBalance || useWalletBalance && $auth.user.wallet_balance < quantity.total_amount" >
                    <div class="background-gray text-uppercase font-weight-bold pl-3 p-2">{{ $t('Metodo di pagamento')}}</div>
                    <payment-methods :hide-stripe-fields.sync="showingWalletTransactionsModal" :success-callback="processPayment" ref="paymentMethods"></payment-methods>
                </div>
            </div>
            <div class="card-footer">
                <button @click.prevent="saveBillingDetails" class="btn btn-brand w-100" v-if="step === 'billing-details'"> {{ $t('Avanti')}}</button>
                <button @click.prevent="getPaymentMethod" class="btn btn-brand w-100" v-else-if="step === 'payment-method'"> {{ $t('Procedi al pagamento')}}</button>
            </div>
            <address-modal ref="addressModal" :entity="address" :confirm-callback="setAddress"></address-modal>
            <wallet-transactions-modal ref="walletTransactionsModal" v-model="showingWalletTransactionsModal" :return-url="app ? '/ricarica-borsellino' : '/gruppi-di-acquisto/' + product.slug"></wallet-transactions-modal>
        </div>
    </b-modal>
</template>

<script>
    import Vue from 'vue';
    import { mapState} from 'vuex'
    import AddressModal from "~/components/AddressModal";
    import PaymentMethods from "~/components/PaymentMethods";
    import WalletTransactionsModal from "~/components/WalletTransactionsModal";

    export default {

        props: {
            successCallback: {
                type: Function,
                default: function () {
                    return function () {}
                }
            },
            product: {
                type: Object,
                default: function() {
                    return {}
                }
            },
            quantity: {
                type: Object,
                default: function() {
                    return {}
                }
            }
        },

        components: {
            PaymentMethods,
            AddressModal,
            WalletTransactionsModal
        },

        data () {
            return {
                stripeApiKey: process.env.STRIPE_PUBLISHABLE_KEY || 'pk_test_nH1hcGkzXexiZBYsKY72hUXA',
                useWalletBalance: this.$auth.loggedIn && this.$auth.user.wallet_balance > 0,
                step: 'billing-details',
                // step: 'payment-method',
                showingAddressModal: '',
                showingWalletTransactionsModal: false,
                sameAddress: true,
                billingDetails: {
                    billing_name: '',
                    billing_phone_number: '',
                    billing_vat_id: '',
                    billing_sdi: '',
                    billing_pec: '',
                    // billing address
                    billing_formatted_address: '',
                    billing_route: '',
                    billing_street_number: '',
                    billing_city_id: '',
                    billing_city_name: '',
                    billing_postal_code: '',
                    // shipping address
                    shipping_formatted_address: '',
                    shipping_route: '',
                    shipping_street_number: '',
                    shipping_city_id: '',
                    shipping_city_name: '',
                    shipping_postal_code: '',
                },
                errorList: [],
                showErrors: false,
            }
        },

        computed: {
            address () {
                let prefix = this.showingAddressModal + '_';
                return {
                    route: this.billingDetails[prefix + 'route'],
                    street_number: this.billingDetails[prefix + 'street_number'],
                    city_id: this.billingDetails[prefix + 'city_id'],
                    city_name: this.billingDetails[prefix + 'city_name'],
                    postal_code: this.billingDetails[prefix + 'postal_code']
                }
            },
            ...mapState([
                'app',
                'insets',
            ])
        },

        methods: {
            boxesForQuantity(quantity) {
                return (quantity.quantity / quantity.unit_per_box).toFixed(0)
            },
            amountForQuantity(quantity) {
                return (quantity.quantity * quantity.unit_price * (1 + quantity.vat_pct / 100) + quantity.shipping_price).toFixed(2)
            },
            show () {
                this.$refs.billingDetailsModal.show();
            },
            hide () {
                this.$refs.billingDetailsModal.hide();
            },
            clearErrorList () {
                this.errorList = [];
            },
            showAddressModal (type) {
                this.showingAddressModal = type
                this.$refs.addressModal.openModal();
            },
            showWalletTransactionsModal () {
                this.showingWalletTransactionsModal = true
                setTimeout(() => {
                    this.$refs.walletTransactionsModal.openModal();
                })
            },
            processPayment ({paymentMethod, source}) {
                this.successCallback({paymentMethod: paymentMethod, source: source, useWalletBalance: this.useWalletBalance})
            },
            getPaymentMethod () {
                if(this.$auth.loggedIn && (!this.useWalletBalance || this.useWalletBalance && this.$auth.user.wallet_balance < this.quantity.total_amount)) {
                    this.$refs.paymentMethods.getPaymentMethod();
                } else {
                    this.successCallback({paymentMethod: 'wallet', useWalletBalance: this.useWalletBalance})
                }
            },
            saveBillingDetails () {
                this.$nuxt.$loading.start();
                this.$axios.post('/api/users/' + this.$auth.user.id + '/update-billing-details', {...this.billingDetails, 'same_address': this.sameAddress})
                    .then(() => {
                        this.step = 'payment-method'
                    }).catch((error) => {
                        this.errorList = error.response.data.errors;
                    })
                    .finally(() => {
                        this.$nuxt.$loading.finish();
                    })
            },
            setAddress(address) {

                let prefix = this.showingAddressModal + '_';
                _.each(Object.keys(address), (key) => {
                    Vue.set(this.billingDetails, prefix + key, address[key])
                })
            }
        }
    }
</script>
