<template>
    <div class="info-card">
        <div class="card card-lg border-0 shadow-none">
            <div class="card-header card-header-sm" v-if="!modal">
                {{ $t('Specializzazioni') }}
            </div>
            <div class="card-body">
                <div v-if="modal" class="font-size-xl font-weight-xbold mt-3">{{ $t('Specializzazioni')}}</div>
                <div v-for="(specialisation, index) in 3">
                    <div v-if="showingSpecialisationForm !== index || !isEditable($auth.user)">
                        <div class="d-flex specialisation-preview-container align-items-center" v-if="!user.user['specialisation_' + (index + 1) + '_id'] && isEditable($auth.user)">
                            <a href="#" class="mr-2" @click.prevent="showSpecialisationForm(index)">
                                <img alt="specialisations-circle" src="~/assets/images/icons/specialisations-circle.svg" width="40">
                            </a>
                            <div class="specialisation-text">
                                <a href="#" @click.prevent="showSpecialisationForm(index)">{{ $t('Aggiungi specializzazione')}} {{ index ? $t('secondaria') : $t('primaria') }}</a>
                            </div>
                        </div>
                        <div class="pt-3 pb-3 show-on-hover" v-else-if="user.user['specialisation_' + (index + 1) + '_id']">
                            <div class="d-flex">
                                <div>
                                    <div class="profile-item flex-grow-6">{{ user.user['specialisation' + (index + 1)] ? user.user['specialisation' + (index + 1)].name : ''}}</div>
                                    <div class="flex-grow-2 text-grey font-size-sm">{{ $t('Specializzazione ' )}}{{ index ? $t('secondaria') : $t('primaria') }}</div>
                                </div>
                                <div class="edit-text flex-grow-1">
                                    <b-dropdown right no-caret variant="link" class="align-self-center d-none d-lg-block absolute" style="right: 30px" v-if="isEditable($auth.user)">
                                        <template slot="button-content">
                                            <i class="cf-more text-grey"></i>
                                        </template>
                                        <b-dropdown-item @click.prevent="showSpecialisationForm(index)">{{ $t('Modifica specializzazione')}}</b-dropdown-item>
                                        <b-dropdown-item v-if="index" @click.prevent="swapPrimarySpecialisation(index)">{{ $t('Rendi primaria')}}</b-dropdown-item>
                                        <b-dropdown-item class="text-danger" @click.prevent="deleteSpecialisation(index)">{{ $t('Elimina specializzazione')}}</b-dropdown-item>
                                    </b-dropdown>
                                    <div class="dropdown d-block d-lg-none align-self-center absolute" style="right: 30px" v-if="isEditable($auth.user)">
                                        <dropup-menu :bottom="50" :hide-bottom-bar="true" :show-overlay="true">
                                            <template slot="menu-icon">
                                                <i class="cf-more text-grey" style="font-size: 5px"></i>
                                            </template>
                                            <template slot="menu-options">
                                                <ul class="menu-options">
                                                    <li @click.prevent="showSpecialisationForm(index)">
                                                        <i class="fa cf-pencil mr-2"></i> {{ $t('Modifica specializzazione')}}
                                                    </li>
                                                    <li v-if="index" @click.prevent="swapPrimarySpecialisation(index)">
                                                        <i class="fa fa-bookmark-o mr-2"></i> {{ $t('Rendi primaria')}}
                                                    </li>
                                                    <li class="text-danger" @click.prevent="deleteSpecialisation(index)">
                                                        <i class="fa fa-trash mr-2"></i> {{ $t('Elimina specializzazione')}}
                                                    </li>
                                                </ul>
                                            </template>
                                        </dropup-menu>
                                    </div>
                                </div>
                            </div>
                            <!--<div class="profile-item edit-text flex-grow-2 text-right"><a v-if="isEditable($auth.user)" href="#" class="d-hover-inline" @click.prevent="showSpecialisationForm(index)"><i class="cf-pencil"></i> {{ $t('Edit') }}</a></div>-->
                        </div>
                    </div>
                    <div class="form-container text-center d-none d-lg-block" v-else>
                        <div class="title text-grey mb-3">{{ $t('Aggiungi la tua specializzazione') }} {{ index ? $t('secondaria') : $t('primaria') }}</div>
                        <div class="d-flex justify-content-center align-items-center mb-3 ml-auto mr-auto" style="width: 80%">
                            <span class="text-grey text-right mr-3 flex-grow-1 flex-basis-0 d-none d-lg-flex">{{ $t('Specializzazione') }}</span>
                            <select class="form-control form-control-sm acumin has-label" name="first_name" v-model="editUser['specialisation_' + (index + 1) + '_id']">
                                <option disabled selected value class="text-grey"> {{ $t('Seleziona una specializzazione')}} </option>
                                <option v-for="specialisation in specialisations" :value="specialisation.id"> {{ specialisation.name }}</option>
                            </select>
                        </div>
                        <div class="d-flex justify-content-center align-items-center">
                            <a href="#" class="btn btn-brand btn-sm col-lg-3 mr-2" @click.prevent="updateUser(index + 1)"> {{ $t('Salva') }}</a>
                            <a href="#" class="btn btn-brand btn-sm btn-white col-lg-2" @click.prevent="hideSpecialisationForm('cancel')"> {{ $t('Annulla') }}</a>
                        </div>
                    </div>
                    <hr v-if="index < 2">
                </div>
            </div>
        </div>
        <select-picker
                class="d-lg-none"
                :options="specialisations"
                v-if="showingSpecialisationForm != null"
                v-model="editUser['specialisation_' + (showingSpecialisationForm + 1) + '_id']"
                :cancel-callback="hideSpecialisationForm"
                :save-callback="updateUser"
        >
            <template slot="picker-title">
                {{ $t('Specializzazioni') }}
            </template>
            <template slot="options-title">
                {{ $t('Specializzazione') }} {{ !showingSpecialisationForm ? $t('primaria') : $t('secondaria')}}
            </template>
        </select-picker>
    </div>
</template>

<script>
    import { mapGetters, mapState, mapActions } from 'vuex'
    import DropupMenu from "~/components/DropupMenu";
    import SelectPicker from "~/components/SelectPicker";

    export default {

        components: {
            DropupMenu,
            SelectPicker
        },

        props: ['modal'],

        data: function () {

            return {
                editUser: {},
                showingSpecialisationForm: null
            }
        },

        computed: {
            ...mapState({
                user: ({ user }) => user,
                specialisations: ({ specialisations }) => specialisations.specialisations
            }),
            ...mapGetters({
                'isEditable': 'user/IS_EDITABLE_BY_USER'
            })
        },

        methods: {
            ...mapActions({
                'save': 'user/update',
                'refetchUser': 'user/refetch'
            }),
            deleteSpecialisation(index) {
                this.editUser['specialisation_' + (index + 1) + '_id'] = ''
                this.updateUser();
            },
            swapPrimarySpecialisation(index) {

                let primarySpecialisationId = this.editUser['specialisation_1_id']

                this.editUser['specialisation_1_id'] = this.editUser['specialisation_' + (index + 1) + '_id'];
                this.editUser['specialisation_' + (index + 1) + '_id'] = primarySpecialisationId;
                this.updateUser();
            },
            updateUser(specialisationId = null) {
                if (specialisationId && (this.editUser['specialisation_' + (specialisationId) + '_id'] === 'undefined' || this.editUser['specialisation_' + (specialisationId) + '_id'] === "" || this.editUser['specialisation_' + (specialisationId) + '_id'] === null )) {
                  this.$notify({
                    group: 'messages',
                    type: 'error',
                    title: 'selezionare una specializzazione'
                  });
                  return
                }

                this.$nuxt.$loading.start();
                console.log(this.editUser)
                this.save(this.editUser)
                    .then(() => {
                        this.$notify({
                            group: 'messages',
                            type: 'success',
                            text: this.$t('Specializzazione salvata correttamente.')
                        });

                        this.refetchUser();
                        this.$nuxt.$loading.finish();
                        this.hideSpecialisationForm()
                    }).catch(() => {
                        this.$nuxt.$loading.finish();
                });
            },
            showSpecialisationForm (index) {
               this.showingSpecialisationForm = index;
            },
            hideSpecialisationForm (action) {
                if (action == 'cancel') {
                    this.init();
                }
                this.showingSpecialisationForm = null;
            },

            init () {
                this.editUser = _.cloneDeep(this.$store.state.user.user)
            }
        },
        created () {
            this.init();
        }
    }
</script>

<style lang="scss" scoped>

    .card-body {
        padding-top: 0!important;
    }

    a {
        text-decoration: none;
    }

    .specialisation-preview-container {

        padding: 12px 0;

        .specialisation-image {
            width: 30px;
            height: 30px;
            margin-bottom: 0!important;

            &.image-placeholder {
                background-color: #772A79;
            }
        }

        .specialisation-text {

            .specialisation-title {
                color: #403D40;
                font-size: 17px;
                font-weight: bold;
                line-height: 20px;
            }
            .specialisation-description {
                color: #8C8B8C;
                font-size: 15px;
                line-height: 18px;
            }
        }
        .edit-specialisation {
            margin-right: -10px
        }
    }
</style>
