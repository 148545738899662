
import Vue from 'vue'
import { getField, updateField } from 'vuex-map-fields';
import _ from 'lodash';

export const state = () => ({
    filters: {
        text:'',
        profile_rank: [],
        status: 'published'
    },
    certificates: [],
    totalCount: 0,
    processing: false
})

export const getters = {
    getField,
    getUpdateCertificatesForEntity: (state) => (entityId, entityType) => {

        return _.filter(state.certificates, (cert) => {

            return cert.type === 'update' && cert.entity_id === entityId && cert.entity_type === entityType && !cert.diploma;
        })
    },
    getSpecialisationCertificatesForEntity: (state) => (entityId, entityType) => {

        return _.filter(state.certificates, (cert) => {

            return cert.type === 'specialisation' && cert.entity_id === entityId && cert.entity_type === entityType && !cert.diploma;
        })
    },
    getDiplomasForEntity: (state) => (entityId, entityType) => {

        return _.filter(state.certificates, (cert) => {

            console.log(cert, entityId, entityType);

            return cert.entity_id === entityId && cert.entity_type === entityType && cert.diploma;
        });
    },
    getRejectedDiplomasCountForEntity: (state) => (entityId, entityType) => {

        return _.filter(state.certificates, (cert) => {

            return cert.entity_id === entityId && cert.entity_type === entityType && cert.diploma && cert.status === 'rejected';
        }).length;
    }
};

export const mutations = {
    updateField,
    SET_TEXT_FILTER (state, text) {
        state.filters.text = text
    },
    SET_PROCESSING (state, processing) {
        state.processing = processing
    },
    SET_CERTIFICATES (state, certificates) {
        state.certificates = certificates
    },
    APPEND_CERTIFICATE (state, certificate) {

        if(!_.includes(_.map(state.certificates, 'id'), certificate.id) || !certificate.id) {

            state.certificates.push(certificate);
        } else {

            let myCertificate = _.find(state.certificates, {id: certificate.id});

            if(myCertificate) {
                _.each(Object.keys(certificate), function (key) {
                    Vue.set(myCertificate, key, certificate[key]);
                })
            }
        }
    },
    REMOVE_CERTIFICATE (state, certificate) {
        let certificateIndex = _.findIndex(state.certificates, {id: certificate.id});

        if (certificateIndex > -1) {
            state.certificates.splice(certificateIndex, 1);
        }
    },
    SET_TOTAL_COUNT (state, totalCount) {
        state.totalCount = totalCount
    }
}

export const actions = {

    setTextFilter ({ commit, dispatch }, text) {
        commit('SET_TEXT_FILTER', text)

        dispatch('fetchAll')
    },

    fetchAll ({ commit, state}, params) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .get('/api/certificates', { params: {entity_id: params.entityId, entity_type: params.entityType ? params.entityType : 'user'} })
            .then(response => {
                _.each(response.data, (certificate) => {

                    commit('APPEND_CERTIFICATE', certificate);
                    if(certificate.user) {
                        commit('users/APPEND_USER', certificate.user, {root: true})
                    }
                });
                
                commit('SET_TOTAL_COUNT', response.length)
                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },

    delete ({ commit, state}, certificate) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .delete('/api/certificates/' + certificate.id)
            .then(r => r.data)
            .then(res => {
                commit('REMOVE_CERTIFICATE', certificate)
                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },

    appendCertificates ({commit, dispatch}, certificates) {

        if(!certificates || !certificates.length) return;

        _.each(certificates, (certificate) => {

            commit('APPEND_CERTIFICATE', certificate);
        });
    },
}