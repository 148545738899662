<template>
    <div class="news">
        <div id="from-notifications" class="from-notifications" v-if="notificationPosts.length && !userId">
            <div class="news-title mt-2 mb-1">{{ $t('Dalle notifiche') }}</div>
            <div v-autoscroll id="notifications-post" v-for="post in notificationPosts" :key="post.id" class="card border-0 post mb-2 new">
                <post :post="post"></post>
            </div>
        </div>
        <div id="news-title" class="news-title font-size-sm mt-2 mb-1" v-if="!userId && !type && !hideLabels">{{ $t('Nuove attività')}}</div>
        <div v-if="filteredPosts.length">
            <div v-for="post in filteredPosts" :key="post.id" class="card border-0 post mb-2">
                <slot name="post" :post="post">
                    <post :post="post" :modal="modal"></post>
                </slot>
            </div>
        </div>
        <div class="d-flex justify-content-center align-items-center background-white" v-else-if="!loading">
            <div class="p-3"><i>{{ $t('Non sono stati trovati risultati corrispondenti alla tua ricerca.') }}</i></div>
        </div>
        <client-only>
            <infinite-loading @infinite="loadMore" force-use-infinite-wrapper=".page"  ref="infiniteLoading">
                <span slot="spinner">
                    <!--<div class="d-flex justify-content-center background-white">-->
                        <!--<img alt="loading" src="~/assets/images/icons/loading.svg" class="fa fa-spin m-3" width="36px">-->
                    <!--</div>-->
                </span>
                <template slot="no-more">
                    <div class="d-flex justify-content-center align-items-center background-white">
                        <div class="p-3"><i>{{ $t('Fine dei risultati') }}</i></div>
                    </div>
                </template>
                <template slot="no-results">
                    <div class="d-flex justify-content-center align-items-center background-white">
                        <div class="p-3"><i>{{ $t('Non sono stati trovati risultati corrispondenti alla tua ricerca.') }}</i></div>
                    </div>
                </template>
            </infinite-loading>
        </client-only>
        <div class="animated-background mb-2" v-if="loading">
            <img alt="fake-post" class="w-100" src="~/assets/images/fake-post.png">
        </div>
    </div>
</template>

<script>

    import { mapState, mapActions, mapGetters } from 'vuex'
    import _ from 'lodash'

    export default {

        props: ['userId', 'modal', 'type', 'filters', 'hideLabels'],

        data: function () {
            return {
                loading: false,
                page: 1
            }
        },

        watch: {
          filters: {
              handler: _.debounce(function() {
                  this.reload()
              }, 300),
              deep:true
          }
        },

        computed: {
            ...mapState({
                'posts': ({ posts }) => posts,
                'group': ({group}) => group.group
            }),
            ...mapGetters({
                'getFilteredPosts': 'posts/getFilteredPosts',
            }),
            notificationPosts() {
                return this.getFilteredPosts({
                    group_id: this.type ? null : this.group.id,
                    user_id: this.type ? null : this.userId,
                    type: this.filters && this.filters.type ? this.filters.type : 'group-post',
                    text: this.filters && this.filters.text ? this.filters.text : '',
                    from_notifications: true
                })
            },
            filteredPosts() {
                return this.getFilteredPosts({
                    group_id: this.type ? null : this.group.id,
                    user_id: this.type ? null : this.userId,
                    type: this.filters && this.filters.type ? this.filters.type : 'group-post',
                    text: this.filters && this.filters.text ? this.filters.text : ''
                })
            }
        },

        methods: {
            ...mapActions({
               'fetchMore': 'posts/fetch'
            }),
            reload() {
                let filters = this.filters;
                this.fetchMore({ page: 0, group_id: null, user_id: this.userId, filters}).then(() => {

                    this.loading = false;
                    this.page++;
                })
            },
            loadMore($state) {

                if (this.loading || !this.posts.infiniteScrollEnabled) return;
                this.loading = true;
                let filters = this.filters;
                this.fetchMore({ page: this.page, group_id: this.group.id, user_id: this.userId, filters }).then(() => {

                    $state.loaded();

                    this.loading = false;
                    this.page++;
                })
            },
        },

        mounted () {
            this.reload()
        }
    }
</script>