<template>
    <div class="user-info d-flex align-items-center">
        <nuxt-link to="/profilo" class="d-none d-md-block">
            <user-image class="mr-lg-2" :user="auth.user"></user-image>
        </nuxt-link>
        <div class="d-block d-md-none" @click.prevent="closeMenu">
            <nuxt-link to="/chat">
                <img alt="messenger-w" src="~/assets/images/icons/messenger-w.svg" width="25px">
            </nuxt-link>
        </div>
        <nuxt-link to="/profilo" class="user-name mr-2">
            {{ auth.user.first_name }}
        </nuxt-link>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import UserImage from '~/components/UserImage'

    export default {

        components: {
            UserImage
        },

        computed: {
            ...mapState([
                'auth',
                'menuOpen'
            ])
        },

        methods: {
            closeMenu() {

                if (this.menuOpen) {
                    let event = {
                        event: 'close-menu'
                    }

                    if (window.ReactNativeWebView) {
                        window.ReactNativeWebView.postMessage(JSON.stringify(event));
                    } else {
                        window.postMessage(JSON.stringify(event), '*');
                    }
                }
            }
        }
    }
</script>