import Vue from 'vue'

import UserImage from '~/components/UserImage';
import AccountImage from '~/components/AccountImage';
import Post from '~/components/Group/Wall/Posts/Post';
import GroupBuy from '~/components/GroupBuys/GroupBuy';
import DropupMenu from '~/components/DropupMenu';
import ImagesGallery from '~/components/ImagesGallery';
import ImagesLoader from '~/components/ImagesLoader';
import UrlMetaTags from "~/components/UrlMetaTags";
import ProfileRank from "~/components/ProfileRank";
import AdminActions from '~/components/PendingUsers/PendingUser/AdminActions';
import WorkExperiencesCard from "~/components/UserProfile/ProfileInfo/Mobile/WorkExperiencesCard";
import BasicInformationCard from "~/components/UserProfile/ProfileInfo/Mobile/BasicInformationCard";
import CertificatesCard from "~/components/UserProfile/ProfileInfo/Mobile/CertificatesCard";

Vue.component('Post', Post);
Vue.component('GroupBuy', GroupBuy);
Vue.component('UserImage', UserImage);
Vue.component('AccountImage', AccountImage);
Vue.component('DropupMenu', DropupMenu);
Vue.component('ImagesGallery', ImagesGallery);
Vue.component('ImagesLoader', ImagesLoader);
Vue.component('UrlMetaTags', UrlMetaTags);
Vue.component('ProfileRank', ProfileRank);
Vue.component('AdminActions', AdminActions);
Vue.component('WorkExperiencesCard', WorkExperiencesCard);
Vue.component('BasicInformationCard', BasicInformationCard);
Vue.component('CertificatesCard', CertificatesCard);

