<template>
    <div class="card results-container rounded card-lg">
        <div class="title font-weight-xbold font-size-xl px-3 py-2 pb-0">
            <slot name="title">
                {{ $t('Centri estetici') }}
            </slot>
        </div>
        <div class="d-none d-lg-flex w-100 pl-3" v-if="!limit">
            <div>
                <a href="#" @click.prevent="filterByItaly">Italia</a><br />
                <i class="font-size-sm">{{ accounts.beautyCentresItalyCount.toLocaleString() }} {{ resourceName }}</i>
            </div>
            <div v-if="region" class="mx-4"> > </div>
            <div v-if="region">
                <a href="#" @click.prevent="filterByRegion">{{ region.name }}</a><br />
                <i class="font-size-sm">{{ accounts.beautyCentresRegionCount.toLocaleString() }} {{ resourceName }}</i>
            </div>
            <div v-if="state" class="mx-4"> > </div>
            <div v-if="state">
                <a href="#" @click.prevent="filterByState">{{ state.name }}</a><br />
                <i class="font-size-sm">{{ accounts.beautyCentresStateCount.toLocaleString() }} {{ resourceName }}</i>
            </div>
            <div v-if="city" class="mx-4"> > </div>
            <div v-if="city">
                <a href="#" @click.prevent="filterByCity">{{ city.name }}</a><br />
                <i class="font-size-sm">{{ accounts.beautyCentresCityCount.toLocaleString() }} {{ resourceName }}</i>
            </div>
        </div>

        <div class="results-body pr-3 pl-3">
            <div class="row">
                <div :class="view == 'list' ? 'col-lg-12' : 'col-lg-3'" v-for="account in filteredAccounts" :key="account.id">
                    <account-result class="result-row" :account="account" :view="view"></account-result>
                    <hr v-if="(view == 'list' || mobileMode) && account.id != lastAccount.id">
                </div>
            </div>
            <div class="justify-content-center background-white align-items-center pt-3 d-none d-lg-flex" v-if="!limit && accounts.pagination.last_page > 1">
                <b-pagination-nav :link-gen="paginatedPath" :number-of-pages="accounts.pagination.last_page" :value="$route.query.page || 1" use-router @change="goToPage"></b-pagination-nav>
            </div>
            <client-only>
                <infinite-loading @infinite="loadMore" force-use-infinite-wrapper=".page" ref="infiniteLoading" class="d-block d-lg-none">
                    <span class="d-flex justify-content-center" slot="spinner">
                        <img alt="loading" src="~/assets/images/icons/loading.svg" class="fa fa-spin m-3" width="36px">
                    </span>
                    <span class="d-flex flex-column justify-content-center align-items-center" slot="no-more">
                        <i class="fa fa-search fa-4x text-grey mt-5" v-if="!limit && !filteredAccounts.length"></i>
                        <span class="p-2 text-grey" v-if="!filteredAccounts.length">{{ $t('Nessun risultato') }}</span>
                        <span class="p-2 text-grey" v-if="!limit && filteredAccounts.length"><i>{{ $t('Fine dei risultati') }}</i></span>
                    </span>
                    <template slot="no-results">
                        <div class="d-flex justify-content-center align-items-center background-white">
                            <div class="p-3"><i>{{ $t('Non sono stati trovati risultati corrispondenti alla tua ricerca.') }}</i></div>
                        </div>
                    </template>
                </infinite-loading>
            </client-only>
        </div>
        <div class="p-2 pr-3 pl-3 results-footer" v-if="limit && filteredAccounts.length">
            <button class="btn btn-sm background-gray font-weight-medium w-100" @click.prevent="setActive" v-if="filteredAccounts.length <= $store.state.accounts.totalCount">{{ $t('Mostra tutti') }}</button>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
    import { mapFields } from 'vuex-map-fields'
    import AccountResult from '~/components/SearchAccounts/Result'
    import { BPaginationNav } from 'bootstrap-vue';
    import _ from 'lodash'

    export default {

        props: {
            limit: {
                type: Number,
                default: function () {
                    return 0
                }
            },
            filters: {
                default: function () {
                    return {
                        profile_rank: [],
                        status: 'published',
                        region_id: null,
                        state_id: null,
                        city_id: null
                    }
                }
            },
            type: {
                type: String,
                default: function() {
                    return 'beauty-centre'
                }
            }
        },
        data: function () {
            return {
                // view: this.$store.state.mobileMode ? 'grid' : 'list',
                view: 'list',
                page: 1
            }
        },

        components: {
            AccountResult,
            BPaginationNav
        },

        watch: {
            $route (to, from) {
                if (to.path === from.path) {
                    this.page = to.query.page
                    this.$store.commit('accounts/SET_ACCOUNTS', [])
                    this.$nuxt.$loading.start()
                    this.fetchMore({page: this.page, filters: {...this.filters, text: this.accounts.filters.text}})
                        .then(() => {
                            this.$nuxt.$loading.finish()
                        })
                }
            }
        },

        computed: {
            ...mapGetters({
                'getAccountsByFilters': 'accounts/getAccountsByFilters'
            }),
            ...mapState([
                'account',
                'accounts',
                'regions',
                'mobileMode'
            ]),
            resourceName() {
                let name = '';
                switch(this.type) {
                    case 'beauty-centre':
                        name =  'Centri'
                        break;
                    case 'company':
                        name =  'Scuole'
                        break;
                    case 'school':
                        name =  'Aziende'
                        break;
                    case 'brand':
                        name =  'Brand'
                        break;
                    default:
                        name = 'Centri'
                }
                return name
            },
            filteredAccounts() {

                let accounts = this.getAccountsByFilters({...this.filters, type: this.type, text: this.accounts.filters.text});

                if (this.limit) {
                    return accounts.slice(0, this.limit);
                } else {
                    return accounts;
                }
            },
            lastAccount() {
                return _.last(this.filteredAccounts);
            },
            region() {
                if (!this.filters.region_id) return null
                return _.find(this.regions.regions, {id: this.filters.region_id})
            },
            state() {
                if (!this.filters.state_id || !this.region) return null
                return _.find(this.region.states, {id: this.filters.state_id})
            },
            city() {
                if (!this.filters.city_id || !this.state) return null
                return _.find(this.state.cities, {id: this.filters.city_id})
            },
        },

        methods: {
            ...mapMutations({
                'resetInfiniteScroll': 'accounts/SET_INFINITE_SCROLL_ENABLED',
                'setAccounts': 'accounts/SET_ACCOUNTS',
                'setPagination': 'accounts/SET_PAGINATION'
            }),
            ...mapActions({
                'fetchMore': 'accounts/fetchMore'
            }),
            paginatedPath (page) {
                return {
                    path: this.$route.path,
                    query: { page }
                }
            },
            filterByItaly() {
                this.filters.region_id = null
            },
            filterByRegion() {
                this.filters.state_id = null
            },
            filterByState() {
                this.filters.city_id = null;
            },
            filterByCity() {
                //nothing basically
            },
            goToPage (page) {
                this.$router.push({ path: this.$route.path, query: { page: page } })
            },
            gridView() {
                this.view = 'grid'
            },
            listView() {
                this.view = 'list'
            },
            updateLoaderState() {

                this.$refs.infiniteLoading && this.$refs.infiniteLoading.stateChanger.loaded();

                if (!this.accounts.infiniteScrollEnabled || this.limit) {
                    this.$refs.infiniteLoading && this.$refs.infiniteLoading.stateChanger.complete();
                }
            },
            loadMore($state) {

                this.fetchMore({ page: this.page, filters: {...this.filters, type: this.type} }).then(() => {

                    this.updateLoaderState();
                    this.page++;
                })
            },
            setActive() {

                let accountType = ''

                switch(this.type) {
                    case 'beauty-centre':
                        accountType = 'centri-estetici';
                        break;
                    case 'company':
                        accountType = 'aziende-cosmetiche';
                        break;
                    case 'school':
                        accountType = 'scuole';
                        break;
                    case 'brand':
                        accountType = 'marchi';
                        break;
                    default:
                        accountType = 'centri-estetici';
                        break;
                }

                this.$router.push('/' + accountType);


                // this.$nuxt.$emit('set-active-tab', 'accounts');
            }
        },

        mounted () {
            this.$nuxt.$on('search-accounts', () => {
                this.page = 1;
                this.$refs.infiniteLoading && this.$refs.infiniteLoading.stateChanger.reset();
                this.setAccounts([])
                this.page = 0;
                this.loadMore()
            })

            this.page = this.$route.query.page || 1
        }
    }
</script>

<style lang="scss" scoped>

    .card-header {
        .title {
            margin-left: -15px;
        }
    }

    .card-body {
        padding-top: 0!important;
    }

    .result-row {
        padding-top: 18px;

    }

    .circle-container {
        width: 30px;
        height: 30px;
        color: white;
    }

    .divider {
        margin: 0 12px;
        width: 2px;
        height: 33px;
        border: 1px solid #F4F2F3;
    }
</style>
