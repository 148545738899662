
import { getField, updateField } from 'vuex-map-fields';
import _ from 'lodash'

export const state = () => ({
    filters: {
        reviewer_user_id: null,
        reviewed_user_id: null,
        reviewed_account_id: null,
        treatment_id: null
    },
    sort: {
        column: 'date',
        order: 'desc'
    },
    pagination: {
        page: 0,
        per_page: 20,
    },
    reviews: [],
    totalCount: 0,
    infiniteScrollEnabled: true,
    processing: false
})

export const getters = {
    getField
}

export const mutations = {
    updateField,
    SET_REVIEWER_USER_ID_FILTER (state, userId) {
        state.filters.reviewer_user_id = userId
    },
    SET_REVIEWED_USER_ID_FILTER (state, userId) {
        state.filters.reviewed_user_id = userId
    },
    SET_REVIEWED_ACCOUNT_ID_FILTER (state, accountId) {
        state.filters.reviewed_account_id = accountId
    },
    SET_FILTERS (state, filters) {
        state.filters = filters
    },
    SET_PROCESSING (state, processing) {
        state.processing = processing
    },
    SET_REVIEWS (state, reviews) {
        state.reviews = reviews
    },
    APPEND_REVIEWS (state, reviews) {

        _.each(reviews, (review) => {
            state.reviews.push(review);
        });
    },
    SET_INFINITE_SCROLL_ENABLED (state, infiniteScrollEnabled) {
        state.infiniteScrollEnabled = infiniteScrollEnabled
    },
    SET_TOTAL_COUNT(state, totalCount) {
        state.totalCount = totalCount
    }
}

export const actions = {

    fetchAll ({ commit, state}, data) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .get('/api/reviews', {
                params: {
                    ...state.filters,
                    ...state.sort,
                    page: data && data.page ? data.page : state.pagination.page,
                    per_page: data && data.per_page ? data.per_page : state.pagination.per_page
                }
            })
            .then(r => r.data)
            .then(response => {
                commit('SET_REVIEWS', response.data)
                commit('SET_TOTAL_COUNT', response.total)
                commit('SET_INFINITE_SCROLL_ENABLED', response.data.length >= ( data && data.per_page ? data.per_page : state.pagination.per_page));
                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },

    fetchMore ({ commit, state}, data) {

        commit('SET_PROCESSING', true);

        return this.$axios
            .get('/api/reviews', {
                params: {
                    ...state.filters,
                    ...state.sort,
                    page: data && data.page ? data.page : state.pagination.page,
                    per_page: data && data.per_page ? data.per_page : state.pagination.per_page
                }
            })
            .then(r => r.data)
            .then(response => {
                commit('APPEND_REVIEWS', response.data);
                commit('SET_TOTAL_COUNT', response.total);
                commit('SET_INFINITE_SCROLL_ENABLED', response.data.length >= ( data && data.per_page ? data.per_page : state.pagination.per_page));
                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    }
}