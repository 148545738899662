<template>
    <div>
        <div class="bottom-bar fixed" v-if="auth.loggedIn" @click.prevent="closeMenu">
            <div class="bottom-bar-icon">
                <nuxt-link to="/dashboard">
                    <img :src="require('~/assets/images/icons/' + (tmpMode ? 'tmp/' : '') + 'bacheca.svg')" />
                    <img :src="require('~/assets/images/icons/' + (tmpMode ? 'tmp/' : '') + 'bacheca-f.svg')" class="active" />
                </nuxt-link>
            </div>
            <div class="bottom-bar-icon" v-if="!tmpMode">
                <nuxt-link to="/profilo">
                    <img :src="require('~/assets/images/icons/' + (tmpMode ? 'tmp/' : '') + 'profile.svg')" />
                    <img :src="require('~/assets/images/icons/' + (tmpMode ? 'tmp/' : '') + 'profile-f.svg')" class="active" />
                </nuxt-link>
            </div>
            <div class="bottom-bar-icon" v-if="auth.loggedIn && auth.user.is_beautician">
                <nuxt-link to="/gruppo">
                    <img :src="require('~/assets/images/icons/' + (tmpMode ? 'tmp/' : '') + 'gruppo.svg')" />
                    <img :src="require('~/assets/images/icons/' + (tmpMode ? 'tmp/' : '') + 'blue-group.svg')" class="active" />
                </nuxt-link>
            </div>
            <div class="bottom-bar-icon" v-if="!tmpMode">
                <nuxt-link to="/gruppi-di-acquisto">
                    <img :src="require('~/assets/images/icons/' + (tmpMode ? 'tmp/' : '') + 'purchasing-group.svg')" />
                    <img :src="require('~/assets/images/icons/' + (tmpMode ? 'tmp/' : '') + 'purchasing-group-f.svg')" class="active" />
                </nuxt-link>
            </div>
            <!--<div class="bottom-bar-icon" v-if="!tmpMode">-->
                <!--<nuxt-link to="/marketplace">-->
                    <!--<img :src="require('~/assets/images/icons/' + (tmpMode ? 'tmp/' : '') + 'marketplace.svg')" />-->
                    <!--<img :src="require('~/assets/images/icons/' + (tmpMode ? 'tmp/' : '') + 'marketplace-f.svg')" class="active" />-->
                <!--</nuxt-link>-->
            <!--</div>-->
            <div class="bottom-bar-icon" @click.prevent="clearNotifications">
                <nuxt-link to="/notifiche">
                    <div class="relative">
                        <img :src="require('~/assets/images/icons/' + (tmpMode ? 'tmp/' : '') + 'bell.svg')" />
                        <img :src="require('~/assets/images/icons/' + (tmpMode ? 'tmp/' : '') + 'bell-f.svg')" class="active" />
                        <div class="absolute circle-number-container d-flex justify-content-center" v-if="$auth.user.unread_notifications_count">{{ $auth.user.unread_notifications_count < 99 ? $auth.user.unread_notifications_count : 99 }}</div>
                    </div>
                </nuxt-link>
            </div>
            <div class="bottom-bar-icon">
                <nuxt-link to="/menu">
                    <div class="relative">
                        <img src="~assets/images/icons/menu.svg" />
                        <img src="~assets/images/icons/menu-f.svg" class="active" />
                    </div>
                </nuxt-link>
                <!--<user-menu ref="userMenu">-->
                    <!--<template slot="menu-button">-->
                        <!--<img src="~assets/images/icons/menu.svg" />-->
                        <!--<img src="~assets/images/icons/menu-f.svg" class="active" />-->
                    <!--</template>-->
                <!--</user-menu>-->
            </div>
        </div>
    </div>
</template>

<script>

    import UserMenu from '~/components/UserMenu'
    import { mapGetters, mapState } from 'vuex'

    export default {

        components: {
            UserMenu
        },

        computed: {
            ...mapState([
                'auth',
                'notifications',
                'menuOpen',
                'tmpMode'
            ])
        },

        methods: {
            clearNotifications() {

                this.$store.dispatch('notifications/clear').then(() => {
                    this.$auth.fetchUser()
                });

                let event = {
                    event: 'clear-notifications'
                }

                if (window.ReactNativeWebView) {
                    window.ReactNativeWebView.postMessage(JSON.stringify(event));
                } else {
                    window.postMessage(JSON.stringify(event), '*');
                }
            },
            closeMenu() {

                if (this.menuOpen) {

                    let event = {
                        event: 'close-menu'
                    }

                    if (window.ReactNativeWebView) {
                        window.ReactNativeWebView.postMessage(JSON.stringify(event));
                    } else {
                        window.postMessage(JSON.stringify(event), '*');
                    }
                }
            }
        },

        mounted () {

        }
    }
</script>

<style lang="scss" scoped>
    .circle-number-container {
        border-radius: 50%;
        top: 0;
        right: -7px;
        width: 17px;
        height: 17px;
        background-color: red;
        color: white;
        font-size: 14px;
    }

    .bottom-bar-icon {
        img {
            width: 25px;
            &.active {
                display: none;
            }
        }

        a.nuxt-link-active:not(.menu-open),
        a.menu-open {
            img {
                display: none;

                &.active {
                    display: block;
                }
                &:not(.active) {
                    display: none;
                }
            }
        }
    }

</style>