<template>
    <div class="card border-0">
        <div class="card-header card-header-sm d-flex justify-content-center align-items-center">
            <i class="cf-back absolute p-3" @click.prevent="resetImgSrc()" style="left: 0"></i>
            <div v-if="certificate.certificate.diploma">
                <span v-if="certificate.certificate.type === 'specialisation'">{{ $t('Immagine specializzazione')}}</span>
                <span v-else>{{ $t('Immagine qualifica') }}</span>
            </div>
            <div v-else>{{ $t('Immagine attestato') }}</div>
            <!--<i class="fa fa-rotate-left absolute p-3" @click.prevent="toggleAspectRatio()" style="right: 0"></i>-->
        </div>
        <div class="card-body p-3 d-flex align-items-center text-center overflow-auto" style="background: black">
            <!--<input type="file" name="image" id="update-profile-certificate-input" accept="image/*" @change="setImage" class="d-none"/>-->
            <vue-cropper
                    ref="cropper"
                    :guides="true"
                    drag-mode="move"
                    :view-mode="2"
                    :auto-crop-area="1"
                    :min-container-width="250"
                    :min-container-height="250"
                    :container-style="{'max-height': '100%'}"
                    :crop-box-movable="false"
                    :crop-box-resizable="true"
                    :background="true"
                    :rotatable="true"
                    alt="Source Image"
                    v-show="imgSrc"
                    :img-style="{width: '100%', 'height': '100%'}">
            </vue-cropper>
        </div>
        <div class="card-footer">
            <div class="w-100 d-flex flex-column">
                <!--<button class="btn btn-brand w-100" @click.prevent="openInput" :disabled="user.user.profile_type === 'student' && certificate.certificate.diploma">-->
                    <!--<i class="cf-camera mr-1"></i>-->
                    <!--<span v-if="!imgSrc">{{ $t('Carica immagine') }}</span>-->
                    <!--<span v-else >{{ $t('Nuova immagine') }}</span>-->
                <!--</button>-->
                <button class="btn btn-brand w-100" @click.prevent="getCroppedArea()">{{ $t('Salva') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState, mapMutations } from 'vuex'
    import _ from 'lodash'

    export default {

        props: ['editCertificate', 'imgSrc', 'blob'],

        data() {
            return {
                landscape: false
            }
        },

        computed: {
            ...mapState({
                'user': ({ user }) => user,
                'certificate': ({ certificate }) => certificate,
                'uploadCertificate': 'upload-certificate'
            }),
            aspectRatio() {
                return this.landscape ? (29.7/21) : (21/29.7);
            }
        },

        watch: {
            aspectRatio(val) {
                this.$refs.cropper.setAspectRatio(val)
            }
        },

        methods: {
            ...mapMutations({
                setStep: 'profile-wizard/SET_STEP',
                setUploadCertificateStep: 'upload-certificate/SET_STEP',
            }),
            ...mapActions({
                'refetchUser': 'user/refetch'
            }),

            closeUploadCertificate() {
                if (this.$route.path === '/aggiorna-profilo') {
                    this.setStep('education')
                } else {
                    this.$router.push('/profilo')
                }
            },
            getCroppedArea() {
                if (this.imgSrc) {
                    this.$refs.cropper.getCroppedCanvas({maxWidth: 1920, maxHeight: 1920}).toBlob(blob => {
                        this.$emit('update:blob', blob)

                        this.resetImgSrc();

                        // this.saveCertificate(blob)

                    } , 'image/jpeg', 0.95);
                } else {
                    this.resetImgSrc();

                }
            },
            resetImgSrc() {
                this.$emit('update:imgSrc', '')
            },
            saveCertificate(blob) {

                let action = this.certificate.certificate.id ? 'update' : 'create';

                this.$nuxt.$loading.start();

                this.$store
                    .dispatch('certificate/' + action, blob)
                    .then(r => {
                        this.$nuxt.$loading.finish();
                        this.resetImgSrc();
                    })
                    .catch((error) => {
                        this.$nuxt.$loading.finish();
                    })
            },
            removeImg () {

                this.$axios.post('/api/certificates/' + this.editCertificate.id + '/remove-image')
                    .then(r => {
                        this.$store.dispatch('certificate/fetchById', this.editCertificate.id)
                            .then(certificate => {

                                this.$notify({
                                    group: 'messages',
                                    type: 'success',
                                    text: this.$t('Immagine rimossa correttamente.')
                                })

                                this.editCertificate = _.cloneDeep(this.$store.state.certificate.certificate);
                            })
                    })

                this.refetchUser();
            },
            setImage (e) {

                const file = e.target.files[0];

                if (!file.type.includes('image/')) {
                    alert('Please select an image file');
                    return;
                }

                if (typeof FileReader === 'function') {
                    const reader = new FileReader();

                    reader.onload = (event) => {

                        this.$emit('update:imgSrc', event.target.result)
                    };

                    reader.readAsDataURL(file);
                } else {
                    alert('Sorry, FileReader API not supported');
                }
            },
            toggleAspectRatio() {
                this.landscape = !this.landscape;
            }
        },

        mounted () {
            this.$refs.cropper.replace(this.imgSrc);
        },
    }
</script>