
import { getField, updateField } from 'vuex-map-fields';

export const state = () => ({
    active_users: [],
    processing: false
})

export const getters = {
    getField
}

export const mutations = {
    updateField,
    SET_PROCESSING (state, processing) {
        state.processing = processing
    },
    SET_ACTIVE_USERS (state, users) {
        state.active_users = users
    }
}

export const actions = {
    getData ({ dispatch, commit, state}, params) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .post('/api/dashboard', params)
            .then(r => r.data)
            .then(response => {

                commit('SET_PROCESSING', false)
                commit('SET_ACTIVE_USERS', response.active_users)
                dispatch('posts/appendPosts', response.posts, {root: true});
                _.each(response.posts, post => {
                    if(post.group_buy_product) {
                        commit('group-buys/APPEND_GROUP_BUY', post.group_buy_product, {root: true});
                    }
                })
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    }
}