<template>
    <div>
        <div v-if="!account.uploadingProfileImage && !account.uploadingHeaderImage" class="text-center">
            <input type="file" name="image" :id="type + '-image-input'" accept="image/*" @change="setImage" style="display: none"/>

            <div v-if="!imgSrc">
                <div class="mb-3 image-container d-flex align-items-center justify-content-center" @click.prevent="openInput">
                    <!--<img alt="header-placeholder2" :class="type.toLowerCase() + '-image'" src="~/assets/images/header-placeholder2.jpg">-->
                    <img :class="type.toLowerCase() + '-image'" :src="imgPath" v-if="imgPath">
                    <div v-else>
                        <img alt="account-placeholder" :class="type.toLowerCase() + '-image'" src="~/assets/images/account-placeholder.svg" v-if="type == 'Profile'">
                        <img alt="header-placeholder" :class="type.toLowerCase() + '-image'" src="~/assets/images/header-placeholder.jpg" v-else>
                    </div>
                </div>
                <div v-if="type == 'Profile'">
                    <strong class="title mt-3">
                        {{ $t('Aggiungi un logo del tuo centro estetico')}}
                    </strong>
                    <div class="description mt-3">
                        {{ $t('Avere un logo ed un marchio moderno non è solamente importante, ma fondamentale. Il logo è lo spirito del tuo centro estetico e le persone tendono a dare la loro fiducia ad una azienda che ha un\'immagine grafica curata e riconoscibile') }}
                    </div>
                    <!--<edit-image type="Profile">-->
                    <!--<template slot="open-modal-button">-->
                </div>
                <div v-else>
                    <strong class="title mt-3">
                        {{ $t('Aggiungi un\'immagine di copertina')}}
                    </strong>
                    <div class="description mt-3">
                        {{ $t('Scegli un\'immagine che verrà visualizzata nella parte superiore del tuo profilo.') }}
                    </div>
                </div>
                <button class="btn btn-brand mr-1 w-100" @click.prevent="openInput">
                    <i class="cf-camera text-white"></i>
                    {{ $t('Scegli un\'immagine') }}
                </button>
            </div>
            <div class="cropper-container-size d-flex align-items-center justify-content-center" :class="type.toLowerCase() + '-cropper'" v-else>
                <vue-cropper
                        ref="cropper"
                        :src="imgSrc"
                        dragMode="move"
                        :guides="false"
                        :info="true"
                        :minCropBoxWidth="250"
                        :minCropBoxHeight="250"
                        :canMove="true"
                        :autoCrop="true"
                        :autoCropArea="options.autoCropArea"
                        :aspectRatio="options.aspectRatio"
                        :cropBoxMovable="false"
                        :cropBoxResizable="false"
                        alt="Source Image"
                        v-if="imgSrc"
                        :imgStyle="{'width': '100%'}"
                        :containerStyle="{'width': '100%'}"
                >
                </vue-cropper>
            </div>
        </div>
        <div v-else class="text-center m-3">
            <i class="fa fa-spinner fa-spin fa-4x"></i>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions, mapMutations } from 'vuex'

    export default {
        props: {
            type: {
                type: String,
                    default: function () {
                        return 'Profile'
                    }
                },
            imgSrc: {
                type: String,
                default: function () {
                    return ''
                }
            }
        },

        data () {
            return {
                options: {
                    autoCropArea: this.type === 'Profile' ? 0.5 : 1,
                    aspectRatio: this.type === 'Profile' ? 1 : 2.53,
                }
            }
        },

        computed: {
            ...mapState([
                'account'
            ]),

            imgPath() {
                if (this.type === 'Profile') {

                    return this.account.account.profile_image_res ? this.account.account.profile_image_res.medium : ''
                }
                else {
                    return this.account.account.header_image_res ? this.account.account.header_image_res.medium : ''
                }
            }
        },

        methods: {
            ...mapActions({
                'refetchAccount': 'account/refetch'
            }),
            ...mapMutations({
                'setStep': 'work-experience/SET_STEP'
            }),
            setImage (e) {

                const file = e.target.files[0];

                if (!file.type.includes('image/')) {
                    alert('Please select an image file');
                    return;
                }

                if (typeof FileReader === 'function') {
                    const reader = new FileReader();

                    reader.onload = (event) => {
                        this.$emit('update:imgSrc', event.target.result)
                        // rebuild cropperjs with the updated source
                        this.$refs.cropper.replace(event.target.result);
                    };

                    reader.readAsDataURL(file);
                } else {
                    alert('Sorry, FileReader API not supported');
                }
            },
            saveImage () {

                this.$refs.cropper.getCroppedCanvas({maxWidth: 1920, maxHeight: 1920}).toBlob(blob => {

                    let action = this.type.substr(0, this.type.indexOf('-'));

                    this.$store
                        .dispatch('account/update' + (action ? action : this.type) + 'Image', blob)
                        .then(r => {

                            this.refetchAccount()
                                .then(() => {

                                    this.$notify({
                                        group: 'messages',
                                        type: 'success',
                                        text: this.$t('Immagine aggiornata correttamente.')
                                    });

                                    this.imgSrc = '';

                                    if (this.type === 'Profile') {
                                        this.setStep('header-image')
                                    } else {
                                        this.setStep('complete')
                                    }
                                })
                        })
                }, 'image/jpeg', 0.95);
            },

            openInput (isVisible) {

                if (isVisible) {
                    let image = document.getElementById(this.type + "-image-input")

                    image.click()
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

    .title {
        font-size: 22px!important;
    }

    .cropper-container-size {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .header-image {
        width: 100%;
    }

    .image-container {

        min-height: 150px;

        .profile-image {
            width: 150px;
            height: 150px;
            border-radius: 8px;
            overflow: hidden;
        }
    }
</style>
