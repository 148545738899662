<template>
    <div>
        <div class="d-flex relative">
            <img class="profile-img profile-placeholder" :alt="user ? user.name : $t('Immagine profilo')" :style="'min-width: ' + size + 'px;max-width: ' + size + 'px; height: ' + size + 'px'" :src="require('~/assets/images/profile_placeholder_' + (user && user.gender === 'male' ? 'm' : 'f') + '.svg')" v-if="!user || !user.profile_image_res"/>
            <img class="profile-img" :alt="user.name" :style="'min-width: ' + size + 'px;max-width: ' + size + 'px; height: ' + size + 'px'" :src="user.profile_image_res.small" v-else/>
            <slot name="profile-rank"></slot>
        </div>

        <!--<images-gallery ref="galleryImagesModal" v-if="user.profile_image_res" :images="images"></images-gallery>-->
        <!--<images-gallery id="gallery-images-modal"-->
                        <!--v-if="user.profile_image_res"-->
                        <!--image-prefix="profile_"-->
                        <!--ref="galleryImagesModal"-->
                        <!--:images="images"-->
        <!--&gt;-->
            <!--<template slot="gallery-title">-->
                <!--{{ $t('Attestato di qualifica')}}-->
            <!--</template>-->
            <!--<template slot="dropdown-menu">-->
                <!--<b-dropdown right no-caret variant="link" class="align-self-center d-none d-lg-block">-->
                    <!--<template slot="button-content">-->
                        <!--<i class="cf-more"></i>-->
                    <!--</template>-->
                    <!--<b-dropdown-item @click.prevent="$router.push('/estetiste/' + user.id)">{{ $t('Visualizza profilo')}}</b-dropdown-item>-->
                <!--</b-dropdown>-->
                <!--<div class="dropdown d-block d-lg-none align-self-center">-->
                    <!--<dropup-menu :hide-bottom-bar="true" :show-overlay="true">-->
                        <!--<template slot="menu-icon">-->
                            <!--<i class="cf-more btn-link"></i>-->
                        <!--</template>-->
                        <!--<template slot="menu-options">-->
                            <!--<ul class="menu-options">-->
                                <!--<li @click.prevent="$router.push('/estetiste/' + user.id)"> {{ $t('Visualizza profilo')}}</li>-->
                            <!--</ul>-->
                        <!--</template>-->
                    <!--</dropup-menu>-->
                <!--</div>-->
            <!--</template>-->
            <!--<template slot="resource-image">-->
                <!--<user-image class="relative mr-4" :user="user" size="50">-->
                    <!--<template slot="profile-rank">-->
                        <!--<profile-rank class="absolute bottom right" :hide-text="true" :user="user" size="small"></profile-rank>-->
                    <!--</template>-->
                <!--</user-image>-->
            <!--</template>-->
            <!--<template slot="resource-name">-->
                <!--<nuxt-link class="title" :to="'/estetiste/' + user.id">{{ user.first_name }} {{ user.last_name}}</nuxt-link>-->
            <!--</template>-->
        <!--</images-gallery>-->
    </div>
</template>

<script>
    export default {
        name: 'UserImage',
        props: {
            'user': Object,
            'size': {
                type: String,
                default() {
                    return '30';
                }
            }
        },
        computed: {
            images() {

                let images = []

                let image = {
                    image_res: this.user.profile_image_res
                }
                images.push(image)

                return images
            }
        }
    }
</script>

<style lang="scss" scoped>
  .profile-img {
    border-radius: 50%;
      &.profile-placeholder {
          border: 1px solid #d8bcd3;
      }
  }
  .profile-rnk {
      position: absolute;
      width: 100%;
      height: 100%;
  }
  .bottom {
      bottom: -2px;
  }
  .right {
      right: -2px;
  }
</style>

