import axios from 'axios'

export default function (req, res, next) {

    let userMatches = req.url.match((/\/(?:albo|utente)-(\d+)-.*/))
    let accountMatches = req.url.match((/\/centro-(\d+)-.*/))
    let companyMatches = req.url.match((/\/azienda-(\d+)-.*/))
    let accountsMatches = req.url.match((/\/estetica(.*)/))
    let accountReviewsMatches = req.url.match((/\/(?:recensioni|scrivi_recensione)-(\d+).*?/))
    let comunicatiMatches = req.url.match((/\/chi-siamo\/category\/comunicati.*?/))
    let chiSiamoMatches = req.url.match((/\/chi-siamo\/(.*[^\/])/))
    let puntoEsteticoArticleMatches = req.url.match((/\/punto-estetico\/(\d+).*?/))
    let puntoEsteticoSectionMatches = req.url.match((/\/punto-estetico\/sezione\/([^\/]+)/))
    let abusiveMatch = req.url.match((/\/abusivi.*?/))
    let attachmentMatches = req.url.match((/\/wp-content\/uploads\/([0-9]+\/(?:[0-9]+)\/[^"']*)/))
    let redirected_id = null
    let baseUrl = process.env.SERVER_BASE_URL;
    let importedId = null

    if (userMatches && userMatches.length > 1 && userMatches[1] != undefined) {

        importedId = userMatches[1];

        axios.get(baseUrl + '/api/get-user-rewrite', { params: {imported_id: importedId} })
            .then((response) => {
                if (response.data.user_id && response.data.user_id > 0) {
                    res.writeHead(301, { Location: ("/estetiste/" + response.data.user_id) + '-' + response.data.slug })
                    res.end()
                } else {
                    next()
                }
            }).catch((response) => {

                res.writeHead(301, { Location: '/estetiste' })
                res.end()

                console.log("ERROR", response)
            })

    } else if (
        (accountMatches && accountMatches.length > 1 && accountMatches[1] != undefined)
        ||
        (accountReviewsMatches && accountReviewsMatches.length > 1 && accountReviewsMatches[1] != undefined)
    ) {

        let reviewTab = false;

        if (accountMatches && accountMatches.length > 1 && accountMatches[1] != undefined) {
            importedId = accountMatches[1];
        } else if (accountReviewsMatches && accountReviewsMatches.length > 1 && accountReviewsMatches[1] != undefined) {
            importedId = accountReviewsMatches[1];
            reviewTab = true;
        }

        axios.get(baseUrl + '/api/get-account-rewrite', { params: {imported_id: importedId} })
            .then((response) => {
                if (response.data.account_id && response.data.account_id > 0) {
                    res.writeHead(301, { Location: ("/centri-estetici/" + response.data.account_id + '-' + response.data.slug + (reviewTab ? '?tab=reviews' : '')) })
                    res.end()
                } else {
                    res.writeHead(301, { Location: ("/centri-estetici") })
                    res.end()
                }
            }).catch((response) => {

                res.writeHead(301, { Location: '/centri-estetici' })
                res.end()

                console.log("ERROR", response)
            })

    } else if (companyMatches && companyMatches[1] !== undefined && companyMatches.length > 1) {

        let importedId = companyMatches[1];

        axios.get(baseUrl + '/api/get-account-rewrite', { params: {imported_id: importedId} })
            .then((response) => {
                if (response.data.account_id && response.data.account_id > 0) {
                    res.writeHead(301, { Location: ("/aziende-cosmetiche/" + response.data.account_id + '-' + response.data.slug) })
                    res.end()
                } else {
                    res.writeHead(301, { Location: ("/aziende-cosmetiche") })
                    res.end()
                }
            }).catch((response) => {

                res.writeHead(301, { Location: '/centri-estetici' })
                res.end()

                console.log("ERROR", response)
            })

    } else if (attachmentMatches) {

        let filename = attachmentMatches[1]

        axios.get(baseUrl + '/api/get-attachment-rewrite', { params: {file_name: filename} })
            .then((response) => {
                res.writeHead(301, { Location: (response.data.url) })
                res.end()
            }).catch((response) => {

                res.writeHead(301, {Location: '/'})
                res.end()

                console.log("ERROR", response)
            })
    } else if (req.url.match(/\/admin.*?/)) {

        res.writeHead(301, { Location: ("/") })
        res.end()
    } else if (req.url.match(/\/estetica\/in.*?/)) {

        res.writeHead(301, { Location: ("/centri-estetici") })
        res.end()
    } else if (req.url.match(/\/(?:albo_confestetica\.php|albo_estetisti\.php|utenti)/)) {

        res.writeHead(301, { Location: ("/estetiste") })
        res.end()
    } else if (req.url.match(/\/(aziende\.php)/)) {

        res.writeHead(301, { Location: ("/aziende-cosmetiche") })
        res.end()
    } else if (req.url.match(/\/(bacheca\.php)/)) {

        res.writeHead(301, { Location: ("/dashboard") })
        res.end()
    } else if (req.url.match(/\/albo-degli-estetisti\/elenco-iscritti-albo-nazionale-estetisti/)) {

        res.writeHead(301, { Location: ("/estetiste") })
        res.end()
    } else if (accountsMatches) {

        let params = accountsMatches[1];

        res.writeHead(301, { Location: ("/centri-estetici" + params) })
        res.end()
    } else if (comunicatiMatches) {

        res.writeHead(301, { Location: ("/comunicazioni") })
        res.end()
    } else if (abusiveMatch) {

        res.writeHead(301, { Location: ("/abusive") })
        res.end()
    } else if (chiSiamoMatches) {

        let article = chiSiamoMatches[1];

        if (article.startsWith('tag/') || article === 'tag') {
            res.writeHead(301, { Location: '/chi-siamo' })
            res.end()

            return;
        }

        axios.get(baseUrl + '/api/get-chi-siamo-rewrite', { params: {link: article} })
            .then((response) => {

                console.log(response.data.url, article);

                if (response.data.url === ("/" + article) || response.data.url === ("/comunicazioni/" + article)) {

                    res.writeHead(301, { Location: response.data.url })
                    res.end()
                } else {
                    next()
                }
            }).catch((response) => {

                res.writeHead(301, { Location: '/chi-siamo' })
                res.end()

                console.log("ERROR", response)
            })

    } else if (puntoEsteticoArticleMatches) {

        let importedId = puntoEsteticoArticleMatches[1];

        axios.get(baseUrl + '/api/get-punto-estetico-article-rewrite', { params: {importedId: importedId} })
            .then((response) => {

                res.writeHead(301, { Location: response.data.url })
                res.end()

            }).catch((response) => {

                console.log("ERROR", response)
                res.writeHead(301, { Location: "/punto-estetico" })
            })

    } else if (req.url.match((/\/contatti.*?/))) {

        res.writeHead(301, { Location: "/" })
        res.end()
    } else if (req.url.match((/\/(modulo-di-iscrizione|associati)/))) {

        res.writeHead(301, { Location: "/diventa-socio" })
        res.end()
    } else if (puntoEsteticoSectionMatches) {

        let section = puntoEsteticoSectionMatches[1];

        res.writeHead(301, { Location: ("/punto-estetico/" + section )})
        res.end()
    } else if (req.url.match((/\/punto-estetico\/date\/.*?/))) {

        res.writeHead(301, { Location: "/punto-estetico" })
        res.end()
    } else {

        axios.get(baseUrl + '/api/check-redirects', { params: {url: req.url} })
            .then((response) => {
                res.writeHead(301, { Location: response.data.url })
                res.end()
            }).catch((response) => {
                next()
            })
    }
}