<template>
    <b-modal :id="'gallery-modal-' + this._uid" modal-class="slide-up news images-gallery-modal" body-class="post" no-fade ref="imagesGalleryModal" @shown="init" @hidden="destroy" lazy>

        <span class="absolute close d-none d-lg-block" @click.prevent.stop="hideImagesGalleryModal">×</span>
        <div v-if="pz && pz.zoomFactor === 1" class="position-fixed d-flex w-100 justify-content-center align-items-center d-lg-none gallery-top-bar" :style="'padding-top:' + insets.top + 'px'">
            <i class="cf-back flex-basis-0 p-3" @click.prevent.stop="hideImagesGalleryModal"></i>
            <div class="flex-grow-1 flex-basis-0 text-center">
                <slot name="gallery-title">
                    {{ imageIndex + 1 }} su {{ images.length}}
                </slot>
            </div>
            <div class="flex-basis-0 pr-3">
                <slot name="dropdown-menu"></slot>
            </div>
        </div>
        <div class="left-column d-none d-lg-flex flex-column">
            <div class="card h-auto">
                <div class="card-body news">
                    <div class="d-flex justify-content-end">
                        <slot name="dropdown-menu" class="d-none d-lg-block"></slot>
                    </div>
                    <div class="d-flex align-items-center">
                        <div class="mr-3">
                            <slot name="resource-image"></slot>
                        </div>
                        <div>
                            <slot name="resource-name"></slot>
                            <div class="text-grey" v-if="getImagePublishDate(images[imageIndex])">{{ getImagePublishDate(images[imageIndex]) }}</div>
                        </div>
                    </div>
                    <div v-if="images[imageIndex] && images[imageIndex].id && images[imageIndex].post_type === 'group-post'" class="post mt-5">
                        <div class="d-flex w-100 justify-content-between pb-2">
                            <div class="mr-auto d-flex align-items-center text-grey" v-if="images[imageIndex].likes_count">
                                <img alt="like-circle" src="~/assets/images/icons/like-circle.svg" class="mr-1" width="18px" height="18px">
                                {{ images[imageIndex].likes_count }}
                            </div>
                            <div class="text-grey" v-if="images[imageIndex].root_comments_count">{{ $t('Commenti') }}: {{ images[imageIndex].root_comments_count }}</div>
                        </div>
                        <slot name="actions">
                            <div class="d-flex post-actions align-items-center justify-content-between">
                                <label class="col d-flex justify-content-center align-items-center text-grey" :class="{ 'text-brand': images[imageIndex].liked }" @click.prevent.stop="likePostImage(images[imageIndex])">
                                    <i class="cf-like mr-2 text-grey" :class="{ 'cf-like-f liked': images[imageIndex].liked }"></i> {{ $t('Mi piace') }}
                                </label>
                                <label :for="'newCommentModal'" class="col d-flex justify-content-center align-items-center text-grey" @click.prevent.stop="$refs.comments.show()">
                                    <i class="cf-comment mr-2 text-grey"></i> {{ $t('Commenta') }}
                                </label>
                                <label class="col d-flex justify-content-center align-items-center text-grey">
                                    <dropup-menu :hide-bottom-bar="true" :show-overlay="true">
                                        <template slot="menu-icon">
                                            <img alt="follow" class="mr-2" src="~/assets/images/icons/follow.svg" width="20px"> {{ $t('Segui') }}
                                        </template>
                                        <template slot="menu-options">
                                            <ul class="menu-options">
                                                <li @click.prevent.stop=""> {{ $t('Segui il post')}}</li>
                                                <li @click.prevent.stop=""> {{ $t('Segui questo utente')}}</li>
                                            </ul>
                                        </template>
                                    </dropup-menu>
                                </label>
                            </div>
                        </slot>
                        <div class="scrollable-content">
                            <comments class="comments mt-3 pb-3" :modal="true" :resource="images[imageIndex]" :comments="getCommentsFromState"></comments>
                        </div>
                    </div>
                    <!--<div class="mt-4" v-if="images[imageIndex].description">{{ images[imageIndex].description }}</div>-->
                </div>
            </div>
            <!--<div class="m-4">-->
                <!--<photo-set-card></photo-set-card>-->
            <!--</div>-->
        </div>
        <div class="gallery relative flex-column d-flex justify-content-center align-items-center image-background-contain">
            <!--:style="'background-image:' + ((images[imageIndex] && images[imageIndex][imagePrefix + 'image_res']) ? 'url(' + images[imageIndex][imagePrefix + 'image_res'].medium + ')' : imagePlaceholder)"-->
            <div :id="'slider' + this._uid" class="swipe align-self-start d-flex align-items-center" v-if="mobileMode">
                <div class="swipe-wrap h-100">
                    <div class="h-100" v-for="image in images">
                        <img class="pinch-zoom" style="width: 100%;" :src="image.path ? image.path : (image[imagePrefix + 'image_res'] ? image[imagePrefix + 'image_res'].medium : imagePlaceholder)">
                    </div>
                </div>
            </div>
            <div class="controls h-100 justify-content-between d-none d-lg-flex">
                <div class="previous-image d-flex align-items-center p-5" @click.prevent.stop="previousImage" v-if="images.length > 1">
                    <img alt="arrow-sx" src="~/assets/images/arrow-sx.svg">
                </div>
                <img style="width: 100%; height: 100%; object-fit: contain" :src="getImageSrc()">
                <div class="next-image d-flex justify-content-end align-items-center p-5" @click.prevent.stop="nextImage" v-if="images.length > 1">
                    <img alt="arrow-dx" src="~/assets/images/arrow-dx.svg">
                </div>
            </div>
            <div v-if="pz && pz.zoomFactor === 1" class="gallery-footer p-3 d-block d-lg-none" :style="'padding-bottom: ' + (insets.bottom) + 'px!important'">
                <slot name="gallery-footer">
                    <div class="d-flex pb-3 pt-3">
                        <div>
                            <slot name="resource-image"></slot>
                        </div>
                        <div>
                            <slot name="resource-data">
                                <slot name="resource-name"></slot>
                                <div class="subtitle text-grey font-size-xs text-uppercase" v-if="getImagePublishDate(images[imageIndex])">{{ getImagePublishDate(images[imageIndex]) }}</div>
                            </slot>
                        </div>
                    </div>
                    <div class="d-flex w-100 justify-content-between pb-1">
                        <div class="mr-auto d-flex align-items-center text-light-grey" v-if="images[imageIndex].likes_count">
                            <img alt="like-circle" src="~/assets/images/icons/like-circle.svg" class="mr-1" width="18px" height="18px">
                            {{ images[imageIndex].likes_count }}
                        </div>
                        <div class="text-light-grey" v-if="images[imageIndex].root_comments_count">{{ $t('Commenti') }}: {{ images[imageIndex].root_comments_count }}</div>
                    </div>
                    <div>
                        <slot name="actions">
                            <div class="d-flex post-actions align-items-center justify-content-between" style="border-bottom: none" >
                                <label class="col d-flex justify-content-center align-items-center text-white" @click.prevent.stop="likePostImage(images[imageIndex])">
                                    <i class="cf-like mr-2 text-white" :class="{ 'cf-like-f liked': images[imageIndex].liked }"></i> {{ $t('Mi piace') }}
                                </label>
                                <label :for="'newCommentModal'" class="col d-flex justify-content-center align-items-center text-white" @click.prevent.stop="$refs.comments.show()">
                                    <i class="cf-comment mr-2 text-white"></i> {{ $t('Commenta') }}
                                </label>
                                <label class="col d-flex justify-content-center align-items-center text-white" v-if="post">
                                    <dropup-menu :hide-bottom-bar="true" :show-overlay="true">
                                        <template slot="menu-icon">
                                            <img alt="follow-f" class="mr-2" src="~/assets/images/icons/follow-f.svg" width="20px" v-if="post.followed">
                                            <img alt="follow" class="mr-2" src="~/assets/images/icons/follow.svg" width="20px" v-else>
                                            <span v-if="post.followed" class="liked text-dark-grey"> {{ $t('Segui già') }}</span><span v-else> {{ $t('Segui') }}</span>                                        </template>
                                        <template slot="menu-options">
                                            <ul class="menu-options">
                                                <li @click.prevent="followPost(post.id)" v-if="post.followed === false"> {{ $t('Segui questo post')}}</li>
                                                <li @click.prevent="unfollowPost(post.id)" v-if="post.followed === true"> {{ $t('Smetti di seguire il post')}}</li>
                                                <li @click.prevent="followUser(post.user_id)" v-if="getUserById(post.user_id) && getUserById(post.user_id).followed === false"> {{ $t('Segui questo utente')}}</li>
                                                <li @click.prevent="unfollowUser(post.user_id)" v-if="getUserById(post.user_id) && getUserById(post.user_id).followed === true"> {{ $t('Smetti di seguire l\'utente')}}</li>
                                            </ul>
                                        </template>
                                    </dropup-menu>
                                </label>
                            </div>
                        </slot>
                    </div>
                </slot>
            </div>
        </div>
        <slot name="dropup-menu" class="d-block d-lg-none"></slot>
        <!--</div>-->
        <!--</div>-->
        <comments-modal ref="comments" :resource="images[imageIndex]" resource-type="post-images"></comments-modal>
    </b-modal>
</template>

<script>

    import { mapState, mapActions, mapGetters } from 'vuex'
    import PhotoSetCard from "~/components/PhotoSetCard";
    import CommentsModal from "~/components/Group/CommentsModal";
    import Comments from "~/components/Group/Wall/Posts/Comments";

    import Swipe from 'swipejs'
    import PinchZoom from 'pinch-zoom-js'

    export default {

        name: 'images-gallery',

        components: {
            PhotoSetCard,
            CommentsModal,
            Comments
        },

        props: {
            'url': {
                type: String,
                default: function () {
                    return ''
                }
            },
            'imagePrefix': {
                type: String,
                default: function () {
                    return ''
                }
            },
            'images': {
                type: Array,
                default: function () {
                    return []
                }
            },
            'post': {
                type: Object,
                default: function () {
                    return null
                }
            }
        },

        data() {

            return {
                imagePlaceholder: '~/assets/images/icons/diploma-grey.svg',
                imageIndex: 0,
                slider: null,
                pz: null,
            }
        },

        computed: {
            ...mapState([
                'orientation',
                'insets',
                'mobileMode'
            ]),
            ...mapGetters({
                'getCommentsByPostImage': 'comments/GET_COMMENTS_BY_POST_IMAGE',
                'getUserById': 'users/GET_USER_BY_ID'
            }),
            getCommentsFromState() {
                return this.getCommentsByPostImage(this.images[this.imageIndex]);
            },
        },

        methods: {
            ...mapActions({
                'refetch': 'post-images/fetchById',
                'like': 'post-images/likeById',
                'followUserById' : 'users/followById',
                'unfollowUserById' : 'users/unfollowById',
                'followPostById' : 'posts/followById',
            }),
            likePostImage(image) {
                this.like(image.id)
                    .then((r) => {
                        this.refetch(image);
                    })
            },

            saveComment () {

                if (this.comment.text.length === 0) return;

                this.$nuxt.$loading.start()

                this.createComment(this.comment)
                    .then((comment) => {
                        this.showingOverlay = false;
                        this.comment.text = '';

                        setTimeout(function () {
                            autosize.update(document.querySelectorAll('textarea'))
                        }, 20)

                        this.refetchPost(this.resource.id)

                        this.$nuxt.$loading.finish()

                    }).catch(() => {
                        this.$nuxt.$loading.finish()
                    });
            },
            followUser(userId) {
                this.followUserById(userId).then((user) => {

                    // this.$refs.followDropup.toggleShowDropUp()

                    this.$notify({
                        group: 'messages',
                        type: 'success',
                        title: this.$t('Ora segui ' + user.name)
                    });
                }).catch(error => {

                    // this.$refs.followDropup.toggleShowDropUp()

                    this.$notify({
                        group: 'messages',
                        type: 'error',
                        title: this.$t('Si è verificato un errore')
                    });
                })
            },
            unfollowUser(userId) {
                this.unfollowUserById(userId).then((user) => {

                    // this.$refs.followDropup.toggleShowDropUp()

                    this.$notify({
                        group: 'messages',
                        type: 'success',
                        title: this.$t('Non segui più ' + user.name)
                    });
                }).catch(error => {

                    // this.$refs.followDropup.toggleShowDropUp()

                    this.$notify({
                        group: 'messages',
                        type: 'error',
                        title: this.$t('Si è verificato un errore')
                    });
                })
            },
            followPost(postId) {
                this.followPostById(postId).then(() => {

                    this.$notify({
                        group: 'messages',
                        type: 'success',
                        title: this.$t('Ora segui questo post')
                    });
                })
            },
            unfollowPost(postId) {
                this.followPostById(postId).then(() => {

                    this.$notify({
                        group: 'messages',
                        type: 'success',
                        title: this.$t('Non segui più questo post')
                    });
                })
            },
            init() {

                if (this.mobileMode) {
                    let areas = document.querySelectorAll('#gallery-modal-' + this._uid + ' .pinch-zoom')

                    if (areas.length && !this.pz) {
                        setTimeout(() =>  {

                            _.each(areas, (area) => {
                                this.pz = new PinchZoom(area, {
                                    minZoom: 0.5,
                                    maxZoom: 10,
                                    draggableUnzoomed: false,
                                    onZoomEnd: function (pz, event) {
                                        if (pz.zoomFactor < 1) {
                                            area.click();
                                        }
                                    }
                                });
                            })
                        })
                    }


                    if (!this.slider) {
                        this.slider = new Swipe(document.getElementById('slider' + this._uid), {
                            startSlide: this.imageIndex,
                            speed: 400,
                            draggable: false,
                            continuous: true,
                            disableScroll: true,
                            stopPropagation: true,
                            callback: (index, elem, dir) => {
                                this.imageIndex = index
                            }
                        })
                    }
                }
            },
            destroy() {

            },
            hideImagesGalleryModal () {
                this.$refs.imagesGalleryModal.hide()
            },
            showImagesGalleryModal (index) {

                this.imageIndex = index;
                this.$refs.imagesGalleryModal.show();
            },
            getImageSrc() {

                if (this.images[this.imageIndex]) {
                    if (this.images[this.imageIndex].path)
                        return this.images[this.imageIndex].path
                    else if (this.images[this.imageIndex][this.imagePrefix + 'image_res']){
                        return this.images[this.imageIndex][this.imagePrefix + 'image_res'].medium
                    }
                }

                return this.imagePlaceholder;
            },
            previousImage () {
                if (this.pz) {
                    this.pz.zoomOutAnimation();
                }
                this.imageIndex = (this.imageIndex > 0 ? this.imageIndex : this.images.length) - 1;
            },
            nextImage () {
                if (this.pz) {
                    this.pz.zoomOutAnimation();
                }
                this.imageIndex = (this.imageIndex < this.images.length - 1 ? this.imageIndex + 1 : 0);
            },
            getImagePublishDate (image) {
                if (!image) return;
                return this.$moment(image.created_at).format('DD MMMM YYYY')
            },
            deleteImage() {

                let image = this.images[this.imageIndex];

                this.imageIndex = this.imageIndex != (this.images.length - 1) ? this.imageIndex : 0;

                this.$nuxt.$loading.start();

                this.$axios.delete(this.url + '/' + image.id)
                    .then((response) => {

                        this.$parent.refetch().then((post) => {

                            this.$nuxt.$loading.finish();

                            if (post.images.length < 1) {
                                this.hideImagesModal()
                            }
                        })
                    }).catch(() => {
                        this.$nuxt.$loading.finish();
                });
            }
        },

        mounted () {
        }
    }
</script>

<style lang="scss">

    @media only screen and (max-width : 991px) {

        .card.parent {
            position: relative!important;
        }
    }

    .swipe {
        overflow: hidden;
        /*visibility: hidden;*/
        position: relative;
        min-height: 100%;
    }
    .swipe-wrap {
        overflow: hidden;
        position: relative;
    }
    .swipe-wrap > div {
        float: left;
        width: 100%;
        position: relative;
        overflow: hidden;
    }

    .images-gallery-modal {
        .close {
            right: 0;
            margin: 16px;
            color: #BABABA;
            opacity: 1;
            z-index: 2;
        }

        .left-column {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            min-width: 330px;
            max-width: 330px;
            background-color: #F9F8F9;
            line-height: 18px;

            .title {
                font-weight: bold;
                font-size: 17px;
            }
            .subtitle {
                font-size: 14px;
            }
        }

        .dropdown .btn-link {
            font-size: 5px!important;
        }
        .gallery {
            width: 100%;
            background-color: black;

            &.image-background-contain {
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
            }

            .image {
                max-height: 100%;
                max-width: 100%;
            }

            .next-image, .previous-image {
                position: absolute;
                max-width: 20%;
                height: 100%;
                top: 0;
                z-index: 1;
                > img {
                    width: 100%;
                }
            }
            .next-image {
                right: 0
            }
            .previous-image {
                left: 0
            }
        }

        .card:not(.parent) {
            width: 100%;
            position: relative;
            .card-body {
                padding-bottom: 60px;
                overflow: auto;
            }
            .card-footer {
                background-color: white;
                position: absolute;
                width: 100%;
                bottom: 0;
            }
        }

        .modal-dialog {
            max-width: 1250px;
        }

        .modal-body {
            padding: 0;
            display: flex;
            min-height: 850px;
            max-height: 850px;

            .image-preview {
                width: 205px;
                height: 260px;
                border-radius: 4px;

                &.bordered {
                    border: 1px solid #F9F8F9;
                }

                .remove-image {
                    position: absolute;
                    top: 5px;
                    right: 5px;
                }

                > i.cf-add {
                    font-size: 40px;
                }

                .title {
                    font-size: 18px;
                }
            }
        }

        .modal-header,
        .modal-footer {
            display: none !important;
        }
        .pinch-zoom-container {
            min-width: 100vw;
            max-width: 100vw;
            min-height: 100vh;
            min-height: calc(var(--vh, 1vh) * 100);

        }

        .landscape {
            max-width: 100vw;
            width: 100vw;
            max-height: 100vh;
            max-height: calc(var(--vh, 1vh) * 100);
            height: 100vh;
            height: calc(var(--vh, 1vh) * 100);
            overflow: hidden;
            .card {
                right: 0;
                padding: 0;
                transform:  translateX(calc(50vh - 50vw)) translateY(calc(50vh - 50vw));
                transform:  translateX(calc((var(--vh) * 50) - 50vw)) translateY(calc((var(--vh) * 50) - 50vw));
                position: absolute;
                max-height: 100vw;
                height: 100vw;

                .card-body {
                    transform: rotate(-90deg);
                    max-width: 100vh;
                    width: 100vh;
                    width: calc(var(--vh, 1vh) * 100);
                }
            }

            &.right .card {
                .card-body {
                    transform: rotate(90deg);
                }
            }
        }
    }
</style>
