import Vue from 'vue'
import { getField, updateField } from 'vuex-map-fields';
import _ from 'lodash'
import moment from "moment-timezone";

export const state = () => ({
    filters: {
        text: null,
    },
    sort: {
        column: 'created_at',
        order: 'desc'
    },
    pagination: {
        page: 0,
        per_page: 5,
        last_page: 0,
    },
    groupBuys: [],
    totalCount: 0,
    infiniteScrollEnabled: true,
    processing: false
})

export const getters = {
    getField,
    getGroupBuyBySlug: (state) => (slug) => {
        let groupBuys = state.groupBuys.filter((groupBuy) => {
            return groupBuy.slug === slug
        })

        return groupBuys.length ? groupBuys[0] : null;
    },
    getFilteredGroupBuys: (state) => (filters) => {

        let sort = state.sort

        if(filters.hasOwnProperty('sort')) {
            sort = filters.sort;
        }

        let filteredGroupBuys = _.filter(state.groupBuys, function (groupBuy) {

            let passes = true;

            if(filters.hasOwnProperty('group_buy_category_id') && filters.group_buy_category_id) {
                passes = passes && groupBuy.group_buy_category_id === filters.group_buy_category_id
            }

            if(filters.hasOwnProperty('text') && filters.text && filters.text.length) {

                passes = passes && (
                    (groupBuy.name ? groupBuy.name.match(new RegExp(filters.text, 'i')) : false) ||
                    (groupBuy.description ? groupBuy.description.match(new RegExp(filters.text, 'i')) : false)
                )
            }

            return passes
        })

        return _.orderBy(filteredGroupBuys, sort.column ? sort.column : 'created_at', sort.order ? sort.order : 'desc')
    }
}

export const mutations = {
    updateField,
    SET_FILTERS (state, filters) {
        state.filters = filters
    },
    SET_PROCESSING (state, processing) {
        state.processing = processing
    },
    SET_GROUP_BUYS (state, groupBuys) {
        state.groupBuys = groupBuys
    },
    APPEND_GROUP_BUY (state, groupBuy) {

        let index = state.groupBuys.findIndex(myGroupBuy => {
            return myGroupBuy.id === groupBuy.id
        })

        if(index < 0 || !groupBuy.id) {

            state.groupBuys.push(groupBuy);
        } else {

            groupBuy.from_notifications = groupBuy.hasOwnProperty('from_notifications') ? groupBuy.from_notifications : state.groupBuys[index].from_notifications;
            groupBuy.from_dashboard = groupBuy.hasOwnProperty('from_dashboard') ? groupBuy.from_dashboard : state.groupBuys[index].from_dashboard;
            groupBuy.new = groupBuy.hasOwnProperty('new') ? groupBuy.new : state.groupBuys[index].new;

            groupBuy.children = groupBuy.hasOwnProperty('children') ? groupBuy.children : state.groupBuys[index].children;
            groupBuy.menu = groupBuy.hasOwnProperty('menu') ? groupBuy.menu : state.groupBuys[index].menu;

            Vue.set(state.groupBuys, index, groupBuy)
        }
    },
    ASSOCIATE_ORDER (state, {groupBuyId, order}) {
        let myGroupBuy = _.find(state.groupBuys, {id: groupBuyId});

        if(myGroupBuy) {
            Vue.set(myGroupBuy, 'order', order);
        }
    },
    LIKE_GROUP_BUY_BY_ID (state, groupBuyId) {

        let myGroupBuy = _.find(state.groupBuys, {id: groupBuyId});

        if(myGroupBuy) {
            Vue.set(myGroupBuy, 'liked', !myGroupBuy.liked)
            Vue.set(myGroupBuy, 'likes_count', myGroupBuy.liked ? myGroupBuy.likes_count + 1 : myGroupBuy.likes_count - 1)
        }
    },
    UPDATE_GROUP_BUY_BY_NAME (state, groupBuy) {

        let myGroupBuyIndex = _.findIndex(state.groupBuys, {name: groupBuy.name});

        if(myGroupBuyIndex > -1) {
            groupBuy.from_notifications = state.groupBuys[myGroupBuyIndex].from_notifications;
            groupBuy.new = state.groupBuys[myGroupBuyIndex].new;

            Vue.set(state.groupBuys, myGroupBuyIndex, groupBuy)
        }
    },
    INCREMENT_LIKES_COUNT (state, groupBuy) {
        let myGroupBuy = _.find(state.groupBuys, {id: groupBuy.id});

        if(myGroupBuy) {
            Vue.set(myGroupBuy, 'likes_count', myGroupBuy.likes_count + 1);
        }

    },
    REMOVE_GROUP_BUY (state, groupBuy) {
        let groupBuyIndex = _.findIndex(state.groupBuys, {id: groupBuy.id});

        if (groupBuyIndex > -1) {
            state.groupBuys.splice(groupBuyIndex, 1);
        }
    },
    APPEND_GROUP_BUYS (state, groupBuys) {

        _.each(groupBuys, (groupBuy) => {
            state.groupBuys.push(groupBuy);
        });
    },
    SET_INFINITE_SCROLL_ENABLED (state, infiniteScrollEnabled) {
        state.infiniteScrollEnabled = infiniteScrollEnabled
    },
    SET_TOTAL_COUNT (state, totalCount) {
        state.totalCount = totalCount
    },
    SET_LAST_PAGE (state, lastPage) {
        state.pagination.last_page = lastPage
    }
}

export const actions = {

    fetchById ({ dispatch, commit, state}, data) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .get('/api/group-buys/' + data.id, {
                params: {
                    notifications: data.notifications
                }
            })
            .then(r => r.data)
            .then(groupBuy => {
                commit('APPEND_GROUP_BUY', groupBuy)
                if(groupBuy.user) {
                    commit('users/APPEND_USER', groupBuy.user, {root: true})
                }

                if(groupBuy.comments && groupBuy.comments.length) {
                    dispatch('group-buy-comments/appendComments', groupBuy.comments, {root: true});
                }
                //
                // if(groupBuy.images && groupBuy.images.length) {
                //     dispatch('groupBuy-images/appendGroupBuyImages', groupBuy.images, {root: true});
                // }

                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                // throw error;
                console.log('fetch by id error: ' + error)

            })
    },

    fetchBySlug ({ dispatch, commit, state}, slug) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .get('/api/group-buys/' + slug)
            .then(r => r.data)
            .then(groupBuy => {
                commit('APPEND_GROUP_BUY', groupBuy)
                if(groupBuy.user) {
                    commit('users/APPEND_USER', groupBuy.user, {root: true})
                }

                if(groupBuy.comments && groupBuy.comments.length) {
                    dispatch('group-buy-comments/appendComments', groupBuy.comments, {root: true});
                }

                // if(groupBuy.images && groupBuy.images.length) {
                //     dispatch('groupBuy-images/appendGroupBuyImages', groupBuy.images, {root: true});
                // }

                commit('SET_PROCESSING', false)

                return groupBuy
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },

    fetch ({ commit, state, dispatch }, data) {

        commit('SET_PROCESSING', true);

        let filters = data.filters ?  data.filters : state.filters;
        let sort = data.sort ?  data.sort : state.filters;

        return this.$axios
            .get('/api/group-buys', {
                params: {
                    ...sort,
                    ...filters,
                    page: data && data.page ? data.page : state.pagination.page,
                    per_page: data && data.per_page ? data.per_page : state.pagination.per_page
                }
            })
            .then(r => r.data)
            .then(response => {

                if(state.groupBuys.length) {

                    _.each(response.data, (groupBuy) => {

                        commit('APPEND_GROUP_BUY', groupBuy);
                        dispatch('group-buy-comments/appendComments', groupBuy.comments, { root: true });
                    });
                } else {
                    commit('SET_GROUP_BUYS', response.data);
                }

                commit('SET_TOTAL_COUNT', response.total);
                commit('SET_LAST_PAGE', response.last_page);
                commit('SET_INFINITE_SCROLL_ENABLED', response.data.length >= ( data && data.per_page ? data.per_page : state.pagination.per_page));
                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
                // console.log('fetch error: ' + error)
            })
    },

    likeGroupBuyById ({ commit, state}, groupBuyId) {

        commit('SET_PROCESSING', true)
        commit('LIKE_GROUP_BUY_BY_ID', groupBuyId);

        return this.$axios
            .post('/api/group-buys/' + groupBuyId + '/like')
            .then(r => r.data)
            .then(groupBuy => {
                commit('SET_PROCESSING', false)
                commit('APPEND_GROUP_BUY', groupBuy);
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },

    delete ({ commit, state}, groupBuy) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .delete('/api/group-buys/' + groupBuy.id)
            .then(r => r.data)
            .then(res => {
                commit('REMOVE_GROUP_BUY', groupBuy)
                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },

    appendGroupBuys ({commit, dispatch}, groupBuys) {

        if(!groupBuys || !_.size(groupBuys)) return;

        _.each(groupBuys, (groupBuy) => {

            commit('APPEND_GROUP_BUY', groupBuy);

            if(groupBuy.user) {
                commit('users/APPEND_USER', groupBuy.user, {root: true})
            }

            if(groupBuy.comments && groupBuy.comments.length) {
                dispatch('group-buy-comments/appendComments', groupBuy.comments, {root: true});
            }

            // if(groupBuy.images && groupBuy.images.length) {
            //     dispatch('groupBuy-images/appendGroupBuyImages', groupBuy.images, {root: true});
            // }
        });
    },
}