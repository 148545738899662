<template>
  <div>
    <client-only>
      <notifications class="notification-messages" group="messages" position="top center">
        <template slot="body" slot-scope="props">
          <div class="notification-message" :class="props.item.type">
            <div class="notification-title" v-if="props.item.title">{{ props.item.title }}</div>
            <div class="notification-text" v-if="props.item.text">{{ props.item.text }}</div>
          </div>
        </template>
      </notifications>

      <notifications class="validation-messages" group="validation-messages" position="top center" :style="'top: ' + ((insets ? insets.top : 0) + 20) + 'px'">
        <template slot="body" slot-scope="props">
          <div class="validation-message" :class="props.item.type">
            {{ props.item.text }}
          </div>
        </template>
      </notifications>

      <notifications group="communications" :position="mobileMode ? 'top center' : 'bottom left'">
        <template slot="body" slot-scope="props">
          <notification-card :notification="props.item"></notification-card>
        </template>
      </notifications>
    </client-only>
    <nuxt keep-alive/>
    <fast-login-modal></fast-login-modal>
    <update-app-modal></update-app-modal>
  </div>
</template>

<script>
    import { mapState } from 'vuex'
    import HeaderBar from '~/components/HeaderBar'
    import BottomBar from '~/components/BottomBar'
    import FastLoginModal from '~/components/FastLoginModal';
    import UpdateAppModal from '~/components/UpdateAppModal';
    import NotificationCard from '~/components/NotificationCard';

    export default {

        components: {
            HeaderBar,
            BottomBar,
            FastLoginModal,
            UpdateAppModal,
            NotificationCard
        },

        watch: {
            'auth.loggedIn': function(val) {
                if(val) {

                    this.$store.dispatch('authenticateEchoChannels', this);
                }
            }
        },

        computed: {
            ...mapState([
                'auth',
                'insets',
                'mobileMode'
            ])
        },

        mounted() {

        }
    }
</script>

<style>
html {
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: border-box;
  margin: 0;
}

.button--green {
  display: inline-block;
  border-radius: 4px;
  border: 1px solid #3b8070;
  color: #3b8070;
  text-decoration: none;
  padding: 10px 30px;
}

.button--green:hover {
  color: #fff;
  background-color: #3b8070;
}

.button--grey {
  display: inline-block;
  border-radius: 4px;
  border: 1px solid #35495e;
  color: #35495e;
  text-decoration: none;
  padding: 10px 30px;
  margin-left: 15px;
}

.button--grey:hover {
  color: #fff;
  background-color: #35495e;
}
</style>

