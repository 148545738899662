<template>
    <div class="card card-modal h-100 border-0 rounded-0 text-center relative">
        <div class="card-header card-header-sm d-flex justify-content-center align-items-center">
            <i class="cf-back absolute p-3" style="left: 0;" @click.prevent="previousStep()"></i>
            <span v-if="type === 'school'">{{ $t('Aggiungi una scuola di estetica') }}</span>
            <span v-else>
                <span v-if="accountType === 'beauty-centre'">{{ $t('Collegati ad un centro estetico') }}</span>
                <span v-else>{{ $t('Collegati ad un luogo di lavoro') }}</span>
            </span>
        </div>
        <div class="card-body p-0 pb-2">
            <div @click.prevent="openAccountModal()" class="border-bottom">
                <div class="d-flex align-items-center p-2 pl-3" v-if="workExperience.workExperience.account.id">
                    <account-image :account="workExperience.workExperience.account" size="50"></account-image>
                    <div class="ml-2 text-left overflow-hidden">
                        <strong class="no-wrap ellipsis">{{ workExperience.workExperience.account.name }}</strong>
                        <div class="text-grey no-wrap ellipsis">{{ workExperience.workExperience.account.address }}</div>
                    </div>
                </div>
                <div v-else class="d-flex align-items-center p-2 pl-3">
                    <div>
                        <account-image class="mr-2 rounded-circle overflow-hidden" :account="workExperience.workExperience.account" size="50"></account-image>
                    </div>
                    <span class="text-light-grey no-wrap ellipsis font-size-xl" v-if="type !== 'school'">{{ $t('Nome del luogo di lavoro')}}</span>
                    <span class="text-light-grey no-wrap ellipsis font-size-xl" v-else>{{ $t('Nome della tua scuola di estetica')}}</span>
                </div>
            </div>
            <div v-if="type !== 'school'">
                <div class="pl-3 pr-3 d-flex justify-content-between align-items-center mt-2 border-bottom">
                    <strong>{{ $t('Titolare')}}</strong>
                    <span class="switch switch-sm">
                        <input type="checkbox" id="type-switch" v-model="owner">
                        <label for="type-switch"></label>
                    </span>
                </div>
                <!--<div class="pl-3 pr-3 d-flex justify-content-between align-items-center mt-2 border-bottom" v-if="workExperience.workExperience.owner">-->
                    <!--<strong>{{ $t('Direttore tecnico')}}</strong>-->
                    <!--<span class="switch switch-sm">-->
                        <!--<input type="checkbox" id="director-switch" v-model="director">-->
                        <!--<label for="director-switch"></label>-->
                    <!--</span>-->
                <!--</div>-->
                <div class="font-size-lg text-left p-3 border-bottom" @click.prevent="showingPositionPicker = true" v-if="!workExperience.workExperience.owner">
                    <span v-if="workExperience.workExperience.position"> {{ workExperience.workExperience.position }}</span>
                    <span class="text-grey" v-else>{{ $t('Ruolo') }}</span>
                </div>
            </div>
            <div class="font-size-lg text-left p-3 border-bottom" @click.prevent="showingSpecialisationsPicker = true" v-if="type !== 'school'">
                <span v-if="role_specialisations.length">
                    <span v-for="selectedOption in selectedOptions">
                        {{ selectedOption.name }}<span v-if="lastSelectedOption.id !== selectedOption.id"><br></span>
                    </span>
                </span>
                <span v-else class="text-grey">{{ $t('Mansione prevalente (max 3)') }}</span>
            </div>
            <div class="pl-3 pr-3 d-flex justify-content-between align-items-center mt-2 border-bottom">
                <strong>
                    <span v-if="type !== 'school'">{{ $t('Attualmente lavoro qui')}}</span>
                    <span v-else>{{ $t('Attualmente studio qui')}}</span>
                </strong>
                <span class="switch switch-sm">
                    <input type="checkbox" id="switch-sm" v-model="current">
                    <label for="switch-sm"></label>
                </span>
            </div>
            <div class="text-left">
                <label for="date_from" class="text-grey mt-2 pl-3">{{ $t('Dal')}}</label>
                <date-picker id="date_from" class="pl-3 pr-3" :day.sync="from_day" :month.sync="from_month" :year.sync="from_year">
                    <template slot="placeholder">
                        <span class="text-light-grey font-size-xl">
                            {{ $t('Data di inizio') }}
                        </span>
                    </template>
                </date-picker>
                <div v-if="!current" class="mt-2">
                    <hr>
                    <label for="date_to" class="text-grey pl-3 mt-2">{{ $t('Al')}}</label>
                    <date-picker id="date_to" class="pl-3 pr-3" :day.sync="to_day" :month.sync="to_month" :year.sync="to_year">
                        <template slot="placeholder">
                            <span class="text-light-grey font-size-xl">
                                {{ $t('Data di fine') }}
                            </span>
                        </template>
                    </date-picker>
                </div>
            </div>
        </div>
        <account-modal ref="accountModal" :type="accountType ? accountType : type" :confirm-callback="setAccount"></account-modal>
        <select-picker  v-if="showingPositionPicker && type !== 'school'"
                        class="not-bordered"
                        :options="positions"
                        :cancel-callback="closePositionPicker"
                        :save-callback="closePositionPicker"
                        v-model="position"
        >
            <template slot="picker-title">
                {{ $t('Ruolo') }}
            </template>
            <template slot="options-title">
                {{ $t('Ruolo') }}
            </template>
        </select-picker>
        <select-picker  v-if="showingSpecialisationsPicker && type !== 'school'"
                        class="not-bordered"
                        :options="specialisations.specialisations"
                        :cancel-callback="closeSpecialisationsPicker"
                        :save-callback="closeSpecialisationsPicker"
                        multiple
                        :max-values-number="3"
                        v-model="role_specialisations"
        >
            <template slot="picker-title">
                {{ $t('Mansione') }}
            </template>
            <template slot="options-title">
                {{ $t('Mansione prevalente (max 3)') }}
            </template>
        </select-picker>
        <div class="card-footer">
            <button class="btn btn-brand w-100" @click.prevent="nextStep()">
                <span v-if="updateProfile">{{ $t('Avanti') }}</span>
                <span v-else>{{ $t('Salva')}}</span>
            </button>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions, mapMutations } from 'vuex'
    import { mapFields } from 'vuex-map-fields'
    import SelectPicker from "~/components/SelectPicker";
    import DatePicker from "~/components/DatePicker";
    import AccountModal from "~/components/AccountModal";
    import _ from 'lodash';

    export default {

        components: {
            SelectPicker,
            DatePicker,
            AccountModal
        },

        props: ['type', 'account-type', 'modal'],

        data () {

            return {
                role_specialisations: _.clone(this.$store.state['work-experience'].workExperience.role_specialisations) || [],
                positions: [
                    'Direttore tecnico dipendente',
                    'Estetista professionista dipendente',
                    'Estetista Apprendista',
                    'Estetista Stagista'
                ],
                showingPositionPicker: false,
                showingSpecialisationsPicker: false,
            }
        },

        watch: {
            current(val) {
                if (val) {
                    this.date_to = null;
                }
            },
            'workExperience.workExperience.role_specialisations'() {
                this.role_specialisations = _.clone(this.workExperience.workExperience.role_specialisations) || []
            }
        },

        computed: {
            ...mapState({
                'workExperience': 'work-experience',
                'specialisations': 'specialisations',
                'mobileMode': state => state.mobileMode
            }),
            ...mapFields('work-experience', [
                'workExperience.position',
                'workExperience.from_day',
                'workExperience.from_month',
                'workExperience.from_year',
                'workExperience.to_day',
                'workExperience.to_month',
                'workExperience.to_year',
                'workExperience.current',
                'workExperience.owner'
            ]),
            lastSelectedOption() {
                return _.last(this.selectedOptions);
            },
            selectedOptions() {

                let options = [];

                _.each(this.role_specialisations, (value) => {
                    let option = _.find(this.specialisations.specialisations, { id: value })
                    if (option) {
                        options.push(option)
                    }
                })

                return options;
            },
            updateProfile() {
                return this.$route.path === '/aggiorna-profilo'
            }
        },

        methods: {
            ...mapActions({
                'refetchUser': 'user/refetch',
                'updateWorkExperience': 'work-experience/update'
            }),
            ...mapMutations({
                'setWorkExperience': 'work-experience/SET_WORK_EXPERIENCE',
                'setWorkExperienceCurrent': 'work-experience/SET_WORK_EXPERIENCE_CURRENT',
                'setWorkExperienceAccount': 'work-experience/SET_WORK_EXPERIENCE_ACCOUNT',
                'setWorkExperienceAccountId': 'work-experience/SET_WORK_EXPERIENCE_ACCOUNT_ID',
                'setWorkExperienceType': 'work-experience/SET_WORK_EXPERIENCE_TYPE',
                'setWorkExperienceOwner': 'work-experience/SET_WORK_EXPERIENCE_OWNER',
                'setRoleSpecialisations': 'work-experience/SET_ROLE_SPECIALISATIONS',
                'setWorkExperienceStep': 'work-experience/SET_STEP'
            }),
            closePositionPicker() {
                this.showingPositionPicker = false;
            },
            closeSpecialisationsPicker() {
                this.showingSpecialisationsPicker = false;
            },
            previousStep() {
                if (this.modal) {
                    this.$nuxt.$emit('close-experience-modal')
                } else {
                    this.smartGoBack();
                }
            },
            setAccount(account) {
                this.setWorkExperienceAccount(account)
                this.setWorkExperienceAccountId(account.id)
            },
            openAccountModal() {
                this.$refs.accountModal.showModal()
            },
            nextStep() {

                this.setRoleSpecialisations(this.role_specialisations)

                let action = this.workExperience.workExperience.id ? 'update' : 'create'

                this.$nuxt.$loading.start();

                this.$store.dispatch('work-experience/' + action, this.workExperience.workExperience).then(() => {

                    this.refetchUser().then(() => {
                        this.$nuxt.$loading.finish();

                        if (this.updateProfile) {
                            this.setWorkExperienceStep('account-connected')
                        } else {
                            this.$nuxt.$emit('close-experience-modal')
                        }
                    }).catch(() => {
                        this.$nuxt.$loading.finish();
                    });

                }).catch((error) => {

                    this.$nuxt.$loading.finish();
                })
            }
        },

        mounted () {

        }
    }
</script>

<style lang="scss" scoped>

    .v-select,
    input, select {
        background-color: white!important;
        border: none;
        border-bottom: 1px solid #DBDBDB;
        &::placeholder {
            color: #DBDBDB;
        }
    }

    hr {
        margin: 0!important;
    }
</style>