<template>
    <b-modal id="post-modal" modal-class="slide-left" size="lg" body-class="p-0 news" @shown="init" @hidden="hide" no-fade hide-header hide-footer ref="postModal" lazy static>
        <div class="card post w-100"
             :style="'height:100vh!important;height: calc(var(--vh, 1vh) * 100)!important;padding-top:' + insets.top + 'px!important; padding-bottom:' + insets.bottom + 'px'"
        >
            <div class="card-header card-header-sm d-flex justify-content-center align-items-center">
                <i class="position-absolute cf-back p-3" style="left: 0" @click.prevent.stop="$refs.postModal.hide()"></i>
                <span class="logo font-weight-bold font-size-lg text-brand">{{ $t('Gruppo di acquisto Confestetica')}}</span>
                <dropup-menu ref="dropupMenu" :bottom="50" :hide-bottom-bar="true" :show-overlay="true">
                    <template slot="menu-icon">
                        <img alt="share" class="position-absolute" src="~/assets/images/icons/share.svg" width="20px" style="right: 16px">
                    </template>
                    <template slot="menu-options">
                        <social-sharing :url="groupBuyUrl"
                                        :title="groupBuy.name ? groupBuy.name : ''"
                                        :description="groupBuy.description ? groupBuy.description :''"
                                        :networks="shareNetworks"
                                        network-tag="li"
                                        inline-template
                        >
                            <ul class="menu-options">
                                <li v-clipboard:copy="groupBuy" v-clipboard:success="showAlert">
                                    {{ $t('Copia link') }}
                                </li>
                                <network network="email" class="show-icon">
                                    <i class="fa fa-envelope"></i> Email
                                </network>
                                <network network="facebook" class="show-icon">
                                    <i class="fa fa-facebook"></i> Facebook
                                </network>
                                <network network="linkedin" class="show-icon">
                                    <i class="fa fa-linkedin"></i> LinkedIn
                                </network>
                                <network network="twitter" class="show-icon">
                                    <i class="fa fa-twitter"></i> Twitter
                                </network>
                                <network network="whatsapp" class="show-icon">
                                    <i class="fa fa-whatsapp"></i> Whatsapp
                                </network>
                            </ul>
                        </social-sharing>
                    </template>
                </dropup-menu>

            </div>
            <div class="card-body scrollable-content p-0">

                <group-buy :level="1" :group-buy="groupBuy" :modal="true" :detail="true"></group-buy>

            </div>
            <div v-if="comment.user" class="card-footer d-lg-none d-flex justify-content-between text-grey font-size-sm align-items-center p-2">
                <span>{{ $t('Risposta a ') }} {{ comment.user.name }}</span>
                <i class="cf-delete" @click.prevent="clearAnswerComment"></i>
            </div>
            <div class="card-footer comments d-lg-none">
                <div class="d-flex">
                    <!--<div class="align-self-end comment-actions d-block d-lg-none">-->
                        <!--<i class="fa cf-camera text-grey mr-2" @click.prevent="saveComment"></i>-->
                    <!--</div>-->
                    <div class="d-flex w-100 relative">
                        <!--@keypress.enter.prevent="saveComment"-->
                        <textarea class="d-flex comment new-comment w-100"
                                  :id="'newCommentGroupBuyId' + groupBuy.id"
                                  :placeholder="$t('Scrivi un commento...')"
                                  v-model="comment.text"
                        >
                        </textarea>
                        <!--<div class="absolute align-self-end comment-actions d-block d-lg-none">-->
                            <!--<i class="fa fa-smile-o text-grey" @click.prevent="saveComment"></i>-->
                        <!--</div>-->
                    </div>
                    <div class="d-block comment-actions d-lg-none align-self-end">
                        <i class="fa cf-message-f text-brand ml-2" v-if="comment.text.length" v-login-required="saveComment"></i>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>

    import Vue from 'vue';
    import { mapGetters, mapState, mapActions } from 'vuex'

    import EditPost from "~/components/Group/Wall/Posts/EditPost.vue";
    import CommunicationModal from "~/components/CommunicationModal";

    import autosize from "autosize"

    export default {
        props: {
            groupBuy: {
                type: Object,
                default: function () {
                    return {}
                }
            }
        },

        data: function () {

            return {
                height: 0,
                comment: {
                    text: '',
                    group_buy_product_id: this.groupBuy.id,
                    group_buy_comment_id: null,
                    user: null
                },
                shareNetworks: {
                    'email':{"sharer":"mailto:?subject=@title&body=@url%0D%0A%0D%0A@description", 'type': 'direct'},
                    'facebook':{"sharer":"https://www.facebook.com/sharer/sharer.php?u=@url&title=@title&description=@description&quote=@quote&hashtag=@hashtags", 'type': 'direct'},
                    'linkedin':{"sharer":"https://www.linkedin.com/shareArticle?mini=true&url=@url&title=@title&summary=@description",'type': 'direct'},
                    'twitter':{"sharer":"https://twitter.com/intent/tweet?text=@title&url=@url&hashtags=@hashtags@twitteruser",'type': 'direct'},
                    'whatsapp':{"sharer":"https://api.whatsapp.com/send?text=@description%0D%0A@url", 'type': 'direct'},
                }
            }
        },

        watch: {
            bottomOffset: function () {
                document.querySelector('body').scrollTo(0,0)
            }
        },

        computed: {
            ...mapGetters({
                'getGroupBuyComments': 'group-buy-comments/getFilteredComments',
            }),
            ...mapState([
                'bottomOffset',
                'keyboardOpened',
                'insets',
                'group'
            ]),
            filteredComments() {
                return this.getGroupBuyComments({
                    group_buy_product_id: this.groupBuy.id
                })
            },
            groupBuyUrl () {
                return process.env.BASE_URL + '/gruppi-di-acquisto/' + this.groupBuy.slug
            },
        },

        methods: {

            ...mapActions({
                'fetch': 'group-buy-comments/fetch',
                'refetchGroupBuy': 'group-buys/fetchBySlug',
                'createComment': 'group-buy-comments/create',
                'refetchComment': 'group-buy-comments/fetchById',
                'like': 'group-buys/likeGroupBuyById',
            }),

            show() {
                this.$refs.postModal.show()
            },

            init() {

                this.loadMore();
                autosize.update(document.querySelectorAll('textarea'))
            },

            hide() {

                this.clearAnswerComment()
            },

            clearAnswerComment() {

                this.$nuxt.$emit('group-buy-' + this.groupBuy.id + '-clear-answer-to-comment-id')

                this.comment.user = null;
                this.comment.group_buy_comment_id = null;
            },

            loadMore() {

                this.page++;

                this.fetch({
                    page: this.page,
                    per_page: 50,
                    filters: {
                        group_post_product_id: this.groupBuy.id
                    }
                })
            },

            saveComment () {

                if (this.comment.text.length === 0) return;

                this.$nuxt.$loading.start()

                this.createComment(this.comment)
                    .then((comment) => {
                        this.showingOverlay = false;

                        this.comment.text = '';
                        this.comment.group_buy_comment_id = null;
                        this.comment.user = null;

                        setTimeout(function () {
                            autosize.update(document.querySelectorAll('textarea'))
                        }, 20)

                        this.refetchGroupBuy(this.groupBuy.slug)

                        if (comment.comment_id) {
                            this.refetchComment(comment.group_buy_comment_id)
                        }

                        this.$nuxt.$loading.finish()

                    }).catch(() => {
                    this.$nuxt.$loading.finish()
                });
            },
        },

        created () {

            this.$nuxt.$on('focus-new-comment-' + this.groupBuy.id, data => {
                document.getElementById('newCommentGroupBuyId' + this.groupBuy.id).focus();
            });

            this.$nuxt.$on('group-buy-' + this.groupBuy.id + '-answer-to-comment-id', (data) => {

                Vue.set(this.comment, 'group_buy_comment_id', data.commentId)
                Vue.set(this.comment, 'user', data.user)
                Vue.set(this.comment, 'text', data.user ? '@' + data.user.name + ' ' + this.comment.text : this.comment.text)

                setTimeout(() => {
                    autosize.update(document.querySelectorAll('textarea'))
                })
            });
        },

        mounted () {

            // this.height = window.innerHeight;
        },
    }
</script>
