<template>
    <b-modal id="communication-modal" size="xl" modal-class="slide-left" body-class="p-0 news" @shown="init" @hidden="hidden" no-fade hide-header hide-footer ref="communicationModal" static lazy>
        <input type="file" name="image" :id="'image-input-' + _uid" accept="image/*" style="display: none" @change="loadImages"/>
        <div class="card post w-100"
             :style="'height:100vh!important;height: calc(var(--vh, 1vh) * 100)!important;padding-top:' + insets.top + 'px!important; padding-bottom:' + insets.bottom + 'px'"
        >
            <div class="card-header card-header-sm d-flex justify-content-center align-items-center">
                <i class="position-absolute cf-back p-3" style="left: 0" @click.prevent="backPressed"></i>
                <span v-if="post.id">{{ $t('Modifica comunicazione') }}</span>
                <span v-else>{{ $t('Nuova comunicazione') }}</span>
                <span class="position-absolute p-3 font-size-sm" style="right: 0; text-decoration: underline" v-if="step === 1" @click="next">{{ $t('Avanti') }}</span>
                <span class="position-absolute p-3 font-size-sm" style="right: 0; text-decoration: underline" v-if="step === 2" @click="store">{{ $t('Salva') }}</span>
            </div>
            <div class="card-body scrollable-content p-0" style="-webkit-user-select:text; padding-bottom: 60px!important" :class="{'background-gray': step === 2}">
                <!-- Communication -->
                <div v-show="step === 1">
                    <images-loader v-if="fileEvent || editPost.images.length"
                                   :immediate-saving="false"
                                   url="api/post-images"
                                   :event.sync="fileEvent"
                                   :images="editPost.images"
                                   size="small"
                                   v-model="editPost.images"
                                   :on-image-deleted="deleteImage"
                                   layout="grid"
                                   @click.prevent="openInput"
                    >
                    </images-loader>
                    <div class="d-flex flex-column justify-content-center align-items-center cursor-pointer position-relative" @click.prevent="openInput" v-else>
                        <div class="image-placeholder d-flex justify-content-center align-items-center background-dark-gray">
                            <!--<i class="cf-camera text-white"></i>-->
                            <img alt="photo-icon-w" src="~/assets/images/icons/photo-icon-w.svg" width="32px" height="32px">
                        </div>
                        <div class="absolute p-1 pr-2 pl-2 font-size-sm edit-button bottom right d-flex align-items-center justify-content-center">
                            <i class="cf-camera-f text-white mr-1"></i>
                            <!--<span v-if="account.account.staff_image_res">{{ $t('Modifica') }}</span>-->
                            <!--<span v-else>{{ $t('Aggiungi') }}</span>-->
                            {{ $t('Aggiungi') }}
                        </div>
                    </div>
                    <textarea
                            :id="'title-text-area-' + _uid"
                            :placeholder="$t('Titolo')"
                            class="w-100 p-3 font-size-extra font-weight-medium title-textarea"
                            @input="updateSlug"
                            v-model="editPost.title"
                    >
                    </textarea>

                    <editor-menu-bubble class="menububble" :editor="editor" @hide="hideLinkMenu" v-slot="{ commands, isActive, getMarkAttrs, menu }">
                        <div
                            class="menububble"
                            :class="{ 'is-active': menu.isActive }"
                            :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
                        >
                            <form class="menububble__form" v-if="linkMenuIsActive" @submit.prevent="setLinkUrl(commands.link, linkUrl)">
                                <input class="menububble__input" type="text" v-model="linkUrl" placeholder="https://" ref="linkInput" @keydown.esc="hideLinkMenu"/>
                                <i class="fa fa-times-circle-o" @click="setLinkUrl(commands.link, null)"></i>
                            </form>
                            <template v-else>
                                <div class="d-flex">
                                    <div class="editor-button" :class="{ 'is-active': isActive.bold() }" @click="commands.bold"><i class="fa fa-bold"></i></div>
                                    <div class="editor-button" :class="{ 'is-active': isActive.italic() }" @click="commands.italic"><i class="fa fa-italic"></i></div>
                                    <div class="editor-button" :class="{ 'is-active': isActive.underline() }" @click="commands.underline"><i class="fa fa-underline"></i></div>
                                    <div class="editor-button" :class="{ 'is-active': isActive.link() }" @click="showLinkMenu(getMarkAttrs('link'))"><i class="fa fa-link"></i></div>
                                </div>
                            </template>
                        </div>
                    </editor-menu-bubble>
                    <editor-menu-bar v-if="!mobileMode" :editor="editor" v-slot="{ commands, isActive, getMarkAttrs }">
                        <div class="d-flex editor-toolbar mx-3" :style="'bottom:' + insets.bottom + 'px;'">
                            <div class="editor-button border-right px-3" @click.prevent="toggleShowingMedia">
                                <i class="fa fa-plus-circle" v-if="!showingMedia"></i>
                                <i class="fa fa-minus-circle" v-else></i>
                            </div>
                            <div class="w-100 d-flex justify-content-between justify-content-lg-start" v-if="!showingMedia">
                                <div class="d-flex">
                                    <div class="position-relative" v-click-outside="hideHeadings">
                                        <div class="editor-dropdown position-absolute border background-white p-3 font-size-sm" style="top: -189px; left: 0" v-if="showingHeadings">
                                            <div class="no-wrap editor-dropdown-item text-h1" :class="{ 'is-active': isActive.heading({ level: 1 }) }" @click="commands.heading({ level: 1 })">{{ $t('Titolo') }} 1</div>
                                            <div class="no-wrap editor-dropdown-item text-h2" :class="{ 'is-active': isActive.heading({ level: 2 }) }" @click="commands.heading({ level: 2 })">{{ $t('Titolo') }} 2</div>
                                            <div class="no-wrap editor-dropdown-item text-h3" :class="{ 'is-active': isActive.heading({ level: 3 }) }" @click="commands.heading({ level: 3 })">{{ $t('Titolo') }} 3</div>
                                        </div>
                                        <div class="editor-button" :class="{ 'is-active': isActive.heading() }" @click.prevent="toggleShowingHeadings">
                                            <span v-if="isActive.heading({level: 1})">H1</span>
                                            <span v-else-if="isActive.heading({level: 2})">H2</span>
                                            <span v-else-if="isActive.heading({level: 3})">H3</span>
                                            <span v-else>H</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex">
                                    <div class="editor-button" :class="{ 'is-active': isActive.bold() }" @click="commands.bold"><i class="fa fa-bold"></i></div>
                                    <!--<div class="editor-button" :class="{ 'is-active': isActive.table() }" @click="commands.createTable({rowsCount: 3, colsCount: 3, withHeaderRow: false })"><i class="fa fa-table"></i></div>-->
                                    <div class="editor-button" :class="{ 'is-active': isActive.italic() }" @click="commands.italic"><i class="fa fa-italic"></i></div>
                                    <div class="editor-button" :class="{ 'is-active': isActive.underline() }" @click="commands.underline"><i class="fa fa-underline"></i></div>
                                </div>
                                <div class="d-flex">
                                    <div class="editor-button" :class="{ 'is-active': isActive.bullet_list() }" @click="commands.bullet_list()"><i class="fa fa-list-ul"></i></div>
                                    <div class="editor-button" :class="{ 'is-active': isActive.ordered_list() }" @click="commands.ordered_list()"><i class="fa fa-list-ol"></i></div>
                                </div>
                                <div class="d-flex">
                                    <div class="editor-button" :class="{ 'is-active': isActive.code_block() }" @click="commands.code_block()"><i class="fa fa-code"></i></div>
                                    <div class="editor-button" :class="{ 'is-active': isActive.blockquote() }" @click="commands.blockquote()"><i class="fa fa-quote-left"></i></div>
                                    <!--<div class="editor-button" :class="{ 'is-active': isActive.link() }" @click="showLinkMenu(getMarkAttrs('link'))"><i class="fa fa-link"></i></div>-->
                                </div>
                            </div>
                            <div class="d-flex" v-else-if="showingMedia">
                                <div class="d-flex">
                                    <input :id="'editor-image-input-' + _uid" type="file" hidden @change="addedImage" accept="image/png, image/jpeg">
                                    <div class="editor-button" @click="documentHandler('image')"><i class="cf-camera-f"></i></div>
                                </div>
                                <div class="d-flex">
                                    <input :id="'editor-file-input-' + _uid" type="file" hidden @change="addedFile">
                                    <div class="editor-button" @click="documentHandler('file')"><i class="fa fa-file"></i></div>
                                </div>
                            </div>
                        </div>
                    </editor-menu-bar>
                    <div class="mx-lg-3 editor__content">
                        <editor-content class="p-3" :editor="editor"></editor-content>
                    </div>

                    <!--<vue-editor class="font-size-lg background-white" v-model="post.text" :editorToolbar="customToolbar" placeholder="Scrivi qualcosa..."></vue-editor>-->

                </div>

                <!-- Settings -->
                <div v-if="step === 2">
                    <div class="text-grey p-2 font-weight-medium font-size-sm">{{ $t('TASSONOMIA') }}</div>
                    <div class="card w-100"  @click="showParentPicker">
                        <div class="card-body p-0">
                            <div class="w-100 py-2 px-3 border-bottom d-flex justify-content-between align-items-center">
                                <div>
                                    <div class="font-weight-bold mb-1">{{ $t('Genitore') }}</div>
                                    <div>{{ getParentLabelFromValue() }}</div>
                                </div>
                                <i class="fa-rotate-180 cf-back text-grey"></i>
                            </div>
                            <!--<div class="w-100 p-3 border-bottom d-flex justify-content-between align-items-center">-->
                            <!--<div>-->
                            <!--<div class="font-weight-bold mb-1">{{ $t('Argomenti') }}</div>-->
                            <!--<div class="text-grey">test</div>-->
                            <!--</div>-->
                            <!--<i class="fa-rotate-180 cf-back text-grey"></i>-->
                            <!--</div>-->
                        </div>
                    </div>
                    <select-picker
                            :options="getFilteredPosts({type: 'page'})"
                            v-if="showingParentPicker"
                            v-model="editPost.parent_id"
                            label="title"
                            :cancel-callback="cancelParentPicker"
                            :save-callback="cancelParentPicker"
                    >
                        <template slot="picker-title">
                            {{ $t('Categoria') }}
                        </template>
                        <template slot="options-title">
                            Test
                        </template>
                    </select-picker>
                    <div class="card w-100" @click="showCategoryPicker">
                        <div class="card-body p-0">
                            <div class="w-100 py-2 px-3 border-bottom d-flex justify-content-between align-items-center">
                                <div>
                                    <div class="font-weight-bold mb-1">{{ $t('Categoria') }}</div>
                                    <div>{{ getTypeLabelFromValue() }}</div>
                                </div>
                                <i class="fa-rotate-180 cf-back text-grey"></i>
                            </div>
                            <!--<div class="w-100 py-2 px-3 border-bottom d-flex justify-content-between align-items-center">-->
                            <!--<div>-->
                            <!--<div class="font-weight-bold mb-1">{{ $t('Argomenti') }}</div>-->
                            <!--<div class="text-grey">test</div>-->
                            <!--</div>-->
                            <!--<i class="fa-rotate-180 cf-back text-grey"></i>-->
                            <!--</div>-->
                        </div>
                    </div>
                    <select-picker
                            :options="categories"
                            v-if="showingCategoryPicker"
                            v-model="editPost.type"
                            :cancel-callback="cancelCategoryPicker"
                            :save-callback="cancelCategoryPicker"
                    >
                        <template slot="picker-title">
                            {{ $t('Categoria') }}
                        </template>
                        <template slot="options-title">
                            {{ $t('Lista categorie') }}
                        </template>
                    </select-picker>
                    <div class="text-grey p-2 font-weight-medium font-size-sm">{{ $t('PUBBLICA') }}</div>
                    <div class="card w-100">
                        <div class="card-body p-0">
                            <div class="w-100 py-2 px-3 border-bottom d-flex justify-content-between align-items-center">
                                <div>
                                    <div class="font-weight-bold mb-1">{{ $t('Data') }}</div>
                                    <label for="datetime-local" class="text-grey" v-if="!editPost.published_at">{{ $t('Inserisci una data..') }}</label>
                                    <input id="datetime-local" type="datetime-local" class="p-0" v-model="editPost.published_at">
                                </div>
                            </div>
                            <div class="w-100 py-2 px-3 border-bottom d-flex justify-content-between align-items-center" @click="showStatusPicker">
                                <div>
                                    <div class="font-weight-bold mb-1">{{ $t('Stato') }}</div>
                                    <div>{{ getStatusLabelFromValue() }}</div>
                                </div>
                            </div>
                            <div class="w-100 py-2 px-3 border-bottom d-flex justify-content-between align-items-center" @click="showVisibilityPicker">
                                <div>
                                    <div class="font-weight-bold mb-1">{{ $t('Visibilità') }}</div>
                                    <div>{{ getVisibilityLabelFromValue() }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <select-picker
                            :options="statuses"
                            v-if="showingStatusPicker"
                            v-model="editPost.status"
                            :cancel-callback="cancelStatusPicker"
                            :save-callback="cancelStatusPicker"
                    >
                        <template slot="picker-title">
                            {{ $t('Stato') }}
                        </template>
                        <template slot="options-title">
                            {{ $t('Lista categorie') }}
                        </template>
                    </select-picker>
                    <select-picker
                            :options="visibility"
                            v-if="showingVisibilityPicker"
                            v-model="editPost.visibility"
                            :cancel-callback="cancelVisibilityPicker"
                            :save-callback="cancelVisibilityPicker"
                    >
                        <template slot="picker-title">
                            {{ $t('Visibiità') }}
                        </template>
                        <template slot="options-title">
                            {{ $t('Visibilità') }}
                        </template>
                    </select-picker>
                    <div class="text-grey p-2 font-weight-medium font-size-sm">{{ $t('CONTRASSEGNA COME IN EVIDENZA') }}</div>
                    <div class="card w-100">
                        <div class="card-body p-0">
                            <div class="w-100 py-2 px-3 border-bottom d-flex justify-content-between align-items-center">
                                <div class="font-weight-bold mb-1">{{ $t("Metti in evidenza l'articolo in prima pagina") }}</div>
                                <div class="switch switch-sm">
                                    <input :id="'switch' + _uid" type="checkbox" v-model="editPost.pinned" class="switch-sm"/>
                                    <label :for="'switch' + _uid" class="m-0" style="font-size: 15px"></label>
                                </div>
                            </div>
                            <div class="w-100 py-2 px-3 border-bottom d-flex justify-content-between align-items-center">
                                <div class="font-weight-bold mb-1">{{ $t("Visibile nel menu") }}</div>
                                <div class="switch switch-sm">
                                    <input :id="'menu-switch' + _uid" type="checkbox" v-model="editPost.menu" class="switch-sm"/>
                                    <label :for="'menu-switch' + _uid" class="m-0" style="font-size: 15px"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-grey p-2 font-weight-medium font-size-sm">{{ $t('ALTRE OPZIONI') }}</div>
                    <div class="card w-100">
                        <div class="card-body p-0">
                            <div class="w-100 py-2 px-3 border-bottom d-flex justify-content-between align-items-center">
                                <div class="w-100">
                                    <div class="font-weight-bold mb-1">{{ $t('Abbreviazione') }}</div>
                                    <input type="text" v-model="editPost.slug" class="w-100" :placeholder="$t('inserisci-una-abbreviazione')" @input="correctSlug" :disabled="editPost.id">
                                </div>
                            </div>
                            <div class="w-100 py-2 px-3 border-bottom">
                                <div class="font-weight-bold mb-1">{{ $t('Riassunto') }}</div>
                                <div class="text-grey w-100">
                                <textarea
                                        :placeholder="$t('Abstract')"
                                        class="w-100 pt-3 font-size-base border-0 abstract"
                                        v-model="editPost.abstract"
                                >
                                </textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <editor-menu-bar v-if="mobileMode" :editor="editor" v-slot="{ commands, isActive, getMarkAttrs }">
                <div class="d-flex editor-toolbar" :style="'bottom:' + insets.bottom + 'px;'">
                    <div class="editor-button border-right px-3" @click.prevent="toggleShowingMedia">
                        <i class="fa fa-plus-circle" v-if="!showingMedia"></i>
                        <i class="fa fa-minus-circle" v-else></i>
                    </div>
                    <div class="w-100 d-flex justify-content-between" v-if="!showingMedia">
                        <div class="d-flex">
                            <div class="position-relative" v-click-outside="hideHeadings">
                                <div class="editor-dropdown position-absolute border background-white p-3 font-size-sm" style="top: -189px; left: 0" v-if="showingHeadings">
                                    <div class="no-wrap editor-dropdown-item text-h1" :class="{ 'is-active': isActive.heading({ level: 1 }) }" @click="commands.heading({ level: 1 })">{{ $t('Titolo') }} 1</div>
                                    <div class="no-wrap editor-dropdown-item text-h2" :class="{ 'is-active': isActive.heading({ level: 2 }) }" @click="commands.heading({ level: 2 })">{{ $t('Titolo') }} 2</div>
                                    <div class="no-wrap editor-dropdown-item text-h3" :class="{ 'is-active': isActive.heading({ level: 3 }) }" @click="commands.heading({ level: 3 })">{{ $t('Titolo') }} 3</div>
                                </div>
                                <div class="editor-button" :class="{ 'is-active': isActive.heading() }" @click.prevent="toggleShowingHeadings">
                                    <span v-if="isActive.heading({level: 1})">H1</span>
                                    <span v-else-if="isActive.heading({level: 2})">H2</span>
                                    <span v-else-if="isActive.heading({level: 3})">H3</span>
                                    <span v-else>H</span>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div class="editor-button" :class="{ 'is-active': isActive.bold() }" @click="commands.bold"><i class="fa fa-bold"></i></div>
                            <!--<div class="editor-button" :class="{ 'is-active': isActive.table() }" @click="commands.createTable({rowsCount: 3, colsCount: 3, withHeaderRow: false })"><i class="fa fa-table"></i></div>-->
                            <div class="editor-button" :class="{ 'is-active': isActive.italic() }" @click="commands.italic"><i class="fa fa-italic"></i></div>
                            <div class="editor-button" :class="{ 'is-active': isActive.underline() }" @click="commands.underline"><i class="fa fa-underline"></i></div>
                        </div>
                        <div class="d-flex">
                            <div class="editor-button" :class="{ 'is-active': isActive.bullet_list() }" @click="commands.bullet_list()"><i class="fa fa-list-ul"></i></div>
                            <div class="editor-button" :class="{ 'is-active': isActive.ordered_list() }" @click="commands.ordered_list()"><i class="fa fa-list-ol"></i></div>
                        </div>
                        <div class="d-flex">
                            <div class="editor-button" :class="{ 'is-active': isActive.code_block() }" @click="commands.code_block()"><i class="fa fa-code"></i></div>
                            <div class="editor-button" :class="{ 'is-active': isActive.blockquote() }" @click="commands.blockquote()"><i class="fa fa-quote-left"></i></div>
                            <!--<div class="editor-button" :class="{ 'is-active': isActive.link() }" @click="showLinkMenu(getMarkAttrs('link'))"><i class="fa fa-link"></i></div>-->
                        </div>
                    </div>
                    <div class="d-flex" v-else-if="showingMedia">
                        <div class="d-flex">
                            <input :id="'editor-image-input-' + _uid" type="file" hidden @change="addedImage" accept="image/png, image/jpeg">
                            <div class="editor-button" @click="documentHandler('image')"><i class="cf-camera-f"></i></div>
                        </div>
                        <div class="d-flex">
                            <input :id="'editor-file-input-' + _uid" type="file" hidden @change="addedFile">
                            <div class="editor-button" @click="documentHandler('file')"><i class="fa fa-file"></i></div>
                        </div>
                    </div>
                </div>
            </editor-menu-bar>
            <!--<ckeditor :editor="CKEditor" v-model="editorData" :config="{}"></ckeditor>-->
        </div>
    </b-modal>
</template>

<script>

    import Vue from 'vue'
    import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'

    import { Editor } from 'tiptap'
    // import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

    import { Bold, Italic, Underline, Link, HardBreak, Heading, CodeBlock, Blockquote, ListItem, BulletList, OrderedList, Image, Table, TableHeader, TableCell, TableRow, Placeholder, TrailingNode } from 'tiptap-extensions'

    import SelectPicker from '~/components/SelectPicker'
    import axios from "axios";

    import autosize from "autosize"
    import { cloneDeep } from 'lodash'

    export default {

        components: {
            SelectPicker
        },

        props: {
            post: {
                type: Object,
                default: function () {
                    return {
                        title: '',
                        type: 'page',
                        status: 'draft',
                        layout: 'article',
                        visibility: 'public',
                        menu: false,
                        text: '',
                        slug: '',
                        abstract: '',
                        images:[],
                        published_at: null,
                    }
                }
            }
        },

        data() {

            let newPost = cloneDeep(this.post)
            let date = this.post.published_at ? this.$moment(this.post.published_at) : this.$moment()
            newPost.published_at = date.format('YYYY-MM-DDTHH:mm')

            return {
                editPost: newPost,
                // CKEditor: ClassicEditor,
                editorData: '<p>Content of the editor.</p>',
                editor: null,
                showingMedia: false,
                showingHeadings: false,
                linkUrl: null,
                linkMenuIsActive: false,
                height: 0,
                step: 1,
                fileEvent: null,
                customToolbar: [{ 'header': 1 }, { 'header': 2 }, "bold", "italic", "underline", 'blockquote', { list: "ordered" }, { list: "bullet" }, 'link', 'video'],
                showingCategoryPicker: false,
                showingParentPicker: false,
                showingVisibilityPicker: false,
                showingStatusPicker: false,
                categories: [
                    {
                        id: 'article',
                        name: 'Articolo'
                    },
                    {
                        id: 'communication',
                        name: 'Circolare'
                    },
                    {
                        id: 'page',
                        name: 'Istituzionale'
                    }
                ],
                statuses: [
                    {
                        id: 'draft',
                        name: 'Bozza'
                    },
                    {
                        id: 'published',
                        name: 'Pubblicato'
                    }
                ],
                visibility: [
                    {
                        id: 'public',
                        name: 'Pubblico'
                    },
                    {
                        id: 'users',
                        name: 'Utenti'
                    },
                    {
                        id: 'members',
                        name: 'Membri'
                    }
                ]
            }
        },

        watch: {
            step: function () {
                setTimeout(function () {
                    autosize(document.querySelectorAll('textarea'))
                    autosize.update(document.querySelectorAll('textarea'));
                });
            },
            post: {
                deep: true,
                handler: function () {
                    this.editPost = {
                        ...this.post,
                        published_at: this.$moment(this.post.published_at).format('YYYY-MM-DDTHH:mm')
                    }
                }
            }
        },

        computed: {

            ...mapGetters({
                'getCommentsByPostId': 'comments/GET_COMMENTS_BY_POST_ID',
                'getFilteredPosts': 'posts/getFilteredPosts'
            }),
            ...mapState([
                'bottomOffset',
                'keyboardOpened',
                'insets',
                'group',
                'mobileMode'
            ])
        },

        methods: {

            ...mapActions({
                'fetch': 'comments/fetch',
                'refetch': 'group/fetchById',
                'refetchPost': 'posts/fetchById',
                'createComment': 'comment/create'
            }),
            ...mapMutations({
                'appendUser': 'users/APPEND_USER',
                'appendPost': 'posts/APPEND_POST'
            }),
            addedDocument(e, type) {
                let formData = new FormData();

                formData.append("document", e.target.files[0]);
                formData.append("type", type);

                this.$axios.post("/api/documents", formData)
                    .then(document => {
                        let src = document.data.url; // Get url from response

                        if (type === 'image') {
                            this.editor.commands.image({ src: src })
                        } else if (type === 'file') {
                            let doc = this.editor.getJSON();

                            doc.content.push({
                                marks: [
                                    {
                                        attrs: {
                                            href: src
                                        },
                                        type: 'link'
                                    }
                                ],
                                text: document.data.original_name,
                                type: "text"
                            })

                            this.editor.setContent(doc)
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    });
            },
            addedFile(e) {
                this.addedDocument(e, 'file')
            },
            addedImage(e) {
                this.addedDocument(e, 'image')
            },
            documentHandler(type) {

                let image = document.getElementById('editor-' + type + '-input-' + this._uid);

                image.click()
            },
            toggleShowingMedia() {
                this.showingMedia = !this.showingMedia;
            },
            hideHeadings() {
                this.showingHeadings = false;
            },
            toggleShowingHeadings() {
                this.showingHeadings = !this.showingHeadings;
            },
            showLinkMenu(attrs) {
                this.linkUrl = attrs.href
                this.linkMenuIsActive = true
                this.$nextTick(() => {
                    this.$refs.linkInput.focus()
                })
            },
            hideLinkMenu() {
                this.linkUrl = null
                this.linkMenuIsActive = false
            },
            setLinkUrl(command, url) {
                command({ href: url })
                this.hideLinkMenu()
            },
            backPressed() {

                if (this.step === 1) {

                    if (_.isEqual(this.editPost, this.post)) {
                        this.$refs.communicationModal.hide()
                    }
                    else {
                        this.$swal({
                            text: this.$t('Sei sicuro di voler uscire? I dati non salvati saranno persi.'),
                            type: 'warning',
                            showCancelButton: true,
                            reverseButtons: true,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false,
                            animation: false,
                            cancelButtonText: this.$t('Annulla'),
                            confirmButtonText: this.$t('Conferma'),
                        }).then((result) => {
                            if (result.value) {
                                
                                this.editPost = cloneDeep(this.post);
                                this.fileEvent = null;

                                this.$refs.communicationModal.hide()
                            }
                        })
                    }
                }
                else {
                    this.step = 1;
                }
            },
            correction(str) {

                str = str.replace(/^\s+|\s+$/g, ''); // trim
                str = str.toLowerCase();

                // remove accents, swap ñ for n, etc
                let from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
                let to   = "aaaaaeeeeeiiiiooooouuuunc------";
                for (var i=0, l=from.length ; i<l ; i++) {
                    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
                }

                return str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
                    .replace(/\s+/g, '-') // collapse whitespace and replace by -
                    .replace(/-+/g, '-');
            },
            correctSlug() {
                this.editPost.slug = this.correction(this.editPost.slug);
            },
            updateSlug() {
                if (!this.editPost.id) {
                    this.editPost.slug = this.correction(this.editPost.title);
                }
            },
            deleteImage() {

                this.editPost.images = [];
                this.fileEvent = null
            },
            showVisibilityPicker() {

                this.showingVisibilityPicker = true;
            },
            cancelVisibilityPicker() {

                this.showingVisibilityPicker = false;
            },
            showStatusPicker() {

                this.showingStatusPicker = true;
            },
            cancelStatusPicker() {

                this.showingStatusPicker = false;
            },
            showCategoryPicker() {

                this.showingCategoryPicker = true;
            },
            cancelCategoryPicker() {

                this.showingCategoryPicker = false;
            },
            showParentPicker() {

                this.showingParentPicker = true;
            },
            cancelParentPicker() {

                this.showingParentPicker = false;
            },
            openInput(isVisible) {

                if (isVisible) {

                    let image = document.getElementById('image-input-' + this._uid)

                    image.click()
                }
            },
            getStatusLabelFromValue() {
                let option = _.find(this.statuses, { 'id': this.editPost.status });
                return option ? option.name : 'Stato';
            },
            getVisibilityLabelFromValue() {
                let option = _.find(this.visibility, { 'id': this.editPost.visibility });
                return option ? option.name : 'Visibilità';
            },
            getParentLabelFromValue() {
                let option = _.find(this.getFilteredPosts({type: 'page'}), { 'id': this.editPost.parent_id });
                return option ? option.title : 'Pagina base';
            },

            getTypeLabelFromValue() {
                let option = _.find(this.categories, { 'id': this.editPost.type });
                return option ? option.name : 'Tipo comunicazione';
            },
            auto_grow() {
                let element = document.getElementById('title-text-area-' + this._uid)
                element.style.height = "5px";
                element.style.height = (element.scrollHeight)+"px";
            },
            loadImages(e) {
                this.fileEvent = e;
            },
            next() {
                this.step = 2;
            },
            store() {

                let data = new FormData();
                let post = this.editPost;

                data.append('text', post.text);
                data.append('type', post.type);
                data.append('layout', post.layout);
                data.append('title', post.title);
                data.append('slug', post.slug);
                data.append('menu', post.menu);
                data.append('abstract', post.abstract);
                data.append('published_at', post.published_at);
                data.append('status', post.status);
                data.append('visibility', post.visibility);

                if (post.parent_id) {
                    data.append('parent_id', post.parent_id);
                }

                _.each(post.images, function (image, index) {

                    data.append('images[' + index + ']' , image.data)
                })

                this.$nuxt.$loading.start();

                this.$axios.post('/api/posts' + (post.id ? '/' + post.id : ''), data, {
                    // cancelToken: source.token,
                    onUploadProgress: (progressEvent) => {

                        // Vue.set(this.progresses, postName, parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total)));
                    },
                })
                    .then(r => r.data)
                    .then((myPost) => {

                        if (myPost.user) {
                            this.appendUser(myPost.user)
                        }

                        this.appendPost(myPost);

                        this.editPost = cloneDeep(this.post)

                        this.$refs.communicationModal.hide()
                    })
                    .catch((error) => {

                        if (axios.isCancel(error)) {
                            console.log('request canceled')
                        } else {
                            this.$notify({
                                group: 'messages',
                                type: 'error',
                                text: getValidationErrorMessage(error.response.data)
                            })
                        }
                    })
                    .finally(() => {
                        this.$nuxt.$loading.finish();
                    });
            },
            show() {
                this.$refs.communicationModal.show()
            },
            hidden() {

                // console.log(this.$data, this.$options.data().apply(this))

                // Object.assign(this.$data, this.$options.data().apply(this))
            },
            init() {

                console.log(this);

                // this.$options.data().apply(this)

                // Object.assign(this.$data, this.$options.data().bind(this))


                autosize(document.querySelectorAll('textarea'))
                autosize.update(document.querySelectorAll('textarea'))
            },
        },

        created () {

        },

        mounted () {
            this.editor = new Editor({
                onUpdate: ({getHTML}) => {
                    const state = getHTML()
                    Vue.set(this.editPost, 'text', state)
                },
                content: this.post.text,
                extensions: [
                    new Heading({
                        levels: [1, 2, 3]
                    }),
                    new Placeholder({
                        emptyNodeClass: 'is-empty',
                        emptyNodeText: this.$t('Scrivi qualcosa…'),
                        showOnlyWhenEditable: true,
                    }),
                    new Bold(),
                    new Italic(),
                    new Underline(),
                    new Link(),
                    new HardBreak(),
                    new CodeBlock(),
                    new Blockquote(),
                    new ListItem(),
                    new BulletList(),
                    new OrderedList(),
                    new Image(),
                    new Table({
                        resizable: true,
                    }),
                    new TableHeader(),
                    new TableCell(),
                    new TableRow(),
                    new TrailingNode({
                        node: 'paragraph',
                        // notAfter: [],
                    }),
                ]
            });
        },
    }
</script>

<style scoped lang="scss">

    /*textarea {*/
        /*resize: none;*/
        /*overflow: hidden;*/
        /*min-height: 30px;*/
        /*height: 40px;*/
        /*max-height: 150px;*/
    /*}*/

    textarea {
        border: none!important;
        height: 50px;
        &:focus {
            outline: none;
        }
    }

    .image-placeholder {
        height: 60vw;
        max-height: 200px;
        width: 100%;
    }

    .edit-button {
        background-color: #a1a1a1;
        color: white;
        border: 1px solid white;
        border-radius: 2px;

    &.bottom {
         bottom: 5px;
     }
    &.right {
         right:5px;
     }
    }
</style>