
import { getField, updateField } from 'vuex-map-fields';

export const state = () => ({
    post: {
        city: null,
        postal_code: null
    },
    tab: 'about',
    loading: false,
    saving: false,
})

export const getters = {
    getField
}

export const mutations = {
    updateField,
    SET_POST (state, post) {
        state.post = post
    },
    SET_LOADING (state, loading) {
        state.loading = loading
    },
    SET_SAVING (state, saving) {
        state.saving = saving
    },
}

export const actions = {

    async refetch ({ state, dispatch }) {
        await dispatch('fetchById', state.post.id)
    },

    fetchById ({ dispatch, commit, state}, data) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .get('/api/posts/' + data.id, {
                params: {
                    notifications: data.notifications
                }
            })
            .then(r => r.data)
            .then(post => {
                commit('SET_POST', post)
                commit('posts/APPEND_POST', post, {root: true});


                if(post.comments && post.comments.length) {
                    dispatch('comments/appendComments', post.comments, {root: true});
                }

                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },

    create ({ commit, state}, post) {

        commit('SET_SAVING', true)

        return this.$axios
            .post('/api/posts', post)
            .then(r => r.data)
            .then(post => {
                if(post.user) {
                    commit('users/APPEND_USER', post.user, {root: true})
                }

                commit('posts/APPEND_POST', post, {root: true});
                commit('SET_SAVING', false)
            })
            .catch(error => {
                commit('SET_SAVING', false)
                throw error;
            })
    },

    update ({ commit, state}, post) {

        commit('SET_SAVING', true)

        return this.$axios
            .post('/api/posts/' + post.id, post)
            .then(r => r.data)
            .then(post => {
                if(post.user) {
                    commit('users/APPEND_USER', post.user, {root: true})
                }
                commit('posts/APPEND_POST', post, {root: true});
                commit('SET_SAVING', false)
            })
            .catch(error => {
                commit('SET_SAVING', false)
                throw error;
            })
    }
}