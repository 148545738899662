<template>
    <div>
        <div class="text-center">
            <input type="file" name="image" :id="type + '-image-input'" accept="image/*" @change="setImage" style="display: none"/>

            <div v-if="!imgSrc">
                <div class="mb-3 image-container d-flex align-items-center justify-content-center" @click.prevent="openInput">
                    <img class="" :class="type.toLowerCase() + '-image'" :src="stringBlob" v-if="blob">
                    <img class="" :class="type.toLowerCase() + '-image'" :src="imgPath" v-else-if="imgPath">
                    <div v-else>
                        <img alt="account-placeholder" class="profile-placeholder" :class="type.toLowerCase() + '-image'" src="~/assets/images/account-placeholder.svg" v-if="type === 'Profile'">
                        <img alt="brand-placeholder" class="profile-placeholder" :class="type.toLowerCase() + '-image'" src="~/assets/images/brand-placeholder.svg" v-else-if="type === 'Brand'">
                        <img alt="header-placeholder" :class="type.toLowerCase() + '-image'" src="~/assets/images/header-placeholder.jpg" v-else>
                    </div>
                </div>
                <div v-if="type === 'Profile'">
                    <strong class="title mt-3">
                        {{ $t('Aggiungi un\'immagine visualizzata')}}
                    </strong>
                    <div class="description mt-3">
                        {{ $t('Scegli un\'immagine professionale per aiutare le tue clienti a trovare la tua struttura') }}
                    </div>
                </div>
                <div v-else-if="type === 'Brand'">
                    <strong class="title mt-3">
                        {{ $t('Aggiungi l\'immagine del brand') }}
                    </strong>
                    <div class="description mt-3">
                        {{ $t('Scegli un\'immagine professionale per rendere riconoscibile questo brand a tutti.') }}
                    </div>
                </div>
                <div v-else>
                    <strong class="title mt-3">
                        {{ $t('Aggiungi un\'immagine di copertina')}}
                    </strong>
                    <div class="description mt-3">
                        {{ $t('Scegli un\'immagine che verrà visualizzata nella parte superiore del profilo della tua struttura.') }}
                    </div>
                </div>
            </div>
            <div class="account-cropper cropper-container-size d-flex align-items-center justify-content-center" :class="type.toLowerCase() + '-cropper'" v-else>
                <vue-cropper
                        ref="cropper"
                        :src="imgSrc"
                        dragMode="move"
                        :guides="false"
                        :info="true"
                        :minCropBoxWidth="250"
                        :minCropBoxHeight="250"
                        :canMove="true"
                        :autoCrop="true"
                        :autoCropArea="options.autoCropArea"
                        :aspectRatio="options.aspectRatio"
                        :cropBoxMovable="false"
                        :cropBoxResizable="false"
                        alt="Source Image"
                        v-if="imgSrc"
                        :imgStyle="{'width': '100%'}"
                        :containerStyle="{'width': '100%'}"
                >
                </vue-cropper>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'

    export default {
        props: {
            type: {
                type: String,
                    default: function () {
                        return 'Profile'
                    }
                },
            imgSrc: {
                type: String,
                default: function () {
                    return ''
                }
            },
            blob: {
                default: function () {
                    return null
                }
            },
            modal: {
                type: Boolean,
                default: function () {
                    return false
                }
            }
        },

        data () {
            return {
                base64img: '',
                options: {
                    autoCropArea: this.type !== 'Header' ? 0.5 : 1,
                    aspectRatio: this.type !== 'Header' ? 1 : 2.53
                }
            }
        },

        computed: {
            ...mapState([
                'account'
            ]),
            stringBlob() {

                if (!this.blob) return '';

                let reader = new FileReader();
                reader.readAsDataURL(this.blob);
                reader.onload = () => {
                    this.base64img = reader.result;
                }
                return this.base64img
            },
            imgPath() {

                if (this.stringBlob) {
                    return this.stringBlob;
                }

                if (this.type === 'Profile') {
                    return this.account.account.profile_image_res ? this.account.account.profile_image_res.medium : ''
                } else if (this.type === 'Header') {
                    return this.account.account.header_image_res ? this.account.account.header_image_res.medium : ''
                }
            }
        },

        methods: {
            ...mapActions({
                'refetchAccount': 'account/refetch'
            }),
            setImage (e) {

                const file = e.target.files[0];

                if (!file.type.includes('image/')) {
                    alert('Please select an image file');
                    return;
                }

                if (typeof FileReader === 'function') {
                    const reader = new FileReader();

                    reader.onload = (event) => {
                        this.$emit('update:imgSrc', event.target.result)
                        // rebuild cropperjs with the updated source

                        // this.$refs.cropper.replace(event.target.result);
                    };

                    reader.readAsDataURL(file);
                } else {
                    alert('Sorry, FileReader API not supported');
                }
            },
            getImage() {
                this.$refs.cropper.getCroppedCanvas({maxWidth: 1920, maxHeight: 1920}).toBlob(blob => {

                    this.$emit('update:imgSrc', '')
                    this.$emit('update:blob', blob)

                }, 'image/jpeg', 0.95);
            },
            saveImage () {

                this.$nuxt.$loading.start()

                this.$refs.cropper.getCroppedCanvas({maxWidth: 1920, maxHeight: 1920}).toBlob(blob => {

                    let action = this.type.substr(0, this.type.indexOf('-'));

                    this.$store
                        .dispatch('account/update' + (action ? action : this.type) + 'Image', blob)
                        .then(r => {

                            this.refetchAccount()
                                .then(() => {
                                    this.$notify({
                                        group: 'messages',
                                        type: 'success',
                                        text: this.$t('Immagine aggiornata correttamente.')
                                    });

                                    this.$emit('update:imgSrc', '')

                                    if (this.modal) {
                                        this.$nuxt.$emit('close-edit-image-modal')
                                    } else {

                                        if (this.type === 'Profile') {
                                            this.$nuxt.$emit('set-step', 'header-image')
                                        } else {
                                            this.$nuxt.$emit('set-step', 'complete')
                                        }
                                    }
                                })
                        })
                        .finally(() => {
                            this.$nuxt.$loading.finish()
                        })
                }, 'image/jpeg', 0.95);
            },

            openInput (isVisible) {

                if (isVisible) {
                    let image = document.getElementById(this.type + "-image-input")

                    image.click()
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

    .cropper-container-size {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .header-image {
        width: 100%;
    }

    .image-container {

        min-height: 150px;

        .profile-placeholder {
            border: 1px solid #d8bcd3;
        }

        .profile-image, .brand-image {
            width: 150px;
            height: 150px;
            border-radius: 5%;
            overflow: hidden;
        }
    }
</style>
