import Vue from 'vue'
import { getField, updateField } from 'vuex-map-fields';
import _ from 'lodash'

export const state = () => ({
    plans: [],
    processing: false
})

export const getters = {
    getField,
    getOrderedPlans: (state) => (customerId) => {

        let plans = _.map(state.plans, (plan) => {
            let description = '';

            switch(plan.nickname) {
                case 'Punto Estetico':
                    description = 'Il giornale dell\'estetista, per rimanere sempre aggiornati'
                    break;
                case 'Estetista':
                    description = 'Un anno di servizi, tutela e rappresentanza'
                    break;
                case 'Centro Estetico':
                    description = 'Un anno di servizi, tutela e rappresentanza'
                    break;
            }

            return {
                id: plan.id ? plan.id : plan.productId,
                name: plan.nickname ? plan.nickname : plan.title,
                title: 'Quota annuale ' + (plan.nickname ? plan.nickname : plan.title),
                description: plan.description ? plan.description : description,
                amount: plan.amount ? plan.amount / 100 : parseFloat(plan.price),
                actual_amount: plan.actual_amount ? plan.actual_amount / 100 : null,
                selected: plan.selected ? plan.selected : false,
                subscribed: plan.subscribed ? plan.subscribed : false,
                pending_subscription: plan.pending_subscription ? plan.pending_subscription : false
            }
        })

        return _.orderBy(plans, 'amount', 'asc');
    },
    getSelectedPlan: (state, getters) => {

        let selectedPlan =  _.find(getters.getOrderedPlans(), { selected: true })
        return selectedPlan ? selectedPlan : _.first(getters.getOrderedPlans())
    },
}

export const mutations = {
    updateField,
    SET_PROCESSING (state, processing) {
        state.processing = processing
    },
    SET_PLANS (state, plans) {
        state.plans = plans
    },
    APPEND_PLAN (state, plan) {

        if(!_.includes(_.map(getters.getOrderedPlans, 'id'), plan.id) ) {

            state.plans.push(plan);
        } else {

            let planIndex = _.findIndex(state.plans, { id: plan.id });

            Vue.set(state.plans, planIndex, plan);
        }
    },
    SELECT_PLAN (state, planId) {
        let plan = _.find(state.plans, { id: planId });
        _.map(state.plans, function (plan) {
            Vue.set(plan, 'selected', false);
        });

        if (plan) {
            Vue.set(plan, 'selected', true);
        }
    },
}

export const actions = {

    fetch ({ commit, state }) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .get('/api/stripe-plans')
            .then(r => r.data)
            .then(response => {

                commit('SET_PLANS', response)
                commit('SET_PROCESSING', false)
            })
            .catch(error => {

                commit('SET_PROCESSING', false)
                throw error;
            })
    },
    setPlans ({ commit, state, dispatch }, plans) {
        let myPlans = _.map(plans, (plan) => {
            return {
                ...plan,
                id: plan.id ? plan.id : plan.productId
            }
        })

        commit('SET_PLANS', myPlans)
    },
    setDefault ({ commit, state, dispatch }, planId) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .post('/api/plans/' + planId + '/default')
            .then(r => r.data)
            .then(response => {

                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },
    create ({ commit, state, dispatch }, plan) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .put('/api/plans', { cvc: plan.expiry })
            .then(r => r.data)
            .then(response => {

                commit('APPEND_PLAN', response);
                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },
    delete ({ commit, state, dispatch }, planId) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .delete('/api/plans/' + planId)
            .then(r => r.data)
            .then(response => {
                commit('REMOVE_PLAN', planId)

                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    }
}