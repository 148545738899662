import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6a3d4224 = () => interopDefault(import('../pages/abbonamenti/index.vue' /* webpackChunkName: "pages/abbonamenti/index" */))
const _5e741bd3 = () => interopDefault(import('../pages/abusive/index.vue' /* webpackChunkName: "pages/abusive/index" */))
const _111ef707 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _391f4f80 = () => interopDefault(import('../pages/aggiorna-profilo/index.vue' /* webpackChunkName: "pages/aggiorna-profilo/index" */))
const _0e0bae7b = () => interopDefault(import('../pages/aziende-cosmetiche/index.vue' /* webpackChunkName: "pages/aziende-cosmetiche/index" */))
const _0b33a0bd = () => interopDefault(import('../pages/badge/index.vue' /* webpackChunkName: "pages/badge/index" */))
const _593b1eef = () => interopDefault(import('../pages/borsellino/index.vue' /* webpackChunkName: "pages/borsellino/index" */))
const _e9938592 = () => interopDefault(import('../pages/cambia-password/index.vue' /* webpackChunkName: "pages/cambia-password/index" */))
const _52e6c202 = () => interopDefault(import('../pages/centri-estetici/index.vue' /* webpackChunkName: "pages/centri-estetici/index" */))
const _f551a7f0 = () => interopDefault(import('../pages/cerca/index.vue' /* webpackChunkName: "pages/cerca/index" */))
const _2330ead4 = () => interopDefault(import('../pages/chat/index.vue' /* webpackChunkName: "pages/chat/index" */))
const _fe9f0a7c = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _50b8a8e8 = () => interopDefault(import('../pages/comunicazioni/index.vue' /* webpackChunkName: "pages/comunicazioni/index" */))
const _03685496 = () => interopDefault(import('../pages/contatti/index.vue' /* webpackChunkName: "pages/contatti/index" */))
const _4ca3beee = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _7fe5560e = () => interopDefault(import('../pages/disattivazione-ed-eliminazione/index.vue' /* webpackChunkName: "pages/disattivazione-ed-eliminazione/index" */))
const _694d81a1 = () => interopDefault(import('../pages/diventa-socio/index.vue' /* webpackChunkName: "pages/diventa-socio/index" */))
const _083d7caa = () => interopDefault(import('../pages/estetiste/index.vue' /* webpackChunkName: "pages/estetiste/index" */))
const _b0d8a832 = () => interopDefault(import('../pages/fatture/index.vue' /* webpackChunkName: "pages/fatture/index" */))
const _d01d2d96 = () => interopDefault(import('../pages/gestione-pagine/index.vue' /* webpackChunkName: "pages/gestione-pagine/index" */))
const _5f3c73be = () => interopDefault(import('../pages/gruppi-di-acquisto/index.vue' /* webpackChunkName: "pages/gruppi-di-acquisto/index" */))
const _2a420041 = () => interopDefault(import('../pages/gruppo/index.vue' /* webpackChunkName: "pages/gruppo/index" */))
const _4c041b2c = () => interopDefault(import('../pages/impostazioni-di-notifica/index.vue' /* webpackChunkName: "pages/impostazioni-di-notifica/index" */))
const _024515fa = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _6d338524 = () => interopDefault(import('../pages/luogo-di-lavoro/index.vue' /* webpackChunkName: "pages/luogo-di-lavoro/index" */))
const _021dcf7c = () => interopDefault(import('../pages/marchi/index.vue' /* webpackChunkName: "pages/marchi/index" */))
const _119cd225 = () => interopDefault(import('../pages/marketplace/index.vue' /* webpackChunkName: "pages/marketplace/index" */))
const _233bd01b = () => interopDefault(import('../pages/menu/index.vue' /* webpackChunkName: "pages/menu/index" */))
const _ee92393e = () => interopDefault(import('../pages/notifiche/index.vue' /* webpackChunkName: "pages/notifiche/index" */))
const _27a6e524 = () => interopDefault(import('../pages/pannello-amministrazione/index.vue' /* webpackChunkName: "pages/pannello-amministrazione/index" */))
const _b221e8ce = () => interopDefault(import('../pages/password-reset/index.vue' /* webpackChunkName: "pages/password-reset/index" */))
const _221cc70d = () => interopDefault(import('../pages/profilo/index.vue' /* webpackChunkName: "pages/profilo/index" */))
const _4cb0df25 = () => interopDefault(import('../pages/punto-estetico/index.vue' /* webpackChunkName: "pages/punto-estetico/index" */))
const _214bc790 = () => interopDefault(import('../pages/ricarica-borsellino/index.vue' /* webpackChunkName: "pages/ricarica-borsellino/index" */))
const _c9f6d542 = () => interopDefault(import('../pages/scuole/index.vue' /* webpackChunkName: "pages/scuole/index" */))
const _d0caea66 = () => interopDefault(import('../pages/test-estetista/index.vue' /* webpackChunkName: "pages/test-estetista/index" */))
const _318c503e = () => interopDefault(import('../pages/aggiorna-profilo/biografia/index.vue' /* webpackChunkName: "pages/aggiorna-profilo/biografia/index" */))
const _42f521d3 = () => interopDefault(import('../pages/aggiorna-profilo/diploma/index.vue' /* webpackChunkName: "pages/aggiorna-profilo/diploma/index" */))
const _aa9b943c = () => interopDefault(import('../pages/aggiorna-profilo/immagine-copertina/index.vue' /* webpackChunkName: "pages/aggiorna-profilo/immagine-copertina/index" */))
const _7cf6ca08 = () => interopDefault(import('../pages/aggiorna-profilo/immagine-profilo/index.vue' /* webpackChunkName: "pages/aggiorna-profilo/immagine-profilo/index" */))
const _701710aa = () => interopDefault(import('../pages/aggiorna-profilo/specializzazione/index.vue' /* webpackChunkName: "pages/aggiorna-profilo/specializzazione/index" */))
const _fb51aee0 = () => interopDefault(import('../pages/gruppo/nuovo-post/index.vue' /* webpackChunkName: "pages/gruppo/nuovo-post/index" */))
const _01b555fe = () => interopDefault(import('../pages/aggiorna-profilo/certificati/nuovo/index.vue' /* webpackChunkName: "pages/aggiorna-profilo/certificati/nuovo/index" */))
const _7da5e3e3 = () => interopDefault(import('../pages/aggiorna-profilo/esperienze-lavorative/nuovo/index.vue' /* webpackChunkName: "pages/aggiorna-profilo/esperienze-lavorative/nuovo/index" */))
const _c7911b28 = () => interopDefault(import('../pages/pannello-amministrazione/confestetica/gestione-utenti/index.vue' /* webpackChunkName: "pages/pannello-amministrazione/confestetica/gestione-utenti/index" */))
const _29b46e20 = () => interopDefault(import('../pages/pannello-amministrazione/confestetica/richieste-iscrizione/index.vue' /* webpackChunkName: "pages/pannello-amministrazione/confestetica/richieste-iscrizione/index" */))
const _5b843e4d = () => interopDefault(import('../pages/pannello-amministrazione/confestetica/richieste-iscrizione-aziende/index.vue' /* webpackChunkName: "pages/pannello-amministrazione/confestetica/richieste-iscrizione-aziende/index" */))
const _5b1b2894 = () => interopDefault(import('../pages/pannello-amministrazione/confestetica/segnalazioni/index.vue' /* webpackChunkName: "pages/pannello-amministrazione/confestetica/segnalazioni/index" */))
const _41de271b = () => interopDefault(import('../pages/pannello-amministrazione/gruppo/gestione-utenti/index.vue' /* webpackChunkName: "pages/pannello-amministrazione/gruppo/gestione-utenti/index" */))
const _6ebe4ed1 = () => interopDefault(import('../pages/pannello-amministrazione/gruppo/richieste-iscrizione/index.vue' /* webpackChunkName: "pages/pannello-amministrazione/gruppo/richieste-iscrizione/index" */))
const _425e2b69 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _2b9bcd34 = () => interopDefault(import('../pages/aggiorna-profilo/certificati/_id.vue' /* webpackChunkName: "pages/aggiorna-profilo/certificati/_id" */))
const _a691dfce = () => interopDefault(import('../pages/aggiorna-profilo/esperienze-lavorative/_id.vue' /* webpackChunkName: "pages/aggiorna-profilo/esperienze-lavorative/_id" */))
const _a8af8142 = () => interopDefault(import('../pages/gruppo/modifica-post/_id.vue' /* webpackChunkName: "pages/gruppo/modifica-post/_id" */))
const _222313e6 = () => interopDefault(import('../pages/aziende-cosmetiche/_id/index.vue' /* webpackChunkName: "pages/aziende-cosmetiche/_id/index" */))
const _d6a9e826 = () => interopDefault(import('../pages/centri-estetici/_id/index.vue' /* webpackChunkName: "pages/centri-estetici/_id/index" */))
const _4438db88 = () => interopDefault(import('../pages/chat/_id.vue' /* webpackChunkName: "pages/chat/_id" */))
const _15579ad6 = () => interopDefault(import('../pages/estetiste/_id/index.vue' /* webpackChunkName: "pages/estetiste/_id/index" */))
const _2db4e49c = () => interopDefault(import('../pages/gruppi-di-acquisto/_slug/index.vue' /* webpackChunkName: "pages/gruppi-di-acquisto/_slug/index" */))
const _29bf7ba6 = () => interopDefault(import('../pages/marchi/_id/index.vue' /* webpackChunkName: "pages/marchi/_id/index" */))
const _e90542d6 = () => interopDefault(import('../pages/posts/_id.vue' /* webpackChunkName: "pages/posts/_id" */))
const _28c5e8ca = () => interopDefault(import('../pages/scuole/_id/index.vue' /* webpackChunkName: "pages/scuole/_id/index" */))
const _25489c7c = () => interopDefault(import('../pages/aziende-cosmetiche/_id/immagine-copertina/index.vue' /* webpackChunkName: "pages/aziende-cosmetiche/_id/immagine-copertina/index" */))
const _f8503fd4 = () => interopDefault(import('../pages/aziende-cosmetiche/_id/immagine-profilo/index.vue' /* webpackChunkName: "pages/aziende-cosmetiche/_id/immagine-profilo/index" */))
const _c70afdd6 = () => interopDefault(import('../pages/centri-estetici/_id/immagine-copertina/index.vue' /* webpackChunkName: "pages/centri-estetici/_id/immagine-copertina/index" */))
const _433c16ef = () => interopDefault(import('../pages/centri-estetici/_id/immagine-profilo/index.vue' /* webpackChunkName: "pages/centri-estetici/_id/immagine-profilo/index" */))
const _c8ac1f36 = () => interopDefault(import('../pages/estetiste/_id/diploma.vue' /* webpackChunkName: "pages/estetiste/_id/diploma" */))
const _8f8250a2 = () => interopDefault(import('../pages/gruppi-di-acquisto/_slug/acquisto-annullato/index.vue' /* webpackChunkName: "pages/gruppi-di-acquisto/_slug/acquisto-annullato/index" */))
const _0e7a2f5f = () => interopDefault(import('../pages/gruppi-di-acquisto/_slug/acquisto-completato/index.vue' /* webpackChunkName: "pages/gruppi-di-acquisto/_slug/acquisto-completato/index" */))
const _5ddd5bf6 = () => interopDefault(import('../pages/gruppi-di-acquisto/_slug/redirect/index.vue' /* webpackChunkName: "pages/gruppi-di-acquisto/_slug/redirect/index" */))
const _e8d9ba56 = () => interopDefault(import('../pages/marchi/_id/immagine-copertina/index.vue' /* webpackChunkName: "pages/marchi/_id/immagine-copertina/index" */))
const _20dbaea2 = () => interopDefault(import('../pages/marchi/_id/immagine-profilo/index.vue' /* webpackChunkName: "pages/marchi/_id/immagine-profilo/index" */))
const _59103518 = () => interopDefault(import('../pages/scuole/_id/immagine-copertina/index.vue' /* webpackChunkName: "pages/scuole/_id/immagine-copertina/index" */))
const _36a4efb2 = () => interopDefault(import('../pages/scuole/_id/immagine-profilo/index.vue' /* webpackChunkName: "pages/scuole/_id/immagine-profilo/index" */))
const _85e29296 = () => interopDefault(import('../pages/punto-estetico/_/index.vue' /* webpackChunkName: "pages/punto-estetico/_/index" */))
const _4369c890 = () => interopDefault(import('../pages/comunicazioni/_/index.vue' /* webpackChunkName: "pages/comunicazioni/_/index" */))
const _0d575652 = () => interopDefault(import('../pages/centri-estetici/_/index.vue' /* webpackChunkName: "pages/centri-estetici/_/index" */))
const _06cbfc0e = () => interopDefault(import('../pages/_/index.vue' /* webpackChunkName: "pages/_/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/abbonamenti",
    component: _6a3d4224,
    name: "abbonamenti"
  }, {
    path: "/abusive",
    component: _5e741bd3,
    name: "abusive"
  }, {
    path: "/account",
    component: _111ef707,
    name: "account"
  }, {
    path: "/aggiorna-profilo",
    component: _391f4f80,
    name: "aggiorna-profilo"
  }, {
    path: "/aziende-cosmetiche",
    component: _0e0bae7b,
    name: "aziende-cosmetiche"
  }, {
    path: "/badge",
    component: _0b33a0bd,
    name: "badge"
  }, {
    path: "/borsellino",
    component: _593b1eef,
    name: "borsellino"
  }, {
    path: "/cambia-password",
    component: _e9938592,
    name: "cambia-password"
  }, {
    path: "/centri-estetici",
    component: _52e6c202,
    name: "centri-estetici"
  }, {
    path: "/cerca",
    component: _f551a7f0,
    name: "cerca"
  }, {
    path: "/chat",
    component: _2330ead4,
    name: "chat"
  }, {
    path: "/checkout",
    component: _fe9f0a7c,
    name: "checkout"
  }, {
    path: "/comunicazioni",
    component: _50b8a8e8,
    name: "comunicazioni"
  }, {
    path: "/contatti",
    component: _03685496,
    name: "contatti"
  }, {
    path: "/dashboard",
    component: _4ca3beee,
    name: "dashboard"
  }, {
    path: "/disattivazione-ed-eliminazione",
    component: _7fe5560e,
    name: "disattivazione-ed-eliminazione"
  }, {
    path: "/diventa-socio",
    component: _694d81a1,
    name: "diventa-socio"
  }, {
    path: "/estetiste",
    component: _083d7caa,
    name: "estetiste"
  }, {
    path: "/fatture",
    component: _b0d8a832,
    name: "fatture"
  }, {
    path: "/gestione-pagine",
    component: _d01d2d96,
    name: "gestione-pagine"
  }, {
    path: "/gruppi-di-acquisto",
    component: _5f3c73be,
    name: "gruppi-di-acquisto"
  }, {
    path: "/gruppo",
    component: _2a420041,
    name: "gruppo"
  }, {
    path: "/impostazioni-di-notifica",
    component: _4c041b2c,
    name: "impostazioni-di-notifica"
  }, {
    path: "/login",
    component: _024515fa,
    name: "login"
  }, {
    path: "/luogo-di-lavoro",
    component: _6d338524,
    name: "luogo-di-lavoro"
  }, {
    path: "/marchi",
    component: _021dcf7c,
    name: "marchi"
  }, {
    path: "/marketplace",
    component: _119cd225,
    name: "marketplace"
  }, {
    path: "/menu",
    component: _233bd01b,
    name: "menu"
  }, {
    path: "/notifiche",
    component: _ee92393e,
    name: "notifiche"
  }, {
    path: "/pannello-amministrazione",
    component: _27a6e524,
    name: "pannello-amministrazione"
  }, {
    path: "/password-reset",
    component: _b221e8ce,
    name: "password-reset"
  }, {
    path: "/profilo",
    component: _221cc70d,
    name: "profilo"
  }, {
    path: "/punto-estetico",
    component: _4cb0df25,
    name: "punto-estetico"
  }, {
    path: "/ricarica-borsellino",
    component: _214bc790,
    name: "ricarica-borsellino"
  }, {
    path: "/scuole",
    component: _c9f6d542,
    name: "scuole"
  }, {
    path: "/test-estetista",
    component: _d0caea66,
    name: "test-estetista"
  }, {
    path: "/aggiorna-profilo/biografia",
    component: _318c503e,
    name: "aggiorna-profilo-biografia"
  }, {
    path: "/aggiorna-profilo/diploma",
    component: _42f521d3,
    name: "aggiorna-profilo-diploma"
  }, {
    path: "/aggiorna-profilo/immagine-copertina",
    component: _aa9b943c,
    name: "aggiorna-profilo-immagine-copertina"
  }, {
    path: "/aggiorna-profilo/immagine-profilo",
    component: _7cf6ca08,
    name: "aggiorna-profilo-immagine-profilo"
  }, {
    path: "/aggiorna-profilo/specializzazione",
    component: _701710aa,
    name: "aggiorna-profilo-specializzazione"
  }, {
    path: "/gruppo/nuovo-post",
    component: _fb51aee0,
    name: "gruppo-nuovo-post"
  }, {
    path: "/aggiorna-profilo/certificati/nuovo",
    component: _01b555fe,
    name: "aggiorna-profilo-certificati-nuovo"
  }, {
    path: "/aggiorna-profilo/esperienze-lavorative/nuovo",
    component: _7da5e3e3,
    name: "aggiorna-profilo-esperienze-lavorative-nuovo"
  }, {
    path: "/pannello-amministrazione/confestetica/gestione-utenti",
    component: _c7911b28,
    name: "pannello-amministrazione-confestetica-gestione-utenti"
  }, {
    path: "/pannello-amministrazione/confestetica/richieste-iscrizione",
    component: _29b46e20,
    name: "pannello-amministrazione-confestetica-richieste-iscrizione"
  }, {
    path: "/pannello-amministrazione/confestetica/richieste-iscrizione-aziende",
    component: _5b843e4d,
    name: "pannello-amministrazione-confestetica-richieste-iscrizione-aziende"
  }, {
    path: "/pannello-amministrazione/confestetica/segnalazioni",
    component: _5b1b2894,
    name: "pannello-amministrazione-confestetica-segnalazioni"
  }, {
    path: "/pannello-amministrazione/gruppo/gestione-utenti",
    component: _41de271b,
    name: "pannello-amministrazione-gruppo-gestione-utenti"
  }, {
    path: "/pannello-amministrazione/gruppo/richieste-iscrizione",
    component: _6ebe4ed1,
    name: "pannello-amministrazione-gruppo-richieste-iscrizione"
  }, {
    path: "/",
    component: _425e2b69,
    name: "index"
  }, {
    path: "/aggiorna-profilo/certificati/:id",
    component: _2b9bcd34,
    name: "aggiorna-profilo-certificati-id"
  }, {
    path: "/aggiorna-profilo/esperienze-lavorative/:id",
    component: _a691dfce,
    name: "aggiorna-profilo-esperienze-lavorative-id"
  }, {
    path: "/gruppo/modifica-post/:id?",
    component: _a8af8142,
    name: "gruppo-modifica-post-id"
  }, {
    path: "/aziende-cosmetiche/:id",
    component: _222313e6,
    name: "aziende-cosmetiche-id"
  }, {
    path: "/centri-estetici/:id",
    component: _d6a9e826,
    name: "centri-estetici-id"
  }, {
    path: "/chat/:id",
    component: _4438db88,
    name: "chat-id"
  }, {
    path: "/estetiste/:id",
    component: _15579ad6,
    name: "estetiste-id"
  }, {
    path: "/gruppi-di-acquisto/:slug",
    component: _2db4e49c,
    name: "gruppi-di-acquisto-slug"
  }, {
    path: "/marchi/:id",
    component: _29bf7ba6,
    name: "marchi-id"
  }, {
    path: "/posts/:id?",
    component: _e90542d6,
    name: "posts-id"
  }, {
    path: "/scuole/:id",
    component: _28c5e8ca,
    name: "scuole-id"
  }, {
    path: "/aziende-cosmetiche/:id?/immagine-copertina",
    component: _25489c7c,
    name: "aziende-cosmetiche-id-immagine-copertina"
  }, {
    path: "/aziende-cosmetiche/:id?/immagine-profilo",
    component: _f8503fd4,
    name: "aziende-cosmetiche-id-immagine-profilo"
  }, {
    path: "/centri-estetici/:id?/immagine-copertina",
    component: _c70afdd6,
    name: "centri-estetici-id-immagine-copertina"
  }, {
    path: "/centri-estetici/:id?/immagine-profilo",
    component: _433c16ef,
    name: "centri-estetici-id-immagine-profilo"
  }, {
    path: "/estetiste/:id/diploma",
    component: _c8ac1f36,
    name: "estetiste-id-diploma"
  }, {
    path: "/gruppi-di-acquisto/:slug?/acquisto-annullato",
    component: _8f8250a2,
    name: "gruppi-di-acquisto-slug-acquisto-annullato"
  }, {
    path: "/gruppi-di-acquisto/:slug?/acquisto-completato",
    component: _0e7a2f5f,
    name: "gruppi-di-acquisto-slug-acquisto-completato"
  }, {
    path: "/gruppi-di-acquisto/:slug?/redirect",
    component: _5ddd5bf6,
    name: "gruppi-di-acquisto-slug-redirect"
  }, {
    path: "/marchi/:id/immagine-copertina",
    component: _e8d9ba56,
    name: "marchi-id-immagine-copertina"
  }, {
    path: "/marchi/:id/immagine-profilo",
    component: _20dbaea2,
    name: "marchi-id-immagine-profilo"
  }, {
    path: "/scuole/:id/immagine-copertina",
    component: _59103518,
    name: "scuole-id-immagine-copertina"
  }, {
    path: "/scuole/:id/immagine-profilo",
    component: _36a4efb2,
    name: "scuole-id-immagine-profilo"
  }, {
    path: "/punto-estetico/*",
    component: _85e29296,
    name: "punto-estetico-all"
  }, {
    path: "/comunicazioni/*",
    component: _4369c890,
    name: "comunicazioni-all"
  }, {
    path: "/centri-estetici/*",
    component: _0d575652,
    name: "centri-estetici-all"
  }, {
    path: "/*",
    component: _06cbfc0e,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
