
export default function ({ $auth }) {

    if (!$auth.loggedIn) {
        return
    }
    if(process.client) {
        window.customerlySettings.user_id = $auth.user.id;
        window.customerlySettings.email = $auth.user.email;
        window.customerlySettings.name= $auth.user.name;
        customerly('update');
    }
    // $auth.fetchUser.then(user => {
    //     console.log(user)
    // })
}