<template>
    <div class="card text-center">
        <div class="card-header card-header-sm  d-flex justify-content-center align-items-center">
            <i class="cf-back absolute p-3" style="left: 0;" @click.prevent="previousStep()"></i>
            {{ $t('Prenotazioni telefoniche') }}
        </div>
        <div class="card-body">
            <div class="mt-5">
                <img alt="step-05" class="w-100 icon" src="~/assets/images/steps/step-05.png">
            </div>
            <div class="mt-3">
                <div class="title">{{ $t('Dove possono chiamarti i tuoi clienti?')}}</div>
            </div>
            <div class="description mt-3">
                {{ $t('E\' importante scrivere il numero di telefono corretto, su quel numero chiameranno i tuoi clienti.')}}
            </div>
            <input type="number" class="form-control form-control-sm" v-model="main_phone_number">
        </div>
        <div class="card-footer">
            <button class="btn btn-brand w-100" @click.prevent="nextStep()">
                {{ $t('Avanti') }}
            </button>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions, mapMutations } from 'vuex'
    import { mapFields } from 'vuex-map-fields'

    export default {

        computed: {
            ...mapState([
                'account',
                'profile-wizard'
            ]),
            ...mapFields('account', [
                'account.main_phone_number'
            ])
        },

        props: ['type'],

        methods: {
            ...mapActions({
               'updateMainPhoneNumber': 'account/updateMainPhoneNumber'
            }),
            ...mapMutations({
                'setStep': 'work-experience/SET_STEP',
                'setAccountType': 'user/SET_ACCOUNT_TYPE'
            }),
            previousStep() {
                this.setStep('specialisation')
            },
            nextStep() {
                this.updateMainPhoneNumber(this.main_phone_number).then(() => {
                    this.setStep('account-whatsapp-number')
                }).catch(() => {

                })
            }
        },

        mounted () {

        }
    }
</script>