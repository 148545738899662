
export default function ({ store, redirect }) {
  store.app.router.beforeResolve((to, from, next) => {

    let redirects = require('../redirects.json')

    const myRedirect = redirects.find(r => r.from === to.path)

    if (myRedirect) {
      redirect(myRedirect.to)
    } else {
      next()
    }
  })
}