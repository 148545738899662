import Vue from 'vue'
import VueTable from 'vuetable-2'

Vue.use(VueTable)

export default ({ app }, inject) => {

     // return {
     //     httpOptions: {
     //         headers: {
     //             'Authorization': app.$auth.$storage.getUniversal('_token.local')
     //         }
     //     }
     // }
}