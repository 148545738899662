<template>
    <div class="text-center d-flex flex-column">
        <slot name="card-header">
            <div class="card-header card-header-sm d-flex justify-content-center align-items-center">
                <i class="cf-back absolute p-3" style="left: 0;" @click.prevent="previousStep()"></i>
                <span>{{ $t('Conferma dati') }}</span>
            </div>
        </slot>
        <div class="card-body d-lg-flex flex-column ">

            <div class="mt-3 title">
                <strong v-if="account.id">{{ $t('Ti stai collegando a: ')}}</strong>
                <strong v-else>{{ $t('Verifica che i dati siano corretti') }}</strong>
            </div>

            <div class="mt-3 d-flex justify-content-center">
                <account-image :account="account" size="100"></account-image>
            </div>

            <div class="mt-3">
                <strong class="title">{{ account.name }}</strong>
                <div v-if="account.vat_id">{{ $t('P. IVA ')}} {{ account.vat_id }}</div>
                <div>{{ account.address }}</div>
            </div>
            <hr>
            <div class="description mt-3 text-left">
                <div v-if="owner && account.type !== 'school'" class="font-size-sm text-grey d-flex justify-content-between align-items-center">
                    <label for="switch" class="mb-1">{{ $t('Dichiaro di essere titolare di ') }} <strong> {{ account.name }} </strong></label>
                    <div class="switch switch">
                        <input id="switch" type="checkbox" v-model="accept_ownership" class="switch-sm"/>
                        <label for="switch" class="m-0" style="font-size: 15px"></label>
                    </div>
                </div>
                <div v-else-if="account.type === 'school'" class="font-size-sm text-grey d-flex justify-content-between align-items-center">
                    <label for="school_switch" class="mb-1">{{ $t('Dichiaro di aver studiato presso ') }} <strong> {{ account.name }} </strong></label>
                    <div class="switch">
                        <input id="school_switch" type="checkbox" v-model="accept_ownership" class="switch"/>
                        <label for="school_switch" class="m-0" style="font-size: 15px"></label>
                    </div>
                </div>
            </div>
            <button class="btn btn-brand mt-3 w-100" @click.prevent="confirmData()" :disabled="(owner || account.type === 'school') && !accept_ownership">
                {{ $t('Conferma') }}
            </button>
        </div>
    </div>
</template>

<script>

    import { mapState, mapActions } from 'vuex'
    import { mapFields } from 'vuex-map-fields'

    export default {

        props: ['account', 'owner', 'confirmCallback', 'set-step', 'work-experience'],

        data () {
            return {
                accept_ownership: false
            }
        },

        computed: {
            ...mapState({
                'user': ({ register }) => register.data
            })
        },

        methods: {
            previousStep() {
                if(this.account.id) {
                    this.setStep('search-accounts')
                } else {
                    this.setStep('account-address')
                }
            },
            nextStep(account) {
                this.confirmCallback(account);
                this.$nuxt.$emit('close-account-modal')
            },
            confirmData() {

                if (!this.account.id) {

                    this.$nuxt.$loading.start()

                    this.$axios.post('api/accounts', {...this.account, work_experience: this.workExperience, owner: this.owner})
                        .then(res => res.data)
                        .then((account) => {

                            this.$nuxt.$loading.finish()
                            this.nextStep(account)
                        }).catch((error) => {
                            this.$nuxt.$loading.finish()
                        })
                } else {
                    if(this.workExperience) {
                        this.$nuxt.$loading.start()
                        this.$axios.post('api/accounts/' + this.account.id + '/connect', { work_experience: this.workExperience, owner: this.owner })
                            .then(res => res.data)
                            .then((account) => {
                                this.$nuxt.$loading.finish()
                                this.nextStep(account)
                            }).catch(() => {
                                this.$nuxt.$loading.finish()
                            })
                    } else {
                        this.nextStep(this.account)
                    }
                }
            },

        },
        created () {
            this.$nuxt.$on('login-previous-step', () => {
                if (this.user.profile_type === 'not_beautician') {
                    this.setStep('search-accounts')
                } else {
                    this.setStep('type')
                }
            })
        }
    }
</script>