<template>
    <div>
        <div class="d-flex" @click.prevent="readNotification(notification)">
            <div v-if="notification.sender && !article && !communication" class="mr-1">
                <!--<img alt="gruppo_1" src="~/assets/images/icons/notifications/gruppo_1.svg" style="border-radius: 50%; width: 65px">-->
                <user-image :user="notification.sender" size="65">
                    <template slot="profile-rank">
                        <div class="notification-icon notification-green d-flex justify-content-center align-items-center bottom right absolute" v-if="notification.type.indexOf('NewGroupPostComment') !== -1">
                            <img alt="post-comment" src="~/assets/images/icons/notifications/post-comment.svg">
                        </div>
                        <div class="notification-icon notification-green d-flex justify-content-center align-items-center bottom right absolute" v-else-if="notification.type.indexOf('NewGroupPost') !== -1">
                            <img alt="post-link" v-if="notification.data.hasUrls" src="~/assets/images/icons/notifications/post-link.svg">
                            <img alt="post-image" v-else-if="notification.data.hasImages" src="~/assets/images/icons/notifications/post-image.svg">
                            <img alt="post" v-else src="~/assets/images/icons/notifications/post.svg" >
                        </div>
                        <div class="notification-icon notification-brand d-flex justify-content-center align-items-center bottom right absolute" v-else-if="notification.type.indexOf('PostLiked') !== -1">
                            <img alt="like" src="~/assets/images/icons/notifications/like.svg">
                        </div>
                        <div class="notification-icon notification-brand d-flex justify-content-center align-items-center bottom right absolute" v-else-if="notification.type.indexOf('CommentLiked') !== -1">
                            <img alt="like" src="~/assets/images/icons/notifications/like.svg">
                        </div>
                        <div class="notification-icon notification-yellow d-flex justify-content-center align-items-center bottom right absolute" v-else-if="notification.type.indexOf('NewJoinGroupRequest') !== -1">
                            <img alt="gruppo-2" src="~/assets/images/icons/gruppo-2.svg">
                        </div>
                        <div class="notification-icon notification-blue d-flex justify-content-center align-items-center bottom right absolute" v-else-if="notification.type.indexOf('NewConversationMessage') !== -1">
                            <img alt="messenger-w" src="~/assets/images/icons/messenger-w.svg">
                        </div>
                    </template>
                </user-image>
            </div>
            <div v-else-if="article || communication">
                <div class="d-flex relative">
                    <img class="profile-img" alt="Confestetica" style="min-width: 65px;max-width: 65px; height: 65px; object-fit: cover" :src="communication ? require('~/assets/images/notifications/comunicati.png') : require('~/assets/images/notifications/punto-estetico.png')"/>
                    <div class="notification-icon notification-green d-flex justify-content-center align-items-center bottom right absolute" v-if="article || communication">
                        <img alt="bell-f" src="~/assets/images/icons/bell-f.svg">
                    </div>
                </div>
            </div>
            <div v-else-if="groupBuy && notification.data.product && notification.data.product.thumbnail_res">
                <div class="d-flex relative">
                    <img class="profile-img" alt="Confestetica" style="min-width: 65px;max-width: 65px; height: 65px; object-fit: cover" :src="notification.data.product.thumbnail_res['medium']"/>
                    <div class="notification-icon notification-green d-flex justify-content-center align-items-center bottom right absolute" v-if="article || communication">
                        <img alt="bell-f" src="~/assets/images/icons/bell-f.svg">
                    </div>
                </div>
            </div>
            <div class="ml-2">
                <!-- Titles -->
                <div v-if="notification.type.indexOf('NewGroupPostComment') !== -1">
                    <div v-if="notification.data.hasUrls">
                        <b>{{ notification.sender.name }}</b> {{ $t('ha commentato il tuo') }} <b>{{ $t('link')}}</b>
                    </div>
                    <div v-else-if="notification.data.hasImages">
                        <b>{{ notification.sender.name }}</b> {{ $t('ha commentato la tua') }} <b>{{ $t('foto')}}</b>
                    </div>
                    <div v-else>
                        <b>{{ notification.sender.name }}</b> {{ $t('ha commentato il tuo') }} <b>{{ $t('post')}}</b>
                    </div>
                </div>
                <div v-else-if="notification.type.indexOf('NewGroupPost') !== -1">
                    <div v-if="notification.data.hasUrls">
                        <b>{{ notification.sender.name }}</b> {{ $t('ha pubblicato un') }} <b>{{ $t('link')}}</b>
                    </div>
                    <div v-else-if="notification.data.hasImages">
                        <b>{{ notification.sender.name }}</b> {{ $t('ha pubblicato una') }} <b>{{ $t('foto')}}</b>
                    </div>
                    <div v-else>
                        <b>{{ notification.sender.name }}</b> {{ $t('ha pubblicato un nuovo') }} <b>{{ $t('post')}}</b>
                    </div>
                </div>
                <div v-else-if="notification.type.indexOf('PostLiked') !== -1">
                    <div v-if="notification.data.hasUrls">
                        A <b>{{ notification.sender.name }}</b> {{ $t('piace il tuo') }} <b>{{ $t('link')}}</b>
                    </div>
                    <div v-else-if="notification.data.hasImages">
                        A <b>{{ notification.sender.name }}</b> {{ $t('piace la tua') }} <b>{{ $t('foto')}}</b>
                    </div>
                    <div v-else>
                        A <b>{{ notification.sender.name }}</b> {{ $t('piace il tuo') }} <b>{{ $t('post')}}</b>
                    </div>
                </div>
                <div v-else-if="notification.type.indexOf('CommentLiked') !== -1">
                    A <b>{{ notification.sender.name }}</b> {{ $t('piace il tuo') }} <b>{{ $t('commento')}}</b>
                </div>
                <div v-else-if="notification.type.indexOf('NewJoinGroupRequest') !== -1">
                    <b>{{ notification.sender.name }}</b> {{ $t('ha richiesto di entrare nel gruppo') }}
                </div>
                <div v-else><b>{{ notification.data.title }}</b></div>
                <!-- End Titles -->

                <!-- Messages -->
                <div v-if="notification.type.indexOf('NewJoinGroupResponse') !== -1">
                    {{ $t('La tua richiesta di iscrizione al') }} <b>{{ $t('gruppo Estetiste Professioniste Confestetica') }}</b> {{ $t('è stata inoltrata. Riceverai una notifica non appena sarà approvata.') }}
                </div>
                <div v-else-if="notification.type.indexOf('GroupRequestAccepted') !== -1">
                    {{ $t('La tua richiesta di iscrizione al') }} <b>{{ $t('gruppo Estetiste Professioniste Confestetica') }}</b> {{ $t('è stata approvata. Ora puoi pubblicare contenuti e scrivere commenti in questo gruppo.') }}
                </div>
                <div v-else-if="notification.type.indexOf('NewJoinGroupRequest') !== -1">
                </div>
                <div v-else>
                    {{ notification.data.message }}
                </div>
                <!-- End Messages -->
                <div class="text-grey notification-time">
                    {{ notification.created_at | fromNow }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import UserImage from "~/components/UserImage"

    export default {

        props: ['notification'],

        components: {
            UserImage
        },

        computed: {
            article() {
                return (this.notification.type.indexOf('NewCommunicationPost') !== -1 && this.notification.data.url.startsWith('/punto-estetico'));
            },
            communication() {
                return (this.notification.type.indexOf('NewCommunicationPost') !== -1 && this.notification.data.url.startsWith('/comunicazioni'));
            },
            groupBuy() {
                return (this.notification.data.url.startsWith('/gruppi-di-acquisto'));
            }
        },

        methods: {
            readNotification(notification) {

                this.$store.dispatch('notifications/read', notification)
                this.$router.push(notification.data.url);
            }
        },

        mounted () {

        }
    }
</script>

<style lang="scss" scoped>
    .profile-img {
        border-radius: 50%;
        &.profile-placeholder {
         border: 1px solid #d8bcd3;
        }
    }
    .bottom {
        bottom: -2px;
    }
    .right {
        right: -2px;
    }
</style>
