import Vue from "vue";
import { getField, updateField } from 'vuex-map-fields';
import _ from 'lodash'

export const state = () => ({
    filters: {
        post_id: null,
        comment_id: null
    },
    sort: {
        column: 'created_at',
        order: 'desc'
    },
    pagination: {
        page: 0,
        per_page: 50,
    },
    comments: [],
    totalCount: 0,
    infiniteScrollEnabled: true,
    processing: false
})

export const getters = {
    getField,
    GET_COMMENTS_BY_POST_ID: (state) => (postId) => {

        if(!postId) return [];

        return _.orderBy(_.filter(state.comments, (comment) => {
            return comment.post_id === postId && !comment.comment_id && !comment.post_image_id
        }), 'created_at', 'asc')
    },

    GET_COMMENTS_BY_POST_IMAGE: (state) => (image) => {

        if(!image) return [];

        return _.orderBy(_.filter(state.comments, (comment) => {
            return comment.post_image_id === image.id  && !comment.comment_id
        }), 'created_at', 'asc')
    },

    GET_COMMENTS_BY_COMMENT_ID: (state) => (commentId) => {

        if(!commentId) return [];

        return _.orderBy(_.filter(state.comments, { 'comment_id': commentId }), 'created_at', 'asc')
    }
}

export const mutations = {
    updateField,
    SET_POST_ID_FILTER (state, postId) {
        state.filters.post_id = postId
    },
    SET_COMMENT_ID_FILTER (state, commentId) {
        state.filters.comment_id = commentId
    },
    SET_FILTERS (state, filters) {
        state.filters = filters
    },
    SET_PROCESSING (state, processing) {
        state.processing = processing
    },
    SET_COMMENTS (state, comments) {
        state.comments = comments
    },
    LIKE_COMMENT (state, commentId) {
        let comment = _.find(state.comments, {id: commentId});

        if(comment) {
            Vue.set(comment, 'liked', true);
        }
    },
    INCREMENT_LIKES_COUNT (state, comment) {
        let myComment = _.find(state.comments, {id: comment.id});

        if(myComment) {
            Vue.set(myComment, 'likes_count', myComment.likes_count + 1);
        }

    },
    APPEND_COMMENT (state, comment) {

        if(!_.includes(_.map(state.comments, 'id'), comment.id)) {

            state.comments.push(comment);
        } else {
            let commentIndex = _.findIndex(state.comments, {id: comment.id});

            Vue.set(state.comments, commentIndex, comment);
        }
    },
    REMOVE_COMMENT (state, comment) {
        let commentIndex = _.findIndex(state.comments, {id: comment.id});

        if (commentIndex > -1) {
            state.comments.splice(commentIndex, 1);
        }
    },
    APPEND_COMMENTS (state, comments) {

        _.each(comments, (comment) => {
            state.comments.push(comment);
        });
    },
    SET_INFINITE_SCROLL_ENABLED (state, infiniteScrollEnabled) {
        state.infiniteScrollEnabled = infiniteScrollEnabled
    },
    SET_TOTAL_COUNT (state, totalCount) {
        state.totalCount = totalCount
    }
}

export const actions = {

    fetchById ({ commit, state}, id) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .get('/api/comments/' + id)
            .then(r => r.data)
            .then(comment => {
                commit('APPEND_COMMENT', comment)

                _.each(comment.comments, function (childComment) {
                    commit('APPEND_COMMENT', childComment)
                })

                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_LOADING', false)
                throw error;
            })
    },

    fetch ({ commit, state}, data) {

        commit('SET_PROCESSING', true);

        commit('SET_POST_ID_FILTER', data.post_id ? data.post_id : null);
        commit('SET_COMMENT_ID_FILTER', data.comment_id ? data.comment_id : null);

        return this.$axios
            .get('/api/comments', {
                params: {
                    ...state.filters,
                    ...state.sort,
                    page: data && data.page ? data.page : state.pagination.page,
                    per_page: data && data.per_page ? data.per_page : state.pagination.per_page
                }
            })
            .then(r => r.data)
            .then(response => {

                _.each(response.data, (comment) => {

                    commit('APPEND_COMMENT', comment);
                });

                commit('SET_TOTAL_COUNT', response.total);
                commit('SET_INFINITE_SCROLL_ENABLED', response.data.length >= ( data && data.per_page ? data.per_page : state.pagination.per_page));
                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },

    delete ({ commit, state}, comment) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .delete('/api/comments/' + comment.id)
            .then(r => r.data)
            .then(res => {
                commit('REMOVE_COMMENT', comment)
                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },

    likeCommentById ({ commit, state}, commentId) {
        
        commit('SET_PROCESSING', true)

        return this.$axios
            .post('/api/comments/' + commentId + '/like')
            .then(r => r.data)
            .then(comment => {
                commit('SET_PROCESSING', false)
                commit('APPEND_COMMENT', comment)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },

    appendComments({commit, state, dispatch}, comments) {

        if(!comments || !comments.length) return;

        _.each(comments, (comment) => {

            commit('APPEND_COMMENT', comment);

            if(comment.comments && comment.comments.length) {
                dispatch('appendComments', comment.comments);
            }
        });
    },
}