
import { getField, updateField } from 'vuex-map-fields';

export const state = () => ({
    workExperience: {
        account: {
            type: 'beauty-centre',
            name: '',
            email: '',
            address: '',
            route: '',
            vat_id: '',
            city_id: null,
            postal_code_id: null,
            postal_code_name: '',
            main_phone_number: '',
            whatsapp_number: ''
        },
        user_id: null,
        account_id: null,
        type: 'work',
        position: '',
        role_specialisations: [],
        current: false,
        from_day: '',
        from_month: '',
        from_year: '',
        date_from: '',
        to_day: '',
        to_month: '',
        to_year: '',
        date_to: '',
        owner: false
    },
    step: 'work-experience',
    loading: false,
    saving: false,
    processing: false,
})

export const getters = {
    getField
}

export const mutations = {
    updateField,
    SET_WORK_EXPERIENCE_USER_ID (state, userId) {
        state.workExperience.user_id = userId
    },
    SET_WORK_EXPERIENCE_ACCOUNT (state, account) {
        state.workExperience.account = account
    },
    SET_WORK_EXPERIENCE_ACCOUNT_ID (state, accountId) {
        state.workExperience.account_id = accountId
    },
    SET_WORK_EXPERIENCE_ACCOUNT_TYPE (state, type) {
        state.workExperience.account.type = type
    },
    SET_WORK_EXPERIENCE_TYPE (state, type) {
        state.workExperience.type = type
    },
    SET_ACCOUNT_NAME (state, name) {
        state.workExperience.account.name = name
    },
    SET_ACCOUNT_TYPE (state, type) {
        state.workExperience.account.type = type
    },
    RESET_ACCOUNT (state) {
        state.workExperience.account = {
            type: 'beauty-centre',
            name: '',
            email: '',
            address: '',
            route: '',
            vat_id: '',
            city_id: null,
            postal_code_id: null,
            postal_code_name: '',
            main_phone_number: '',
            whatsapp_number: ''
        }
    },
    RESET_STATE (state) {
        state.workExperience = {
            account: {
                type: 'beauty-centre',
                name: '',
                email: '',
                address: '',
                route: '',
                vat_id: '',
                city_id: null,
                postal_code_id: null,
                postal_code_name: '',
                main_phone_number: '',
                whatsapp_number: ''
            },
            user_id: null,
            account_id: null,
            type: 'work',
            position: '',
            role_specialisations: [],
            current: false,
            from_day: '',
            from_month: '',
            from_year: '',
            date_from: '',
            to_day: '',
            to_month: '',
            to_year: '',
            date_to: '',
            owner: false
        }
    },
    SET_WORK_EXPERIENCE (state, workExperience) {
        if(workExperience.id) {
            state.workExperience = workExperience
        } else {
            state.workExperience = {
                account: {
                    type: 'beauty-centre',
                    name: '',
                    email: '',
                    address: '',
                    route: '',
                    vat_id: '',
                    city_id: null,
                    postal_code_id: null,
                    postal_code_name: '',
                    main_phone_number: '',
                    whatsapp_number: ''
                },
                user_id: null,
                account_id: null,
                type: 'work',
                position: '',
                role_specialisations: [],
                current: false,
                from_day: '',
                from_month: '',
                from_year: '',
                date_from: '',
                to_day: '',
                to_month: '',
                to_year: '',
                date_to: '',
                owner: false
            }
        }
    },
    SET_ROLE_SPECIALISATIONS (state, roleSpecialisations) {
        state.workExperience.role_specialisations = roleSpecialisations
    },
    SET_WORK_EXPERIENCE_CURRENT (state, current) {
        state.workExperience.current = current
    },
    SET_TYPE (state, type) {
        state.workExperience.type = type
    },
    SET_STEP (state, step) {
        state.step = step
    },
    SET_LOADING (state, loading) {
        state.loading = loading
    },
    SET_SAVING (state, saving) {
        state.saving = saving
    },
}

export const actions = {
    
    fetchById ({ commit, state}, id) {

        commit('SET_LOADING', true)

        return this.$axios
            .get('/api/work-experiences/' + id)
            .then(r => r.data)
            .then(workExperience => {
                commit('SET_WORK_EXPERIENCE', workExperience)
                commit('SET_LOADING', false)
            })
            .catch(error => {
                commit('SET_LOADING', false)
                throw error;
            })
    },

    create ({ commit, state }, data) {

        commit('SET_SAVING', true)

        return this.$axios
            .post('/api/work-experiences', data)
            .then(r => r.data)
            .then(workExperience => {
                commit('SET_WORK_EXPERIENCE', workExperience);
                commit('SET_SAVING', false);
            })
            .catch(error => {
                commit('SET_SAVING', false);
                throw error;
            })
    },

    update ({ commit, state }, data) {

        commit('SET_SAVING', true)

        return this.$axios
            .put('/api/work-experiences/' + state.workExperience.id, data)
            .then(r => r.data)
            .then(workExperience => {
                commit('SET_SAVING', false);
                commit('SET_WORK_EXPERIENCE', workExperience)
            })
            .catch(error => {
                commit('SET_SAVING', false);
                throw error;
            })
    },

    removeImage ({commit, state}) {

        commit('SET_PROCESSING', true)

        return this.$axios.post('/api/work-experiences/' + state.workExperiences.id + '/remove-image')
            .then(certificate => {
                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },

    delete ({ commit, state}, workExperience) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .delete('/api/work-experiences/' + workExperience.id)
            .then(r => r.data)
            .then(res => {
                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },
}