
import { getField, updateField } from 'vuex-map-fields';

export const state = () => ({
    comment: {
        text: '',
        comment_id: null
    },
    loading: false,
    saving: false,
})

export const getters = {
    getField
}

export const mutations = {
    updateField,
    SET_COMMENT (state, comment) {
        state.comment = comment
    },
    SET_LOADING (state, loading) {
        state.loading = loading
    },
    SET_SAVING (state, saving) {
        state.saving = saving
    },
}

export const actions = {

    async refetch ({ state, dispatch }) {
        await dispatch('fetchById', state.comment.id)
    },

    fetchById ({ commit, state}, id) {

        commit('SET_LOADING', true)

        return this.$axios
            .get('/api/comments/' + id)
            .then(r => r.data)
            .then(comment => {
                commit('SET_COMMENT', comment)
                commit('SET_LOADING', false)
            })
            .catch(error => {
                commit('SET_LOADING', false)
                throw error;
            })
    },

    create ({ commit, state}, comment) {

        commit('SET_SAVING', true)

        return this.$axios
            .post('/api/comments', comment)
            .then(r => r.data)
            .then(comment => {
                commit('comments/APPEND_COMMENT', comment, {root: true});
                commit('SET_SAVING', false)
            })
            .catch(error => {
                commit('SET_SAVING', false)
                throw error;
            })
    },

    update ({ commit, state}, comment) {

        commit('SET_SAVING', true)

        return this.$axios
            .put('/api/comments/' + comment.id, comment)
            .then(r => r.data)
            .then(comment => {
                commit('comments/APPEND_COMMENT', comment, {root: true});
                commit('SET_SAVING', false)
            })
            .catch(error => {
                commit('SET_SAVING', false)
                throw error;
            })
    }
}