<template>
    <div>
        <b-modal id="post-images-modal" modal-class="slide-left" body-class="p-0" @shown="init" @hidden="clear" no-fade hide-header hide-footer ref="postImagesModal" static lazy>
            <div class="d-flex" :style="'height: 100vh;height: calc(var(--vh, 1vh) * 100)'">
                <div class="card border-0 news w-100"
                     :style="'padding-top:' + insets.top + 'px!important;'"
                >
                    <div class="card-header background-white card-header-sm d-flex justify-content-center align-items-center">
                        <i class="position-absolute cf-back p-3" style="left: 0" @click.prevent="$refs.postImagesModal.hide()"></i>
                        <span v-if="post.user">{{ $t('Post di ') }} {{ post.user.first_name }}</span>
                        <span v-else>{{ $t('Post') }}</span>
                    </div>
                    <div class="card-body post background-dark-gray p-0 scrollable-content">
                        <div class="card-body background-white p-0 mb-2 mt-2">
                            <div class="pb-2" style="padding: 15px">
                                <div class="d-flex post-user mb-3">
                                    <div class="d-flex flex-grow-1">
                                        <nuxt-link :to="'/estetiste/' + post.user.id + '-' + post.user.slug" v-if="post.user">
                                            <user-image class="relative mr-3" :user="post.user" size="40"></user-image>
                                        </nuxt-link>
                                        <user-image class="relative mr-3" :user="post.user" size="40" v-else></user-image>
                                        <div class="w-100 user-data">
                                            <nuxt-link class="user-name font-weight-medium" :to="'/estetiste/' + post.user.id + '-' + post.user.slug" v-if="post.user">
                                                {{ post.user.last_name + ' ' + post.user.first_name }}
                                            </nuxt-link>
                                            <div v-else>{{ $t('Utente Cancellato')}}</div>
                                            <div class="text-dark-grey font-size-base d-flex align-items-center">
                                                <profile-rank :user="post.user" :hide-text="true" class="font-weight-medium"></profile-rank>•<span class="font-size-sm no-wrap">&nbsp;{{ post.created_at | fromNow }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="pre-wrap mb-3 post-text" :class="{'text-small': post.text.length > 80}" v-if="post.text">{{ post.text }}</div>
                                <div class="d-flex post-reactions" v-if="post.likes_count || post.comments_count">
                                    <div class="mr-auto d-flex align-items-center" v-if="post.likes_count">
                                        <img alt="like-circle" src="~/assets/images/icons/like-circle.svg" class="mr-1" width="18px" height="18px">
                                        {{ post.likes_count }}
                                    </div>
                                    <a class="ml-auto cursor-pointer text-grey" v-if="post.root_comments_count" @click.prevent.stop="openCommentsModal(post)">{{ $t('Commenti')}}: {{ post.root_comments_count }}</a>
                                </div>
                            </div>
                            <div class="d-flex post-actions align-items-center justify-content-between" v-if="post.id">
                                <label class="col d-flex justify-content-center align-items-center text-grey" :class="{ liked: post.liked }" @click.prevent="">
                                    <i class="cf-like mr-2 text-grey" :class="{ 'cf-like-f liked': post.liked }"></i> {{ $t('Mi piace') }}
                                </label>
                                <label :id="'newCommentLabelPostId' + post.id" :for="'newCommentPostId'" class="col d-flex justify-content-center align-items-center text-grey" @click.prevent.stop="openCommentsModal(post)">
                                    <i class="cf-comment mr-2 text-grey"></i> {{ $t('Commenta') }}
                                </label>
                            </div>
                        </div>
                        <div :id="'imageid_' + image.id" class="card-body background-white p-0 mb-2" v-for="(image, index) in getPostImagesByPostId(post.id)">

                            <img class="w-100" v-if="image.image_res" :src="image.image_res.medium" @click.prevent.stop="openImagesGalleryModal(index)">

                            <div class="d-flex post-reactions p-2" v-if="image.likes_count || image.comments_count">
                                <div class="mr-auto d-flex align-items-center" v-if="image.likes_count">
                                    <img alt="like-circle" src="~/assets/images/icons/like-circle.svg" class="mr-1" width="18px" height="18px">
                                    {{ image.likes_count }}
                                </div>
                                <a class="ml-auto cursor-pointer text-grey" v-if="image.root_comments_count" @click.prevent="openCommentsModal(image)">{{ $t('Commenti')}}: {{ image.root_comments_count }}</a>
                            </div>

                            <div class="d-flex post-actions align-items-center justify-content-between" v-if="image.id">
                                <label class="col d-flex justify-content-center align-items-center text-grey" :class="{ liked: image.liked }" @click.prevent="likeImage(image)">
                                    <i class="cf-like mr-2 text-grey" :class="{ 'cf-like-f liked': image.liked }"></i> {{ $t('Mi piace') }}
                                </label>
                                <label :id="'newCommentLabelPostId' + image.id" :for="'newCommentPostId'" class="col d-flex justify-content-center align-items-center text-grey" @click.prevent.stop="openCommentsModal(image)">
                                    <i class="cf-comment mr-2 text-grey"></i> {{ $t('Commenta') }}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <images-gallery :post="post" ref="postImagesGallery" v-if="getPostImagesByPostId(post.id).length" :images="getPostImagesByPostId(post.id)" url="/api/post-images">
                <template slot="dropdown-menu">
                    <b-dropdown right no-caret variant="link" class="d-none d-lg-block" v-if="$auth.loggedIn">
                        <template slot="button-content">
                            <i class="cf-more"></i>
                        </template>
                        <b-dropdown-item @click.prevent>{{ $t('Salva immagine') }}</b-dropdown-item>
                        <b-dropdown-item @click.prevent>{{ $t('Segnala immagine') }}</b-dropdown-item>
                        <b-dropdown-item class="text-danger" @click.prevent="deleteImage" v-if="$auth.user.is_admin || post.user_id === $auth.user.id || group.admin">{{ $t('Elimina immagine') }}</b-dropdown-item>
                    </b-dropdown>
                    <div class="dropdown d-block d-lg-none" v-if="$auth.loggedIn">
                        <dropup-menu :bottom="50" :hide-bottom-bar="true" :show-overlay="true">
                            <template slot="menu-icon">
                                <i class="cf-more btn-link text-white"></i>
                            </template>
                            <template slot="menu-options">
                                <ul class="menu-options">
                                    <li >
                                        <i class="fa fa-bookmark-o mr-2"></i> {{ $t('Salva immagine')}}
                                    </li>
                                    <li>
                                        <i class="fa fa-flag-o mr-2"></i> {{ $t('Segnala immagine')}}
                                    </li>
                                    <li class="text-danger" @click.prevent="deleteImage" v-if="$auth.user.is_admin || post.user_id === $auth.user.id || group.admin">
                                        <i class="fa fa-trash mr-2"></i> {{ $t('Elimina immagine')}}
                                    </li>
                                </ul>
                            </template>
                        </dropup-menu>
                    </div>
                </template>

                <template slot="resource-name">
                    <nuxt-link v-if="post.user" class="title font-weight-bold" :class="{'text-white': mobileMode}" :to="'/estetiste/' + post.user.id + '-' + post.user.slug">{{ post.user.last_name }} {{ post.user.first_name }}</nuxt-link>
                    <span class="text-white" v-else>{{ $t('Utente Cancellato') }}</span>
                </template>
                <template slot="dropup-menu">
                    <dropup-menu :hide-bottom-bar="true">
                        <template slot="menu-options">
                            <ul>
                                <li @click.prevent="openInput">
                                    <i class="fa fa-photo mr-2"></i>
                                    {{ $t('Foto/video')}}
                                </li>
                                <li>
                                    <i class="cf-camera mr-2"></i>
                                    {{ $t('Fotocamera')}}</li>
                                <li>
                                    <i class="cf-friends mr-2"></i>
                                    {{ $t('Tagga persone')}}
                                </li>
                            </ul>
                        </template>
                    </dropup-menu>
                </template>
            </images-gallery>
            <comments-modal ref="commentsModal" :resource="resource" :resource-type="resourceType"></comments-modal>
        </b-modal>
    </div>
</template>

<script>

    import { mapGetters, mapState, mapActions } from 'vuex'
    import CommentsModal from "./CommentsModal";

    const bodyScrollLock = require('body-scroll-lock')

    export default {

        components: {
            CommentsModal
        },

        props: {
            post: {
                type: Object,
                default: function () {
                    return {}
                }
            }
        },

        data: function () {

            return {
                resource: this.post,
                resourceType: 'posts'
            }
        },

        computed: {
            ...mapState([
                'group',
                'insets',
                'mobileMode'
            ]),
            ...mapGetters({
                'getPostImagesByPostId': 'post-images/GET_POST_IMAGES_BY_POST_ID',
                'getUserById': 'users/GET_USER_BY_ID'
            })
        },

        methods: {

            ...mapActions({
                'likePost': 'posts/likePostId',
                'likePostImage': 'post-images/likeById',
                'refetchPost': 'posts/fetchById',
                'refetchPostImage': 'post-images/fetchById'
            }),

            init() {

            },

            clear() {
            },

            likePost() {

                this.likePost(this.post.id).then(() => {
                    this.refetchPost(this.post)
                })
            },

            likeImage(image) {
                this.likePostImage(image.id).then(() => {
                    this.refetchPostImage(image)
                })
            },

            openImagesGalleryModal(index) {
                this.$refs.postImagesGallery.showImagesGalleryModal(index)
            },

            openCommentsModal(resource) {

                this.resource = resource;

                if (resource.post_id) {
                    this.resourceType = 'post-images'
                } else {
                    this.resourceType = 'posts'
                }

                this.$refs.commentsModal.show();
            },

            show() {
                this.$refs.postImagesModal.show()
            },
        },

        mounted () {

            this.height = window.innerHeight;
        },
    }
</script>