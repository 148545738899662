<template>
    <b-modal ref="workExperiences" body-class="p-0" hide-header hide-footer no-fade class="card border-0" static lazy>
        <div class="card w-100"
             style="height: 100vh!important"
             :style="'padding-top:' + insets.top + 'px!important; padding-bottom:' + insets.bottom + 'px'"
        >
            <div class="card-header card-header-sm d-flex justify-content-center align-items-center">
                <i class="cf-delete absolute p-3" @click.prevent="$refs.workExperiences.hide()" style="left: 0;"></i>
                {{ $t('Esperienze lavorative') }}
            </div>
            <work-experiences-card :modal="true"></work-experiences-card>
        </div>
    </b-modal>
</template>

<script>

    import { mapState } from 'vuex';
    // import WorkExperiencesCard from "~/components/UserProfile/ProfileInfo/Mobile/WorkExperiencesCard";

    export default {

        // components: {
        //     WorkExperiencesCard
        // },

        computed: {
            ...mapState({
                insets: 'insets'
            }),
        },

        methods: {

            showModal() {
                this.$refs.workExperiences.show();
            },
        },

        mounted () {
            this.$nuxt.$on('close-modal', () => {
                // this.$refs.workExperiences.hide();
            })
        }
    }
</script>

<style lang="scss" scoped>
    .card {
        .card-footer {
            background-color: #f4f2f3;
        }
    }
</style>