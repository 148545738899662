const middleware = {}

middleware['act-as'] = require('../middleware/act-as.js')
middleware['act-as'] = middleware['act-as'].default || middleware['act-as']

middleware['admin'] = require('../middleware/admin.js')
middleware['admin'] = middleware['admin'].default || middleware['admin']

middleware['history'] = require('../middleware/history.js')
middleware['history'] = middleware['history'].default || middleware['history']

middleware['redirect-if-authenticated'] = require('../middleware/redirect-if-authenticated.js')
middleware['redirect-if-authenticated'] = middleware['redirect-if-authenticated'].default || middleware['redirect-if-authenticated']

middleware['redirect-to-profile-wizard'] = require('../middleware/redirect-to-profile-wizard.js')
middleware['redirect-to-profile-wizard'] = middleware['redirect-to-profile-wizard'].default || middleware['redirect-to-profile-wizard']

middleware['redirects'] = require('../middleware/redirects.js')
middleware['redirects'] = middleware['redirects'].default || middleware['redirects']

middleware['server/redirect-ssl'] = require('../middleware/server/redirect-ssl.js')
middleware['server/redirect-ssl'] = middleware['server/redirect-ssl'].default || middleware['server/redirect-ssl']

middleware['server/seo'] = require('../middleware/server/seo.js')
middleware['server/seo'] = middleware['server/seo'].default || middleware['server/seo']

export default middleware
