<template>
    <b-modal ref="fastLoginModal" modal-class="slide-up" body-class="p-0 overflow-hidden h-auto" @shown="init" no-fade hide-header hide-footer static lazy :no-close-on-esc="needEmailConfirmation" :no-close-on-backdrop="needEmailConfirmation">

        <div class="scrollable-content mr-2 ml-2 overflow-auto rounded position-relative" :style="'margin-top:' + insets.top + 'px;'">
            <login-card class="mt-2" :step.sync="step" :modal="true" :subscription-required="subscriptionRequired" :login-callback="loginCallback" v-if="!subscriptionRequired || (((step !== 'register' && step !== 'payment' && step !== 'plans') || step === 'register' && app) && (!$auth.loggedIn || $auth.loggedIn && $auth.user && !$auth.user.email_confirmed))"></login-card>
            <checkout-card :step.sync="step" :login-callback="loginCallback" v-else-if="subscriptionRequired"></checkout-card>
        </div>
    </b-modal>
</template>

<script>

    import Vue from 'vue';

    import { mapState, mapMutations } from 'vuex';
    import LoginCard from '~/components/LoginCard'
    import CheckoutCard from '~/components/CheckoutCard'


    export default {

        components: {
            LoginCard,
            CheckoutCard
        },

        data() {
            return {
                subscriptionRequired: false,
                step: 'register',
                callbackFunction: function () {
                    return false
                }
            }
        },

        computed: {
            ...mapState({
                insets: 'insets',
                app: 'app'
            }),
            needEmailConfirmation () {
                return this.$auth.loggedIn && !this.$auth.user.email_confirmed && this.$route.path != '/aggiorna-profilo';
            }
        },

        methods: {
            ...mapMutations({
                'setProfileType': 'register/SET_PROFILE_TYPE',
                'setRegisteredFrom': 'register/SET_REGISTERED_FROM'
            }),
            init() {
                this.setProfileType('user');
                this.setRegisteredFrom('fast-login')
            },
            openModal () {
                if (this.$refs.fastLoginModal) {
                    this.$refs.fastLoginModal.show();
                }
            },
            closeModal () {
                if (this.$refs.fastLoginModal) {
                    this.$refs.fastLoginModal.hide();
                }
            },
            loginCallback () {

                if (this.$auth.loggedIn) {
                    if ((!this.subscriptionRequired || (this.subscriptionRequired && this.$auth.user.subscribed || this.$auth.user.subscription_status === 'pending'))) {
                        this.callbackFunction();
                        this.closeModal()
                    } else {
                        this.step = 'payment'
                    }
                }
            }
        },

        mounted() {
            if(this.needEmailConfirmation) {
                this.openModal();
            }
        },

        created () {

            this.$nuxt.$on('close-fast-login-modal', () => {
                this.closeModal();
            })

            this.$nuxt.$on('finish-iap-transaction', () => {
                this.closeModal();
                this.$auth.fetchUser()
            })

            this.$nuxt.$on('open-fast-login-modal', (data) => {

                this.subscriptionRequired = data.subscription_required;
                this.step = data.subscription_required && (!this.app || (this.$auth.loggedIn && this.$auth.user.email_confirmed)) ? 'payment' : 'register';
                this.callbackFunction = data.callback_function;
                this.openModal();
            })
        }
    }
</script>
