<template>
    <div class="card">
        <div class="card-header card-header-sm  d-flex justify-content-center align-items-center">
            <i class="cf-back absolute p-3" style="left: 0;" @click.prevent="previousStep"></i>
            {{ $t('Specializzazione del centro') }}
        </div>
        <div class="card-body text-center p-4">
            <div class="mt-5">
                <img alt="step-04" class="w-100 icon" src="~/assets/images/steps/step-04.png">
            </div>
            <div class="p-4">
                <div class="title mt-3">
                    {{ $t('Qual è la specializzazione del tuo centro estetico?')}}
                </div>
                <div class="description mt-3">
                    {{ $t('E\' molto importante comunicare ai propri clienti la propria specializzazione primaria.') }}
                </div>

                <button class="btn btn-brand btn-transparent mr-1 w-100 d-lg-none" @click.prevent="showSelectPicker = true" v-if="!account.account.specialisation1">
                    {{ $t('Scegli specializzazione') }}
                </button>
                <span @click.prevent="showSelectPicker = true" v-else>{{account.account.specialisation1.name}}</span>

                <select class="form-control form-control-sm acumin has-label mt-3 d-lg-block d-none" v-model="specialisation_1_id">
                    <option v-for="specialisation in specialisations.specialisations" :value="specialisation.id">{{ specialisation.name}}</option>
                </select>
            </div>
        </div>
        <select-picker
                class="d-lg-none"
                :options="specialisations.specialisations"
                v-if="showSelectPicker"
                v-model="specialisation_1_id"
                :cancel-callback="hideSpecialisationForm"
                :save-callback="updateSpecialisation"
        >
            <template slot="picker-title">
                {{ $t('Specializzazioni') }}
            </template>
            <template slot="options-title">
                {{ $t('Specializzazione primaria') }}
            </template>
        </select-picker>
        <div class="card-footer d-flex">
            <button class="btn btn-brand btn-transparent mr-1 w-100" @click.prevent="nextStep">
                {{ $t('Salta') }}
            </button>
            <button class="btn btn-brand ml-1 w-100" @click.prevent="updateSpecialisation" :disabled="!specialisation_1_id">
                {{ $t('Ok') }}
            </button>
        </div>
    </div>
</template>

<script>

    import { mapMutations, mapState, mapActions } from 'vuex';
    import { mapFields } from 'vuex-map-fields';
    import SelectPicker from '~/components/SelectPicker';

    export default {

        props: ['type'],

        data: function() {
            return {
                showSelectPicker: false
            }
        },

        components: {
            SelectPicker
        },

        computed: {
            ...mapState({
                'user': 'user',
                'account': 'account',
                'workExperience': 'work-experience',
                'specialisations': 'specialisations'
            }),
            ...mapFields('account', [
                'account.specialisation_1_id'
            ]),
        },

        methods: {
            ...mapActions({
                'updateAccount': 'account/update',
                'completeWizard': 'user/completeWizard'
            }),
            ...mapMutations({
                'setStep': 'work-experience/SET_STEP'
            }),
            previousStep() {
                this.setStep('account-vat-id')
            },
            nextStep() {
                this.setStep('account-phone-number')
            },
            updateSpecialisation() {
                this.updateAccount(this.account.account).then(() => {
                    this.nextStep()
                })
            },
            closeUpdateProfile() {
                this.$router.push('/dashboard')
            }
        }
    }
</script>