<template>
    <b-modal id="certificate-modal" ref="certificateModal" modal-class="slide-up" body-class="p-0" hide-header hide-footer no-fade @shown="init" @hidden="clear">
        <upload-certificate :diploma="diploma" :modal="true" :group="group"
                :style="'height:100vh!important;height: calc(var(--vh, 1vh) * 100)!important;padding-top:' + insets.top + 'px!important; padding-bottom:' + insets.bottom + 'px'"
        >
        </upload-certificate>
    </b-modal>
</template>

<script>
    import { mapActions, mapState, mapMutations } from 'vuex'
    import UploadCertificate from '~/components/UploadCertificate/User'

    export default {

        props: {
            'diploma': {
                type: Boolean,
                default: function () {
                    return false;
                }
            },
            'group': {
                type: Boolean,
                default: function () {
                    return false;
                }
            }
        },

        components: {
            UploadCertificate
        },

        data () {
            return {
            }
        },

        computed: {
            ...mapState({
                insets: 'insets',
            }),
        },

        methods: {
            ...mapActions({
                updateUser: 'user/update',
            }),
            init() {

            },

            clear() {

            },
            showModal() {
              this.$refs.certificateModal.show();
            }
        },

        created () {
            this.$nuxt.$on('close-certificate-modal', () => {
                if (this.$refs.certificateModal) {
                    this.$refs.certificateModal.hide();
                }
            })
        },
    }
</script>
<style lang="scss" scoped>
    textarea {
        &:focus {
            outline: none
        }
    }
</style>
