
import isHTTPS from 'is-https'

const opts = {
    xForwardedProto: true,
    redirectPort: 443,
    redirectHost: undefined,
    redirectUnknown: true,
    statusCode: 307,
    forceHttps: process.env.FORCE_HTTPS === "true",
    exclude: []
}

export default function (req, res, next) {

    if (opts.forceHttps) {
        const _isHttps = isHTTPS(req, opts.xForwardedProto)
        const shouldRedirect = _isHttps === false || (opts.redirectUnknown && _isHttps === null)

        if (shouldRedirect) {
            const _redirectURL = 'https://' + (opts.redirectHost || req.headers.host) + req.url
            res.writeHead(opts.statusCode, { Location: _redirectURL })
            return res.end()
        }
    }

    return next()
}