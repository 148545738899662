<template>
    <div>
        <b-modal id="diplomas-modal" modal-class="slide-left" body-class="p-0" @shown="init" no-fade hide-header hide-footer ref="diplomasModal" lazy>
            <div class="d-flex" :style="'height: 100vh;height: calc(var(--vh, 1vh) * 100)'">
                <div class="card background-gray w-100"
                     :style="'padding-top:' + insets.top + 'px!important;'"
                >
                    <div class="card-header background-gray card-header-sm d-flex justify-content-center align-items-center">
                        <i class="position-absolute cf-back p-3" style="left: 0" @click.prevent="$refs.diplomasModal.hide()"></i>
                        <span v-if="user">{{ user.first_name }} {{ user.last_name }}</span>
                        <span v-if="account">{{ account.name }}</span>
                    </div>

                    <div class="card-body scrollable-content p-0">
                        <div v-if="certificates.processing" class="text-center">
                            <i class="fa fa-spin">
                                <img alt="loading" src="~/assets/images/icons/loading.svg" width="36px">
                            </i>
                        </div>
                        <div class="bg-white p-2" v-if="!certificates.processing && user">
                            <i class="cf-like fa fa-rotate-180 liked text-grey"></i>
                            {{ getRejectedDiplomasCount(user.id, 'App\\User') }} {{ $t('diplomi rifiutati') }}
                        </div>
                        <div class="bg-white p-2" v-if="!certificates.processing && account">
                            <i class="cf-like fa fa-rotate-180 liked text-grey"></i>
                            {{ getRejectedDiplomasCount(account.id, 'App\\Account') }} {{ $t('diplomi rifiutati') }}
                        </div>
                        <hr class="m-0" v-if="!certificates.processing"/>
                        <div v-if="user">
                            <div v-for="diploma in getDiplomas(user.id, 'App\\User')" :key="diploma.id">
                                <diploma :diploma="diploma" :modal="true"></diploma>
                                <div class="background-gray divider"></div>
                            </div>
                        </div>
                        <div v-if="account">
                            <div v-for="diploma in getDiplomas(account.id, 'App\\Account')" :key="diploma.id">
                                <diploma :diploma="diploma" :modal="true"></diploma>
                                <div class="background-gray divider"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>

    import { mapGetters, mapState, mapActions } from 'vuex'
    import Diploma from '~/components/Diploma';

    export default {

        components: {
            Diploma
        },

        props: {
            user: {
                type: Object,
                default: function () {
                    return null
                }
            },
            account: {
                type: Object,
                default: function () {
                    return null
                }
            }
        },

        data: function () {

            return {
            }
        },

        computed: {
            ...mapState([
                'insets',
                'certificates'
            ]),
            ...mapGetters({
                getDiplomas: 'certificates/getDiplomasForEntity',
                getRejectedDiplomasCount: 'certificates/getRejectedDiplomasCountForEntity'
            }),

            entity() {
                return this.user ? this.user : this.account
            },
        },

        methods: {

            ...mapActions({

            }),

            show() {
                this.$refs.diplomasModal.show()
            },

            init() {

                this.$store.dispatch('certificates/fetchAll', {entityId: this.user ? this.user.id : this.account.id, entityType: this.user ? 'user' : 'account', status: 'rejected'});
            },
        },

        mounted () {

            this.height = window.innerHeight;
        },
    }
</script>

<style lang="scss" scoped>
    .divider {
        width: 100%;
        height: 1rem;
    }
</style>