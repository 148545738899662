<template>
    <b-modal :ref="modalName" modal-class="slide-up" body-class="p-0" hide-header hide-footer no-fade class="card card-modal border-0" @shown="setStep()" static>
        <div class="w-100"
             :style="'height: 100vh!important;height: calc(var(--vh, 1vh) * 100)!important;padding-top:' + insets.top + 'px!important; padding-bottom:' + insets.bottom + 'px'"
        >
        <!--<div class="card-header card-header-sm background-brand d-flex justify-content-center align-items-center text-white">-->
            <!--<i class="cf-delete absolute" @click.prevent="setStep('work-experience')" style="left: 16px" v-if="workExperience.step == 'intro'"></i>-->
            <!--<i class="cf-back absolute" @click.prevent="previousStep()" style="left: 16px" v-else></i>-->
            <!--{{ $t('Cerca centro estetico') }}-->
        <!--</div>-->
        <!--<div class="card-body d-flex flex-column p-4">-->
            <working-place ref="workExperience" :modal="true" :type="type" :account-type="accountType"></working-place>
        <!--</div>-->
        <!--<div class="card-footer d-flex">-->
            <!--<div v-if="workExperience.step == 'complete'" class="w-100">-->
                <!--<button class="btn btn-brand w-100">{{ $t('Esci') }}</button>-->
            <!--</div>-->
            <!--<div v-else class="w-100">-->
                <!--<button class="btn btn-brand w-100">{{ $t('Avanti') }}</button>-->
            <!--</div>-->
        <!--</div>-->
        </div>
    </b-modal>
</template>

<script>

    import { mapState, mapActions, mapMutations } from 'vuex';
    import WorkingPlace from "~/components/WorkingPlace";

    export default {

        components: {
            WorkingPlace
        },

        props: ['type', 'account-type'],

        computed: {
            ...mapState({
                user: 'user',
                workExperience: 'work-experience',
                insets: 'insets'
            }),
            modalName() {
                return this.type + 'WorkingPlace';
            }
        },

        methods: {
            ...mapActions({
                setStep: 'profile-wizard/setStep',
            }),
            ...mapMutations({
                'resetAccount': 'work-experience/RESET_ACCOUNT',
                'setAccountType': 'work-experience/SET_ACCOUNT_TYPE',
                'resetWorkExperience': 'work-experience/RESET_STATE',
                'setWorkExperience': 'work-experience/SET_WORK_EXPERIENCE',
                'setWorkExperienceType': 'work-experience/SET_WORK_EXPERIENCE_TYPE',
                'setWorkExperienceStep': 'work-experience/SET_STEP',
                'setWorkExperienceCurrent': 'work-experience/SET_WORK_EXPERIENCE_CURRENT'
            }),
            openModal(init = true) {
                this.$refs[this.modalName].show();

                if (init) {
                    this.init();
                }
            },
            setStep() {

                this.setWorkExperienceType(this.type);

                this.setWorkExperienceStep('work-experience')
            },
            init() {

                this.resetWorkExperience();

                if (this.type === 'school') {

                    if (this.$store.state.user.user.attending_place) {
                        this.setWorkExperience(this.$store.state.user.user.attending_place)
                    } else {
                        this.setWorkExperienceCurrent(true)
                    }
                    this.setWorkExperienceStep('work-experience')

                } else {

                    if (this.$store.state.user.user.working_place) {
                        this.setWorkExperience(this.$store.state.user.user.working_place)
                    } else {
                        this.setWorkExperienceCurrent(true)
                    }
                }

                this.setWorkExperienceType(this.type)

                if (this.type === 'school') {
                    this.setWorkExperienceStep('work-experience')
                } else {
                    this.setWorkExperienceStep('intro')
                }
            }
        },

        created () {

            this.$nuxt.$on('close-experience-modal', () => {
                if (this.$refs[this.modalName]) {
                    this.$refs[this.modalName].hide();
                }
            })
        }
    }
</script>

<style lang="scss" scoped>
    .card {
        .card-footer {
            background-color: #f4f2f3;
        }
    }
</style>