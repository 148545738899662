
export default async function ({isHMR, app, store, route, params, error, redirect}) {

    if (route.params.pathMatch === 'accedi-come' && route.query.token) {

        await app.$auth.logout()

        await app.$auth.setUserToken(route.query.token)
            .then(() => {
                redirect('/dashboard')
            })
    }
}