
import { getField, updateField } from 'vuex-map-fields';

export const state = () => ({
    filters: {
        text:'',
        profile_rank: [],
        status: 'published'
    }
});

export const getters = {
    getField
};

export const mutations = {
    updateField,
    SET_FILTERS (state, filters) {
        state.filters = filters
    },
    SET_TEXT_FILTER (state, text) {
        state.filters.text = text
    }
};

export const actions = {
    setTextFilter ({commit, dispatch}, text) {
        commit('SET_TEXT_FILTER', text)
    }
};