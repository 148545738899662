<template>
    <div class="content h-100">
        <input type="file" name="image" id="image-input" accept="image/*" style="display: none" @change="loadImages" multiple/>
        <div class="card h-100" :style="'padding-top:' + insets.top + 'px;padding-bottom:' + insets.bottom + 'px'">
            <div class="card-header card-header-sm d-flex justify-content-between align-items-center p-3">
                <!--<div  class="background-brand">-->
                <a class="font-weight-normal text-primary font-size-base" @click.prevent="closeModal()"><u>{{ $t('Annulla') }}</u></a>
                <!--<nuxt-link class="font-weight-normal" to="/gruppo#posts"><u>{{ $t('Annulla') }}</u></nuxt-link>-->
                <div class="group-name font-size-lg">{{ group.group.name }}</div>
                <a href="#" class="font-weight-normal disabled-text-light font-size-base" @click.prevent="savePost()" :disabled="!editPost.text && !editPost.images.length">
                    <u>
                        <span v-if="post.id">{{ $t('Salva') }}</span>
                        <span v-else>{{ $t('Pubblica') }}</span>
                    </u>
                </a>
                <!--</div>-->
            </div>
            <div class="card-body overflow-auto" @click.prevent="focusCreatePostTextArea">
                <div class="d-flex mb-3">
                    <user-image class="mr-2" :user="$auth.user" size="44"></user-image>
                    <div class="w-100">
                        <strong>{{ $auth.user.name }}</strong>
                        <div class="profile-rank-container font-size-sm d-flex align-items-center w-100 pl-1">
                            <img alt="questionnaire-icon" v-if="$auth.user.profile_type == 'professional' && $auth.loggedIn && !$auth.user.questionnaire_completed" class="mr-1" src="~/assets/images/icons/questionnaire-icon.svg" width="16px" height="16px">
                            <profile-rank :user="$auth.user" size="small"></profile-rank>
                        </div>
                    </div>
                </div>
                <textarea id="create-post-modal-textarea"
                          :placeholder="$t('Scrivi qualcosa...')"
                          class="w-100 post-textarea"
                          :class="{'text-small': editPost.text && editPost.text.length > 80}" v-model="editPost.text"
                >
                </textarea>
                <url-meta-tags v-if="editPost.text" :text="editPost.text"></url-meta-tags>
                <images-loader :immediate-saving="!mobileMode" url="api/post-images" v-if="files || editPost.images.length"
                               :event="files" :images="editPost.images" size="small" v-model="editPost.images"
                >
                </images-loader>
            </div>
            <dropup-menu :visible-on-open="true" :fixed="true">
                <template slot="menu-title">
                    {{ $t('Aggiungi al tuo post')}}
                </template>
                <template slot="menu-actions">
                    <div class="bottom-bar-actions">
                        <i class="fa fa-photo mr-1"></i>
                        <i class="fa fa-camera mr-1"></i>
                        <i class="cf-friends"></i>
                    </div>
                </template>
                <template slot="menu-options">
                    <ul>
                        <li class="d-flex align-items-center" @click.prevent="openInput">
                            <img alt="photo-icon" class="menu-post-icon mr-3" src="~/assets/images/icons/photo-icon.svg">
                            {{ $t('Foto/video')}}
                        </li>
                        <li class="d-flex align-items-center">
                            <i class="menu-post-icon cf-camera-f mr-3" style="color: #5dabd2;"></i>
                            {{ $t('Fotocamera')}}
                        </li>
                        <li class="d-flex align-items-center">
                            <img alt="tag-f" class="menu-post-icon mr-3" src="~/assets/fonts/cf-chars/tag-f.svg">
                            {{ $t('Tagga persone')}}
                        </li>
                    </ul>
                </template>
            </dropup-menu>
        </div>
    </div>
</template>

<script>
    import { mapMutations, mapState, mapActions } from 'vuex'
    import ProfileRank from "~/components/ProfileRank";
    import autosize from "autosize"
    import _ from 'lodash'

    export default {

        props: {
          post: {
              type: Object,
              default() {
                  return {
                      type: 'group-post',
                      layout: 'group-post',
                      group_id: this.$store.state.group.group.id,
                      text: '',
                      images: [],
                      comments: [],
                      user: this.$auth.user
                  }
              }
          }
        },

        data() {
            return {
                files: null,
                editPost: {}
            }
        },

        components: {
            ProfileRank,
        },

        computed: {
            ...mapState([
                'group',
                'bottomOffset',
                'insets',
                'mobileMode'
            ])
        },

        methods: {
            ...mapMutations({
                'appendPost': 'posts/APPEND_POST'
            }),
            ...mapActions({
                'createPost': 'post/create',
                'updatePost': 'post/update'
            }),
            init() {
                autosize.update(document.querySelectorAll('textarea'));
            },
            focusCreatePostTextArea() {
                document.getElementById('create-post-modal-textarea').focus();
            },
            loadImages(e) {
                this.files = e;
            },
            openInput(isVisible) {

                if (isVisible) {

                    let image = document.getElementById("image-input")

                    image.click()
                }
            },
            closeModal() {
                this.$nuxt.$emit('close-edit-post-modal');
            },
            savePost() {

                this.$nuxt.$emit('save-post', this.editPost)

                this.editPost = {
                    type: 'group-post',
                    layout: 'group-post',
                    group_id: this.$store.state.group.group.id,
                    text: '',
                    images: [],
                    comments: [],
                    user: this.$auth.user
                }
                this.closeModal();
            },
        },

        created () {
            this.editPost = _.cloneDeep(this.post);
        },

        mounted () {

            autosize(document.querySelectorAll('textarea'));
        }
    }
</script>

<style lang="scss" scoped>

    img.menu-post-icon {
        width: 24px;
        height: 24px;
    }

    i.menu-post-icon {
        font-size: 22px
    }

    .profile-rank-container {
        border: 1px solid #d1d1d1;
        border-radius: 4px;
        padding: 4px;
        line-height: 100%;
    }

    textarea {
        border: none;
        overflow: hidden;
        &:focus {
            outline: none;
        }
    }
</style>