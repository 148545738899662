
import { getField, updateField } from 'vuex-map-fields';
import _ from 'lodash'

export const state = () => ({
    group: {},
    tab: 'about',
    loading: false,
    saving: false,
    processing: false,
})

export const getters = {
    getField,
    IS_EDITABLE_BY_USER: (state) => (user) => {

        if(!user) return false;

        return state.group.admin;
    },

    IS_JOINABLE: (state) => {

        return !state.group.joined;
    }
}

export const mutations = {
    updateField,
    SET_GROUP (state, group) {
        state.group = group
    },
    SET_GROUP_JOINED (state, joined) {
        state.group.joined = joined
    },
    SET_TAB (state, tab) {
        state.tab = tab
    },
    SET_LOADING (state, loading) {
        state.loading = loading
    },
    SET_SAVING (state, saving) {
        state.saving = saving
    },
    SET_PROCESSING (state, processing) {
        state.processing = processing
    },
}

export const actions = {

    async refetch ({ state, dispatch }) {
        await dispatch('fetchById', state.group.id)
    },

    fetchById ({ commit, state, dispatch }, id) {

        commit('SET_LOADING', true)

        return this.$axios
            .get('/api/groups/' + id)
            .then(r => r.data)
            .then(group => {

                dispatch('posts/appendPosts', group.posts, {root: true});
                // commit('users/SET_USERS', group.users, {root: true});

                commit('SET_GROUP', group)
                commit('SET_LOADING', false)
            })
            .catch(error => {
                commit('SET_LOADING', false)
                throw error;
            })
    },

    join ({ commit, state }) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .post('/api/groups/' + state.group.id + '/join')
            .then(r => r.data)
            .then(group => {
                commit('SET_GROUP_JOINED', true)
                commit('SET_LOADING', false)
            })
            .catch(error => {
                commit('SET_LOADING', false)
                throw error;
            })
    },

    leave ({ commit, state }) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .post('/api/groups/' + state.group.id + '/join')
            .then(r => r.data)
            .then(group => {
                commit('SET_GROUP_JOINED', false)
                commit('SET_LOADING', false)
            })
            .catch(error => {
                commit('SET_LOADING', false)
                throw error;
            })
    },

    setDescription ({ state, dispatch }, description) {

        dispatch('update', { description: description })
    },

    update ({ commit, state}, group) {

        commit('SET_SAVING', true)

        return this.$axios
            .put('/api/groups/' + state.group.id, group)
            .then(r => r.data)
            .then(user => {
                commit('SET_SAVING', false)
            })
            .catch(error => {
                commit('SET_SAVING', false)
                throw error;
            })
    },

    blockUser ({ commit, state}, userId) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .post('/api/groups/' + state.group.id + '/block', { user_id: userId })
            .then(r => r.data)
            .then(user => {
                commit('users/APPEND_USER', user, { root: true })
                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },

    unblockUser ({ commit, state}, userId) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .post('/api/groups/' + state.group.id + '/unblock', { user_id: userId })
            .then(r => r.data)
            .then(user => {
                commit('users/APPEND_USER', user, { root: true })
                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_SAVING', false)
                throw error;
            })
    },

    acceptUserRequest ({ commit, state}, { user }) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .post('/api/groups/' + state.group.id + '/accept-request', { user_id: user.id })
            .then(r => r.data)
            .then(user => {
                commit('users/APPEND_USER', user, { root:true })
                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },

    rejectUserRequest ({ commit, state}, { user, reason }) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .post('/api/groups/' + state.group.id + '/reject-request', { user_id: user.id, reason: reason })
            .then(r => r.data)
            .then(user => {
                commit('users/APPEND_USER', user, { root:true })
                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },

    promoteUserToAdmin ({ commit, state}, userId) {

        commit('SET_PROCESSING', true)
        return this.$axios
            .post('/api/groups/' + state.group.id + '/promote-to-admin', { user_id: userId })
            .then(r => r.data)
            .then(user => {
                commit('users/APPEND_USER', user, { root:true })
                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },

    removeUserAdmin ({ commit, state}, userId) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .post('/api/groups/' + state.group.id + '/remove-admin', { user_id: userId })
            .then(r => r.data)
            .then(user => {
                commit('users/APPEND_USER', user, { root:true })
                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    }

}