<template>
    <div class="background-white">
        <div class="card-body scrollable-content">
            <div v-if="!modal" class="font-size-xl font-weight-xbold mt-2 mb-2">{{ $t('Lavoro')}}</div>
            <div class="d-flex work-experience-preview-container align-items-center" v-if="$auth.user && $auth.user.id === user.user.id">
                <a href="#" @click.prevent="showWorkExperience({})" class="d-flex justify-content-center align-items-center mr-2">
                    <img alt="work-circle" src="~/assets/images/icons/work-circle.svg" width="40">
                </a>
                <div class="work-experience-text">
                    <a href="#" @click.prevent="showWorkExperience({})">{{ $t('Aggiungi esperienza di lavoro')}}</a>
                </div>
            </div>
            <!--<hr class="m-1" v-if="user.user.work_experiences && user.user.work_experiences.length && $auth.user && $auth.user.id === user.user.id" >-->
            <div v-for="myWorkExperience in filteredWorkExperiences" :key="myWorkExperience.id" class="show-on-hover" v-if="workExperience.id != myWorkExperience.id">
                <div class="work-experience-preview-container d-flex">
                    <div class="work-experience-image d-flex justify-content-center mr-2 cursor-pointer">
                        <img alt="work-icon-grey" src="~/assets/images/icons/work-icon-grey.svg" width="20">
                    </div>
                    <!--<account-image class="mr-3" :account="myWorkExperience.account" size="30"></account-image>-->
                    <div class="work-experience-text">
                        {{ myWorkExperience.current ? $t('Lavora presso') : $t('Ha lavorato presso ')}} <span class="font-weight-bold">{{ myWorkExperience.account.name }}</span>
                    </div>
                    <!--<div class="edit-work-experience flex-grow-2 text-right align-self-start"><a href="#" class="d-hover-inline" @click.prevent="showWorkExperience(myWorkExperience)"><i class="cf-pencil"></i> {{ $t('Edit') }}</a></div>-->
                    <div class="dropdown text-right align-self-start ml-auto" v-if="isEditable($auth.user)">
                        <dropup-menu :hide-bottom-bar="true" :show-overlay="true">
                            <template slot="menu-icon">
                                <i class="cf-more btn-link"></i>
                            </template>
                            <template slot="menu-options">
                                <ul class="menu-options">
                                    <li class="pt-2 pb-1 text-grey" ><small>{{ $t('Esperienza lavorativa')}}</small></li>
                                    <hr class="m-0">
                                    <li @click.prevent="showWorkExperience(myWorkExperience)"> {{ $t('Modifica')}}</li>
                                    <hr class="m-0">
                                    <li class="text-danger" @click.prevent="removeWorkExperience(myWorkExperience)"> {{ $t('Elimina')}}</li>
                                </ul>
                            </template>
                        </dropup-menu>
                    </div>
                </div>
                <!--<hr v-if="lastWorkExperience && (myWorkExperience.id != lastWorkExperience.id)">-->
            </div>
            <div class="work-experience-preview-container d-flex align-items-center dropdown" v-if="!modal && user.user.work_experiences.length" @click.prevent="$refs.workExperiencesModal.showModal()">
                <div class="work-experience-image d-flex align-items-center justify-content-center mr-2">
                    <i class="cf-more btn-link"></i>
                </div>
                <div>
                    <span v-if="$auth.user && $auth.user.id === user.user.id">{{ $t('Vedi le tue esperienze lavorative')}}</span>
                    <span v-else>{{ $t('Vedi dove ha lavorato ')}} {{ user.user.first_name }}</span>
                </div>
            </div>
        </div>
        <experience-modal class="steps" ref="accountModal" type="work"></experience-modal>
        <work-experiences-modal ref="workExperiencesModal" v-if="!modal"></work-experiences-modal>
    </div>
</template>

<script>
    import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
    import ExperienceModal from '~/components/UpdateProfile/Mobile/Steps/WorkingPlace'
    import WorkExperiencesModal from '~/components/UserProfile/ProfileInfo/Mobile/WorkExperiencesModal'

    import _ from 'lodash'

    export default {

        components: {
            ExperienceModal,
            WorkExperiencesModal
        },

        props: ['limit', 'modal', 'edit'],

        data: function () {

            return {
                showingWorkExperience: false,
                workExperience: {}
            }
        },

        computed: {
            ...mapState([
                'user',
                'mobileMode'
            ]),
            ...mapGetters({
                'isEditable': 'user/IS_EDITABLE_BY_USER'
            }),
            dropupMenuName() {
                return 'dropupMenu' + (this.modal ? 'Modal' : '')
            },
            filteredWorkExperiences () {
                if (this.limit) {
                    return this.user.user.work_experiences.slice(0, this.limit);
                } else {
                    return this.user.user.work_experiences;
                }
            },
            lastWorkExperience() {
                return _.last(this.filteredWorkExperiences);
            }
        },

        methods: {
            ...mapActions({
                'refetchUser': 'user/refetch',
                'deleteWorkExperience': 'work-experience/delete'
            }),
            ...mapMutations({
                'setUserTab': 'user/SET_USER_TAB',
                'resetWorkExperience': 'work-experience/RESET_STATE',
                'setWorkExperience': 'work-experience/SET_WORK_EXPERIENCE',
                'setWorkExperienceStep': 'work-experience/SET_STEP',
                'setWorkExperienceCurrent': 'work-experience/SET_WORK_EXPERIENCE_CURRENT'
            }),
            showWorkExperience (workExperience) {

                if (this.mobileMode) {

                    this.resetWorkExperience();

                    if (workExperience.id) {
                        this.setWorkExperience(workExperience)
                    }

                    this.$refs.accountModal.openModal(false);
                } else {
                    if (workExperience.id) {
                        // this.setCertificate(certificate);
                        this.$router.push('/aggiorna-profilo/esperienze-lavorative/' + workExperience.id)
                    } else {
                        // this.resetCertificate()
                        this.$router.push('/aggiorna-profilo/esperienze-lavorative/nuovo')
                    }
                }
            },
            hideWorkExperience() {
                this.showingWorkExperience = false;
                this.workExperience = {};
            },
            getWorkExperienceDateFrom(workExperience) {
                return workExperience.date_from ? this.$moment(workExperience.date_from).format('MMM YYYY') : ''
            },
            getWorkExperienceDateTo(workExperience) {
                return workExperience.date_to ? this.$moment(workExperience.date_to).format('MMM YYYY') : ''
            },
            getWorkExperiencePeriod(workExperience) {
                let dateFrom = this.$moment(workExperience.date_from);
                let dateTo = this.$moment(workExperience.date_to);

                let diff = dateTo.diff(dateFrom, 'months');

                if (diff < 18) {
                    return diff + ' ' + this.$t('mesi')
                } else {
                    return dateTo.diff(dateFrom, 'years') + ' ' + this.$t('anni');
                }

            },
            removeWorkExperience(workExperience) {

                this.$nuxt.$loading.start()

                this.deleteWorkExperience(workExperience).then(() => {

                    this.refetchUser().then(() => {

                        this.$nuxt.$loading.finish()

                        this.$notify({
                            group: 'messages',
                            type: 'success',
                            title: this.$t('Esperienza lavorativa eliminato correttamente')
                        });
                    }).catch(() => {
                        this.$nuxt.$loading.finish()
                    })
                })
            },
        },

        mounted () {

        }
    }
</script>

<style lang="scss" scoped>

    a {
        text-decoration: none;
    }

    .work-experience-preview-container {

        padding: 8px 0;

        .work-experience-image {
            min-width: 30px;
            max-width: 30px;
            height: 30px;
            margin-bottom: 0!important;

            &.image-placeholder {
                background-color: #772A79;
            }
        }
        .edit-work-experience {
            font-size: 13px;
            margin-right: -10px
        }
    }
</style>
