import { render, staticRenderFns } from "./AccountVatId.vue?vue&type=template&id=4ef7fa78&scoped=true"
import script from "./AccountVatId.vue?vue&type=script&lang=js"
export * from "./AccountVatId.vue?vue&type=script&lang=js"
import style0 from "./AccountVatId.vue?vue&type=style&index=0&id=4ef7fa78&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ef7fa78",
  null
  
)

export default component.exports