<template>
    <div class="info-card">
        <component :modal="modal" :is="BasicInformationComponent"></component>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    export default {

        props: ['modal'],

        computed: {
            ...mapState([
                'mobileMode'
            ]),
            BasicInformationComponent() {
                return () => import('~/components/UserProfile/ProfileInfo/Mobile/BasicInformationCard')
                // return () => import('~/components/UserProfile/ProfileInfo/' + ( this.mobileMode ? 'Mobile' : 'Desktop' ) +'/BasicInformationCard')
            }
        }
    }
</script>
