<template>
    <div class="text-center d-flex flex-column h-100">
        <slot name="card-header">
            <div class="card-header card-header-sm  d-flex justify-content-center align-items-center">
                <i class="cf-back absolute p-3" style="left: 0;" @click.prevent="setWorkExperienceStep('search-accounts')"></i>
                <span>{{ $t('Collegati ad un luogo di lavoro') }}</span>
            </div>
        </slot>
        <div class="card-body background-gray p-0">
            <div class="card mt-2">
                <div class="card-header card-header-sm text-left">
                    {{ $t('Scegli il tuo luogo di lavoro')}}
                </div>
                <div class="d-flex align-items-center border-bottom p-3" @click.prevent="nextStep('beauty-centre')">
                    <img alt="beauty-centre-icon-grey" class="m-0 mr-3" src="~/assets/images/icons/beauty-centre-icon-grey.svg" width="30px">
                    {{ $t('Centro estetico')}}
                    <i class="fa fa-angle-right font-size-xl text-light-grey ml-auto"></i>
                </div>
                <div class="d-flex align-items-center border-bottom p-3" @click.prevent="nextStep('school')">
                    <img alt="school-icon-grey" class="m-0 mr-3" src="~/assets/images/icons/school-icon-grey.svg" width="30px">
                    {{ $t('Scuola o ente di formazione')}}
                    <i class="fa fa-angle-right font-size-xl text-light-grey ml-auto"></i>
                </div>
                <div class="d-flex align-items-center border-bottom p-3" @click.prevent="nextStep('company')">
                    <!--<img alt="school-icon-grey" class="m-0 mr-3" src="~/assets/images/icons/school-icon-grey.svg" width="30px">-->
                    <img alt="company-icon-grey" class="m-0 mr-3" src="~/assets/images/icons/company-icon-grey.svg" width="30px">
                    {{ $t('Azienda di settore')}}
                    <i class="fa fa-angle-right font-size-xl text-light-grey ml-auto"></i>
                </div>
            </div>
        </div>
        <div></div>
        <div class="card-footer" v-if="account.account.address">
            <button class="btn btn-brand w-100" @click.prevent="confirmData()" :disabled="!account.account.address">
                <span >{{ $t('Avanti') }}</span>
            </button>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState, mapMutations } from 'vuex'
    import { mapFields } from 'vuex-map-fields'
    import AccountAddressModal from '~/components/WorkingPlace/Steps/AccountAddressModal'
    import _ from 'lodash'

    export default {
        components: {
            AccountAddressModal
        },

        props: ['account', 'account-type', 'type', 'confirmCallback', 'setStep'],

        data() {
            return {
                cities: [],
                postalCodes: [],
            }
        },

        computed: {
            ...mapState({
                'account': 'account',
            }),
            ...mapFields('account', [
                'account.address',
                'account.postal_code_id',
                'account.city_id',
                'account.latitude',
                'account.longitude'
            ])
        },

        methods: {

            nextStep(type) {

                this.$emit('update:accountType', type)
                this.setStep('search-accounts')
            }
        },

        mounted () {
        },
    }
</script>