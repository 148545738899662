
import _ from 'lodash';

const bodyScrollLock = require('body-scroll-lock')

export default async (ctx) => {
    await ctx.store.dispatch('nuxtClientInit', ctx)

    if (!ctx.app.mixins) {
        ctx.app.mixins = []
    }

    ctx.app.mixins.push({
        created() {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }
    })

    ctx.app.mixins.push({
        mounted() {

            window.addEventListener('resize', function () {
                if(!ctx.store.state.app) {
                    let vh = window.innerHeight * 0.01;
                    document.documentElement.style.setProperty('--vh', `${vh}px`);
                }
            });

            this.$root.$on('cf::dropup::show', dropupId => {
                setTimeout(() => {
                    let pages = document.querySelectorAll('body #dropupid-' + dropupId + ' .dropup-options .scrollable-content')
                    if(pages) {
                        _.each(pages, function (page) {
                            bodyScrollLock.disableBodyScroll(page)
                        })
                    }
                })
            })

            this.$root.$on('cf::dropup::hide', dropupId => {
                let pages = document.querySelectorAll('body #dropupid-' + dropupId + ' .dropup-options .scrollable-content')
                if(pages) {
                    _.each(pages, function (page) {
                        bodyScrollLock.enableBodyScroll(page)
                    })
                }
            })

            this.$root.$on('bv::modal::shown', (bvEvent, modalId) => {
                let pages = document.querySelectorAll('body #' + modalId + ' .modal-body .scrollable-content')
                if(pages) {
                    _.each(pages, function (page) {
                        bodyScrollLock.disableBodyScroll(page)
                    })
                }
            })

            this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {

                let pages = document.querySelectorAll('body #' + modalId + ' .modal-body .scrollable-content')
                if (pages) {
                    _.each(pages, function (page) {
                        bodyScrollLock.enableBodyScroll(page)
                    })
                }
            })
        }
    })
}