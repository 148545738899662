<template>
    <div class="d-flex communications notification" @click.prevent="readNotification">
        <user-image class="mr-2" :user="notification.data.user"></user-image>
        <div>
            <strong>{{ notification.data.title }}</strong>
            <div>{{ notification.data.message }}</div>
            <div><small class="text-grey d-none d-lg-block">{{ $t('Adesso')}}</small></div>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import UserImage from "~/components/UserImage"

    export default {

        props: ['notification'],

        components: {
            UserImage
        },

        methods: {
            readNotification() {
                // Set notification as read
                this.$router.push(this.notification.data.url);
            }
        },

        mounted () {

        }
    }
</script>

<style lang="scss" scoped>
    .notification {
        cursor: pointer;
    }
</style>