
import { getField, updateField } from 'vuex-map-fields';

export const state = () => ({
    data: {
        account: {
            type: 'user',
            name: '',
            vat_id: ''
        },
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        password_confirmation: '',
        profile_type: '',
        birth_date: '',
        gender: '',
        referrer_code: '',
        registered_from: 'register'
    },
    step: 'login',
    processing: false
})

export const getters = {
    getField
}

export const mutations = {
    updateField,
    SET_PROCESSING (state, processing) {
        state.processing = processing
    },
    SET_BIRTH_DATE (state, birthDate) {
        state.data.birth_date = birthDate
    },
    SET_STEP (state, step) {
        state.step = step
    },
    SET_PROFILE_TYPE (state, profileType) {
        state.data.profile_type = profileType;
    },
    SET_ACCOUNT (state, account) {
        state.data.account = account;
    },
    SET_ACCOUNT_NAME (state, accountName) {
        state.data.account.name = accountName;
    },
    SET_ACCOUNT_TYPE (state, accountType) {
        state.data.account.type = accountType;
    },
    SET_REFERRER_CODE (state, ref) {
        state.data.referrer_code = ref
    },
    SET_REGISTERED_FROM (state, type) {
        state.data.registered_from = type
    },
}

export const actions = {
    setStep({ commit }, step) {
        commit('SET_STEP', step)
    },

    processRegistration ({ commit, state}, groupId) {

        return this.$axios.post('/api/register', {...state.data, group_id: groupId})
    }
}