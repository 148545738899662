<template>
    <div :id="'dropupid-' + _uid" :class="{'dropup-fixed': fixed }">
        <div class="dropup-menu">
            <div class="dropup-overlay" v-if="showingDropUp && showOverlay" @click.prevent.stop="toggleShowDropUp"></div>
            <div @click.prevent.stop="toggleShowDropUp" class="d-flex align-items-center">
                <slot name="menu-icon" ></slot>
            </div>
            <div class="bottom-bar d-flex justify-content-between p-3" style="position: fixed!important;" @click.prevent.stop="toggleShowDropUp" v-if="!hideBottomBar" :style="'bottom: ' + (insets ? insets.bottom : 0) + 'px!important'">
                <slot name="menu-title"></slot>

                <slot name="menu-actions"></slot>
            </div>
            <div v-if="showingDropUp" class="dropup-container" :style="'bottom: ' + (insets && !fixed ? insets.bottom : 0) + 'px'">
                <div class="w-100 dropup-close d-flex justify-content-center" @click.prevent.stop="toggleShowDropUp">
                    <div class="close-icon"></div>
                </div>
                <div class="dropup-options" @click.prevent.stop="toggleShowDropUp" :style="'padding-bottom: ' + (insets && fixed ? insets.bottom : 0) + 'px'">
                    <slot name="menu-options"></slot>
                </div>
                <div class="dropup-options cancel mt-3 p-3 mb-3" v-if="!fixed" @click.prevent.stop="toggleShowDropUp">
                    <strong>{{ $t('Annulla')}}</strong>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import { mapState } from 'vuex';

    export default {

        props: {
            'visibleOnOpen': {
                type: Boolean,
                default () {
                    return false
                }
            },
            'hideBottomBar': {
                type: Boolean,
                default () {
                    return false
                }
            },
            'showOverlay': {
                type: Boolean,
                default () {
                    return false
                }
            },
            'bottom': {
                type: Number,
                default () {
                    return 0
                }
            },
            'fixed': {
                type: Boolean,
                default() {
                    return false
                }
            },
            'disabled': {
                type: Boolean,
                default() {
                    return false
                }
            }
        },

        data () {
            return {
                showingDropUp: this.visibleOnOpen,
                clicked: false,
            }
        },

        watch: {
            showingDropUp: function (val) {
                this.$store.dispatch('setDropupOpen', val)

                if(val) {
                    this.$root.$emit('cf::dropup::show', this._uid);
                } else {
                    this.$root.$emit('cf::dropup::hide', this._uid);

                }
            }
        },

        computed: {
            ...mapState([
                'insets'
            ])
        },

        methods: {
            toggleShowDropUp() {
                if (!this.disabled) {
                    this.showingDropUp = !this.showingDropUp;
                }
            }
        },

        beforeDestroy() {
            this.$store.dispatch('setDropupOpen', false)
            this.$root.$emit('cf::dropup::hide', this._uid);
        },

        mounted () {

        }
    }
</script>