<template>
    <div>
        <div>
            <div class="payment-method-input">
                <div class="d-flex">
                    <div class="mr-2">
                        <i class="fa fa-check-circle fa-lg text-primary" v-if="paymentMethod === 'stripe'"></i>
                        <i class="fa fa-circle-thin fa-lg text-grey" v-else></i>
                    </div>
                    <div class="d-flex w-100 justify-content-between" @click.prevent="paymentMethod = 'stripe'">
                        <div class="payment-method-item">
                            <span class="text-grey font-size-lg" :class="{'text-brand font-weight-bold': paymentMethod === 'stripe'}">{{ $t('Carta di credito') }}</span>
                        </div>
                        <div class="payment-method-item">
                            <img alt="payment-method-logo" class="payment-method-logo" src="~/assets/images/cards/payment-method-logo.svg" height="18px">
                        </div>
                    </div>
                </div>
            </div>
            <div v-show="paymentMethod === 'stripe'">
                <div v-if="$auth.loggedIn && $auth.user && $auth.user.default_payment_method" class="d-flex justify-content-between align-items-center mr-3 ml-3 mb-3">
                    <div v-if="$auth.user.default_payment_method.card">
                        <img class="mr-2" :src="require('~/assets/images/cards/' + getCardName($auth.user.default_payment_method.card.brand) + '.png')" height="24px">
                        <strong>{{ $auth.user.default_payment_method.card.brand }}</strong> {{ $t('termina con ')}} {{ $auth.user.default_payment_method.card.last4 }}
                    </div>
                    <u class="text-dark-grey font-size-xs" @click.prevent="openCardsModal">{{ $t('Modifica')}}</u>
                </div>
                <div v-else-if="!hideStripeFields">
                    <div class="form-group w-100" style="max-width: inherit">
                        <card-number class='has-label stripe-element card-number'
                                     ref='cardNumber'
                                     :placeholder="$t('Numero carta di credito')"
                                     :stripe="stripeApiKey"
                                     :options='stripeOptions'
                                     @change='number = $event.complete'
                        />
                    </div>
                    <div class="d-flex">
                        <div class="form-group flex-grow-1 flex-basis-0">
                            <card-expiry class='stripe-element card-expiry'
                                         ref='cardExpiry'
                                         :stripe="stripeApiKey"
                                         :options='stripeOptions'
                                         @change='expiry = $event.complete'
                            />
                        </div>
                        <div class="form-group flex-grow-1 flex-basis-0">
                            <card-cvc class='stripe-element card-cvc'
                                      ref='cardCvc'
                                      :stripe="stripeApiKey"
                                      :options='stripeOptions'
                                      @change='cvc = $event.complete'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="payment-method-input">
            <div class="d-flex">
                <div class="mr-2">
                    <i class="fa fa-check-circle fa-lg text-primary" v-if="paymentMethod === 'bank_transfer'"></i>
                    <i class="fa fa-circle-thin fa-lg text-grey" v-else></i>
                </div>
                <div class="d-flex w-100 justify-content-between" @click.prevent="paymentMethod = 'bank_transfer'">
                    <div class="payment-method-item">
                        <span class="text-grey font-size-lg" :class="{'text-brand font-weight-bold': paymentMethod === 'bank_transfer'}">{{ $t('Bonifico bancario') }}</span>
                    </div>
                    <div class="payment-method-item">
                        <img alt="sofort-logo" class="payment-method-logo" src="~/assets/images/sofort-logo.png" height="18px">
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <cards-modal ref="cardsModal"></cards-modal>
    </div>
</template>

<script>

    import { mapState, mapActions } from 'vuex';
    import { CardNumber, CardExpiry, CardCvc, createPaymentMethod } from 'vue-stripe-elements-plus'
    import CardsModal from "./CardsModal";

    export default {

        components: {
            CardsModal,
            CardNumber,
            CardExpiry,
            CardCvc
        },

        props: ['successCallback', 'hideStripeFields'],

        data () {
            let elementStyles = {
                base: {
                    color: '#403D40',
                    fontFamily: 'Acumin Pro',
                    fontSize: '20px',

                    '::placeholder': {
                        color: '#A6ACAF'
                    }
                },
                invalid: {
                    color: '#FA755A',
                    ':focus': {
                        color: '#FA755A',
                    },
                    '::placeholder': {
                        color: '#FFCCA5',
                    },
                },
            };

            let elementClasses = {
                focus: 'focus',
                empty: 'empty',
                invalid: 'invalid',
            };

            return {
                stripeApiKey: process.env.STRIPE_PUBLISHABLE_KEY || 'pk_test_nH1hcGkzXexiZBYsKY72hUXA',
                paymentMethod: 'stripe',
                showErrors: false,
                errorList: [],
                stripeOptions: {
                    style: elementStyles,
                    classes: elementClasses
                },
            }
        },

        watch: {
            'account': function() {
                if (this.account) {
                    this.first_name = this.$auth.user.first_name;
                    this.last_name = this.$auth.user.last_name;
                }
            },
            'checkout.data': {
                deep: true,
                handler: function () {
                    this.showErrors = false
                    this.errorList = []
                }
            },
            'number': function () {
                this.update()
            },
            'expiry': function () {
                this.update()
            },
            'cvc': function () {
                this.update() }
        },

        computed: {
            ...mapState({
                insets: 'insets'
            })
        },

        methods: {
            ...mapActions({
                'fetchUser': 'user/refetch',
                'savePaymentMethod': 'payment-methods/create'
            }),
            openCardsModal () {
                this.$refs.cardsModal.openModal()
            },
            getCardName(name) {
                return name.replace(' ', '-').toLowerCase();
            },
            getPaymentMethod () {

                switch(this.paymentMethod) {
                    case 'bank_transfer':
                        this.successCallback({paymentMethod: this.paymentMethod})
                        break;

                    case 'stripe':
                        if(this.$auth.loggedIn && this.$auth.user.default_payment_method) {
                            this.successCallback({paymentMethod: this.paymentMethod, source: this.$auth.user.default_payment_method})
                        } else {
                            this.$validator.validate().then(result => {

                                if (!result) return;

                                let _self = this;

                                this.$nuxt.$loading.start()

                                createPaymentMethod('card')
                                    .then((data) => {

                                        if (!data || !data.paymentMethod) {
                                            this.processing = false;
                                            this.$nuxt.$loading.finish()

                                            this.$notify({
                                                group: 'messages',
                                                type: 'error',
                                                duration: 3000,
                                                title: _self.$t('Errore durante il pagamento.')
                                            });
                                        }

                                        if (data && data.paymentMethod) {

                                            this.savePaymentMethod(data.paymentMethod).then(() => {
                                                this.$auth.fetchUser().then(() => {
                                                    this.newCard = false;

                                                    this.$nuxt.$loading.finish()

                                                    this.successCallback({paymentMethod: this.paymentMethod, source: data.paymentMethod})

                                                    this.$notify({
                                                        group: 'messages',
                                                        type: 'success',
                                                        duration: 10000,
                                                        title: _self.$t('Carta aggiunta correttamente.')
                                                    });
                                                });
                                            }).catch(() => {
                                                this.$nuxt.$loading.finish()
                                            })
                                        }
                                    })
                                    .catch(() => {
                                        this.$nuxt.$loading.finish()
                                    });
                            });
                        }
                        break;
                }
            },
            update () {
                this.complete = this.number && this.expiry && this.cvc

                // field completed, find field to focus next
                if (this.number) {
                    if (!this.expiry) {
                        this.$refs.cardExpiry.focus()
                    } else if (!this.cvc) {
                        this.$refs.cardCvc.focus()
                    }
                } else if (this.expiry) {
                    if (!this.cvc) {
                        this.$refs.cardCvc.focus()
                    } else if (!this.number) {
                        this.$refs.cardNumber.focus()
                    }
                }
                // no focus magic for the CVC field as it gets complete with three
                // numbers, but can also have four
            },
        },

        created () {
            if (this.$auth.loggedIn) {
                this.$store.dispatch('payment-methods/fetch');
            }
        }
    }
</script>

<style lang="scss" scoped>

    a {
        text-decoration: underline;
        color: inherit;
    }

    hr {
        margin: 0;
    }

    .payment-method-input {
        padding: 20px 15px;
        i {
            font-size: 24px;
        }
    }

    .payment-method-logo {
        height: 18px;
    }

    .form-group {
        margin: 0;
    }

    .stripe-element {
        padding: 20px 15px;
        border: 1px solid #F4F2F3;
    }
</style>