<template>
    <b-modal ref="updateAppModal" modal-class="slide-up d-flex align-items-center" body-class="p-0 overflow-hidden h-auto" no-fade hide-header hide-footer  no-close-on-esc no-close-on-backdrop>

        <div class="mr-3 ml-3 rounded position-relative p-3 py-4 background-white" :style="'margin-top:' + insets.top + 'px;'">
            <div class="font-weight-bold font-size-lg"> {{ $t('Nuova versione disponibile') }}</div>
            <div class="mt-2 text-grey">{{ $t('Scarica l\'ultima versione dell\'app per avere gli ultimi miglioramenti e risoluzione di problemi')}}</div>
            <div class="text-uppercase text-brand text-right mt-3 font-weight-bold" @click.prevent="openStore">{{ $t('Aggiorna') }}</div>
        </div>
    </b-modal>
</template>

<script>

    import {mapState} from 'vuex'

    export default {

        computed: {
            ...mapState([
                'platform',
                'insets'
            ])
        },

        methods: {
            openModal() {
                if(this.$refs.updateAppModal) {
                    this.$refs.updateAppModal.show();
                }
            },
            openStore () {
                switch (this.platform) {
                    case 'android':
                        location.href = 'https://play.google.com/store/apps/details?id=com.confestetica&hl=en'
                        break;
                    case 'ios':
                        location.href = 'https://apps.apple.com/it/app/confestetica/id1445127437'
                        break;
                }
            }
        },

        mounted() {

        },

        created () {

            this.$nuxt.$on('update-app', () => {
                this.openModal()
            })
        }
    }
</script>
