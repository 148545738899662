
import { getField, updateField } from 'vuex-map-fields';
import _ from 'lodash'
import Vue from "vue";

export const state = () => ({
    filters: {
        status: 'open'
    },
    sort: {
        column: 'created_at',
        order: 'desc'
    },
    pagination: {
        page: 1,
        per_page: 10,
    },
    crashReports: [],
    totalCount: 0,
    infiniteScrollEnabled: true,
    processing: false
})

export const getters = {
    getField
}

export const mutations = {
    updateField,
    SET_STATUS_FILTER (state, status) {
        state.filters.status = status
    },
    SET_FILTERS (state, filters) {
        state.filters = filters
    },
    SET_CRASH_REPORTS (state, crashReports) {
        state.crashReports = crashReports
    },
    APPEND_CRASH_REPORT (state, crashReport) {

        if (!_.includes(_.map(state.crashReports, 'id'), crashReport.id)) {

            state.crashReports.push(crashReport);
        } else {

            let myCrashReportIndex = _.findIndex(state.crashReports, {id: crashReport.id});

            if (myCrashReportIndex > -1) {
                Vue.set(state.crashReports, myCrashReportIndex, crashReport)
            }
        }
    },
    SET_INFINITE_SCROLL_ENABLED (state, infiniteScrollEnabled) {
        state.infiniteScrollEnabled = infiniteScrollEnabled
    },
    SET_PROCESSING (state, processing) {
        state.processing = processing
    },
    SET_TOTAL_COUNT (state, totalCount) {
        state.totalCount = totalCount
    }
}

export const actions = {

    fetch ({ commit, state}, data) {

        commit('SET_PROCESSING', true);

        commit('SET_STATUS_FILTER', data && data.hasOwnProperty('status') ? data.status : 'open');

        let filters = state.filters
        let sort = _.clone(state.sort)

        if(data && data.hasOwnProperty('filters')) {
            filters = data.filters;

            sort.column = data.filters.column ? sort.column : data.filters.column;
            sort.order = data.filters.order ? sort.order : data.filters.order;
        }
        return this.$axios
            .get('/api/crash-reports', {
                params: {
                    ...filters,
                    ...sort,
                    page: data && data.page ? data.page : state.pagination.page,
                    per_page: data && data.per_page ? data.per_page : state.pagination.per_page
                },
            })
            .then(r => r.data)
            .then(response => {

                _.each(response.data, (crashReport) => {

                    commit('APPEND_CRASH_REPORT', crashReport);
                });

                commit('SET_TOTAL_COUNT', response.total);
                commit('SET_INFINITE_SCROLL_ENABLED', response.data.length >= ( data && data.per_page ? data.per_page : state.pagination.per_page));
                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    }
}