<template>
    <div class="group-buy" :class="{'detail': detail}" @click.prevent="openGroupBuy">
        <div class="card-body p-0" :class="{'scrollable-content': !level }">
            <div class="group-buy-header" v-if="!showTimeline && detail">
                <h1 class="font-weight-bold font-size-extra mt-2 px-3" v-if="detail">{{ groupBuy.name }}</h1>
                <img class="header-image" :src="groupBuy.thumbnail_res.large" v-if="groupBuy.thumbnail">
            </div>

            <div class="group-buy-body p-3" v-if="!detail">
                <div class="d-flex">
                    <div>
                        <div class="group-buy-image-container">
                            <img :src="groupBuy.thumbnail_res.large" v-if="groupBuy.thumbnail">
                        </div>
                    </div>
                    <div class="group-buy-info pl-3">
                        <h1 class="font-weight-bold font-size-lg m-0">{{ groupBuy.name }}</h1>
                        <div class="mt-2 font-size-base">
                            <span class="text-dark-grey font-weight-bold" v-if="quantity">{{ $t('Prezzo:')}} <span style="color: #B12704!important">{{ quantity.unit_price.toFixed(2) }} € {{ quantity.vat_pct !== 0 ? '(' + $t('IVA esclusa')  + ')' : '' }}</span></span>
                        </div>
                    </div>
                </div>
                <div class="font-size-sm text-center mt-3">
                    <u class="font-weight-bold">{{ groupBuy.sold_quantity | toHumanReadableNumber }} {{ $t(' pezzi venduti.')}}</u> {{ $t('Arriviamo a ')}} <u class="font-weight-bold">{{ groupBuy.target_quantity | toHumanReadableNumber }}</u>
                </div>
                <div class="progress mt-2" v-if="groupBuy.target_quantity" :aria-valuenow="(groupBuy.sold_quantity * 100/ groupBuy.target_quantity).toFixed(2) + ' %'" >
                    <div class="progress-bar background-primary-green" :class="{'showing-percentage': (groupBuy.sold_quantity / groupBuy.target_quantity).toFixed(2) > 20}" role="progressbar" :style="'width: ' + (groupBuy.sold_quantity * 100 / groupBuy.target_quantity).toFixed(2) + '%'" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
            </div>

            <div class="group-buy-body px-3 pb-2" v-if="detail">
                <div v-if="!showTimeline">
                    <h1 class="font-weight-bold font-size-extra mt-2 mb-0" v-if="!detail">{{ groupBuy.name }}</h1>
                    <div class="font-size-sm font-weight-medium text-dark-grey">{{ groupBuy.subtitle }}</div>
                    <hr>
                </div>

                <!-- Timeline -->

                <div v-if="showTimeline" class="pt-3">
                    <div class="d-flex">
                        <div>
                            <div class="group-buy-image-container">
                                <img v-if="groupBuy.order.product.thumbnail_res" :src="groupBuy.order.product.thumbnail_res.small">
                            </div>
                        </div>
                        <div class="ml-2">
                            <div class="text-dark-grey font-weight-bold font-size-sm">{{ groupBuy.order.product.name }}</div>
                            <div class="text-dark-grey font-weight-normal font-size-sm">{{ groupBuy.order.group_buy_product_quantity.quantity }}pz. - {{ $t('euro') }} {{ groupBuy.order.group_buy_product_quantity.unit_price.toLocaleString() }} più iva</div>
                            <div class="text-dark-grey font-weight-normal font-size-sm">{{ $t('Costi spedizione') }} {{ groupBuy.order.group_buy_product_quantity.shipping_price.toLocaleString() }} {{ $t('euro') }}</div>
                            <div class="text-dark-grey font-weight-normal font-size-sm">{{ $t('Totale') }} {{ groupBuy.order.group_buy_product_quantity.total_amount.toFixed(2) }} {{ $t('euro') }}</div>
                        </div>
                    </div>
                </div>
                <hr v-if="showTimeline"/>

                <div class="font-size-sm text-center mt-3">
                    <u class="font-weight-bold">{{ groupBuy.sold_quantity | toHumanReadableNumber }} {{ $t(' pezzi venduti.')}}</u> {{ $t('Arriviamo a ')}} <u class="font-weight-bold">{{ groupBuy.target_quantity | toHumanReadableNumber }}</u>
                </div>
                <div class="progress mt-3" v-if="groupBuy.target_quantity" :aria-valuenow="(groupBuy.sold_quantity * 100/ groupBuy.target_quantity).toFixed(2) + ' %'" >
                    <div class="progress-bar background-primary-green" :class="{'showing-percentage': (groupBuy.sold_quantity / groupBuy.target_quantity).toFixed(2) > 20}" role="progressbar" :style="'width: ' + (groupBuy.sold_quantity * 100 / groupBuy.target_quantity).toFixed(2) + '%'" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div v-if="$auth.user && $auth.user.is_admin" class="text-center font-size-sm text-primary-green mt-2 mb-2">
                    <strong>{{ groupBuy.sold_quantity - groupBuy.fake_quantity }} pezzi venduti</strong>
                </div>
                <hr/>
                <div class="text-center font-size-sm" v-if="groupBuy.status === 'open'">
                    <strong>{{ $t('Condividi per arrivare prima al traguardo') }}</strong>
                    <no-ssr>
                        <social-sharing :url="groupBuyUrl"
                                        :title="groupBuy.name ? groupBuy.name : ''"
                                        :description="shareMessage"
                                        :hashtags="groupBuy.keywords ? groupBuy.keywords : ''"
                                        :networks="shareNetworks"
                                        network-tag="div"
                                        inline-template
                        >
                            <div class="d-flex justify-content-around w-100 mt-2 px-lg-5 font-size-sm">
                                <network network="facebook" class="show-icon font-weight-medium">
                                    <img src="~/assets/images/icons/sharing/facebook.svg" height="50px" width="50px"><br/> Facebook
                                </network>
                                <network network="whatsapp" class="show-icon font-weight-medium">
                                    <img src="~/assets/images/icons/sharing/whatsapp.svg" height="50px" width="50px"><br/> Whatsapp
                                </network>
                                <network network="email" class="show-icon font-weight-medium">
                                    <img src="~/assets/images/icons/sharing/email.svg" height="50px" width="50px"><br/> Email
                                </network>
                                <network network="others" class="show-icon font-weight-medium d-lg-none">
                                    <img src="~/assets/images/icons/sharing/others.svg" height="50px" width="50px"><br/> {{ $t('Altro') }}
                                </network>
                                <network network="link" class="show-icon font-weight-medium d-none d-lg-block">
                                    <img src="~/assets/images/icons/sharing/copy-link.svg" height="50px" width="50px"><br/> {{ $t('Copia link') }}
                                </network>
                            </div>
                        </social-sharing>
                    </no-ssr>
                    <div class="text-dark-grey font-weight-medium mt-2">{{ $t('Consegna in 5/6 giorni l. dal traguardo')}}</div>
                </div>
                <div v-else class="text-center">
                    <span class="d-block text-black text-uppercase font-weight-xbold">{{ $t('Traguardo raggiunto!')}}</span>
                    <span class="font-size-sm font-weight-bold text-dark-grey">{{ $t('Verrai avvisato all\'apertura di un nuovo gruppo di acquisto')}}</span>
                </div>
                <hr/>

                <div v-if="showTimeline">
                    <div class="d-flex flex-column" v-if="groupBuy.order.status !== 'pending_payment'">
                        <div class="shipping-step">
                            <div class="shipping-square completed mr-2"><i class="fa fa-check"></i></div>
                            <div class="shipping-data text-secondary">
                                <strong class="text-primary-green">Ordinato {{ groupBuy.order.order_date | format('D MMMM') }}</strong>
                            </div>
                        </div>
                        <div class="shipping-progress current"></div>
                        <div class="shipping-progress in-progress current" v-if="!groupBuy.shipping_date"></div>
                        <div class="shipping-step">
                            <div class="shipping-square mr-2" :class="{'completed': groupBuy.shipping_date}"><i class="fa fa-check"></i></div>
                            <div class="shipping-data text-secondary">
                                <div class="font-weight-bold" :class="{'text-primary-green': groupBuy.shipping_date}">Spedito dal produttore<span v-if="groupBuy.shipping_date"> il {{ groupBuy.shipping_date | format('D MMMM') }}</span></div>
                                <div class="font-size-xs" :class="{'text-brand': groupBuy.shipping_date}">Alla logistica di Confestetica</div>
                                <div class="font-size-xs mt-1" v-if="groupBuy.shipping_tracking_code">Corriere {{ groupBuy.shipping_company_name }} - {{ groupBuy.shipping_tracking_code }}</div>
                            </div>
                        </div>
                        <div class="shipping-progress" :class="{'current': groupBuy.shipping_date}"></div>
                        <div class="shipping-progress in-progress current" v-if="groupBuy.status === 'shipped_to_confestetica'"></div>
                        <div class="shipping-step">
                            <div class="shipping-square mr-2" :class="{'completed': groupBuy.status === 'shipped_to_customer' || groupBuy.status === 'delivered'}"><i class="fa fa-check"></i></div>
                            <div class="shipping-data text-secondary">
                                <div class="font-weight-bold text-primary-green" v-if="groupBuy.status === 'shipped_to_customer' || groupBuy.status === 'delivered'">In partenza</div>
                                <div class="font-weight-bold" v-else>Consegna <span v-if="groupBuy.warehouse_delivery_date">{{ groupBuy.warehouse_delivery_date | format('D MMMM') }}</span></div>
                                <div class="font-size-xs">
                                    <span v-if="groupBuy.status === 'shipped_to_customer'">dalla</span>
                                    Logistica di Confestetica
                                </div>
                            </div>
                        </div>
                        <div class="shipping-progress" :class="{'current': groupBuy.status === 'shipped_to_customer' || groupBuy.status === 'delivered'}"></div>
                        <div class="shipping-progress in-progress current" v-if="groupBuy.status === 'shipped_to_customer'"></div>
                        <div class="shipping-step">
                            <div class="shipping-square mr-2" :class="{'completed': groupBuy.status === 'delivered'}"><i class="fa fa-check"></i></div>
                            <div class="shipping-data text-secondary">
                                <div class="font-weight-bold">Arrivo <span v-if="groupBuy.order.delivery_date"> previsto {{ groupBuy.order.delivery_date | format('D MMMM')}}</span></div>
                                <div class="font-size-xs">{{ groupBuy.order.shipping_name ? groupBuy.order.shipping_name : groupBuy.order.billing_name }} </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="text-center">
                        <button class="btn btn-brand w-100 mb-1" disabled>{{ $t('Stiamo processando il tuo ordine')}}</button>
                        <a href="#" class="text-brand font-size-sm" @click.prevent="refetchGroupBuy(groupBuy.slug)" v-if="!groupBuys.processing"><u>{{ $t('Ricarica lo stato dell\'ordine')}}</u></a>
                        <img alt="loading" src="~/assets/images/icons/loading.svg" class="fa fa-spin" width="24px" height="24px" v-else>
                    </div>
                </div>

                <!-- End Timeline -->

                <div>
                    <div v-if="!showTimeline">
                        <div class="d-flex flex-column">
                            <div class="text-dark-grey font-weight-bold">{{ $t('Prezzo:')}} <span style="color: #B12704!important">{{ quantity.unit_price.toFixed(2) }} € {{ quantity.vat_pct !== 0 ? '(' + $t('IVA esclusa') + ')' : '' }}</span></div>
                            <div v-if="groupBuy.status === 'open'">
                                <div class="text-dark-grey font-weight-medium font-size-sm mt-1">{{ boxesForQuantity(quantity) }} {{ $t('conf. da') }} {{ quantity.unit_per_box }}pz.</div>
                                <!--<span class="text-dark-grey font-weight-bold font-size-sm mt-1" style="color: #B12704!important">{{ amountForQuantityWithoutShipping(quantity) }} € ({{ quantity.vat_pct === 0 ? $t('esente IVA') : $t('IVA inclusa') }})</span>-->
                                <div class="text-dark-grey font-weight-medium font-size-sm mt-1">{{ $t('Costo di spedizione ')}} {{ quantity.shipping_price }} €</div>
                                <!--<div class="font-weight-medium font-size-sm mt-1">{{ $t('Consegna in 5/6 giorni l. dalla chiusura del gruppo')}}</div>-->
                                <div class="mt-2">
                                    <div class="font-weight-medium">{{ $t('Quantità')}}</div>
                                    <b-form-group class="mb-0 p-0 mt-1">
                                        <b-form-select :options="mapQuantities" v-model="quantity_id"></b-form-select>
                                    </b-form-group>
                                </div>
                            </div>
                        </div>
                        <div v-if="groupBuy.status === 'open'">
                            <button class="btn btn-brand w-100 my-2" v-login-required="checkBillingDetails">{{ $t('Acquista')}}</button>
                            <billing-details-modal ref="billingDetailsModal" :success-callback="purchaseProduct" :quantity="quantity" :product="groupBuy"></billing-details-modal>
                            <div class="font-size-xs">{{ $t('La fattura è disponibile in automatico') }}</div>
                            <nuxt-link to="/gruppo-acquisto-confestetica/come-funziona" class="font-size-xs"><u class="text-brand">{{ $t('Come funziona il gruppo di acquisto Confestetica')}}</u></nuxt-link>
                        </div>
                    </div>
                    <hr />
                    <div v-if="groupBuy.description" class="mt-3 w-100">
                        <div class="font-weight-bold font-size-extra d-flex justify-content-between align-items-center">
                            {{ $t('Caratteristiche') }}
                            <i class="fa" :class="{'fa-angle-up': showingDescription, 'fa-angle-down': !showingDescription}" @click.prevent="toggleDescription"></i>
                        </div>
                        <div v-if="showingDescription" class="mt-3 group-buy-description">
                            <div v-html="groupBuy.description"></div>
                        </div>
                    </div>
                    <hr>
                    <div v-if="groupBuyImagesGroups.length" class="mt-3 w-100">
                        <div class="font-weight-bold font-size-extra d-flex justify-content-between align-items-center">
                            {{ $t('Galleria immagini')}}
                            <!--<i class="fa" :class="{'fa-angle-up': showingGallery, 'fa-angle-down': !showingGallery}" @click.prevent="toggleGallery"></i>-->
                        </div>
                        <div v-if="showingGallery" class="mt-3">
                            <div v-for="(groupBuyImagesGroup, groupIndex) in groupBuyImagesGroups" class="gallery-row d-flex">
                                <div v-for="image in groupBuyImagesGroup"
                                     class="gallery-image flex-grow-1 position-relative"
                                     :style="'padding-top: ' + (groupBuyImagesGroup.length === 1 ? 50 : (100 / groupBuyImagesGroup.length)) + '%; background-size: cover; background-position: center; background-repeat: no-repeat; background-image: url(' + (image.image_res ? image.image_res.medium : image.path) + ')'"
                                     @click.prevent="showGroupBuyImagesModal(groupIndex)"
                                >
                                    <div class="more-images-overlay d-none justify-content-center align-items-center" v-if="groupBuy.images.length > 5"> +{{ groupBuy.images.length - 5 }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex group-buy-reactions px-3 pb-1" v-if="groupBuy.likes_count || groupBuy.comments_count">
                <div class="mr-auto d-flex align-items-center" v-if="groupBuy.likes_count">
                    <img alt="like-circle" src="~/assets/images/icons/like-circle.svg" class="mr-1" width="18px" height="18px">
                    {{ (groupBuy.likes_count ? groupBuy.likes_count : 0) }}
                </div>
                <a class="ml-auto cursor-pointer text-grey" v-if="groupBuy.root_comments_count" @click.prevent="toggleComments">{{ $t('Commenti')}}: {{ groupBuy.root_comments_count }}</a>
            </div>
            <div class="d-flex group-buy-actions align-items-center justify-content-between" v-if="groupBuy.status === 'open'">
                <label class="col d-flex justify-content-center align-items-center text-dark-grey" :class="{ liked: groupBuy.liked }" v-login-required="likeGroupBuy">
                    <i class="cf-like mr-2 text-dark-grey" :class="{ 'cf-like-f liked': groupBuy.liked }"></i> {{ $t('Mi piace') }}
                </label>
                <label :id="'newCommentLabelGroupBuyId' + groupBuy.id" :for="'newCommentGroupBuyId'" class="col d-flex justify-content-center align-items-center text-dark-grey" v-login-required="focusNewComment">
                    <i class="cf-comment mr-2 text-dark-grey"></i> {{ $t('Commenta') }}
                </label>
                <label class="col d-flex justify-content-center align-items-center text-dark-grey" v-if="!detail">
                    <dropup-menu ref="followDropup" :hide-bottom-bar="true" :show-overlay="true">
                        <template slot="menu-icon">
                            <img alt="share" class="mr-2" src="~/assets/images/icons/share.svg" width="20px">
                            {{ $t('Condividi') }}
                        </template>
                        <template slot="menu-options">
                            <social-sharing :url="groupBuyUrl"
                                            :title="groupBuy.name ? groupBuy.name : ''"
                                            :description="shareMessage"
                                            :hashtags="groupBuy.keywords ? groupBuy.keywords : ''"
                                            :networks="shareNetworks"
                                            network-tag="li"
                                            inline-template
                            >
                                <ul class="menu-options">
                                    <network network="link" class="show-icon">
                                        <i class="fa fa-link"></i> Copia link
                                    </network>
                                    <network network="email" class="show-icon">
                                        <i class="fa fa-envelope"></i> Email
                                    </network>
                                    <network network="facebook" class="show-icon">
                                        <i class="fa fa-facebook"></i> Facebook
                                    </network>
                                    <network network="linkedin" class="show-icon">
                                        <i class="fa fa-linkedin"></i> LinkedIn
                                    </network>
                                    <network network="twitter" class="show-icon">
                                        <i class="fa fa-twitter"></i> Twitter
                                    </network>
                                    <network network="whatsapp" class="show-icon">
                                        <i class="fa fa-whatsapp"></i> Whatsapp
                                    </network>
                                </ul>
                            </social-sharing>
                        </template>
                    </dropup-menu>
                </label>
            </div>
            <div v-else class="group-buy-actions text-center">
                <label class="text-dark-grey">
                    <span class="d-block text-black text-uppercase font-weight-xbold">{{ $t('Traguardo raggiunto!')}}</span>
                    <span class="font-size-sm">{{ $t('Verrai avvisato all\'apertura di un nuovo gruppo di acquisto')}}</span>
                </label>
            </div>

        </div>
        <div class="p-3 d-flex flex-column comments" v-if="showingComments && !hideComments">
            <comments
                    :limit="limit"
                    :resource="groupBuy"
                    :comments="filteredComments"
                    :focus-comment-id="comment.comment_id"
                    :modal="modal"
                    :group-buy-detail="detail"
            >
            </comments>
        </div>
        <group-buy-modal :group-buy="groupBuy" ref="groupBuyModal" v-if="!detail"></group-buy-modal>
        <images-gallery ref="groupBuyImagesGallery" v-if="groupBuy.images.length" :images="groupBuy.images">
            <template slot="dropdown-menu">
                <b-dropdown right no-caret variant="link" class="d-none d-lg-block">
                    <template slot="button-content">
                        <i class="cf-more"></i>
                    </template>
                    <b-dropdown-item @click.prevent>{{ $t('Salva immagine') }}</b-dropdown-item>
                    <b-dropdown-item @click.prevent>{{ $t('Segnala immagine') }}</b-dropdown-item>
                </b-dropdown>
                <div class="dropdown d-block d-lg-none">
                    <dropup-menu :bottom="50" :hide-bottom-bar="true" :show-overlay="true">
                        <template slot="menu-icon">
                            <i class="cf-more btn-link text-white"></i>
                        </template>
                        <template slot="menu-options">
                            <ul class="menu-options">
                                <li >
                                    <i class="fa fa-bookmark-o mr-2"></i> {{ $t('Salva immagine')}}
                                </li>
                                <li>
                                    <i class="fa fa-flag-o mr-2"></i> {{ $t('Segnala immagine')}}
                                </li>
                            </ul>
                        </template>
                    </dropup-menu>
                </div>
            </template>

            <template slot="resource-name">
                <span class="title font-weight-bold" :class="{'text-white': mobileMode}">{{ groupBuy.name }}</span>
            </template>
            <template slot="dropup-menu">
                <dropup-menu :hide-bottom-bar="true">
                    <template slot="menu-options">
                        <ul>
                            <li @click.prevent="openInput">
                                <i class="fa fa-photo mr-2"></i>
                                {{ $t('Foto/video')}}
                            </li>
                            <li>
                                <i class="cf-camera mr-2"></i>
                                {{ $t('Fotocamera')}}</li>
                            <li>
                                <i class="cf-friends mr-2"></i>
                                {{ $t('Tagga persone')}}
                            </li>
                        </ul>
                    </template>
                </dropup-menu>
            </template>
            <span slot="actions"></span>
        </images-gallery>
    </div>
</template>

<script>

    import Vue from 'vue'
    import { mapState, mapActions, mapGetters } from 'vuex'
    import Comments from "~/components/GroupBuys/GroupBuy/Comments";
    import GroupBuyModal from "~/components/GroupBuys/GroupBuy/GroupBuyModal";
    import BillingDetailsModal from "~/components/BillingDetailsModal";

    import { focus } from 'vue-focus';
    import autosize from 'autosize';
    import _ from 'lodash';

    export default {

        name: 'group-buy',

        props: {
            groupBuy: {
                type: Object,
                default: function () {
                    return {}
                }
                },
            detail: {
                type: Boolean,
                default: function () {
                    return true
                }
            },
            modal: {
                type: Boolean,
                default: function () {
                    return false
                }
            },
            level: {
                type: Number,
                default: function () {
                    return 0
                }
            },
            hideComments: {
                type: Boolean,
                default: function () {
                    return false
                }
            }
        },

        components: {
            Comments,
            GroupBuyModal,
            BillingDetailsModal
        },

        directives: {
            focus: focus,
        },

        data () {
            return {
                stripeApiKey: process.env.STRIPE_PUBLISHABLE_KEY || 'pk_test_nH1hcGkzXexiZBYsKY72hUXA',
                limit: !this.detail ? 1 : 0,
                showingGallery: true,
                showingDescription: true,
                showingPriceDetails: false,
                showingComments: this.detail,
                focused: true,
                truncation: true,
                quantity_id: null,
                comment: {
                    text: '',
                    group_buy_product_id: this.groupBuy.id,
                    comment_id: null,
                    user: null
                },
                shareMessage:
                    'Ciao, guarda questo gruppo d’acquisto di Confestetica. ' + "\n" +
                    'Direttamente dal produttore al centro estetico a prezzi di fabbrica. Incredibile!' + "\n",
                shareNetworks: {
                    'email':{"sharer":"mailto:?subject=@title&body=@url%0D%0A%0D%0A@description",'type': 'direct'},
                    'facebook':{"sharer":"https://www.facebook.com/sharer/sharer.php?u=@url&title=@title&description=@description&quote=@quote&hashtag=@hashtags",'type': 'direct'},
                    'linkedin':{"sharer":"https://www.linkedin.com/shareArticle?mini=true&url=@url&title=@title&summary=@description",'type': 'direct'},
                    'twitter':{"sharer":"https://twitter.com/intent/tweet?text=@title&url=@url&hashtags=@hashtags@twitteruser",'type': 'direct'},
                    'whatsapp':{"sharer":"https://api.whatsapp.com/send?text=@description%0D%0A@url",'type': 'direct'},
                    'others':{"sharer":"",'type': 'direct'},
                    'link':{"sharer":"",'type': 'direct'},
                }
            }
        },

        computed: {
            ...mapState({
                'app': state => state.app,
                'mobileMode': state => state.mobileMode,
                'platform': state => state.platform,
                'groupBuys': state => state['group-buys']
            }),
            ...mapGetters({
                'getGroupBuyComments': 'group-buy-comments/getFilteredComments',
                'getUserById': 'users/GET_USER_BY_ID'
            }),
            filteredComments() {
                return this.getGroupBuyComments({
                    group_buy_product_id: this.groupBuy.id
                })
            },
            quantity () {
                return  _.find(this.groupBuy.quantities, {id: this.quantity_id})
            },
            orderedQuantities() {

                let quantities = _.map(this.groupBuy.quantities,(quantity) => {
                    quantity.order_string = quantity.unit_price + '_' + this.strPad(quantity.quantity, 6)
                    return quantity
                })

                return _.orderBy(quantities, 'order_string', 'asc')
            },
            mapQuantities () {
                return this.orderedQuantities.map(quantity => {
                    return {
                        value: quantity.id,
                        text: quantity.quantity + 'pz - ' + quantity.unit_price + ' €' + (quantity.quantity > 1 ? (' - ' + (quantity.quantity * quantity.unit_price).toFixed(2) + '€' ) : '')
                    }
                })
            },
            showTimeline () {
                return this.groupBuy.order && ['canceled', 'failed'].indexOf(this.groupBuy.order.status) < 0
            },
            groupBuyUrl () {
                return process.env.BASE_URL + '/gruppi-di-acquisto/' + this.groupBuy.slug
            },
            groupBuyImagesGroups() {

                let imagesGroups = [];
                let groupMaxLength = 3;

                let groupBuyImages = _.take(this.groupBuy.images, 5);

                if (groupBuyImages.length) {
                    _.each(groupBuyImages, function (image) {
                        if (imagesGroups.length > Math.max(Math.floor(groupBuyImages.length / groupMaxLength), 1)) {

                            let i = imagesGroups.length;

                            while((imagesGroups[i - 1].length >= groupMaxLength) && i > 0) {
                                i--
                            }

                            if (i > 0) {
                                imagesGroups[i - 1].push(image);
                            }

                        } else {
                            imagesGroups.push([]);
                            let imageGroup = _.last(imagesGroups);
                            imageGroup.push(image);
                        }
                    })
                }

                return imagesGroups;
            }
        },

        methods: {
            ...mapActions({
                'refetchGroupBuy': 'group-buys/fetchBySlug',
                'createComment': 'group-buy-comments/create',
                'refetchComment': 'group-buy-comments/fetchById',
                'like': 'group-buys/likeGroupBuyById',
            }),
            strPad (text = '', length, char = '0') {
                for(let i = 0; i < length; i++) {
                    text = char + text;
                }

                return text.slice(- length)
            },
            boxesForQuantity(quantity) {
                return (quantity.quantity / quantity.unit_per_box).toFixed(0)
            },
            amountForQuantityWithoutShipping (quantity) {
                return (quantity.quantity * quantity.unit_price * (1 + quantity.vat_pct / 100)).toFixed(2)
            },
            paymentSucceeded() {

            },
            purchaseProduct ({paymentMethod, source, useWalletBalance}) {
                this.$nuxt.$loading.start();

                this.$axios.post('/api/orders/checkout', { product_id: this.groupBuy.id, quantity_id: this.quantity_id, payment_method: paymentMethod, stripe_payment_method: source, use_wallet_balance: useWalletBalance })
                    .then(response => response.data)
                    .then((response) => {

                        let order = response.order

                        this.$store.commit('group-buys/ASSOCIATE_ORDER', { groupBuyId: this.groupBuy.id, order: order })

                        this.$refs.billingDetailsModal.hide();

                        if(paymentMethod === 'stripe') {
                            let stripe = Stripe(this.stripeApiKey);

                            if (response.requiresSCA) {
                                stripe.handleCardPayment(response.payment_intent_secret).then(result => {

                                    this.$nuxt.$loading.finish();
                                });
                            } else {
                                this.$nuxt.$loading.finish();
                            }
                        } else if(paymentMethod === 'bank_transfer') {
                            let stripe = Stripe(this.stripeApiKey);

                            let amount = order.amount

                            if(useWalletBalance) {
                                amount = amount - this.$auth.user.wallet_balance > 0 ? amount - this.$auth.user.wallet_balance : 0
                            }

                            if(amount) {
                                stripe.createSource({
                                    type: 'sofort',
                                    amount: parseInt((amount * 100).toFixed()),
                                    statement_descriptor: 'Acquisto Confestetica',
                                    currency: 'eur',
                                    redirect: {
                                        return_url: process.env.BASE_URL + '/gruppi-di-acquisto/' + this.groupBuy.slug + (this.app ? '/redirect?platform=' + this.platform : ''),
                                    },
                                    metadata: {
                                        order_id: response.order.id,
                                        user_id: this.$auth.user.id.toString(),
                                        use_wallet_balance: useWalletBalance
                                    },
                                    owner: {
                                        name: this.$auth.user.name,
                                        email: this.$auth.user.email
                                    },
                                    sofort: {
                                        country: 'IT',
                                    },
                                }).then((result) => {
                                    if (result.source) {
                                        window.location = result.source.redirect.url;
                                    } else if(result.error) {
                                        console.error(result.error)
                                    }
                                })
                            } else {
                                this.$nuxt.$loading.finish();

                            }
                        } else if(paymentMethod === 'wallet') {
                            this.$nuxt.$loading.finish();
                        }
                    })
                    .catch(() => {
                        this.$notify({
                            group: 'messages',
                            type: 'error',
                            title: this.$t('E\' stato riscontrato un errore durante il pagamento')
                        })
                    })
                    .finally(() => {
                        this.$nuxt.$loading.finish();
                    });
            },
            showText() {
                if(this.mobileMode) {
                    this.openGroupBuyModal();
                } else {
                    this.truncation = false;
                }
            },
            doCopy(text) {
                this.$copyText(text)
                    .then(()=> {
                        this.showAlert();
                    })
            },
            showAlert() {
                if (this.platform === 'ios') {
                    this.$iosAlert('Link dell\'articolo copiato.');
                } else {
                  this.$notify({
                    group: 'messages',
                    type: 'success',
                    title: this.$t('Link dell\'articolo copiato.'),
                    duration: 2000
                  });
                }
            },
            checkBillingDetails() {
              this.$swal({
                html: this.$t('<strong>Stai ordinando</strong><br /> ' + this.quantity.quantity + ' pezzi<br/><br/>' + this.boxesForQuantity(this.quantity) + ' conf. da ' + this.quantity.unit_per_box + 'pz&nbsp;<span class="font-weight-bold" style="color: #B12704!important">' + this.amountForQuantityWithoutShipping(this.quantity) + '€ ' + (this.quantity.vat_pct ?  '(' + this.$t('IVA inclusa')  + ')' : '') + '</span>' + '<br/><br />' + 'In questo gruppo di acquisto si può fare un solo ordine. <br /> <span class="text-brand">Sei sicura di voler confermare questa quantità?</span>'),
                showCancelButton: true,
                reverseButtons: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                animation: false,
                customClass: {
                  'cancelButton': 'btn btn-grey btn-sm flex-grow-3',
                  'confirmButton': 'btn btn-sm btn-brand flex-grow-5'
                },
                cancelButtonText: this.$t('Annulla'),
                confirmButtonText: this.$t('Conferma')
              }).then((result) => {
                if (result.value) {
                  this.$refs.billingDetailsModal.show();
                }
              })
            },
            toggleGallery () {
                this.showingGallery = !this.showingGallery;
            },
            toggleDescription () {
                this.showingDescription = !this.showingDescription;
            },
            togglePriceDetails () {
                this.showingPriceDetails = !this.showingPriceDetails;
            },
            toggleComments () {
                if (!this.detail) {
                    this.$router.push('/gruppi-di-acquisto/' + this.groupBuy.slug)
                } else {
                    this.showingComments = !this.showingComments;
                }
            },
            showGroupBuyImagesModal (index) {
                this.$refs.groupBuyImagesGallery.showImagesGalleryModal(index);
            },
            likeGroupBuy() {
                this.like(this.groupBuy.id)
                    .then((r) => {
                        // this.refetch();
                    })
            },
            followUser(userId) {
                this.followUserById(userId).then((user) => {

                    // this.$refs.followDropup.toggleShowDropUp()

                    this.$notify({
                        group: 'messages',
                        type: 'success',
                        title: this.$t('Ora segui ' + user.name)
                    });
                }).catch(error => {

                    // this.$refs.followDropup.toggleShowDropUp()

                    this.$notify({
                        group: 'messages',
                        type: 'error',
                        title: this.$t('Si è verificato un errore')
                    });
                })
            },
            unfollowUser(userId) {
                this.unfollowUserById(userId).then((user) => {

                    // this.$refs.followDropup.toggleShowDropUp()

                    this.$notify({
                        group: 'messages',
                        type: 'success',
                        title: this.$t('Non segui più ' + user.name)
                    });
                }).catch(error => {

                    // this.$refs.followDropup.toggleShowDropUp()

                    this.$notify({
                        group: 'messages',
                        type: 'error',
                        title: this.$t('Si è verificato un errore')
                    });
                })
            },
            refetch() {
                this.refetchGroupBuy(this.groupBuy.slug)
            },
            clearAnswerComment() {

                this.comment.user = null;
                this.comment.group_buy_comment_id = null;
            },
            focusNewComment() {

                if (this.mobileMode && !this.detail) {

                    this.$refs.groupBuyModal.show()
                } else {
                    this.showingComments = true;

                    setTimeout(() => {
                        this.$nuxt.$emit('focus-new-comment-' + this.groupBuy.id);
                    })
                }
            },
            openGroupBuyModal() {

                if (this.$refs.groupBuyModal) {
                    this.$refs.groupBuyModal.show()
                }
            },
            openGroupBuy() {
                if(this.detail) return;

                if (this.mobileMode) {
                    this.openGroupBuyModal();
                } else {
                    this.$router.push('/gruppi-di-acquisto/' + this.groupBuy.slug)
                }
            },
            showUser() {

                if (!this.mobileMode) {
                    if(this.groupBuy.user) {
                        this.$router.push('/estetiste/' + this.groupBuy.user.id + '-' + this.groupBuy.user.slug)
                    }
                } else {
                    if (this.$refs.userModal) {
                        this.$refs.userModal.show()
                    }
                }
            },

            closeEditGroupBuyModal() {
                if (this.$refs.editGroupBuyModal) {
                    this.$refs.editGroupBuyModal.hide()
                }
            }
        },

        deactivated () {
            this.$nuxt.$off('social_shares_open')
        },
        activated () {
            this.$nuxt.$on('social_shares_open', (network, url) => {
                if (this.groupBuyUrl === url) {
                    switch(network) {
                        case 'others':
                            if(navigator.share) {
                                navigator.share({
                                    url: url,
                                    title: this.groupBuy.name,
                                    text: this.shareMessage,
                                })
                                    .then(() => console.log('Share was successful.'))
                                    .catch((error) => console.log('Sharing failed', error));
                            } else if(this.app) {
                                let event = {
                                    event: 'share',
                                    body: {
                                        url: url,
                                        title: this.groupBuy.name,
                                        text: this.shareMessage,
                                    }
                                }

                                if (window.ReactNativeWebView) {
                                    window.ReactNativeWebView.postMessage(JSON.stringify(event));
                                } else {
                                    window.postMessage(JSON.stringify(event), '*');
                                }
                            } else {
                                this.doCopy(url)
                            }
                            break;
                        case 'link':
                            this.doCopy(url)
                            break;
                        default:
                            this.doCopy(url)
                            break;
                    }
                }
            });
        },

        created () {

            let firstQuantity = _.first(this.orderedQuantities);

            if (firstQuantity) {
                this.quantity_id = firstQuantity.id
            }

            this.$nuxt.$on('group-buy-' + this.groupBuy.id + '-clear-answer-to-comment-id', () => {
                this.clearAnswerComment()
            })

            this.$nuxt.$on('group-buy-' + this.groupBuy.id + '-answer-to-comment-id', (data) => {

                Vue.set(this.comment, 'group_buy_comment_id', data.commentId)
                Vue.set(this.comment, 'user', data.user)
                Vue.set(this.comment, 'text', data.user ? '@' + data.user.name + ' ' + this.comment.text : this.comment.text)

                setTimeout(() => {
                    autosize.update(document.querySelectorAll('textarea'))
                })
            });
        },

        mounted () {
            autosize(document.querySelectorAll('textarea'))
            setTimeout(() => {
                autosize.update(document.querySelectorAll('textarea'))
            })

            this.$nuxt.$on('close-edit-group-buy-modal', () => {
                this.closeEditGroupBuyModal()
            })
        }
    }
</script>

<style lang="scss" scoped>

    .text-large {
        font-size: 24px!important;
    }

    a {
        text-decoration: none;
    }

    hr {
        /*border-top: 1px solid #F4F2F3;*/
    }

    .group-buy-header {
        text-align: center;
        img {
            max-height: 200px;
            width: auto;
        }
    }

    .group-buy-content-container {
        padding-left: 15px;
        padding-right: 15px;
        &.communication {
            padding-left: 24px;
            padding-right: 24px;
        }
    }

    .gallery-row {
        margin-left: -15px;
        margin-right: -15px;
        max-height: 75vw;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .shipping-step {
        display: flex;
        min-height: 40px;
        position: relative;

        .shipping-data {
            position: absolute;
            margin-left: 35px;
        }
    }

    .shipping-progress {
        height: 60px;
        border-left: 2px dashed grey;
        margin-top: -10px;
        margin-bottom: 5px;
        margin-left: 12px;
        &.completed {
            margin-top: -15px;
            margin-bottom: 0;
            border-left: 2px solid #9B187E;
        }
        &.current {
            margin-top: -15px;
            margin-bottom: 0;
            height: 55px;
            border-left: 2px solid #9B187E;
            &.in-progress {
                margin-top: 5px;
                margin-bottom: 5px;
                height: 5px;
                border-left: 2px dashed grey;
            }
        }
    }
    .shipping-square {
        border-radius: 0px;
        width: 25px;
        height: 25px;
        border: 2px solid grey;
        text-align: center;
        font-size: 13px;
        padding-top: 3px;
        color: white;
        &.completed {
            background-color: #9B187E;
            border: 2px solid #9B187E;
        }
    }

</style>
