<template>
    <div class="user-menu">
        <div class="user-nav" v-click-outside="closeMenu">
            <a href="javascript:void(0)" class="no-decoration" @click.prevent="toggleMenu" :class="{'menu-open': menuOpen || $route.path === '/menu'}" v-if="!mobileMode">
                <slot name="menu-button">
                    <i class="fa fa-bars"></i>
                </slot>
            </a>
            <div class="list card border-0" v-if="menuOpen || mobileMode">
                <ul v-for="category in menuItems" :class="category.classes" v-if="category.visible">
                    <li v-if="category.title" class="divider" :class="category.classes" @click.prevent="toggleItem(category)">
                        <div class="d-flex justify-content-between align-items-center">
                            {{ category.title }}
                            <div v-if="category.dropdown">
                                <i v-if="category.expanded" class="fa fa-angle-up"></i>
                                <i v-else class="fa fa-angle-down"></i>
                            </div>
                        </div>
                    </li>
                    <li v-for="item in category.items" :class="item.classes" v-if="item.visible && (!category.dropdown || (category.dropdown && category.expanded))" :style="item.touchable_style_web">
                        <div v-if="item.html" v-html="item.html"></div>
                        <a href="javascript:void(0)" class="d-flex align-items-center nuxt-link-inactive" v-else>
                            <div class="d-flex align-items-center menu-icon-container" @click.prevent="itemClick(item)">
                                <div class="menu-icon" :style="item.image_style_web">
                                    <img :alt="item.title" :src="item.image_url">
                                </div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between w-100 h-100 border-bottom" :style="item.text_container_style_web">
                                <div class="text-container d-flex justify-content-center flex-column flex-grow-1" @click.prevent="itemClick(item)">
                                    <div class="d-flex align-items-center">
                                        <span :style="item.title_style_web"> {{ item.title }}</span>
                                        <img :alt="item.title" v-if="item.badge_image_url" class="ml-2" :src="item.badge_image_url" :style="item.badge_image_style">
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <div class="news" v-if="item.news" :style="item.news_style_web"></div>
                                        <span class="subtitle" :style="item.subtitle_style_web"> {{ item.subtitle }}</span>
                                    </div>
                                </div>
                                <div v-if="item.right_content" class="ml-auto right-content mr-3 align-self-center">{{ item.right_content }}</div>
                                <!--<i class="fa fa-angle-right" v-if="!item.dropdown_items || item.dropdown"></i>-->
                                <div v-if="item.dropdown_items && item.dropdown" @click.prevent="toggleItem(item)">
                                    <i class="fa fa-angle-up p-3" v-if="item.expanded"></i>
                                    <i class="fa fa-angle-down p-3" v-else></i>
                                </div>
                            </div>
                        </a>
                        <ul v-if="item.dropdown_items && item.expanded">
                            <li v-for="dropdownItem in item.dropdown_items" v-if="dropdownItem.visible">
                                <a href="javascript:void(0)" class="d-flex w-100 align-items-center justify-content-between text-black" @click.prevent="itemClick(dropdownItem)">
                                    <div class="d-flex align-items-center w-100">
                                        <div class="menu-icon-container d-flex justify-content-center align-items-center">
                                            <div class="menu-icon" :style="dropdownItem.image_style_web">
                                                <img :alt="dropdownItem.title" :src="dropdownItem.image_url"/>
                                            </div>
                                        </div>
                                        <div class="ml-3 text-container w-100 d-flex justify-content-center flex-column">
                                            <span :style="dropdownItem.title_style_web"> {{ dropdownItem.title }}</span>

                                            <div class="d-flex align-items-center">
                                                <div class="news" v-if="dropdownItem.news" :style="dropdownItem.news_style_web"></div>
                                                <span class="subtitle" :style="dropdownItem.subtitle_style_web"> {{ dropdownItem.subtitle }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="dropdownItem.dropdown_items && dropdownItem.dropdown" @click.prevent="toggleItem(dropdownItem)">
                                        <i class="fa fa-angle-up p-3" v-if="dropdownItem.expanded"></i>
                                        <i class="fa fa-angle-down p-3" v-else></i>
                                    </div>
                                </a>
                                <!--<div>-->
                                <hr v-if="dropdownItem.expanded">
                                <a href="javascript:void(0)" class="d-flex justify-content-between align-items-center text-black" @click.prevent="itemClick(childItem)" v-for="childItem in dropdownItem.dropdown_items" v-if="dropdownItem.expanded">
                                    <!--<div class="menu-icon-container d-flex justify-content-center align-items-center">-->
                                        <!--<div class="menu-icon" :style="childItem.image_style_web">-->
                                            <!--<img :alt="childItem.title" :src="childItem.image_url"/>-->
                                        <!--</div>-->
                                    <!--</div>-->
                                    <div class="ml-3 text-container w-100 d-flex justify-content-center flex-column">
                                        <span :style="childItem.title_style_web"> {{ childItem.title }}</span>

                                        <div class="d-flex align-items-center">
                                            <div class="news" v-if="dropdownItem.news" :style="dropdownItem.news_style_web"></div>
                                            <span class="subtitle" :style="childItem.subtitle_style_web"> {{ childItem.subtitle }}</span>
                                        </div>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
        <dropup-menu ref="dropupMenu" :hide-bottom-bar="true" :show-overlay="true" :fixed="true">
            <template slot="menu-options">
                <h4 class="mx-5 my-4 text-center"><strong>{{ $t('Torna dove hai visto il tuo problema e agita il telefono') }}</strong></h4>
                <p class="mx-1 text-center mb-4 font-size-sm">{{ $t("Hai riscontrato un problema?")}}<br />{{ $t('Agita il tuo telefono quando lo vedi per aiutarci a trovarlo e risolverlo più velocemente.')}}</p>
                <div class="d-flex flex-column justify-content-center mt-3 pb-3">
                    <img src="~assets/images/shake.png" class="my-3" alt="shake" style="object-fit: contain"/>
                    <a href="#" class="btn-post btn-brand mx-3 font-size-sm font-weight-bold text-center">
                        {{ $t('Torna indietro e agita il telefono') }}
                    </a>
                </div>
            </template>
        </dropup-menu>
        <v-facebook-login
                :app-id="facebookAppId"
                class="mt-3 w-100 text-center"
                :token-style="{'margin': 0}"
                :login-options="{scope: 'email,user_gender,user_birthday'}"
                @sdk-init="handleSdkInit"
                v-show="false"
        ></v-facebook-login>
    </div>
</template>

<script>
    import { mapMutations, mapActions, mapState, mapGetters } from 'vuex'
    import _ from 'lodash'

    export default {

        props: ['visibleOnOpen'],

        data () {
            return {
                facebookSDK: null,
                facebookAppId: process.env.FACEBOOK_CLIENT_ID,
                menuOpen: this.visibleOnOpen,
                menuItems: [],
                showingSettingsDropdown: false,
            }
        },

        watch: {
            '$auth.user': {
                deep: true,
                handler: 'getMenuItems'
            }
        },

        computed: {
            ...mapGetters([
                'account',
                'hasACompanyAccount'
            ]),
            ...mapState([
                'auth',
                'mobileMode',
                'app',
                'menuOpening'
            ]),
            checkExpirationDate() {
                return this.$auth.loggedIn && this.$auth.user.account.subscription_expiration_date && this.$moment(this.$auth.user.account.subscription_expiration_date).format() > this.$moment().format();
            }
        },

        methods: {
            ...mapActions({
                'processLogout': 'user/logout',
                // 'openCrashReport': 'openCrashReport'
            }),
            ...mapMutations({
               'setMenuOpening': 'SET_MENU_OPENING'
            }),
            outside: function(e) {
                this.menuOpen = false;
            },

            close() {
                if (this.menuOpen) {
                    this.menuOpen = false;
                }
            },

            openCrashReport() {

                this.$refs.dropupMenu.toggleShowDropUp()
            },

            getMenuItems() {

                this.menuItems = [
                    {
                        visible: true,
                        items: [
                            {
                                visible: this.$auth.loggedIn,
                                expanded: false,
                                classes: [
                                    'menu-dropdown'
                                ],
                                dropdown: true,
                                dropdown_items: [
                                    // {
                                    //     visible: true,
                                    //     // news: true,
                                    //     image_url: require('assets/images/icons/menu/reviews.svg'),
                                    //     title: 'Recensioni',
                                    //     // subtitle: '3 nuove recensioni',
                                    //     url: '/profile?tab=reviews'
                                    // },

                                    {
                                        visible: true,
                                        image_url: require('assets/images/icons/menu/subscription.svg'),
                                        title: 'Quota associativa',
                                        subtitle: this.$auth.loggedIn && this.$auth.user.account.subscription_expiration_date ? this.$t('Scade il ') + this.$moment(this.$auth.user.account.subscription_expiration_date).format('LL') : 'Iscriviti ora',
                                        url: '/diventa-socio'
                                    },
                                    {
                                        visible: false,
                                        image_url: require('assets/images/icons/menu/badge.svg'),
                                        title: 'Badge',
                                        subtitle: 'Stampa',
                                        url: '/badge'
                                    },
                                    {
                                        visible: false,
                                        image_url: (this.$auth.loggedIn && this.$auth.user.certified_at ? require('assets/images/icons/diploma.svg') : (this.$auth.loggedIn && this.$auth.user.verified_at ? require('assets/images/icons/certified.svg') : (this.$auth.loggedIn && this.$auth.user.diploma ? require('assets/images/icons/pending-verification.svg') : require('assets/images/icons/pending-verification-f.svg')))),
                                        title: this.$auth.loggedIn && this.$auth.user.certified_at ? 'Profilo certificato' : (this.$auth.loggedIn && this.$auth.user.verified_at ? 'Certifica il profilo' : (this.$auth.loggedIn && this.$auth.user.diploma ? 'Attestato caricato' : 'Verifica il tuo profilo')),
                                        subtitle: this.$auth.loggedIn && this.$auth.user.certified_at ? 'Dal ' + this.$moment(this.$auth.user.certified_at).format('DD/MM/YYYY') : (this.$auth.loggedIn && this.$auth.user.verified_at ? 'Profilo non certificato' : (this.$auth.loggedIn && this.$auth.user.diploma ? 'In attesa di verifica' : 'Caricare attestato estetista')),
                                        url: (this.$auth.loggedIn && this.$auth.user.verified_at && !this.$auth.user.certified_at) ? '/diventa-socio' : '/aggiorna-profilo/diploma'
                                    },
                                    {
                                        visible: false,
                                        text_container_style_web: {
                                            'border-bottom': 'none!important'
                                        },
                                        image_url: require('assets/images/icons/menu/curriculum-f.svg'),
                                        title: 'Curriculum',
                                        subtitle: '',
                                        url: '/curriculum'
                                    }
                                ],
                                touchable_style_web: {
                                    'border-bottom': '1px solid #f4f2f3',
                                    'background': 'transparent!important'
                                },
                                text_container_style_web: {
                                    'height': '68px!important',
                                    'border-bottom': 'none!important'
                                },
                                image_url: this.$auth.loggedIn && this.$auth.user.profile_type !== 'not_beautician'
                                    ? this.$auth.user.profile_image_res ? this.$auth.user.profile_image_res.medium : require('~/assets/images/profile_placeholder_' + (this.$auth.loggedIn && this.$auth.user.gender === 'male' ? 'm' : 'f') + '.svg')
                                    : this.$auth.loggedIn && this.$auth.user.account.profile_image_res ? this.$auth.user.account.profile_image_res.medium : require('~/assets/images/account-placeholder.svg'),
                                image_style: {
                                    height: 48,
                                    width: 48,
                                    borderRadius: this.$auth.loggedIn && this.$auth.user.profile_type !== 'not_beautician' ? 24 : 4
                                },
                                image_style_web: {
                                    'height': '2.2em',
                                    'width': '2.2em',
                                    'border-radius': '50%',
                                    'overflow': 'hidden',
                                },
                                title: this.$auth.loggedIn ? (this.$auth.user.profile_type !== 'not_beautician' ? this.$auth.user.name : this.$auth.user.account.name) : '',
                                // subtitle: this.$auth.loggedIn && this.$auth.user.profile_rank,
                                subtitle: this.$t('Vedi il tuo profilo'),
                                badge_image_url: (this.$auth.loggedIn && this.$auth.user.certified_at ? require('assets/images/icons/certified.svg') : (this.$auth.loggedIn && this.$auth.user.verified_at ? require('assets/images/icons/verified.svg') : (this.$auth.loggedIn && this.$auth.user.diploma ? require('assets/images/icons/pending-verification.svg') : require('assets/images/icons/pending-verification-f.svg')))),
                                badge_image_style: {
                                    width: '20px',
                                    height: '20px'
                                },
                                subtitle_style: {
                                    marginLeft: 15
                                },
                                url: '/profilo'
                            },
                            {
                                visible: this.$auth.loggedIn && this.$auth.user.profile_type !== 'not_beautician',
                                expanded: false,
                                classes: [
                                    'menu-dropdown'
                                ],
                                // dropdown: this.$auth.loggedIn && this.$auth.user.working_place && this.$auth.user.working_place.owner,
                                dropdown: false,
                                dropdown_items: [
                                    // {
                                    //     visible: true,
                                    //     // news: true,
                                    //     image_url: require('assets/images/icons/menu/reviews.svg'),
                                    //     title: 'Recensioni',
                                    //     // subtitle: '3 nuove recensioni',
                                    //     url: this.$auth.user.working_place ? '/centri-estetici/' + this.$auth.user.working_place.account.id + '?tab=reviews' : ''
                                    // },
                                    {
                                        visible: true,
                                        image_url: require('assets/images/icons/menu/contacts.svg'),
                                        title: 'Rubrica clienti del centro',
                                        subtitle: '',
                                        url: ''
                                    },
                                    {
                                        visible: true,
                                        image_url: require('assets/images/icons/menu/suppliers.svg'),
                                        title: 'Fornitori',
                                        subtitle: '',
                                        url: '/suppliers'
                                    },
                                    {
                                        visible: true,
                                        image_url: require('assets/images/icons/menu/invoices.svg'),
                                        title: 'Fatture',
                                        subtitle: '',
                                        url: '/invoices'
                                    },
                                ],
                                image_url: this.$auth.loggedIn && this.$auth.user.working_place ? (this.$auth.user.working_place.account.profile_image_res ? this.$auth.user.working_place.account.profile_image_res.medium : require('~/assets/images/account-placeholder.svg')) : require('~/assets/images/icons/beauty-centre-plus.svg'),
                                touchable_style_web: {
                                    'border-bottom': '1px solid #f4f2f3',
                                    'background': 'transparent!important'
                                },
                                text_container_style_web: {
                                    'height': '68px!important',
                                    'border-bottom': 'none!important'
                                },
                                image_style: {
                                    height: 48,
                                    width: 48,
                                },
                                image_style_web: {
                                    'height': '2.2em',
                                    'width': '2.2em',
                                },
                                title: this.$auth.loggedIn && this.$auth.user.working_place ? this.$auth.user.working_place.account.name : 'Collegati ad un luogo di lavoro',
                                subtitle: this.$auth.loggedIn && this.$auth.user.working_place ? (this.$auth.user.working_place.owner ? 'Titolare' : 'Dipendente') : '',
                                subtitle_style: {
                                    marginLeft: 15
                                },
                                url: this.$auth.loggedIn && this.$auth.user.working_place  ? this.$auth.user.working_place.account.permalink : '/luogo-di-lavoro'
                            }
                        ]
                    },
                    {
                        visible: true,
                        expanded: true,
                        dropdown: true,
                        // title: 'COLLEGAMENTI RAPIDI',
                        items: [
                            {
                                visible: this.mobileMode && (!this.$auth.loggedIn || (this.$auth.loggedIn && this.$auth.user.is_beautician)),
                                html:
                                    '<div class="p-3 d-flex">\n' +
                                    '    <div class="menu-icon-container">\n' +
                                    '        <div class="menu-icon">\n' +
                                    '            <img src="' + require('assets/images/ico-c.png') + '" width="24px">\n' +
                                    '        </div>\n' +
                                    '    </div>\n' +
                                    '    <div class="ml-3 position-relative">\n' +
                                    '        <span class="font-weight-bold">Iscrizione a confestetica <br> ' + this.$moment().year() + ' - '  + (this.$moment().year() + 1) + '</span>\n' +
                                    '        <div class="d-flex justify-content-between mt-2">\n' +
                                    '            <div class="w-50 d-flex flex-column justify-content-between">\n' +
                                    '                Insieme si cresce più velocemente\n' +
                                    '                <button onclick="routeTo(\'/diventa-socio\')" class="btn btn-sm btn-brand">Associati</button>\n' +
                                    '            </div>\n' +
                                    '            <img src="' + require('assets/images/estetista.png') + '" width="120px" height="120px" class="rounded-circle ml-3">\n' +
                                    '        </div>\n' +
                                    '    </div>\n' +
                                    '</div>'
                            },
                            {
                                visible: !this.$auth.loggedIn,
                                image_url: require('assets/images/icons/menu/confestetica.svg'),
                                title: 'Chi siamo',
                                url: '/chi-siamo'
                            },
                            {
                                visible: this.$auth.loggedIn,
                                image_url: require('assets/images/icons/menu/blue-group.svg'),

                                title: 'Gruppo Estetiste Professioniste',
                                url: '/gruppo'
                            },
                            {
                                visible: !this.$auth.loggedIn,
                                image_url: require('assets/images/ico-c.png'),
                                title: 'Centri estetici',
                                url: '/centri-estetici'
                            },
                            {
                                visible: !this.$auth.loggedIn,
                                image_url: require('assets/images/estetista-circle.png'),
                                title: 'Albo Confestetica',
                                url: '/estetiste'
                            },
                            {
                                visible: true,
                                image_url: require('assets/images/icons/menu/communications.svg'),
                                title: 'Comunicazioni e circolari',
                                url: '/comunicazioni'
                            },
                            {
                                visible: true,
                                image_url: require('assets/images/icons/menu/punto-estetico.svg'),
                                title: 'Punto Estetico - Info di settore',
                                url: '/punto-estetico'
                            },
                            {
                                visible: !this.$auth.loggedIn,
                                image_url: require('assets/images/icons/menu/blue-group.svg'),
                                title: 'Gruppo Estetiste Professioniste',
                                url: '/gruppo'
                            },
                            {
                                visible: true,
                                image_url: require('assets/images/ico-c.png'),
                                title: 'Segnala un\'abusiva',
                                url: '/abusive'
                            },
                            {
                                visible: this.$auth.loggedIn,
                                image_url: require('assets/images/icons/menu/borsellino.svg'),
                                title: 'Borsellino elettronico',
                                right_content: this.$auth.loggedIn && this.$auth.user.wallet_balance ? '€ ' + this.$auth.user.wallet_balance : 'Attivalo',
                                right_content_style: {
                                    fontWeight: 'bold',
                                    fontSize: 19,
                                    marginRight: 10
                                },
                                url: '/borsellino'
                            },
//                            {
//                                visible: this.$auth.loggedIn,
//                                image_url: require('assets/images/icons/menu/education.svg'),
//                                title: 'Corsi di formazione',
//                                subtitle: '',
//                                url: '/education'
//                            },
//                             {
//                                 visible: this.$auth.loggedIn && this.$auth.user.beauty_centre,
//                                 image_url: require('assets/images/icons/marketplace-f.svg'),
//                                 title: 'Marketplace',
//                                 subtitle: '',
//                                 url: '/marketplace'
//                             },
                            {
                                visible: true,
                                image_url: require('assets/images/icons/purchasing-group-f.svg'),
                                title: 'Gruppi d\'acquisto',
                                subtitle: '',
                                url: '/gruppi-di-acquisto'
                            },
                            {
                                visible: this.$auth.loggedIn,
                                image_url: require('assets/images/icons/menu/invoices.svg'),
                                title: 'Fatture',
                                subtitle: '',
                                url: '/fatture'
                            }
                        ]
                    },
                    // {
                    //     visible: this.hasACompanyAccount,
                    //     expanded: true,
                    //     dropdown: true,
                    //     title: 'STRUMENTI DI LAVORO',
                    //     items: [
                    //         {
                    //             visible: true,
                    //             image_url: require('assets/images/icons/menu/work-f.svg'),
                    //             title: 'Lavoro',
                    //             subtitle: '',
                    //             url: '/job-placement'
                    //         }
                    //     ]
                    // },
                    {
                        visible: true,
                        items: [
                            {
                                visible: this.$auth.loggedIn && this.$auth.user.is_admin,
                                expanded: false,
                                dropdown:true,
                                classes: [
                                    'menu-dropdown'
                                ],
                                text_container_style_web: {
                                    'border-bottom': 'none!important'
                                },
                                touchable_style: {
                                    borderBottomWidth: 1,
                                    borderColor: '#ebebeb',
                                    backgroundColor: '#f4f2f3'
                                },
                                touchable_style_web: {
                                    'border-bottom': '1px solid #ebebeb',
                                    'background-color': '#f4f2f3'
                                },
                                image_url: require('assets/images/icons/menu/admin-tools.svg'),
                                title: 'Strumenti amministratore',
                                subtitle: '',
                                url: '',
                                dropdown_items: [
                                    {
                                        visible: this.$auth.loggedIn && this.$auth.user.is_admin,
                                        dropdown: true,
                                        expanded: false,
                                        image_url: require('assets/images/icons/menu/settings.svg'),
                                        title: 'Impostazioni sviluppatore',
                                        subtitle: '',
                                        url: '',
                                        dropdown_items:  [
                                            {
                                                visible: true,
                                                touchable_style: {
                                                    backgroundColor: '#fff'
                                                },
                                                image_url: require('assets/images/icons/menu/settings.svg'),
                                                title: 'Modifica ambiente di sviluppo',
                                                admin: true,
                                                subtitle: '',
                                                url: '',
                                                method: 'openDebugMenu'
                                            }
                                        ]
                                    },
                                    {
                                        visible: true,
                                        image_url: require('assets/images/icons/menu/admin-tools.svg'),
                                        title: 'Pannello amministrazione',
                                        url: '/pannello-amministrazione'
                                    },
                                    {
                                        visible: true,
                                        dropdown: false,
                                        expanded: false,
                                        text_container_style_web: {
                                            'border-bottom': 'none!important'
                                        },
                                        image_url: require('assets/images/icons/menu/settings.svg'),
                                        title: 'Gestione pagine',
                                        subtitle: '',
                                        url: '/gestione-pagine',
                                    }
                                ]
                            },
                            {
                                visible: true,
                                expanded: false,
                                dropdown: true,
                                classes: [
                                    'menu-dropdown'
                                ],
                                text_container_style_web: {
                                    'border-bottom': 'none!important'
                                },
                                touchable_style: {
                                    borderBottomWidth: 1,
                                    borderColor: '#ebebeb',
                                    backgroundColor: '#f4f2f3'
                                },
                                touchable_style_web: {
                                    'border-bottom': '1px solid #ebebeb',
                                    'background-color': '#f4f2f3'
                                },
                                image_url: require('assets/images/icons/menu/support.svg'),
                                title: 'Assistenza e supporto',
                                subtitle: '',
                                url: '',
                                dropdown_items:  [
                                    {
                                        visible: this.$auth.loggedIn,
                                        image_url: require('assets/images/icons/menu/assistance-chat.svg'),
                                        title: 'Messaggi assistenza',
                                        url: '/chat'
                                    },
                                    {
                                        visible: this.app,
                                        image_url: require('assets/images/icons/menu/crash-report.svg'),
                                        title: 'Segnala un problema',
                                        subtitle: '',
                                        method: 'openCrashReport'
                                    }
                                ]
                            },
                            {
                                visible: true,
                                expanded: false,
                                classes: [
                                    'menu-dropdown'
                                ],
                                dropdown: true,
                                text_container_style_web: {
                                    'border-bottom': 'none!important'
                                },
                                touchable_style: {
                                    borderBottomWidth: 1,
                                    borderColor: '#ebebeb',
                                    backgroundColor: '#f4f2f3'
                                },
                                touchable_style_web: {
                                    'border-bottom': '1px solid #ebebeb',
                                    'background-color': '#f4f2f3'
                                },
                                image_url: require('assets/images/icons/menu/settings.svg'),
                                title: 'Impostazioni e privacy',
                                subtitle: '',
                                url: '',
                                dropdown_items:  [
                                    {
                                        visible: true,
                                        image_url: require('assets/images/icons/menu/terms.svg'),
                                        title: 'Termini e condizioni',
                                        subtitle: '',
                                        url: 'https://www.iubenda.com/termini-e-condizioni/62789847'
                                    },
                                    {
                                        visible: true,
                                        image_url: require('assets/images/icons/menu/privacy.svg'),
                                        title: 'Normative sulla privacy',
                                        subtitle: '',
                                        url: 'https://www.iubenda.com/privacy-policy/62789847'
                                    },
                                    {
                                        visible: true,
                                        image_url: require('assets/images/icons/menu/cookies.svg'),
                                        title: 'Normativa sui cookie',
                                        subtitle: '',
                                        url: 'https://www.iubenda.com/privacy-policy/62789847/cookie-policy'
                                    },
                                    {
                                        visible: this.$auth.loggedIn,
                                        image_url: require('assets/images/icons/menu/settings.svg'),
                                        title: 'Il mio account',
                                        subtitle: '',
                                        url: '/account'
                                    }
                                ]
                            },
                            {
                                visible: !this.$auth.loggedIn,
                                text_container_style_web: {
                                    'border-bottom': 'none!important'
                                },
                                classes: [
                                  'menu-dropdown'
                                ],
                                touchable_style: {
                                    backgroundColor: '#f4f2f3'
                                },
                                touchable_style_web: {
                                    'background-color': '#f4f2f3'
                                },
                                image_url: require('assets/images/icons/menu/logout.svg'),
                                title: 'Accedi',
                                subtitle: '',
                                url: '/'
                            },
                            {
                                visible: this.$auth.loggedIn,
                                text_container_style_web: {
                                    'border-bottom': 'none!important'
                                },
                                classes: [
                                    'menu-dropdown'
                                ],
                                touchable_style: {
                                    backgroundColor: '#f4f2f3'
                                },
                                touchable_style_web: {
                                    'background-color': '#f4f2f3'
                                },
                                image_url: require('assets/images/icons/menu/logout.svg'),
                                title: 'Esci',
                                subtitle: '',
                                method: 'logout'
                            },
                        ]
                    }
                ];
            },
            closeMenu() {
                this.menuOpen = false;
            },
            toggleMenu() {

                if (this.$route.path !== '/menu' && this.mobileMode) {
                    this.$router.push('/menu')
                }

                this.getMenuItems();
                this.menuOpen = !this.menuOpen
            },

            toggleItem(item) {

                if (item.dropdown) {
                    item.expanded = !item.expanded;
                }
            },

            itemClick(item) {

                if (this.$route.path !== '/menu') {
                    this.toggleMenu()
                }

                if (item.method) {

                    this[item.method]();
                } else if (item.url) {
                    if(item.url.startsWith('http')) {
                        window.open(item.url, '_blank')
                    } else {
                        this.$router.push(item.url)
                    }
                } else {
                    this.toggleItem(item)
                }
            },

            openDebugMenu() {

                let event = {
                    event: 'open-debug',
                }

                if (window.ReactNativeWebView) {

                    window.ReactNativeWebView.postMessage(JSON.stringify(event));
                } else {

                    window.postMessage(JSON.stringify(event), '*');
                }
            },

            handleSdkInit({ FB, scope }) {
              this.facebookSDK = FB
              this.scope = scope
            },

            logout() {
                this.processLogout(this.facebookSDK)
                    .then(() => {
                        this.$router.push('/')
                });
            }
        },

        created () {
            if (this.visibleOnOpen) {
                this.getMenuItems();
            }
        }
    }
</script>

<style lang="scss" scoped>
    hr {
        margin: 0!important;
        border-color: #F4F2F3;
    }
</style>
