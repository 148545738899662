<template>
    <div>
        <div class="card text-center">
            <div class="card-header card-header-sm  d-flex justify-content-center align-items-center">
                <i class="cf-back absolute p-3" style="left: 0;" @click.prevent="previousStep()"></i>
                {{ $t('Verifica licenza') }}
            </div>
            <div class="card-body">
                <div class="mt-lg-5 mb-2">
                    <img alt="step-10" class="w-100 icon" src="~/assets/images/steps/step-10.png">
                </div>
                <div class="p-4">
                    <div class="title">
                        {{ $t('Verifica allegati in corso...')}}
                    </div>
                    <div class="description mt-4">
                        {{ $t('Grazie per aver caricato la licenza del tuo centro estetico!') }}
                    </div>
                    <div class="description mt-4">
                        {{ $t('Il tuo centro estetico sarà validato e certificato.') }}<br>
                        {{ $t('Riceverai presto una notifica con l\'esito.')}}
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <button class="btn btn-brand w-100" @click.prevent="nextStep()">
                    {{ $t('Avanti') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState, mapMutations } from 'vuex'
    import _ from 'lodash'

    export default {

        data() {
            return {
                email: '',
                message: 'Ciao, entra anche tu a far parte della community di confestetica! Registrati sul sito ' + encodeURIComponent(process.env.BASE_URL + '/?ref=' + this.$auth.user.referral_code) + ' per maggiori informazioni'
            }
        },

        props: ['type'],

        computed: {
            ...mapState({
                'user': 'user',
                'account': 'account',
                'workExperience': 'work-experience'
            })
        },

        methods: {
            ...mapActions({
                'refetchUser': 'user/refetch'
            }),
            ...mapMutations({
                'setWorkExperienceType': 'work-experience/SET_TYPE',
                'setWorkExperienceStep': 'work-experience/SET_STEP'
            }),
            previousStep() {
                    this.setWorkExperienceStep('certificate')
            },
            nextStep() {
                this.setWorkExperienceStep('location-photo')
            }
        },

        mounted () {
        },
    }
</script>