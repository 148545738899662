<template>
    <div>
        <input type="file" name="image" id="image-input" accept="image/*" style="display: none" @change="generatePreviews" v-if="!mobileMode" multiple/>
        <div class="d-flex" :class="size == 'small' ? 'overflow-auto' : 'flex-wrap'" v-if="layout === 'horizontal'">
            <div class="my-2 mx-1">
                <div class="image-preview d-flex justify-content-center align-items-center flex-column border-secondary alert alert-dashed cursor-pointer" :class="'image-preview-' + size" @click.prevent="openInput">
                    <img alt="photo-icon" src="~/assets/images/icons/photo-icon-grey.svg" width="30px" height="30px">
                    <div class="title text-primary text-center" v-if="size != 'small'">{{ $t('Scegli una o più immagini')}}</div>
                </div>
            </div>
            <div v-for="(image, index) in images" class="my-2 mx-1 show-on-hover">
                <div class="image-preview bordered relative d-flex justify-content-center align-items-center" :class="'image-preview-' + size" :style="'background-size: cover; background-repeat: no-repeat; background-image: url(' + (image.image_res ? image.image_res.medium : image.path) + ');opacity: ' + ((getUploadPercentage(image) !== 100 && !image.id && immediateSaving) ? 0.5 : 1)" @click.prevent="showLoaderImagesModal(index)">
                    <span class="d-hover-inline remove-image close cursor-pointer" v-if="!mobileMode" @click.prevent.stop="removeImage(image)">×</span>
                    <span class="remove-image mobile cursor-pointer text-white" v-else @click.prevent.stop="removeImage(image)">
                        <i class="la la-minus"></i>
                    </span>
                    <b-progress v-if="getUploadPercentage(image) !== 100 && !image.id && immediateSaving" :value="getUploadPercentage(image)" :max="100" variant="success" style="width: 100px"></b-progress>
                </div>
            </div>
        </div>
        <div v-else-if="layout === 'grid'">
            <div v-for="postImagesGroup in postImagesGroups" class="gallery-row d-flex relative">
                <div v-if="processingImages" class="absolute w-100 h-100 d-flex align-items-center justify-content-center" style="background-color: #555a; z-index: 1">
                    <i class="fa fa-spin">
                        <img alt="loading" src="~/assets/images/icons/loading.svg" width="36px">
                    </i>
                </div>
                <div v-for="(image, index) in postImagesGroup"
                     class="gallery-image flex-grow-1 position-relative"
                     :style="'padding-top: ' + (postImagesGroup.length === 1 ? 50 : (100 / postImagesGroup.length)) + '%; background-size: cover; background-position: center; background-repeat: no-repeat; background-image: url(' + (image.image_res ? image.image_res.medium : image.path) + ')'"
                     @click.prevent="showLoaderImagesModal(index)"
                >
                    <div class="more-images-overlay d-none justify-content-center align-items-center" v-if="images.length > 5"> +{{ images.length - 5 }}</div>
                </div>
            </div>
        </div>
        <images-gallery ref="loaderImagesGallery" v-if="images.length" :images="images" url="/api/post-images">
            <template slot="dropdown-menu">
                <b-dropdown right no-caret variant="link" class="d-none d-lg-block">
                    <template slot="button-content">
                        <i class="cf-more"></i>
                    </template>
                    <b-dropdown-item class="text-danger" @click.prevent="deleteImage">{{ $t('Elimina immagine') }}</b-dropdown-item>
                </b-dropdown>
                <div class="dropdown d-block d-lg-none">
                    <dropup-menu :bottom="50" :hide-bottom-bar="true" :show-overlay="true">
                        <template slot="menu-icon">
                            <i class="cf-more btn-link text-white"></i>
                        </template>
                        <template slot="menu-options">
                            <ul class="menu-options">
                                <li class="text-danger" @click.prevent="deleteImage">
                                    <i class="fa fa-trash mr-2"></i> {{ $t('Elimina immagine')}}
                                </li>
                            </ul>
                        </template>
                    </dropup-menu>
                </div>
            </template>
            <template slot="resource-image" v-if="$auth.loggedIn">
                <user-image class="relative" :user="$auth.user" size="50">
                    <template slot="profile-rank">
                        <profile-rank class="absolute bottom right" :hide-text="true" :user="$auth.user" size="medium"></profile-rank>
                    </template>
                </user-image>
            </template>
            <template slot="resource-name" v-if="$auth.loggedIn">
                <nuxt-link class="title font-weight-bold text-white" :to="'/estetiste/' + $auth.user.id + '-' + $auth.user.slug">{{ $auth.user.name }}</nuxt-link>
            </template>
            <template slot="dropup-menu">
                <dropup-menu :hide-bottom-bar="true">
                    <template slot="menu-options">
                        <ul>
                            <li @click.prevent="openInput">
                                <i class="fa fa-photo mr-2"></i>
                                {{ $t('Foto/video')}}
                            </li>
                            <li>
                                <i class="cf-camera mr-2"></i>
                                {{ $t('Fotocamera')}}</li>
                            <li>
                                <i class="cf-friends mr-2"></i>
                                {{ $t('Tagga persone')}}
                            </li>
                        </ul>
                    </template>
                </dropup-menu>
            </template>
        </images-gallery>
    </div>
</template>

<script>

    import { mapState } from 'vuex';
    import _ from 'lodash'

    export default {

        props: {
            'layout': {
                default: function () {
                    return 'grid'
                }
            },
            'event': {
                default: function () {
                    return null
                }
            },
            'url': {
                type: String,
                default: function () {
                    return ''
                }
            },
            'size': {
                type: String,
                default: function () {
                    return 'medium'
                }
            },
            'images': {
                type: Array,
                default: function () {
                    return []
                }
            },
            'immediateSaving': {
                type: Boolean,
                default: function () {
                    return true
                }
            },
            'onImageDeleted': {
                type: Function,
                default: function () {
                    return function() {}
                }
            }
        },

        data() {

            return {
                files: [],
                processingImages: false,
                uploadPercentages: []
            }
        },

        watch: {

            'event': {
                deep: true,
                handler: function () {
                    this.generatePreviews(this.event)
                }
            },

            'images': function () {
                this.$emit('input', this.images);
            }
        },

        computed: {

            ...mapState([
               'mobileMode'
            ]),
            postImagesGroups() {

                let imagesGroups = [];
                let groupMaxLength = 3;

                let postImages = _.take(this.images, 5);

                if (postImages.length) {
                    _.each(postImages, function (image) {
                        if (imagesGroups.length > 1) {

                            let i = imagesGroups.length;

                            while(imagesGroups[i - 1].length >= groupMaxLength && i > 0) {
                                i--
                            }

                            if (i > 0) {
                                imagesGroups[i - 1].push(image);
                            }

                        } else {
                            imagesGroups.push([]);
                            let imageGroup = _.last(imagesGroups);
                            imageGroup.push(image);
                        }
                    })
                }

                return imagesGroups;
            }
        },

        methods: {

            openInput(isVisible) {

                if (isVisible) {

                    let image = document.getElementById("image-input")

                    image.click()
                }
            },

            generateUniqueName () {

                return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10);
            },

            dataURLToBlob (dataURL) {
                let BASE64_MARKER = ';base64,';
                if (dataURL.indexOf(BASE64_MARKER) == -1) {
                    let parts = dataURL.split(',');
                    let contentType = parts[0].split(':')[1];
                    let raw = parts[1];

                    return new Blob([raw], {type: contentType});
                }

                let parts = dataURL.split(BASE64_MARKER);
                let contentType = parts[0].split(':')[1];
                let raw = window.atob(parts[1]);
                let rawLength = raw.length;

                let uInt8Array = new Uint8Array(rawLength);

                for (let i = 0; i < rawLength; ++i) {
                    uInt8Array[i] = raw.charCodeAt(i);
                }

                return new Blob([uInt8Array], {type: contentType});
            },

            generatePreviews (e) {

                let _self = this;

                if (window.File && window.FileList && window.FileReader && e) {

                    this.processingImages = true;
                    this.files = _.toArray(e.target.files || e.dataTransfer.files);

                    for (let i = 0; i < this.files.length; i++) {
                        let file = this.files[i];
                        //Only pics
                        if (!file.type.match('image')) continue;

                        let picReader = new FileReader();

                        picReader.addEventListener("load", function (event) {

                            let image = new Image();
                            image.onload = function (imageEvent) {

                                let canvas = document.createElement('canvas'),
                                    max_size = process.env.IMAGINATION_MAX_UPLOAD_SIZE || '1920',
                                    width = image.width,
                                    height = image.height;
                                if (width > height) {
                                    if (width > max_size) {
                                        height *= max_size / width;
                                        width = max_size;
                                    }
                                } else {
                                    if (height > max_size) {
                                        width *= max_size / height;
                                        height = max_size;
                                    }
                                }

                                canvas.width = width;
                                canvas.height = height;
                                canvas.getContext('2d').drawImage(image, 0, 0, width, height);

                                let dataUrl = canvas.toDataURL('image/jpeg');
                                let resizedImage = _self.dataURLToBlob(dataUrl);

                                let data = new FormData();

                                let imageName = '';
                                let duplicates = [];

                                //Ensure a unique name for the file
                                do {
                                    imageName = _self.generateUniqueName();

                                    duplicates = _.filter(_self.images, function (image) {
                                        return image.name === imageName;
                                    })
                                }
                                while (duplicates.length)

                                _self.images.push({
                                    path: event.target.result,
                                    name: imageName,
                                    data: resizedImage
                                });

                                _self.$emit('update:event', null)
                                _self.processingImages = false;

                                data.append('location_image', resizedImage);
                                data.append('location_image_name', imageName);

                                if (_self.immediateSaving) {
                                    _self.$axios.post(
                                        _self.url,
                                        data,
                                        {
                                            onUploadProgress(progressEvent) {

                                                _self.uploadPercentages.push({
                                                    name: imageName,
                                                    progress: parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))
                                                });
                                            }
                                        }).then((response) => {

                                        let myImage = _.find(_self.images, {name: response.data.old_name});
                                        myImage.id = response.data.image.id;
                                    });
                                }
                            }
                            image.src = event.target.result;
                        });
                        //Read the image
                        picReader.readAsDataURL(file);
                    }
                } else {
                    console.log("Your browser does not support File API");
                }

                // this.$refs.uploadImagesModal.show();
            },
            showLoaderImagesModal (index) {
                this.$refs.loaderImagesGallery.showImagesGalleryModal(index);
            },
            postImages () {

                this.publishImages(this.images).then((response) => {
                    this.hideImagesModal();
                    this.refetchAccount();
                });
            },

            // FIXME
            deleteImage() {

                if (this.images.length > 0) {
                    if (this.images[0].id) {
                        this.$refs.loaderImagesGallery.deleteImage();
                    }
                    else {
                        this.deleteFirstImage()
                    }
                }
            },

            deleteFirstImage () {

                this.removeImage(this.images[0]);
                this.$refs.loaderImagesGallery.hideImagesGalleryModal()
                this.onImageDeleted(this.images[0])
            },

            removeImage (image) {

                this.images = _.filter(this.images, function (myImage) {

                    return (myImage.name !== image.name) || (myImage.id !== image.id);
                })

                this.files = _.filter(this.files, function (file) {

                    return (file.name !== image.name);
                })
            },

            getUploadPercentage (image) {

                let upload = _.find(this.uploadPercentages, {'name': image.name});

                return upload ? upload.progress : 0;
            }
        },
        created () {

            this.$emit('update:layout', this.mobileMode ? 'grid' : 'horizontal')

            if (this.event) {
                this.generatePreviews(this.event)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .image-preview {
        &.image-preview-medium {
            width: 205px;
            height: 260px;
        }
        &.image-preview-small {
            width: 94px;
            height: 94px;
        }

        border-radius: 4px;

        &.bordered {
            border: 1px solid #F9F8F9;
        }

        .remove-image {
            position: absolute;
            top: 5px;
            right: 5px;

            &.mobile {
                top: -8px;
                right: -8px;
                width: 20px;
                height: 20px;
                font-size: 14px;
                background-color: black;
                border: 2px solid white;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        > i.cf-add {
            font-size: 40px;
        }

        .title {
            font-size: 18px;
        }
    }

    .gallery-row {
        margin-left: -20px!important;
        margin-right: -20px!important;
    }
</style>
