<template>
    <div class="card w-100" :style="app ? 'height:auto!important;' : ''">
        <div class="card-body scrollable-content overflow-auto p-0" v-show="step === 'payment'">
            <div class="card border-0">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <div class="d-flex justify-content-center align-items-center relative mr-4">
                            <img alt="shield" src="~/assets/images/subscription/shield.svg" width="75px">
                            <img alt="ico-c" class="absolute" src="~/assets/images/ico-c.png" width="40px">
                        </div>
                        <div>
                            <div class="recap-title font-size-sm text-uppercase">{{ $t('Confestetica') }}</div>
                            <div class="recap-text">
                                <div class="text-grey">{{ $t('Quota associativa annuale')}}</div>
                                <div>{{ $t('Dal') }} <strong>{{ fromDate }}</strong> {{ $t('al') }} <strong>{{ expirationDate }}</strong></div>
                                <div class="total text-primary ">
                                    € <span v-if="selectedPlan">{{ (selectedPlan.amount).toFixed(2) }}</span><i v-else class="fa fa-spinner fa-spin"></i>
                                    {{ $t('annui')}}
                                </div>
                            </div>
                            <div class="mt-1" v-if="getOrderedPlans().length > 1" @click.prevent="$emit('update:step', 'plans')"><u class="font-size-xs text-brand">{{ $t('Visualizza altre offerte') }}</u></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-body p-3" v-if="!$auth.loggedIn">
                    <div class="font-weight-bold text-grey mb-2">
                        {{ $t('REGISTRATI')}}
                    </div>
                    <div class="d-flex flex-column">
                        <input class="form-control form-control has-label" @focus="clearErrorList" name="first_name" :placeholder="$t('Nome')" autocomplete="off" v-model="first_name" />
                        <div class="form-error text-danger" v-if="errorList.first_name">{{ errorList.first_name[0] }}</div>
                    </div>
                    <div class="d-flex mt-2 flex-column">
                        <input class="form-control form-control has-label" @focus="clearErrorList" name="last_name" :placeholder="$t('Cognome')" autocomplete="off" v-model="last_name" />
                        <div class="form-error text-danger" v-if="errorList.last_name">{{ errorList.last_name[0] }}</div>
                    </div>
                    <div class="d-flex mt-2 flex-column">
                        <input class="form-control has-label" name="email" @focus="clearErrorList" :placeholder="$t('E-mail')" autocomplete="off" v-model="email" />
                        <strong class="form-error text-danger mt-2" v-if="errorList.email">{{ errorList.email[0] }}</strong>
                    </div>
                    <div class="d-flex mt-2 flex-column">
                        <input v-input-password class="form-control has-label" name="password" @focus="clearErrorList" type="password" :placeholder="$t('Password')" autocomplete="off" v-model="password" />
                        <strong class="form-error text-danger mt-1" v-if="errorList.password">{{ errorList.password[0] }}</strong>
                    </div>
                    <div class="mt-2 text-center">
                        <a href="#" class="font-size-sm text-brand font-weight-medium" @click.prevent="$emit('update:step', 'login')"><u>{{ $t('Ho già un account') }}</u></a>
                    </div>
                </div>
                <div class="card-body p-3" v-else>
                    <div class="d-flex">
                        <user-image :user="$auth.user" :size="50"></user-image>
                        <div class="ml-2">
                            <div class="font-size-xl font-weight-bold">{{ $auth.user.first_name }} {{ $auth.user.last_name }}</div>
                            <profile-rank class="font-weight-medium mt-1" :user="$auth.user"></profile-rank>
                        </div>
                    </div>
                </div>
                <!--<button class="btn btn-post mt-2 btn-brand w-100 font-size-lg" @click.prevent="validateData">{{ $t('Avanti') }}</button>-->

                <strong class="background-gray text-uppercase pl-3 p-2" v-if="!app">{{ $t('Metodo di pagamento')}}</strong>
                <div class="card-body pt-0 p-0" v-if="!app">
                    <div class="payment-method-input d-flex align-items-center" @click.prevent="payment_method = 'stripe'">
                        <div class="mr-2">
                            <i class="fa fa-check-circle text-primary" v-if="payment_method == 'stripe'"></i>
                            <i class="fa fa-circle-o text-grey" v-else></i>
                        </div>
                        <div class="w-100">
                            <div class="payment-method-item">
                                <strong>{{ $t('Carta di credito') }}</strong>
                            </div>
                        </div>
                    </div>
                    <div v-show="payment_method === 'stripe'">
                        <div v-if="$auth.loggedIn && $auth.user && $auth.user.default_payment_method" class="d-flex justify-content-between align-items-center mr-3 ml-3 mb-3">
                            <div v-if="$auth.user.default_payment_method.card">
                                <img class="mr-2" :src="require('~/assets/images/cards/' + getCardName($auth.user.default_payment_method.card.brand) + '.png')" height="24px">
                                <strong>{{ $auth.user.default_payment_method.card.brand }}</strong> {{ $t('termina con ')}} {{ $auth.user.default_payment_method.card.last4 }}
                            </div>
                            <u class="text-dark-grey font-size-xs" @click.prevent="setStep('cards')">{{ $t('Modifica')}}</u>
                        </div>
                        <div v-else>
                            <div class="form-group w-100" style="max-width: inherit">
                                <card-number class='has-label stripe-element card-number'
                                             ref='cardNumber'
                                             :placeholder="$t('Numero carta di credito')"
                                             :stripe="stripeApiKey"
                                             :options='stripeOptions'
                                             @change='number = $event.complete'
                                />
                            </div>
                            <div class="d-flex">
                                <div class="form-group flex-grow-1 flex-basis-0">
                                    <card-expiry class='stripe-element card-expiry'
                                                 ref='cardExpiry'
                                                 :stripe="stripeApiKey"
                                                 :options='stripeOptions'
                                                 @change='expiry = $event.complete'
                                    />
                                </div>
                                <div class="form-group flex-grow-1 flex-basis-0">
                                    <card-cvc class='stripe-element card-cvc'
                                              ref='cardCvc'
                                              :stripe="stripeApiKey"
                                              :options='stripeOptions'
                                              @change='cvc = $event.complete'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <!--<div class="payment-method-input d-flex align-items-center" @click.prevent="payment_method = 'paypal'">-->
                    <!--<div class="mr-2">-->
                    <!--<i class="fa fa-check-circle text-primary" v-if="payment_method == 'paypal'"></i>-->
                    <!--<i class="fa fa-circle-o text-grey" v-else></i>-->
                    <!--</div>-->
                    <!--<div class="row w-100">-->
                    <!--<div class="payment-method-item col-lg-6">-->
                    <!--<strong :class="payment_method == 'paypal' ? 'selected' : 'paypal'">{{ $t('PayPal') }}</strong>-->
                    <!--</div>-->
                    <!--&lt;!&ndash;<div class="payment-method-item col-lg-6 mt-2 mt-lg-0">&ndash;&gt;-->
                    <!--&lt;!&ndash;<img alt="paypal" class="payment-method-logo" src="~/assets/images/paypal.png">&ndash;&gt;-->
                    <!--&lt;!&ndash;</div>&ndash;&gt;-->
                    <!--</div>-->
                    <!--</div>-->
                    <!--<hr>-->
                    <!--<div class="payment-method-input d-flex align-items-center" @click.prevent="payment_method = 'bank_transfer'">-->
                    <!--<div class="mr-2">-->
                    <!--<i class="fa fa-check-circle text-primary" v-if="payment_method == 'bank_transfer'"></i>-->
                    <!--<i class="fa fa-circle-o text-grey" v-else></i>-->
                    <!--</div>-->
                    <!--<div class="row w-100">-->
                    <!--<div class="payment-method-item col-lg-6">-->
                    <!--<strong :class="payment_method == 'bank_transfer' ? 'selected' : ''">{{ $t('Bonifico bancario') }}</strong>-->
                    <!--</div>-->
                    <!--&lt;!&ndash;<div class="payment-method-item col-lg-6 mt-2 mt-lg-0">&ndash;&gt;-->
                    <!--&lt;!&ndash;<img alt="sofort-logo" class="payment-method-logo" src="~/assets/images/sofort-logo.png">&ndash;&gt;-->
                    <!--&lt;!&ndash;</div>&ndash;&gt;-->
                    <!--</div>-->
                    <!--</div>-->
                    <!--<hr>-->
                    <strong class="d-flex background-gray text-uppercase px-3 py-2 w-100 justify-content-between align-items-center">
                        {{ $t('Dati fatturazione')}}
                        <div class="switch switch-sm">
                            <input :id="'switch' + _uid" type="checkbox" v-model="with_invoice" class="switch-sm"/>
                            <label :for="'switch' + _uid" class="m-0" style="font-size: 15px"></label>
                        </div>
                    </strong>
                    <div v-if="with_invoice" class="p-3">
                        <div class="form-group mb-2" style="max-width: inherit">
                            <input class="form-control has-label" name="name" :placeholder="$t('Ragione sociale')" autocomplete="off" v-model="name" />
                            <div class="form-error" v-if="errors.has('name')">{{ errors.first('name') }}</div>
                        </div>
                        <div class="form-group mb-2" style="max-width: inherit">
                            <input class="form-control has-label" name="vat_id" :placeholder="$t('Partita IVA')" autocomplete="off" v-model="vat_id" />
                            <div class="form-error" v-if="errors.has('vat_id')">{{ errors.first('vat_id') }}</div>
                        </div>

                        <div class="form-group mb-2" style="max-width: inherit">
                            <input class="form-control has-label" name="address" v-validate="'required'" :placeholder="$t('Indirizzo')" autocomplete="off" v-model="address" />
                            <div class="form-error" v-if="errors.has('address')">{{ errors.first('address') }}</div>
                        </div>
                    </div>
                    <!--<div class="payment-method-input d-flex ">-->
                    <!--<input type="checkbox" name="read_terms" style="vertical-align: baseline" v-model="read_terms" />-->
                    <!--<div class="ml-2">{{ $t('Ho letto e accetto le Condizioni d\'uso, la')}} <a href="#">{{ $t('Politica sulla Privacy') }}</a> {{ $t('e la') }} <a href="#">{{ $t('Politica sui Cookies.') }}</a>*</div>-->
                    <!--</div>-->
                </div>
            </div>
        </div>
        <div v-if="step === 'plans'">
            <div class="card-header card-header-sm d-flex justify-content-center align-items-center">
                <i class="cf-back absolute p-3" style="left: 0" @click.prevent="$emit('update:step', 'payment')"></i>
                <span>{{ $t('Piani disponibli')}}</span>
            </div>
            <div class="card-body p-0">
                <div class="card">
                    <div class="card-body pt-0 p-0">
                        <div class="plan p-3" v-for="(plan, index) in getOrderedPlans()" :key="plan.id">
                            <div class="font-size-extra text-center mb-3">
                                {{ plan.title }}
                            </div>
                            <div class="text-dark-grey text-center mb-3">
                                {{ plan.description }}
                            </div>
                            <div class="d-flex justify-content-center subscription-cost">
                                <div class="text-vanitas yearly-cost mt-1">
                                    € {{ (plan.amount).toFixed(2) }}
                                </div>
                                <strong class="text-grey align-self-end">
                                    /{{ $t('anno') }}
                                </strong>
                            </div>
                            <div class="mt-3">
                                <button class="btn btn-brand m-auto" @click.prevent="setPlan(plan.id)">{{ $t('Scegli offerta')}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer text-center" v-if="!app && step !== 'plans'">
            <button class="btn btn-brand w-100" v-if="!$auth.loggedIn || ($auth.loggedIn && !$auth.user.subscribed)" @click.prevent="validateData" :disabled="processing || payment_method !== 'stripe' || !selectedPlan">{{ $t('Procedi al pagamento') }}</button>
            <button class="btn btn-brand w-100" disabled v-else-if="$auth.loggedIn && $auth.user.subscription_status === 'pending'">{{ $t('In attesa di completamento')}}</button>
        </div>
        <div v-else-if="step !== 'plans'" class="card-footer text-center">
            <button class="btn btn-brand w-100" v-if="($auth.loggedIn && !$auth.user.subscribed)" :disabled="appProcessing || payment_method !== 'stripe' || !selectedPlan" @click.prevent="appPurchase">
                <span v-if="!appProcessing">{{ $t('Procedi al pagamento') }}</span>
                <span v-else> {{ $t('In attesa di completamento')}}</span>
            </button>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
    import { mapFields } from 'vuex-map-fields'
    import { Card, CardNumber, CardExpiry, CardCvc, createPaymentMethod } from 'vue-stripe-elements-plus'

    export default {

        auth: false,

        props: {
            step: {
                type: String,
                default: function () {
                    return 'payment'
                }
            },
            loginCallback: {
                type: Function,
                default: function () {
                    return null
                }
            }
        },

        components: {
            Card,
            CardNumber,
            CardExpiry,
            CardCvc
        },

        data() {

            let elementStyles = {
                base: {
                    color: '#403D40',
                    fontFamily: 'Acumin Pro',
                    fontSize: '20px',

                    '::placeholder': {
                        color: '#A6ACAF'
                    }
                },
                invalid: {
                    color: '#FA755A',
                    ':focus': {
                        color: '#FA755A',
                    },
                    '::placeholder': {
                        color: '#FFCCA5',
                    },
                },
            };

            let elementClasses = {
                focus: 'focus',
                empty: 'empty',
                invalid: 'invalid',
            };

            return {
                stripeApiKey: process.env.STRIPE_PUBLISHABLE_KEY || 'pk_test_nH1hcGkzXexiZBYsKY72hUXA',
                appProcessing: false,
                errorList: [],
                read_terms: false,
                complete: false,
                stripeOptions: {
                    style: elementStyles,
                    classes: elementClasses
                },
            }
        },

        watch: {
            'account': function() {
                if (this.account) {
                    this.first_name = this.$auth.user.first_name;
                    this.last_name = this.$auth.user.last_name;
                }
            },
            'number': function () {
                this.update()
            },
            'expiry': function () {
                this.update()
            },
            'cvc': function () {
                this.update() }
        },

        computed: {
            ...mapGetters({
                'account': 'account',
                'selectedPlan': 'plans/getSelectedPlan',
                'getOrderedPlans': 'plans/getOrderedPlans',
            }),
            ...mapState([
                'checkout',
                'app'
            ]),
            ...mapFields('checkout', [
                'data.name',
                'data.first_name',
                'data.last_name',
                'data.email',
                'data.password',
                'data.password_confirmation',
                'data.vat_id',
                'data.address',
                'data.with_invoice',
                'data.stripe_payment_method',
                'payment_method',
                'processing'
            ]),
            fromDate () {
                return this.$moment().format('DD/MM/YYYY')
            },
            expirationDate () {
                return this.$moment().add(1, 'year').format('DD/MM/YYYY')
            },
        },

        methods: {
            ...mapActions({
                'fetchPlans': 'plans/fetch',
                'refetchUser': 'user/refetch',
                'processCheckout': 'checkout/processCheckout',
                'login': 'user/login'
            }),
            ...mapMutations({
                selectPlan: 'plans/SELECT_PLAN'
            }),
            requestIAPProducts (products = []) {
                let events = [
                    {
                        event: 'access-token',
                        body: this.$auth.getToken('local')
                    },
                    {
                        event: 'request-iap-products',
                        body: products
                    }
                ]

                _.each(events, event => {
                    if (window.ReactNativeWebView) {
                        window.ReactNativeWebView.postMessage(JSON.stringify(event));
                    } else {
                        window.postMessage(JSON.stringify(event), '*');
                    }
                })
            },
            setPlan(planId) {
                this.selectPlan(planId)
                this.$emit('update:step', 'payment')
            },
            getPlans () {
                if (this.app) {
                    this.$axios.get('/api/in-app-products').then((res) => {
                        this.requestIAPProducts(res.data)
                    })
                } else {
                    this.fetchPlans();
                }
            },
            appPurchase () {
                this.appProcessing = true;

                let event = {
                    event: 'iap-purchase',
                    body: this.selectedPlan.id
                }

                if (window.ReactNativeWebView) {
                    window.ReactNativeWebView.postMessage(JSON.stringify(event));
                } else {
                    window.postMessage(JSON.stringify(event), '*');
                }
            },
            clearErrorList () {
                this.errorList = [];
            },
            getCardName (name) {

                return name.replace(' ', '-').toLowerCase();
            },
            update () {
                this.complete = this.number && this.expiry && this.cvc

                // field completed, find field to focus next
                if (this.number) {
                    if (!this.expiry) {
                        this.$refs.cardExpiry.focus()
                    } else if (!this.cvc) {
                        this.$refs.cardCvc.focus()
                    }
                } else if (this.expiry) {
                    if (!this.cvc) {
                        this.$refs.cardCvc.focus()
                    } else if (!this.number) {
                        this.$refs.cardNumber.focus()
                    }
                }
                // no focus magic for the CVC field as it gets complete with three
                // numbers, but can also have four
            },
            validateData () {
                this.$axios.post('/api/validate-data', this.checkout.data).then(() => {

                    this.pay();

                }).catch((error) => {

                    this.errorList = error.response.data.errors;
                })
            },
            pay () {

                let _self = this;

                let stripe = Stripe(this.stripeApiKey);

                if (this.$auth.loggedIn && this.$auth && this.$auth.user.default_payment_method) {

                    this.$nuxt.$loading.start()

                    this.processCheckout(this.selectedPlan.id).then((response) => {

                        if (response.requiresSCA) {
                            return stripe.handleCardPayment(response.payment_intent_secret).then(result => {
                                this.refetchUser().then(() => {
                                    this.loginCallback();
                                })
                            });
                        } else {
                            return this.refetchUser().then(() => {
                                this.loginCallback();
                            })
                        }

                    }).finally(() => {
                        this.$nuxt.$loading.finish()
                    });

                } else {
                    this.$validator.validate().then(result => {

                        if (!result) return;

                        this.$nuxt.$loading.start()

                        createPaymentMethod('card')
                            .then((data) => {

                                if (!data || !data.paymentMethod) {
                                    this.processing = false;
                                    this.$nuxt.$loading.finish()

                                    this.$notify({
                                        group: 'messages',
                                        type: 'error',
                                        duration: 3000,
                                        title: _self.$t('Errore durante il pagamento.')
                                    });
                                }

                                if (data && data.paymentMethod) {

                                    this.stripe_payment_method = data.paymentMethod;

                                    this.processCheckout(this.selectedPlan.id).then(response => {

                                        if (response.requiresSCA) {
                                            stripe.handleCardPayment(response.payment_intent_secret).then(result => {

                                                if (this.$auth.loggedIn) {

                                                    this.$auth.fetchUser().then(() => {
                                                        this.loginCallback();
                                                        this.$nuxt.$loading.finish();
                                                    })
                                                } else {

                                                    this.login({
                                                        username: this.email,
                                                        password: this.password
                                                    }).then(() => {
                                                        this.loginCallback();
                                                        this.$nuxt.$loading.finish();
                                                    })
                                                }
                                            })
                                        } else {
                                            if (this.$auth.loggedIn) {

                                                this.$auth.fetchUser().then(() => {
                                                    this.loginCallback();
                                                    this.$nuxt.$loading.finish();
                                                })
                                            } else {

                                                this.login({
                                                    username: this.email,
                                                    password: this.password
                                                }).then(() => {
                                                    this.loginCallback();
                                                    this.$nuxt.$loading.finish();
                                                })
                                            }
                                        }

                                    }).catch(() => {
                                        this.$nuxt.$loading.finish()
                                    });
                                }
                            })
                            .catch((error) => {
                                this.$nuxt.$loading.finish()
                            });
                    });
                }
            },
            redirectAmazon () {
                window.open("https://www.amazon.it/dp/1983214388/ref=cm_sw_r_cp_ep_dp_.Y2uBbS9C471J");
            }
        },

        mounted () {
            this.getPlans();
        }
    }
</script>

<style lang="scss" scoped>

    .subscription.card {
        .card-body {
            max-height: inherit!important;
            .card  {
                height: inherit!important;
                .card-body {
                    padding-bottom: 20px !important;
                    max-height: inherit!important;
                }
            }
        }
    }

    .middle {
        flex-basis: 0;
        width: inherit !important;
    }

    .sidebar-right {
        flex-basis: 0;
        max-width: inherit !important;
        width: inherit !important;
    }

    a {
        text-decoration: underline;
        color: inherit;
    }

    hr {
        margin: 0;
    }

    .form-group {
        margin-bottom: 0;
    }

    .payment-method-input {
        padding: 20px 15px;
        i {
            font-size: 24px;
        }
    }

    .payment-method-logo {
        height: 18px;
    }

    .card.cursor-pointer:hover {
        background-color: #f4f4f4;
    }

    .cf-logo-container {
        width: 60px;
        height: 60px;
        border-radius: 6px;
        background-color: #772A79;
    }

    .book-image-container {
        width: 100%;
        height: 120px;
    }

    .total {
        font-size: 20px;
        font-weight: bold;
    }

    .form-group {
        margin: 0;
    }

    .stripe-element {
        padding: 20px 15px;
        border: 1px solid #F4F2F3;
    }

    .plan {
        border-bottom: 1px solid lightgrey;
    }

    .subscription-cost {
        .yearly-cost {
            font-size: 42px;
            line-height: 1;
        }
    }
</style>
