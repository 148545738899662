import Vue from 'vue'
import _ from 'lodash'
import zenscroll from 'zenscroll'

const PRESS_TIMEOUT = 500

Vue.directive('longpress', {
    bind: function (el, { value }, vNode) {

        if (typeof value !== 'function') {
            console.warn(`Expect a function, got ${value}`)
            return
        }

        let pressTimer = null
        let timerRunning = false;

        const start = e => {

            if (e.type === 'click' && e.button !== 0) {
                return;
            }

            if (pressTimer === null) {

                timerRunning = true;
                el.classList.add('pressed')

                pressTimer = setTimeout(() => {
                    timerRunning = false;
                    el.classList.remove('pressed')
                    value(e)
                }, PRESS_TIMEOUT)
            }
        };

        const cancel = e => {

            el.classList.remove('pressed')

            if(!timerRunning && !_.includes(['mousemove', 'touchmove'], e.type)) {
                e.preventDefault()
            }

            if (pressTimer !== null) {
                clearTimeout(pressTimer)
                timerRunning = false;
                pressTimer = null
            }
        }

        ;['mousedown', 'touchstart'].forEach(e => el.addEventListener(e, start))
        ;['click', 'mouseout', 'mousemove', 'touchend', 'touchcancel', 'touchmove'].forEach(e => el.addEventListener(e, cancel))
    }
})

Vue.directive('click-outside', {
    bind: function (el, binding, vNode) {

        // Provided expression must evaluate to a function.
        if (typeof binding.value !== 'function') {
            const compName = vNode.context.name
            let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`
            if (compName) {
                warn += `Found in component '${compName}'`
            }

            console.warn(warn)
        }
        // Define Handler and cache it on the element
        const bubble = binding.modifiers.bubble
        const handler = (e) => {

            if (bubble || (!el.contains(e.target) && el !== e.target)) {
                binding.value(e)
            }
        }
        el.__vueClickOutside__ = handler

        // add Event Listeners
        ;['mousedown', 'touchstart'].forEach(e => document.addEventListener(e, handler))
    },

    unbind: function (el, binding) {
        // Remove Event Listeners

        ;['mousedown', 'touchstart'].forEach(e => document.removeEventListener(e, el.__vueClickOutside__))
        el.__vueClickOutside__ = null

    }
})

Vue.directive('autoscroll', {
    bind: function (el, { value }, vNode) {
        let target = el

        if(target) {

            setTimeout(() => {

                let page = document.querySelector('.page')

                let myScroller = zenscroll.createScroller(page);
                myScroller.toY(target.getBoundingClientRect().bottom - window.innerHeight + 50, value)

            }, 100);

            setTimeout(() => {
                target.classList.remove('new')
            }, 1000)
        }
    }
})

Vue.directive('avoid-keyboard', {
    bind: function (el, { value }, vNode) {

        const handler = (e) => {
            if(document.activeElement == el) {
                if(el.scrollIntoViewIfNeeded) {
                    el.scrollIntoViewIfNeeded(true)
                } else {
                    el.scrollIntoView(true)
                }
            }
        };

        el.__avoidKeyboard__ = handler

        window.addEventListener('resize', handler)
    },
    unbind: function (el, binding) {
        // Remove Event Listeners
        window.removeEventListener('resize', el.__avoidKeyboard__)
    }
})

Vue.directive('login-required', {
    bind: function (el, binding, vNode) {

        // Provided expression must evaluate to a function.
        if (typeof binding.value !== 'function') {
            const compName = vNode.context.name
            let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`
            if (compName) {
                warn += `Found in component '${compName}'`
            }
        }
        // Define Handler and cache it on the element
        const handler = (e) => {

            e.stopPropagation();

            if(vNode.context.$auth.loggedIn && vNode.context.$auth.user.email_confirmed) {
                binding.value(e);
            } else {
                vNode.context.$root.$emit('open-fast-login-modal', { subscription_required: false, callback_function: binding.value })
            }
        }

        el.__vueLoginRequired__ = handler

        // add Event Listeners
        ;['click'].forEach(e => el.addEventListener(e, handler))
    },

    unbind: function (el, binding) {
        // Remove Event Listeners
        ;['click'].forEach(e => el.removeEventListener(e, el.__vueLoginRequired__))
    }
})

Vue.directive('subscription-required', {
    bind: function (el, binding, vNode) {

        // Provided expression must evaluate to a function.
        if (typeof binding.value !== 'function') {
            const compName = vNode.context.name
            let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`
            if (compName) {
                warn += `Found in component '${compName}'`
            }
        }
        // Define Handler and cache it on the element
        const handler = (e) => {

            if(vNode.context.$auth.loggedIn && (vNode.context.$auth.user.subscribed || vNode.context.$auth.user.subscription_status === 'pending')) {
                binding.value(e);
            } else {
                vNode.context.$root.$emit('open-fast-login-modal', { subscription_required: true, callback_function: binding.value })
            }
        }

        el.__vueLoginRequired__ = handler

        // add Event Listeners
        ;['click'].forEach(e => el.addEventListener(e, handler))
    },

    unbind: function (el, binding) {
        // Remove Event Listeners
        ;['click'].forEach(e => el.removeEventListener(e, el.__vueLoginRequired__))
    }
})

Vue.directive('input-password', {
    bind: function (el, binding , vNode) {

        if (el.tagName.toLowerCase() !== 'input' && ['text', 'password'].indexOf(el.type)) {
            console.warn(`This directive can be used on text or password inputs only`)
            return
        }

        let btn = document.createElement('I')
        btn.classList.add('fa')

        if (el.type === "password") {
            btn.classList.add('fa-eye')
        } else {
            btn.classList.add('fa-eye-slash')
        }

        btn.style.position = 'absolute'

        setTimeout(() => {
            el.parentNode.style.position = 'relative';
            el.parentNode.insertBefore(btn, el.nextSibling);

            const reset = (e) => {
                btn.style.left = (el.offsetWidth - btn.offsetWidth + el.offsetLeft - 12) + 'px';
                btn.style.top = el.offsetTop + (el.offsetHeight / 2 - btn.offsetHeight / 2) + 'px'
                btn.style.display = 'block'
            }

            const handler = (e) => {
                if (el.type === "password") {
                    el.type = "text";
                    btn.classList.remove('fa-eye')
                    btn.classList.add('fa-eye-slash')
                } else {
                    el.type = "password";
                    btn.classList.remove('fa-eye-slash')
                    btn.classList.add('fa-eye')
                }
            }

            el.__vueInputPassword__ = handler
            el.__resetBtnPosition__ = reset
            el.__parentNode__ = el.parentNode
            el.__toggleButtonElement__ = btn

            reset();
            ;['click'].forEach(e => btn.addEventListener(e, handler))
            window.addEventListener('resize', reset)
        })
    },
    unbind: function (el, binding) {
        // Remove Event Listeners
        if(el.__toggleButtonElement__) {
            ;['click'].forEach(e => el.removeEventListener(e, el.__toggleButtonElement__.__vueInputPassword__))
            el.__parentNode__.removeChild(el.__toggleButtonElement__);
        }
        window.removeEventListener('resize', el.__resetBtnPosition__)
    }
})