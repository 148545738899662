
import { getField, updateField } from 'vuex-map-fields';

export const state = () => ({
    data: {
        name: '',
        address: '',
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        password_confirmation: '',
        vat_id: '',
        with_invoice: false,
        stripe_token: ''
    },
    payment_method: 'stripe',
    processing: false
})

export const getters = {
    getField
}

export const mutations = {
    updateField,
    SET_PROCESSING (state, processing) {
        state.processing = processing
    }
}

export const actions = {
    processCheckout ({ commit, state}, planId) {

        commit('SET_PROCESSING', false)

        return this.$axios
            .post('/api/checkout', {...state.data, plan_id: planId})
            .then(r => r.data)
            .then(response => {
                commit('SET_PROCESSING', false)
                return response;
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    }
}