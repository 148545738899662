import Vue from 'vue'
import bugsnag from '@bugsnag/js'
import bugsnagVue from '@bugsnag/plugin-vue'

if(process.env.BUGSNAG_API_KEY) {
    const bugsnagClient = bugsnag({
        apiKey: process.env.BUGSNAG_API_KEY || '',
        releaseStage: process.env.RELEASE_STAGE
    })

    bugsnagClient.use(bugsnagVue, Vue)
}

export default ({ app }, inject) => {
}