<template>
    <div class="d-flex">
        <div class="mr-4 d-flex align-items-center" @click.prevent="showDiplomasModal" v-if="!modal">
            <i class="cf-like fa fa-rotate-180 liked text-grey"></i>
            <span class="ml-1"><u>{{ entity.rejected_diplomas_count ? entity.rejected_diplomas_count : 0 }}</u></span>
        </div>
        <div class="flex-grow-1 flex-basis-0 mr-1 d-none d-lg-block">
            <button
                    v-if="entityStatus === 'pending' || entityStatus === 'rejected'"
                    :class="[brandButtonClasses, 'btn btn-sm w-100']"
                    :disabled="!entity.diploma"
                    @click.prevent="verifyEntity"
            >
                {{ $t('Approva') }}
            </button>
        </div>
        <div class="ml-1 flex-grow-1 flex-basis-0 dropdown d-block d-lg-none align-self-center">
            <dropup-menu ref="verifyDropup" :hide-bottom-bar="true" :show-overlay="true">
                <template slot="menu-icon">
                    <button
                            v-if="(entityStatus === 'pending' || entityStatus === 'rejected') && entity.diploma"
                            :class="[brandButtonClasses, 'btn btn-sm w-100']"
                    >
                        {{ $t('Approva') }}
                    </button>
                </template>
                <template slot="menu-options">
                    <ul class="filter-options">
                        <li class="p-2 text-grey text-center font-size-base">{{ entity.name }}</li>
                        <li @click.prevent="verifyEntity" class="align-items-center font-weight-normal">
                            <span v-if="group">{{ $t('Accetta nel gruppo')}}</span>
                            <span v-else>{{ $t('Conferma verifica') }}</span>
                            <!--<i class="fa fa-circle fa-lg text-primary" v-if="reason.value === rejectReason"></i>-->
                            <!--<i class="fa fa-circle-o fa-lg text-grey" v-else></i>-->
                        </li>
                    </ul>
                </template>
            </dropup-menu>
        </div>
        <!--<button-->
            <!--v-if="entityStatus == 'pending' && !showingRejectReason"-->
            <!--:class="['btn btn-sm w-100 ml-1 mr-1', defaultButtonClasses]"-->
            <!--@click.prevent="showRejectUserModal"-->
        <!--&gt;-->
            <!--{{ $t('Rifiuta') }}-->
        <!--</button>-->

        <b-dropdown no-caret variant="link" class="ml-1 w-100 position-static d-none d-lg-block p-0">
            <template slot="button-content">
                <button
                        v-if="entityStatus === 'pending'"
                        :class="['btn btn-sm w-100', defaultButtonClasses]"
                >
                    {{ $t('Rifiuta') }}
                </button>
            </template>
            <b-dropdown-item v-for="reason in rejectReasons" @click.prevent="reject(reason.value)" :key="reason.value">{{ reason.label }}</b-dropdown-item>
        </b-dropdown>
        <div class="ml-1 flex-grow-1 flex-basis-0 dropdown d-block d-lg-none align-self-center">
            <dropup-menu ref="rejectReasonDropup" :hide-bottom-bar="true" :show-overlay="true">
                <template slot="menu-icon">
                    <button
                            v-if="entityStatus === 'pending'"
                            :class="['btn btn-sm w-100', defaultButtonClasses]"
                    >
                        {{ $t('Rifiuta') }}
                    </button>
                </template>
                <template slot="menu-options">
                    <ul class="filter-options">
                        <li class="p-2 text-grey text-center font-size-base">{{ $t('Motivo del rifiuto')}}</li>
                        <li v-for="reason in rejectReasons" @click.prevent="reject(reason.value)" :key="reason.value" class="d-flex align-items-center font-weight-normal">
                            {{ reason.label }}
                            <!--<i class="fa fa-circle fa-lg text-primary" v-if="reason.value === rejectReason"></i>-->
                            <!--<i class="fa fa-circle-o fa-lg text-grey" v-else></i>-->
                        </li>
                    </ul>
                </template>
            </dropup-menu>
        </div>

        <button
            v-if="entityStatus == 'rejected'"
            :class="['btn btn-sm w-100 ml-1 mr-1', defaultButtonClasses]"
            @click.prevent="blockEntity"
        >
            {{ $t('Blocca') }}
        </button>
        <button
            v-if="entityStatus == 'blocked'"
            :class="['btn btn-sm w-100 mr-1', brandButtonClasses]"
            @click.prevent="unlockEntity"
        >
            {{ $t('Sblocca') }}
        </button>
        <!--<button-->
            <!--:class="['btn btn-sm ml-1', brandButtonClasses]"-->
        <!--&gt;-->
            <!--<i class="cf-more" style="font-size: 5px"></i>-->
        <!--</button>-->
        <diplomas-modal v-if="!modal" :user="user" :account="account" ref="diplomasModal"></diplomas-modal>
    </div>
</template>

<script>

    import { mapActions } from 'vuex'
    import DiplomasModal from '~/components/PendingUsers/DiplomasModal'

    export default {

        name: "admin-actions",

        components: {
            DiplomasModal
        },

        props: {
            user: {
                type: Object,
                default: function () {
                    return null
                }
            },
            account: {
                type: Object,
                default: function () {
                    return null
                }
            },
            certificate: {
                type: Object,
                default: function () {
                    return null
                }
            },
            defaultButtonClasses: {
                type: String,
                default: function () {
                    return 'btn-grey'
                }
            },
            brandButtonClasses: {
                type: String,
                default: function () {
                    return 'btn-brand btn-transparent'
                }
            },
            modal: {
                type: Boolean,
                default: function() {
                    return false;
                }
            },
            group: {
                type: Boolean,
                default: function() {
                    return false
                }
            }
        },

        data() {
            return {
                showingRejectReason: false,
                rejectReason: ''
            }
        },

        computed: {

            entity() {
                return this.user ? this.user : this.account
            },

            entityStatus() {

                return this.group ? this.user.group_status : this.entity.status
            },

            rejectReasons() {

                return this.user ? [
                    {
                        label: this.$t('Immagine non conforme'),
                        value: this.$t('Immagine non conforme')
                    },
                    {
                        label: this.$t('Scuola non conforme'),
                        value: this.$t('Scuola non conforme')
                    },
                    {
                        label: this.$t('Anno non conforme'),
                        value: this.$t('Anno non conforme')
                    },
                    {
                        label: this.$t('Altro'),
                        value: this.$t('Altro')
                    },
                ] :
                [
                    {
                        label: this.$t('Immagine non conforme'),
                        value: this.$t('Immagine non conforme')
                    }
                ]
            }
        },

        methods: {
            ...mapActions({
                'verifyUser': 'users/verify',
                'rejectUser': 'users/reject',
                'blockUser': 'users/block',
                'unlockUser': 'users/unlock',
                'verifyAccount': 'accounts/verify',
                'rejectAccount': 'accounts/reject',
                'blockAccount': 'accounts/block',
                'unlockAccount': 'accounts/unblock',
                'verifyUserGroup': 'group/acceptUserRequest',
                'rejectUserGroup': 'group/rejectUserRequest',
                'blockUserGroup': 'group/blockUser',
                'unlockUserGroup': 'group/unblockUser',
                'createConversation': 'conversations/create'
            }),

            showDiplomasModal() {
                this.$refs.diplomasModal.show();
            },
            setFilter(reason) {

                this.rejectReason = reason;

                this.rejectUser({user:this.user, reason: this.rejectReason})

            },
            reject(reason) {

                this.rejectReason = reason;

                if (this.group) {
                    this.rejectUserGroup({ user:this.user, reason: this.rejectReason }).then(() => {

                        if (this.rejectReason === 'Altro') {

                            let newConversation = {};

                            newConversation.user_id = this.user.id;

                            this.createConversation(newConversation)
                                .then((conversation) => {

                                    this.$router.push('/chat/' + conversation.id)
                                })
                        }
                    })
                } else if (this.user) {
                    this.rejectUser({user:this.user, reason: this.rejectReason, certificate_id: this.certificate ? this.certificate.id : null}).then(() => {

                        if (this.rejectReason === 'Altro') {

                            let newConversation = {};

                            newConversation.user_id = this.user.id;

                            this.createConversation(newConversation)
                                .then((conversation) => {

                                    this.$router.push('/chat/' + conversation.id)
                                })
                        }
                    })
                } else if (this.account) {
                    this.rejectAccount({account:this.account, reason: this.rejectReason, certificate_id: this.certificate ? this.certificate.id : null})
                }

                // ref.toggleShowDropUp()
            },

            verifyEntity() {

                if (this.group) {
                    this.verifyUserGroup(({'user': this.user }))
                } else if (this.user) {
                    this.verifyUser(({'user': this.user, 'certificate_id': this.certificate ? this.certificate.id : null }))
                } else if (this.account) {
                    this.verifyAccount(({'account': this.account, 'certificate_id': this.certificate ? this.certificate.id : null }))
                }
            },

            blockEntity() {

                if (this.group) {
                    alert('Block user from group.')
                } else if (this.user) {
                    this.blockUser(this.user)
                } else if (this.account) {
                    this.blockAccount(this.account)
                }
            },

            unlockEntity() {

                if (this.group) {
                    alert('Unlock user from group.')
                } else if (this.user) {
                    this.unlockUser(this.user)
                } else if (this.account) {
                    this.unlockAccount(this.account)
                }
            }
        },

        mounted () {
            // if (this.group) {
            //     this.brandButtonClasses = 'btn-group btn-transparent'
            // } else {
            //     this.brandButtonClasses = 'btn-brand btn-transparent'
            // }
        }
    }
</script>

<style scoped>

</style>