
import { getField, updateField } from 'vuex-map-fields';

export const state = () => ({
    illicitWorkReports: [],
    regions: [],
    totalCount: 0,
    processing: false
})

export const getters = {
    getField
}

export const mutations = {
    updateField,
    SET_PROCESSING (state, processing) {
        state.processing = processing
    },
    SET_REGIONS (state, regions) {
        state.regions = regions
    },
    SET_TOTAL_COUNT (state, totalCount) {
        state.totalCount = totalCount
    }
}

export const actions = {

    getStats ({ commit }) {
        return this.$axios.get('/api/illicit-work-reports/stats')
            .then(r => r.data)
            .then((response) => {
                commit('SET_TOTAL_COUNT', response.total_count)
                commit('SET_REGIONS', response.regions)
            })
    },

    submitReport ({ commit, state}, report) {

        commit('SET_PROCESSING', true)

        let data = new FormData();

        _.each(Object.keys(report), (key) => {
            data.append(key, report[key]);
        })

        data.append('links' , JSON.stringify(report.filtered_links))

        _.each(report.images, function (image, index) {
            data.append('images[' + index + ']' , image.data)
        })

        return this.$axios.post('/api/illicit-work-reports', data)
            .then(() => {

            }).finally(() => {
                commit('SET_PROCESSING', false)
            })
    }
}