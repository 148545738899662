<template>
    <div class="card text-center">
        <div class="card-header card-header-sm  d-flex justify-content-center align-items-center">
            <i class="cf-back absolute p-3" style="left: 0;" @click.prevent="setStep('account-address')"></i>
            {{ $t('Numero di partita IVA') }}
        </div>
        <div class="card-body">
            <div class="mt-5">
                <img alt="step-03" class="w-100 icon" src="~/assets/images/steps/step-03.png">
            </div>
            <div class="mt-3">
                <div class="title">{{ $t('Qual è la partita iva del tuo centro estetico?')}}</div>
            </div>
            <div class="description mt-3">
                {{ $t('Inserisci la partita iva del tuo centro estetico, per ottenere la verifica della tua pagina aziendale.') }}
            </div>
            <div class="input-group mt-4">
                <div class="input-group-prepend input-group-prepend-sm">
                    <span class="input-group-text">IT -</span>
                </div>
                <input type="text" pattern="[0-9]*" inputmode="numeric" maxlength="11" class="form-control form-control-sm" v-model="vat_id">
            </div>
        </div>
        <div class="card-footer">
            <button class="btn btn-brand w-100" @click.prevent="nextStep()" :disabled="!vat_id">
                {{ $t('Avanti') }}
            </button>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions, mapMutations } from 'vuex'
    import { mapFields } from 'vuex-map-fields'

    export default {

        computed: {
            ...mapState([
                'account',
                'profile-wizard'
            ]),
            ...mapFields('account', [
                'account.vat_id'
            ])
        },

        props: ['type'],

        methods: {
            ...mapActions({
                'updateVatId': 'account/updateVatId'
            }),
            ...mapMutations({
                'setStep': 'work-experience/SET_STEP',
                'setAccountType': 'user/SET_ACCOUNT_TYPE'
            }),
            nextStep() {
                this.updateVatId(this.vat_id).then(() => {
                    this.setStep('specialisation')
                })
            }
        },

        mounted () {

        }
    }
</script>

<style lang="scss" scoped>
    .profile-type-box {
        width: 150px;
    }
</style>