<template>
    <div class="h-100">
        <div v-show="!imgSrc" class="h-100">
            <recap :modal="modal" :group="group" :img-src.sync="imgSrc" :blob.sync="blob" :editCertificate="editCertificate"></recap>
        </div>
        <div v-if="imgSrc" class="h-100">
            <certificate-image :blob.sync="blob" :img-src.sync="imgSrc" :edit-certificate.sync="editCertificate"></certificate-image>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState, mapMutations } from 'vuex'
    import CertificateImage from '~/components/UploadCertificate/User/Steps/Image'
    import Recap from '~/components/UploadCertificate/User/Steps/Recap'

    export default {

        props: {
            'diploma': {
                type: Boolean,
                default: function () {
                    return false;
                }
            },
            'modal': {
                type: Boolean,
                default: function () {
                    return false;
                }
            },
            'group': {
                type: Boolean,
                default: function () {
                    return false;
                }
            }
        },

        data () {
            return {
                editCertificate: {},
                imgSrc: '',
                blob: ''
            }
        },

        components: {
            CertificateImage,
            Recap
        },

        computed: {
            ...mapState({
                user: 'user',
                certificate: 'certificate',
                uploadCertificate: 'upload-certificate',
            }),
        },

        methods: {
            ...mapMutations({
                setCertificate: 'certificate/SET_CERTIFICATE',
                setCertificateEntityId: 'certificate/SET_CERTIFICATE_ENTITY_ID',
                setCertificateEntityType: 'certificate/SET_CERTIFICATE_ENTITY_TYPE',
                setCertificateDiploma: 'certificate/SET_CERTIFICATE_DIPLOMA',
            }),
            ...mapActions({
                refetchCertificate: 'certificate/fetchById',
                refetchUser: 'user/refetch'
            }),
            init() {

                this.setCertificateDiploma(this.diploma)

                if (this.user.user.diploma && this.certificate.certificate.diploma) {
                    this.setCertificate(this.user.user.diploma)
                } else {
                    if (!this.certificate.certificate.id) {
                        this.setCertificateEntityId(this.$store.state.user.user.id)
                        this.setCertificateEntityType('user')
                    }
                }
            },

            openInput (isVisible) {

                if (isVisible) {
                    let image = document.getElementById("certificate-image-input");

                    image.click()
                }
            }
        },

        created () {
            if (!this.modal) {
                this.init();
            }
        },

        beforeMount() {
            this.editCertificate = _.cloneDeep(this.$store.state.certificate.certificate);
        }
    }
</script>
