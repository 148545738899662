import Vue from 'vue'
import _ from 'lodash'

Vue.prototype.$app_log = function (data) {
    this.$axios.post('/api/app-log', data)
};

Vue.prototype.postTextPieces = function(text, mustTruncate) {

    const truncation = 150

    if(!text) {
        return []
    }

    const regex = /((http(s)?:\/\/.)|(www\.))[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    let matches = text.match(regex)

    if (!matches) {
        return [{
            type: 'plain',
            value: mustTruncate ? _.truncate(text, { length: truncation, separator: /,? +/ }) : text
        }]
    }

    var lastIndex = -1
    let splitPoints = matches.map(match => {
        lastIndex = text.indexOf(match, lastIndex + 1)
        return {
            index: lastIndex,
            length: match.length
        }
    })

    let pieces = []

    // Non parto con un URL
    if (splitPoints[0].index !== 0) {
        // Devo troncare?
        if (splitPoints[0].index > truncation && mustTruncate) {
            // Aggiungo il primo pezzo plain troncato e basta
            pieces.push({
                type: 'plain',
                value: text.slice(0, truncation)
            })
            // Ho già sforato
            return pieces
        } else {
            pieces.push({
                type: 'plain',
                value: text.slice(0, splitPoints[0].index)
            })
        }
    }

    for (let i=0; i < splitPoints.length; i++) {

        const point = splitPoints[i]
        const endIndex = point.index + point.length
        const match = text.slice(point.index, endIndex)

        if (endIndex > truncation && mustTruncate) {
            pieces.push({
                type: 'html',
                value: '<a href="' + match +'" target="_blank">'+ match.slice(0, endIndex - truncation) +'</a>',
                url: match
            });
            // Ho già sforato
            return pieces
        } else {
            pieces.push({
                type: 'html',
                value: '<a href="' + match +'" target="_blank">'+ match +'</a>',
                url: match
            });
        }

        // Non sono arrivato alla fine della stringa
        if (endIndex < text.length) {
            // Ci sono altri match
            if(i < splitPoints.length - 1) {
                // Il prossimo match non è attaccato a questo
                let nextPoint = splitPoints[i+1]
                if (nextPoint.index > endIndex) {

                    if (endIndex > truncation && mustTruncate) {
                        pieces.push({
                            type: 'plain',
                            value: text.slice(endIndex, truncation)
                        })
                        // Ho già sforato
                        return pieces
                    } else {
                        pieces.push({
                            type: 'plain',
                            value: text.slice(endIndex, nextPoint.index)
                        })
                    }

                }
            } // non ci sono altri match
            else {
                if (endIndex > truncation && mustTruncate) {
                    pieces.push({
                        type: 'plain',
                        value: text.slice(truncation)
                    })
                    // Ho già sforato
                    return pieces
                } else {
                    pieces.push({
                        type: 'plain',
                        value: text.slice(endIndex)
                    })
                }
            }
        }
    }

    return pieces
}

String.prototype.toCapitalizedString = function (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}