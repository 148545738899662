<template>
    <div class="search-bar" :class="{'focused': focused || searchParam}">
        <form @submit.prevent="search" action=".">
            <label for="search-input" class="m-0">
                <i class="cf-search"></i>
            </label>
            <input @focus="focused = true" id="search-input" type="search" autocomplete="off" autocapitalize="off" :placeholder="placeholder" v-model="searchParam" />
            <!-- Fix this -->
            <input type="submit" style="display:none"/>
        </form>
        <span class="cancel-button text-brand font-size-sm" @click.prevent="resetSearchParams"> {{ $t('Annulla') }}</span>

        <div v-if="focused" class="header-dropdown dropdown-full background-white" :style="'top: ' + headerHeight + 'px'">
            <slot name="search-placeholder" :search-param="searchParam"></slot>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'

    export default {

        props: {
            headerHeight: {
                type: Number,
                default: function () {
                    return 0
                }
            },
            postUrl: {
                type: String,
                default: function () {
                    return '/cerca'
                }
            },
            placeholder: {
                type: String,
                default: function () {
                    return this.$t('Cerca')
                }
            },
            value: {
                type: String,
                default: function () {
                    return null
                }
            }
        },

        data () {
            return {
                inputId: this._uid,
                searchParam: '',
                focused: false
            }
        },

        watch: {
            $route (to, from) {
                if (from && to && from.name !== to.name) {
                    this.searchParam = ''
                }
            }
        },

        computed: {
            ...mapState([
                'menuOpen'
            ])
        },

        methods: {
            ...mapActions({
                'setUsersFilter': 'users/setTextFilter',
                'setAccountsFilter': 'accounts/setTextFilter'
            }),
            resetSearchParams () {
                this.focused = false;
                this.searchParam = '';
            },
            search () {

                if (this.menuOpen) {
                    let event = {
                        event: 'close-menu'
                    }

                    if (window.ReactNativeWebView) {
                        window.ReactNativeWebView.postMessage(JSON.stringify(event));
                    } else {
                        window.postMessage(JSON.stringify(event), '*');
                    }
                }

                this.focused = false;
                this.$emit('input', this.searchParam)

                this.$router.push({
                    path: this.postUrl,
                    query: {
                        'text': this.searchParam
                    }
                })
            }
        },

        created () {
            if(this.$route.query.text) {
                this.searchParam = this.$route.query.text
            }
        }
    }
</script>