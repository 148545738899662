<template>
    <div class="card text-center">
        <div class="card-header card-header-sm  d-flex justify-content-center align-items-center">
            <i class="cf-back absolute p-3" style="left: 0;" @click.prevent="setWorkExperienceStep('work-experience')"></i>
            {{ $t('Cerca centro estetico') }}
        </div>
        <div class="card-body">
            <div class="mt-lg-5 mb-2">
                <img alt="step-02" class="w-100 icon" src="~/assets/images/steps/step-02.png">
            </div>
            <div class="title">
                {{ $t('Lavori presso:')}}
            </div>
            <div class="mt-3">
                <div class="title">
                    {{ account.account.name }}
                </div>
                <div class="description">
                    {{ account.account.address }}
                </div>
            </div>
            <div class="description mt-5">
                {{ $t('Invita la titolare di')}} <strong> {{ account.account.name}} </strong>{{ $t('a confermare la tua posizione lavorativa')}}
            </div>
            <div class="input-container mt-3">
                <input class="form-control form-control-sm has-label pr-4" name="email" :data-vv-as="$t('E-mail')" v-validate="'email'" :placeholder="$t('E-mail')" autocomplete="off" v-model="email" />
                <a :href="'mailto:' + email + '?body=' + message" :disabled="errors.has('email') || !email.length">
                    <i class="input-icon text-grey cf-message text-grey" :class="{'cf-message-f text-brand': !errors.has('email') && email.length}"></i>
                </a>
            </div>
            <strong class="form-error text-danger mt-2" v-if="errors.has('email')">{{ errors.first('email') }}</strong>
        </div>
        <div class="card-footer">
            <a :href="'https://api.whatsapp.com/send?text=' + message" target="_blank" class="btn btn-whatsapp w-100 d-flex justify-content-center align-items-center">
                <i class="fa fa-whatsapp fa-2x mr-2"></i>
                {{ $t('Invita tramite Whatsapp') }}
            </a>
            <button class="btn btn-brand w-100" @click.prevent="setWorkExperienceStep('staff-invite')">
                {{ $t('Avanti') }}
            </button>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState, mapMutations } from 'vuex'
    import _ from 'lodash'

    export default {

        props: ['type'],

        data() {
          return {
              email: '',
              message: 'Ciao, entra anche tu a far parte della community di confestetica! Registrati sul sito ' + encodeURIComponent(process.env.BASE_URL + '/?ref=' + this.$auth.user.referral_code) + ' per maggiori informazioni'
          }
        },

        computed: {
            ...mapState({
                'user': 'user',
                'account': 'account',
                'workExperience': 'work-experience'
            }),
        },

        methods: {
            ...mapActions({
                'refetchUser': 'user/refetch'
            }),
            ...mapMutations({
                'setWorkExperienceType': 'work-experience/SET_TYPE',
                'setWorkExperienceStep': 'work-experience/SET_STEP'
            }),
        },

        mounted () {
        },
    }
</script>