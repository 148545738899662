<template>
    <b-modal ref="cardsModal" modal-class="slide-up" body-class="p-0" hide-header hide-footer no-fade class="card card-modal border-0" static>
        <div class="w-100"
             style="height: 100vh!important"
             :style="'padding-top:' + insets.top + 'px!important; padding-bottom:' + insets.bottom + 'px'"
        >
            <cards>
                <template slot="card-header">
                    <div class="card-header card-header-sm d-flex justify-content-center align-items-center">
                        <i class="cf-back absolute p-3" style="left: 0" @click.prevent="$refs.cardsModal.hide()"></i>
                        <span>{{ $t('Le tue carte')}}</span>
                    </div>
                </template>
            </cards>
        </div>
    </b-modal>
</template>

<script>
    import { mapState } from 'vuex'
    import Cards from '~/components/Subscription/User/Steps/Cards'

    export default {

        components: {
            Cards
        },

        computed: {
            ...mapState([
                'insets'
            ])
        },

        methods: {
            openModal () {
                this.$refs.cardsModal.show()
            },
            closeModal () {
                this.$refs.cardsModal.hide()
            }
        }
    }
</script>
