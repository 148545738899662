<template>
    <div class="card border-0">
        <div class="card-header card-header-sm d-flex justify-content-center align-items-center">
            <i class="cf-back absolute" @click.prevent="previousStep" style="left: 16px"></i>
            {{ $t('Verifica licenza') }}
        </div>
        <div class="card-body text-center p-4">
            <input type="file" name="image" id="update-account-certificate-input" accept="image/*" @change="setImage" class="d-none"/>
            <div v-if="!imgSrc">
                <div class="certificate-image-container" v-if="account.account.diploma && account.account.diploma.image_res">
                    <img alt="account-diploma" :src="account.account.diploma.image_res.medium">
                </div>
                <img alt="step-08" class="icon" src="~/assets/images/steps/step-08.png" width="100%" v-else>

                <div class="pr-4 pl-4">
                    <div class="title mt-3">
                        <strong>{{ $t('Dimostraci che sei titolare di centro estetico')}}</strong>
                    </div>
                    <div class="description mt-4">
                        {{ $t('Aggiungi la foto della tua licenza per dimostrare di essere titolare di centro estetico. Questa piattaforma è riservata esclusivamente ai titolari.') }}
                    </div>
                </div>
            </div>
            <vue-cropper
                    ref="cropper"
                    :guides="true"
                    :view-mode="2"
                    drag-mode="crop"
                    :full="true"
                    :auto-crop-area="1"
                    :min-container-width="250"
                    :min-container-height="250"
                    :movable="false"
                    :crop-box-movable="false"
                    :background="true"
                    :rotatable="true"
                    alt="Source Image"
                    v-show="imgSrc"
                    :img-style="{width: '400px', 'height': '100%'}">
            </vue-cropper>
        </div>
        <div class="card-footer d-flex">
            <div class="w-100 d-flex flex-column">
                <button class="btn btn-brand w-100" @click.prevent="openInput"><i class="cf-camera mr-1"></i>{{ $t('Carica immagine') }}</button>
                <button class="btn btn-brand w-100" @click.prevent="saveImage()">{{ $t('Continua') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState, mapMutations } from 'vuex'
    import _ from 'lodash'

    export default {

        data() {
            return {
                editCertificate: {},
                imgSrc: '',
                loading: false
            }
        },

        props: ['type'],

        computed: {
            ...mapState({
                'workExperience': 'work-experience',
                'account': ({ account }) => account,
                'certificate': ({ certificate }) => certificate,
                'saving': ({ certificate }) => certificate.saving,
            })
        },

        methods: {
            ...mapMutations({
                setWorkExperienceStep: 'work-experience/SET_STEP',
                resetCertificate: 'certificate/RESET_STATE',
                setCertificate: 'certificate/SET_CERTIFICATE',
                setCertificateEntityId: 'certificate/SET_CERTIFICATE_ENTITY_ID',
                setCertificateEntityType: 'certificate/SET_CERTIFICATE_ENTITY_TYPE',
                setCertificateDiploma: 'certificate/SET_CERTIFICATE_DIPLOMA',
            }),
            ...mapActions({
                'refetchAccount': 'account/refetch'
            }),
            previousStep() {
                this.setWorkExperienceStep('account-whatsapp-number')
            },
            nextStep() {
                this.setWorkExperienceStep('verify-certificate')
            },
            init() {

                this.resetCertificate()

                if (this.$store.state.account.account.diploma) {
                    this.setCertificate(this.$store.state.account.account.diploma)
                } else {
                    this.setCertificateEntityId(this.$store.state.account.account.id)
                    this.setCertificateEntityType('account')
                    this.setCertificateDiploma(true)
                }

                this.editCertificate = _.cloneDeep(this.$store.state.certificate.certificate);
            },
            setImage (e) {

                this.loading = true;

                const file = e.target.files[0];

                if (!file.type.includes('image/')) {
                    alert('Please select an image file');
                    return;
                }

                if (typeof FileReader === 'function') {
                    const reader = new FileReader();

                    reader.onload = (event) => {

                        this.imgSrc = event.target.result;

                        this.$refs.cropper.replace(event.target.result);

                        this.loading = false;
                    };

                    reader.readAsDataURL(file);
                } else {
                    alert('Sorry, FileReader API not supported');
                }
            },

            saveImage () {

                let action = this.certificate.certificate.id ? 'update' : 'create';

                if (this.imgSrc) {

                    this.$nuxt.$loading.start()

                    this.$refs.cropper.getCroppedCanvas({maxWidth: 1920, maxHeight: 1920}).toBlob(blob => {

                        this.$store
                            .dispatch('certificate/' + action, blob)
                            .then(r => {
                                this.refetchAccount()
                                    .then(() => {

                                        this.$notify({
                                            group: 'messages',
                                            type: 'success',
                                            text: this.$t('Certificato caricato correttamente.')
                                        })

                                        this.imgSrc = '';
                                        this.$nuxt.$loading.finish()
                                        this.nextStep()
                                    })
                            })
                            .catch(() => {
                                this.$nuxt.$loading.finish()
                            })
                    }, 'image/jpeg', 0.95);
                } else {
                    this.nextStep()
                }
            },

            openInput (isVisible) {

                if (isVisible) {
                    let image = document.getElementById('update-account-certificate-input');

                    image.click()
                }
            }
        },

        mounted () {
            this.init();
        },
    }
</script>

<style lang="scss" scoped>
    .certificate-image-container {
        width: 100%;
        height: 200px;
        > img {
            height: 100%;
            width: 100%;
            object-fit: contain
        }
    }
</style>