
import { getField, updateField } from 'vuex-map-fields';
import _ from 'lodash'

export const state = () => ({
    account: {
        type: 'beauty-centre',
        address: '',
        route: '',
        vat_id: '',
        city_id: null,
        postal_code_id: null,
        postal_code_name: '',
        main_phone_number: '',
        whatsapp_number: ''
    },
    tab: 'about',
    loading: false,
    saving: false,
    publishingImages: false,
    uploadingHeaderImage: false,
    uploadingProfileImage: false,
    uploadingCertificateImage: false
})

export const getters = {
    getField,
    IS_EDITABLE_BY_USER: (state) => (user) => {

        if(!user) return false;

        let owner = false;

        if(state.account.owners) {
            owner = _.includes(_.map(state.account.owners, 'id'), user.id)
        }

        return (owner || user.is_admin);
    }
}

export const mutations = {
    updateField,
    SET_ACCOUNT (state, account) {
        state.account = account
    },
    RESET_ACCOUNT (state) {
        state.account = {
            type: 'beauty-centre',
            address: '',
            route: '',
            vat_id: '',
            city_id: null,
            postal_code_id: null,
            postal_code_name: '',
            main_phone_number: '',
            whatsapp_number: ''
        }
    },
    SET_ACCOUNT_NAME (state, name) {
        state.account.name = name
    },
    SET_ACCOUNT_TYPE (state, type) {
        state.account.type = type
    },
    SET_ACCOUNT_TAB (state, tab) {
        state.tab = tab
    },
    SET_LOADING (state, loading) {
        state.loading = loading
    },
    SET_SAVING (state, saving) {
        state.saving = saving
    },
    SET_PUBLISHING_IMAGES (state, publishing) {
        state.publishingImages = publishing
    },
    SET_UPLOADING_HEADER_IMAGE (state, loading) {
        state.uploadingHeaderImage = loading
    },
    SET_UPLOADING_PROFILE_IMAGE (state, loading) {
        state.uploadingProfileImage = loading
    },
    SET_UPLOADING_CERTIFICATE_IMAGE (state, loading) {
        state.uploadingProfileImage = loading
    },
}

export const actions = {

    async refetch ({ state, dispatch }) {
        await dispatch('fetchById', state.account.id)
    },

    login ({ state }, loginData) {

        return this.$auth.loginWith('local', {
            data: {
                // Temp hard code
                client_id: process.env.CLIENT_ID,
                client_secret: process.env.CLIENT_SECRET,

                // client_id: '6',
                // client_secret: 'TdeSZDv6KQvwxvtFwKoRwkFY2gmEJED9Y9jgowHi',

                grant_type: 'password',
                accountname: loginData.accountname,
                password: loginData.password
            }
        })
    },

    setStatus ({ state, dispatch }, status) {

        dispatch('update', { status: status })
    },

    setBiography ({ state, dispatch }, biography) {

        dispatch('update', { biography: biography })
    },

    fetchById ({ commit, dispatch, state}, id) {

        commit('SET_LOADING', true)

        return this.$axios
            .get('/api/accounts/' + id)
            .then(r => r.data)
            .then(account => {
                commit('SET_ACCOUNT', account)
                commit('accounts/APPEND_ACCOUNT', account, { root: true })

                if(account.users) {
                    dispatch('users/appendUsers', account.users, { root: true })
                }

                commit('SET_LOADING', false)
            })
            .catch(error => {
                commit('SET_LOADING', false)
                throw error;
            })
    },

    create ({ commit, state}, account) {

        commit('SET_SAVING', true)

        let data = new FormData();

        let myAccount = account ? account : state.account

        _.each(Object.keys(myAccount), (key) => {
            data.append(key, myAccount[key]);
        })

        return this.$axios
            .post('/api/accounts', data)
            .then(r => r.data)
            .then(account => {
                // commit('SET_ACCOUNT', account)
                commit('SET_SAVING', false)

                return account
            })
            .catch(error => {
                commit('SET_SAVING', false)
                throw error;
            })
    },

    update ({ commit, state}, account) {

        commit('SET_SAVING', true)

        let data = new FormData();

        _.each(Object.keys(account), (key) => {
            data.append(key, account[key]);
        })

        return this.$axios
            .post('/api/accounts/' + state.account.id, data)
            .then(r => r.data)
            .then(account => {
                commit('SET_ACCOUNT', account)
                commit('SET_SAVING', false)
            })
            .catch(error => {
                commit('SET_SAVING', false)
                throw error;
            })
    },

    updateVatId ({ state, dispatch }, vatId) {

        dispatch('update', { vat_id: vatId })
    },

    updateMainPhoneNumber ({ state, dispatch }, mainPhoneNumber) {

        dispatch('update', { main_phone_number: mainPhoneNumber })
    },

    updateWhatsappNumber ({ state, dispatch }, whatsappNumber) {

        dispatch('update', { whatsapp_number: whatsappNumber })
    },

    updateHeaderImage ({ commit, state }, image) {

        let data = new FormData()

        data.append('header_image', image)

        commit('SET_UPLOADING_HEADER_IMAGE', true)

        return this.$axios
            .post('/api/accounts/' + state.account.id + '/header-image', data)
            .then(r => r.data)
            .then(account => {
                commit('SET_UPLOADING_HEADER_IMAGE', false)
            })
            .catch(error => {
                commit('SET_UPLOADING_HEADER_IMAGE', false)
                throw error;
            })
    },

    updateStaffImage ({ commit, state }, image) {

        let data = new FormData()

        data.append('staff_image', image)

        commit('SET_UPLOADING_HEADER_IMAGE', true)

        return this.$axios
            .post('/api/accounts/' + state.account.id + '/staff-image', data)
            .then(r => r.data)
            .then(account => {
                commit('SET_UPLOADING_HEADER_IMAGE', false)
            })
            .catch(error => {
                commit('SET_UPLOADING_HEADER_IMAGE', false)
                throw error;
            })
    },

    deleteStaffImage ({ commit, state }) {

        commit('SET_UPLOADING_HEADER_IMAGE', true)
        return this.$axios
            .post('/api/accounts/' + state.account.id + '/delete-staff-image')
            .then(r => r.data)
            .then(account => {
                commit('SET_UPLOADING_HEADER_IMAGE', false)
            })
            .catch(error => {
                commit('SET_UPLOADING_HEADER_IMAGE', false)
                throw error;
            })
    },

    updateProfileImage ({ commit, state }, image) {

        let data = new FormData()

        data.append('profile_image', image)

        commit('SET_UPLOADING_PROFILE_IMAGE', true)

        return this.$axios
            .post('/api/accounts/' + state.account.id + '/profile-image', data)
            .then(r => r.data)
            .then(account => {
                commit('SET_UPLOADING_PROFILE_IMAGE', false)
            })
            .catch(error => {
                commit('SET_UPLOADING_PROFILE_IMAGE', false)
                throw error;
            })
    },

    publishImages ({ commit, state }, images) {

        commit('SET_PUBLISHING_IMAGES', true)

        return this.$axios
            .post('/api/accounts/' + state.account.id + '/publish-images', images)
            .then(r => r.data)
            .then(account => {
                commit('SET_PUBLISHING_IMAGES', false)
            })
            .catch(error => {
                commit('SET_UPLOADING_PROFILE_IMAGE', false)
                throw error;
            })
    },

    blockUser ({ commit, state}, userId) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .post('/api/accounts/' + state.account.id + '/block-user', { user_id: userId })
            .then(r => r.data)
            .then(user => {
                commit('users/APPEND_USER', user, { root: true })
                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },

    unblockUser ({ commit, state}, userId) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .post('/api/accounts/' + state.account.id + '/unblock-user', { user_id: userId })
            .then(r => r.data)
            .then(user => {
                commit('users/APPEND_USER', user, { root: true })
                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_SAVING', false)
                throw error;
            })
    },

    acceptUserRequest ({ commit, state}, userId) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .post('/api/accounts/' + state.account.id + '/accept-request', { user_id: userId })
            .then(r => r.data)
            .then(user => {
                commit('users/APPEND_USER', user, { root:true })
                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },
}