import Vue from 'vue'
import moment from 'moment'
import _ from 'lodash'

moment.locale('it');

Vue.filter('fullMonthName', monthNumber => moment.months(monthNumber - 1))
Vue.filter('monthName', monthNumber => moment.monthsShort(monthNumber - 1))
Vue.filter('fromNow', datetime => moment.utc(datetime).fromNowOrNow())
Vue.filter('format', (date, format) => moment.utc(date).tz('Europe/Rome').format(format))
Vue.filter('truncate', (text, maxLength) => _.truncate(text, { length: maxLength, separator: /,? +/ }))
Vue.filter('toTime', (text) => {
    let words = text.split(" ");

    return Math.ceil(words.length / 275) + ' ' + (Math.ceil(words.length / 275) === 1 ? 'minuto' : 'minuti') + ' di lettura';
});
Vue.filter('toHumanReadableNumber', (number) => {
    let n = 0;
    if(number >= 1000000) {
        n = (number / 1000000).toFixed()
        if(n > 1) return n + 'Milioni'
        return n + 'Milione'
    } else if (number >= 2000) {
        return (number / 1000).toFixed() + 'Mila'
    } else {
        return number
    }
});
Vue.filter('pad', (text = '', length, char = '0') => {
    for(let i = 0; i < length; i++) {
        text = char + text;
    }

    return text.slice(- length)
})
Vue.filter('nl2br', (text) => {
    if (typeof text === 'undefined' || text === null) {
        return '';
    }

    return (text + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '<br />' + '$2');
})