import Vue from 'vue'
import { getField, updateField } from 'vuex-map-fields';
import _ from 'lodash'
import moment from "moment-timezone";

export const state = () => ({
  filters: {
    text: null,
  },
  sort: {
    column: 'created_at',
    order: 'desc'
  },
  pagination: {
    page: 0,
    per_page: 5,
    last_page: 0,
  },
  invoices: [],
  totalCount: 0,
  infiniteScrollEnabled: true,
  processing: false
})

export const getters = {
  getField,
  getFilteredInvoices: (state) => (filters) => {

    let sort = state.sort

    if(filters.hasOwnProperty('sort')) {
      sort = filters.sort;
    }

    let filteredInvoices = _.filter(state.invoices, function (groupBuy) {

      let passes = true;

      // if(filters.hasOwnProperty('text') && filters.text && filters.text.length) {
      //
      //   passes = passes && (
      //     (groupBuy.title ? groupBuy.title.match(new RegExp(filters.text, 'i')) : false) ||
      //     (groupBuy.abstract ? groupBuy.abstract.match(new RegExp(filters.text, 'i')) : false) ||
      //     (groupBuy.text ? groupBuy.text.match(new RegExp(filters.text, 'i')) : false)
      //   )
      // }

      return passes
    })

    return _.orderBy(filteredInvoices, sort.column ? sort.column : 'created_at', sort.order ? sort.order : 'desc')
  }
}

export const mutations = {
  updateField,
  SET_FILTERS (state, filters) {
    state.filters = filters
  },
  SET_PROCESSING (state, processing) {
    state.processing = processing
  },
  SET_INVOICES (state, invoices) {
    state.invoices = invoices
  },
  APPEND_INVOICE (state, invoice) {

    let index = state.invoices.findIndex(myInvoice => {
      return myInvoice.id === invoice.id
    })

    if(index < 0 || !invoice.id) {

      state.invoices.push(invoice);
    } else {

      Vue.set(state.invoices, index, invoice)
    }
  },
  REMOVE_INVOICE (state, invoice) {
    let invoiceIndex = _.findIndex(state.invoices, {id: invoice.id});

    if (invoiceIndex > -1) {
      state.groupBuys.splice(invoiceIndex, 1);
    }
  },
  APPEND_INVOICES (state, invoices) {

    _.each(invoices, (invoice) => {
      state.invoices.push(invoice);
    });
  },
  SET_INFINITE_SCROLL_ENABLED (state, infiniteScrollEnabled) {
    state.infiniteScrollEnabled = infiniteScrollEnabled
  },
  SET_TOTAL_COUNT (state, totalCount) {
    state.totalCount = totalCount
  },
  SET_LAST_PAGE (state, lastPage) {
    state.pagination.last_page = lastPage
  }
}

export const actions = {

  fetch ({ commit, state, dispatch }, data) {

    commit('SET_PROCESSING', true);

    let filters = data.filters ?  data.filters : state.filters;
    let sort = data.sort ?  data.sort : state.filters;

    return this.$axios
      .get('/api/invoices', {
        params: {
          ...sort,
          ...filters,
          page: data && data.page ? data.page : state.pagination.page,
          per_page: data && data.per_page ? data.per_page : state.pagination.per_page
        }
      })
      .then(r => r.data)
      .then(response => {

        if(state.invoices.length) {

          _.each(response.data, (invoice) => {

            commit('APPEND_INVOICE', invoice);
          });
        } else {
          commit('SET_INVOICES', response.data);
        }

        commit('SET_TOTAL_COUNT', response.total);
        commit('SET_LAST_PAGE', response.last_page);
        commit('SET_INFINITE_SCROLL_ENABLED', response.data.length >= ( data && data.per_page ? data.per_page : state.pagination.per_page));
        commit('SET_PROCESSING', false)
      })
      .catch(error => {
        commit('SET_PROCESSING', false)
        throw error;
        // console.log('fetch error: ' + error)
      })
  }
}