<template>
    <div>
        <div class="header fixed">
            <div class="top-bar">

                <div class="left">
                    <logo></logo>
                    <search-bar v-if="auth.loggedIn && auth.user.wizard_completed"></search-bar>
                </div>

                <div class="right">
                    <div class="user-navigation" v-if="auth.loggedIn && auth.user.wizard_completed">
                        <user-info></user-info>

                        <quick-navigation class="d-none d-lg-flex"></quick-navigation>
                        <user-menu class="d-none d-lg-flex">
                            <template slot="menu-button">
                                <i class="cf-hamburger"></i>
                            </template>
                        </user-menu>
                    </div>
                    <div v-if="!auth.loggedIn">
                        <login-box></login-box>
                    </div>
                </div>
            </div>
        </div>
        <!--<div>-->
            <!--<div v-if="account">-->
                <!--<nuxt-link to="/checkout" v-if="!account.subscription_active && auth.user.wizard_completed && !auth.user.wizard_completed_now">-->
                    <!--<div class="alert alert-warning mb-0 text-center">-->
                        <!--<i class="fa fa-exclamation-triangle"></i> {{ $t('Non sei ancora diventato associato. Paga ora la tua quota associativa per usufruire di tutti i servizi esclusivi di Confestetica!')}}-->
                    <!--</div>-->
                <!--</nuxt-link>-->
            <!--</div>-->
        <!--</div>-->
    </div>
</template>

<script>
    import { mapState, mapGetters } from 'vuex'
    import Logo from './Logo'
    import SearchBar from './SearchBar'
    import UserInfo from './UserInfo'
    import LoginBox from './LoginBox'
    import QuickNavigation from './QuickNavigation'
    import UserMenu from '~/components/UserMenu'

    export default {

        components: {
            Logo,
            SearchBar,
            UserInfo,
            LoginBox,
            QuickNavigation,
            UserMenu
        },

        computed:  {
            ...mapState([
                'auth'
            ]),
            ...mapGetters([
                'account'
            ])
        },

        // methods: {
        //     ...mapGetters({
        //         'refetchNotifications': 'notifications/fetch'
        //     })
        // },

        mounted () {

//            let channel = this.$pusher.subscribe('App.Users');
//
//            channel.bind('new-notification', function(data) {
//                this.$store.dispatch('notifications/fetch')
//            }, this);
        }
    }
</script>