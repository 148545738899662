<template>
  <div class="d-flex flex-column overflow-hidden" :class="{'guest': !auth.loggedIn}">
    <header-bar class="header-fixed d-none d-lg-block"></header-bar>
    <div class="page boxed responsive w-100 flex-grow-1 background-gray"
         :class="{'guest': !auth.loggedIn, 'user-select-none': menuOpen, 'dropup-open': dropupOpen}"
    >
      <client-only>
        <notifications group="messages" position="top center" />

        <notifications class="validation-messages" group="validation-messages" position="top center" :style="'top: ' + ((insets ? insets.top : 0) + 20) + 'px'">
          <template slot="body" slot-scope="props">
            <div class="validation-message" :class="props.item.type">
              {{ props.item.text }}
            </div>
          </template>
        </notifications>

        <notifications group="communications" :position="mobileMode ? 'top center' : 'bottom left'">
          <template slot="body" slot-scope="props">
            <notification-card :notification="props.item"></notification-card>
          </template>
        </notifications>
      </client-only>

      <nuxt keep-alive/>
      <update-app-modal></update-app-modal>
    </div>
    <bottom-bar class="bottom-fixed" v-if="showBottomBar" />
  </div>

</template>

<script>
    import { mapState } from 'vuex'
    import HeaderBar from '~/components/HeaderBar'
    import BottomBar from '~/components/BottomBar'
    import UserImage from "../components/UserImage";
    import NotificationCard from '~/components/NotificationCard'
    import UpdateAppModal from '~/components/UpdateAppModal';

    import _ from 'lodash'

    const bodyScrollLock = require('body-scroll-lock')

    export default {

        components: {
            UserImage,
            HeaderBar,
            BottomBar,
            NotificationCard,
            UpdateAppModal
        },

        watch: {
          'auth.loggedIn': function(val) {
              if(val) {
                  this.$store.dispatch('authenticateEchoChannels', this);
              } else {
              }
          }
        },

        data() {
            return {
                height: null,
                bottomOffsetExcludedRoutes: []
            }
        },

        computed: {
            ...mapState([
                'register',
                'bottomOffset',
                'auth',
                'mobileMode',
                'menuOpen',
                'dropupOpen',
                'insets'
            ]),
            showBottomBar() {

                let paths = [
                    // '/gestione-utenti'
                ]

                return _.includes(paths, this.$route.path)
            }
        },

        destroyed() {
            bodyScrollLock.clearAllBodyScrollLocks();
        },

        mounted() {

            let pages = document.querySelectorAll('body .page.responsive .content .card .card-body')

            if(pages.length) {

                setTimeout(() => {
                    _.each(pages, (page) => {
                        // bodyScrollLock.disableBodyScroll(page)
                    })
                }, 500)
            }

            this.height = window.innerHeight;

            if (this.$auth && this.$auth.loggedIn) {

                this.$store.dispatch('authenticateEchoChannels', this);
            }
        }
    }
</script>

<style>
    html {
         font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
         font-size: 16px;
         word-spacing: 1px;
         -ms-text-size-adjust: 100%;
         -webkit-text-size-adjust: 100%;
         -moz-osx-font-smoothing: grayscale;
         -webkit-font-smoothing: antialiased;
         box-sizing: border-box;
     }

    *, *:before, *:after {
        box-sizing: border-box;
        margin: 0;
    }

</style>

