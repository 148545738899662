import Vue from 'vue'
import { getField, updateField } from 'vuex-map-fields';
import _ from 'lodash'

export const state = () => ({
    filters: {
        status: ''
    },
    pagination: {
        page: 0,
        per_page: 15,
    },
    data: [],
    totalCount: 0,
    archivedConversationsCount: 0,
    unreadConversationsCount: 0,
    infiniteScrollEnabled: true,
    processing: false,
    saving: false,
})

export const getters = {
    getField,
    getConversationById: (state) => (conversationId) => {
        return _.find(state.data, {id: parseInt(conversationId)})
    },
    activeConversations(state) {
        return _.filter(state.data, (record) => {
            return record.messagesCount > 0
        });
    }
}

export const mutations = {
    updateField,
    SET_PROCESSING (state, processing) {
        state.processing = processing
    },
    APPEND (state, record) {

        if (!_.includes(_.map(state.data, 'id'), record.id) || !record.id) {

            state.data.push(record);
        } else {

            let recordIndex = _.findIndex(state.data, { id: record.id });

            Vue.set(state.data, recordIndex, record);
        }
    },
    REMOVE (state, record) {

        if (record.id) {

            let recordIndex = _.findIndex(state.data, {'id': record.id});

            if (recordIndex !== -1) {
                state.data.splice(recordIndex, 1);
            }
        }
    },
    SET_INFINITE_SCROLL_ENABLED (state, infiniteScrollEnabled) {
        state.infiniteScrollEnabled = infiniteScrollEnabled
    },
    SET_TOTAL_COUNT (state, totalCount) {
        state.totalCount = totalCount
    },
    SET_UNREAD_CONVERSATIONS_COUNT (state, unreadConversationsCount) {
        state.unreadConversationsCount = unreadConversationsCount
    },
    SET_ARCHIVED_CONVERSATIONS_COUNT (state, archivedConversationsCount) {
        state.archivedConversationsCount = archivedConversationsCount
    }
}

export const actions = {

    fetch ({ commit, state }, data) {

        commit('SET_PROCESSING', true);

        let filters = state.filters;

        if(data && data.hasOwnProperty('filters')) {
            filters = data.filters
        }

        return this.$axios
            .get('/api/conversations', {
                params: {
                    ...filters,
                    page: data && data.page ? data.page : state.pagination.page,
                    per_page: data && data.per_page ? data.per_page : state.pagination.per_page
                }
            })
            .then(r => r.data)
            .then(response => {

                if(response.hasOwnProperty('archived_conversations_count')) {
                    commit('SET_ARCHIVED_CONVERSATIONS_COUNT', response.archived_conversations_count)
                }

                if(response.hasOwnProperty('unread_conversations_count')) {
                    commit('SET_UNREAD_CONVERSATIONS_COUNT', response.unread_conversations_count)
                }

                _.each(response.data, (conversation) => {
                    commit('APPEND', conversation);

                    _.each(conversation.messages, (message) => {
                        commit('messages/APPEND', message, {root: true})
                    });
                    if (conversation.messages.length === 0 && conversation.last_message) {

                        commit('messages/APPEND', conversation.last_message, {root: true})
                    }
                });

                commit('SET_TOTAL_COUNT', response.total);
                commit('SET_INFINITE_SCROLL_ENABLED', response.data.length >= ( data && data.per_page ? data.per_page : state.pagination.per_page));
                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },

    fetchById ({ dispatch, commit, state}, conversationId) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .get('/api/conversations/' + conversationId)
            .then(r => r.data)
            .then(conversation => {
                commit('APPEND', conversation);

                _.each(conversation.messages, (message) => {
                    commit('messages/APPEND', message, {root: true})
                });

                if (conversation.last_message) {

                    commit('messages/APPEND', conversation.last_message, {root: true})
                }

                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },

    create ({ commit, state}, data) {

        commit('SET_SAVING', true)

        return this.$axios
            .post('/api/conversations', data)
            .then(r => r.data)
            .then(conversation => {
                commit('SET_SAVING', false)
                commit('APPEND', conversation )

                return conversation
            })
            .catch(error => {
                commit('SET_SAVING', false)
                throw error;
            })
    },

}