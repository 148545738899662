<template>
    <div class="h-100">
        <intro :type="type" :modal="modal" v-if="workExperience.step === 'intro'"></intro>
        <account-vat-id :type="type" v-if="workExperience.step === 'account-vat-id'"></account-vat-id>
        <search-accounts :type="type" v-if="workExperience.step === 'search-accounts'"></search-accounts>
        <certificate :type="type" v-if="workExperience.step === 'certificate'"></certificate>
        <verify-certificate :type="type" v-if="workExperience.step === 'verify-certificate'"></verify-certificate>
        <location-photo :type="type" v-if="workExperience.step === 'location-photo'"></location-photo>
        <account-address :type="type" v-if="workExperience.step === 'account-address'"></account-address>
        <work-experience :type="type" :modal="modal" v-if="workExperience.step === 'work-experience'"></work-experience>
        <specialisation :type="type" v-if="workExperience.step === 'specialisation'"></specialisation>
        <profile-image :type="type" v-if="workExperience.step === 'profile-image'"></profile-image>
        <header-image :type="type" v-if="workExperience.step === 'header-image'"></header-image>
        <account-phone-number :type="type" v-if="workExperience.step === 'account-phone-number'"></account-phone-number>
        <account-whatsapp-number :type="type" v-if="workExperience.step === 'account-whatsapp-number'"></account-whatsapp-number>
        <staff-invite :type="type" v-if="workExperience.step === 'staff-invite'"></staff-invite>
        <owner-invite :type="type" v-if="workExperience.step === 'owner-invite'"></owner-invite>
        <complete :type="type" :modal="modal" v-if="workExperience.step === 'complete'"></complete>
        <account-connected :type="type" :modal="modal" v-if="workExperience.step === 'account-connected'"></account-connected>
    </div>
</template>

<script>
    import { mapState, mapMutations } from 'vuex'
    import Intro from '~/components/WorkingPlace/Steps/Intro'
    import AccountAddress from "~/components/WorkingPlace/Steps/AccountAddress";
    import HeaderImage from "~/components/WorkingPlace/Steps/HeaderImage";
    import ProfileImage from "~/components/WorkingPlace/Steps/ProfileImage";
    import SearchAccounts from "~/components/WorkingPlace/Steps/SearchAccounts";
    import StaffInvite from "~/components/WorkingPlace/Steps/StaffInvite";
    import OwnerInvite from "~/components/WorkingPlace/Steps/OwnerInvite";
    import Complete from "~/components/WorkingPlace/Steps/Complete";
    import AccountVatId from "~/components/WorkingPlace/Steps/AccountVatId";
    import AccountPhoneNumber from "~/components/WorkingPlace/Steps/AccountPhoneNumber";
    import AccountWhatsappNumber from "~/components/WorkingPlace/Steps/AccountWhatsappNumber";
    import Certificate from "~/components/WorkingPlace/Steps/Certificate";
    import VerifyCertificate from "~/components/WorkingPlace/Steps/VerifyCertificate";
    import LocationPhoto from "~/components/WorkingPlace/Steps/LocationPhoto";
    import Specialisation from "~/components/WorkingPlace/Steps/Specialisation";
    import WorkExperience from "~/components/WorkingPlace/Steps/WorkExperience";
    import AccountConnected from "~/components/WorkingPlace/Steps/AccountConnected";

    export default {

        head() {

            let src = '';

            if (typeof google !== 'object') {
                src = 'https://maps.googleapis.com/maps/api/js?key=' + process.env.GOOGLE_API_KEY + '&libraries=places';
            }

            return {
                script: [
                    {
                        src: src
                    }
                ]
            }
        },

        components: {
            AccountConnected,
            WorkExperience,
            Specialisation,
            LocationPhoto,
            Certificate,
            VerifyCertificate,
            AccountWhatsappNumber,
            AccountPhoneNumber,
            AccountVatId,
            Complete,
            OwnerInvite,
            StaffInvite,
            SearchAccounts,
            AccountAddress,
            Intro,
            ProfileImage,
            HeaderImage
        },


        props: {
            type: {
                type: String,
                default: function () {
                    return 'work';
                }
            },
            accountType: {
                type: String,
                default: function () {
                    return null;
                }
            },
            modal: {
                type: Boolean,
                default: function () {
                    return false;
                }
            }
        },

        data () {
            return {
                editCertificate: {},
                imgSrc: '',
                blob: ''
            }
        },

        computed: {
            ...mapState({
                user: 'user',
                workExperience: 'work-experience',
            }),
        },

        methods: {
            ...mapMutations({
                'setWorkExperienceStep': 'work-experience/SET_STEP',
            })
        },

        created () {
        }
    }
</script>
