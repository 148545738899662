
import { getField, updateField } from 'vuex-map-fields';

export const state = () => ({
    filters: {
        text:''
    },
    treatments: [],
    totalCount: 0,
    processing: false
})

export const getters = {
    getField
}

export const mutations = {
    SET_PROCESSING (state, processing) {
        state.processing = processing
    },
    SET_TREATMENTS (state, treatments) {
        state.treatments = treatments
    },
    SET_TOTAL_COUNT (state, totalCount) {
        state.totalCount = totalCount
    }
}

export const actions = {
    
    fetchAll ({ commit, state}) {

        if(state.processing) return;
        commit('SET_PROCESSING', true)

        return this.$axios
            .get('/api/treatments', { params: state.filters })
            .then(r => r.data)
            .then(response => {
                commit('SET_TREATMENTS', response.data)
                commit('SET_TOTAL_COUNT', response.total)
                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    }
}