<template>
    <b-modal ref="userInformations" modal-class="slide-up" body-class="p-0" hide-header hide-footer no-fade class="card border-0" @shown="init" @hidden="clear" static lazy>
        <div class="card w-100"
             :style="'height: 100vh!important;height: calc(var(--vh, 1vh) * 100)!important;padding-top:' + insets.top + 'px!important; padding-bottom:' + insets.bottom + 'px'"
        >
            <div class="card-header card-header-sm d-flex justify-content-center align-items-center">
                <i class="cf-delete absolute p-3" @click.prevent="$refs.userInformations.hide()" style="left: 0;"></i>
                <span v-if="$auth.user && user.user.id === $auth.user.id">{{ $t('Le mie informazioni') }}</span>
                <span v-else>{{ $t('Le informazioni di ') }} {{ user.user.first_name}}</span>
            </div>
            <div class="card-body p-0 scrollable-content overflow-auto">
                <work-experiences v-if="user.user.work_experiences.length || ($auth.user && $auth.user.id === user.user.id)" :limit="2"></work-experiences>
                <div class="background-white"><hr class="m-0 mr-3 ml-3"></div>
                <education-card v-if="user.user.diploma || user.user.specialisation_diploma || user.user.attending_place || ($auth.user && $auth.user.id === user.user.id)" :limit="2"></education-card>
                <div class="background-white"><hr class="m-0 mr-3 ml-3"></div>
                <certificates v-if="getUpdateCertificatesForEntity(user.user.id, 'App\\User').length || getSpecialisationCertificatesForEntity(user.user.id, 'App\\User').length || ($auth.user && $auth.user.id === user.user.id)" :limit="2"></certificates>
                <div class="background-white"><hr class="m-0 mr-3 ml-3"></div>
                <specialisations v-if="user.user.specialisation1 || user.user.specialisation2 || user.user.specialisation3 || ($auth.user && $auth.user.id === user.user.id)" :modal="true"></specialisations>
                <div class="background-white"><hr class="m-0 mr-3 ml-3"></div>
                <cities-card v-if="user.user.birth_city || user.user.city || ($auth.user && $auth.user.id === user.user.id)"></cities-card>
                <div class="background-white"><hr class="m-0 mr-3 ml-3"></div>
                <contact-information v-if="user.user.address || ($auth.user && $auth.user.id === user.user.id)" :modal="true"></contact-information>
                <div class="background-white"><hr class="m-0 mr-3 ml-3"></div>
                <basic-information v-if="user.user.gender || user.user.birth_year || ($auth.user && $auth.user.id === user.user.id)" :modal="true"></basic-information>

                <!--<diploma v-if="user.user.diploma || ($auth.user && $auth.user.id === user.user.id)"></diploma>-->
                <!--<specialisation-diploma v-if="user.user.diploma_specialisation || (user.user.diploma && $auth.user && $auth.user.id === user.user.id )"></specialisation-diploma>-->
            </div>
        </div>
    </b-modal>
</template>

<script>

    import { mapState, mapGetters } from 'vuex';
    import Certificates from "~/components/UserProfile/ProfileInfo/Certificates";
    import WorkExperiences from "~/components/UserProfile/ProfileInfo/WorkExperiences";
    import BasicInformation from "~/components/UserProfile/ProfileInfo/BasicInformation";
    import ContactInformation from "~/components/UserProfile/ProfileInfo/ContactInformation";
    import Diploma from "~/components/UserProfile/ProfileInfo/Diploma";
    import SpecialisationDiploma from "~/components/UserProfile/ProfileInfo/SpecialisationDiploma";
    import Specialisations from "~/components/UserProfile/ProfileInfo/Specialisations";
    import EducationCard from "~/components/UserProfile/ProfileInfo/Mobile/EducationCard";
    import CitiesCard from "~/components/UserProfile/ProfileInfo/Mobile/CitiesCard";

    const bodyScrollLock = require('body-scroll-lock')

    export default {

        components: {
            SpecialisationDiploma,
            WorkExperiences,
            Certificates,
            BasicInformation,
            ContactInformation,
            Diploma,
            Specialisations,
            EducationCard,
            CitiesCard
        },

        computed: {
            ...mapState({
                insets: 'insets',
                user: 'user'
            }),
            ...mapGetters({
                'isEditable': 'user/IS_EDITABLE_BY_USER',
                'getUpdateCertificatesForEntity': 'certificates/getUpdateCertificatesForEntity',
                'getSpecialisationCertificatesForEntity': 'certificates/getSpecialisationCertificatesForEntity'
            })
        },

        methods: {

            init() {

            },

            clear() {

            },

            showModal() {
                this.$refs.userInformations.show();
            },
        },

        mounted () {
            this.$nuxt.$on('close-user-informations-modal', () => {
                this.$refs.userInformations.hide();
            })
        }
    }
</script>

<style lang="scss" scoped>
    .card {
        .card-footer {
            background-color: #f4f2f3;
        }
    }
</style>