<template>
    <div class="card">
        <slot name="card-header">
            <div class="card-header card-header-sm  d-flex justify-content-center align-items-center">
                <i class="cf-back absolute p-3" style="left: 0;" @click.prevent="setStep('profile-image')"></i>
                {{ $t('Copertina del tuo centro')}}
            </div>
        </slot>
        <div class="card-body d-flex flex-column justify-content-center flex-grow-1 p-4">
            <cropper type="Header" :img-src.sync="imgSrc" ref="editImageCropper"></cropper>
        </div>
        <div class="card-footer d-flex">
            <button class="btn btn-brand btn-transparent mr-1 w-100" @click.prevent="setStep('staff-invite')">
                {{ $t('Salta') }}
            </button>
            <button class="btn btn-brand ml-1 w-100" @click.prevent="updateImage" :disabled="!imgSrc && !account.account.header_image">
                {{ $t('Ok') }}
            </button>
        </div>
    </div>
</template>

<script>

    import { mapState, mapMutations } from 'vuex';
    import Cropper from "~/components/WorkingPlace/Steps/Cropper";

    export default {
        components: {
            Cropper
        },

        props: ['type'],

        data () {
            return {
                imgSrc: ''
            }
        },
        computed: {
            ...mapState({
                account: 'account',
                workExperience: 'work-experience'
            })
        },
        methods: {
            ...mapMutations({
                'setStep': 'work-experience/SET_STEP'
            }),

            updateImage() {

                if (this.imgSrc) {
                    this.$refs.editImageCropper.saveImage()
                }
                else {
                    this.setStep('staff-invite')
                }
            },

            closeUpdateProfile() {
                this.$router.push('/dashboard')
            }
        }
    }
</script>