<template>
    <div class="text-center d-flex flex-column h-100">
        <slot name="card-header">
            <div class="card-header card-header-sm  d-flex justify-content-center align-items-center">
                <i class="cf-back absolute p-3" style="left: 0;" @click.prevent="setStep('search-accounts')"></i>
                <span>{{ $t('Collegati ad un luogo di lavoro') }}</span>
            </div>
        </slot>
        <div class="card-body text-center p-4">
            <cropper type="Brand" :img-src.sync="imgSrc" :blob.sync="blob" ref="editImageCropper"></cropper>
        </div>
        <div class="card-footer d-flex flex-column">
            <div v-if="!imgSrc">
                <button class="btn btn-brand w-100 mb-2" @click.prevent="openInput">
                    <i class="cf-camera-f mr-2"></i>
                    <span v-if="!blob">{{ $t('Scegli un\'immagine') }}</span>
                    <span v-else>{{ $t('Modifica immagine') }}</span>
                </button>
                <button class="btn btn-brand w-100" @click.prevent="saveBrand" v-if="!imgSrc" :disabled="!blob">
                    {{ $t('Crea brand') }}
                </button>
            </div>
            <button class="btn btn-brand w-100" @click.prevent="nextStep()" v-else>
                {{ $t('Avanti') }}
            </button>
        </div>
    </div>
</template>

<script>

    import Cropper from '~/components/AccountProfile/Cropper'

    export default {

        components: {
            Cropper
        },

        props: ['account', 'setStep', 'confirmCallback'],

        data () {
            return {
                imgSrc: '',
                blob: null
            }
        },

        watch: {
            blob: function (val) {

                this.$emit('update:profile_image', val)
            }
        },

        methods: {

            openInput(isVisible) {
                this.$refs.editImageCropper.openInput(isVisible);
            },
            saveBrand() {

                this.$nuxt.$loading.start()

                this.$store
                    .dispatch('account/create', this.account)
                    .then(account => {

                        this.confirmCallback(account);
                        this.$nuxt.$emit('close-account-modal');
                    })
                    .finally(() => {
                        this.$nuxt.$loading.finish()
                    })
            },
            nextStep() {

                this.$refs.editImageCropper.getImage();
            }
        }
    }
</script>