<template>
    <div class="quick-navigation">

        <div class="divider"></div>

        <div class="d-flex align-items-center">
            <nuxt-link to="/">{{ $t('Home') }}</nuxt-link>
        </div>

        <div class="divider"></div>

        <div>
            <user-messages>
                <template slot="menu-button">
                    <nuxt-link to="/chat" tag="div">
                        <i class="fa-lg cf-chat" :class="{ 'text-white': unreadMessagesCount }"></i>
                        <div class="absolute circle-number-container d-flex justify-content-center" v-if="unreadMessagesCount">{{ unreadMessagesCount < 99 ? unreadMessagesCount : 99 }}</div>
                    </nuxt-link>
                </template>
            </user-messages>
        </div>

        <div class="ml-2">
            <user-notifications>
                <template slot="menu-button">
                    <div @click.prevent="clearNotifications">
                        <i class="fa-lg cf-bell" :class="{ 'text-white': $auth.user.unread_notifications_count }"></i>
                        <div class="absolute circle-number-container d-flex justify-content-center" v-if="$auth.user.unread_notifications_count">{{ $auth.user.unread_notifications_count < 99 ? $auth.user.unread_notifications_count : 99 }}</div>
                    </div>
                </template>
            </user-notifications>
        </div>

        <div class="divider"></div>
    </div>
</template>

<script>
    import { mapGetters, mapState } from 'vuex'
    import UserImage from '~/components/UserImage'
    import UserNotifications from '~/components/UserNotifications'
    import UserMessages from '~/components/UserMessages'

    export default {

        components: {
            UserImage,
            UserNotifications,
            UserMessages
        },

        computed: {
            ...mapState([
                'auth'
            ]),
            ...mapGetters({
                'unreadNotifications': 'notifications/getUnreadNotifications',
                'unreadMessages': 'messages/UNREAD_MESSAGES'
            }),
            unreadMessagesCount() {
                return this.unreadMessages.filter((message) => {
                    return message.user_id !== this.$auth.user.id;
                }).length
            }
        },

        methods: {
            clearNotifications() {

                this.$store.dispatch('notifications/clear').then(this.$auth.fetchUser())

                let event = {
                    event: 'clear-notifications'
                }

                if (window.ReactNativeWebView) {
                    window.ReactNativeWebView.postMessage(JSON.stringify(event));
                } else {
                    window.postMessage(JSON.stringify(event), '*');
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .quick-navigation {
        display: flex;
        align-items: center;
        a {
            text-decoration: none;
            color: #fff;
            font-size: 15px;
            &.menu-open i {
                color: #63C1B7 !important;
            }
        }
        i {
            color: #401140;
        }

        .divider {
            margin: 0 14px;
            width: 2px;
            height: 33px;
            border: 1px solid #551C56;
        }
    }

    .circle-number-container {
        border-radius: 50%;
        top: -6px;
        right: -4px;
        width: 14px;
        height: 14px;
        background-color: red;
        color: white;
        font-size: 12px;
    }
</style>