<template>
    <b-modal ref="basicInformation" modal-class="slide-left" body-class="p-0" hide-header hide-footer no-fade class="card border-0" static lazy>
        <div class="card w-100"
             :style="'height: 100vh!important;height: calc(var(--vh, 1vh) * 100)!important;padding-top:' + insets.top + 'px!important; padding-bottom:' + insets.bottom + 'px'"
        >
            <div class="card-header card-header-sm d-flex justify-content-center align-items-center">
                <i class="cf-delete absolute p-3" @click.prevent="$refs.basicInformation.hide()" style="left: 0;"></i>
                {{ $t('Modifica del profilo') }}
                <u class="p-3 absolute font-size-base" @click.prevent="saveUser" style="right: 0;">{{ $t('Salva') }}</u>
            </div>
            <div class="card-body background-dark-gray p-0 overflow-auto">
                <div class="card mt-2">
                    <div class="p-2 pl-3 font-size-xxl font-weight-xbold">
                        {{ $t('Data di nascita') }}
                    </div>
                    <div class="p-2 pl-3 justify-content-between">
                        <date-picker :day.sync="editUser.birth_day" :month.sync="editUser.birth_month" :year.sync="editUser.birth_year">
                            <span class="text-grey" slot="placeholder">{{ $t('Data di nascita') }}</span>
                        </date-picker>
                    </div>
                </div>
                <div class="card mt-2">
                    <div class="p-2 pl-3 font-size-xxl font-weight-xbold">
                        {{ $t('Genere') }}
                    </div>
                    <div class="p-3 justify-content-between border-bottom" @click.prevent="editUser.gender = 'female'">
                        <div class="d-flex justify-content-between">
                            {{ $t('Donna')}}
                            <div v-if="editUser.gender === 'female'"><img src="~/assets/images/icons/tick-sign-brand.svg" width="20px"></div>
                        </div>
                    </div>
                    <div class="p-3 justify-content-between border-bottom" @click.prevent="editUser.gender = 'male'">
                        <div class="d-flex justify-content-between">
                            {{ $t('Uomo')}}
                            <div v-if="editUser.gender === 'male'"><img src="~/assets/images/icons/tick-sign-brand.svg" width="20px"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>

    import { mapActions, mapState, mapGetters } from 'vuex';

    import DatePicker from "~/components/DatePicker";

    import _ from 'lodash'

    export default {

        components: {
            DatePicker,
        },

        computed: {
            ...mapState({
                insets: 'insets',
                user: 'user'
            }),
            ...mapGetters({
                'isEditable': 'user/IS_EDITABLE_BY_USER',
                'getUpdateCertificatesForEntity': 'certificates/getUpdateCertificatesForEntity',
                'getSpecialisationCertificatesForEntity': 'certificates/getSpecialisationCertificatesForEntity'
            })
        },

        data() {
            return {
                editUser: _.cloneDeep(this.$store.state.user.user)
            }
        },

        methods: {
            ...mapActions({
                'updateUser': 'user/update'
            }),
            showModal() {
                this.$refs.basicInformation.show();
            },
            saveUser() {
                this.$nuxt.$loading.start();
                this.updateUser(this.editUser)
                    .then(() => {
                        this.$nuxt.$loading.finish();
                        this.$refs.basicInformation.hide();
                    })
                    .catch(() => {
                        this.$nuxt.$loading.finish();
                    });
            }
        },

        mounted () {
            this.$nuxt.$on('close-basic-informations-modal', () => {
                this.$refs.basicInformation.hide();
            })
        }
    }
</script>

<style lang="scss" scoped>
    .card {
        .card-footer {
            background-color: #f4f2f3;
        }
    }
</style>