<template>
    <div v-if="user">
        <div v-if="user.profile_rank && user.profile_points" class="no-wrap">
            <div v-if="user.id === 19437" class="d-flex align-items-center text-brand" :class="{'justify-content-center': position === 'center', 'justify-content-start': position === 'left'}">
                <img src="~/assets/images/ico-c.png" width="12px" height="12px">&nbsp{{ $t('Segretario Nazionale') }}
            </div>
            <div v-else class="d-flex align-items-center" :class="{'justify-content-center': position === 'center', 'justify-content-start': position === 'left'}">
                {{ $t('Estetista') }}&nbsp
                <div class="font-weight-bold" v-if="!hideText">
                    <span v-if="user.profile_rank.indexOf('top') > -1">{{ $t('Top Senior')}}</span>
                    <span v-else-if="user.profile_rank.indexOf('senior') > -1">{{ $t('Senior')}}</span>
                    <span v-else-if="user.profile_rank.indexOf('junior') > -1">{{ $t('Junior')}}</span>
                    {{ parseInt(user.profile_points) }}
                </div>
                <slot name="time"></slot>
            </div>
        </div>
        <div v-else>
            <div v-if="!hideText">
                <div class="d-flex align-items-center no-wrap" :class="{'justify-content-center': position === 'center', 'justify-content-start': position === 'left'}">
                    <span v-if="user.profile_type === 'student'">{{ $t('Estetista Studentessa') }}</span>
                    <span v-else-if="user.profile_type === 'professional' && $auth.loggedIn && user.id === $auth.user.id && !$auth.user.questionnaire_completed">
                        <nuxt-link class="w-100" to="/test-estetista">{{ $t('Scopri che estetista sei' )}}</nuxt-link>
                    </span>
                    <span v-else-if="user.profile_rank && user.profile_type === 'professional' && $auth.loggedIn && user.id !== $auth.user.id">
                        <span class="d-flex align-items-center" :class="{'justify-content-center': position === 'center', 'justify-content-start': position === 'left'}">
                            <span v-if="!hideText && user.profile_rank.indexOf('top') > -1">{{ $t('Estetista Top Senior')}}</span>
                            <span v-else-if="!hideText && user.profile_rank.indexOf('senior') > -1">{{ $t('Estetista Senior')}}</span>
                            <span v-else-if="!hideText &&user.profile_rank.indexOf('junior') > -1 ">{{ $t('Estetista Junior')}}</span>
                        </span>
                    </span>
                    <span v-else-if="user.profile_type === 'not_beautician' && $auth.loggedIn && user.id !== $auth.user.id && user.account">
                        <span v-if="user.account.type === 'company'">{{ $t('Proprietario azienda estetica')}}</span>
                        <span v-if="user.account.type === 'beauty-centre'">{{ $t('Proprietario centro estetico')}}</span>
                        <span v-if="user.account.type === 'school'">{{ $t('Proprietario scuola estetica')}}</span>
                        <span v-if="user.account.type === 'formation'">{{ $t('Formatore estetiste')}}</span>
                    </span>
                    <slot name="time"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {

        props: ['user', 'position', 'hideText', 'hideBadge', 'size'],

        computed: {
            badgeImage: function() {

                if (this.user.profile_rank.indexOf('top') > -1) {

                    return require("~/assets/images/badge-gold@2x.svg");
                }
                else if (this.user.profile_rank.indexOf('senior') > -1) {

                    return require('~/assets/images/badge-silver@2x.svg');
                }
                else if (this.user.profile_rank.indexOf('junior') > -1) {

                    return require('~/assets/images/badge-bronze@2x.svg');
                }

                return '';
            }
        }
    }
</script>
