<template>
    <b-modal ref="accountAddress" modal-class="slide-up" body-class="p-0" hide-header hide-footer no-fade static lazy>
        <div class="card"
             :style="'height: 100vh!important;height: calc(var(--vh, 1vh) * 100); padding-top:' + insets.top + 'px!important; padding-bottom:' + insets.bottom + 'px'"
        >
            <div class="card-header card-header-sm d-flex justify-content-center align-items-center">
                <i class="cf-back absolute p-3" @click.prevent="closeModal" style="left: 0px"></i>
                <span v-if="type === 'school'">{{ $t('Aggiungi una scuola di estetica') }}</span>
                <span v-else>{{ $t('Collegati ad un luogo di lavoro') }}</span>
            </div>
            <div class="card-body p-0" v-if="step === 'address-data'">
                <div class="address-item font-size-xl">
                    <input v-model="myAddress.route" class="w-100" :placeholder="type !== 'school' ? $t('Indirizzo e civico del centro') : 'Indirizzo e civico della scuola'">
                </div>
                <div class="address-item font-size-xl">
                    <div class="w-100 text-left" @click.prevent="showingSearch = 'city'">
                        <span class="text-light-grey" v-if="!myAddress.city">
                            <span v-if="type !== 'school'">{{ $t('Città del centro') }}</span>
                            <span v-else>{{ $t('Città della scuola')}}</span>
                        </span>
                        <span v-else>{{ myAddress.city.name }}</span>
                    </div>
                    <select-picker
                            class="d-lg-none"
                            url="/api/cities"
                            v-if="showingSearch === 'city'"
                            v-model="myAddress.city_id"
                            :cancel-callback="hideSearch"
                            :save-callback="updateAccount"
                            :initial-object="myAddress.city"
                    >
                        <template slot="picker-title">
                            {{ $t('Seleziona città') }}
                        </template>
                        <template slot="options-title">
                            {{ $t('Lista città') }}
                        </template>
                    </select-picker>
                </div>
                <div class="address-item font-size-xl">
                    <input v-model="myAddress.postal_code_name" class="w-100" :placeholder="type !== 'school' ? $t('Codice postale del centro') : 'Codice postale della scuola'">
                </div>
            </div>
            <div class="card-body p-0" v-if="step === 'confirm-address'">
                <div class="p-4 border-bottom" style="text-align: left">
                    <div class="d-flex">
                        <span><input id="original" value="original" type="radio" class="mr-2" v-model="selectedAddress"></span>
                        <div class="d-flex flex-column">
                            <strong class="mb-1">{{ $t('Indirizzo originale:') }}</strong>
                            <span v-if="originalAddress" class="address-string">
                                <span v-if="originalAddress.route">{{ originalAddress.route }}</span><span v-if="originalAddress.route && originalAddress.locality">,</span>
                                <span v-if="originalAddress.locality">{{ originalAddress.locality }}</span><span v-if="originalAddress.locality && originalAddress.postal_code_name">,</span>
                                <span v-if="originalAddress.postal_code_name">{{ originalAddress.postal_code_name }}</span><span v-if="originalAddress.postal_code_name && originalAddress.state">,</span>
                                <span v-if="originalAddress.state">{{ originalAddress.state }}</span><span v-if="originalAddress.state && originalAddress.country">,</span>
                                <span v-if="originalAddress.country">{{ originalAddress.country }}</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="p-4 border-bottom" style="text-align: left" v-if="suggestedAddress">
                    <div class="d-flex">
                        <span><input id="suggested" value="suggested" type="radio" class="mr-2" v-model="selectedAddress"></span>
                        <div class="d-flex flex-column">
                            <strong class="mb-1">{{ $t('Indirizzo suggerito:') }}</strong>
                            <div v-if="suggestedAddress" class="address-string">
                                <span v-if="suggestedAddress.route"
                                      :class="{'error': !checkAddressComponent('route') }"
                                >{{ suggestedAddress.route }}</span><span v-if="suggestedAddress.route && suggestedAddress.locality">,</span>
                                <span v-if="suggestedAddress.locality"
                                      :class="{'error': !checkAddressComponent('locality') }"
                                >{{ suggestedAddress.locality }}</span><span v-if="suggestedAddress.locality && suggestedAddress.postal_code_name">,</span>
                                <span v-if="suggestedAddress.postal_code_name"
                                      :class="{'error': !checkAddressComponent('postal_code_name') }"
                                >{{ suggestedAddress.postal_code_name }}</span><span v-if="suggestedAddress.postal_code_name && suggestedAddress.state">,</span>
                                <span v-if="suggestedAddress.state"
                                      :class="{'error': !checkAddressComponent('state') }"
                                >{{ suggestedAddress.state }}</span><span v-if="suggestedAddress.state && suggestedAddress.country">,</span>
                                <span v-if="suggestedAddress.country"
                                      :class="{'error': !checkAddressComponent('country') }"
                                >{{ suggestedAddress.country }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <button class="btn btn-brand w-100" @click.prevent="confirmData()" :disabled="!(myAddress.route && myAddress.city_id && myAddress.postal_code_name)" v-if="step === 'address-data'">
                    {{ $t('Avanti') }}
                </button>
                <button class="btn btn-brand w-100" @click.prevent="confirmAddress()" v-if="step === 'confirm-address'">
                    {{ $t('Conferma') }}
                </button>
                <button class="btn btn-brand w-100" @click.prevent="createAccount()" v-if="step === 'creating-account'">
                    {{ $t('Conferma e crea centro') }}
                </button>
            </div>
        </div>
    </b-modal>
</template>

<script>
    import { mapActions, mapState, mapMutations } from 'vuex'
    import { mapFields } from 'vuex-map-fields'
    import SelectPicker from "~/components/SelectPicker";

    export default {

        components: {
            SelectPicker
        },

        props: [
            'type',
            'account'
        ],

        data() {
            return {
                step: 'address-data',
                showingSearch: '',
                myAddress: {
                    route: this.account.route,
                    postal_code_name: this.account.postal_code_name,
                    city_id: this.account.city_id,
                    city: this.account.city,
                },
                googleResponse: null,
                selectedAddress: 'original'
            }
        },

        computed: {
            ...mapState({
                'user': 'user',
                'insets': 'insets',
            }),
            originalAddress() {
                return this.googleResponse ? this.googleResponse.original_address : null;
            },
            suggestedAddress() {
                return this.googleResponse ? this.googleResponse.suggested_address : null;
            }
        },

        methods: {
            ...mapActions({
                'refetchUser': 'user/refetch'
            }),
            openModal() {
                this.$refs.accountAddress.show();
            },
            closeModal() {
                switch (this.step) {
                    case 'confirm-address':
                        this.step = 'address-data';
                        break;
                    case 'creating-account':
                        this.step = 'confirm-address';
                        break;
                    default:
                        this.$refs.accountAddress.hide();
                        break;
                }
            },
            updateAccount(city) {
                this.myAddress.city = city
                this.hideSearch()
            },
            hideSearch() {
                this.showingSearch = ''
            },
            checkAddressComponent(param) {
                return this.suggestedAddress[param] && this.originalAddress[param] && (this.suggestedAddress[param].toLowerCase() === this.originalAddress[param].toLowerCase())
            },
            nextStep() {

            },
            confirmData() {

                this.$nuxt.$loading.start()

                this.$axios.get('/api/geolocate-address', {
                    params: {
                        'route': this.myAddress.route,
                        'city_id': this.myAddress.city_id,
                        'postal_code_name': this.myAddress.postal_code_name
                    }
                })
                .then(r => r.data)
                .then((response) => {

                    this.$nuxt.$loading.finish()

                    this.googleResponse = response;

                    if (this.originalAddress.formatted_address.toLowerCase() === this.suggestedAddress.formatted_address.toLowerCase()) {
                        this.confirmAddress()
                    } else {
                        this.step = 'confirm-address'
                    }
                }).catch((error) => {
                    this.$nuxt.$loading.finish()

                    this.$notify({
                        group: 'messages',
                        type: 'error',
                        text: this.$t('Errore, riprova più tardi.')
                    })
                })
            },
            confirmAddress() {

                let address = this.selectedAddress === 'original' ? this.originalAddress : this.suggestedAddress;

                this.$emit('update:address', address.formatted_address);
                this.$emit('update:route', address.route);
                this.$emit('update:postal_code_name ', address.postal_code_name);
                this.$emit('update:city_id', this.myAddress.city_id);
                this.$emit('update:state_name', address.state);
                this.$emit('update:latitude', address.latitude);
                this.$emit('update:longitude', address.longitude);

                this.$refs.accountAddress.hide();
            },
        },

        mounted () {
        },
    }
</script>

<style lang="scss" scoped>
    .error {
        border: 1px solid red;
        color: red;
        padding: 0 2px;
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;
    }
    .address-string {
        line-height: 1.5;
    }
    .address-item {
        border-bottom: 1px solid #DBDBDB;
        padding: 12px;

        input {
            &::placeholder {
                color: #DBDBDB;
            }
        }
    }
</style>