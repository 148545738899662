<template>
    <div class="card text-center">
        <div class="card-header card-header-sm  d-flex justify-content-center align-items-center">
            <i class="cf-back absolute p-3" style="left: 0;" @click.prevent="previousStep()"></i>
            {{ $t('Cerca centro estetico') }}
        </div>
        <div class="card-body">
            <div class="mt-lg-5 mb-2">
                <img alt="step-13" class="w-100 icon" src="~/assets/images/steps/step-13.png">
            </div>
            <div class="title">
                <span v-if="workExperience.workExperience.type === 'owner'">
                    {{ $t('Vuoi invitare le tue dipendenti?')}}
                </span>
                <span v-else>
                    {{ $t('Vuoi invitare le tue colleghe?')}}
                </span>
            </div>
            <div class="description mt-5">
                <div v-if="workExperience.workExperience.type === 'owner'">
                    {{ $t('Invita le tue dipendenti ad iscriversi all\'albo degli estetisti di Confestetica.') }}
                    <div class="input-container mt-3">
                        <input class="form-control form-control-sm has-label pr-4" name="email" :data-vv-as="$t('E-mail')" v-validate="'email'" :placeholder="$t('E-mail')" autocomplete="off" v-model="email" />
                        <a :href="'mailto:' + email + '?body=' + message" :disabled="errors.has('email') || !email.length">
                            <i class="input-icon text-grey cf-message text-grey" :class="{'cf-message-f text-brand': !errors.has('email') && email.length}"></i>
                        </a>
                    </div>
                    <strong class="form-error text-danger" v-if="errors.has('email')">{{ errors.first('email') }}</strong>
                </div>
                <span v-else>
                    {{ $t('Invita le tue colleghe ad iscriversi all\'albo degli estetisti di Confestetica.')}}
                </span>
            </div>
        </div>
        <div class="card-footer">
            <a :href="'https://api.whatsapp.com/send?text=' + message" target="_blank" class="btn btn-whatsapp w-100 d-flex justify-content-center align-items-center">
                <i class="fa fa-whatsapp fa-2x mr-2"></i>
                {{ $t('Invita tramite Whatsapp') }}
            </a>
            <button class="btn btn-brand w-100" @click.prevent="setWorkExperienceStep('complete')" :disabled="workExperience.workExperience.type !== 'owner' && workExperience.workExperience.type !== 'employee'">
                {{ $t('Avanti') }}
            </button>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState, mapMutations } from 'vuex'
    import _ from 'lodash'

    export default {

        props: ['type'],

        data() {
            return {
                email: '',
                message: 'Ciao, entra anche tu a far parte della community di confestetica! Registrati sul sito ' + encodeURIComponent(process.env.BASE_URL + '/?ref=' + this.$auth.user.referral_code) + ' per maggiori informazioni'
            }
        },

        computed: {
            ...mapState({
                'user': 'user',
                'account': 'account',
                'workExperience': 'work-experience'
            })
        },

        methods: {
            ...mapActions({
                'refetchUser': 'user/refetch'
            }),
            ...mapMutations({
                'setWorkExperienceType': 'work-experience/SET_TYPE',
                'setWorkExperienceStep': 'work-experience/SET_STEP'
            }),
            previousStep() {
                if (this.workExperience.workExperience.type === 'owner') {
                    this.setWorkExperienceStep('header-image')
                } else {
                    this.setWorkExperienceStep('owner-invite')
                }
            }
        },

        mounted () {
        },
    }
</script>