<template>
    <b-modal id="account-modal" ref="accountModal" modal-class="slide-up" body-class="p-0" hide-header hide-footer no-fade @show="init()">
        <div class="card border-0"
             :style="'height:100vh!important;height: calc(var(--vh, 1vh) * 100)!important;padding-top:' + insets.top + 'px!important; padding-bottom:' + insets.bottom + 'px'"
        >
            <account-type v-on:update:accountType="val => editAccount.type = val" :account.sync="editAccount" :set-step="setStep" :modal="modal" :type="type" v-if="step === 'account-type'">
                <template slot="card-header">
                    <div class="card-header card-header-sm  d-flex justify-content-center align-items-center">
                        <i class="cf-back absolute p-3" style="left: 0;" @click.prevent="$refs.accountModal.hide()"></i>
                        <span v-if="type === 'beauty-centre'">{{ $t('Aggiungi un centro estetico') }}</span>
                        <span v-else-if="type === 'school'">{{ $t('Aggiungi una scuola di estetica') }}</span>
                        <span v-else-if="type === 'brand'">{{ $t('Aggiungi un brand') }}</span>
                        <span v-else>{{ $t('Collegati ad un luogo di lavoro') }}</span>
                    </div>
                </template>
            </account-type>
            <search-accounts :account.sync="editAccount" v-bind.sync="editAccount" :set-step="setStep" :modal="modal" :type="type" :confirm-callback="confirmCallback" v-if="step === 'search-accounts'">
                <template slot="card-header">
                    <div class="card-header card-header-sm  d-flex justify-content-center align-items-center">
                        <i class="cf-back absolute p-3" style="left: 0;" @click.prevent="$refs.accountModal.hide()" v-if="type === 'school' || type === 'brand' || type === 'beauty-centre'"></i>
                        <i class="cf-back absolute p-3" style="left: 0;" @click.prevent="step = 'account-type'" v-else></i>
                        <span v-if="type === 'beauty-centre'">{{ $t('Cerca il tuo centro estetico') }}</span>
                        <span v-else-if="type === 'school'">{{ $t('Cerca la tua scuola di estetica') }}</span>
                        <span v-else-if="type === 'brand'">{{ $t('Cerca tra i brand') }}</span>
                        <span v-else>{{ $t('Collegati ad un luogo di lavoro') }}</span>
                    </div>
                </template>
            </search-accounts>
            <account-address :account.sync="editAccount" v-bind.sync="editAccount" :set-step="setStep" :owner="owner" :type="type" :confirm-callback="confirmCallback" v-if="step === 'account-address'"></account-address>
            <confirm-account-data :account.sync="editAccount" v-bind.sync="editAccount" :set-step="setStep" :owner="owner" :work-experience="workExperience" :type="type" :confirm-callback="confirmCallback" v-if="step === 'confirm-account-data'"></confirm-account-data>
            <account-image :account.sync="editAccount" v-bind.sync="editAccount" :set-step="setStep" :type="type" v-if="step === 'account-image'" :confirm-callback="confirmCallback">
                <template slot="card-header">
                    <div class="card-header card-header-sm  d-flex justify-content-center align-items-center">
                        <i class="cf-back absolute p-3" style="left: 0;" @click.prevent="step = 'search-accounts'"></i>
                        <span v-if="type === 'school'">{{ $t('Aggiungi una scuola di estetica') }}</span>
                        <span v-else-if="type === 'brand'">{{ $t('Aggiungi un brand') }}</span>
                        <span v-else>{{ $t('Collegati ad un luogo di lavoro') }}</span>
                    </div>
                </template>
            </account-image>
        </div>
    </b-modal>
</template>

<script>
    import { mapActions, mapState, mapMutations } from 'vuex'
    import AccountAddress from "~/components/AccountModal/Steps/AccountAddress";
    import AccountType from "~/components/AccountModal/Steps/AccountType";
    import SearchAccounts from "~/components/AccountModal/Steps/SearchAccounts";
    import ConfirmAccountData from "~/components/AccountModal/Steps/ConfirmAccountData";
    import AccountImage from "~/components/AccountModal/Steps/AccountImage";
    import _ from 'lodash';
    export default {

        head() {

            let src = '';

            if (typeof google !== 'object') {
                src = 'https://maps.googleapis.com/maps/api/js?key=' + process.env.GOOGLE_API_KEY + '&libraries=places';
            }

            return {
                script: [
                    {
                        src: src
                    }
                ]
            }
        },

        components: {
            SearchAccounts,
            AccountAddress,
            AccountType,
            AccountImage,
            ConfirmAccountData
        },


        props: {
            type: {
                type: String,
                default: function () {
                    return 'beauty-centre';
                }
            },
            account: {
                type: Object,
                default: function () {
                    return {
                        name: '',
                        address: '',
                        type: 'beauty-centre',
                        vat_id: '',
                        postal_code_name: '',
                        city_id: '',
                        state_name: '',
                        route: '',
                        latitude: '',
                        longitude: '',
                    }
                }
            },
            modal: {
                type: Boolean,
                default: function () {
                    return false;
                }
            },
            owner: {
                type: Boolean,
                default: function () {
                    return false;
                }
            },
            workExperience: {
                type: Boolean,
                default: function () {
                    return false;
                }
            },
            confirmCallback: {
                type: Function,
                default: function () {
                    return {}
                }
            },
            backCallback: {
                type: Function,
                default: function () {
                    return {}
                }
            }
        },

        data () {
            return {
                editAccount: _.cloneDeep(this.account),
                imgSrc: '',
                blob: '',
                step: 'search-accounts'
            }
        },

        computed: {
            ...mapState({
                user: 'user',
                insets: 'insets',
            })
        },

        methods: {
            ...mapMutations({
                'setAccountType': 'account/SET_ACCOUNT_TYPE'
            }),
            setStep(step) {
                this.step = step;
            },
            showModal() {
              this.$refs.accountModal.show();
            },
            init() {

                this.editAccount = _.cloneDeep(this.account);

                if (this.type === 'school' || this.type === 'brand' || this.type === 'beauty-centre') {
                    this.editAccount.type = this.type;
                    this.setStep('search-accounts')
                } else {
                    this.setStep('account-type')
                }
            },
        },

        created () {
            this.$nuxt.$on('close-account-modal', () => {

                if (this.$refs.accountModal) {
                    this.$refs.accountModal.hide();
                }
            })
        }
    }
</script>
