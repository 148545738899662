import Vue from "vue";
import { getField, updateField } from 'vuex-map-fields';
import _ from 'lodash'

export const state = () => ({
    filters: {
        conversation_id: null
    },
    pagination: {
        page: 0,
        per_page: 50,
    },
    data: [],
    totalCount: 0,
    infiniteScrollEnabled: true,
    processing: false
})

export const getters = {
    getField,
    GET_MESSAGES_BY_CONVERSATION_ID: (state) => (conversationId) => {

        if (!conversationId) return [];

        return _.orderBy(_.filter(state.data, (record) => {
            return record.conversation_id === conversationId
        }), 'created_at', 'asc');
    },
    UNREAD_MESSAGES: (state) => {

        return _.filter(state.data, (record) => {
            return !record.read_at
        });
    }
}

export const mutations = {
    updateField,
    SET_CONVERSATION_ID_FILTER (state, conversationId) {
        state.filters.conversation_id = conversationId
    },
    SET_PROCESSING (state, processing) {
        state.processing = processing
    },
    SET_READ (state, message) {
        state.processing = processing
    },
    MARK_ALL_AS_READ (state, conversationId) {
        _.each(_.filter(state.data, {conversation_id: conversationId}), (message) => {

            let recordIndex = _.findIndex(state.data, { id: message.id });
            let record = _.clone(message);

            record.read_at = this.$moment().format('YYYY-MM-DD HH:mm:ss');

            Vue.set(state.data, recordIndex, record);

        })
    },
    REMOVE_MESSAGE (state, myRecord) {
        let recordIndex = _.findIndex(state.data, function (record) {
            return (myRecord.id === record.id || myRecord.name === record.name)
        });

        if (recordIndex > -1) {
            state.data.splice(recordIndex, 1);
        }
    },
    APPEND (state, record) {

        if (!_.includes(_.map(state.data, 'id'), record.id) || !record.id) {

            state.data.push(record);
        } else {

            let recordIndex = _.findIndex(state.data, { id: record.id });

            if(recordIndex > -1) {
                Vue.set(state.data, recordIndex, record);
            }
        }
    },
    APPEND_ID_FROM_NAME (state, record) {

        let myRecord = _.find(state.data, {name: record.name});

        if(myRecord) {
            Vue.set(myRecord, 'id', record.id)
        }
    },
    UPDATE_PROGRESS_BY_NAME (state, record) {

        let myRecord = _.find(state.data, {name: record.name});

        if(myRecord) {
            Vue.set(myRecord, 'progress', record.progress)
        }
    },

    SET_INFINITE_SCROLL_ENABLED (state, infiniteScrollEnabled) {
        state.infiniteScrollEnabled = infiniteScrollEnabled
    },
    SET_TOTAL_COUNT (state, totalCount) {
        state.totalCount = totalCount
    }
}

export const actions = {

    fetch ({ commit, state}, data) {

        commit('SET_PROCESSING', true);

        if (data && data.conversation_id) {
            commit('SET_CONVERSATION_ID_FILTER', data.conversation_id);
        }

        return this.$axios
            .get('/api/messages', {
                params: {
                    page: data && data.page ? data.page : state.pagination.page,
                    per_page: data && data.per_page ? data.per_page : state.pagination.per_page,
                    ...state.filters
                }
            })
            .then(r => r.data)
            .then(response => {

                _.each(response.data, (record) => {
                    commit('APPEND', record);
                });

                // commit('MARK_ALL_AS_READ', data.conversation_id);

                commit('SET_TOTAL_COUNT', response.total);
                commit('SET_INFINITE_SCROLL_ENABLED', response.data.length >= ( data && data.per_page ? data.per_page : state.pagination.per_page));
                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    }
}