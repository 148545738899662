<template>
    <div class="select-picker card card-full" :style="'padding-top:' + (insets ? insets.top : 0) + 'px'">
        <div class="backdrop d-none d-lg-block"></div>
        <div class="card-header card-header-sm background-white">
            <slot name="cancel-button">
                <a href="javascript:void(0)" @click.prevent="cancel()" class="text-left text-black flex-grow-1 flex-basis-0 font-size-base">
                    <slot name="cancel-button-name">
                        <u>{{ $t('Annulla') }}</u>
                    </slot>
                </a>
            </slot>
            <div class="text-center font-size-lg flex-basis-0 flex-grow-3">
                <slot name="picker-title"></slot>
            </div>

            <slot name="save-buttom">
                <a href="javascript:void(0)" @click.prevent="save()" class="text-right text-black flex-grow-1 flex-basis-0 font-size-base">
                    <slot name="save-button-name">
                        <u>{{ $t('Fine') }}</u>
                    </slot>
                </a>
            </slot>
        </div>
        <div class="card-body background-white picker p-0 scrollable-content overflow-auto">
            <div class="position-sticky background-white" style="top: 0">
                <div class="text-primary text-left">
                    <div class="selected-value">
                        <span v-if="selectedOptions.length">
                            <span v-for="selectedOption in selectedOptions">
                                <u @click.prevent="toggleValue(selectedOption)">{{ selectedOption.name }}</u><span v-if="lastSelectedOption.id !== selectedOption.id">, </span>
                            </span>
                        </span>
                        <slot name="placeholder" v-else>
                            <span class="placeholder text-grey">
                                {{ $t('Seleziona un\'opzione dalla lista')}}
                            </span>
                        </slot>
                    </div>
                    <div class="input-search background-gray p-2" v-if="!hideSearch">
                        <form action="." @submit.prevent="">
                            <i class="cf-search text-grey"></i>
                            <input v-focus="focused" type="text" class="w-100" :placeholder="$t('Cerca...')" v-model="text" autocomplete="off">
                        </form>
                    </div>
                </div>
                <div class="title text-left">
                    <slot name="options-title">
                        {{ $t('Specializzazione primaria')}}
                    </slot>
                </div>
            </div>
            <div id="list" class="list" v-if="!loading">
                <a href="javascript:void(0)" @click.prevent="toggleValue(option)" v-for="option in filteredOptions">
                    <div :style="'padding-left:' + (option.level * 15) + 'px!important'">
                        <slot name="optionName">
                            {{ option.name }}
                        </slot>
                    </div>
                    <i class="fa fa-check-circle text-primary ml-3" v-if="selected(option.id)"></i>
                    <i class="fa fa-circle-o text-grey ml-3" v-else></i>
                </a>
            </div>
            <div v-else class="text-center p-3">
                <i class="fa fa-spin">
                    <img alt="loading" src="~/assets/images/icons/loading.svg" width="36px">
                </i>
            </div>
        </div>
        <!--<div class="card-footer">-->
            <!--&nbsp;-->
        <!--</div>-->
    </div>
</template>

<script>

    import { mapState } from 'vuex'
    import { focus } from 'vue-focus';
    import _ from 'lodash'

    export default {

        props: {
            'value': {
                default: null
            },
            'label': {
                type: String,
                default: function () {
                    return 'name'
                }
            },
            'saveCallback': {
                type: Function,
                default: function () {
                    return {}
                }
            },
            'cancelCallback': {
                type: Function,
                default: function () {
                    return {}
                }
            },
            'initialObject': {
                type: Object,
                default: function () {
                    return null
                }
            },
            'url': {
                type: String,
                default: function () {
                    return ''
                }
            },
            'options': {
                type: Array,
                default: function () {
                    return []
                }
            },
            'multiple': {
                type: Boolean,
                default: function () {
                    return false
                }
            },
            'maxValuesNumber': {
                type: Number,
                default: function () {
                    return 0
                }
            },
            'hideSearch': {
                type: Boolean,
                default: function () {
                    return false
                }
            },
            'errorMessage': {
                type: String,
                default: function () {
                  return ''
                }
            },
            'showErrorMessage': {
                type: Boolean,
                default: function () {
                  return false
                }
            }
        },

        data() {
            return {
                focused: true,
                text: '',
                loading: false,
                topOffset: 0,
                pickerOptions: this.options,
                // selectedOptions:
                //     this.value
                //         ? this.value
                //         : (this.multiple ? [] : null)
                selectedOptions: []
            }
        },

        directives: {
            focus: focus,
        },

        watch: {
            // 'selectedOptions': function () {
            //     this.$emit('input', this.selectedOptions);
            // },
            'text': _.debounce(function () {
                if (this.url && this.text.length >= 2) {
                    this.fetchOptions()
                }
            }, 500),
            'options': function () {
                this.mapOptions();
            }
        },

        computed: {

            ...mapState([
                'insets'
            ]),

            filteredOptions() {
                if (this.text) {
                    return _.filter(this.pickerOptions, (option) => {
                        return option.name.toLowerCase().indexOf(this.text.toLowerCase()) > -1
                    })
                } else {
                    return this.pickerOptions
                }
            },
            lastSelectedOption() {
                return _.last(this.selectedOptions);
            }
        },

        methods: {
            fetchOptions() {
                this.loading = true;

                this.$axios.get(this.url + '?name=' + this.text)
                    .then((response) => {
                        this.options = response.data;
                        this.loading = false;
                    }).then(() => {
                        this.loading = false;
                    })
            },
            save() {
                if(this.selectedOptions.length === 0 && this.showErrorMessage) {
                  this.$notify({
                    group: 'messages',
                    type: 'error',
                    title: this.errorMessage
                  });
                  return
                }
                let selectedOption = null;
                if (!this.multiple) {
                    selectedOption = _.first(this.selectedOptions);
                    this.$emit('input', selectedOption ? selectedOption.id : null);
                } else {
                    this.$emit('input', _.map(this.selectedOptions, 'id'));
                }
                this.saveCallback(selectedOption);
            },
            cancel() {
                this.cancelCallback('cancel');
            },
            getTopOffset() {

                let el = document.getElementById('list');
                this.topOffset =  el.getBoundingClientRect().top;
            },
            mapOptionChildren(option, options, level) {

                if (!option.children) return;

                _.each(option.children, (child) => {
                    options.push({
                        id: child.id,
                        name: child[this.label],
                        level: level
                    })
                    this.mapOptionChildren(child, options, level + 1)
                })
            },
            mapOptions() {

                let options = [];

                let parentOptions = _.filter(this.options, (option) => {
                    return !option.parent_id
                })

                _.each(parentOptions, (option) => {

                    let level = 0;

                    if (typeof option === 'string') {

                        options.push({
                            id: option,
                            name: option,
                            level: level
                        })
                    } else {
                        options.push({
                            id: option.id,
                            name: option[this.label],
                            level: level
                        });

                        this.mapOptionChildren(option, options, ++level)
                    }
                })

                this.pickerOptions = options;
            },
            selected(value) {
                return _.indexOf(_.map(this.selectedOptions, 'id'), value) > -1;
            },
            toggleValue(option) {

                if (this.selected(option.id)) {
                    let valueIndex = _.indexOf(_.map(this.selectedOptions, 'id'), option.id);
                    this.selectedOptions.splice(valueIndex, 1);
                } else {
                    if (!this.multiple) {
                        this.selectedOptions = [option];
                        this.save();
                    } else {

                        if (this.selectedOptions.length < this.maxValuesNumber || !this.maxValuesNumber) {

                            this.selectedOptions.push(option)
                        } else {

                            this.$notify({
                                group: 'messages',
                                type: 'error',
                                text: this.$t('Puoi selezionare al massimo ') + this.maxValuesNumber + (this.maxValuesNumber > 1 ? this.$t(' elementi. ') : this.$t(' elemento'))
                            });
                        }
                    }
                }
            },
        },

        destroyed() {
            document.body.classList.remove('select-picker-open');
        },

        mounted () {

            document.body.classList.add('select-picker-open');

            this.mapOptions();
            this.getTopOffset();

            if (this.initialObject && this.url) {
                this.selectedOptions.push(this.initialObject)
            } else if (this.value) {
                if (!this.multiple) {
                    let option = _.find(this.pickerOptions, { 'id': this.value });
                    if (option) {
                        this.selectedOptions.push(option)
                    }
                } else {
                    _.each(this.value, (value) => {
                        let option = _.find(this.pickerOptions, { 'id': value });
                        if (option) {
                            this.selectedOptions.push(option)
                        }
                    })
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .card-body {
        /*max-height: calc((var(--vh) * 50) - 50px)!important;*/
        padding: 0!important;
    }
    .picker {
        .selected-value {
            padding: 8px;
        }

        .input-search {
            display: flex;
            justify-content: space-around;
            padding: 8px 12px!important;
            form {
                background-color: white;
                position: relative;
                display: flex;
                width: 100%;
                height: 34px;
                align-items: center;
                border-radius: 8px;
                border: none;

                i {
                    margin: 0 12px;
                }
                input {
                    width: 100%;

                    &::-webkit-input-placeholder,
                    &::-moz-placeholder,
                    &:-ms-input-placeholder,
                    &:-moz-placeholder {
                        opacity: 0.3;
                    }
                }
            }
        }

        .title {
            font-size: 15px!important;
            height: 40px;
            background-color: #ddd;
            padding: 8px;
        }
        .list {
            overflow: auto;

            > a {
                padding: 8px;
                border-bottom: 1px solid #eee;
                display: flex;
                justify-content: space-between;
                align-items: center;
                text-decoration: none;
                color: inherit;
                max-width: inherit;

                i {
                    font-size: 20px;
                }
            }
        }
    }
</style>
