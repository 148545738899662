<template>
    <div>
        <slot name="card-header"></slot>
        <div class="card-body p-0" :class="{'loading': !post.id, 'scrollable-content': !level }">
            <div class="post-content-container pb-3" :style="layout === 'horizontal' && post.has_images ? 'padding-bottom: 0!important;' : ''"
                 :class="[layout, {'post-detail': postDetail, 'article': post.is_article, 'communication': post.is_communication && postDetail}]">
                <div class="d-flex post-user" :class="{'pt-3': !post.is_article && !postDetail }">
                    <div class="d-flex flex-grow-1">
                        <div class="pt-2" @click.prevent="showUser" v-if="post.layout === 'group-post'">
                            <user-image class="relative mr-3" :user="post.user" size="40" v-if="post.user">
                                <template slot="profile-rank">
                                    <!--<profile-rank class="absolute bottom right" :hide-text="true" :user="post.user" size="small"></profile-rank>-->
                                </template>
                            </user-image>
                            <div class="d-flex relative mr-3" v-else>
                                <img class="profile-img" alt="Confestetica" style="min-width: 40px;max-width: 40px; height: 40px" :src="require('~/assets/images/ico-c.png')"/>
                            </div>
                        </div>
                        <!--<div v-else>-->

                        <!--</div>-->
                        <!--<div v-else-if="post.layout === 'communication' && !postDetail" class="mr-2">-->
                            <!--<img alt="ico-c" src="~/assets/images/ico-c.png" width="30px">-->
                        <!--</div>-->
                        <nuxt-link :to="post.permalink ? post.permalink : ''">
                            <div class="w-100 user-data pt-2" v-if="post.layout === 'group-post'" @click.prevent="showUser">
                                <div class="user-name font-weight-medium">
                                    {{ post.user ? post.user.name : (post.notified ? 'Confestetica' : $t('Utente Cancellato')) }}
                                </div>
                                <div class="text-dark-grey d-flex align-items-center">
                                    <profile-rank :user="post.user" class="font-weight-medium font-size-sm"></profile-rank>&nbsp;•&nbsp;<span class="font-size-sm font-weight-light no-wrap">{{ post.created_at | fromNow }}</span>
                                </div>
                            </div>
                            <div class="w-100 user-data" v-else-if="post.layout === 'article' && !postDetail" @click.prevent="openPost">
                                <!--<div class="user-name font-weight-bold">-->
                                    <!--{{ $t('Punto Estetico') }}-->
                                <!--</div>-->
                                <!--<div class="text-dark-grey d-flex align-items-center">-->
                                    <!--<span class="font-size-sm font-weight-light no-wrap">-->
                                        <!--{{ $t('Articolo del') }} {{ post.published_at | format('LL') }}-->
                                    <!--</span>-->
                                <!--</div>-->
                                <!--<div class="post-tag font-weight-bold font-size-sm text-uppercase" v-if="post.parent && post.parent.layout === 'category'">-->
                                    <!--<span>{{ post.parent.title }}</span>-->
                                <!--</div>-->
                            </div>
                            <div class="w-100 user-data" v-else-if="post.is_communication && !postDetail" @click.prevent="openPost">
                                <div class="font-weight-bold font-size-sm text-uppercase text-dark-grey" v-if="post.parent && post.parent.layout === 'category'">
                                    <span>{{ post.parent.title }}</span>
                                </div>
                                <div class="text-black font-size-xl font-weight-bold my-2">
                                    {{ post.title }}
                                </div>
                                <div class="font-size-sm no-wrap text-black" v-if="!postDetail">
                                    <span class="post-tag font-weight-medium mb-2">N. {{ post.number}}</span> | {{ $t('pubblicata il:') }} <span class="font-weight-medium">{{ post.published_at | format('LL') }}</span>
                                </div>
                                <!--<div class="communication-tag font-weight-bold font-size-sm text-uppercase text-grey" v-if="post.parent">-->
                                    <!--<span>{{ post.parent.title }}</span>-->
                                <!--</div>-->
                                <!--<div class="text-dark-grey font-size-sm font-weight-medium post-tag">-->
                                    <!--<span class="text-uppercase">-->
                                        <!--{{ $t('Comunicazione') }}-->
                                        <!--N. {{ post.number }}-->
                                    <!--</span>-->
                                    <!--<div class="text-dark-grey font-size-sm">{{ $t('del') }} {{ post.published_at | format('LL') }}</div>-->
                                <!--</div>-->
                            </div>
                            <div class="w-100 user-data" v-else-if="post.layout === 'page'" @click.prevent="openPost">
                                <h1 class="user-name font-weight-bold">
                                    {{ post.title }}
                                </h1>
                            </div>
                        </nuxt-link>
                    </div>
                    <b-dropdown right no-caret variant="link" class="ml-auto align-self-start d-none d-lg-block" v-if="$auth.loggedIn && post.id && (post.type === 'group-post' || !post.has_images) && !modal && ($auth.user.is_admin || post.user_id === $auth.user.id || group.admin)">
                        <template slot="button-content">
                            <i class="cf-more pt-3"></i>
                        </template>
                        <b-dropdown-item @click.prevent="openEditPostModal" v-if="$auth.user.is_admin || post.user_id === $auth.user.id || group.admin"> {{ $t('Modifica post') }}</b-dropdown-item>
                        <b-dropdown-item @click.prevent="destroyPost(post)" v-if="$auth.user.is_admin || post.user_id === $auth.user.id || group.admin">{{ $t('Elimina post') }}</b-dropdown-item>
                    </b-dropdown>
                    <div class="dropdown ml-auto align-self-start d-block d-lg-none" :class="{'pt-3': post.is_article }" v-if="$auth.loggedIn && (post.type === 'group-post' || !post.has_images) && !modal && ($auth.user.is_admin || post.user_id === $auth.user.id || group.admin)">
                        <dropup-menu ref="dropupMenu" :bottom="50" :hide-bottom-bar="true" :show-overlay="true">
                            <template slot="menu-icon">
                                <i class="cf-more btn-link"></i>
                            </template>
                            <template slot="menu-options">
                                <ul class="menu-options">
                                    <li v-if="$auth.user.is_admin || post.user_id === $auth.user.id || group.admin" @click.prevent="openEditPostModal">
                                        <i class="fa cf-pencil mr-2"></i> {{ $t('Modifica post')}}
                                    </li>
                                    <!--<li>-->
                                        <!--<i class="fa fa-bookmark-o mr-2"></i> {{ $t('Salva post')}}-->
                                    <!--</li>-->
                                    <!--<li>-->
                                        <!--<i class="fa fa-flag-o mr-2"></i> {{ $t('Segnala post')}}-->
                                    <!--</li>-->
                                    <li class="text-danger" @click.prevent="destroyPost(post)" v-if="$auth.user.is_admin || post.user_id === $auth.user.id || group.admin">
                                        <i class="fa fa-trash mr-2"></i> {{ $t('Elimina post')}}
                                    </li>
                                </ul>
                            </template>
                        </dropup-menu>
                    </div>
                </div>
                <div class="w-100 user-data d-lg-none" v-if="post.is_communication" @click.prevent="openPost">
                    <div class="text-dark-grey font-size-sm font-weight-medium text-center" v-if="postDetail">
                        <div class="communication-tag font-weight-bold font-size-sm text-uppercase" v-if="post.parent && post.parent.layout === 'category'">
                            <span>{{ post.parent.title }}</span>
                        </div>
                        <!--<span class="text-uppercase">-->
                        <!--{{ $t('Comunicazione') }}<br>-->
                        <!--N. {{ post.number }}-->
                        <!--</span>-->
                        <div class="text-dark-grey font-size-sm" v-if="!postDetail">
                            {{ $t('del') }} {{ post.published_at | format('LL') }}
                        </div>
                    </div>
                    <h1 class="text-black font-size-extra font-weight-bold my-3 text-center" v-if="postDetail">
                        {{ post.title }}
                    </h1>
                    <div class="text-center font-size-sm no-wrap" v-if="postDetail">
                        <div class="post-tag font-weight-medium mb-2">N. {{ post.number}}</div>
                        {{ $t('pubblicata il:') }} <span class="font-weight-medium">{{ post.published_at | format('LL') }}</span>
                    </div>
                </div>
                <span class="institutional-divider d-lg-none" v-if="postDetail && post.is_communication"><hr><hr></span>
                <div class="post-subtitle font-size-lg mt-3 d-lg-none" :class="{'text-center': postDetail && mobileMode}" v-if="postDetail && post.subtitle">
                  <i>{{ post.subtitle }}</i>
                </div>
                <!--Actual post text-->
                <div v-if="post.text && post.layout === 'group-post'" class="mt-2">
                    <div class="pre-wrap mb-3 post-text" :class="{'text-small': post.text.length > 80}">
                        <template v-for="piece in textPieces">
                            <span v-if="piece.type === 'plain'" @click.prevent="openPost">{{ piece.value }}</span><span v-if="piece.type === 'html'" v-html="piece.value"></span>
                        </template>
                        <a href="#" v-if="post.text.length > 150 && !postDetail && truncation" @click.prevent="showText">{{ $t('Mostra tutto') }}</a>
                    </div>

                    <url-meta-tags :text="post.text"></url-meta-tags>
                </div>
                <!--End actual post text-->

                <div class="post-tag mt-3" v-if="post.tag && post.layout !== 'group-post'">
                    <span>{{ post.tag }}</span>
                </div>

                <!--<div v-if="post.abstract && post.layout === 'communication' && !postDetail" class="mt-3" @click.prevent="openPost">-->
                    <!--{{ post.abstract }}-->
                <!--</div>-->
                <div class="d-flex" :class="{'flex-column': layout === 'vertical'}">
                    <div v-if="postImagesGroups.length" class="mb-2">
                        <div v-for="(postImagesGroup, groupIndex) in postImagesGroups" class="gallery-row d-flex">
                            <div v-for="image in postImagesGroup"
                                 class="gallery-image flex-grow-1 position-relative"
                                 :style="'padding-top: ' + (postImagesGroup.length === 1 ? 50 : (100 / postImagesGroup.length)) + '%; background-size: cover; background-position: center; background-repeat: no-repeat; background-image: url(' + (image.image_res ? image.image_res.medium : image.path) + ')'"
                                 @click.prevent="showPostImagesModal()"
                            >
                                <div class="more-images-overlay d-none justify-content-center align-items-center" v-if="post.images.length > 5"> +{{ post.images.length - 5 }}</div>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="post.thumbnail_url && !postDetail" class="gallery-row" @click.prevent="openPost" :data-results-label="post.parent ? post.parent.title : ''">
                        <img :src="post.thumbnail_url" width="100%">

                        <div class="absolute" style="right: 0px; top: 0px">

                            <b-dropdown right no-caret variant="link" class="ml-auto align-self-start d-none d-lg-block" v-if="$auth.loggedIn && post.id && !modal && ($auth.user.is_admin || post.user_id === $auth.user.id || group.admin)">
                                <template slot="button-content">
                                    <i class="cf-more pt-3 pr-3"></i>
                                </template>
                                <b-dropdown-item @click.prevent.stop="openEditPostModal" v-if="$auth.user.is_admin || post.user_id === $auth.user.id || group.admin"> {{ $t('Modifica post') }}</b-dropdown-item>
                                <b-dropdown-item @click.prevent.stop="destroyPost(post)" v-if="$auth.user.is_admin || post.user_id === $auth.user.id || group.admin">{{ $t('Elimina post') }}</b-dropdown-item>
                            </b-dropdown>
                            <div class="dropdown ml-auto align-self-start d-block d-lg-none" v-if="$auth.loggedIn && post.id && !modal && ($auth.user.is_admin || post.user_id === $auth.user.id || group.admin)">
                                <dropup-menu ref="dropupMenu" :bottom="50" :hide-bottom-bar="true" :show-overlay="true">
                                    <template slot="menu-icon">
                                        <i class="cf-more btn-link p-3 text-white" style="background-image: radial-gradient(ellipse at top right, #0003 50%, transparent 85%)"></i>
                                    </template>
                                    <template slot="menu-options">
                                        <ul class="menu-options">
                                            <li v-if="$auth.user.is_admin || post.user_id === $auth.user.id || group.admin" @click.prevent.stop="openEditPostModal">
                                                <i class="fa cf-pencil mr-2"></i> {{ $t('Modifica post')}}
                                            </li>
                                            <li>
                                                <i class="fa fa-bookmark-o mr-2"></i> {{ $t('Salva post')}}
                                            </li>
                                            <li>
                                                <i class="fa fa-flag-o mr-2"></i> {{ $t('Segnala post')}}
                                            </li>
                                            <li class="text-danger" @click.prevent.stop="destroyPost(post)" v-if="$auth.user.is_admin || post.user_id === $auth.user.id || group.admin">
                                                <i class="fa fa-trash mr-2"></i> {{ $t('Elimina post')}}
                                            </li>
                                        </ul>
                                    </template>
                                </dropup-menu>
                            </div>
                        </div>
                    </div>
                    <div class="post-info pt-2" v-if="post.is_article">
                        <div>
                            <div class="font-weight-bold font-size-sm text-uppercase mb-1"
                                 :class="postDetail ? 'communication-tag text-center' : 'post-tag'"
                                 v-if="((postDetail && mobileMode) || !post.has_images && !postDetail) && post.parent && post.parent.layout === 'category'"
                            >
                                <span>{{ post.parent.title }}</span>
                            </div>
                            <h1 class="font-weight-bold m-0 post-title" :class="{'font-size-xl': !postDetail, 'text-center': postDetail && mobileMode }" @click.prevent="openPost">
                                {{ post.title }}
                            </h1>
                            <div class="post-subtitle font-size-lg mt-3" :class="{'text-center': postDetail && mobileMode}" v-if="postDetail">
                                <i>{{ post.subtitle }}</i>
                            </div>
                            <div class="mt-3" v-if="post.thumbnail_url && postDetail">
                                <img :src="post.thumbnail_url" width="100%" @click.prevent="openLink(post.thumbnail_link)">
                            </div>
                            <div class="d-flex justify-content-between align-items-center py-2 post-author" v-if="!postDetail" @click="showUser">
                                <span><span v-if="post.user" class="text-dark-grey mr-2">{{ $t('di')}}</span><i class="font-weight-medium">{{ post.user.name }}</i></span>
                                <div class="font-weight-bold font-size-sm"> {{ post.published_at | format('LL') }}</div>
                            </div>
                            <div class="post-abstract">
                                {{ post.abstract }}
                            </div>
                        </div>
                        <div class="d-flex post-reactions mt-3 pb-1" v-if="layout === 'horizontal' && ((post.likes_count || getFakeLikesCount(post)) || post.comments_count)">
                            <div class="mr-auto d-flex align-items-center" v-if="post.likes_count || getFakeLikesCount(post)">
                                <img alt="like-circle" src="~/assets/images/icons/like-circle.svg" class="mr-1" width="18px" height="18px">
                                {{ (post.likes_count ? post.likes_count : 0) + getFakeLikesCount(post) }}
                                <div class="ml-2 d-flex align-items-center" v-if="$auth.loggedIn && $auth.user.is_admin">
                                    <img alt="like-circle-blue" src="~/assets/images/icons/like-circle-blue.svg" class="mr-1" width="18px" height="18px">
                                    {{ post.likes_count ? post.likes_count : 0 }}
                                </div>
                            </div>
                            <a class="ml-auto cursor-pointer text-grey" v-if="post.root_comments_count" @click.prevent="toggleComments">{{ $t('Commenti')}}: {{ post.root_comments_count }}</a>
                        </div>
                    </div>
                </div>

                <div class="d-flex align-items-center py-2 mt-3" v-if="post.is_article && postDetail" @click="showUser">
                    <user-image class="relative mr-2" :user="post.user" size="30">
                        <template slot="profile-rank">
                            <!--<profile-rank class="absolute bottom right" :hide-text="true" :user="post.user" size="small"></profile-rank>-->
                        </template>
                    </user-image>
                    <span v-if="post.user" class="text-dark-grey mr-2">{{ $t('di ')}}</span><i class="font-weight-medium">{{ post.user.name }}</i>
                </div>

                <div v-if="(post.layout === 'article' || post.layout === 'communication') && postDetail" class="mt-3 font-size-xs d-flex justify-content-between">
                    <div class="font-weight-medium" v-if="post.layout === 'article'"> {{ post.published_at | format('LL') }}</div>
                    <div class="font-weight-light d-flex align-items-center ml-auto"> <i class="la la-clock-o fa-lg mr-1"></i> {{ post.plain_text | toTime }}</div>
                </div>

                <hr class="d-lg-none" v-if="postDetail && post.is_communication">
                <!--Actual post text-->
                <div class="mt-3 post-content article-post" v-if="post.text && postDetail && (post.is_communication || post.is_article)">
                    <div class="mb-3 font-size-lg" v-html="post.text"></div>
                </div>
                <!--End actual post text-->

                <div class="mt-3 d-flex align-items-center cursor-pointer background-gray p-3" v-if="post.attachment_url && postDetail && post.is_communication" @click.prevent="openLink(post.attachment_url)">
                    <div class="">
                      <img src="~assets/images/icons/pdf.svg" width="50">
                    </div>
                    <span class="m-3 font-size-lg text-dark-grey">{{ $t('Circolare n.') }} {{ post.number }} del {{ post.published_at | format('DD/MM/YYYY') }}.</span>
                </div>
                <div class="d-flex post-reactions mt-3 pb-1" v-if="layout === 'vertical' && ((post.likes_count || getFakeLikesCount(post)) || post.comments_count)">
                    <div class="mr-auto d-flex align-items-center" v-if="post.likes_count || getFakeLikesCount(post)">
                        <img alt="like-circle" src="~/assets/images/icons/like-circle.svg" class="mr-1" width="18px" height="18px">
                        {{ (post.likes_count ? post.likes_count : 0) + getFakeLikesCount(post) }}
                        <div class="ml-2 d-flex align-items-center" v-if="$auth.loggedIn && $auth.user.is_admin">
                            <img alt="like-circle-blue" src="~/assets/images/icons/like-circle-blue.svg" class="mr-1" width="18px" height="18px">
                            {{ post.likes_count ? post.likes_count : 0 }}
                        </div>
                    </div>
                    <a class="ml-auto cursor-pointer text-grey" v-if="post.root_comments_count" @click.prevent="toggleComments">{{ $t('Commenti')}}: {{ post.root_comments_count }}</a>
                </div>
            </div>

            <div class="d-flex post-actions align-items-center justify-content-between" v-if="post.id && (!post.is_article || postDetail) && (!post.is_communication || postDetail)">
                <label class="col d-flex justify-content-center align-items-center text-dark-grey" :class="{ liked: post.liked }" v-login-required="likePost">
                    <i class="cf-like mr-2 text-dark-grey" :class="{ 'cf-like-f liked': post.liked }"></i> {{ $t('Mi piace') }}
                </label>
                <label :id="'newCommentLabelPostId' + post.id" :for="'newCommentPostId'" class="col d-flex justify-content-center align-items-center text-dark-grey" v-login-required="focusNewComment">
                    <i class="cf-comment mr-2 text-dark-grey"></i> {{ $t('Commenta') }}
                </label>
                <label class="col d-flex justify-content-center align-items-centert text-dark-grey" v-if="post.layout === 'group-post'">
                    <dropup-menu ref="followDropup" :hide-bottom-bar="true" :show-overlay="true">
                        <template slot="menu-icon">
                            <img alt="follow-f" class="mr-2" src="~/assets/images/icons/follow-f.svg" width="20px" v-if="post.followed">
                            <img alt="follow" class="mr-2" src="~/assets/images/icons/follow.svg" width="20px" v-else>
                            <span v-if="post.followed" class="liked text-dark-grey"> {{ $t('Segui già') }}</span><span v-else> {{ $t('Segui') }}</span>
                        </template>
                        <template slot="menu-options">
                            <ul class="menu-options">
                                <li @click.prevent="followPost(post.id)" v-if="post.followed === false"> {{ $t('Segui questo post')}}</li>
                                <li @click.prevent="unfollowPost(post.id)" v-if="post.followed === true"> {{ $t('Smetti di seguire il post')}}</li>
                                <li @click.prevent="followUser(post.user_id)" v-if="getUserById(post.user_id) && getUserById(post.user_id).followed === false"> {{ $t('Segui questo utente')}}</li>
                                <li @click.prevent="unfollowUser(post.user_id)" v-if="getUserById(post.user_id) && getUserById(post.user_id).followed === true"> {{ $t('Smetti di seguire l\'utente')}}</li>
                            </ul>
                        </template>
                    </dropup-menu>
                </label>
                <label class="col d-flex justify-content-center align-items-centert text-dark-grey" v-if="post.is_article || post.is_communication">
                    <dropup-menu ref="followDropup" :hide-bottom-bar="true" :show-overlay="true">
                        <template slot="menu-icon">
                            <img alt="share" class="mr-2" src="~/assets/images/icons/share.svg" width="20px">
                            {{ $t('Condividi') }}
                        </template>
                        <template slot="menu-options">
                            <social-sharing :url="postUrl"
                                            :title="post.title ? post.title : ''"
                                            :description="post.abstract ? post.abstract :''"
                                            :hashtags="post.keywords ? post.keywords : ''"
                                            :networks="shareNetworks"
                                            network-tag="li"
                                            inline-template
                            >
                                <ul class="menu-options">
                                    <!--<li>Condividi su FB</li>-->
                                    <!--<li>Condividi su Twitter</li>-->
                                    <!--<li>Condividi su Whatsapp</li>-->
                                    <!--<li>Condividi su Telegram</li>-->
                                    <li v-clipboard:copy="postUrl" v-clipboard:success="showAlert">
                                        {{ $t('Copia link') }}
                                    </li>
                                    <network network="email" class="show-icon">
                                        <i class="fa fa-envelope"></i> Email
                                    </network>
                                    <network network="facebook" class="show-icon">
                                        <i class="fa fa-facebook"></i> Facebook
                                    </network>
                                    <network network="linkedin" class="show-icon">
                                        <i class="fa fa-linkedin"></i> LinkedIn
                                    </network>
                                    <network network="twitter" class="show-icon">
                                        <i class="fa fa-twitter"></i> Twitter
                                    </network>
                                    <network network="whatsapp" class="show-icon">
                                        <i class="fa fa-whatsapp"></i> Whatsapp
                                    </network>
                                </ul>
                            </social-sharing>
                        </template>
                    </dropup-menu>
                </label>
            </div>
        </div>
        <div class="p-3 d-flex flex-column comments" v-if="showingComments && !hideComments">
            <comments
                :limit="postDetail ? 0 : 1"
                :resource="post"
                resource-type="posts"
                :comments="getCommentsByPostId(post.id)"
                :focus-comment-id="comment.comment_id"
                :modal="modal"
                :post-detail="modal || post.permalink === $route.path"
            >
            </comments>
        </div>
        <!--<div v-if="comment.user" class="card-footer d-lg-none d-flex justify-content-between text-grey font-size-sm align-items-center p-2">-->
            <!--<span>{{ $t('Risposta a ') }} {{ comment.user.name }}</span>-->
            <!--<i class="cf-delete" @click.prevent="clearAnswerComment"></i>-->
        <!--</div>-->
        <!--<div class="card-footer comments d-lg-none" v-if="modal">-->
            <!--<div class="d-flex">-->
                <!--<div class="align-self-end comment-actions d-block d-lg-none">-->
                    <!--<i class="fa cf-camera text-grey mr-2" @click.prevent="saveComment"></i>-->
                <!--</div>-->
                <!--<div class="d-flex w-100 relative">-->
                    <!--<textarea class="d-flex comment new-comment w-100"-->
                              <!--v-focus="focused"-->
                              <!--:id="'newCommentPostId'"-->
                              <!--:placeholder="$t('Scrivi un commento...')"-->
                              <!--@keypress.enter.prevent="saveComment"-->
                              <!--v-model="comment.text"-->
                    <!--&gt;-->
                    <!--</textarea>-->
                    <!--<div class="absolute align-self-end comment-actions d-block d-lg-none">-->
                        <!--<i class="fa fa-smile-o text-grey" @click.prevent="saveComment"></i>-->
                    <!--</div>-->
                <!--</div>-->
                <!--<div class="d-block comment-actions d-lg-none align-self-end">-->
                    <!--<i class="fa cf-message-f text-brand ml-2" v-if="comment.text.length" @click.prevent="saveComment"></i>-->
                <!--</div>-->
            <!--</div>-->
        <!--</div>-->

        <post-images-modal v-if="post.images.length" :post="post" ref="postImagesModal"></post-images-modal>
        <post-modal :post="post" ref="postModal" v-if="!postDetail"></post-modal>
        <user-modal :user-id="post.user.id" ref="userModal" v-if="!postDetail && mobileMode && post.user"></user-modal>
        <images-gallery ref="postImagesGallery" v-if="post.images.length" :images="post.images" url="/api/post-images">
            <template slot="dropdown-menu">
                <b-dropdown right no-caret variant="link" class="d-none d-lg-block">
                    <template slot="button-content">
                        <i class="cf-more"></i>
                    </template>
                    <b-dropdown-item @click.prevent>{{ $t('Salva immagine') }}</b-dropdown-item>
                    <b-dropdown-item @click.prevent>{{ $t('Segnala immagine') }}</b-dropdown-item>
                    <b-dropdown-item class="text-danger" @click.prevent="deleteImage" v-if="$auth.loggedIn && ($auth.user.is_admin || post.user_id === $auth.user.id || group.admin)">{{ $t('Elimina immagine') }}</b-dropdown-item>
                </b-dropdown>
                <div class="dropdown d-block d-lg-none">
                    <dropup-menu :bottom="50" :hide-bottom-bar="true" :show-overlay="true">
                        <template slot="menu-icon">
                            <i class="cf-more btn-link text-white"></i>
                        </template>
                        <template slot="menu-options">
                            <ul class="menu-options">
                                <li >
                                    <i class="fa fa-bookmark-o mr-2"></i> {{ $t('Salva immagine')}}
                                </li>
                                <li>
                                    <i class="fa fa-flag-o mr-2"></i> {{ $t('Segnala immagine')}}
                                </li>
                                <li class="text-danger" @click.prevent="deleteImage" v-if="$auth.loggedIn && ($auth.user.is_admin || post.user_id === $auth.user.id || group.admin)">
                                    <i class="fa fa-trash mr-2"></i> {{ $t('Elimina immagine')}}
                                </li>
                            </ul>
                        </template>
                    </dropup-menu>
                </div>
            </template>

            <template slot="resource-name">
                <nuxt-link v-if="post.user" class="title font-weight-bold text-white" :to="'/estetiste/' + post.user.id + '-' + post.user.slug">{{ post.user.name }}</nuxt-link>
                <span v-else>{{ $t('Utente Cancellato')}}</span>
            </template>
            <template slot="dropup-menu">
                <dropup-menu :hide-bottom-bar="true">
                    <template slot="menu-options">
                        <ul>
                            <li @click.prevent="openInput">
                                <i class="fa fa-photo mr-2"></i>
                                {{ $t('Foto/video')}}
                            </li>
                            <li>
                                <i class="cf-camera mr-2"></i>
                                {{ $t('Fotocamera')}}</li>
                            <li>
                                <i class="cf-friends mr-2"></i>
                                {{ $t('Tagga persone')}}
                            </li>
                        </ul>
                    </template>
                </dropup-menu>
            </template>
        </images-gallery>
        <b-modal id="create-post-modal" v-if="post.layout === 'group-post'" no-fade ref="editPostModal" hide-header hide-footer static lazy @shown="initEditPost">
            <edit-post ref="editPost" :post="post"></edit-post>
        </b-modal>
        <no-ssr>
            <communication-modal v-if="post.layout !== 'group-post'" :post="post" ref="editCommunication"></communication-modal>
        </no-ssr>
    </div>
</template>

<script>

    import Vue from 'vue'
    import { mapState, mapActions, mapGetters } from 'vuex'
    import UserImage from "~/components/UserImage";
    import Comments from "~/components/Group/Wall/Posts/Comments";
    import PostModal from "~/components/Group/PostModal";
    import UserModal from "~/components/Group/UserModal";
    import PostImagesModal from "~/components/Group/PostImagesModal";
    import { focus } from 'vue-focus';
    import EditPost from "~/components/Group/Wall/Posts/EditPost.vue";
    import CommunicationModal from "~/components/CommunicationModal";
    import autosize from 'autosize';
    import _ from 'lodash';

    export default {

        name: 'post',
        // props: ['post', 'modal', 'level', 'layout'],
        props: {
            post: {
                type: Object,
                required: true
            },
            modal: {
                type: Boolean,
                default: function () {
                    return false
                }
            },
            level: {
                type: Number,
                default: function () {
                    return 0
                }
            },
            layout: {
                type: String,
                default: function () {
                    return 'vertical'
                }
            },
            hideComments: {
                type: Boolean,
                default: function () {
                    return false
                }
            }
        },

        components: {
            UserImage,
            Comments,
            EditPost,
            PostModal,
            UserModal,
            PostImagesModal,
            CommunicationModal
        },

        directives: {
            focus: focus,
        },

        data: function () {
            return {
                // limit: !this.modal ? 1 : 0,
                showingComments: (!this.post.from_notifications && this.post.layout === 'group-post' && this.post.comments.length) || this.modal || this.post.permalink === this.$route.path,
                focused: true,
                truncation: true,
                comment: {
                    text: '',
                    post_id: this.$store.state.post.post.id,
                    comment_id: null,
                    user: null
                },
                shareNetworks: {
                  'email':{"sharer":"mailto:?subject=@title&body=@url%0D%0A%0D%0A@description",'type': 'direct'},
                  'facebook':{"sharer":"https://www.facebook.com/sharer/sharer.php?u=@url&title=@title&description=@description&quote=@quote&hashtag=@hashtags",'type': 'direct'},
                  'linkedin':{"sharer":"https://www.linkedin.com/shareArticle?mini=true&url=@url&title=@title&summary=@description",'type': 'direct'},
                  'twitter':{"sharer":"https://twitter.com/intent/tweet?text=@title&url=@url&hashtags=@hashtags@twitteruser",'type': 'direct'},
                  'whatsapp':{"sharer":"https://api.whatsapp.com/send?text=@description%0D%0A@url",'type': 'direct'},
                }
            }
        },

        computed: {
            ...mapState({
                'group': ({group}) => group.group,
                'mobileMode': (index) => index.mobileMode
            }),
            ...mapGetters({
                'getCommentsByPostId': 'comments/GET_COMMENTS_BY_POST_ID',
                'getFakeLikesCount': 'posts/getFakeLikesCount',
                'getUserById': 'users/GET_USER_BY_ID'
            }),
            postUrl() {
              return process.env.BASE_URL + this.post.permalink
            },
            postDetail() {
                return this.modal || this.$route.path.replace(/\/$/, "") === this.post.permalink
            },
            textPieces () {

                let mustTruncate = !this.postDetail && this.truncation

                return this.postTextPieces(this.post.text, mustTruncate)
            },
            postImagesGroups() {

                let imagesGroups = [];
                let groupMaxLength = 3;

                let postImages = _.take(this.post.images, 5);

                if (postImages.length) {
                    _.each(postImages, function (image) {
                        if (imagesGroups.length > Math.max(Math.floor(postImages.length / groupMaxLength), 1)) {

                            let i = imagesGroups.length;

                            while((imagesGroups[i - 1].length >= groupMaxLength) && i > 0) {
                                i--
                            }

                            if (i > 0) {
                                imagesGroups[i - 1].push(image);
                            }

                        } else {
                            imagesGroups.push([]);
                            let imageGroup = _.last(imagesGroups);
                            imageGroup.push(image);
                        }
                    })
                }

                return imagesGroups;
            }
            // formattedPublishDate() {
            //     return this.post.published_at ? moment(this.post.published_at).format('LL') : ''
            // }
        },

        methods: {
            ...mapActions({
                'refetchPost': 'posts/fetchById',
                'deletePost': 'posts/delete',
                'createComment': 'comment/create',
                'refetchComment': 'comments/fetchById',
                'like': 'posts/likePostById',
                'followUserById' : 'users/followById',
                'unfollowUserById' : 'users/unfollowById'
            }),
            showText() {
                if(this.mobileMode) {
                    this.openPostModal();
                } else {
                    this.truncation = false;
                }
            },
            showAlert() {
                if (this.platform === 'ios') {
                    this.$iosAlert('Link dell\'articolo copiato.');
                } else {
                  this.$notify({
                    group: 'messages',
                    type: 'success',
                    title: this.$t('Link dell\'articolo copiato.'),
                    duration: 2000
                  });
                }
            },
            openLink (link) {
                if (link) {
                  window.open(link, 'blank')
                }
            },
            saveComment () {

                if (this.comment.text.length === 0) return;

                this.$nuxt.$loading.start()

                this.createComment(this.comment)
                    .then((comment) => {
                        this.showingOverlay = false;

                        this.comment.text = '';
                        this.comment.comment_id = null;
                        this.comment.user = null;

                        setTimeout(function () {
                            autosize.update(document.querySelectorAll('textarea'))
                        }, 20)

                        this.refetchPost(this.post)

                        if (comment.comment_id) {
                            this.refetchComment(comment.comment_id)
                        }

                        this.$nuxt.$loading.finish()

                    }).catch(() => {
                    this.$nuxt.$loading.finish()
                });
            },
            toggleComments() {
                if (this.mobileMode && !this.postDetail) {
                    this.$refs.postModal.show()
                } else if(this.hideComments) {
                    this.$router.push((this.post.permalink))
                } else {
                    this.showingComments = !this.showingComments;
                }
            },
            showPostImagesModal () {
                if (this.post.images.length > 1) {
                    this.$refs.postImagesModal.show();
                } else {
                    this.$refs.postImagesGallery.showImagesGalleryModal(0);
                }
            },
            likePost() {
                this.like(this.post.id)
                    .then((r) => {
                        // this.refetch();
                    })
            },
            followUser(userId) {
                this.followUserById(userId).then((user) => {

                    // this.$refs.followDropup.toggleShowDropUp()

                    this.$notify({
                        group: 'messages',
                        type: 'success',
                        title: this.$t('Ora segui ' + user.name)
                    });
                }).catch(error => {

                    // this.$refs.followDropup.toggleShowDropUp()

                    this.$notify({
                        group: 'messages',
                        type: 'error',
                        title: this.$t('Si è verificato un errore')
                    });
                })
            },
            unfollowUser(userId) {
                this.unfollowUserById(userId).then((user) => {

                    // this.$refs.followDropup.toggleShowDropUp()

                    this.$notify({
                        group: 'messages',
                        type: 'success',
                        title: this.$t('Non segui più ' + user.name)
                    });
                }).catch(error => {

                    // this.$refs.followDropup.toggleShowDropUp()

                    this.$notify({
                        group: 'messages',
                        type: 'error',
                        title: this.$t('Si è verificato un errore')
                    });
                })
            },
            followPost(postId) {
                this.followPostById(postId).then(() => {

                    // this.$refs.followDropup.toggleShowDropUp()

                    this.$notify({
                        group: 'messages',
                        type: 'success',
                        title: this.$t('Ora segui questo post')
                    });
                })
            },
            unfollowPost(postId) {
                this.followPostById(postId).then(() => {

                    // this.$refs.followDropup.toggleShowDropUp()

                    this.$notify({
                        group: 'messages',
                        type: 'success',
                        title: this.$t('Non segui più questo post')
                    });
                })
            },
            destroyPost(post) {
                this.$nuxt.$loading.start()

                this.deletePost(post).then(() => {

                    this.smartGoBack()

                    this.$nuxt.$loading.finish()

                    this.$refs.dropupMenu.toggleShowDropUp()

                })
                .catch(() => {
                    this.$nuxt.$loading.finish()
                });
            },
            refetch() {
                this.refetchPost(this.post)
            },
            focusNewComment() {

                if (this.mobileMode && !this.modal) {

                    this.$refs.postModal.show()
                } else {
                    this.showingComments = true;

                    setTimeout(() => {
                        this.$nuxt.$emit('focus-new-comment-' + this.post.id);
                    })
                }
            },
            deleteImage() {
                this.$refs.postImagesGallery.deleteImage();
            },
            openPostModal() {

                if (this.$refs.postModal) {
                    this.$refs.postModal.show()
                }
            },
            openPost() {
                if (this.mobileMode) {
                    this.openPostModal();
                } else {
                    this.$router.push(this.post.permalink)
                }
            },
            showUser() {

                if (!this.mobileMode) {
                    if(this.post.user) {
                        this.$router.push('/estetiste/' + this.post.user.id + '-' + this.post.user.slug)
                    }
                } else {
                    if (this.$refs.userModal) {
                        this.$refs.userModal.show()
                    }
                }
            },
            initEditPost() {
                this.$refs.editPost.init()
            },
            openEditPostModal() {
                if (this.post.layout === 'group-post') {
                    this.$refs.editPostModal.show()
                } else {
                    // this.$refs.editCommunication.show()

                    let resource = '';

                    if(this.post.type === 'article') {
                        if(this.post.permalink.startsWith('/comunicazioni/')) {
                            resource = 'communications'
                        } else if(this.post.permalink.startsWith('/punto-estetico/')) {
                            resource = 'articles'
                        }
                    } else if(this.post.type === 'page') {
                        if(this.post.layout === 'category') {
                            if(this.post.permalink.startsWith('/comunicazioni/')) {
                                resource = 'communication-categories'
                            } else if(this.post.permalink.startsWith('/punto-estetico/')) {
                                resource = 'article-categories'
                            }
                        } else {
                            resource = 'pages'
                        }
                    }

                    window.open(process.env.SERVER_BASE_URL + '/nova/resources/' + resource + '/' + this.post.id + '/edit?viaResource&viaResourceId&viaRelationship', '_blank')
                }
            },

            closeEditPostModal() {
                if (this.$refs.editPostModal) {
                    this.$refs.editPostModal.hide()
                }
            }
        },

        created () {

            this.$nuxt.$on('post-' + this.post.id + '-answer-to-comment-id', (data) => {

                Vue.set(this.comment, 'comment_id', data.commentId)
                Vue.set(this.comment, 'user', data.user)
                Vue.set(this.comment, 'text', data.user ? '@' + data.user.name + ' ' + this.comment.text : this.comment.text)

                setTimeout(() => {
                    autosize.update(document.querySelectorAll('textarea'))
                })
            });
        },

        mounted () {
            autosize(document.querySelectorAll('textarea'))
            setTimeout(() => {
                autosize.update(document.querySelectorAll('textarea'))
            })

            this.$nuxt.$on('open-post-modal', (postId) => {
                if (postId === this.post.id) {
                    this.openPostModal()
                }
            })

            this.$nuxt.$on('close-edit-post-modal', () => {
                this.closeEditPostModal()
            })
        }
    }
</script>

<style lang="scss" scoped>

    .text-large {
        font-size: 24px!important;
    }

    a {
        text-decoration: none;
    }

    hr {
        /*border-top: 1px solid #F4F2F3;*/
    }


    .post-content-container {
        padding-left: 15px;
        padding-right: 15px;
        &.communication {
            padding-left: 24px;
            padding-right: 24px;
        }
    }

    .gallery-row {
        margin-left: -15px;
        margin-right: -15px;
        max-height: 75vw;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

</style>
