<template>
    <div class="d-flex relative">
        <img class="profile-img profile-placeholder" :alt="account ? account.name : $t('Immagine profilo')" :style="'width: ' + size + 'px; height: ' + size + 'px'" :src="placeholderImage" v-if="!account ||!account.profile_image_res"/>
        <img class="profile-img" :alt="account.name" :style="'width: ' + size + 'px; height: ' + size + 'px'" :src="account.profile_image_res.small" v-else/>
        <slot name="profile-rank"></slot>
    </div>
</template>

<script>
    import AccountRank from "./AccountRank";

    export default {

        components: {
            AccountRank
        },

        computed: {
            placeholderImage() {

                let image = ''

                let type = this.account ? this.account.type : null;

                switch(type) {
                    case 'school':
                        image =  require('~/assets/images/school-placeholder.svg')
                        break;
                    case 'company':
                    case 'beauty-centre':
                        image =  require('~/assets/images/account-placeholder.svg')
                        break;
                    case 'brand':
                        image = require('~/assets/images/brand-placeholder.svg')
                        break;
                    default:
                        image = require('~/assets/images/account-placeholder.svg');
                        break;
                }
                return image;
            }
        },

        props: {
            'account': {
                type: Object,
                default() {
                    return {};
                }
            },
            'size': {
                type: String,
                default() {
                    return '30';
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
  .profile-img {
    /*border: 1px solid #F9F8F9;*/
    border-radius: 10%;
  }
  .bottom {
    bottom: -10px;
  }
  .right {
    right: -10px;
  }
</style>

