<template>
    <b-modal id="post-modal" modal-class="slide-left" size="lg" body-class="p-0 news" @shown="init" @hidden="hide" no-fade hide-header hide-footer ref="postModal" lazy>
        <div class="card post w-100"
             :style="'height:100vh!important;height: calc(var(--vh, 1vh) * 100)!important;padding-top:' + insets.top + 'px!important; padding-bottom:' + insets.bottom + 'px'"
        >
            <div class="card-header card-header-sm d-flex justify-content-center align-items-center" :class="{' border-0': post.is_communication}">
                <i class="position-absolute cf-back p-3" style="left: 0" @click.prevent="$refs.postModal.hide()"></i>
                <span v-if="post.type === 'group-post'">
                    <span v-if="post.user">{{ $t('Post di ') }} {{ post.user.first_name }}</span>
                    <span v-else>{{ $t('Post')}}</span>
                </span>
                <span v-else-if="post.type === 'article'">
                    <span v-if="post.is_article">
                        <img src="~/assets/images/punto-estetico.png" height="25px">
                    </span>
                    <span v-else-if="post.is_communication" class="d-flex justify-content-center align-items-center">
                        <img src="~/assets/images/ico-c.png" height="20px">
                        <!--<span class="text-vanitas text-brand font-size-xl text-uppercase font-size-lg ml-2">{{ $t('Confestetica') }}</span>-->
                    </span>
                </span>
                <span v-else-if="post.type === 'page'">confestetica</span>
                <dropup-menu ref="dropupMenu" :bottom="50" :hide-bottom-bar="true" :show-overlay="true" v-if="$auth.loggedIn && ($auth.user.is_admin || post.user_id === $auth.user.id || group.group.admin) && !post.is_communication">
                    <template slot="menu-icon">
                        <i class="position-absolute cf-more btn-link text-grey p-3" style="font-size:5px; right: 0"></i>
                    </template>
                    <template slot="menu-options">
                        <ul class="menu-options">
                            <li v-if="$auth.user.is_admin || post.user_id === $auth.user.id || group.group.admin" @click.prevent="openEditPostModal">
                                <!--<nuxt-link :to="'/gruppo/modifica-post/'+ post.id">-->
                                <i class="fa cf-pencil mr-2"></i> {{ $t('Modifica post')}}
                                <!--</nuxt-link>-->
                            </li>
                            <!--<li>-->
                                <!--<i class="fa fa-bookmark-o mr-2"></i> {{ $t('Salva post')}}-->
                            <!--</li>-->
                            <!--<li>-->
                                <!--<i class="fa fa-flag-o mr-2"></i> {{ $t('Segnala post')}}-->
                            <!--</li>-->
                            <li class="text-danger" @click.prevent="destroyPost(post)" v-if="$auth.user.is_admin || post.user_id === $auth.user.id || group.group.admin">
                                <i class="fa fa-trash mr-2"></i> {{ $t('Elimina post')}}
                            </li>
                        </ul>
                    </template>
                </dropup-menu>
                <dropup-menu ref="dropupMenu" :bottom="50" :hide-bottom-bar="true" :show-overlay="true" v-else-if="post.is_communication">
                    <template slot="menu-icon">
                        <img alt="share" class="position-absolute" src="~/assets/images/icons/share.svg" width="20px" style="right: 16px">
                    </template>
                    <template slot="menu-options">
                        <social-sharing :url="postUrl"
                                        :title="post.title ? post.title : ''"
                                        :description="post.abstract ? post.abstract :''"
                                        :hashtags="post.keywords ? post.keywords : ''"
                                        :networks="shareNetworks"
                                        network-tag="li"
                                        inline-template
                        >
                            <ul class="menu-options">
                                <li v-clipboard:copy="postUrl" v-clipboard:success="showAlert">
                                    {{ $t('Copia link') }}
                                </li>
                                <network network="email" class="show-icon">
                                    <i class="fa fa-envelope"></i> Email
                                </network>
                                <network network="facebook" class="show-icon">
                                    <i class="fa fa-facebook"></i> Facebook
                                </network>
                                <network network="linkedin" class="show-icon">
                                    <i class="fa fa-linkedin"></i> LinkedIn
                                </network>
                                <network network="twitter" class="show-icon">
                                    <i class="fa fa-twitter"></i> Twitter
                                </network>
                                <network network="whatsapp" class="show-icon">
                                    <i class="fa fa-whatsapp"></i> Whatsapp
                                </network>
                            </ul>
                        </social-sharing>
                    </template>
                </dropup-menu>

            </div>
            <div class="card-body scrollable-content p-0">

                <post :level="1" :post="post" :modal="true"></post>

            </div>
            <div v-if="comment.user" class="card-footer d-lg-none d-flex justify-content-between text-grey font-size-sm align-items-center p-2">
                <span>{{ $t('Risposta a ') }} {{ comment.user.name }}</span>
                <i class="cf-delete" @click.prevent="clearAnswerComment"></i>
            </div>
            <div class="card-footer comments d-lg-none">
                <div class="d-flex">
                    <!--<div class="align-self-end comment-actions d-block d-lg-none">-->
                        <!--<i class="fa cf-camera text-grey mr-2" @click.prevent="saveComment"></i>-->
                    <!--</div>-->
                    <div class="d-flex w-100 relative">
                        <!--@keypress.enter.prevent="saveComment"-->
                        <textarea class="d-flex comment new-comment w-100"
                                  :id="'newCommentPostId'"
                                  :placeholder="$t('Scrivi un commento...')"
                                  v-model="comment.text"
                        >
                        </textarea>
                        <!--<div class="absolute align-self-end comment-actions d-block d-lg-none">-->
                            <!--<i class="fa fa-smile-o text-grey" @click.prevent="saveComment"></i>-->
                        <!--</div>-->
                    </div>
                    <div class="d-block comment-actions d-lg-none align-self-end">
                        <i class="fa cf-message-f text-brand ml-2" v-if="comment.text.length" v-login-required="saveComment"></i>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="create-post-modal" v-if="post.type === 'group-post'" no-fade ref="editPost" hide-header hide-footer static lazy>
            <edit-post :post="post"></edit-post>
        </b-modal>
        <communication-modal v-if="post.type !== 'group-post'" :post="post" ref="editCommunication"></communication-modal>
    </b-modal>
</template>

<script>

    import Vue from 'vue';
    import { mapGetters, mapState, mapActions } from 'vuex'

    import EditPost from "~/components/Group/Wall/Posts/EditPost.vue";
    import CommunicationModal from "~/components/CommunicationModal";

    import autosize from "autosize"

    export default {

        components: {
            EditPost,
            CommunicationModal
        },

        props: {
            post: {
                type: Object,
                default: function () {
                    return {}
                }
            }
        },

        data: function () {

            return {
                height: 0,
                comment: {
                    text: '',
                    post_id: this.post.id,
                    comment_id: null
                },
                shareNetworks: {
                    'email':{"sharer":"mailto:?subject=@title&body=@url%0D%0A%0D%0A@description", 'type': 'direct'},
                    'facebook':{"sharer":"https://www.facebook.com/sharer/sharer.php?u=@url&title=@title&description=@description&quote=@quote&hashtag=@hashtags", 'type': 'direct'},
                    'linkedin':{"sharer":"https://www.linkedin.com/shareArticle?mini=true&url=@url&title=@title&summary=@description",'type': 'direct'},
                    'twitter':{"sharer":"https://twitter.com/intent/tweet?text=@title&url=@url&hashtags=@hashtags@twitteruser",'type': 'direct'},
                    'whatsapp':{"sharer":"https://api.whatsapp.com/send?text=@description%0D%0A@url", 'type': 'direct'},
                }
            }
        },

        watch: {
            bottomOffset: function () {
                document.querySelector('body').scrollTo(0,0)
            }
        },

        computed: {

            ...mapGetters({
                'getCommentsByPostId': 'comments/GET_COMMENTS_BY_POST_ID',
            }),
            ...mapState([
                'bottomOffset',
                'keyboardOpened',
                'insets',
                'group'
            ]),
            postUrl() {
                return process.env.BASE_URL + this.post.permalink
            },
        },

        methods: {

            ...mapActions({
                'fetch': 'comments/fetch',
                'refetch': 'group/fetchById',
                'refetchPost': 'posts/fetchById',
                'createComment': 'comment/create'
            }),

            openEditPostModal() {
                if (this.post.type === 'group-post') {
                    this.$refs.editPost.show()
                } else {
                    // this.$refs.editCommunication.show()
                    window.location = process.env.SERVER_BASE_URL + '/nova/resources/' + this.post.type + 's/edit?viaResource&viaResourceId&viaRelationship'
                }
            },

            show() {
                this.$refs.postModal.show()
            },

            init() {

                this.loadMore();
                autosize.update(document.querySelectorAll('textarea'))
            },

            hide() {

                this.clearAnswerComment()
            },

            clearAnswerComment() {
                this.comment.user = null;
                this.comment.comment_id = null;
            },

            loadMore() {

                this.page++;

                this.fetch({
                    page: this.page,
                    per_page: 50,
                    post_id: this.post.id
                })
            },

            saveComment () {

                if (this.comment.text.length === 0) return;

                this.$nuxt.$loading.start()

                this.createComment(this.comment)
                    .then((comment) => {
                        this.showingOverlay = false;
                        this.comment.text = '';

                        setTimeout(function () {
                            autosize.update(document.querySelectorAll('textarea'))
                        }, 20)

                        this.refetchPost(this.post);

                        this.$nuxt.$loading.finish()

                    }).catch(() => {
                    this.$nuxt.$loading.finish()
                });
            }
        },

        created () {
            this.$nuxt.$on('answer-to-comment-id', (data) => {

                Vue.set(this.comment, 'comment_id', data.commentId)
                Vue.set(this.comment, 'user', data.user)
                Vue.set(this.comment, 'text', data.user ? '@' + data.user.name + ' ' + this.comment.text : this.comment.text)

                setTimeout(() => {
                    autosize.update(document.querySelectorAll('textarea'))
                })
            });
        },

        mounted () {

            // this.height = window.innerHeight;
        },
    }
</script>