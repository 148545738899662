
export const state = () => ({
    step: 'confirm-email'
})

export const mutations = {
    SET_STEP (state, step) {
        state.step = step
    }
}

export const actions = {
    setStep ({ commit }, step) {
        commit('SET_STEP', step)
    }
}