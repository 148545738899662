<template>
    <div class="background-white">
        <div class="profile-helper position-relative p-3" v-if="$auth.loggedIn && $auth.user.id === user.user.id && showProfileHelper && currentProfileHelperStep && !modal">
            <i class="la la-times absolute text-grey font-size-xl p-3" style="right: 0; top: 0" @click.prevent="showProfileHelper = false"></i>
            <div class="d-flex mb-2">
                <img class="align-self-start" :src="currentProfileHelperStep.image_url" width="40px">
                <div class="ml-2">
                    <div class="font-weight-bold mb-1">{{ currentProfileHelperStep.title }}</div>
                    <span>{{ currentProfileHelperStep.description }}</span>
                </div>
            </div>
            <div class="d-flex">
                <button class="btn btn-xs btn-grey flex-grow-1 flex-basis-0 mr-1">{{ $t('Non ora')}}</button>
                <button class="btn btn-xs btn-brand flex-grow-1 flex-basis-0 ml-1" @click.prevent="currentProfileHelperStep.action">{{ currentProfileHelperStep.btn_text }}</button>
            </div>
        </div>
        <hr class="m-0 ml-3 mr-3" v-if="!modal">
        <div class="card-body mt-2">
            <div v-if="modal">
                <div class="font-size-xl mb-2 justify-content-between align-items-center d-flex">
                    <span class="font-weight-xbold">{{ $t('Informazioni di base')}}</span>
                    <a class="text-primary font-size-base ml-auto" @click.prevent="$refs.basicInformationsModal.showModal()" v-if="$auth.user && $auth.user.id === user.user.id">{{ $t('Modifica')}}</a>
                </div>
                <div class="d-flex pt-2 align-items-start">
                    <img alt="location-circle-f" src="~/assets/images/icons/location-circle-f.svg" width="40px">
                    <div class="ml-3 w-100 pb-2" :class="{'border-bottom': user.user.birth_year}">
                        {{ user.user.gender == 'male' ? $t('Uomo') : $t('Donna') }}
                        <div class="font-size-sm text-grey">{{ $t('Genere')}}</div>
                    </div>
                </div>
                <div class="d-flex pt-2 align-items-start" v-if="user.user.birth_year">
                    <img alt="location-circle-f" src="~/assets/images/icons/location-circle-f.svg" width="40px">
                    <div class="ml-3 w-100 pb-2">
                        <span v-if="user.user.birth_day">{{ user.user.birth_day | pad(2) }}/</span><span v-if="user.user.birth_month">{{ user.user.birth_month | pad(2) }}/</span>{{ user.user.birth_year }}
                        <div class="font-size-sm text-grey">{{ $t('Data di nascita')}}</div>
                    </div>
                </div>
            </div>
            <div v-if="!modal">
                <div class="d-flex work-experience-preview-container align-items-center" v-if="($auth.loggedIn && $auth.user.id === user.user.id) || user.user.city" @click.prevent="showSearch('city')">
                    <div class="work-experience-image d-flex justify-content-center align-items-center mr-3">
                        <img alt="home-f" class="basic-information-icon" src="~/assets/images/icons/profile/home-f.svg" v-if="user.user.city">
                        <img alt="home" class="basic-information-icon" src="~/assets/images/icons/profile/home.svg" v-else>
                    </div>
                    <div class="work-experience-text" v-if="user.user.city">
                        {{ $t('Vive a ')}}<span class="font-weight-bold">{{ user.user.city.name }}</span>
                    </div>
                    <div v-else>
                        <span class="text-light-grey">{{ $t('Città attuale') }}</span>
                    </div>
                </div>
                <div class="d-flex work-experience-preview-container align-items-center" v-if="($auth.loggedIn && $auth.user.id === user.user.id ) || (user.user.working_place && user.user.working_place.account)" @click.prevent="openWorkExperienceModal()">
                    <div class="work-experience-image d-flex justify-content-center align-items-center mr-3">
                        <img alt="work-f" class="basic-information-icon" src="~/assets/images/icons/profile/work-f.svg" v-if="user.user.working_place && user.user.working_place.account">
                        <img alt="work" class="basic-information-icon" src="~/assets/images/icons/profile/work.svg" v-else>
                    </div>
                    <div class="work-experience-text" v-if="user.user.working_place && user.user.working_place.account">
                        {{ user.user.working_place.owner ? $t('Titolare presso ') : $t('Dipendente presso ')}}<span class="font-weight-bold">{{ user.user.working_place.account.name }}</span>
                    </div>
                    <div v-else>
                        <span class="text-light-grey">{{ $t('Posto di lavoro') }}</span>
                    </div>
                </div>
                <experience-modal ref="workExperienceModal" type="work"></experience-modal>
                <div class="d-flex work-experience-preview-container align-items-center" v-if="($auth.loggedIn && $auth.user.id === user.user.id && $auth.user.is_beautician) || (user.user.attending_place && user.user.attending_place.account)">
                    <!--<div @click.prevent="">-->
                        <!--&lt;!&ndash;<div class="work-experience-image d-flex justify-content-center align-items-center mr-3">&ndash;&gt;-->
                            <!--&lt;!&ndash;<img alt="education-f" class="basic-information-icon" src="~/assets/images/icons/profile/education-f.svg" v-if="(user.user.attending_place && user.user.attending_place.account) || user.user.diploma || user.user.specialsiation_diploma">&ndash;&gt;-->
                            <!--&lt;!&ndash;<img alt="education" class="basic-information-icon" src="~/assets/images/icons/profile/education.svg" v-else>&ndash;&gt;-->
                        <!--&lt;!&ndash;</div>&ndash;&gt;-->
                        <!--&lt;!&ndash;<div class="work-experience-text" v-if="user.user.specialisation_diploma">&ndash;&gt;-->
                            <!--&lt;!&ndash;{{ $t('Specializzata ') }} <span v-if="user.user.specialisation_diploma.account"> {{ $t('presso la scuola ')}} <strong @click.prevent="$router.push('/centri-estetici/' + user.user.specialisation_diploma.account.id + '-' + user.user.specialisation_diploma.account.slug)">{{ user.user.specialisation_diploma.account.name }}</strong></span> <span v-if="user.user.specialisation_diploma.year"> {{ $t(' nel ')}}<span v-if="user.user.specialisation_diploma.day">{{ user.user.specialisation_diploma.day | pad(2) }}/</span><span v-if="user.user.specialisation_diploma.month">{{ user.user.specialisation_diploma.month | pad(2) }}/</span>{{ user.user.specialisation_diploma.year }}</span>&ndash;&gt;-->
                        <!--&lt;!&ndash;</div>&ndash;&gt;-->
                        <!--&lt;!&ndash;<div class="work-experience-text" v-else-if="user.user.diploma">&ndash;&gt;-->
                            <!--&lt;!&ndash;{{ $t('Diplomata ') }} <span v-if="user.user.diploma.account"> {{ $t('presso la scuola ')}} <strong @click.prevent="$router.push('/centri-estetici/' + user.user.diploma.account.id + '-' + user.user.diploma.account.slug)">{{ user.user.diploma.account.name }}</strong></span> <span v-if="user.user.diploma.year"> {{ $t(' nel ')}}<span v-if="user.user.diploma.day">{{ user.user.diploma.day | pad(2) }}/</span><span v-if="user.user.diploma.month">{{ user.user.diploma.month | pad(2) }}/</span>{{ user.user.diploma.year }}</span>&ndash;&gt;-->
                        <!--&lt;!&ndash;</div>&ndash;&gt;-->
                        <!--&lt;!&ndash;<div class="work-experience-text" v-else-if="user.user.attending_place && user.user.attending_place.account">&ndash;&gt;-->
                            <!--&lt;!&ndash;{{ user.user.attending_place.current ? $t('Studia presso ') : $t('Ha studiato presso')}}<span class="font-weight-bold">{{ user.user.attending_place.account.name }}</span>&ndash;&gt;-->
                        <!--&lt;!&ndash;</div>&ndash;&gt;-->
                    <!--</div>-->
                    <div>
                        <dropup-menu ref="educationDropupMenu" :hide-bottom-bar="true" :show-overlay="true" :disabled="!isEditable($auth.user)">
                            <template slot="menu-icon">
                                <slot name="placeholder">
                                    <div class="work-experience-image d-flex justify-content-center align-items-center mr-3">
                                        <img alt="education-f" class="basic-information-icon" src="~/assets/images/icons/profile/education-f.svg" v-if="(user.user.attending_place && user.user.attending_place.account) || user.user.diploma || user.user.specialsiation_diploma">
                                        <img alt="education" class="basic-information-icon" src="~/assets/images/icons/profile/education.svg" v-else>
                                    </div>
                                    <div class="work-experience-text" v-if="user.user.specialisation_diploma">
                                        {{ $t('Specializzata ') }} <span v-if="user.user.specialisation_diploma.account"> {{ $t('presso la scuola ')}} <strong @click.prevent.stop="$router.push(user.user.specialisation_diploma.account.permalink)">{{ user.user.specialisation_diploma.account.name }}</strong></span> <span v-if="user.user.specialisation_diploma.year"> {{ $t(' nel ')}}<span v-if="user.user.specialisation_diploma.day">{{ user.user.specialisation_diploma.day | pad(2) }}/</span><span v-if="user.user.specialisation_diploma.month">{{ user.user.specialisation_diploma.month | pad(2) }}/</span>{{ user.user.specialisation_diploma.year }}</span>
                                    </div>
                                    <div class="work-experience-text" v-else-if="user.user.diploma">
                                        {{ $t('Diplomata ') }} <span v-if="user.user.diploma.account"> {{ $t('presso la scuola ')}} <strong @click.prevent.stop="$router.push( user.user.diploma.account.permalink)">{{ user.user.diploma.account.name }}</strong></span> <span v-if="user.user.diploma.year"> {{ $t(' nel ')}}<span v-if="user.user.diploma.day">{{ user.user.diploma.day | pad(2) }}/</span><span v-if="user.user.diploma.month">{{ user.user.diploma.month | pad(2) }}/</span>{{ user.user.diploma.year }}</span>
                                    </div>
                                    <!--<div class="work-experience-text" v-else-if="user.user.attending_place && user.user.attending_place.account">-->
                                        <!--{{ user.user.attending_place.current ? $t('Studia presso ') : $t('Ha studiato presso')}}<span class="font-weight-bold">{{ user.user.attending_place.account.name }}</span>-->
                                    <!--</div>-->
                                    <div v-else>
                                        <span class="text-light-grey">{{ $t('Scuola di estetica frequentata') }}</span>
                                    </div>
                                </slot>
                            </template>
                            <template slot="menu-options">
                                <ul class="menu-options">
                                    <li class="text-left" @click.prevent="openCertificateModal(user.user.diploma)" v-if="user.user.diploma"><img class="mr-2" src="~/assets/images/icons/certificates-icon-blk.svg" width="22px">{{ $t('Diploma estetista ') }}<u>{{ $t('II anno')}}</u></li>
                                    <li class="text-left" @click.prevent="openCertificateModal({})" v-else><img class="mr-2" src="~/assets/images/icons/certificates-icon-blk.svg" width="22px">{{ $t('Diploma estetista ') }}<u>{{ $t('II anno')}}</u></li>
                                    <li class="text-left" @click.prevent="openCertificateModal(user.user.diploma_specialisation, 'specialisation')" v-if="user.user.diploma_specialisation"><img class="mr-2" src="~/assets/images/icons/certificates-icon-blk.svg" width="22px">{{ $t('Specializzazione estetista') }} <u>{{ $t('III anno')}}</u></li>
                                    <li class="text-left" @click.prevent="openCertificateModal({}, 'specialisation')" v-else-if="user.user.diploma"><img class="mr-2" src="~/assets/images/icons/certificates-icon-blk.svg" width="22px">{{ $t('Specializzazione estetista ') }} <u>{{ $t('III anno')}}</u></li>
                                    <!--<li class="text-left" @click.prevent="openExperienceModal()"><img class="mr-2" src="~/assets/images/icons/education-icon-blk.svg" width="22px">{{ $t('Aggiungi scuola di estetica') }}</li>-->
                                </ul>
                            </template>
                        </dropup-menu>
                    </div>
                    <certificate-modal ref="certificateModal" :diploma="true"></certificate-modal>
                    <experience-modal ref="accountModal" type="school"></experience-modal>
                </div>
                <div class="d-flex work-experience-preview-container align-items-center" v-if="($auth.loggedIn && $auth.user.id === user.user.id ) || user.user.birth_place" @click.prevent="showSearch('birth_place')">
                    <div class="work-experience-image d-flex justify-content-center align-items-center mr-3">
                        <img alt="location-f" class="basic-information-icon" src="~/assets/images/icons/profile/location-f.svg" v-if="user.user.birth_place">
                        <img alt="location" class="basic-information-icon" src="~/assets/images/icons/profile/location.svg" v-else>
                    </div>
                    <div class="work-experience-text" v-if="user.user.birth_place">
                        {{ $t('Di ')}}<span class="font-weight-bold">{{ user.user.birth_place.name }}</span>
                    </div>
                    <div v-else>
                        <span class="text-light-grey">{{ $t('Città di origine') }}</span>
                    </div>
                </div>
                <div class="d-flex work-experience-preview-container align-items-center" v-if="($auth.loggedIn && $auth.user.id === user.user.id && $auth.user.is_beautician) || user.user.specialisation1" @click.prevent="showSearch('specialisation')">
                    <div class="work-experience-image d-flex justify-content-center align-items-center mr-3">
                        <img alt="specialisation-f" class="basic-information-icon" src="~/assets/images/icons/profile/specialisation-f.svg" v-if="user.user.specialisation1">
                        <img alt="specialisation" class="basic-information-icon" src="~/assets/images/icons/profile/specialisation.svg" v-else>
                    </div>
                    <div class="work-experience-text" v-if="user.user.specialisation1" @click.prevent="showSearch('specialisation')">
                        {{ $t('Specializzata in ')}}<span class="font-weight-bold">{{ user.user.specialisation1.name }}</span>
                    </div>
                    <div v-else>
                        <span class="text-light-grey">{{ $t('Specializzazione primaria') }}</span>
                    </div>
                </div>
                <div class="d-flex work-experience-preview-container align-items-center dropdown" @click.prevent="$refs.userInformationsModal.showModal()" v-if="!modal">
                    <div class="work-experience-image d-flex align-items-center justify-content-center mr-3">
                        <i class="cf-more btn-link"></i>
                    </div>
                    <span v-if="$auth.user && $auth.user.id === user.user.id">{{ $t('Vedi le tue informazioni') }}</span>
                    <span v-else>{{ $t('Vedi le informazioni di ')}} {{ user.user.first_name }}</span>
                </div>
            </div>
        </div>
        <select-picker
                v-if="showingSearch === 'specialisation' && $auth.user && $auth.user.id === user.user.id"
                v-model="specialisation_1_id"
                :cancel-callback="hideSearch"
                :save-callback="saveSpecialisation"
                :options="specialisations.specialisations"
        >
            <template slot="picker-title">
                {{ $t('Specializzazione primaria') }}
            </template>
            <template slot="options-title">
                {{ $t('Lista specializzazioni') }}
            </template>
        </select-picker>
        <select-picker
                v-if="showingSearch === 'city' && $auth.user && $auth.user.id === user.user.id"
                url="/api/cities"
                v-model="city_id"
                :cancel-callback="hideSearch"
                :save-callback="saveCity"
                :initial-object="user.user.city"
        >
            <template slot="picker-title">
                {{ $t('Città attuale') }}
            </template>
            <template slot="options-title">
                {{ $t('Lista città') }}
            </template>
        </select-picker>
        <select-picker
                v-if="showingSearch === 'birth_place' && $auth.user && $auth.user.id === user.user.id"
                url="/api/cities"
                v-model="birth_place_id"
                :cancel-callback="hideSearch"
                :save-callback="saveBirthCity"
                :initial-object="user.user.birth_place"
        >
            <template slot="picker-title">
                {{ $t('Città di origine') }}
            </template>
            <template slot="options-title">
                {{ $t('Lista città') }}
            </template>
        </select-picker>
        <user-information-modal ref="userInformationsModal" v-if="!modal"></user-information-modal>
        <basic-information-modal ref="basicInformationsModal" v-if="modal"></basic-information-modal>
    </div>
</template>

<script>
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
    import { mapFields } from 'vuex-map-fields';
    import UserInformationModal from '~/components/UserProfile/ProfileInfo/Mobile/UserInformationModal'
    import BasicInformationModal from '~/components/UserProfile/ProfileInfo/Mobile/BasicInformationModal'
    import CertificateModal from '~/components/CertificateModal'
    import ExperienceModal from '~/components/UpdateProfile/Mobile/Steps/WorkingPlace'
    import SelectPicker from '~/components/SelectPicker'

    import _ from 'lodash'

    export default {

        components: {
            UserInformationModal,
            BasicInformationModal,
            CertificateModal,
            ExperienceModal,
            SelectPicker
        },

        props: ['limit', 'modal'],

        data: function () {
            return {
                showingSearch: '',
                showProfileHelper: this.$auth.loggedIn && this.$auth.user.is_beautician
            }
        },

        computed: {
            ...mapState([
                'user',
                'specialisations',
                'mobileMode'
            ]),
            ...mapFields('user', [
                'user.city_id',
                'user.birth_place_id',
                'user.specialisation_1_id'
            ]),
            ...mapGetters({
                'isEditable': 'user/IS_EDITABLE_BY_USER'
            }),
            currentProfileHelperStep() {
                return _.find(this.profileHelperSteps, {visible: true})
            },
            profileHelperSteps() {
                return {
                    'specialisation': {
                        visible: !this.$auth.user.specialisation_1_id,
                        image_url: require('assets/images/icons/specialisation-circle-f.svg'),
                        title: 'In cosa sei specializzata?',
                        description: 'Il tuo profilo può cambiare quanto te. Aggiungi dettagli per aiutare le persone a conoscerti meglio.',
                        btn_text: 'Aggiungi specializzazione',
                        action: this.showSearch.bind(this, 'specialisation'),
                    },
                    'education': {
                        visible: !this.$auth.user.attending_place,
                        image_url: require('assets/images/icons/school-circle-f.svg'),
                        title: 'Quale scuola hai frequentato?',
                        description: 'Il tuo profilo può cambiare quanto te. Aggiungi dettagli per aiutare le persone a conoscerti meglio.',
                        btn_text: 'Aggiungi scuola di estetica',
                        action: this.openExperienceModal
                    },
                    'birth_place': {
                        visible: !this.$auth.user.birth_place_id,
                        image_url: require('assets/images/icons/location-circle-f.svg'),
                        title: 'Qual è la tua città di origine?',
                        description: 'Il tuo profilo può cambiare quanto te. Aggiungi dettagli per aiutare le persone a conoscerti meglio.',
                        btn_text: 'Aggiungi città di origine',
                        action: this.showSearch.bind(this, 'birth_place'),
                    },
                    'city': {
                        visible: !this.$auth.user.city_id,
                        image_url: require('assets/images/icons/home-circle-f.svg'),
                        title: 'In quale città vivi?',
                        description: 'Il tuo profilo può cambiare quanto te. Aggiungi dettagli per aiutare le persone a conoscerti meglio.',
                        btn_text: 'Aggiungi la città dove vivi',
                        action: this.showSearch.bind(this, 'city'),
                    },
                    'working_place': {
                        visible: !this.$auth.user.working_place,
                        image_url: require('assets/images/icons/work-circle-f.svg'),
                        title: 'Dove lavori?',
                        description: 'Il tuo profilo può cambiare quanto te. Aggiungi dettagli per aiutare le persone a conoscerti meglio.',
                        btn_text: 'Aggiungi luogo di lavoro',
                        action: this.openWorkExperienceModal
                    }
                }
            }
        },

        methods: {
            ...mapActions({
               'updateUser': 'user/update'
            }),
            ...mapMutations({
                resetCertificate: 'certificate/RESET_STATE',
                setCertificate: 'certificate/SET_CERTIFICATE',
                setCertificateType: 'certificate/SET_CERTIFICATE_TYPE',
                setCertificateDiploma: 'certificate/SET_CERTIFICATE_DIPLOMA',
                setCertificateEntityId: 'certificate/SET_CERTIFICATE_ENTITY_ID',
                setCertificateEntityType: 'certificate/SET_CERTIFICATE_ENTITY_TYPE',
                resetAccount: 'account/RESET_ACCOUNT',
                resetWorkExperience: 'work-experience/RESET_STATE',
                setWorkExperience: 'work-experience/SET_WORK_EXPERIENCE',
                setWorkExperienceStep: 'work-experience/SET_STEP',
                setWorkExperienceCurrent: 'work-experience/SET_WORK_EXPERIENCE_CURRENT',
            }),
            showSearch(search) {

                this.showingSearch = search
            },
            hideSearch() {
                this.showingSearch = '';
            },
            saveSpecialisation() {

                this.$nuxt.$loading.start()

                this.updateUser({
                    specialisation_1_id: this.user.user.specialisation_1_id
                }).then(() => {
                    this.hideSearch();
                    this.$nuxt.$loading.finish()
                }).catch(() => {
                    this.$nuxt.$loading.finish()
                })
            },
            saveBirthCity() {

                this.$nuxt.$loading.start()

                this.updateUser({
                    birth_place_id: this.user.user.birth_place_id
                }).then(() => {
                    this.hideSearch();
                    this.$nuxt.$loading.finish()
                }).catch(() => {
                    this.$nuxt.$loading.finish()
                })
            },
            saveCity() {

                this.$nuxt.$loading.start()

                this.updateUser({
                    city_id: this.user.user.city_id
                }).then(() => {
                    this.hideSearch();
                    this.$nuxt.$loading.finish()
                }).catch(() => {
                    this.$nuxt.$loading.finish()
                })
            },
            openWorkExperienceModal() {

                if (!this.isEditable(this.$auth.user)) return;

                if (this.user.user.working_place) {
                    this.setWorkExperience(this.user.user.working_place)
                } else {
                    this.resetWorkExperience();
                }

                this.$refs.workExperienceModal.openModal()
            },
            openExperienceModal() {

                if (!this.isEditable(this.$auth.user)) return;

                this.resetWorkExperience();

                if (this.user.user.attending_place) {
                    this.setWorkExperience(this.user.user.attending_place)
                } else {
                    this.setWorkExperienceCurrent(true)
                }

                this.$refs.accountModal.openModal()
            },
            openCertificateModal(diploma, type = null) {

                if (!this.isEditable(this.$auth.user)) return;

                if (diploma.id) {
                    this.setCertificate(diploma)
                } else {
                    this.resetCertificate(diploma)
                    this.setCertificateDiploma(true);
                    this.setCertificateType(type);
                    this.setCertificateEntityId(this.$store.state.user.user.id)
                    this.setCertificateEntityType('user')
                }
                this.$refs.certificateModal.showModal();
            }
        },

        mounted () {

        }
    }
</script>

<style lang="scss" scoped>

    .profile-helper {
        background-color: rgba(155, 24, 126, 0.07);
    }

    a {
        text-decoration: none;
    }

    .basic-information-icon {
        width: 24px;
    }

    .work-experience-preview-container {

        padding: 4px 0;

        .work-experience-image {
            width: 30px;
            min-width: 30px;
            height: 30px;
            margin-bottom: 0!important;

            &.image-placeholder {
                background-color: #772A79;
            }
        }

        .work-experience-text {

            .work-experience-title {
                color: #403D40;
                font-size: 17px;
                font-weight: bold;
                line-height: 20px;
            }
            .work-experience-description {
                color: #8C8B8C;
                font-size: 15px;
                line-height: 18px;
            }
        }
        .edit-work-experience {
            font-size: 13px;
            margin-right: -10px
        }
    }
</style>
