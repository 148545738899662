import Vue from "vue";
import { getField, updateField } from 'vuex-map-fields';
import _ from 'lodash'

export const state = () => ({
    sort: {
        column: 'created_at',
        order: 'desc'
    },
    pagination: {
        page: 0,
        per_page: 50,
    },
    groupBuyComments: [],
    totalCount: 0,
    infiniteScrollEnabled: true,
    processing: false
})

export const getters = {
    getField,
    getFilteredComments: (state) => (filters) => {
        return _.orderBy(_.filter(state.groupBuyComments, (comment) => {

            let passes = true;

            if(filters.hasOwnProperty('group_buy_product_id')) {
                passes = passes && comment.group_buy_product_id === filters.group_buy_product_id
            } else {
                passes = passes && !comment.group_buy_product_id
            }

            if(filters.hasOwnProperty('group_buy_comment_id')) {

                console.log(filters, comment, passes)
                passes = passes && comment.group_buy_comment_id === filters.group_buy_comment_id

            } else {
                passes = passes && !comment.group_buy_comment_id
            }

            return passes
        }), 'created_at', 'asc')
    }
}

export const mutations = {
    updateField,
    SET_PROCESSING (state, processing) {
        state.processing = processing
    },
    SET_GROUP_BUY_COMMENTS (state, groupBuyComments) {
        state.groupBuyComments = groupBuyComments
    },
    LIKE_GROUP_BUY_COMMENT (state, commentId) {
        let comment = _.find(state.groupBuyComments, {id: commentId});

        if(comment) {
            Vue.set(comment, 'liked', true);
        }
    },
    INCREMENT_LIKES_COUNT (state, groupBuyComment) {
        let myComment = _.find(state.groupBuyComments, {id: groupBuyComment.id});

        if(myComment) {
            Vue.set(myComment, 'likes_count', myComment.likes_count + 1);
        }

    },
    APPEND_GROUP_BUY_COMMENT (state, groupBuyComment) {

        if(!_.includes(_.map(state.groupBuyComments, 'id'), groupBuyComment.id)) {

            state.groupBuyComments.push(groupBuyComment);
        } else {
            let commentIndex = _.findIndex(state.groupBuyComments, {id: groupBuyComment.id});

            Vue.set(state.groupBuyComments, commentIndex, groupBuyComment);
        }
    },
    REMOVE_GROUP_BUY_COMMENT (state, groupBuyComment) {
        let commentIndex = _.findIndex(state.groupBuyComments, {id: groupBuyComment.id});

        if (commentIndex > -1) {
            state.groupBuyComments.splice(commentIndex, 1);
        }
    },
    APPEND_GROUP_BUY_COMMENTS (state, groupBuyComments) {

        _.each(groupBuyComments, (groupBuyComment) => {
            state.groupBuyComments.push(groupBuyComment);
        });
    },
    SET_INFINITE_SCROLL_ENABLED (state, infiniteScrollEnabled) {
        state.infiniteScrollEnabled = infiniteScrollEnabled
    },
    SET_TOTAL_COUNT (state, totalCount) {
        state.totalCount = totalCount
    }
}

export const actions = {

    fetchById ({ commit, state}, id) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .get('/api/group-buy-comments/' + id)
            .then(r => r.data)
            .then(groupBuyComment => {
                commit('APPEND_GROUP_BUY_COMMENT', groupBuyComment)

                _.each(groupBuyComment.comments, function (childComment) {
                    commit('APPEND_GROUP_BUY_COMMENT', childComment)
                })

                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_LOADING', false)
                throw error;
            })
    },

    fetch ({ commit, state}, data) {

        return this.$axios
            .get('/api/group-buy-comments', {
                params: {
                    ...data.filters,
                    ...data.sort,
                    page: data && data.page ? data.page : state.pagination.page,
                    per_page: data && data.per_page ? data.per_page : state.pagination.per_page
                }
            })
            .then(r => r.data)
            .then(response => {

                _.each(response.data, (groupBuyComment) => {

                    commit('APPEND_GROUP_BUY_COMMENT', groupBuyComment);
                });

                commit('SET_TOTAL_COUNT', response.total);
                commit('SET_INFINITE_SCROLL_ENABLED', response.data.length >= ( data && data.per_page ? data.per_page : state.pagination.per_page));
                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },

    create ({ commit, state}, groupBuyComment) {

        return this.$axios
            .post('/api/group-buy-comments', groupBuyComment)
            .then(r => r.data)
            .then(comment => {
                commit('APPEND_COMMENT', groupBuyComment);
            })
            .catch(error => {
                throw error;
            })
    },

    update ({ commit, state}, groupBuyComment) {

        return this.$axios
            .put('/api/group-buy-comments/' + groupBuyComment.id, groupBuyComment)
            .then(r => r.data)
            .then(comment => {
                commit('APPEND_COMMENT');
            })
            .catch(error => {
                throw error;
            })
    },

    delete ({ commit, state}, groupBuyComment) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .delete('/api/group-buy-comments/' + groupBuyComment.id)
            .then(r => r.data)
            .then(res => {
                commit('REMOVE_GROUP_BUY_COMMENT', groupBuyComment)
                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },

    likeCommentById ({ commit, state}, groupBuyCommentId) {
        
        commit('SET_PROCESSING', true)

        return this.$axios
            .post('/api/group-buy-comments/' + groupBuyCommentId + '/like')
            .then(r => r.data)
            .then(groupBuyComment => {
                commit('SET_PROCESSING', false)
                commit('APPEND_GROUP_BUY_COMMENT', groupBuyComment)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },

    appendComments({commit, state, dispatch}, groupBuyComments) {

        if(!groupBuyComments || !groupBuyComments.length) return;

        _.each(groupBuyComments, (groupBuyComment) => {

            commit('APPEND_GROUP_BUY_COMMENT', groupBuyComment);

            if(groupBuyComment.groupBuyComments && groupBuyComment.groupBuyComments.length) {
                dispatch('appendComments', groupBuyComment.groupBuyComments);
            }
        });
    },
}