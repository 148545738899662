<template>
    <div class="card h-100 text-center">
        <div class="card-header card-header-sm  d-flex justify-content-center align-items-center">
            <i class="cf-delete absolute p-3" style="left: 0;" @click.prevent="previousStep()"></i>
            {{ $t('Collegati ad un luogo di lavoro') }}
        </div>
        <div class="card-body">
            <div class="image-container mb-2">
                <img alt="step-01" class="w-100 icon" src="~/assets/images/steps/step-01.png">
            </div>
            <div class="title">{{ $t('Unisci il tuo profilo professionale ad un centro estetico')}}</div>
            <div class="description"> {{ $t('Seleziona il tuo ruolo nel centro estetico.')}}</div>
            <!--<div class="d-flex mt-3 mb-2 justify-content-center">-->
                <!--<div class="work-experience-type-box d-flex justify-content-center align-items-center flex-column mr-2 text-uppercase font-size-sm" @click.prevent="setWorkExperienceType('owner')" :class="{active: workExperience.workExperience.type === 'owner'}">-->
                    <!--{{ $t('Titolare') }}-->
                <!--</div>-->
                <!--<div class="work-experience-type-box d-flex justify-content-center align-items-center flex-column ml-2 text-uppercase font-size-sm" @click.prevent="setWorkExperienceType('employee')" :class="{active: workExperience.workExperience.type === 'employee'}">-->
                    <!--{{ $t('Dipendente') }}-->
                <!--</div>-->
            <!--</div>-->
        </div>
        <div class="card-footer">
            <button class="btn btn-brand w-100" @click.prevent="nextStep()">
                {{ $t('Avanti') }}
            </button>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState, mapMutations } from 'vuex'
    import _ from 'lodash'

    export default {

        props: ['modal', 'type'],

        computed: {
            ...mapState({
                'user': 'user',
                'workExperience': 'work-experience'
            })
        },

        methods: {
            ...mapActions({
                'refetchUser': 'user/refetch'
            }),
            ...mapMutations({
                'setStep': 'profile-wizard/SET_STEP',
                'setWorkExperienceType': 'work-experience/SET_TYPE',
                'setWorkExperienceStep': 'work-experience/SET_STEP'
            }),
            previousStep() {
                if (this.modal) {
                    this.$nuxt.$emit('close-experience-modal')
                } else {
                    this.smartGoBack()
                }
            },
            nextStep() {
                this.setWorkExperienceStep('work-experience')
            }
        },

        mounted () {
        },
    }
</script>