<template>
    <div class="user-menu">
        <div class="user-nav">
            <a href="#" class="no-decoration" @click.prevent="toggleMenu" :class="{'menu-open': menuOpen}">
                <slot name="menu-button">
                    <i class="cf-bell"></i>
                </slot>
            </a>
            <!--<div class="list card" v-if="menuOpen" v-click-outside="outside">-->
            <div class="list card" v-if="menuOpen">
                <ul>
                    <li class="divider">
                        <div class="d-flex justify-content-between align-items-center">
                            {{ $t('Notifiche') }}
                            <a href="#" @click.prevent="readAllNotifications"> {{ $t('Segna tutte come già lette') }}</a>
                        </div>
                    </li>

                    <li v-if="!latestNotifications.length" @click="outside" class="p-3">{{ $t('Attualmente non hai ricevuto altre notifiche.') }}</li>

                    <li v-if="latestNotifications.length" v-for="notification in latestNotifications" :key="notification.id" @click="outside">
                        <notification class="result-row p-2" :class="{active : !notification.read_at }" :notification="notification"></notification>
                        <hr />
                    </li>
                    <li @click="outside">
                        <!--<div v-for="notification in latestNotifications" :key="notification.id">-->
                        <!--</div>-->
                        <nuxt-link to="/notifiche" class="text-center nuxt-link-inactive" v-if="latestNotifications.length">
                            {{ $t('Mostra tutte') }}
                        </nuxt-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters } from 'vuex'
    import UserImage from "~/components/UserImage"
    import Notification from "~/components/Notifications/Notification"

    export default {
        data () {
            return {
                menuOpen: false
            }
        },

        components: {
            UserImage,
            Notification
        },

        computed: {
            ...mapGetters({
                'latestNotifications': 'notifications/getLatestNotifications'
            }),
            ...mapState([
                'auth'
            ])
        },

        directives: {
            'click-outside': {
                bind: function (el, binding, vNode) {
                    // Provided expression must evaluate to a function.
                    if (typeof binding.value !== 'function') {
                        const compName = vNode.context.name
                        let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`
                        if (compName) {
                            warn += `Found in component '${compName}'`
                        }

                        console.warn(warn)
                    }
                    // Define Handler and cache it on the element
                    const bubble = binding.modifiers.bubble
                    const handler = (e) => {
                        if (bubble || (!el.contains(e.target) && el !== e.target)) {
                            binding.value(e)
                        }
                    }
                    el.__vueClickOutside__ = handler

                    // add Event Listeners
                    document.addEventListener('click', handler)
                },

                unbind: function (el, binding) {
                    // Remove Event Listeners
                    document.removeEventListener('click', el.__vueClickOutside__)
                    el.__vueClickOutside__ = null

                }
            }
        },

        methods: {
            outside: function(e) {
                this.menuOpen = false;
            },

            toggleMenu () {
                this.menuOpen = !this.menuOpen
            },
            readNotification(notification) {

                // Set notification as read

                this.$store.dispatch('notifications/read', notification)

                this.$router.push(notification.data.url);
            },
            readAllNotifications() {

                // Set all notifications as read

                this.$store.dispatch('notifications/readAll').then(() => {

                    this.$store.dispatch('notifications/fetch');
                })
            }
        },
    }
</script>

<style lang="scss" scoped>
    .list {
        width: 350px !important;

        .divider {
            font-size: 14px;
            padding: 6px 12px!important;
            border-bottom: 1px solid #F4F2F3!important;
        }

        a {
            text-decoration: none;
        }
    }

    hr {
        margin: 0!important;
    }
</style>