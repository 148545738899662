
import { getField, updateField } from 'vuex-map-fields';
import _ from 'lodash'
import Vue from "vue";

export const state = () => ({
    filters: {
        text:'',
        profile_rank: [],
        status: 'published',
        group_status: 'enabled',
        group_id: null,
        account_id: null
    },
    sort: {
        column: 'created_at',
        order: 'desc'
    },
    pagination: {
        page: 1,
        per_page: 10,
        last_page: 0
    },
    users: [],
    pendingUsersCount: 0,
    rejectedUsersCount: 0,
    blockedUsersCount: 0,
    totalCount: 0,
    usersCount: 0,
    infiniteScrollEnabled: true,
    processing: false,
    appending: false
})

export const getters = {
    getField,
    GET_ADMINISTRATORS (state) {

        return _.filter(state.users, (user) => { return user.is_admin })
    },
    GET_USER_BY_ID: (state) => (userId) => {

        return _.find(state.users, { 'id': parseInt(userId)})
    },
    getUsersByGroupStatus: (state) => (groupStatus) => {

        if(!groupStatus) return [];

        return _.filter(state.users, function (user) {
            if(groupStatus === 'admin') {
                return user.pivot && user.pivot.admin;
            } else {
                return user.pivot && (user.pivot.status === groupStatus);
            }
        })
    },
    getFromNotificationsUsersByGroupId: (state) => (groupId) => {

        if(!groupId) return [];

        return _.orderBy(_.filter(state.users, function (user) {
            return user.pivot && user.pivot.group_id == groupId &&  user.from_notifications ==  true
        }), 'created_at', 'desc')
    },
    getUsersByFilters: (state) => (filters) => {

        return _.orderBy(_.filter(state.users, function (user) {

            let passes = true;

            if (filters.hasOwnProperty('diploma') && filters.diploma !== null) {

                if(filters.diploma) {
                    passes = passes && user.diploma
                } else {
                    passes = passes && !user.diploma
                }
            }

            if (filters.hasOwnProperty('status') && filters.status) {
                passes = passes && user.status === filters.status
            }

            if (filters.hasOwnProperty('group_status') && filters.hasOwnProperty('group_id')) {
                passes = passes && user.pivot && user.pivot.status === filters.group_status && user.pivot.group_id === filters.group_id
            }

            if (filters.hasOwnProperty('from_notifications')) {
                passes = passes && user.from_notifications === filters.from_notifications
            }

            if (filters.hasOwnProperty('text')) {
                passes = passes && user.name.match(new RegExp(filters.text, 'i'))
            }

            if (filters.hasOwnProperty('exclude_user_id') && filters.exclude_user_id) {
                passes = passes && user.id !== filters.exclude_user_id
            }

            return passes
        }), 'created_at', 'desc')
    }
}

export const mutations = {
    updateField,
    SET_TEXT_FILTER (state, text) {
        state.filters.text = text
    },
    SET_STATUS_FILTER (state, status) {
        state.filters.status = status
    },
    SET_ACCOUNT_ID_FILTER (state, accountId) {
        state.filters.account_id = accountId
    },
    SET_GROUP_ID_FILTER (state, groupId) {
        state.filters.group_id = groupId
    },
    SET_GROUP_STATUS_FILTER (state, groupStatus) {
        state.filters.group_status = groupStatus
    },
    SET_FILTERS (state, filters) {
        state.filters = filters
    },
    SET_PROCESSING (state, processing) {
        state.processing = processing
    },
    SET_APPENDING (state, appending) {
        state.appending = appending
    },
    SET_USERS (state, users) {
        state.users = users
    },
    APPEND_USER (state, user) {

        if(!_.includes(_.map(state.users, 'id'), user.id)) {

            state.users.push(user);
        } else {

            let myUserIndex = _.findIndex(state.users, {id: user.id});

            if(myUserIndex > -1) {
                user.from_notifications = user.from_notifications ? user.from_notifications : state.users[myUserIndex].from_notifications;
                Vue.set(state.users, myUserIndex, user)
            }
        }
    },
    SET_INFINITE_SCROLL_ENABLED (state, infiniteScrollEnabled) {
        state.infiniteScrollEnabled = infiniteScrollEnabled
    },
    SET_PENDING_USERS_COUNT (state, pendingUsersCount) {
        state.pendingUsersCount = pendingUsersCount
    },
    SET_REJECTED_USERS_COUNT (state, rejectedUsersCount) {
        state.rejectedUsersCount = rejectedUsersCount
    },
    SET_BLOCKED_USERS_COUNT (state, blockedUsersCount) {
        state.blockedUsersCount = blockedUsersCount
    },
    SET_USERS_COUNT(state, usersCount) {
      state.usersCount = usersCount
    },
    SET_TOTAL_COUNT (state, totalCount) {
        state.totalCount = totalCount
    },
    SET_LAST_PAGE (state, lastPage) {
        state.pagination.last_page = lastPage
    }
}

export const actions = {

    setTextFilter ({ commit, dispatch }, text) {
        commit('SET_TEXT_FILTER', text)

        // dispatch('fetchAll')
    },

    fetchById ({ commit, state}, data) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .get('/api/users/' + data.id, {
                params: {
                    group_id:  data.group_id,
                    notifications: data.notifications
                }
            })
            .then(r => r.data)
            .then(user => {
                commit('APPEND_USER', user)
                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },

    fetchAll ({ commit, state}, data) {

        commit('SET_PROCESSING', true)

        commit('SET_GROUP_ID_FILTER', data && data.group_id ? data.group_id : null);
        commit('SET_ACCOUNT_ID_FILTER', data && data.account_id ? data.account_id : null);
        commit('SET_STATUS_FILTER', data && data.hasOwnProperty('status') ? data.status : 'published');

        let filters = state.filters
        let sort = _.clone(state.sort)

        if(data && data.hasOwnProperty('filters')) {
            filters = data.filters;

            sort.column = data.filters.column ? data.filters.column : sort.column;
            sort.order = data.filters.order ? data.filters.order  : sort.order ;
        }

        return this.$axios
            .get('/api/users', {
                params: {
                    ...filters,
                    ...sort,
                    page: data && data.page ? data.page : state.pagination.page,
                    per_page: data && data.per_page ? data.per_page : state.pagination.per_page
                },
            })
            .then(r => r.data)
            .then(response => {
                commit('SET_USERS', response.data);
                commit('SET_TOTAL_COUNT', response.total);
                commit('SET_LAST_PAGE', response.last_page);

                commit('SET_PENDING_USERS_COUNT', response.pending_users_count);
                commit('SET_REJECTED_USERS_COUNT', response.rejected_users_count);
                commit('SET_BLOCKED_USERS_COUNT', response.blocked_users_count);

                commit('SET_INFINITE_SCROLL_ENABLED', response.data.length >= ( data && data.per_page ? data.per_page : state.pagination.per_page));
                commit('SET_PROCESSING', false);
            })
            .catch(error => {
                commit('SET_PROCESSING', false);
                throw error;
            })
    },

    fetchMore ({ commit, state}, data) {

        commit('SET_APPENDING', true);

        commit('SET_GROUP_ID_FILTER', data && data.group_id ? data.group_id : null);
        commit('SET_ACCOUNT_ID_FILTER', data && data.account_id ? data.account_id : null);
        commit('SET_STATUS_FILTER', data && data.hasOwnProperty('status') ? data.status : 'published');

        let filters = state.filters
        let sort = _.clone(state.sort)

        if(data && data.hasOwnProperty('filters')) {
            filters = data.filters;

            sort.column = data.filters.column ? sort.column : data.filters.column;
            sort.order = data.filters.order ? sort.order : data.filters.order;
        }

        return this.$axios
            .get('/api/users', {
                params: {
                    ...filters,
                    ...sort,
                    page: data && data.page ? data.page : state.pagination.page,
                    per_page: data && data.per_page ? data.per_page : state.pagination.per_page
                },
            })
            .then(r => r.data)
            .then(response => {

                _.each(response.data, (user) => {

                    commit('APPEND_USER', user);
                });

                commit('SET_TOTAL_COUNT', response.total);
                commit('SET_LAST_PAGE', response.last_page);

                if(response.pending_users_count) {
                    commit('SET_PENDING_USERS_COUNT', response.pending_users_count);
                }
                if(response.rejected_users_count) {
                    commit('SET_REJECTED_USERS_COUNT', response.rejected_users_count);
                }
                if(response.blocked_users_count) {
                    commit('SET_BLOCKED_USERS_COUNT', response.blocked_users_count);
                }

                commit('SET_INFINITE_SCROLL_ENABLED', response.data.length >= ( data && data.per_page ? data.per_page : state.pagination.per_page));
                commit('SET_APPENDING', false)
            })
            .catch(error => {
                commit('SET_APPENDING', false)
                throw error;
            })
    },

    fetchCount({ commit, state }, data) {
        commit("SET_PROCESSING", true)

        let filters = []

        if (data && data.hasOwnProperty("filters")) {
            filters = data.filters
        }

        return this.$axios
            .get("/api/users/count", {
                params: {
                    // ...state.filters,
                    ...filters
                }
            })
            .then(response => {
                commit("SET_USERS_COUNT", response.data)
                commit("SET_PROCESSING", false)
            })
            .catch(error => {
                commit("SET_PROCESSING", false)
                throw error
            })
    },

    appendUsers ({commit}, users) {

        if(!users || !users.length) return;

        _.each(users, (user) => {

            commit('APPEND_USER', user);
        });
    },

    verify ({ commit, state}, {user, certificate_id}) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .post('/api/users/' + user.id + '/verify', {certificate_id})
            .then(r => r.data)
            .then(user => {

                if(this.$auth.user && user.id === this.$auth.user.id) {
                    this.$auth.fetchUser()
                }

                commit('SET_PENDING_USERS_COUNT', state.blockedUsersCount - 1)
                commit('APPEND_USER', user)
                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },

    reject ({ commit, state}, {user, reason, certificate_id}) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .post('/api/users/' + user.id + '/reject', {reason, certificate_id})
            .then(r => r.data)
            .then(user => {

                if(this.$auth.user && user.id === this.$auth.user.id) {
                    this.$auth.fetchUser()
                }

                commit('SET_PENDING_USERS_COUNT', state.pendingUsersCount - 1)
                commit('SET_REJECTED_USERS_COUNT', state.rejectedUsersCount + 1)

                commit('APPEND_USER', user)
                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },

    block ({ commit, state}, user) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .post('/api/users/' + user.id + '/block')
            .then(r => r.data)
            .then(user => {

                if(this.$auth.user && user.id === this.$auth.user.id) {
                    this.$auth.fetchUser()
                }

                commit('SET_BLOCKED_USERS_COUNT', state.blockedUsersCount + 1)
                commit('SET_PENDING_USERS_COUNT', state.pendingUsersCount - 1)

                commit('APPEND_USER', user)
                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },

    unlock ({ commit, state}, user) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .post('/api/users/' + user.id + '/unlock')
            .then(r => r.data)
            .then(user => {

                if(this.$auth.user && user.id === this.$auth.user.id) {
                    this.$auth.fetchUser()
                }

                commit('SET_BLOCKED_USERS_COUNT', state.blockedUsersCount - 1)
                commit('SET_PENDING_USERS_COUNT', state.pendingUsersCount + 1)

                commit('APPEND_USER', user)
                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },

    followById ({ commit, state}, userId) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .post('/api/users/' + userId + '/follow')
            .then(r => r.data)
            .then(user => {
                commit('SET_PROCESSING', false)
                commit('APPEND_USER', user);

                return user;
            })
            .catch(error => {

                commit('SET_PROCESSING', false)
                throw error;
            })
    },

    unfollowById ({ commit, state}, userId) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .post('/api/users/' + userId + '/unfollow')
            .then(r => r.data)
            .then(user => {
                commit('SET_PROCESSING', false)
                commit('APPEND_USER', user);

                return user;
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },
}