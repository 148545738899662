
import { getField, updateField } from 'vuex-map-fields';
import _ from 'lodash'
import Vue from "vue";

export const state = () => ({
    filters: {
        text:'',
        profile_rank: [],
        status: '',
        type: 'beauty-centre',
        region_id: null,
        state_id: null,
        city_id: null,
    },
    pagination: {
        page: 0,
        per_page: 10,
    },
    accounts: [],
    totalCount: 0,
    accountsCount: 0,
    beautyCentresItalyCount: 0,
    beautyCentresRegionCount: 0,
    beautyCentresStateCount: 0,
    beautyCentresCityCount: 0,
    infiniteScrollEnabled: true,
    processing: false
})

export const getters = {
    getField,
    getAccountsByFilters: (state) => (filters) => {

        return _.orderBy(_.filter(state.accounts, function (account) {

            let passes = true;

            if (filters.hasOwnProperty('status') && filters.status) {
                passes = passes && account.status === filters.status
            }

            if (filters.hasOwnProperty('type')) {
                passes = passes && account.type === filters.type
            }

            if (filters.hasOwnProperty('text')) {
                passes = passes && account.name.match(new RegExp(filters.text, 'i'))
            }

            return passes
        }), 'created_at', 'desc')
    }
}

export const mutations = {
    updateField,
    SET_TEXT_FILTER (state, text) {
        state.filters.text = text
    },
    SET_FILTERS (state, filters) {
        state.filters = filters
    },
    SET_PROCESSING (state, processing) {
        state.processing = processing
    },
    SET_ACCOUNTS (state, accounts) {
        state.accounts = accounts
    },
    SET_PAGINATION (state, pagination) {
        state.pagination = pagination
    },
    APPEND_ACCOUNTS (state, accounts) {

        _.each(accounts, (account) => {
            state.accounts.push(account);
        });
    },
    APPEND_ACCOUNT (state, account) {

        if(!_.includes(_.map(state.accounts, 'id'), account.id)) {

            state.accounts.push(account);
        } else {

            let myAccount = _.find(state.accounts, {id: account.id});

            if(myAccount) {
                _.each(Object.keys(account), function (key) {
                    Vue.set(myAccount, key, account[key]);
                })
            }
        }
    },
    SET_INFINITE_SCROLL_ENABLED (state, infiniteScrollEnabled) {
        state.infiniteScrollEnabled = infiniteScrollEnabled
    },
    SET_TOTAL_COUNT (state, totalCount) {
        state.totalCount = totalCount
    },
    SET_ACCOUNTS_COUNT (state, accountsCount) {
        state.accountsCount = accountsCount
    },
    SET_BEAUTY_CENTRES_ITALY_COUNT (state, beautyCentresItalyCount) {
        state.beautyCentresItalyCount = beautyCentresItalyCount
    },
    SET_BEAUTY_CENTRES_REGION_COUNT (state, beautyCentresRegionCount) {
        state.beautyCentresRegionCount = beautyCentresRegionCount
    },
    SET_BEAUTY_CENTRES_STATE_COUNT (state, beautyCentresStateCount) {
        state.beautyCentresStateCount = beautyCentresStateCount
    },
    SET_BEAUTY_CENTRES_CITY_COUNT (state, beautyCentresCityCount) {
        state.beautyCentresCityCount = beautyCentresCityCount
    },
    SET_LAST_PAGE (state, lastPage) {
        state.pagination.last_page = lastPage
    }
}

export const actions = {

    setTextFilter ({ commit, dispatch }, text) {
        commit('SET_TEXT_FILTER', text)

        // dispatch('fetchAll')
    },

    fetchAll ({ commit, state}, data) {

        commit('SET_PROCESSING', true);

        let filters = [];

        if(data && data.hasOwnProperty('filters')) {
            filters = data.filters;
        }

        return this.$axios
            .get('/api/accounts', {
                params: {
                    ...state.filters,
                    ...filters,
                    page: data && data.page ? data.page : state.pagination.page,
                    per_page: data && data.per_page ? data.per_page : state.pagination.per_page
                }
            })
            .then(r => r.data)
            .then(response => {
                commit('SET_ACCOUNTS', response.data)
                commit('SET_TOTAL_COUNT', response.total)
                commit('SET_BEAUTY_CENTRES_ITALY_COUNT', response.italy_count)
                commit('SET_BEAUTY_CENTRES_REGION_COUNT', response.region_count)
                commit('SET_BEAUTY_CENTRES_STATE_COUNT', response.state_count)
                commit('SET_BEAUTY_CENTRES_CITY_COUNT', response.city_count)
                commit('SET_INFINITE_SCROLL_ENABLED', response.data.length >= ( data && data.per_page ? data.per_page : state.pagination.per_page));
                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },

    fetchCount ({ commit, state}, data) {
        commit('SET_PROCESSING', true);

        let filters = [];

        if(data && data.hasOwnProperty('filters')) {
            filters = data.filters;
        }

        return this.$axios
            .get('/api/accounts/count', {
                params: {
                    ...state.filters,
                    ...filters
                }
            })
            .then(response => {
                commit('SET_ACCOUNTS_COUNT', response.data)
                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },

    fetchMore ({ commit, state}, data) {

        commit('SET_PROCESSING', true);

        let filters = [];

        if(data && data.hasOwnProperty('filters')) {
            filters = data.filters;
        }

        return this.$axios
            .get('/api/accounts', {
                params: {
                    ...state.filters,
                    ...filters,
                    page: data && data.page ? data.page : state.pagination.page,
                    per_page: data && data.per_page ? data.per_page : state.pagination.per_page
                }
            })
            .then(r => r.data)
            .then(response => {

                _.each(response.data, (account) => {
                    commit('APPEND_ACCOUNT', account);
                });
                commit('SET_TOTAL_COUNT', response.total);
                commit('SET_LAST_PAGE', response.last_page);
                if(response.italy_count) {
                    commit('SET_BEAUTY_CENTRES_ITALY_COUNT', response.italy_count)
                }
                if(response.region_count) {
                    commit('SET_BEAUTY_CENTRES_REGION_COUNT', response.region_count)
                }
                if(response.state_count) {
                    commit('SET_BEAUTY_CENTRES_STATE_COUNT', response.state_count)
                }
                if(response.city_count) {
                    commit('SET_BEAUTY_CENTRES_CITY_COUNT', response.city_count)
                }
                commit('SET_INFINITE_SCROLL_ENABLED', response.data.length >= ( data && data.per_page ? data.per_page : state.pagination.per_page));
                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },

    block ({ commit, state}, account) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .post('/api/accounts/' + account.id + '/block')
            .then(r => r.data)
            .then(account => {
                commit('accounts/APPEND_ACCOUNT', account, { root: true })
                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },

    unblock ({ commit, state}, account) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .post('/api/accounts/' + account.id + '/unlock')
            .then(r => r.data)
            .then(account => {
                commit('accounts/APPEND_ACCOUNT', account, { root: true })
                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_SAVING', false)
                throw error;
            })
    },

    verify ({ commit, state}, {account: account, certificate_id}) {

        commit('SET_SAVING', true)

        return this.$axios
            .post('/api/accounts/' + account.id + '/verify', {certificate_id})
            .then(r => r.data)
            .then(account => {
                commit('accounts/APPEND_ACCOUNT', account, { root: true })
                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_SAVING', false)
                throw error;
            })
    },

    reject ({ commit, state}, {account, reason, certificate_id}) {

        commit('SET_SAVING', true)

        return this.$axios
            .post('/api/accounts/' + account.id + '/reject', {reason, certificate_id})
            .then(r => r.data)
            .then(account => {
                commit('accounts/APPEND_ACCOUNT', account, { root: true })
                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_SAVING', false)
                throw error;
            })
    },
}