<template>
    <div class="card text-center">
        <div class="card-header card-header-sm d-flex justify-content-center align-items-center">
            <i class="cf-back absolute p-3" style="left: 0;" @click.prevent="previousStep"></i>
            {{ $t('Prenotazioni su WhatsApp') }}
        </div>
        <div class="card-body">
            <div class="mt-3">
                <img alt="step-06" class="w-100 icon" src="~/assets/images/steps/step-06.png">
            </div>
            <div class="mt-3">
                <div class="title">{{ $t('Vuoi ricevere prenotazioni con WhatsApp?')}}</div>
            </div>
            <div class="description mt-3 mb-2">
                {{ $t('E\' importante scrivere il numero di telefono corretto, su quel numero chiameranno i tuoi clienti.')}}
                <br>
                <strong> {{ $t('Ti consigliamo un numero aziendale e non personale.') }}</strong>
            </div>
            <u class="text-primary mt-2">{{ $t('Non uso Whatsapp nel mio centro estetico')}}</u>
            <input type="number" class="form-control form-control-sm mt-3" v-model="whatsapp_number">
        </div>
        <div class="card-footer">
            <button class="btn btn-brand w-100" @click.prevent="nextStep">
                {{ $t('Avanti') }}
            </button>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions, mapMutations } from 'vuex'
    import { mapFields } from 'vuex-map-fields'

    export default {

        computed: {
            ...mapState([
                'account',
                'profile-wizard'
            ]),
            ...mapFields('account', [
                'account.whatsapp_number'
            ])
        },

        props: ['type'],

        methods: {
            ...mapActions({
                'updateWhatsappNumber': 'account/updateWhatsappNumber'
            }),
            ...mapMutations({
                'setStep': 'work-experience/SET_STEP',
                'setAccountType': 'user/SET_ACCOUNT_TYPE'
            }),
            previousStep() {
                this.setStep('account-phone-number')
            },
            nextStep() {
                this.updateWhatsappNumber(this.whatsapp_number).then(() => {
                    this.setStep('certificate')
                }).catch(() => {

                })
            }
        },

        mounted () {

        }
    }
</script>