<template>
    <!--<div class="card info-card card-lg mt-lg-3" v-if="isEditable($auth.user)">-->
        <!--<div class="card-header card-header-sm">-->
            <!--{{ $t('Attestati dei corsi di aggiornamento')}}-->
        <!--</div>-->
    <div class="background-white">
        <div class="card-body">
            <div class="font-size-xl font-weight-xbold mt-3">
                {{ $t('Corsi di specializzazione e aggiornamento')}}
            </div>
            <div v-if="!showingCertificate">
                <div class="d-flex certificate-preview-container align-items-center" v-if="$auth.user && $auth.user.id === user.user.id">
                    <a href="#" class="mr-2" @click.prevent="openCertificateModal({})">
                        <img alt="certificates-circle" src="~/assets/images/icons/certificates-circle.svg" width="40">
                    </a>
                    <div class="certificate-text w-100">
                        <a href="#" @click.prevent="openCertificateModal({}, 'specialisation')">
                            {{ $t('Aggiungi corso di specializzazione')}}
                        </a>
                    </div>
                </div>
                <div v-for="certificate in filteredSpecialisations" :key="certificate.id">
                    <div class="certificate-preview-container d-flex align-items-center show-on-hover">
                        <div class="certificate-image mr-3 cursor-pointer relative" v-if="certificate.image_res">
                            <img :src="certificate.image_res.medium" width="100%">
                        </div>
                        <div class="certificate-image image-placeholder mr-3 d-flex justify-content-center align-items-center" v-else>
                            <img alt="diploma-icon" src="~/assets/images/diploma-icon.svg" width="35px">
                        </div>
                        <div class="certificate-text">
                            <div class="certificate-title">
                                <span v-if="certificate.title">{{ certificate.title }}</span>
                                <span v-else>{{ $t('MakeUp Artist') }}</span>
                            </div>
                            <div class="certificate-description font-size-sm">
                                {{ certificate.institute }}<br>
                                {{ certificate.year}}
                                <span class="text-danger" v-if="certificate.diploma && certificate.status === 'rejected'">{{ $t('Rifiutato') }} </span>
                            </div>
                        </div>
                        <div class="dropdown text-right align-self-start ml-auto" v-if="isEditable($auth.user)">
                            <dropup-menu :hide-bottom-bar="true" :show-overlay="true">
                                <template slot="menu-icon">
                                    <i class="cf-more btn-link"></i>
                                </template>
                                <template slot="menu-options">
                                    <ul class="menu-options">
                                        <li class="pt-2 pb-1 text-grey" ><small>{{ $t('Attestato')}}</small></li>
                                        <hr class="m-0">
                                        <li @click.prevent="openCertificateModal(certificate)"> {{ $t('Modifica')}}</li>
                                        <hr class="m-0">
                                        <li class="text-danger" @click.prevent="removeCertificate(certificate)"> {{ $t('Elimina')}}</li>
                                    </ul>
                                </template>
                            </dropup-menu>
                        </div>
                    </div>
                </div>
                <div class="certificate-preview-container d-flex align-items-center dropdown" v-if="specialisations.length > limit">
                    <div class="certificate-image d-flex align-items-center justify-content-center mr-3">
                        <i class="cf-more btn-link"></i>
                    </div>
                    <div>
                        <span v-if="$auth.user && $auth.user.id === user.user.id && limit">
                            {{ $t('Vedi i tuoi corsi di specializzazione')}}
                        </span>
                        <span v-else>{{ $t('Vedi i corsi di ')}} {{ user.user.first_name }}</span>
                    </div>
                </div>
                <div class="d-flex certificate-preview-container align-items-center" v-if="$auth.user && $auth.user.id === user.user.id">
                    <a href="#" class="d-flex justify-content-center align-items-center mr-2" @click.prevent="openCertificateModal({})">
                        <img alt="specialisations-circle" src="~/assets/images/icons/specialisations-circle.svg" width="40">
                    </a>
                    <div class="certificate-text">
                        <a href="#" @click.prevent="openCertificateModal({}, 'update')">
                           {{ $t('Aggiungi corso di aggiornamento') }}
                        </a>
                    </div>
                </div>
                <div v-for="certificate in filteredCertificates" :key="certificate.id">
                    <div class="certificate-preview-container d-flex align-items-center show-on-hover">
                        <div class="certificate-image mr-3 cursor-pointer relative" v-if="certificate.image_res">
                            <img :src="certificate.image_res.medium" width="100%">
                        </div>
                        <div class="certificate-image image-placeholder mr-3 d-flex justify-content-center align-items-center" v-else>
                            <img alt="diploma-icon" src="~/assets/images/diploma-icon.svg" width="35px">
                        </div>
                        <div class="certificate-text">
                            <div class="certificate-title">
                                <span v-if="certificate.title">{{ certificate.title }}</span>
                                <span v-else>{{ $t('MakeUp Artist') }}</span>
                            </div>
                            <div class="certificate-description font-size-sm">
                                {{ certificate.institute }}<br>
                                {{ certificate.year}}
                                <span class="text-danger" v-if="certificate.diploma && certificate.status === 'rejected'">{{ $t('Rifiutato') }} </span>
                            </div>
                        </div>
                        <div class="dropdown text-right align-self-start ml-auto" v-if="isEditable($auth.user)">
                            <dropup-menu :hide-bottom-bar="true" :show-overlay="true">
                                <template slot="menu-icon">
                                    <i class="cf-more btn-link"></i>
                                </template>
                                <template slot="menu-options">
                                    <ul class="menu-options">
                                        <li class="pt-2 pb-1 text-grey" ><small>{{ $t('Attestato')}}</small></li>
                                        <hr class="m-0">
                                        <li @click.prevent="openCertificateModal(certificate)"> {{ $t('Modifica')}}</li>
                                        <hr class="m-0">
                                        <li class="text-danger" @click.prevent="removeCertificate(certificate)"> {{ $t('Elimina')}}</li>
                                    </ul>
                                </template>
                            </dropup-menu>
                        </div>
                    </div>
                </div>
                <div class="certificate-preview-container d-flex align-items-center dropdown" v-if="certificates.length > limit">
                    <div class="certificate-image d-flex align-items-center justify-content-center mr-3">
                        <i class="cf-more btn-link"></i>
                    </div>
                    <div>
                        <span v-if="$auth.user && $auth.user.id === user.user.id && limit">
                            {{ $t('Vedi i tuoi corsi di aggiornamento')}}
                        </span>
                        <span v-else>{{ $t('Vedi i corsi di ')}} {{ user.user.first_name }}</span>
                    </div>
                </div>
            </div>
        </div>
        <certificate-modal ref="certificateModal"></certificate-modal>
    </div>
</template>

<script>

    import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
    import CertificateModal from '~/components/CertificateModal'
    import _ from 'lodash'

    export default {

        components: {
            CertificateModal,
            // EditCertificate
        },

        props: ['limit', 'modal'],

        data: function () {
            return {
                showingCertificate: false
            }
        },

        computed: {
            ...mapState([
                'user'
            ]),
            ...mapGetters({
                'isEditable': 'user/IS_EDITABLE_BY_USER',
                'getUpdateCertificatesForEntity': 'certificates/getUpdateCertificatesForEntity',
                'getSpecialisationCertificatesForEntity': 'certificates/getSpecialisationCertificatesForEntity'
            }),
            certificates() {
                return this.getUpdateCertificatesForEntity(this.user.user.id, 'App\\User')
            },
            specialisations() {
                return this.getSpecialisationCertificatesForEntity(this.user.user.id, 'App\\User')
            },
            filteredCertificates () {

                if (this.limit) {
                    return this.certificates.slice(0, this.limit);
                } else {
                    return this.certificates;
                }
            },
            filteredSpecialisations () {

                if (this.limit) {
                    return this.specialisations.slice(0, this.limit);
                } else {
                    return this.specialisations;
                }
            },
            lastCertificate() {
                return _.last(this.filteredCertificates);
            }
        },

        methods: {
            ...mapActions({
                'refetchUser': 'user/refetch',
                'deleteCertificate': 'certificates/delete'
            }),
            ...mapMutations({
                setUserTab: 'user/SET_USER_TAB',
                resetCertificate: 'certificate/RESET_STATE',
                setCertificate: 'certificate/SET_CERTIFICATE',
                setCertificateType: 'certificate/SET_CERTIFICATE_TYPE',
                setCertificateEntityId: 'certificate/SET_CERTIFICATE_ENTITY_ID',
                setCertificateEntityType: 'certificate/SET_CERTIFICATE_ENTITY_TYPE'
            }),
            openCertificateModal (certificate, type = 'update') {

                if (certificate.id) {
                    this.setCertificate(certificate);
                } else {
                    this.resetCertificate()
                    this.setCertificateType(type)
                    this.setCertificateEntityId(this.$store.state.user.user.id)
                    this.setCertificateEntityType('user')
                }
                this.$refs.certificateModal.showModal();
            },
            removeCertificate(certificate) {

                this.$nuxt.$loading.start()

                this.deleteCertificate(certificate).then(() => {

                    this.refetchUser().then(() => {

                        this.$nuxt.$loading.finish()

                        this.$notify({
                            group: 'messages',
                            type: 'success',
                            title: this.$t('Attestato eliminato correttamente')
                        });
                    }).catch(() => {
                        this.$nuxt.$loading.finish()
                    })
                })
            },
            hideCertificate () {

                this.refetchUser();
                this.showingCertificate = false;
            }
        },
        mounted () {
        }
    }
</script>

<style lang="scss" scoped>

    a {
        text-decoration: none;
    }

    .certificate-preview-container {

        padding: 10px 0;

        .certificate-image {
            width: 30px;
            height: 30px;
            overflow: hidden;
            margin-bottom: 0!important;

            &.image-placeholder {
                background-color: #772A79;
            }
        }

        .certificate-text {

            .certificate-title {
                color: #403D40;
                font-weight: bold;
                line-height: 20px;
            }
            .certificate-description {
                color: #8C8B8C;
                line-height: 18px;
            }
        }
        .edit-certificate {
            margin-right: -10px
        }
    }

    .certificate-preview {
        width: 100%;
        border: 2px solid #DFDFDF;
        border-radius: 6px;
        background-color: #F9F8F9;

        .certificate-image-container {
            width: 100%;
            overflow: hidden;
        }

        .certificate-image {
            width: 100%;
        }
        .certificate-footer {
            color: #8C8B8C;
            font-size: 14px;
            line-height: 22px;
            height: 50px;
        }
    }
</style>