<template>
    <b-modal id="user-modal" modal-class="slide-up" body-class="p-0 overflow-hidden" content-class="background-translucent" @shown="init" @hidden="hide" no-fade hide-header hide-footer ref="userModal" static>
        <div class="user-modal-header background-white d-flex align-items-center ml-2 mr-2" v-if="showHeader" :style="'top:' + insets.top + 'px;'">
            <nuxt-link :to="'/estetiste/' +  user.id + '-' + user.slug">
                <user-image class="p-2" :user="user" size="40"></user-image>
            </nuxt-link>
            <div class="font-weight-xbold font-size-xl">{{ user.name }}</div>
            <div class="close-button rounded-circle background-gray ml-auto mr-2" @click.prevent="$refs.userModal.hide()"><i class="cf-delete"></i></div>
        </div>
        <div>
            <div class="scrollable-content mr-2 ml-2 overflow-auto" :style="'height: calc(100vh - ' + (insets.top ? insets.top : 0) + 'px);margin-top:' + insets.top + 'px;'">
                <!--<div :style="'padding-bottom:' + insets.bottom + 'px'">-->
                    <div class="background-white rounded overflow-hidden pb-3">
                        <div id="hero" class="hero relative" :style="heroStyle" @click.prevent="$refs.headerImage.showImagesGalleryModal(0)">
                            <div class="close-button rounded-circle background-gray absolute m-2" @click.stop.prevent="$refs.userModal.hide()"><i class="cf-delete"></i></div>
                        </div>
                        <div class="avatar ml-auto mr-auto">
                            <div class="relative" :class="{'profile-placeholder': !user.profile_image_res}" :style="'background: url(' + require('~/assets/images/profile_placeholder_'  + (user.gender === 'male' ? 'm' : 'f') + '.svg') + ') center center/cover no-repeat;'">
                                <img @click.prevent="$refs.profileImage.showImagesGalleryModal(0)" class="absolute w-100 h-100" style="top: 0; left: 0" :src="user.profile_image_res.medium" v-if="user.profile_image_res"/>
                                <!--<img :src="require('~/assets/images/profile_placeholder_'  + (user.user.gender === 'male' ? 'm' : 'f') + '.svg')" v-else/>-->
                            </div>
                        </div>
                        <h1 class="mt-3 user-full-name small-h1 font-weight-title font-size-extra d-flex align-items-center justify-content-center">
                            {{ user.first_name }} {{ user.last_name }}
                            <img alt="certified" class="mt-2 ml-2 verified-icon" src="~/assets/images/icons/certified.svg" v-if="user.status === 'published' && checkExpirationDate">
                            <img alt="verified" class="mt-2 ml-2 verified-icon" src="~/assets/images/icons/verified.svg" v-else-if="user.status === 'published'">
                            <img alt="pending-verification" class="mt-2 ml-2 verified-icon" src="~/assets/images/icons/pending-verification.svg" v-else-if="user.status === 'pending' && user.diploma">
                            <img alt="pending-verification-f" class="mt-2 ml-2 verified-icon" src="~/assets/images/icons/pending-verification-f.svg" v-else>
                        </h1>
                        <div class="pl-3 pr-3 text-center" v-if="$auth.user">
                            <div class="badge badge-sm background-brand text-white mb-2" v-if="group.admin || user.is_admin">
                                <i class="fa fa-star"></i>
                                {{ $t('Amministratore') }}
                            </div>
                            <div class="text-grey font-size-sm mb-3">{{ $t('Data iscrizione a ')}} <b>{{ $t('Confestetica') }}</b>: {{ user.created_at | format('L')}}</div>
                            <div class="d-flex">
                                <div class="w-100">
                                    <button class="btn btn-brand btn-sm w-100" @click.prevent="followUser" v-if="getUserById(user.id) && getUserById(user.id).followed === false">
                                        <img alt="follow-white" class="mr-2" src="~/assets/images/icons/follow-white.svg" width="22px">
                                        {{ $t('Segui estetista') }}
                                    </button>
                                    <button class="btn btn-brand btn-transparent btn-sm w-100" @click.prevent="unfollowUser" v-if="getUserById(user.id) && getUserById(user.id).followed === true">
                                        <img alt="follow-f" class="mr-2" src="~/assets/images/icons/follow-f.svg" width="22px">
                                        {{ $t('Segui già') }}
                                    </button>
                                </div>
                                <button class="btn btn-sm background-gray ml-2" @click.prevent="$refs.userMenu.toggleShowDropUp">
                                    <i class="cf-more text-grey" style="font-size: 5px"></i>
                                </button>
                            </div>
                        </div>

                        <div class="font-weight-xbold font-size-lg p-3"  v-if="common.followeds || common.specialisation">{{ $t('Cose in comune') }}</div>
                        <div class="p-3" style="background-color: rgba(155, 24, 126, 0.07)" v-if="common.followeds || common.specialisation">
                            <div v-if="common.followeds" class="d-flex">
                                <img alt="follow" class="mr-2 align-self-start" src="~/assets/images/icons/follow.svg" width="22px">
                                {{ common.followeds }}
                            </div>
                            <div v-if="common.specialisation" class="d-flex mt-2">
                                <img alt="specialisation-f" class="mr-2 align-self-start" src="~/assets/images/icons/profile/specialisation-f.svg" width="22px">
                                <span>{{ $t('Siete entrambi specializzati in ')}} <u class="font-weight-bold">{{ $auth.user.specialisation1.name }}</u></span>
                            </div>
                        </div>
                        <div class="mt-3 mr-4 ml-4">
                            <button class="btn background-gray btn-sm w-100 font-weight-medium" @click.prevent="$router.push('/estetiste/' + user.id + '-' +  user.slug)">
                                {{ $t('Visualizza il profilo Confestetica') }}
                            </button>
                        </div>
                    </div>
                    <div class="background-white mt-2 pl-2 pr-2 rounded overflow-hidden">

                        <div class="font-weight-xbold font-size-lg p-3">{{ $t('Foto recenti') }}</div>
                        <div v-if="loading" class="d-flex justify-content-center">
                            <i class="fa fa-spin">
                                <img alt="loading" src="~/assets/images/icons/loading.svg" width="36px">
                            </i>
                        </div>
                        <div v-else-if="postImagesGroups.length" class="mb-2">
                            <div v-for="(postImagesGroup, groupIndex) in postImagesGroups" class="gallery-row d-flex">
                                <div v-for="image in postImagesGroup"
                                     class="gallery-image flex-grow-1 position-relative"
                                     :style="'padding-top: ' + (postImagesGroup.length === 1 ? 50 : (100 / postImagesGroup.length)) + '%; background-size: cover; background-position: center; background-repeat: no-repeat; background-image: url(' + (image.image_res ? image.image_res.medium : image.path) + ')'"
                                     @click.prevent="showLastPostImagesModal()"
                                >
                                    <div class="more-images-overlay d-none justify-content-center align-items-center" v-if="lastPostImages.length > 5"> +{{ lastPostImages.length - 5 }}</div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="pl-3">
                            {{ $t('Nessuna foto recente da mostrare')}}
                        </div>
                        <hr class="m-0 mt-4 mr-2 ml-2">
                        <div class="font-weight-xbold font-size-lg p-3">{{ $t('Post del gruppo segreto') }}</div>
                        <posts :user-id="user.id" :modal="true"></posts>
                    </div>
                </div>
            <!--</div>-->
        </div>

        <dropup-menu :hide-bottom-bar="true" :show-overlay="true" ref="userMenu">
            <template slot="menu-options">
                <ul class="menu-options">
                    <li @click.prevent="$router.push('/estetiste/' + user.id + '-' +  user.slug)">{{ $t('Visualizza il profilo') }}</li>
                </ul>
            </template>
        </dropup-menu>

        <images-gallery ref="profileImage" v-if="user.profile_image_res" :images="[user]" image-prefix="profile_">
            <template slot="dropdown-menu" v-if="$auth.user">
                <b-dropdown right no-caret variant="link" class="d-none d-lg-block">
                    <template slot="button-content">
                        <i class="cf-more"></i>
                    </template>
                    <b-dropdown-item @click.prevent>{{ $t('Salva immagine') }}</b-dropdown-item>
                    <b-dropdown-item @click.prevent>{{ $t('Segnala immagine') }}</b-dropdown-item>
                    <b-dropdown-item class="text-danger" @click.prevent="deleteImage" v-if="$auth.user.is_admin || user.id === $auth.user.id || group.admin">{{ $t('Elimina immagine') }}</b-dropdown-item>
                </b-dropdown>
                <div class="dropdown d-block d-lg-none">
                    <dropup-menu :bottom="50" :hide-bottom-bar="true" :show-overlay="true">
                        <template slot="menu-icon">
                            <i class="cf-more btn-link text-white"></i>
                        </template>
                        <template slot="menu-options">
                            <ul class="menu-options">
                                <li >
                                    <i class="fa fa-bookmark-o mr-2"></i> {{ $t('Salva immagine')}}
                                </li>
                                <li>
                                    <i class="fa fa-flag-o mr-2"></i> {{ $t('Segnala immagine')}}
                                </li>
                                <li class="text-danger" @click.prevent="deleteImage" v-if="$auth.user.is_admin || user.id === $auth.user.id || group.admin">
                                    <i class="fa fa-trash mr-2"></i> {{ $t('Elimina immagine')}}
                                </li>
                            </ul>
                        </template>
                    </dropup-menu>
                </div>
            </template>

            <template slot="resource-name">
                <nuxt-link class="title font-weight-bold text-white" :to="'/estetiste/' + user.id + '-' + user.slug">{{ user.name }}</nuxt-link>
            </template>
        </images-gallery>
        <images-gallery ref="headerImage" v-if="user && user.header_image_res" :images="[user]" image-prefix="header_">
            <template slot="dropdown-menu" v-if="$auth.user">
                <b-dropdown right no-caret variant="link" class="d-none d-lg-block">
                    <template slot="button-content">
                        <i class="cf-more"></i>
                    </template>
                    <b-dropdown-item @click.prevent>{{ $t('Salva immagine') }}</b-dropdown-item>
                    <b-dropdown-item @click.prevent>{{ $t('Segnala immagine') }}</b-dropdown-item>
                    <b-dropdown-item class="text-danger" @click.prevent="deleteImage" v-if="$auth.user.is_admin || user.id === $auth.user.id || group.admin">{{ $t('Elimina immagine') }}</b-dropdown-item>
                </b-dropdown>
                <div class="dropdown d-block d-lg-none">
                    <dropup-menu :bottom="50" :hide-bottom-bar="true" :show-overlay="true">
                        <template slot="menu-icon">
                            <i class="cf-more btn-link text-white"></i>
                        </template>
                        <template slot="menu-options">
                            <ul class="menu-options">
                                <li >
                                    <i class="fa fa-bookmark-o mr-2"></i> {{ $t('Salva immagine')}}
                                </li>
                                <li>
                                    <i class="fa fa-flag-o mr-2"></i> {{ $t('Segnala immagine')}}
                                </li>
                                <li class="text-danger" @click.prevent="deleteImage" v-if="$auth.user.is_admin || user.id === $auth.user.id || group.admin">
                                    <i class="fa fa-trash mr-2"></i> {{ $t('Elimina immagine')}}
                                </li>
                            </ul>
                        </template>
                    </dropup-menu>
                </div>
            </template>

            <template slot="resource-name">
                <nuxt-link class="title font-weight-bold text-white" :to="'/estetiste/' + user.id + '-' + user.slug">{{ user.name }}</nuxt-link>
            </template>
        </images-gallery>
        <images-gallery ref="lastPostImages" v-if="lastPostImages.length" :images="lastPostImages" url="/api/post-images">
            <template slot="dropdown-menu" v-if="$auth.user">
                <b-dropdown right no-caret variant="link" class="d-none d-lg-block">
                    <template slot="button-content">
                        <i class="cf-more"></i>
                    </template>
                    <b-dropdown-item @click.prevent>{{ $t('Salva immagine') }}</b-dropdown-item>
                    <b-dropdown-item @click.prevent>{{ $t('Segnala immagine') }}</b-dropdown-item>
                    <b-dropdown-item class="text-danger" @click.prevent="deleteImage" v-if="$auth.user.is_admin || user.id === $auth.user.id || group.admin">{{ $t('Elimina immagine') }}</b-dropdown-item>
                </b-dropdown>
                <div class="dropdown d-block d-lg-none">
                    <dropup-menu :bottom="50" :hide-bottom-bar="true" :show-overlay="true">
                        <template slot="menu-icon">
                            <i class="cf-more btn-link text-white"></i>
                        </template>
                        <template slot="menu-options">
                            <ul class="menu-options">
                                <li >
                                    <i class="fa fa-bookmark-o mr-2"></i> {{ $t('Salva immagine')}}
                                </li>
                                <li>
                                    <i class="fa fa-flag-o mr-2"></i> {{ $t('Segnala immagine')}}
                                </li>
                                <li class="text-danger" @click.prevent="deleteImage" v-if="$auth.user.is_admin || user.id === $auth.user.id || group.admin">
                                    <i class="fa fa-trash mr-2"></i> {{ $t('Elimina immagine')}}
                                </li>
                            </ul>
                        </template>
                    </dropup-menu>
                </div>
            </template>

            <template slot="resource-name">
                <nuxt-link class="title font-weight-bold text-white" :to="'/estetiste/' + user.id + '-' + user.slug">{{ user.name }}</nuxt-link>
            </template>
        </images-gallery>
    </b-modal>
</template>

<script>

    import { mapGetters, mapState, mapActions } from 'vuex'

    import Posts from '~/components/Group/Wall/Posts'

    import _ from 'lodash'

    export default {

        components: {
            Posts
        },

        props: {
            userId: {
                type: Number
            }
        },

        data: function () {

            return {
                height: 0,
                loading: false,
                scrollTop: 0,
                lastPostImages: [],
                common: [],
            }
        },

        computed: {
            ...mapGetters({
                'getUserById': 'users/GET_USER_BY_ID'
            }),
            ...mapState([
                'insets',
                'group'
            ]),
            user () {
                let user = this.getUserById(this.userId)
                return user ? user : {};
            },
            showHeader() {
                return this.scrollTop > 20;
            },
            checkExpirationDate() {
                return this.user.account && this.user.account.subscription_expiration_date && this.$moment(this.user.account.subscription_expiration_date).format() > this.$moment().format();
            },
            heroStyle() {
                return this.user.header_image_res
                    ? 'background: url(' + this.user.header_image_res.xlarge + ') center center/cover no-repeat;'
                    : ''
            },
            postImagesGroups() {

                let imagesGroups = [];
                let groupMaxLength = 3;

                let postImages = _.take(this.lastPostImages, 5);

                if (postImages.length) {
                    _.each(postImages, function (image) {
                        if (imagesGroups.length > Math.max(Math.floor(postImages.length / groupMaxLength), 1)) {

                            let i = imagesGroups.length;

                            while((imagesGroups[i - 1].length >= groupMaxLength) && i > 0) {
                                i--
                            }

                            if (i > 0) {
                                imagesGroups[i - 1].push(image);
                            }

                        } else {
                            imagesGroups.push([]);
                            let imageGroup = _.last(imagesGroups);
                            imageGroup.push(image);
                        }
                    })
                }

                return imagesGroups;
            }
        },

        methods: {

            ...mapActions({
                'fetch': 'user/fetch',
                'refetch': 'user/fetchById',
                'followUserById' : 'users/followById',
                'unfollowUserById' : 'users/unfollowById'
            }),
            followUser() {
                this.followUserById(this.userId).then((user) => {

                    // this.$refs.followDropup.toggleShowDropUp()

                    this.$notify({
                        group: 'messages',
                        type: 'success',
                        title: this.$t('Ora segui ' + user.name)
                    });
                }).catch(error => {

                    // this.$refs.followDropup.toggleShowDropUp()

                    this.$notify({
                        group: 'messages',
                        type: 'error',
                        title: this.$t('Si è verificato un errore')
                    });
                })
            },
            unfollowUser() {
                this.unfollowUserById(this.userId).then((user) => {

                    // this.$refs.followDropup.toggleShowDropUp()

                    this.$notify({
                        group: 'messages',
                        type: 'success',
                        title: this.$t('Non segui più ' + user.name)
                    });
                }).catch(error => {

                    // this.$refs.followDropup.toggleShowDropUp()

                    this.$notify({
                        group: 'messages',
                        type: 'error',
                        title: this.$t('Si è verificato un errore')
                    });
                })
            },
            show() {
                this.refetch(this.userId).then(() => {
                    this.$refs.userModal.show()
                })
            },
            showLastPostImagesModal() {
                this.$refs.lastPostImages.showImagesGalleryModal(0);
            },
            handleModalScroll(modal) {
                this.scrollTop = modal.scrollTop;
            },
            // getUserData() {
            //     this.loading = true;
            //
            //     this.$axios.get('/api/users/' + this.user.id).then((res) => {
            //         this.lastPostImages = res.data.lastPostImages;
            //         this.common = res.data.common;
            //         this.loading = false;
            //     })
            // },
            init() {
                let modal = document.querySelector('#user-modal .modal-body .scrollable-content')

                if (modal) {
                    modal.addEventListener('scroll', this.handleModalScroll.bind(this, modal), { passive: true })
                }

            },
            hide() {
                let modal = document.querySelector('#user-modal .modal-body')

                if (modal) {
                    modal.removeEventListener('scroll', this.handleModalScroll)
                }
            }
        },

        async created () {
            // await this.refetch(this.userId);
        },

        mounted () {
            this.height = window.innerHeight;
        },
    }
</script>
<style scoped lang="scss">

    .scrollable-content {
        border-top-right-radius: 0.25rem !important;
        border-top-left-radius: 0.25rem !important;
    }

    .user-modal-header {
        border-top-right-radius: 0.25rem !important;
        border-top-left-radius: 0.25rem !important;
        position: fixed;
        z-index: 1;
        top:0;
        left: 0;
        right: 0;
    }

    .hero {
        height: 220px;
        background-color: #232935;
    }

    .close-button {
        width: 10vw;
        height: 10vw;
        display: flex;
        justify-content: center;
        align-items: center;
        right: 0;
        top: 0;
    }
    .verified-icon {
        width: 20px;
    }
</style>