<template>
    <div>
        <div class="d-flex" v-if="editable">
            <dropup-menu :hide-bottom-bar="true" :show-overlay="true">
                <template slot="menu-icon">
                    <slot name="placeholder" v-if="!year">
                         <span class="text-light-grey font-size-xl">
                            {{ $t('Data di conseguimento') }}
                         </span>
                    </slot>
                    <div v-else class="no-wrap form-control d-flex align-items-center w-auto background-white border form-control-sm font-weight-medium">
                        {{ year }} <i class="fa fa-caret-down ml-1"></i>
                    </div>
                </template>
                <template slot="menu-options">
                    <ul class="menu-options scrollable-options scrollable-content">
                        <li @click.prevent="$emit('update:year', year.toString())" v-for="year in years">{{ year }}</li>
                    </ul>
                    <ul class="menu-options">
                        <hr>
                        <li v-if="year" @click.prevent="resetYear">{{ $t('Rimuovi') }}</li>
                    </ul>
                </template>
            </dropup-menu>
            <dropup-menu :hide-bottom-bar="true" :show-overlay="true" v-if="year">
                <template slot="menu-icon">
                    <div class="no-wrap form-control d-flex align-items-center w-auto background-white border form-control-sm ml-2 font-weight-medium">
                        <span v-if="!month"><i class="la la-plus mr-1"></i>{{ $t('Aggiungi il mese') }}</span>
                        <span v-else>
                            {{ month | fullMonthName }} <i class="fa fa-caret-down ml-1"></i>
                        </span>
                    </div>
                </template>
                <template slot="menu-options">
                    <ul class="menu-options scrollable-options scrollable-content">
                        <li @click.prevent="$emit('update:month', month.toString())" v-for="month in 12">{{ month | fullMonthName }}</li>
                    </ul>
                    <ul class="menu-options">
                        <hr>
                        <li v-if="month" @click.prevent="resetMonth">{{ $t('Rimuovi') }}</li>
                    </ul>
                </template>
            </dropup-menu>
            <dropup-menu :hide-bottom-bar="true" :show-overlay="true" v-if="month">
                <template slot="menu-icon">
                    <div class="no-wrap form-control d-flex align-items-center w-auto background-white border form-control-sm ml-2 font-weight-medium">
                        <span v-if="!day"><i class="la la-plus mr-1"></i>{{ $t('Aggiungi il giorno') }}</span>
                        <span v-else>
                            {{ day }} <i class="fa fa-caret-down ml-1"></i>
                        </span>
                    </div>
                </template>
                <template slot="menu-options">
                    <ul class="menu-options scrollable-options scrollable-content">
                        <li @click.prevent="$emit('update:day', day.toString())" v-for="day in monthDays">{{ day }}</li>
                    </ul>
                    <ul class="menu-options">
                        <hr>
                        <li v-if="day" @click.prevent="resetDay()">{{ $t('Rimuovi') }}</li>
                    </ul>
                </template>
            </dropup-menu>
        </div>
        <div v-else-if="year" class="text-black">
            <span v-if="day">{{ day | pad(2) }}/</span><span v-if="month">{{ month | pad(2) }}/</span>{{ year }}
        </div>
    </div>
</template>

<script>

    import _ from 'lodash'

    export default {

        props: {
            'editable': {
                type: Boolean,
                default: function () {
                    return true
                }
            },
            'day': {
                type: String,
                default: function () {
                    return ''
                }
            },
            'month': {
                type: String,
                default: function () {
                    return ''
                }
            },
            'year': {
                type: String,
                default: function () {
                    return ''
                }
            },
        },

        data() {
            return {}
        },

        computed: {
            monthDays() {
                return this.$moment(this.year + '-' + this.month, 'YYYY-MM').daysInMonth();
            },
            years() {
                return _.rangeRight(1905, this.$moment().year() + 1);
            },
        },

        methods: {
            resetDay() {
                this.$emit('update:day', '');
            },
            resetMonth() {
                this.resetDay()
                this.$emit('update:month', '');
            },
            resetYear() {
                this.resetMonth()
                this.$emit('update:year', '');
            },
        },

        mounted () {
            // this.$emit('input', this.fullDate)
        }
    }
</script>