<template>
    <div class="info-card">
        <div class="card-body">
            <div class="font-size-xl font-weight-xbold mb-2">{{ $t('Luoghi in cui hai vissuto')}}</div>
            <div class="d-flex certificate-preview-container align-items-center" v-if="$auth.loggedIn && $auth.user.id === user.user.id && !user.user.city" @click.prevent="showSearch('city')">
                <a href="#" class="certificate-image d-flex justify-content-center align-items-center mr-3">
                    <img alt="home-circle" src="~/assets/images/icons/home-circle.svg" width="40">
                </a>
                <div class="certificate-text w-100">
                    <a href="#" >{{ $t('Aggiungi città attuale')}}</a>
                </div>
            </div>
            <div class="d-flex certificate-preview-container align-items-start" v-else-if="user.user.city" @click.prevent="showSearch('city')">
                <a href="#" class="certificate-image d-flex justify-content-center align-items-center mr-3">
                    <img alt="home-circle-f" src="~/assets/images/icons/home-circle-f.svg" width="40">
                </a>
                <div class="certificate-text w-100" :class="{'border-bottom': user.user.birth_place || ($auth.loggedIn && $auth.user.id === user.user.id) }">
                    {{ user.user.city.name }}
                    <div class="pb-2 font-size-sm flex-grow-2 text-grey">{{ $t('Città attuale') }}</div>
                </div>
            </div>
            <div class="d-flex certificate-preview-container align-items-center" v-if="$auth.loggedIn && $auth.user.id === user.user.id && !user.user.birth_place" @click.prevent="showSearch('birth_place')">
                <a href="#" class="certificate-image d-flex justify-content-center align-items-center mr-3">
                    <img alt="location-circle" src="~/assets/images/icons/location-circle.svg" width="40">
                </a>
                <div class="certificate-text w-100">
                    <a href="#" >{{ $t('Aggiungi città di origine')}}</a>
                </div>
            </div>
            <div class="d-flex certificate-preview-container align-items-start" v-else-if="user.user.birth_place" @click.prevent="showSearch('birth_place')">
                <a href="#" class="certificate-image d-flex justify-content-center align-items-center mr-3">
                    <img alt="location-circle-f" src="~/assets/images/icons/location-circle-f.svg" width="40">
                </a>
                <div class="certificate-text w-100">
                    {{ user.user.birth_place.name }}
                    <div class="font-size-sm flex-grow-2 text-grey">{{ $t('Città di origine') }}</div>
                </div>
            </div>
        </div>
        <select-picker
                v-if="showingSearch === 'city' && $auth.loggedIn && $auth.user.id === user.user.id"
                url="/api/cities"
                v-model="city_id"
                :cancel-callback="hideSearch"
                :save-callback="saveCity"
        >
            <template slot="picker-title">
                {{ $t('Città attuale') }}
            </template>
            <template slot="options-title">
                {{ $t('Lista città') }}
            </template>
        </select-picker>
        <select-picker
                v-if="showingSearch === 'birth_place' && $auth.loggedIn && $auth.user.id === user.user.id"
                url="/api/cities"
                v-model="birth_place_id"
                :cancel-callback="hideSearch"
                :save-callback="saveBirthCity"
        >
            <template slot="picker-title">
                {{ $t('Città di origine') }}
            </template>
            <template slot="options-title">
                {{ $t('Lista città') }}
            </template>
        </select-picker>
    </div>
</template>

<script>

    import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
    import { mapFields } from 'vuex-map-fields'
    import SelectPicker from '~/components/SelectPicker'

    export default {

        components: {
            SelectPicker
        },

        props: ['modal'],

        data: function () {
            return {
                showingSearch: ''
            }
        },

        computed: {
            ...mapState([
                'user'
            ]),
            ...mapGetters({
                'isEditable': 'user/IS_EDITABLE_BY_USER'
            }),
            ...mapFields('user', [
                'user.city_id',
                'user.birth_place_id'
            ])
        },

        methods: {
            ...mapActions({
                'updateUser': 'user/update',
                'refetchUser': 'user/refetch',
                'deleteCertificate': 'certificates/delete'
            }),
            ...mapMutations({
                setUserTab: 'user/SET_USER_TAB',
                resetCertificate: 'certificate/RESET_STATE',
                setCertificate: 'certificate/SET_CERTIFICATE',
                setCertificateType: 'certificate/SET_CERTIFICATE_TYPE',
                setCertificateDiploma: 'certificate/SET_CERTIFICATE_DIPLOMA',
                setCertificateEntityId: 'certificate/SET_CERTIFICATE_ENTITY_ID',
                setCertificateEntityType: 'certificate/SET_CERTIFICATE_ENTITY_TYPE'
            }),
            saveBirthCity() {

                this.$nuxt.$loading.start()

                this.updateUser({
                    birth_place_id: this.user.user.birth_place_id
                }).then(() => {
                    this.hideSearch();
                    this.$nuxt.$loading.finish()
                }).catch(() => {
                    this.$nuxt.$loading.finish()
                })
            },
            saveCity() {

                this.$nuxt.$loading.start()

                this.updateUser({
                    city_id: this.user.user.city_id
                }).then(() => {
                    this.hideSearch();
                    this.$nuxt.$loading.finish()
                }).catch(() => {
                    this.$nuxt.$loading.finish()
                })
            },
            showSearch(search) {
                this.showingSearch = search
            },
            hideSearch() {
                this.showingSearch = '';
            }
        },
        mounted () {
        }
    }
</script>

<style lang="scss" scoped>

    a {
        text-decoration: none;
    }

    .certificate-preview-container {

        padding: 10px 0;

        .certificate-image {

            min-width: 30px;
            height: 30px;
            margin-bottom: 0!important;

            &.image-placeholder {
                background-color: #772A79;
            }
        }

        .certificate-text {

            .certificate-title {
                color: #403D40;
                font-weight: bold;
                line-height: 20px;
            }
            .certificate-description {
                color: #8C8B8C;
                line-height: 18px;
            }
        }
        .edit-certificate {
            margin-right: -10px
        }
    }

    .certificate-preview {
        width: 100%;
        border: 2px solid #DFDFDF;
        border-radius: 6px;
        background-color: #F9F8F9;

        .certificate-image-container {
            width: 100%;
            overflow: hidden;
        }

        .certificate-image {
            width: 100%;
        }
        .certificate-footer {
            color: #8C8B8C;
            font-size: 14px;
            line-height: 22px;
            height: 50px;
        }
    }
</style>