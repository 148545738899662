<template>
    <div class="card border-0">
        <div class="card-header card-header-sm background-brand d-flex justify-content-center align-items-center text-white">
            <i class="cf-back absolute" @click.prevent="previousStep()" style="left: 16px"></i>
            {{ $t('Foto centro estetico') }}
        </div>
        <div class="card-body d-flex flex-column p-4">
            <input type="file" name="image" id="image-input" accept="image/*" style="display: none" @change="generatePreviews"/>
            <div class="image-preview-container m-1 show-on-hover" v-if="image.path || image.image_res">
                <div class="image-preview bordered relative d-flex justify-content-center align-items-center" :style="'background-position: center; background-size: contain; background-repeat: no-repeat; background-image: url(' + (image.image_res ? image.image_res.medium : image.path) + ');opacity: ' + ((uploadPercentage != 100 && !image.id) ? 0.5 : 1)">
                    <span class="d-hover-inline remove-image close cursor-pointer" @click.prevent="removeImage()">×</span>
                    <b-progress v-if="uploadPercentage != 100 && !image.id" :value="uploadPercentage" :max="100" variant="success" style="width: 100px"></b-progress>
                </div>
            </div>
            <div v-else>
                <img alt="step-09" class="icon" src="~/assets/images/steps/step-09.png" width="100%">
            </div>

            <div class="pr-4 pl-4">
                <div class="title mt-3">
                    <strong>{{ $t('Scatta una foto del tuo centro estetico')}}</strong>
                </div>
                <div class="description mt-4">
                    {{ $t('Aggiungi la foto dell\'ingresso del tuo centro estetico..') }}
                </div>
            </div>
            <!--<div class="d-flex" :class="size == 'small' ? 'overflow-auto' : 'flex-wrap'">-->
                <!--<div class="m-1 show-on-hover" v-if="image.path">-->
                    <!--<div class="image-preview bordered relative d-flex justify-content-center align-items-center" :class="'image-preview-' + size" :style="'background-size: cover; background-repeat: no-repeat; background-image: url(' + (image.image_res ? image.image_res.medium : image.path) + ');opacity: ' + ((uploadPercentage != 100 && !image.id) ? 0.5 : 1)">-->
                        <!--<span class="d-hover-inline remove-image close cursor-pointer" @click.prevent="removeImage()">×</span>-->
                        <!--<b-progress v-if="uploadPercentage != 100 && !image.id" :value="uploadPercentage" :max="100" variant="success" style="width: 100px"></b-progress>-->
                    <!--</div>-->
                <!--</div>-->
            <!--</div>-->
        </div>
        <div class="card-footer d-flex">
            <div class="w-100 d-flex flex-column">
                <button class="btn btn-brand w-100" @click.prevent="openInput"><i class="cf-camera mr-1"></i>{{ $t('Carica immagine') }}</button>
                <button class="btn btn-brand w-100" @click.prevent="nextStep()">{{ $t('Continua') }}</button>
            </div>
        </div>
    </div>
</template>

<script>

    import Vue from 'vue'
    import { mapState, mapActions, mapMutations } from 'vuex'
    import _ from 'lodash'

    export default {

        props: ['type'],

        data: function () {

            return {
                size: 'medium',
                files: [],
                image: this.$store.state.account.account.location_image || {},
                uploadPercentage: 0
            }
        },

        watch: {
            'images': function () {
                this.$emit('input', this.images);
            }
        },

        computed: {
            ...mapState({
                account: 'account'
            })
        },

        methods: {
            ...mapActions({
                'refetchAccount': 'account/refetch',
                'publishImages': 'account/publishImages'
            }),
            ...mapMutations({
                'setStep': 'work-experience/SET_STEP'
            }),
            previousStep() {
                this.setStep('certificate')
            },
            nextStep() {
                if (this.image.path) {
                    this.publishImages([this.image]).then((response) => {
                        this.refetchAccount().then(() => {
                            this.setStep('profile-image')
                        });
                    });
                } else {
                    this.setStep('profile-image')
                }
            },
            openInput(isVisible) {

                if (isVisible) {
                    let image = document.getElementById("image-input")

                    image.click()
                }
            },

            dataURLToBlob (dataURL) {
                let BASE64_MARKER = ';base64,';
                if (dataURL.indexOf(BASE64_MARKER) == -1) {
                    let parts = dataURL.split(',');
                    let contentType = parts[0].split(':')[1];
                    let raw = parts[1];

                    return new Blob([raw], {type: contentType});
                }

                let parts = dataURL.split(BASE64_MARKER);
                let contentType = parts[0].split(':')[1];
                let raw = window.atob(parts[1]);
                let rawLength = raw.length;

                let uInt8Array = new Uint8Array(rawLength);

                for (let i = 0; i < rawLength; ++i) {
                    uInt8Array[i] = raw.charCodeAt(i);
                }

                return new Blob([uInt8Array], {type: contentType});
            },

            generatePreviews (e) {

                let _self = this;

                if (window.File && window.FileList && window.FileReader) {

                    let files = _.toArray(e.target.files || e.dataTransfer.files);

                    for (let i = 0; i < files.length; i++) {
                        let file = files[i];
                        //Only pics
                        if (!file.type.match('image')) continue;

                        let picReader = new FileReader();

                        picReader.addEventListener("load", function (event) {


                            let image = new Image();
                            image.onload = function (imageEvent) {

                                // Resize the image
                                let canvas = document.createElement('canvas'),
                                    max_size = process.env.IMAGINATION_MAX_UPLOAD_SIZE || '1920',
                                    width = image.width,
                                    height = image.height;
                                if (width > height) {
                                    if (width > max_size) {
                                        height *= max_size / width;
                                        width = max_size;
                                    }
                                } else {
                                    if (height > max_size) {
                                        width *= max_size / height;
                                        height = max_size;
                                    }
                                }
                                canvas.width = width;
                                canvas.height = height;
                                canvas.getContext('2d').drawImage(image, 0, 0, width, height);
                                let dataUrl = canvas.toDataURL('image/jpeg');
                                let resizedImage = _self.dataURLToBlob(dataUrl);

                                let data = new FormData();

                                let imageName = '';

                                _self.image = {
                                    path: event.target.result
                                };

                                data.append('location_image', resizedImage);
                                data.append('location_image_name', imageName);

                                _self.$axios.post(
                                    '/api/accounts/' + _self.account.account.id + '/location-images',
                                    data,
                                    {
                                        onUploadProgress( progressEvent ) {

                                            _self.uploadPercentage = parseInt(Math.round((progressEvent.loaded * 100 ) / progressEvent.total))
                                        }
                                    }).then((response) => {

                                        Vue.set(_self.image, 'id', response.data.image.id)
                                    });
                            }
                            image.src = event.target.result;
                        });
                        //Read the image
                        picReader.readAsDataURL(file);
                    }
                } else {
                    console.log("Your browser does not support File API");
                }
            },

            removeImage () {

                this.image = {
                    'id': null,
                    'path': ''
                }
            }
        },
        created () {
        }
    }
</script>

<style lang="scss" scoped>


    .image-preview-container {
        height: 200px;
        width: 100%;
    }

    .image-preview {

        height: 100%;
        width: 100%;
        object-fit: contain;

        border-radius: 4px;

        &.bordered {
            border: 1px solid #F9F8F9;
        }

        .remove-image {
            position: absolute;
            top: 5px;
            right: 5px;
        }

        > i.cf-add {
            font-size: 40px;
        }

        .title {
            font-size: 18px;
        }
    }
</style>