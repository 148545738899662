<template>
    <div class="error-500 row">
        <div class="col-lg-5 d-flex justify-content-center">
            <img src="~assets/images/update-profile/profile-type.png" class="w-100 px-5 px-lg-0">
        </div>
        <div class="col-lg-7">
            <h1 class="my-lg-5 d-none d-lg-block" style="font-size: 104px">404</h1>
            <div class="font-size-2x mb-3 mb-lg-5 text-center text-lg-left px-4 px-lg-0">{{ $t('Non siamo riusciti a trovare la pagina che stai cercando')}}</div>
            <button class="btn btn-brand m-auto m-lg-0" @click.prevent="$router.push('/')">{{ $t('Torna alla dashboard')}}</button>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'error-404',
        props: {
            error: {
                type: Object,
                default: () => {},
            },
        },
    };
</script>