import Vue from 'vue'
import { getField, updateField } from 'vuex-map-fields';
import _ from 'lodash'

export const state = () => ({
    paymentMethods: [],
    processing: false
})

export const getters = {
    getField,
    getPaymentMethodsByCustomerId: (state) => (customerId) => {
        return _.filter(state.paymentMethods, { customer: customerId });
    },
}

export const mutations = {
    updateField,
    SET_PROCESSING (state, processing) {
        state.processing = processing
    },
    APPEND_PAYMENT_METHOD (state, paymentMethod) {

        if(!_.includes(_.map(state.paymentMethods, 'id'), paymentMethod.id) ) {

            state.paymentMethods.push(paymentMethod);
        } else {

            let paymentMethodIndex = _.findIndex(state.paymentMethods, {id: paymentMethod.id});

            Vue.set(state.paymentMethods, paymentMethodIndex, paymentMethod);
        }
    },
    REMOVE_PAYMENT_METHOD (state, paymentMethodId) {
        let paymentMethodIndex = _.findIndex(state.paymentMethods, {id: paymentMethodId});

        if (paymentMethodIndex > -1) {
            state.paymentMethods.splice(paymentMethodIndex, 1);
        }
    },
}

export const actions = {

    fetch ({ commit, state }) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .get('/api/payment-methods')
            .then(r => r.data)
            .then(response => {

                _.each(response, (paymentMethod) => {

                    commit('APPEND_PAYMENT_METHOD', paymentMethod);
                });

                commit('SET_PROCESSING', false)
            })
            .catch(error => {

                commit('SET_PROCESSING', false)
                throw error;
            })
    },

    setDefault ({ commit, state, dispatch }, paymentMethodId) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .post('/api/payment-methods/' + paymentMethodId + '/default')
            .then(r => r.data)
            .then(response => {

                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },
    create ({ commit, state, dispatch }, paymentMethod) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .post('/api/payment-methods', { stripe_payment_method: paymentMethod })
            .then(r => r.data)
            .then(response => {

                commit('APPEND_PAYMENT_METHOD', response);
                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },

    update ({ commit, state, dispatch }, paymentMethod) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .put('/api/payment-methods', { cvc: paymentMethod.expiry })
            .then(r => r.data)
            .then(response => {

                commit('APPEND_PAYMENT_METHOD', response);
                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },

    delete ({ commit, state, dispatch }, paymentMethodId) {

        commit('SET_PROCESSING', true)

        return this.$axios
            .delete('/api/payment-methods/' + paymentMethodId)
            .then(r => r.data)
            .then(response => {
                commit('REMOVE_PAYMENT_METHOD', paymentMethodId)

                commit('SET_PROCESSING', false)
            })
            .catch(error => {
                commit('SET_PROCESSING', false)
                throw error;
            })
    },
}