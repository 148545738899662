<template>
    <div class="info-card">
        <component :modal="modal" :is="ContactInformationComponent"></component>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    export default {

        props: ['modal'],

        computed: {
            ...mapState([
                'mobileMode'
            ]),
            ContactInformationComponent() {
                return () => import('~/components/UserProfile/ProfileInfo/Mobile/ContactInformationCard')
                // return () => import('~/components/UserProfile/ProfileInfo/' + ( this.mobileMode ? 'Mobile' : 'Desktop' ) +'/ContactInformationCard')
            }
        }
    }
</script>
